import { createSelector } from 'reselect';

export const getYouthCampData = (state) => state.youthCamp;
export const getYouthCampDataLoading = (state) => state.youthCamp.isLoading;

export const youthCampPopupDataLoading = (state) =>
  state.youthCamp.isYouthCampPopupLoading;
export const getYouthCampStatus = (state) => state.youthCamp.youthCampStatus;
export const isYouthCampAvailable = (state) =>
  state.youthCamp.isYouthCampAvailable;
export const isYCRegistered = (state) => state.youthCamp.registeredStatus;
export const getScoutLoadingId = (state) => state.youthCamp.scoutLoadingId;
export const getBidLoadingId = (state) => state.youthCamp.bidLoadingId;

export const getYouthCampPlayers = createSelector(
  getYouthCampData,
  ({ youthCampPlayers }) => youthCampPlayers
);
export const getYouthCampFilteredPlayers = createSelector(
  getYouthCampData,
  ({ youthCampFilteredPlayers }) => youthCampFilteredPlayers
);
export const getYouthCampDetails = createSelector(
  getYouthCampData,
  ({ scoutsRemaining, totalScouts, bidsRemaining }) => ({
    scoutsRemaining,
    totalScouts,
    bidsRemaining,
  })
);

export const getYouthCampPlayerDataById = (id) =>
  createSelector(
    getYouthCampData,
    ({ youthCampPlayers }) =>
      youthCampPlayers.filter((i) => i._id === id)[0] || {}
  );
