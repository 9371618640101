import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import usePortal from 'react-useportal';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyledFormContainer,
  StyledFormHeader,
  StyledFormParagraph,
  StyledFormButton,
  StyledBidContainer,
  StyledNumberScout,
} from './styled';

import BidSelect from '../BidSelect';
import { YouthCampScoutPopup } from '../Popup';

import { clearSignUpData } from '../../redux/actions/SignUp';
import { showPopupWindow } from '../../redux/actions/Popups';

import { getPopupByName } from '../../redux/selector/Popups';

const BidForm = ({ bg, noPopup, number }) => {
  const youthCampScoutPopupName = `youthCampScoutPopup-${number}`;

  const dispatch = useDispatch();
  const [scoutAmount, setScoutAmount] = React.useState(1);

  useEffect(() => {
    dispatch(clearSignUpData());
  }, [dispatch]);

  const signUpHandler = () => {
    dispatch(showPopupWindow(youthCampScoutPopupName, youthCampScoutPopupName));
  };

  const checkCallback = (val) => {
    setScoutAmount(val);
  };
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  const youthCampScoutPopupData = useSelector(
    getPopupByName(youthCampScoutPopupName)
  );

  return (
    <StyledFormContainer bg={bg}>
      {!isEmpty(youthCampScoutPopupData) && !noPopup && scoutAmount && (
        <Portal>
          <YouthCampScoutPopup
            scoutVal={scoutAmount}
            popupName={youthCampScoutPopupName}
            id={youthCampScoutPopupName}
          />
        </Portal>
      )}

      <StyledFormHeader>Signup for camp</StyledFormHeader>
      <StyledFormParagraph>
        Join camp to scout and recruit the <b>top 30</b> youth prospects in the
        league. Select how many scouts you want to send to camp.
      </StyledFormParagraph>
      <StyledBidContainer>
        <BidSelect
          callback={(e) => checkCallback(e)}
          minLimitValue={1}
          maxLimitValue={6}
        />
        <StyledFormParagraph />
      </StyledBidContainer>
      <StyledNumberScout>
        Scout <span>{scoutAmount * 5 || 0} </span> players /day
      </StyledNumberScout>
      <StyledFormButton disabled={!scoutAmount} primary onClick={signUpHandler}>
        Sign up
      </StyledFormButton>
    </StyledFormContainer>
  );
};

export default BidForm;
