import styled, { css } from 'styled-components';
import Select from 'react-select';
import { ReactComponent as StyledLeftArrowIcon } from '../../../../../../assets/img/go-arrow-left.svg';
import { StyledSectionContainer } from '../../../../../../globalStyled';

export const StyledTitle = styled.h3``;
export const StyledLeftArrow = styled(StyledLeftArrowIcon)``;

export const StyledWrapper = styled.section`
  /* margin-top: 36px; */
  /* flex: 3; */
  /* margin-right: 10px; */
  z-index: 1;
  h3 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    /* margin-bottom: 25px; */
  }
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    max-width: 220px;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.03);
  padding: 10px 10px 10px;
  border-radius: 2px;
`;

export const StyledBlockWrapper = styled.div``;

export const StyledContainer = styled(StyledSectionContainer)`
  flex-wrap: wrap;
  width: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  padding-bottom: 10px;
  h3 {
    width: 100%;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.18px;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 20px;
  }
`;
export const StyledTacticContainer = styled(StyledContainer)`
  z-index: 2;
`;

export const StyledButton = styled.button`
  display: flex;
  /* flex: 1 40%; */
  width: 30%;
  flex-wrap: wrap;
  justify-content: center;
  /* width: 100%; */
  padding: 12px 5px;
  background: #ffffff;
  height: 90px;
  border: solid 1px #9797973d;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  margin: 5px 0;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    height: 120px;
  }
  svg {
    g {
      fill: #b2b2b2;
      opacity: 1;
    }
  }
  ${({ active }) => {
    if (active)
      return css`
        &.${active} {
          pointer-events: none;
          background: #000;
          box-shadow: 2px 2px 7px 3px rgba(0, 0, 0, 0.16);
          border-radius: 5px;
          border: 1px solid #000;
          > span {
            color: white;
          }
          svg {
            g {
              fill: white;
              opacity: 1;
            }
          }
        }
      `;
  }}
  > span {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #979797;
    transition: all 0.3s;
    @media only screen and (max-width: 1400px) {
      font-size: 12px;
    }
    svg {
      transition: all 0.3s;

      transform: rotate(180deg);
    }
  }
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;

  .react-select__control {
    height: 40px;
    color: #000;
    border: solid 1px #d1d1d1;
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }
`;

export const StyledFormationButtons = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 1400px) {
    padding: 15px 10px;
  }
`;

export const StyledIconPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    height: 40px;
  }
  @media only screen and (max-width: 1400px) {
    svg {
      height: 40px;
      max-width: 40px;
    }
  }
`;

export const StyledMobileSidebar = styled.div`
  height: 100%;
  width: 0px;
  position: fixed;

  z-index: 11;
  top: 46px;
  left: 0px;
  background: #fff;
  padding-top: 0px;
  transition: all 0.2s ease 0s;
  overflow: auto;
  padding-bottom: 10px !important;
  h3 {
    margin-left: 15px;
    height: 46px;
    background-color: #fff;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    @media only screen and (max-width: 768px) {
      margin-left: 0px;
      padding-left: 15px;
      border-bottom: 1px solid #e5e5e5;
    }
    svg {
      margin-right: 20px;
    }
  }
  ${({ isOpened }) => {
    if (isOpened) {
      return css`
        width: 100%;

        //padding: 34px 24px 0 17px;
      `;
    }
  }}
`;
