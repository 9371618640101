// TODO move all league calls here

import { headers } from './requestConfig';
import {
  financeSummary,
  financeAll,
  financeSendMoney,
  financeChart,
} from '../service/links';

export const dataAboutRevenueAndExpensesRequest = async () => {
  const response = await fetch(financeSummary, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const dataAboutTransactionRequest = async () => {
  const response = await fetch(financeAll, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const sendMoneyRequest = async (teamId, cash) => {
  const response = await fetch(financeSendMoney, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      dst: teamId,
      amount: cash,
    }),
  });
  return response;
};

export const financeChartRequest = async () => {
  const response = await fetch(financeChart, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
