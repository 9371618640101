import React from 'react';
import ContentLoader from 'react-content-loader';

const DealsCardPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={220}
    height={265}
    viewBox="0 0 220 265"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="18" y="208" rx="4" ry="4" width="185" height="29" />
    <rect x="63" y="43" rx="4" ry="4" width="94" height="19" />
    <rect x="62" y="100" rx="4" ry="4" width="94" height="15" />
    <rect x="66" y="123" rx="4" ry="4" width="85" height="10" />
    <rect x="60" y="162" rx="4" ry="4" width="101" height="15" />
    <rect x="68" y="185" rx="4" ry="4" width="85" height="10" />
    <rect x="150" y="7" rx="4" ry="4" width="63" height="15" />
    <rect x="14" y="7" rx="4" ry="4" width="63" height="15" />
  </ContentLoader>
);

export default DealsCardPreloader;
