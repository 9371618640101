import { createAction } from 'redux-act';

export const showPopup = createAction('show popup');
export const hidePopup = createAction('hide popup');
export const addPopupError = createAction('Request with error');
export const addNotificationPopup = createAction('Add notification popup');
export const setPopupLoading = createAction('Popup loading');

export const showPopupWindow =
  (id, popupName, isLoading) => async (dispatch) => {
    dispatch(showPopup({ id, popupName, isLoading }));
  };
export const hidePopupWindow = (popupName) => async (dispatch) => {
  dispatch(hidePopup(popupName));
};
export const setErrorToPopup = (popupName, error) => async (dispatch) => {
  dispatch(addPopupError({ popupName, error }));
};
export const isPopupLoading = (popupName, isLoading) => async (dispatch) => {
  dispatch(setPopupLoading({ popupName, isLoading }));
};

export const addNotificationPopupRequest =
  (popupName, message) => async (dispatch) => {
    dispatch(addNotificationPopup(popupName, message));
  };
