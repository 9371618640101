import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/CurrentCash';

const initialValue = {
  cash: 440865,
};
export const cash = handleActions(
  {
    [action.setCash]: (draft, { payload }) => {
      draft.cash = payload.increment;
    },
  },
  initialValue
);
