import React from 'react';
import { useSelector } from 'react-redux';
import { GroupTeamDesktop } from '../../GroupTeam';

import QualifiedWinner from '../../QualifiedWinner';
import {
  qualFirstSet,
  qualSecondSet,
} from '../../../redux/selector/DomesticCup';
import { getGroupWinner } from '../../../service/utils';
import {
  StyledQualifiersGroup,
  StyledRound,
  StyledBorder,
  StyledQualifiersSection,
  StyledWinnerSection,
  StyledFinalGroup,
  StyledQualifiedContainer,
} from './styled';

const QualifiersTable = () => {
  const { homeTeam: firstRoundLeftSetHome, awayTeam: firstRoundLeftSetAway } =
    useSelector(qualFirstSet);
  const { homeTeam: firstRoundRightSetHome, awayTeam: firstRoundRightSetAway } =
    useSelector(qualSecondSet);

  const leftSetWinner = getGroupWinner(
    firstRoundLeftSetHome,
    firstRoundLeftSetAway
  );
  const rightSetWinner = getGroupWinner(
    firstRoundRightSetHome,
    firstRoundRightSetAway
  );

  return (
    <StyledQualifiedContainer>
      <>
        <StyledRound side="left">
          <StyledQualifiersSection>
            <StyledQualifiersGroup>
              <GroupTeamDesktop teamData={firstRoundLeftSetHome} />
              <GroupTeamDesktop teamData={firstRoundLeftSetAway} />
            </StyledQualifiersGroup>
            <StyledBorder large />
          </StyledQualifiersSection>
          <StyledWinnerSection>
            <StyledFinalGroup>
              <QualifiedWinner winner={leftSetWinner} />
            </StyledFinalGroup>
          </StyledWinnerSection>
        </StyledRound>
        <StyledRound side="right">
          <StyledQualifiersSection side="right">
            <StyledQualifiersGroup>
              <GroupTeamDesktop
                side="right"
                teamData={firstRoundRightSetHome}
              />
              <GroupTeamDesktop
                side="right"
                teamData={firstRoundRightSetAway}
              />
            </StyledQualifiersGroup>
            <StyledBorder large side="right" />
          </StyledQualifiersSection>
          <StyledWinnerSection>
            <StyledFinalGroup side="right">
              <QualifiedWinner winner={rightSetWinner} />
            </StyledFinalGroup>
          </StyledWinnerSection>
        </StyledRound>
      </>
    </StyledQualifiedContainer>
  );
};

export default QualifiersTable;
