import styled from 'styled-components';

export const StyledTitle = styled.h1`
  font-size: 24px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
`;

export const StyledSubTitle = styled.h3`
  font-size: 16px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  margin-top: 20px;
  line-height: 30px;
`;

export const StyledParagraph = styled.p`
  li {
    margin-left: 30px;
  }
`;
