import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { StyledRightBlock, StyledCross } from './styled';

import TeamSquad from './pages/TeamSquad';
import TransfersSearch from './pages/TransfersSearch';
import TransfersDeals from './pages/TransfersDeals';

export default React.memo(function RightSideBlock({
  isRightOpen,
  hasTopHeader,
  sidebarOpenRightPanel,
}) {
  return (
    <StyledRightBlock
      className="right-sidebar"
      data-right={isRightOpen}
      hasTopHeader={hasTopHeader}
    >
      <StyledCross onClick={() => sidebarOpenRightPanel(false)} />
      <Switch>
        <Route path="/transfers/deals" component={TransfersDeals} />
        <Route path="/transfers/search-players" component={TransfersSearch} />
        <Route path="/team/squad" component={TeamSquad} />
        <Route
          path="/team/strategy"
          render={() => <TeamSquad isMobileView />}
        />
      </Switch>
    </StyledRightBlock>
  );
});
