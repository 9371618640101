import { createAction } from 'redux-act';
import { getDomesticCup, getCupGamesList } from '../../../api/domesticCup';
import { getDomesticCupCalendar } from '../../../service/utils';

export const domesticCupDataLoading = createAction('Domestic cup data loading');
export const domesticCupTableLoading = createAction(
  'Domestic cup table loading'
);
export const setDomesticCupData = createAction('set domestic cup data');
export const setCalendarDates = createAction('Set calendar dates');

export const domesticCupRequest = () => async (dispatch) => {
  dispatch(domesticCupDataLoading(true));
  const response = await getDomesticCup();
  if (response.status === 200) {
    const data = await response.json();
    dispatch(setDomesticCupData({ ...data }));
    const date = data.startDate;
    const leagueData = [
      {
        round: 'Qualifiers',
        date: getDomesticCupCalendar(date, 0),
      },
      {
        round: 'Quarter Finals - Round 1',
        date: getDomesticCupCalendar(date, 1),
      },
      {
        round: 'Quarter Finals - Round 2',
        date: getDomesticCupCalendar(date, 2),
      },
      {
        round: 'Semi Finals',
        date: getDomesticCupCalendar(date, 3),
      },
      {
        round: 'Final',
        date: getDomesticCupCalendar(date, 4),
      },
    ];
    dispatch(setCalendarDates(leagueData));
  } else {
    console.log('error');
  }
  dispatch(domesticCupDataLoading(false));
};
export const domesticCupLeaguesRequest = (id) => async (dispatch) => {
  dispatch(domesticCupTableLoading(true));
  const response = await getCupGamesList(id);
  if (response.status === 200) {
    const data = await response.json();
    dispatch(setDomesticCupData({ ...data }));
    const date = data.startDate;
    const leagueData = [
      {
        round: 'Qualifiers',
        date: getDomesticCupCalendar(date, 0),
      },
      {
        round: 'Quarter Finals - Round 1',
        date: getDomesticCupCalendar(date, 1),
      },
      {
        round: 'Quarter Finals - Round 2',
        date: getDomesticCupCalendar(date, 2),
      },
      {
        round: 'Semi Finals',
        date: getDomesticCupCalendar(date, 3),
      },
      {
        round: 'Final',
        date: getDomesticCupCalendar(date, 4),
      },
    ];
    dispatch(setCalendarDates(leagueData));
  } else {
    console.log('error');
  }
  dispatch(domesticCupTableLoading(false));
};
