import React from 'react';
import PropTypes from 'prop-types';
import { StyledLiveMatch, StyledOnlineIcon } from './styled';
import { goToDiscordLogin } from '../../service/functions';

const LiveMatch = ({
  discordChannelId,
  title = '',
  theme,
  isAuthenticated,
}) => {
  const goToDiscordChannel = () => {
    if (isAuthenticated) {
      window.open(`https://discord.com/channels/${discordChannelId}`, '_blank');
    } else {
      goToDiscordLogin();
    }
  };

  return (
    <StyledLiveMatch
      theme={theme}
      onClick={() => goToDiscordChannel(discordChannelId)}
    >
      <StyledOnlineIcon /> {title}
    </StyledLiveMatch>
  );
};

LiveMatch.propTypes = {
  discordChannelId: PropTypes.string.isRequired,
  title: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
};
LiveMatch.defaultProps = {
  title: '',
  theme: 'light',
  isAuthenticated: false,
};
export default LiveMatch;
