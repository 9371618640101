import styled, { css } from 'styled-components';

export const StyledQualifiedContainer = styled.div`
  margin: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  position: relative;
`;

export const StyledRound = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
`;

export const StyledQualifiersGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 145px;
  justify-content: space-between;
`;
export const StyledBorder = styled.div`
  position: relative;
  width: 30px;
  height: 68px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  height: 110px;
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    width: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    border-top: 1px solid #e5e5e5;
    right: -20px;
  }
  ${({ side }) => {
    if (side === 'right') {
      return css`
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        border-right: 0;
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          border-top: 1px solid #e5e5e5;
          left: -20px;
        }
      `;
    }
  }}
`;

export const StyledQualifiersSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
`;

export const StyledWinnerSection = styled.div``;
export const StyledFinalGroup = styled.div`
  width: 90px;
  height: 90px;
  margin-left: 30px;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        margin-right: 30px;
      `;
    }
  }}
`;
