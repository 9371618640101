import React from 'react';
import ContentLoader from 'react-content-loader';

const LastResultPreloader = (props) => (
  <ContentLoader
    style={{ width: '100%' }}
    speed={2}
    width={630}
    height={305}
    viewBox="0 0 630 305"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="275" y="74" rx="3" ry="3" width="78" height="7" />
    <rect x="77" y="137" rx="3" ry="3" width="113" height="10" />
    <rect x="272" y="91" rx="3" ry="3" width="84" height="43" />
    <rect x="430" y="137" rx="3" ry="3" width="113" height="10" />
    <rect x="275" y="145" rx="3" ry="3" width="78" height="7" />
    <circle cx="130" cy="101" r="22" />
    <circle cx="488" cy="101" r="22" />
    <rect x="19" y="248" rx="4" ry="4" width="467" height="16" />
    <rect x="15" y="12" rx="4" ry="4" width="74" height="13" />
    <rect x="520" y="12" rx="4" ry="4" width="74" height="13" />
    <rect x="2" y="39" rx="0" ry="0" width="630" height="1" />
    <rect x="2" y="196" rx="0" ry="0" width="630" height="1" />
  </ContentLoader>
);

export default LastResultPreloader;
