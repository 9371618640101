import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import YouthCampFullBg from '../../assets/img/youth-camp-full-bg.png';
import { ReactComponent as StepOneTwo } from '../../assets/img/step-1-to-2.svg';
import { ReactComponent as StepTwoThree } from '../../assets/img/step-2-to-3.svg';

export const StyledHeader = styled.h1`
  z-index: 2;
  color: #000;
`;

export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledContainerLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
`;

export const StyledBG = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  min-width: 1400px;
  min-height: 100%;
  background: transparent;
  background-image: url(${YouthCampFullBg});
  background-color: #fff;
  height: auto;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 991px) {
    background-image: none;
    background-color: #efefef;
    position: absolute;
  }
`;

export const StyledLoginContainer = styled.main`
  z-index: 1;
  display: flex;
  flex-direction: row;
  position: relative;

  width: 100%;
  height: calc(100vh - 50px);

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    background-image: none;
    height: 100%;
  }
`;

export const StyledLeftBlock = styled.section`
  overflow: auto;
  width: 45%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: 0 60px;
  background-color: #ffc80f;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    overflow: hidden;
    max-width: 991px;
    width: 100%;
    height: auto;
    padding: 0px;
  }
  @media screen and (min-width: 991px) and (max-width: 1400px) {
    padding: 0 20px;
  }
  > h1 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #000;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 0px;
    text-transform: none;
    text-align: left;
    width: 100%;
    @media screen and (max-width: 425px) {
      font-weight: 800;
      font-size: 31px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #000;
      margin-bottom: 25px;
    }
    @media screen and (max-width: 1200px) {
      text-align: center;
    }
  }
  > p {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    margin: 17px 30px 0;
    opacity: 0.9;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: normal;
    color: #ffffff;
    z-index: 2;
    @media screen and (max-width: 768px) {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
`;

export const StyledRightBlock = styled.section`
  position: relative;
  width: 55%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 20px 0;
  }
`;

export const StyledLogo = styled.img`
  width: auto;
  max-width: 200px;
  height: 200px;
  object-fit: cover;
  z-index: 2;
`;

export const StyledMobileBg = styled.div`
  background-color: rgba(0, 0, 0, 0.06);
`;

export const StyledText = styled.span`
  max-width: 225px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 19px;
`;

export const StyledCanvasContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 1000px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledFirstSection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  max-width: 800px;
  justify-content: flex-start;
  width: 100%;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    display: flex;
    margin-bottom: 20px;
  }
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 991px) {
    margin-top: 10px;
  }
`;

export const StyledStepOne = styled.img`
  position: relative;
  width: 170px;
  height: auto;
  transform: rotate(5deg);
`;

export const StyledStepOneTwo = styled(StepOneTwo)`
  position: absolute;
  bottom: -30px;
  right: 150px;
  width: 300px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (min-width: 1601px) {
    right: 250px;
  }
`;

export const StyledSecondSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 250px;
  align-items: flex-end;
  max-width: 800px;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    align-items: center;
    height: auto;
    margin-bottom: 20px;
  }
`;

export const StyledSecondSectionContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -50px;
  @media only screen and (max-width: 1200px) {
    position: relative;
    top: 0;
  }
`;

export const StyledStepTwo = styled.img`
  position: relative;
  width: 170px;
  height: auto;
  transform: rotate(4deg);
  @media only screen and (max-width: 991px) {
    transform: rotate(-4deg);
  }
`;

export const StyledStepTwoThree = styled(StepTwoThree)`
  position: absolute;
  bottom: -60px;
  right: 100px;
  width: 200px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (min-width: 1601px) {
    right: 200px;
  }
  @media only screen and (min-width: 1901px) {
    right: 300px;
  }
`;

export const StyledThirdSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: flex-start;
  max-width: 800px;
  justify-content: center;
  height: 250px;
  @media only screen and (max-width: 1200px) {
    align-items: center;
    height: auto;
  }
`;

export const StyledThirdSectionContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  top: -100px;
  /*   left: 250px; */
  @media only screen and (max-width: 991px) {
    top: 0;
  }
  @media only screen and (max-width: 1200px) {
    top: 0;
    margin-bottom: 50px;
  }
`;

export const StyledStepThree = styled.img`
  position: relative;
  width: 170px;
  height: auto;
  transform: rotate(-4deg);
  @media only screen and (max-width: 991px) {
    transform: rotate(4deg);
  }
`;

export const StyledNumberImg = styled.img`
  width: 25px;
  height: 27px;
`;

export const StyledMobileBlock = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    background-color: #1b1b1b;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 250px;
    margin-bottom: 250px;
    padding-top: 35px;
  }
`;
