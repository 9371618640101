import orderBy from 'lodash/orderBy';
import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/SignUp';

const initialValue = {
  selectTeamList: [],
  leagueCode: '',
  isLoading: false,
  error: '',
  chooseTeamResponseCode: null,
  matchesCount: {},
  universesList: [],
  universesListLoading: false,
  waitlistRequestLoading: false,
  waitlistRequestStatus: null,
};

export const signUp = handleActions(
  {
    [action.setTeamList]: (draft, { payload }) => {
      draft.selectTeamList = payload.data;
      draft.leagueCode = payload.code;
      draft.isLoading = false;
      draft.error = '';
    },
    [action.leagueCodeSendLoading]: (draft, { payload }) => {
      draft.isLoading = payload;
    },
    [action.chooseTeamSendLoading]: (draft, { payload }) => {
      draft.isLoading = payload;
    },
    [action.setErrorToSignUp]: (draft, { payload }) => {
      draft.error = payload;
    },
    [action.clearSignUpData]: (draft) => {
      draft.selectTeamList = [];
      draft.leagueCode = '';
      draft.isLoading = false;
      draft.error = '';
    },
    [action.setTeamResponseCode]: (draft, { payload }) => {
      draft.chooseTeamResponseCode = payload;
    },
    [action.getMatchesCount]: (draft, { payload }) => {
      draft.matchesCount = payload;
    },
    [action.universesListLoading]: (draft, { payload }) => {
      draft.universesListLoading = payload;
    },
    [action.getUniversesList]: (draft, { payload }) => {
      draft.universesListLoading = false;
      draft.universesList = orderBy(payload, ['freeTeams'], ['desc']);
    },
    [action.setWaitlistRequestStatus]: (draft, { payload }) => {
      draft.waitlistRequestStatus = payload;
      draft.waitlistRequestLoading = false;
    },
  },
  initialValue
);
