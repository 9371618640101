import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledLeagueCup,
  StyledTextedDiv,
  StyledContainer,
  StyledRound,
  StyledSelect,
} from './styled';
import { StyledInfoIcon, StyledReactTooltip } from '../../globalStyled';
import img from '../../assets/img/league-cup.png';
import { domesticCupLeaguesRequest } from '../../redux/actions/DomesticCup';
import { isLeaguesListLoaded } from '../../redux/selector/League';
import { leaugesOptions } from '../SendMoney/constants';

const LeagueCup = ({ phase, leagueId }) => {
  const [leaguesList, setLeaguesList] = useState([]);

  const dispatch = useDispatch();
  const leagueOptionsList = useSelector(isLeaguesListLoaded);

  useEffect(() => {
    setLeaguesList(leaugesOptions(leagueOptionsList));
  }, [leagueOptionsList]);

  const selectLeagueFromList = (league) => {
    dispatch(domesticCupLeaguesRequest(league));
  };

  const getRoundByPhase = (phaseData) => {
    switch (phaseData) {
      case 0:
        return 'Qualifiers';
      case 1:
        return 'Quarter Finals - Round 1';
      case 2:
        return 'Quarter Finals - Round 2';
      case 3:
        return 'Semi Finals';
      case 4:
        return 'Final';
      default:
        return 'Complete';
    }
  };
  return (
    <StyledContainer>
      <StyledLeagueCup>
        <StyledTextedDiv>
          <p>
            Playing <StyledRound> {getRoundByPhase(phase)}</StyledRound>
          </p>
          <h1>
            League Cup
            <StyledInfoIcon
              iconsize="15"
              data-tip
              clickable="true"
              data-for="league-cup-tooltip"
            />
          </h1>
          <StyledReactTooltip
            id="league-cup-tooltip"
            type="light"
            effect="solid"
            place="bottom"
          >
            THE LEAGUE CUP is our single-elimination tournament. The 10 teams in
            a league play each other over the course of the season to crown the
            cup winner
          </StyledReactTooltip>
        </StyledTextedDiv>
        <img src={img} alt="" />
        {!isEmpty(leaguesList) && (
          <StyledSelect
            defaultValue={leaguesList.find((item) => item.value === leagueId)}
            onChange={(e) => {
              selectLeagueFromList(e.value);
            }}
            isSearchable={false}
            isClearable={false}
            isLoading={!leaguesList}
            options={leaguesList}
            menuPlacement="auto"
            placeholder="Select a team"
            classNamePrefix="react-select"
          />
        )}
      </StyledLeagueCup>
    </StyledContainer>
  );
};

LeagueCup.propTypes = {
  phase: PropTypes.number.isRequired,
  leagueId: PropTypes.string.isRequired,
};

export default React.memo(LeagueCup);
