// TODO move all league calls here

import { headers } from './requestConfig';
import {
  leagueCodeName,
  leaguesList,
  teamsListFromLeague,
  leaguePlayers,
  leagueFixtures,
  fixturesFromLeague,
  exportPlayersDoc,
} from '../service/links';

export const getLeagueCodeName = async () => {
  const response = await fetch(leagueCodeName, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
export const leaguesListRequest = async () => {
  const response = await fetch(leaguesList, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
export const getLeagueTeamsList = async (id) => {
  const response = await fetch(`${teamsListFromLeague}${id}`, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
export const getPlayerInfo = async (id) => {
  const response = await fetch(`${leaguePlayers}${id}`, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const leagueFixturesRequest = async () => {
  const response = await fetch(leagueFixtures, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const fixturesFromLeagueRequest = async (id) => {
  const response = await fetch(`${fixturesFromLeague}${id}`, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const getExportPlayersDocRequest = async () => {
  const response = await fetch(`${exportPlayersDoc}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'text/csv;charset=UTF-8',
    },
    credentials: 'include',
  });
  return response;
};
