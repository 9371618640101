import { createSelector } from 'reselect';

export const getUser = (state) => state.user;

export const isAuth = createSelector(getUser, ({ isAuth }) => isAuth);
export const isAuthAsWaited = createSelector(
  getUser,
  ({ isAuthLikeWithoutTeam }) => isAuthLikeWithoutTeam
);
export const currentUser = createSelector(getUser, ({ email }) => email);
export const showLoader = createSelector(
  getUser,
  ({ loader, isAuth }) => loader
);
export const getMyCurrentTeam = createSelector(
  getUser,
  ({ team_info }) => team_info
);
export const getMyDiscordChannel = createSelector(
  getUser,
  ({ discord }) => discord
);
export const isPremiumUser = createSelector(
  getUser,
  ({ isPremium }) => isPremium
);
// export const isAuth = createSelector(
//   getUser,
//   ({ email, password }) => !!(email && password)
// );

// export const currentCard = (id) =>
//   createSelector(getUser, ({ creditCard }) => creditCard[id]);
