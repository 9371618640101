export const generalTeamRool = {
  '4-4-2': {
    LS: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LS',
        fav_position: 'LS',
        fav_zone: 'OFF',
      },
    ],
    RS: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RS',
        fav_position: 'RS',
        fav_zone: 'OFF',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LM',
        fav_position: 'LM',
        fav_zone: 'MID',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LCM',
        fav_position: 'LCM',
        fav_zone: 'MID',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RCM',
        fav_position: 'RCM',
        fav_zone: 'MID',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RM',
        fav_position: 'RM',
        fav_zone: 'MID',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LB',
        fav_position: 'LB',
        fav_zone: 'MID',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(L)',
        fav_position: 'DC(L)',
        fav_zone: 'DEF',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(R)',
        fav_position: 'DC(R)',
        fav_zone: 'DEF',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RB',
        fav_position: 'RB',
        fav_zone: 'DEF',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'GK',
        fav_position: 'GK',
        fav_zone: 'GK',
      },
    ],
  },
  '4-3-3': {
    LW: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LW',
        fav_position: 'LW',
        fav_zone: 'MID',
      },
    ],
    CF: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'CF',
        fav_position: 'CF',
        fav_zone: 'OFF',
      },
    ],
    RW: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RW',
        fav_position: 'RW',
        fav_zone: 'MID',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LCM',
        fav_position: 'LCM',
        fav_zone: 'MID',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RCM',
        fav_position: 'RCM',
        fav_zone: 'MID',
      },
    ],
    'DM(C)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DM(C)',
        fav_position: 'DM(C)',
        fav_zone: 'MID',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LB',
        fav_position: 'LB',
        fav_zone: 'MID',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(L)',
        fav_position: 'DC(L)',
        fav_zone: 'DEF',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(R)',
        fav_position: 'DC(R)',
        fav_zone: 'DEF',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RB',
        fav_position: 'RB',
        fav_zone: 'DEF',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'GK',
        fav_position: 'GK',
        fav_zone: 'GK',
      },
    ],
  },
  '4-2-3-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'CF',
        fav_position: 'CF',
        fav_zone: 'OFF',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LM',
        fav_position: 'LM',
        fav_zone: 'MID',
      },
    ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'CM',
        fav_position: 'CM',
        fav_zone: 'MID',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RM',
        fav_position: 'RM',
        fav_zone: 'MID',
      },
    ],
    'DM(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DM(L)',
        fav_position: 'DM(L)',
        fav_zone: 'MID',
      },
    ],
    'DM(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DM(R)',
        fav_position: 'DM(R)',
        fav_zone: 'MID',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LB',
        fav_position: 'LB',
        fav_zone: 'MID',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(L)',
        fav_position: 'DC(L)',
        fav_zone: 'DEF',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(R)',
        fav_position: 'DC(R)',
        fav_zone: 'DEF',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RB',
        fav_position: 'RB',
        fav_zone: 'DEF',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'GK',
        fav_position: 'GK',
        fav_zone: 'GK',
      },
    ],
  },
  '4-5-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'CF',
        fav_position: 'CF',
        fav_zone: 'OFF',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LM',
        fav_position: 'LM',
        fav_zone: 'MID',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LCM',
        fav_position: 'LCM',
        fav_zone: 'MID',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RCM',
        fav_position: 'RCM',
        fav_zone: 'MID',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RM',
        fav_position: 'RM',
        fav_zone: 'MID',
      },
    ],
    'DM(C)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DM(C)',
        fav_position: 'DM(C)',
        fav_zone: 'MID',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LB',
        fav_position: 'LB',
        fav_zone: 'MID',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(L)',
        fav_position: 'DC(L)',
        fav_zone: 'DEF',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(R)',
        fav_position: 'DC(R)',
        fav_zone: 'DEF',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RB',
        fav_position: 'RB',
        fav_zone: 'DEF',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'GK',
        fav_position: 'GK',
        fav_zone: 'GK',
      },
    ],
  },
  '3-5-2': {
    LS: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LS',
        fav_position: 'LS',
        fav_zone: 'OFF',
      },
    ],
    RS: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RS',
        fav_position: 'RS',
        fav_zone: 'OFF',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LM',
        fav_position: 'LM',
        fav_zone: 'MID',
      },
    ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'CM',
        fav_position: 'CM',
        fav_zone: 'MID',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RM',
        fav_position: 'RM',
        fav_zone: 'MID',
      },
    ],
    'DM(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DM(L)',
        fav_position: 'DM(L)',
        fav_zone: 'MID',
      },
    ],
    'DM(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DM(R)',
        fav_position: 'DM(R)',
        fav_zone: 'MID',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(L)',
        fav_position: 'DC(L)',
        fav_zone: 'DEF',
      },
    ],
    'DC(C)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(C)',
        fav_position: 'DC(C)',
        fav_zone: 'DEF',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(R)',
        fav_position: 'DC(R)',
        fav_zone: 'DEF',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'GK',
        fav_position: 'GK',
        fav_zone: 'GK',
      },
    ],
  },
  '4-4-1-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'CF',
        fav_position: 'CF',
        fav_zone: 'OFF',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LM',
        fav_position: 'LM',
        fav_zone: 'MID',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LCM',
        fav_position: 'LCM',
        fav_zone: 'MID',
      },
    ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'CM',
        fav_position: 'CM',
        fav_zone: 'MID',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RCM',
        fav_position: 'RCM',
        fav_zone: 'MID',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RM',
        fav_position: 'RM',
        fav_zone: 'MID',
      },
    ],

    LB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'LB',
        fav_position: 'LB',
        fav_zone: 'MID',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(L)',
        fav_position: 'DC(L)',
        fav_zone: 'DEF',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'DC(R)',
        fav_position: 'DC(R)',
        fav_zone: 'DEF',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'RB',
        fav_position: 'RB',
        fav_zone: 'DEF',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
        current_position: 'GK',
        fav_position: 'GK',
        fav_zone: 'GK',
      },
    ],
  },
};
