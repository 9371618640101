import React from 'react';
import ContentLoader from 'react-content-loader';

const LeagueTeamsPreloader = (props) => (
  <ContentLoader
    width={600}
    speed={2}
    height={38}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="127" y="11" rx="3" ry="3" width="140" height="8" />
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
    <rect x="28" y="48" rx="3" ry="3" width="100" height="11" />
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
    <rect x="283" y="11" rx="3" ry="3" width="114" height="8" />
    <rect x="28" y="38" rx="0" ry="0" width="489" height="2" />
    <rect x="32" y="37" rx="0" ry="0" width="540" height="1" />
    <rect x="55" y="11" rx="3" ry="3" width="56" height="8" />
    <rect x="440" y="11" rx="3" ry="3" width="50" height="8" />
    <rect x="15" y="11" rx="3" ry="3" width="30" height="8" />
  </ContentLoader>
);

export default LeagueTeamsPreloader;
