import styled from 'styled-components';
import Select from 'react-select';
import { ReactComponent as ClockIcon } from '../../../assets/img/clock.svg';
import { ReactComponent as Calendar } from '../../../assets/img/calendar.svg';

export const StyledInput = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  width: 100%;
  height: 35px;
  position: relative;

  border-radius: 2px;
  border: solid 1px #d1d1d1;
  background-color: #fafafa;
  color: #191919;
  font-size: 14px;
  padding: 12px;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  .react-select__control {
    height: 35px;
    min-height: 35px;
    border-radius: 2px;
    color: #4c4c4c;
    background-color: #fafafa;
    border: solid 1px #d1d1d1;
    &--is-focused,
    &--is-selected {
      background-color: #fafafa;
      color: #191919;
    }
  }
  .react-select__single-value {
    font-size: 14px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    color: #191919;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    font-size: 14px;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__indicator {
    svg {
      display: none;
    }
  }
`;
export const StyledClock = styled(ClockIcon)`
  width: 16px;
  height: 16px;
  right: 8px;
  top: 9px;
  position: absolute;
`;

export const StyledDateSelectContainer = styled.div`
  position: relative;
  width: 49%;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const CalendarIcon = styled(Calendar)`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 8px;
  top: 9px;
`;
export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledCalendarContainer = styled.div`
  width: 49%;
  height: 35px;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
  .loan-date {
    z-index: 1;
    min-width: 280px;
    min-height: 280px;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.05);
    border: solid 0.5px rgba(30, 36, 71, 0.1);
  }
  .react-calendar__navigation {
    margin-bottom: 0;
  }
  .react-calendar__month-view__weekdays {
    margin-bottom: 10px;
  }
  .react-calendar__navigation__label {
    pointer-events: none;
    font-family: 'Gilroy', sans-serif;
    opacity: 0.8;
    font-size: 17px;
    font-weight: 700;
  }
  .loan-tile {
    background-color: #fff;
    width: 36px;
    height: 28px;
    padding: 0px 5px;
    margin: 5px 0;
    &:disabled {
      opacity: 0.15;
    }
    &:hover:not(:disabled) {
      background-color: #fff;
      abbr {
        background-color: #0e4afb;
        color: #fff;
      }
    }
    abbr {
      color: #001122;
      border-radius: 100%;
      width: 28px;
      height: 28px;
      padding: 0;
      margin: 0px 0;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .react-calendar__tile--active {
    abbr {
      background-color: #0e4afb;
      color: #fff;
    }
  }
  .react-calendar__month-view__weekdays__weekday {
    border-bottom: 1px solid #eee;
    abbr {
      font-weight: normal;
      color: #001122;
      font-size: 12px;
      text-decoration: none;
      text-transform: none;
      opacity: 0.4;
    }
  }
  .react-calendar__tile--now {
    &.react-calendar__tile--active {
      abbr {
        background-color: #fff;
        color: #0e4afb;
      }
    }

    abbr {
      color: #0e4afb;

      &:hover {
        color: #0e4afb;
        background-color: #fff;
      }
    }
  }
`;
