import React from 'react';
import PropTypes from 'prop-types';
import {
  SwitchInput,
  SwitchLabel,
  SwitchButton,
  StyledToggleContainer,
} from './styled';

const SwitchToggle = ({
  id,
  toggled,
  onChange,
  isDisabled,
  viewMode = false,
  isOnAndOffLabelVisible = false,
}) => (
  <StyledToggleContainer>
    <SwitchInput
      className="switch-checkbox"
      id={id}
      type="checkbox"
      checked={toggled}
      onChange={onChange}
      disabled={isDisabled}
      viewMode={viewMode}
    />
    <SwitchLabel
      viewMode={viewMode}
      disabled={isDisabled}
      className="switch-label"
      htmlFor={id}
      toggled={toggled}
      isOnAndOffLabelVisible={isOnAndOffLabelVisible}
    >
      <SwitchButton className="switch-button" />
    </SwitchLabel>
  </StyledToggleContainer>
);

SwitchToggle.propTypes = {
  id: PropTypes.string.isRequired,
  toggled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
SwitchToggle.defaultProps = {
  isDisabled: false,
};
export default SwitchToggle;
