import styled from 'styled-components';
import { StyledButton } from '../../../globalStyled';
import { StyledPopUpBlock } from '../styled';

export const StyledPopupButton = styled(StyledButton)`
  @media only screen and (max-width: 991px) {
    width: 140px;
  }
`;

export const StyledPopupBlock = styled(StyledPopUpBlock)`
  @media only screen and (max-width: 991px) {
    padding: 20px;
  }
`;
