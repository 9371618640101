import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import {
  StyledBalanceWrapper,
  StyledBalanceContainer,
  StyledCashContainer,
  StyledArrowGreen,
  StyledBalanceLabel,
  StyledArrowRed,
  StyledCash,
  StyledBalanceAmount,
  StyledCashBlock,
  StyledPreloaderContainer,
} from './styled';

import FallbackContentAnimation from '../FallbackContentAnimation';
import {
  FinanceCashPreloader,
  FinanceCashInOutPreloader,
  HomeSmallStat,
} from '../ContentPreloaders';

const FinanceBalance = ({
  current_cash,
  total_earnings,
  total_spendings,
  isLoading,
}) => (
  <StyledBalanceWrapper>
    <FallbackContentAnimation
      loading={isLoading}
      fallback={
        <StyledPreloaderContainer isMobile={isMobile}>
          {isMobile ? (
            <HomeSmallStat style={{ width: '100%' }} />
          ) : (
            <FinanceCashPreloader style={{ width: '100%' }} />
          )}
        </StyledPreloaderContainer>
      }
    >
      <StyledBalanceContainer>
        <StyledBalanceLabel>Balance</StyledBalanceLabel>
        <StyledBalanceAmount>${current_cash}</StyledBalanceAmount>
      </StyledBalanceContainer>
    </FallbackContentAnimation>

    <FallbackContentAnimation
      loading={isLoading}
      fallback={
        <StyledPreloaderContainer isMobile={isMobile}>
          {isMobile ? (
            <>
              <HomeSmallStat style={{ width: '100%' }} />
              <HomeSmallStat style={{ width: '100%' }} />
            </>
          ) : (
            <FinanceCashInOutPreloader style={{ width: '100%' }} />
          )}
        </StyledPreloaderContainer>
      }
    >
      <StyledCashContainer>
        <StyledCashBlock>
          <StyledBalanceLabel isIn>
            <StyledArrowGreen />
            Cash In
          </StyledBalanceLabel>
          <StyledCash>${total_earnings}</StyledCash>
        </StyledCashBlock>
        <StyledCashBlock>
          <StyledBalanceLabel isOut>
            <StyledArrowRed />
            Cash Out
          </StyledBalanceLabel>
          <StyledCash>${total_spendings}</StyledCash>
        </StyledCashBlock>
      </StyledCashContainer>
    </FallbackContentAnimation>
  </StyledBalanceWrapper>
);

FinanceBalance.propTypes = {
  current_cash: PropTypes.string.isRequired,
  total_earnings: PropTypes.string.isRequired,
  total_spendings: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
FinanceBalance.defaultProps = {
  isLoading: true,
};

export default FinanceBalance;
