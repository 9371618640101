import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  StyledContainer,
  StyledBlocksContainer,
  StyledReportLabel,
  StyledInfoIcon,
  StyledReactTooltip,
  StyledTooltipText,
} from './styled';
import Learnings from './components/Learnings';
import { learningFromLastMatch } from '../../redux/selector/Home';

const TipsInNotif = () => {
  const learnings = useSelector(learningFromLastMatch);
  return (
    <StyledContainer>
      {!isEmpty(learnings) ? (
        <>
          <StyledReportLabel>
            Match Report
            <StyledInfoIcon
              iconsize="10"
              data-tip
              clickable="true"
              data-for="match-learnings"
            />
            <StyledReactTooltip
              id="match-learnings"
              type="light"
              effect="solid"
              place="bottom"
            >
              <StyledTooltipText>
                Below is how our team compared to our last opponent.
              </StyledTooltipText>
            </StyledReactTooltip>
          </StyledReportLabel>
          <StyledBlocksContainer>
            {!isEmpty(learnings) && <Learnings learnings={learnings} />}
          </StyledBlocksContainer>
        </>
      ) : (
        <>
          <h4>
            No new learnings at this point. Please come back after our first
            match
          </h4>
        </>
      )}
    </StyledContainer>
  );
};

export default TipsInNotif;
