import styled from 'styled-components';
import { StyledWrapper } from '../styled';

export const StyledNewWrapper = styled(StyledWrapper)`
  flex-direction: column;
  .team-name {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
    @media (min-width: 48em) {
      margin: 0 25px;
      margin-bottom: 24px;
    }
    @media screen and (max-width: 768px) {
      margin: 0 0 24px 0;
    }
    @media screen and (max-width: 426px) {
      margin: 15px 0 24px 0;
    }
    @media screen and (min-width: 769px) {
      margin: 0 20px 25px;
    }
    > img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 15px;
    }
    > h1 {
      margin: 0;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 28.833px;
      line-height: 41px;
      width: 100%;
      text-transform: none;

      width: auto;
      @media screen and (max-width: 426px) {
        margin: 0;
      }
      @media screen and (max-width: 768px) {
        margin: 0;
      }
      @media screen and (min-width: 769px) {
      }
    }
  }
`;

export const StyledBlockContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;

export const StyledLeague = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 4px;
  align-items: center;
  @media only screen and (max-width: 1199px) {
    display: none;
  }
`;

export const StyledGroupMatch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
`;

export const StyledMobileLeague = styled.div`
  display: none;
  @media only screen and (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
  }
`;

export const StyledPlayingSectionPreloader = styled.div`
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 25px;
`;

export const StyledSectionPreloader = styled(StyledPlayingSectionPreloader)`
  margin-left: 25px;
`;
