import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { StyledContainer } from './styled';
import SearchPlayerContainer from './components/SearchPlayersContainer';
import PlayerDealsContainer from './components/PlayerDealsContainer/index';
import { teamGetMyTeam } from '../../redux/actions/Team';
import { getMyAllSquad } from '../../redux/selector/Team';

export default function Transfers() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const team = useSelector(getMyAllSquad);
  useEffect(() => {
    if (isEmpty(team)) {
      dispatch(teamGetMyTeam());
    }
  }, [dispatch, team]);

  useEffect(() => {
    if (location.pathname === '/transfers') {
      history.push('/transfers/deals');
    }
  }, [history, location.pathname]);

  return (
    <StyledContainer>
      <Switch>
        <Route
          path="/transfers/search-players"
          component={SearchPlayerContainer}
        />
        <Route path="/transfers/deals" component={PlayerDealsContainer} />
      </Switch>
    </StyledContainer>
  );
}
