import { scoutRequest, freeAgentRequest } from '../service/links';
import { headers } from './requestConfig';

export const setScoutRequestForPlayer = async (playerID) => {
  const response1 = await fetch(scoutRequest, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      player: playerID,
    }),
  });
  return response1;
};

export const sendSignFreeAgentRequest = async (playerID) => {
  const response = await fetch(freeAgentRequest, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      playerID,
    }),
  });
  return response;
};
