import styled from 'styled-components';
import { StyledButton } from '../../globalStyled';

export const BidSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledBidButton = styled(StyledButton)`
  padding: 7px 10px;
  min-width: 30px;
`;

export const StyledInput = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  width: 80px;
  position: relative;
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  background-color: #fafafa;
  font-family: Gilroy;
  font-size: 45px;
  font-weight: bold;
  font-stretch: normal;
  padding: 13px;
  margin: 0 35px;
  text-align: center;
`;
