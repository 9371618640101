import React from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { ReactComponent as WelcomeImage } from '../../../assets/img/welcome-slider/Welcome.svg';
import { ReactComponent as DiscordImage } from '../../../assets/img/welcome-slider/Discord.svg';
import { ReactComponent as PortalImage } from '../../../assets/img/welcome-slider/Portal.svg';
import { ReactComponent as SeasonImage } from '../../../assets/img/welcome-slider/Season.svg';
import { ReactComponent as CommunityImage } from '../../../assets/img/welcome-slider/Community.svg';
import { ReactComponent as DailyMatchImage } from '../../../assets/img/welcome-slider/Clock.svg';
import { ReactComponent as Arrow } from '../../../assets/img/welcome-slider/arrow.svg';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpContainer,
  StyledButtonContainer,
} from '../styled';
import {
  StyledWrapper,
  StyledSlider,
  StyledSlide,
  StyledSlideHeader,
  StyledSlideParagraph,
  StyledWelcomePopUp,
  StyledSlideTitle,
  StyledImageContainer,
} from './styled';

import { hidePopupWindow } from '../../../redux/actions/Popups';

const WelcomePopup = ({ popupName }) => {
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const dispatch = useDispatch();

  const sliderRef = React.useRef();
  const hidePopup = () => {
    localStorage.setItem('hideWelcomePopup', true);
    dispatch(hidePopupWindow(popupName));
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => {
      setSliderIndex(index);
    },
  };

  return (
    <StyledPopUp>
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <StyledWelcomePopUp maxWidth={600}>
        <StyledPopUpContainer>
          <StyledWrapper>
            <StyledSlider>
              <div className="container">
                <Slider {...settings} ref={sliderRef}>
                  <StyledSlide>
                    <StyledSlideHeader>Welcome</StyledSlideHeader>
                    <StyledImageContainer>
                      <WelcomeImage />
                    </StyledImageContainer>
                    <StyledSlideTitle>
                      Not your typical football game
                    </StyledSlideTitle>
                    <StyledSlideParagraph>
                      The Open League is a different breed of sports management
                      game which uses a club portal (where you are now) in
                      conjunction with Discord, a popular communications
                      platform
                    </StyledSlideParagraph>
                  </StyledSlide>
                  <StyledSlide>
                    <StyledSlideHeader>Portal</StyledSlideHeader>
                    <StyledImageContainer>
                      <PortalImage />
                    </StyledImageContainer>
                    <StyledSlideTitle>
                      The portal is where you manage your team
                    </StyledSlideTitle>
                    <StyledSlideParagraph>
                      Come to the portal to search for players, send offers to
                      buy players from other clubs, and manage your team
                      strategy for your next match
                    </StyledSlideParagraph>
                  </StyledSlide>
                  <StyledSlide>
                    <StyledSlideHeader>Discord</StyledSlideHeader>
                    <StyledImageContainer>
                      <DiscordImage />
                    </StyledImageContainer>
                    <StyledSlideTitle>
                      Discord is where the fun lives
                    </StyledSlideTitle>
                    <StyledSlideParagraph>
                      Your daily matches are live-streamed in your Discord
                      channel. It is also where you’ll find clubs to practice
                      with and get in touch with other club managers to
                      negotiate player transfers
                    </StyledSlideParagraph>
                  </StyledSlide>
                  <StyledSlide>
                    <StyledSlideHeader>Daily matches</StyledSlideHeader>
                    <StyledImageContainer>
                      <DailyMatchImage />
                    </StyledImageContainer>
                    <StyledSlideTitle>
                      League matches every night at 7pm
                    </StyledSlideTitle>
                    <StyledSlideParagraph>
                      Don’t forget to set your starting 11 and team tactics
                      every day. Real-time, play-by-play text updates of your
                      matches are streamed in your Discord channel.
                    </StyledSlideParagraph>
                  </StyledSlide>
                  <StyledSlide>
                    <StyledSlideHeader>Season and Off-season</StyledSlideHeader>
                    <StyledImageContainer>
                      <SeasonImage />
                    </StyledImageContainer>
                    <StyledSlideTitle>A season lasts 3 weeks</StyledSlideTitle>
                    <StyledSlideParagraph>
                      Top three clubs are promoted. Bottom three are relegated.
                      During the off-season weekend, clubs participate in youth
                      camps to find the next legendary player
                    </StyledSlideParagraph>
                  </StyledSlide>
                  <StyledSlide>
                    <StyledSlideHeader>Community</StyledSlideHeader>
                    <StyledImageContainer>
                      <CommunityImage />
                    </StyledImageContainer>
                    <StyledSlideTitle>Let’s make friends</StyledSlideTitle>
                    <StyledSlideParagraph>
                      We are proud of our respectful, inclusive, and kind
                      community. Threat your fellow managers as you would want
                      to be treated.
                    </StyledSlideParagraph>
                  </StyledSlide>
                </Slider>
              </div>
            </StyledSlider>
          </StyledWrapper>

          <StyledButtonContainer>
            {sliderIndex === 5 ? (
              <StyledButton
                customBgColor="#fff"
                customFontColor="#000"
                onClick={() => hidePopup()}
              >
                Let's play!
              </StyledButton>
            ) : (
              <StyledButton
                customBgColor="#191919"
                customFontColor="#fff"
                onClick={() => sliderRef.current.slickNext()}
              >
                <Arrow />
              </StyledButton>
            )}
          </StyledButtonContainer>
        </StyledPopUpContainer>
      </StyledWelcomePopUp>
    </StyledPopUp>
  );
};

export default React.memo(WelcomePopup);
