import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  margin-top: 25px;
  width: 49%;
  background-color: #fff;
  border-radius: 4px;
  @media screen and (max-width: 1199px) {
    width: 100%;
    margin-top: 0px;
  }
  > h4 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #949494;
    margin-bottom: 30px;
  }
`;

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px;
`;

export const StyledSeason = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(25, 25, 25, 0.5);
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.28px;
`;

export const StyledMatchResult = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin: 8px 0;
  align-items: center;
  padding: 6px 0px;

  > span {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    color: #191919;

    @media screen and (max-width: 425px) {
      font-size: 13px;
      line-height: 22px;
    }
  }
`;

export const StyledButton = styled(Link)`
  text-decoration: none;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: rgba(25, 25, 25, 0.5);
  @media screen and (max-width: 1199px) {
    padding: 0;
  }
`;

export const StyledScore = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  @media screen and (max-width: 425px) {
    justify-content: flex-start;
  }
  > h6 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    color: #191919;
    text-align: center;
    padding: 10px 14px;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    line-height: 0.8;
    letter-spacing: 0.91px;

    @media screen and (max-width: 1199px) {
      font-size: 13px;
      padding: 6px 8px;
    }
  }
  > span {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.222px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #000000;
    @media screen and (max-width: 1199px) {
      font-size: 13px;
      line-height: 23px;
    }
  }
`;

export const StyledTeamsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  /* span {
    width: 33%;
    text-align: center;
  } */
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding: 0px 15px;
  text-transform: uppercase;
`;

export const StyledDate = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Gilroy', sans-serif;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const StyledCenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTeam = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  text-align: center;
  span {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Gilroy', sans-serif;
    margin-top: 10px;
    @media only screen and (max-width: 1199px) {
      font-size: 11px;
    }
  }
  img {
    max-width: 50px;
    height: auto;
  }
`;
