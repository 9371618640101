import styled, { css } from 'styled-components';
import { ReactComponent as StyledLeftArrowIcon } from '../../../../../../../../assets/img/go-arrow-left.svg';

export const StyledLeftArrow = styled(StyledLeftArrowIcon)``;

export const StyledContainer = styled.section`
  width: 380px;
  z-index: 1;
  ${({ isLoad }) => {
    if (isLoad) {
      return css`
        max-height: 750px;
        background-color: #fff;
      `;
    }
  }}
`;

export const StyledMobileSidebar = styled.div`
  background: ${({ customBg }) => customBg || '#fff'};
  padding-top: 0px;
  padding-bottom: 10px !important;
  height: calc(100% - 50px);
  width: 0px;
  position: fixed;
  z-index: 11;
  top: 50px;
  left: 0px;
  transition: all 0.2s ease 0s;
  padding-bottom: 10px !important;

  ${({ noPadding }) => {
    if (noPadding) {
      return css`
        padding-top: 0;
      `;
    }
  }}
  h3 {
    font-size: 15px;
    background: #fff;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    @media only screen and (max-width: 768px) {
      padding: 15px 10px;
      height: 45px;
    }
    svg {
      margin-right: 10px;
    }
    @media screen and (max-width: 1199px) {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 3;
    }
  }
  ${({ isOpened }) => {
    if (isOpened) {
      return css`
        width: 100%;
      `;
    }
  }}
`;
