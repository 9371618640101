import React from 'react';
import usePortal from 'react-useportal';
import { isMobile } from 'react-device-detect';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { NotificationPopup, SelectLeaguePopup } from '../../components/Popup';
import Matchday from '../../components/Matchday';
import SignUpUniverses from '../../components/SignUpUniverses';
import SignUpForm from '../../components/SignUpForm';

import {
  StyledContainer,
  StyledLoginContainer,
  StyledLeftBlock,
  StyledReactTooltip,
  StyledInfoIcon,
  StyledHeader,
  StyledLeftHeaderSection,
  StyledRightHeaderSection,
  StyledTOLLogo,
  StyledTOLMobileLogo,
  StyledSignUpContainer,
  StyledSubheader,
  StyledMobileSubheader,
  StyledBackButton,
  StyledMobileBackButton,
  StyledTitle,
  StyledFilterTitle,
  StyledLeftWhiteArrow,
  StyledLeagueCode,
} from './styled';
import { StyledLoader } from '../styled';

import { getPopupByName } from '../../redux/selector/Popups';
import { getLoadingUniversesData } from '../../redux/selector/SignUp';

import { getUniverses } from '../../redux/actions/SignUp';
import { showPopupWindow } from '../../redux/actions/Popups';
import { POPUPS } from '../../service/constants';

const SignUpPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationPopupData = useSelector(
    getPopupByName(POPUPS.NO_TEAM_POPUP)
  );
  const selectLeaguePopupData = useSelector(
    getPopupByName(POPUPS.SELECT_LEAGUE_CODE_POPUP)
  );
  const { universesList, universesListLoading } = useSelector(
    getLoadingUniversesData
  );

  const showLeagueCodePopup = () => {
    dispatch(
      showPopupWindow(
        POPUPS.SELECT_LEAGUE_CODE_POPUP,
        POPUPS.SELECT_LEAGUE_CODE_POPUP
      )
    );
  };

  React.useEffect(() => {
    if (isEmpty(universesList)) {
      dispatch(getUniverses());
    }
  }, [dispatch, universesList]);
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });
  const redirectToPage = () => {
    history.push('/home');
  };
  const universesListToExclude = ['61284d3c5e4423001f8302cf'];

  const filteredUniverses = universesList.filter(
    (universe) => !universesListToExclude.includes(universe._id)
  );
  return (
    <>
      {!isEmpty(notificationPopupData) && (
        <Portal>
          <NotificationPopup
            popupName={POPUPS.NO_TEAM_POPUP}
            closeCallback={() => {}}
          />
        </Portal>
      )}
      {!isEmpty(selectLeaguePopupData) && (
        <Portal>
          <SelectLeaguePopup popupName={POPUPS.SELECT_LEAGUE_CODE_POPUP} />
        </Portal>
      )}
      <StyledLoginContainer>
        <StyledHeader>
          {' '}
          <StyledLeftHeaderSection>
            {isMobile ? <StyledTOLMobileLogo /> : <StyledTOLLogo />}
          </StyledLeftHeaderSection>
          <StyledRightHeaderSection>
            <Matchday isHeaderBased />
          </StyledRightHeaderSection>
        </StyledHeader>
        {universesListLoading ? (
          <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
        ) : (
          <StyledContainer>
            {isMobile ? (
              <>
                <StyledMobileSubheader>
                  <StyledMobileBackButton onClick={redirectToPage}>
                    <StyledLeftWhiteArrow />
                    Homepage
                  </StyledMobileBackButton>
                  <StyledLeagueCode onClick={showLeagueCodePopup}>
                    Have league code?
                  </StyledLeagueCode>
                </StyledMobileSubheader>
                <StyledFilterTitle>Select a server</StyledFilterTitle>
              </>
            ) : (
              <StyledSubheader>
                <StyledBackButton onClick={redirectToPage}>
                  <StyledLeftWhiteArrow />
                  Homepage
                </StyledBackButton>

                <StyledTitle>Select a league</StyledTitle>
              </StyledSubheader>
            )}
            <StyledLeftBlock>
              {!isEmpty(filteredUniverses) && (
                <SignUpUniverses universes={filteredUniverses} />
              )}
              {!isMobile && (
                <StyledSignUpContainer>
                  <h3>
                    League Code{' '}
                    <StyledInfoIcon
                      iconsize="10"
                      data-tip
                      clickable
                      data-for="league-code"
                    />
                  </h3>

                  <StyledReactTooltip
                    id="league-code"
                    type="light"
                    effect="solid"
                    place="bottom"
                  >
                    If you are joining your friends, ask them for their league
                    code to find them faster.
                  </StyledReactTooltip>
                  <SignUpForm />
                </StyledSignUpContainer>
              )}
            </StyledLeftBlock>
          </StyledContainer>
        )}
      </StyledLoginContainer>
    </>
  );
};

export default SignUpPage;
