import React from 'react';

import { StyledBidButton, StyledInput, BidSelectWrapper } from './styled';

const BidSelect = ({
  callback = () => {},
  minLimitValue,
  maxLimitValue,
  className = '',
}) => {
  const [bidNumber, changeBid] = React.useState(1);

  const decrementBid = () => {
    changeBid((bid) => bid - 1);
    callback(bidNumber - 1);
  };
  const incrementBid = () => {
    changeBid((bid) => bid + 1);
    callback(bidNumber + 1);
  };
  const setOnBlurBid = (e) => {
    const val = parseInt(e.target.value);
    if (!val) {
      changeBid(1);
      callback(1);
    }
  };

  const setBid = (e) => {
    const val = parseInt(e.target.value) || 1;
    if (val >= maxLimitValue) {
      changeBid(maxLimitValue);
      callback(maxLimitValue);
    } else if (val <= minLimitValue) {
      changeBid(minLimitValue);
      callback(minLimitValue);
    } else {
      changeBid(val);
      callback(val);
    }
  };
  return (
    <BidSelectWrapper className={className}>
      <StyledBidButton
        primary
        disabled={bidNumber <= minLimitValue || !bidNumber}
        onClick={() => {
          decrementBid();
        }}
        type="button"
      >
        -
      </StyledBidButton>
      <StyledInput
        type="number"
        value={bidNumber}
        onBlur={setOnBlurBid}
        onChange={setBid}
        min={minLimitValue}
        max={maxLimitValue}
      />
      <StyledBidButton
        disabled={bidNumber >= maxLimitValue}
        primary
        onClick={() => {
          incrementBid();
        }}
        type="button"
      >
        +
      </StyledBidButton>
    </BidSelectWrapper>
  );
};
export default BidSelect;
