import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import moment from 'moment-timezone';
import { NextMatchPreloader, MobileNextMatchPreloader } from '../ContentPreloaders';
import FallbackContentAnimation from '../FallbackContentAnimation';
import {
  StyledNextMatch,
  StyledTextedDiv,
  StyledContainer,
  StyledLink,
  StyledLeagueLabel,
  StyledButtonsContainer,
  StyledFallbackContainer,
  StyledLockers,
  StyledNextMatchBg,
  StyledInGame,
  StyledNewLabel,
  StyledTeamShirtContainer,
} from './styled';
import LiveMatch from '../LiveMatch';
import nextMatchImg from '../../assets/img/next-match-bg.png';
import nextMatchMobile from '../../assets/img/next-match-mobile.png';
import lockers from '../../assets/img/lockers.png';
import TeamShirt from '../TeamShirt';
import { isAuth, isPremiumUser } from '../../redux/selector/User';
import { isOpponentPremium } from '../../redux/selector/Home';

const NextMatch = ({ data, isLoading, teamKit, opponentKit }) => {
  const isCurrentPremium = useSelector(isPremiumUser);
  const isOpponentUserPremium = useSelector(isOpponentPremium);
  const isAuthenticated = useSelector(isAuth);
  const isCurrentMatchAvailable = !isEmpty(data.matchRunning);
  const matchData = isCurrentMatchAvailable ? 'matchRunning' : 'next_match';
  const noNextMatch = isEmpty(data.next_match);
  const homeTeam = data?.[matchData]?.home_team?.name;
  const homeTeamId = data?.[matchData]?.home_team?._id;
  const awayTeam = data?.[matchData]?.away_team?.name;
  const awayTeamId = data?.[matchData]?.away_team?._id;
  const matchDate = data?.[matchData]?.match_date;
  const isDomesticCup = data?.[matchData]?.domestic_cup;
  const dateAtUTC = moment.utc();
  const tzOffset = data?.timezone ? moment.tz.zone(data.timezone).utcOffset(dateAtUTC) : '';
  const formattedMatchDate = moment(matchDate).utcOffset('-0000');
  const timezoneDateRelatedToUTC = `${formattedMatchDate.subtract(tzOffset / 60, 'hours').format('MMM D')} ${isDomesticCup ? '12:00pm' : '7:00PM'
    }`;
  const isCurrentUserHome = data.teamId === homeTeamId;
  const isCurrentUserAway = data.teamId === awayTeamId;
  const isOpponentUserHome = data.teamId !== homeTeamId;
  const isOpponentUserAway = data.teamId !== awayTeamId;
  const isCurrentUserHomePremium = isCurrentUserHome && isCurrentPremium;
  const isCurrentUserAwayPremium = isCurrentUserAway && isCurrentPremium;
  const isOpponentUserHomePremium = isOpponentUserHome && isOpponentUserPremium;
  const isOpponentUserAwayPremium = isOpponentUserAway && isOpponentUserPremium;

  const isCurrentHomeUserHasKit = isCurrentUserHome && !isEmpty(teamKit);
  const isCurrentAwayUserHasKit = isCurrentUserAway && !isEmpty(teamKit);
  const isOpponentHomeUserHasKit = isOpponentUserHome && !isEmpty(opponentKit);
  const isOpponentAwayUserHasKit = isOpponentUserAway && !isEmpty(opponentKit);
  const isHomeJerseyPremium = isCurrentUserHomePremium || isOpponentUserHomePremium;
  const isAwayJerseyPremium = isCurrentUserAwayPremium || isOpponentUserAwayPremium;

  const leftKitStyle = () => {
    if (!isMobile) {
      return {
        position: 'absolute',
        top: '56px',
        right: '175px',
        height: '100%',
        maxHeight: '120px',
        width: 'auto',
        zIndex: 1,
      };
    }

    if (isMobile) {
      return {
        position: 'relative',
        width: '85px',
        height: 'auto',
        maxHeight: '95px',
        bottom: '0px',
        top: '0px',
        left: '0px',
        right: '0px',
        margin: 0,
        marginTop: '10px',
      };
    }
  };
  const rightKitStyle = () => {
    if (!isMobile) {
      return {
        position: 'absolute',
        top: '56px',
        right: '20px',
        height: '100%',
        maxHeight: '120px',
        width: 'auto',
        zIndex: 1,
      };
    }
    if (isMobile) {
      return {
        position: 'relative',
        bottom: '0px',
        top: '0px',
        left: '0px',
        right: '0px',
        width: '85px',
        height: 'auto',
        maxHeight: '95px',
        margin: 0,
        marginTop: '10px',
      };
    }
  };
  return (
    <StyledContainer>
      <FallbackContentAnimation
        loading={isLoading}
        fallback={
          <StyledFallbackContainer>
            <StyledNextMatchBg src={nextMatchImg} alt="next-match-bg" />
            {!isMobile && <StyledLockers src={lockers} alt="lockers" />}
            {isMobile ? <MobileNextMatchPreloader /> : <NextMatchPreloader />}
            <StyledTeamShirtContainer>
              <TeamShirt
                style={
                  !isMobile
                    ? {
                      position: 'absolute',
                      top: '56px',
                      right: '175px',
                      height: '100%',
                      maxHeight: '120px',
                      width: 'auto',
                    }
                    : {
                      position: 'relative',
                      width: '100px',
                      height: 'auto',
                      maxHeight: '200px',
                      bottom: '0px',
                      top: '0px',
                      left: '0px',
                      right: '0px',
                      margin: 0,
                    }
                }
              />
              <TeamShirt
                style={
                  !isMobile
                    ? {
                      position: 'absolute',
                      top: '56px',
                      right: '20px',
                      height: '100%',
                      maxHeight: '120px',
                      width: 'auto',
                    }
                    : {
                      position: 'relative',
                      bottom: '0px',
                      top: '0px',
                      left: '0px',
                      right: '0px',
                      width: '100px',
                      height: 'auto',
                      maxHeight: '200px',
                      margin: 0,
                    }
                }
              />
            </StyledTeamShirtContainer>
          </StyledFallbackContainer>
        }
      >
        <StyledNextMatch className="next-match-section">
          <>
            <StyledTextedDiv>
              {!isMobile && (
                <StyledLeagueLabel>
                  {noNextMatch ? 'Off Season' : `${isDomesticCup ? 'Cup' : 'League'} Match`}
                </StyledLeagueLabel>
              )}

              {noNextMatch ? (
                <>
                  <p>No matches yet</p>
                  <h1>
                    Next match <br />
                    <span>starts </span>
                    Next Monday
                  </h1>{' '}
                </>
              ) : (
                <>
                  <p>{isCurrentMatchAvailable ? 'Current Match' : `Next Match - ${timezoneDateRelatedToUTC}`}</p>
                  <h1>
                    {homeTeam} <br />
                    <span>vs </span>
                    {awayTeam}
                  </h1>{' '}
                </>
              )}
            </StyledTextedDiv>
            <StyledTeamShirtContainer>
              <TeamShirt
                isPremium={isHomeJerseyPremium}
                style={leftKitStyle()}
                team={homeTeam}
                teamKit={isCurrentHomeUserHasKit ? teamKit?.pic : isOpponentHomeUserHasKit ? opponentKit?.pic : null}
              />
              <TeamShirt
                isPremium={isAwayJerseyPremium}
                style={rightKitStyle()}
                team={awayTeam}
                teamKit={isCurrentAwayUserHasKit ? teamKit?.pic : isOpponentAwayUserHasKit ? opponentKit?.pic : null}
              />
            </StyledTeamShirtContainer>
            {noNextMatch ? null : (
              <StyledButtonsContainer>
                {isCurrentMatchAvailable ? (
                  <>
                    <StyledInGame to="/team/strategy">
                      <StyledNewLabel>NEW!</StyledNewLabel>
                      In-game strategy
                    </StyledInGame>

                    <LiveMatch
                      theme="dark"
                      discordChannelId={data.discordChannel}
                      title="Watch Live on Discord"
                      isAuthenticated={isAuthenticated}
                    />
                  </>
                ) : (
                  <StyledLink isMobile={isMobile} to="/team/strategy">
                    Set Strategy
                  </StyledLink>
                )}
              </StyledButtonsContainer>
            )}

            {isMobile ? (
              <StyledNextMatchBg src={nextMatchMobile} alt="next-match-bg" />
            ) : (
              <StyledNextMatchBg src={nextMatchImg} alt="next-match-bg" />
            )}
            {!isMobile && <StyledLockers src={lockers} alt="lockers" />}
          </>
        </StyledNextMatch>
      </FallbackContentAnimation>
    </StyledContainer>
  );
};

NextMatch.propTypes = {
  data: PropTypes.object,
};

NextMatch.defaultProps = {
  data: null,
};

export default React.memo(NextMatch);
