import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import ReadMore from '../../../../../ReadMore';
import TransactionIcon from '../../../../../TransactionIcon';
import {
  StyledTeamSquadContainer,
  StyledCard,
  StyledMatch,
  StyledContext,
  StyledTransaction,
  StyledLoader,
  StyledToggleView,
  StyledToggleButton,
  StyledHeader,
  StyledTeamInfo,
  StyledLeagueName,
  StyledWinnerSection,
  StyledManagerCard,
  StyledManagerName,
  StyledWinnerCard,
  StyledImage,
  StyledWinnerContainer,
  StyledWinnerSeasonNumber,
  StyledWinnerLabel,
  StyledTitle,
  StyledMatchName,
  StyledScore,
  StyledReactTooltip,
  StyledTeamName,
} from './styled';

import {
  teamInformation,
  playerIsLoaded,
} from '../../../../../../redux/selector/League';

import { cashFormat } from '../../../../../../service/functions';

const TeamInfo = () => {
  const SCORES_PANEL = 'scores';
  const TRANSACTIONS_PANEL = 'transactions';
  const [activePanel, setActivePanel] = useState(SCORES_PANEL);
  // REDUX
  const teamInfo = useSelector(teamInformation);
  const isLoad = useSelector(playerIsLoaded);
  const styles = {
    'font-size': '13px',
    'font-weight': 'bold',
  };

  const leagueWinners = teamInfo?.manager
    ? Object.values(teamInfo?.manager?.previous_league_wins)
    : [];
  const cupWinners = teamInfo?.manager
    ? Object.values(teamInfo?.manager?.previous_cup_wins)
    : [];
  const noAdditionalTeamData = !(
    isEmpty(teamInfo?.lastMatches) && isEmpty(teamInfo?.lastTransfers)
  );

  const goToDiscordManagerUrl = () => {
    if (!isEmpty(teamInfo?.manager)) {
      window.open(
        `https://discordapp.com/users/${teamInfo?.manager?.discord_id}`,
        '_blank'
      );
    }
  };

  if (isLoad) {
    return (
      <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
    );
  }
  if (teamInfo)
    return (
      <StyledTeamSquadContainer>
        <StyledHeader>
          <StyledImage>
            <img src={teamInfo.team.symbol} alt="team logo" />
          </StyledImage>
          <StyledTeamInfo>
            <StyledTeamName> {teamInfo.team.name}</StyledTeamName>
            <StyledLeagueName>
              {teamInfo.team.league ? teamInfo.team.league.name : ''}
            </StyledLeagueName>
          </StyledTeamInfo>
        </StyledHeader>
        <StyledWinnerSection>
          <StyledManagerCard>
            <h4>Owner (Discord ID)</h4>
            <StyledManagerName
              isClickable={teamInfo.manager}
              onClick={goToDiscordManagerUrl}
            >
              @{`${teamInfo.manager ? teamInfo.manager.user_name : 'BOT'}`}
            </StyledManagerName>
          </StyledManagerCard>
          <StyledWinnerContainer>
            {leagueWinners.map((winner, i) => (
              <StyledWinnerCard key={`league${i}`}>
                <StyledWinnerSeasonNumber>L{i + 1}</StyledWinnerSeasonNumber>
                <StyledWinnerLabel data-tip clickable data-for={`league${i}`}>
                  {winner}
                </StyledWinnerLabel>
                <StyledReactTooltip
                  id={`league${i}`}
                  type="dark"
                  effect="solid"
                  place="bottom"
                >
                  League {i + 1} trophies
                </StyledReactTooltip>
              </StyledWinnerCard>
            ))}
          </StyledWinnerContainer>
          <StyledWinnerContainer>
            {cupWinners.map((winner, i) => (
              <StyledWinnerCard key={`cup${i}`}>
                <StyledWinnerSeasonNumber>C{i + 1}</StyledWinnerSeasonNumber>

                <StyledWinnerLabel data-tip clickable data-for={`cup${i}`}>
                  {winner}
                </StyledWinnerLabel>
                <StyledReactTooltip
                  id={`cup${i}`}
                  type="dark"
                  effect="solid"
                  place="bottom"
                >
                  Cup {i + 1} trophies
                </StyledReactTooltip>
              </StyledWinnerCard>
            ))}
          </StyledWinnerContainer>
        </StyledWinnerSection>
        <StyledContext>
          <h4>Club history</h4>

          <ReadMore
            toggleOnClick
            strLimit={200}
            str={teamInfo.team.context}
            onReadMoreClick={() => {
              // showReadMoreFullPopup();
            }}
            additionalLabelStyles={styles}
          />
        </StyledContext>
        {noAdditionalTeamData && (
          <>
            <StyledToggleView>
              <StyledToggleButton
                isActive={activePanel === SCORES_PANEL}
                onClick={() => setActivePanel(SCORES_PANEL)}
              >
                Last 10 scores
              </StyledToggleButton>
              <StyledToggleButton
                isActive={activePanel === TRANSACTIONS_PANEL}
                onClick={() => setActivePanel(TRANSACTIONS_PANEL)}
              >
                Last 10 transfers
              </StyledToggleButton>
            </StyledToggleView>

            {activePanel === SCORES_PANEL && (
              <StyledCard>
                {!isEmpty(teamInfo.lastMatches) ? (
                  <>
                    {teamInfo.lastMatches.map(
                      ({ home_team_data, away_team_data }, index) => (
                        <StyledMatch
                          data-type="lastmatch"
                          key={`${index + Math.random()}index`}
                        >
                          <StyledMatchName left>
                            <img
                              src={home_team_data.symbol}
                              alt="home team logo"
                            />
                            <StyledTitle>{home_team_data.name}</StyledTitle>
                          </StyledMatchName>
                          <StyledScore>
                            {home_team_data.scores} : {away_team_data.scores}
                          </StyledScore>
                          <StyledMatchName right>
                            <StyledTitle>{away_team_data.name}</StyledTitle>
                            <img
                              src={away_team_data.symbol}
                              alt="away team logo"
                            />
                          </StyledMatchName>
                        </StyledMatch>
                      )
                    )}
                  </>
                ) : (
                  <StyledMatch>No previous matches yet</StyledMatch>
                )}
              </StyledCard>
            )}
            {activePanel === TRANSACTIONS_PANEL && (
              <StyledCard>
                {!isEmpty(teamInfo.lastTransfers) ? (
                  <>
                    {teamInfo.lastTransfers.map(
                      ({ amount, description, _id }) => (
                        <StyledTransaction
                          key={_id}
                          data-type={amount > 0 ? 'revenue' : 'expenses'}
                        >
                          <h4>
                            <TransactionIcon
                              showBorder={false}
                              transactionName={description}
                            />
                            {description}
                          </h4>
                          <span>{cashFormat(Math.round(amount), '$')}</span>
                        </StyledTransaction>
                      )
                    )}
                  </>
                ) : (
                  <StyledTransaction>No Transfers yet</StyledTransaction>
                )}
              </StyledCard>
            )}
          </>
        )}
      </StyledTeamSquadContainer>
    );
  return null;
};

export default TeamInfo;
