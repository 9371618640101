import styled, { css } from 'styled-components';

export const StyledBigSymbol = styled.span`
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.5px;
  ${({ isHeaderBased }) => {
    if (isHeaderBased) {
      return css`
        color: #fff;
        font-size: 14px;
      `;
    }
  }}
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
`;
export const StyledSmallSymbol = styled.span`
  font-size: 14px;
  letter-spacing: 1px;
  ${({ isHeaderBased }) => {
    if (isHeaderBased) {
      return css`
        color: #fff;
        font-size: 14px;
      `;
    }
  }}
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 14px;
  color: #fff;
  ${({ isHeaderBased }) => {
    if (isHeaderBased) {
      return css``;
    }
  }};
`;
