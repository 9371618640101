import styled from 'styled-components';
import { StyledWrapper } from '../styled';

export const StyledNewWrapper = styled(StyledWrapper)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledContainerLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
`;

export const StyledLeftContainer = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 710px;
  position: relative;
  margin-right: 10px;
  @media only screen and (max-width: 1199px) {
    margin-right: 0;
    max-width: 590px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    max-width: 640px;
    margin-right: 0;
  }
`;
export const StyledTopSection = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;
export const StyledRightContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 545px;
  margin-left: 10px;
  @media only screen and (max-width: 1199px) {
    margin-left: 0;
    width: 100%;
    max-width: 590px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    max-width: 640px;
    margin-left: 0;
  }
`;

export const StyledPreloaderContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
`;
export const StyledTransactionPreloaderContainer = styled.div`
  background-color: #fff;
  border-radius: 0px;
  margin-bottom: 0px;
`;
