import React from 'react';
import { isMobile } from 'react-device-detect';
import { StyledTitle, StyledSubTitle, StyledParagraph } from './styled';

const UserGuide = ({ content }) => {
  const displayGuideType = {
    start: {
      guideName: 'Main',
      displayContent: [
        {
          name: '',
          text: 'The Open League is a free-to-play online football manager game. TOL is casual simulation for the hardcore football (soccer) fan.            ',
        },
        {
          name: 'Community rules',
          text: 'We pride ourselves on being a respectful and kind community. Protecting our football community is what we care the most about and we have zero tolerance for language or actions that might make others uncomfortable. “Unfair” and “drastic” have been used in the past to qualify our responses to unacceptable behaviors and words in our server.',
        },
        {
          name: 'Bots',
          text: 'Claire and Chris are Discord bots. Chris is our assistant manager. Claire is the league’s pundit. You can ask each “what can you do” for the latest list of commands they are able to handle. You can start conversations with Claire by mentioning her in our Discord server (@Claire_C). Chris will respond to you via Direct Message.',
        },
        {
          name: 'Local time',
          text: ' Our different servers operate in the time zone they are linked to (“server time”). For example, the EUR1 server is linked to London and operates in the same time zone. League matches are played every night at 7pm or 19:00 UK time. You can ask Claire for the time (@Claire_C server time).',
        },
      ],
    },
    players: {
      guideName: 'Players',
      displayContent: [
        {
          name: 'Rating',
          text: `
          A player’s rating indicates the player’s current performance level. It’s a composite of the players technical, physical and mental attributes. There are six player ratings:
            <ol><li>Legend</li>
            <li>World Class</li>
            <li>Strong</li>
            <li>Average</li>
            <li>Weak</li>
            <li>Weakest </li>
          Not all players with the same rating are equal (e.g., some legends are stronger than others). Players usually start their careers as Weak or Weakest and develop into better ratings.`,
        },
        {
          name: 'Potential',
          text: 'The potential rating is an estimate of how good a player could become. Please see the next section for additional details on developing players to reach their full potential. Note that player might never reach their potential but they might also develop to be better than initially expected.',
        },
        {
          name: 'Development',
          text: `Player ratings are updated at the end of each season (i.e. every three weeks). Players improve, decay or stagnate (as indicated by the green, yellow and red arrows next to their names). The end-of-season change may or may not be enough to push them into a new rating.<br/>
          Players only develop if they play matches. Leave them on the bench and they won't reach their peak potential. Some players learn faster than others. For older players, decline is mostly a function of age and injuries. But it's also a function of their mind and body. Some players will decline slower or faster, all else being equal.<br/>
          You can invest in tailored programs that will allow players to either develop faster or keep their current rating longer. These programs need to be purchased individually for each player at a cost of $2 million and last until your player retires.
          `,
        },
        {
          name: 'Academy',
          text: `Clubs have academy spots they can use for their senior squad. Non-patron clubs have two spots while patrons have three spots. The goal of the academy is to develop your players without having them playing in your official league and cup games. They develop at a slightly lower rate in the academy than in official games.<br/> 
      The cost of the academy is $100,000 per day. Once sent, a player will only come back at the end of the season. If your squad is full when the player is set to return, Chris will keep attempting to return your academy player until there is room. You will keep incurring the $100,000 daily fee until your player is returned. <br/> 
      You cannot send to the academy players that your club does not own (i.e., loaned in players) or players that have reached their peak.`,
        },
        {
          name: 'Fitness',
          text: `There are five fitness levels: Great, OK, Tired, Exhausted, and Injured. Older and teenage players usually get tired faster. And it takes them more time to recover. Players' fitness decreases as they play matches and they recover some fitness after a night of sleep. <br/>
      Playing tired players will increase the risk of injury. Fitness resets before a new season begins (the morning of the first matches). Note however that injuries do not reset as they take time to heal.`,
        },
        {
          name: 'Injuries',
          text: "Unfortunately even digital players get injured sometimes. Player injuries are typically a function of a player's fitness level and age. Most players will recover within a few days however devastating season-ending or even career-debilitating injuries are also possible. The league prevents managers from playing injured players.",
        },
        {
          name: 'Player preferences',
          text: 'Players typically have favorite formations, tactics and opponents that are more or less comfortable playing against. The strongest managers in TOL go to great lengths to optimize their team for fit, assembling players who tend to prefer the same play styles in order to create super teams. These preferences will, sometimes, be apparent during matches. Claire (our favorite match commentator) will usually highlight these preferences. Patrons are able to see these preferences without searching through Discord.',
        },
        {
          name: 'Form',
          text: 'Like all players, some TOL players sometimes punch above their weights and transcend their ratings to deliver incredible performances. Sustained overperformance will usually result in an upgrade in the player’s rating or potential. Other players however are not able to deliver to the extent they are expected to. Managers usually get a sense of a player’s form by observing and studying their players’ media ratings.',
        },
        {
          name: 'Media ratings',
          text: 'Media ratings indicate how a player performed in a specific match. While the performance of the broader team impacts this rating, it is mostly a function of a player’s performance in a given match. Media ratings can also give you a sense of a player’s form. For example a Legend player collecting 5s and 6s is probably not having a great time at your club.',
        },
        {
          name: 'Player wages',
          text: 'At the moment, all the players have agreed to a Collective Bargaining Agreement and have the following structured payment per week based upon current rating. Keep these rates in mind when building your team. Carrying a very strong team is expensive, so you will have to do well in the League and Cup to stay afloat. ',
        },
        {
          name: '',
          text: `Current agreement salary:
            <ul>
            <li>Legend: $400,000 per week</li>
            <li>World Class: $200,000 per week</li>
            <li>Strong: $100,000 per week</li>
            <li>Average: $50,000 per week</li>
            <li>Weak: $40,000 per week</li>
            <li>Weakest: $5,000 per week</li> 
            </ul>
          `,
        },
      ],
    },
    'off-season': {
      guideName: 'Off-season',
      displayContent: [
        {
          name: 'Youth camp',
          text: `
This is arguably the most exciting time for clubs. At the end of each season, youth camp opens and allows teams to scout, bid and sign exciting new football talent.<br/>

Camp is held over three days: the Friday, Saturday and Sunday following the end of the season. Right after the season ends, clubs are able to register for youth camp and choose how many scouts to send to camp. They can register a maximum of 6 scouts which gives clubs the capacity to scout 30 players (each scout can scout 5 players).<br/>

Camp is held on Friday, Saturday and Sunday. At 5pm server time on each of these days your scout will submit a scouting report on each of the players scouted. New information is added each day. Managers have the ability to try to sign players after day 1 - or they can decide to scout the players for 1 or 2 more days to have a more precise appreciation of the player’s potential. If they decide to scout for another day, however, another club might decide to bid (and sign) desired players before they do. If several clubs bid on the same player, the highest bid wins the player. If two clubs bid the same amount, the club who bid first will sign the player.<br/>

Note that if you do not have room on your team roster to sign a player (i.e. less than 27 players), your bid will be ignored. If you lose a bid, you will get your bid points back. Bid points are allocated based on a complex formula so that teams with weaker rosters and/or less cash have more points at the start of camp. Bid points not used at the end of the three days of camp are taken away.<br/>
        `,
        },
        {
          name: 'New players',
          text: 'In addition to players who have been selected to participate in Youth Camp, the League also adds new players during the off-season (players are added progressively, as they are discovered, throughout the weekend of the off-season). These players are listed as Free Agents and usually need to be scouted first to assess their potential rating and potential.',
        },
      ],
    },
    trading: {
      guideName: 'Trading',
      displayContent: [
        {
          name: 'Scouting',
          text: "You can send scouts to estimate the rating, potential and fitness of players in the Free Agent pool (that is, players who do not have a club). Scout is a difficult job though and it's challenging even for the sharpest ones to accurately guess a player's potential (i.e. they are often wrong on a +/- 1 rating level on potential ratings). That said they are almost always right in estimating current rating and fitness.",
        },
        {
          name: 'Player values',
          text: 'Values on player cards are an indication of how most players with similar profiles have traded in the past. Some players have traded for less, others have traded for more. But a wide majority have traded in this range. Note that managers who have been playing TOL for less than seven days are blocked from making or accepting offers above or below the recommended range (to save them from themselves). If unsure, it’s usually a good idea to ask other managers what fair value is for a player you are considering selling or buying.',
        },
        {
          name: 'Negotiating	',
          text: 'This is one of the most unique aspects of TOL. Trading players requires negotiating with other managers. It is strongly encouraged to agree on an offer before making a formal offer in the portal. For the introverts reading this, don’t sweat it - most negotiations go something like this “Sell me X for $5M?” “No but I’ll do 6” “Cool offer on the way”… It’s also common for buyers and sellers to agree on structured deals. For example, a buyer and seller might agree to a $10M deal with $5M payable now and $5M after some time. Note that TOL does not and will not enforce these side deals.',
        },
        {
          name: 'Trading with bots',
          text: 'Bot managers will only consider sell offers that are above the high-end of the player’s recommended value and will only sell a player if they have enough players on roster. Bots assess and make decision on offers every hour or so.',
        },
        {
          name: 'Releasing players',
          text: 'Clubs can release players from their squad for a small fee. Players with a rating or potential rating of Strong or better cannot be released unless they are above a certain age. This is mostly to prevent the “accidental” ',
        },
      ],
    },
    competitions: {
      guideName: 'Competitions',
      displayContent: [
        {
          name: 'The League',
          text: 'The League is the main and most prestigious competition. Each team plays each other twice in a season. A win is worth three points, a draw is worth one point. At the end of the season, the team with the most points wins the league. If teams are tied, the team with the highest goal difference wins.  In addition to bragging rights and prestige, teams earn prize money based on their ranking. Prizes are different based on the league with L1 bringing significantly more money. The top 3 teams in L2 and L3 are promoted. The bottom three teams in L1 and L2 get demoted.',
        },
        {
          name: 'The Cup',
          text: 'The Cup pits the 10 teams in each league against each other in a single elimination format.',
        },
      ],
    },
    coaching: {
      guideName: 'Coaching',
      displayContent: [
        {
          name: 'Coaching',
          text: 'Most of the decisions you will need to make as a coach to win silverware for your football club can be accessed from the Teams page. Before you dive in, below are a few pointers.',
        },
        {
          name: 'Matches',
          text: `Matches are simulated in real time and text highlights are streamed into your team’s league channel in Discord. Games last 2x45 minutes with a 15-minute break at halftime. League matches start at 7pm (19:00) every night while Cup games happen at noon on most days.<br/.
        Managers have the ability to make (unlimited) substitutions during matches. They can also make changes to their formation, level of effort and mindset.<br/>
        Injured players will continue to play if not substituted but will not be able to perform at their usual level. For our patrons, Chris will make substitution on your behalf.

`,
        },
        {
          name: 'Roster size',
          text: 'Each team can carry up to 27 players on their roster. Players that are owned by your club but loaned out to other clubs do not count against this limit. The same goes for players who are in your academy. However, players that are owned by other clubs who you have loaned in, do count against the 27-player limit. A simple way to think about this is: you have 27 players are able to play for your team in a given season.',
        },
        {
          name: 'Formations and player positions',
          text: 'You can select one of six formations that all have pros and cons and should be considered based on the team you expect your opponent to play. This is where your managerial skills will come into play. While you can play players out of position, they will usually not perform as well. A player’s comfort playing in a given position is indicated by colors on the card when you drag and drop players in a given position.',
        },
        {
          name: 'Tactics',
          text: `Beyond formations, you can change a teams overall mindset (attack, defend or neutral) and effort level. For mindset, a team attacking more will tend to push the ball forward more aggressively and have its defenders and midfield contribute more to attacking phases. On the flip side, this will leave them exposed to deadly counters. The inverse is true for a team playing more defensively.<br/>
        In terms of effort, a team using extreme effort will leave everything on the pitch in an effort to bring home a win. That said, there is also a cost with fatigue and injuries being more significant and frequent in this configuration.
`,
        },
        {
          name: 'Forfeits',
          text: 'If you forfeit more than 2 matches in a row because you failed to set your team, you will be removed from your team.',
        },
        {
          name: 'Practice',
          text: "You can hold practices once a day against teams that are not in your league. These practices will give your team a slight boost in the day's match. Note that players won't fatigue or get injured in practice. Also - Claire doesn't care for practices and won't give you updates on the practice. Just ask Chris to “practice” to get started or select Practice from the to-do list in the lobby.",
        },
        {
          name: 'Friendlies',
          text: 'You can organize friendly matches with other managers to give your players more opportunities to develop and/or fine tune your strategy. Your players will fatigue when playing friendlies and might get injured so keep that in mind before organizing friendlies during the regular season.',
        },
        {
          name: 'Match feedback',
          text: `
        After each match, a feedback card will be available in the lobby to give you a better sense of how both teams looked on the pitch on a scale from one dot to five dots.
        <ul>
          <li>SKILL: This is your average squad strength throughout the match; this takes into consideration subs and each player’s play time</li>
          <li>FITNESS: Also an average of each player's fitness during the game, including subs</li>
          <li>FORM: Similar to above, this is an average of each player's form throughout the match</li>
          <li>FIT: This is a function of several factors including players being in/out of position, players favorite/worst mindset, opponent, and formation</li>
          <li>PRACTICE: All dots light up if your team practiced. None if your team did not practice</li>
          <li>EFFORT: indicates the average effort level of the team during the match</li>
        </ul>
        `,
        },
      ],
    },
    finances: {
      guideName: 'Finances',
      displayContent: [
        {
          name: 'Revenues',
          text: 'Your club’s main sources of income will be prize money from the league and matchday revenues. Prize money varies between $12M and $22M for League 1 teams, $6M and $8M for League 2 teams and $4M and $6M for League 3 teams. Matchday revenues are typically around $400K per home match in the lowest league to approximately $1M per home match in the top league.',
        },
        {
          name: 'Expenses',
          text: 'The main financial burden for a club is its player wages. Thankfully there is a collective bargaining agreement in place between the League and the players’ union with fixed salaries based on a player’s current rating. Salaries range from $400K per week for Legend players to $5K for Weakest football players.',
        },
        {
          name: 'Bankruptcy',
          text: 'Run out of money, face the anger of your players. Going bankrupt has a significant and lasting impact on your players’ performance. Avoid at all cost. Sell players or arrange a loan with another club manager.',
        },
        {
          name: 'Fund transfers',
          text: 'You are able to transfer funds to other clubs (e.g., to settle past transfers or repay loans) however in order to limit fraud, the league prevents managers with less than 7 days in a club from transferring any funds. In addition, no club is allowed to transfer more than $5M in any given week.',
        },
      ],
    },
  };
  const guideContent = displayGuideType[content];
  const guideContentMobile = Object.values(displayGuideType);
  return isMobile ? (
    guideContentMobile.map((mobileGuide) => (
      <>
        <StyledTitle>{mobileGuide.guideName}</StyledTitle>
        <div>
          {mobileGuide.displayContent.map((guide) => (
            <>
              <StyledSubTitle>{guide.name}</StyledSubTitle>
              <StyledParagraph
                dangerouslySetInnerHTML={{ __html: guide.text }}
              />
            </>
          ))}
        </div>
      </>
    ))
  ) : (
    <>
      <StyledTitle>{guideContent.guideName}</StyledTitle>
      <div>
        {guideContent.displayContent.map((guide) => (
          <>
            <StyledSubTitle>{guide.name}</StyledSubTitle>
            <StyledParagraph dangerouslySetInnerHTML={{ __html: guide.text }} />
          </>
        ))}
      </div>
    </>
  );
};
export default UserGuide;
