import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { InfoDarkRelative } from '../../globalStyled';

export const StyledContainer = styled.section`
  position: relative;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-top: 0px;
  margin-top: 50px;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #e5e5e5;
    top: -7px;
    left: 0;
  }
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #e5e5e5;
    top: -7px;
    right: 0;
  }
  > h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
    margin-bottom: 40px;
    margin-left: 20px;
    > svg {
      cursor: pointer;
      user-select: none;
    }
  }
`;

export const StyledBlocksContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px;
  justify-content: space-between;
`;

export const StyledReportLabel = styled.span`
  padding: 5px 14px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  line-height: 6px;
  color: #000;
  font-family: Gilroy;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.17px;
  border: 1px solid #e5e5e5;
  font-weight: 500;
  width: 135px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  svg {
    margin-left: 5px;
  }
`;

export const StyledInfoIcon = styled(InfoDarkRelative)`
  width: 12px;
  height: 12px;
  top: 0;
  margin-left: 0px;
  ${({ iconsize }) => {
    if (iconsize)
      return css`
        width: ${iconsize}px;
        height: ${iconsize}px;
      `;
  }}
  &:focus,
  &:active {
    outline: none;
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledTooltipText = styled.span`
  text-transform: none;
`;
