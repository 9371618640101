import { createSelector } from 'reselect';

export const getTransfers = (state) => state.transfers;
export const getTransfersList = (state) => state.transfers.transfersList;
export const getBuyData = (state) => state.transfers.buy;
export const getSellData = (state) => state.transfers.sell;
export const getSearchPlayerData = (state) => state.transfers.searchPlayer;
export const getDealsData = (state) => state.transfers.deals;
export const getTransferWindow = (state) => state.transfers.transferWindow;

export const isFilterShown = createSelector(
  getTransfers,
  ({ showFilter }) => showFilter
);
export const getPlayerDataById = (id) =>
  createSelector(
    getSearchPlayerData,
    ({ data }) => data.filter((i) => i._id === id)[0] || {}
  );

// -----------------BUY--------------------
// TODO Buy is deprecated, remove

export const getBuyPlayer = createSelector(getBuyData, ({ data }) => data);
export const isBuyLoading = createSelector(
  getBuyData,
  ({ loading }) => loading
);

export const isSelectedPlayerLoading = createSelector(
  getBuyData,
  ({ selectedPlayerLoading }) => selectedPlayerLoading
);

// ---------------------SELL-------------------------
// TODO Sell is deprecated, remove
export const getSellPlayer = createSelector(getSellData, ({ data }) => data);
export const isSellLoading = createSelector(
  getSellData,
  ({ loading }) => loading
);
export const isSelectedPlayerLoadingInSell = createSelector(
  getSellData,
  ({ selectedPlayerLoading }) => selectedPlayerLoading
);
// ---------------------DEALS-------------------------

export const getDealsPlayer = createSelector(getSellData, ({ data }) => data);
/* export const isDealLoading = createSelector(
  getDealsData,
  ({ loading }) => loading
); */
export const isSelectedPlayerLoadingInDeals = createSelector(
  getDealsData,
  ({ selectedPlayerLoading }) => selectedPlayerLoading
);
export const getSelectedPlayerInDeals = createSelector(
  getDealsData,
  ({ selectedPlayer }) => selectedPlayer?.players
);
export const getDataForRequest = createSelector(
  getDealsData,
  ({ dataForRequest }) => dataForRequest
);

// ----------------------SEARCH------------------------
export const getSearchPlayer = createSelector(
  getSearchPlayerData,
  ({ data }) => data
);
export const getSearchPlayerLoading = createSelector(
  getSearchPlayerData,
  ({ loading }) => loading
);

export const getSelectedPlayerByIndexInSearchable = createSelector(
  getSearchPlayerData,
  ({ selectedIndex, data }) => data[selectedIndex]
);
export const isCreateNewOffer = createSelector(
  getSearchPlayerData,
  ({ offerToBuyLoading }) => offerToBuyLoading
);
export const endOfSeason = createSelector(
  getSearchPlayerData,
  ({ endOfSeasonDate }) => endOfSeasonDate
);
