import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import { isAndroid } from 'react-device-detect';
import { DonutChart, GridChart } from '../../../../../Charts';
import {
  transferShowUnshowFilter,
  transferGetDataToSearchable,
} from '../../../../../../redux/actions/Transfers';
import { teamGetMyTeam } from '../../../../../../redux/actions/Team';

import {
  getSelectedPlayerInDeals,
  isSelectedPlayerLoadingInDeals,
  getSearchPlayer,
  getSearchPlayerLoading,
} from '../../../../../../redux/selector/Transfers';
import { isPremiumUser } from '../../../../../../redux/selector/User';
import { getSeasonNumber } from '../../../../../../redux/selector/Team';
import { digitFormater } from '../../../../../../service/functions';
import {
  formRangeStatus,
  rangeColor,
  formRangeColor,
  simplifiedPosition,
  strategyIds,
  mindsetStatusToValue,
  transferFeelingColor,
  transferFeeling,
} from '../../../../../../service/data';
import { ratingRangeStatus } from '../../../../../../service/constants';

import {
  StyledLoader,
  StyledTeamSquadContainer,
  StyledTeamName,
  StyledCard,
  StyledInjuredLabel,
  StyledLeftLabel,
  StyldRightLabel,
  StyledPlus,
  StyledHeader,
  StyledPlayerData,
  StyledCardDetailItem,
  StyledValue,
  StyledLabel,
  StyledToggleTeamView,
  StyledDarkReactTooltip,
  StyledPatronLabel,
  StyledInsight,
  StyledChartContainer,
} from './styled';

import { ReactComponent as Crown } from '../../../../../../assets/img/crown.svg';

export default function TransfersDeals() {
  const [isShowingOverview, changeCardOverview] = React.useState(true);
  const dispatch = useDispatch();
  const seasonNumber = useSelector(getSeasonNumber);
  const isLoad = useSelector(isSelectedPlayerLoadingInDeals);
  const allPlayersList = useSelector(getSearchPlayer);
  const selectedPlayerFromTable = useSelector(getSelectedPlayerInDeals);
  const isPlayersListLoading = useSelector(getSearchPlayerLoading);
  const isPremium = useSelector(isPremiumUser);

  useEffect(() => {
    dispatch(teamGetMyTeam());
  }, [dispatch]);
  useEffect(() => () => dispatch(transferShowUnshowFilter(false)), [dispatch]);
  useEffect(() => {
    if (allPlayersList.length === 0) dispatch(transferGetDataToSearchable());
  }, [dispatch, allPlayersList.length]);

  const getRecoveryDate = (date) => {
    const eventdate = moment(date).utcOffset('-0000').add(1, 'days');
    const todaysdate = moment();
    const daysLeft = eventdate.diff(todaysdate, 'days');
    return `${daysLeft < 1 ? 1 : daysLeft} day${daysLeft > 1 ? 's' : ''}`;
  };

  const goToPatreon = () => {
    if (window.navigator.userAgent.includes('wv') && isAndroid) {
      window.open(
        'inappsubscription://?package=com.example.app.webview-master&successful_url=https://www.theopenleague.com/home&expired_url=https://www.yahoo.com',
        '_blank'
      );
    } else {
      window.open('https://www.patreon.com/join/theopenleague?', '_blank');
    }
  };

  if (isLoad || isPlayersListLoading) {
    return (
      <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
    );
  }
  if (selectedPlayerFromTable) {
    const potentialRange =
      ratingRangeStatus[selectedPlayerFromTable.potential_range];
    const ratingRange = ratingRangeStatus[selectedPlayerFromTable.rating_range];
    const formRange = formRangeStatus[selectedPlayerFromTable.form_range];
    const reverseRating = [...selectedPlayerFromTable.media_rating];
    reverseRating.reverse();
    const reversePrevRating = [...selectedPlayerFromTable.previous_rating];
    reversePrevRating.reverse();
    return (
      <>
        <StyledTeamSquadContainer>
          <StyledHeader>
            <h3>
              {selectedPlayerFromTable.first_name}{' '}
              {selectedPlayerFromTable.last_name}
            </h3>

            {selectedPlayerFromTable.team && (
              <StyledTeamName>
                {selectedPlayerFromTable.team.name}
              </StyledTeamName>
            )}
            <StyledToggleTeamView className="formations-section">
              <button
                className={isShowingOverview ? 'active' : ''}
                onClick={() => changeCardOverview(true)}
                type="button"
              >
                Player Overview
              </button>
              <button
                disabled={!isPremium}
                className={!isShowingOverview ? 'active' : ''}
                onClick={() => changeCardOverview(false)}
                type="button"
              >
                <StyledDarkReactTooltip
                  globalEventOff="click"
                  id="insights"
                  type="dark"
                  clickable
                  effect="solid"
                  place="bottom"
                >
                  <StyledPatronLabel onClick={() => goToPatreon()}>
                    Become a patron
                  </StyledPatronLabel>{' '}
                  for easier access to player insights. Subscribe using our mobile application.
                </StyledDarkReactTooltip>
                <StyledInsight
                  {...(!isPremium && {
                    'data-tip': true,
                    'data-event': 'click',
                    clickable: true,
                    'data-for': 'insights',
                  })}
                >
                  Insights
                  <Crown />
                </StyledInsight>
              </button>
            </StyledToggleTeamView>
          </StyledHeader>
          <StyledCard>
            {selectedPlayerFromTable.recovery_time && (
              <StyledInjuredLabel>
                <StyledLeftLabel>
                  <StyledPlus>+</StyledPlus>Player is injured
                </StyledLeftLabel>
                <StyldRightLabel>
                  Returns in:{' '}
                  {getRecoveryDate(selectedPlayerFromTable.recovery_time)}
                </StyldRightLabel>
              </StyledInjuredLabel>
            )}
            {isShowingOverview ? (
              <>
                <StyledPlayerData>
                  <StyledCardDetailItem half topBorder>
                    <>
                      <StyledValue>
                        {simplifiedPosition[
                          selectedPlayerFromTable.fav_position
                        ] || 'N/A'}
                      </StyledValue>
                      <StyledLabel>Position</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem half topBorder>
                    <>
                      <StyledValue>
                        {selectedPlayerFromTable.age || 'N/A'}
                      </StyledValue>
                      <StyledLabel>Age</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color={rangeColor[ratingRange]}>
                        {ratingRange || 'N/A'}
                      </StyledValue>
                      <StyledLabel>Rating</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color={rangeColor[potentialRange]}>
                        {potentialRange || 'N/A'}
                      </StyledValue>
                      <StyledLabel>Potential</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color={formRangeColor[formRange]}>
                        {formRange || 'N/A'}
                      </StyledValue>
                      <StyledLabel>Fitness</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledChartContainer>
                  {reverseRating.map((item, index) => (
                    <DonutChart
                      key={`${item}${index}`}
                      index={index + 1}
                      arcSize={item}
                    />
                  ))}
                </StyledChartContainer>
                <StyledChartContainer className="grid-chart-section">
                  <GridChart
                    currentRating={selectedPlayerFromTable.ratingValue}
                    seasonNumber={seasonNumber}
                    data={reversePrevRating}
                  />
                </StyledChartContainer>
                <StyledPlayerData className="key-statistics-section">
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>
                        {Number.isInteger(selectedPlayerFromTable.goals_scored)
                          ? selectedPlayerFromTable.goals_scored
                          : '-'}
                      </StyledValue>
                      <StyledLabel>Goals</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>
                        {Number.isInteger(selectedPlayerFromTable.assists)
                          ? selectedPlayerFromTable.assists
                          : '-'}
                      </StyledValue>
                      <StyledLabel>Assists</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    {selectedPlayerFromTable.teamName !== 'Free agent' ? (
                      <>
                        <StyledValue>
                          {Number.isInteger(
                            selectedPlayerFromTable.played_matches
                          )
                            ? selectedPlayerFromTable.played_matches
                            : '-'}
                        </StyledValue>
                        <StyledLabel>Starts</StyledLabel>
                      </>
                    ) : (
                      <>
                        <StyledValue>
                          {selectedPlayerFromTable.played_matches_total || '-'}
                        </StyledValue>
                        <StyledLabel>Career starts</StyledLabel>{' '}
                      </>
                    )}
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>
                        {selectedPlayerFromTable.teamName === 'Free agent'
                          || (selectedPlayerFromTable.potential_min === -1 && selectedPlayerFromTable.potential_max === -1)
                          ? 'N/A'
                          : `$${digitFormater(
                            selectedPlayerFromTable.potential_min
                          )}-${digitFormater(
                            selectedPlayerFromTable.potential_max
                          )}`}
                      </StyledValue>
                      <StyledLabel>Est. Value</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue>
                        $
                        {digitFormater(selectedPlayerFromTable.salary / 7, 0) ||
                          '-'}
                      </StyledValue>
                      <StyledLabel>Daily Salary</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
              </>
            ) : (
              <>
                <StyledPlayerData>
                  <StyledCardDetailItem topBorder half>
                    <>
                      <StyledValue>
                        {isPremium &&
                          strategyIds[selectedPlayerFromTable.fav_formation]}
                      </StyledValue>
                      <StyledLabel>Favourite Formation</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem topBorder half>
                    <>
                      <StyledValue>
                        {isPremium &&
                          simplifiedPosition[
                          selectedPlayerFromTable.second_fav_position
                          ]}
                      </StyledValue>
                      <StyledLabel>Alternate Position</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem half>
                    <>
                      <StyledValue color="#00996b">
                        {isPremium &&
                          mindsetStatusToValue[
                          selectedPlayerFromTable.fav_mindset
                          ]}
                      </StyledValue>
                      <StyledLabel>Favourite Mindset</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                  <StyledCardDetailItem half>
                    <>
                      <StyledValue color="#d63c30">
                        {isPremium &&
                          mindsetStatusToValue[
                          selectedPlayerFromTable.worst_mindset
                          ]}
                      </StyledValue>
                      <StyledLabel>Worst Mindset</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color="#00996b">
                        {isPremium && selectedPlayerFromTable.fav_opponent}
                      </StyledValue>
                      <StyledLabel>Favourite Opponent</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                <StyledPlayerData>
                  <StyledCardDetailItem>
                    <>
                      <StyledValue color="#d63c30">
                        {isPremium && selectedPlayerFromTable.worst_opponent}
                      </StyledValue>
                      <StyledLabel>Worst Opponent</StyledLabel>
                    </>
                  </StyledCardDetailItem>
                </StyledPlayerData>
                {Number(selectedPlayerFromTable?.transfer_feeling) ? (
                  <StyledPlayerData>
                    <StyledCardDetailItem>
                      <>
                        <StyledValue
                          color={
                            transferFeelingColor[
                            selectedPlayerFromTable.transfer_feeling
                            ]
                          }
                        >
                          {isPremium &&
                            transferFeeling[
                            selectedPlayerFromTable.transfer_feeling
                            ]}
                        </StyledValue>
                        <StyledLabel>Club Fit</StyledLabel>
                      </>
                    </StyledCardDetailItem>
                  </StyledPlayerData>
                ) : null}
              </>
            )}
          </StyledCard>
        </StyledTeamSquadContainer>
      </>
    );
  }
  return null;
}
