import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import {
  StyledPopUp,
  StyledPopUpContainer,
  StyledPopUpBlock,
  StyledParagraph,
  StyledHeaderWithLogo,
  StyledDiscordLogin,
  StyledLoader,
} from '../styled';
import { StyledButton } from '../../../globalStyled';
import { previewLogin } from '../../../redux/actions/User';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { goToDiscordLogin } from '../../../service/functions';
import { getPopupByName } from '../../../redux/selector/Popups';

const NonDiscordPopup = ({ popupName, selectedTeamId, teamName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading } = useSelector(getPopupByName(popupName));
  const hidePopup = () => {
    mixpanel.track('Discordpop2 declined');
    dispatch(hidePopupWindow(popupName));
    if (!isEmpty(localStorage.getItem('previewTeamData'))) {
      history.push('/home');
    }
  };

  const handleGoToDiscord = () => {
    mixpanel.track('Discordpop2 accepted');
    goToDiscordLogin();
  };

  useEffect(() => {
    dispatch(previewLogin(selectedTeamId, teamName));
  }, [selectedTeamId, teamName]);

  return (
    <StyledPopUp>
      <StyledPopUpBlock maxWidth={500} darkBg>
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#2AFC98"
              height={100}
              width={100}
            />
          ) : (
            <>
              <StyledHeaderWithLogo>Preview mode</StyledHeaderWithLogo>
              <StyledParagraph
                aligned="left"
                customColor="#ccc"
                lineHeight="1.5"
              >
                In <b>preview mode </b>you will not be able to keep of make
                changes to your team until you login with Discord - we do this
                to keep our <b>community safe</b>
              </StyledParagraph>
              <StyledButton
                customBgColor="#979797"
                fullWidth
                primary
                onClick={() => hidePopup()}
              >
                I get it, let me in
              </StyledButton>
              <StyledDiscordLogin onClick={() => handleGoToDiscord()}>
                Fine, I’ll login with Discord now
              </StyledDiscordLogin>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(NonDiscordPopup);
