import React from "react";
import { StyledContainer } from "./styled";

export default function Hidden() {
  return (
    <StyledContainer>
      <h1>Hidden Page</h1>
    </StyledContainer>
  );
}
