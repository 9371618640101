import React from 'react';
import PropTypes from 'prop-types';
import { StyledInput, StyledSubmitArrow, StyledFormButton } from './styled';

const FormInput = ({
  disabledButton,
  onButtonClick,
  placeholder,
  onKeyPress,
  onChange,
  inputVal,
}) => (
  <>
    <StyledInput
      onKeyDown={onKeyPress}
      type="text"
      value={inputVal}
      onChange={onChange}
      placeholder={placeholder}
    />
    <StyledFormButton disabled={disabledButton} primary onClick={onButtonClick}>
      <StyledSubmitArrow />
    </StyledFormButton>
  </>
);

FormInput.propTypes = {
  disabledButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  placeholder: PropTypes.string,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  inputVal: PropTypes.string,
};
FormInput.defaultProps = {
  disabledButton: false,
  onButtonClick: () => {},
  placeholder: '',
  onKeyPress: () => {},
  onChange: () => {},
  inputVal: '',
};

export default FormInput;
