import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Ramp , RampUnit } from "@playwire/pw-react-component";
import isEmpty from 'lodash/isEmpty';
import { useLocation, useHistory } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { Slide } from 'react-toastify';
import { isAuth, isAuthAsWaited, getUser, isPremiumUser} from '../redux/selector/User';
import { isRightPanelOpen } from '../redux/selector/Sidebar';

import { isYouthCampAvailable } from '../redux/selector/YouthCamp';
import { getTourState } from '../redux/selector/TourControl';
import { testAuth, getLeagueName, previewLogin } from '../redux/actions/User';
import { allowTour } from '../redux/actions/TourControl';
import { getYouthCampStatusRequest } from '../redux/actions/YouthCamp';
import { tourSteps } from '../helpers/tour/tourSteps';
import {
  locationToStorage,
  tourStorageValue,
} from '../helpers/tour/locationToStorage';
import {
  SQUAD_LOCATION,
  PLAYER_INFO_STORAGE,
  STRATEGY_LOCATION,
} from '../helpers/tour/constants';

import { StyledContainerLoader, StyledLoader } from '../page/styled';
import { StyledToastContainer } from '../globalStyled';
import 'react-toastify/dist/ReactToastify.css';

export default function AuthLayout({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isPremium = useSelector(isPremiumUser);
  const isAuthenticated = useSelector(isAuth);
  const isAuthedAsWaiter = useSelector(isAuthAsWaited);
  const { leagueName, avatarID, username } = useSelector(getUser);
  const isTourStart = useSelector(getTourState);
  const isYouthCampAuth = useSelector(isYouthCampAvailable);
  const isSidebarOpen = useSelector(isRightPanelOpen);

  const [activeTour, setActiveTour] = useState([]);
  const discordData = username && avatarID;
  const isAppLoading =
    location.pathname !== '/' &&
    isAuthenticated === null &&
    isAuthedAsWaiter === null &&
    isYouthCampAuth === null &&
    !discordData &&
    leagueName === null;

  const previewTeamData = localStorage.getItem('previewTeamData');
  useEffect(() => {
    if (!isEmpty(previewTeamData)) {
      const previewTeam = JSON.parse(previewTeamData);
      dispatch(previewLogin(previewTeam.id, previewTeam.name));
    }
  }, []);
  useEffect(() => {
    if (location.pathname !== '/') {
      if (isAuthenticated === null) {
        dispatch(testAuth());
      }
      if (isAuthenticated) {
        if (isYouthCampAuth === null) {
          dispatch(getYouthCampStatusRequest());
        }
        if (leagueName === null) {
          dispatch(getLeagueName());
        }
      }
    }
  }, [
    dispatch,
    isAuthenticated,
    isYouthCampAuth,
    discordData,
    leagueName,
    location,
    history,
  ]);
  useEffect(() => {
    if (location.pathname === '/' && isAuthedAsWaiter) {
      history.push('/signup');
    }
  }, [history, isAuthedAsWaiter, location.pathname]);

  useEffect(() => {
    if (tourStorageValue[location.pathname]) {
      const activeLocalStorageTourState = isSidebarOpen
        ? localStorage.getItem(PLAYER_INFO_STORAGE)
        : localStorage.getItem(tourStorageValue[location.pathname]);
      if (!activeLocalStorageTourState && isTourStart) {
        setTimeout(() => {
          setActiveTour(tourSteps(location.pathname, isSidebarOpen, isMobile));
        }, 1000);
      }
      if (activeLocalStorageTourState && isTourStart) {
        dispatch(allowTour(false));
      }
    }
  }, [location, isTourStart, dispatch, isSidebarOpen]);

  const handleJoyrideCallback = (callbackData) => {
    if (
      callbackData.status === STATUS.FINISHED ||
      callbackData.status === STATUS.SKIPPED
    ) {
      dispatch(allowTour(false));
      locationToStorage(location.pathname, isSidebarOpen);
      setActiveTour([]);
    }
  };
  if (isAppLoading) {
    return (
      <StyledContainerLoader>
        <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
      </StyledContainerLoader>
    );
  }
  return (
    <>
      {!isEmpty(activeTour) && (
        <Joyride
          disableScrollParentFix={location.pathname === STRATEGY_LOCATION}
          floaterProps={{ disableAnimation: true }}
          disableScrolling={
            !(location.pathname === SQUAD_LOCATION && isSidebarOpen)
          }
          callback={(data) => handleJoyrideCallback(data)}
          run={!isEmpty(activeTour) && isTourStart}
          steps={activeTour}
          showProgress
          continuous
          showSkipButton
        />
      )}
      <StyledToastContainer
        className="full-width-toast-container"
        toastClassName="full-width-toast"
        closeButton
        position="top-right"
        autoClose={5000}
        transition={Slide}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        pauseOnHover
      />
   {/*     {<Ramp
          publisherId='1025083'
          id='74579'
        />} */}
        {/* Place this component as needed for all in-page units */}
       {/*  {!isPremium && (<RampUnit
          type="dfp-med-rect"
          cssClass="leaderboard"
        />)} */}

      {children}
    </>
  );
}
