import styled, { css } from 'styled-components';
import {
  StyledButton,
  StyledButtonContainer,
  InfoDark,
  StyledReactTooltip,
} from '../../../../../../globalStyled';
import { ReactComponent as StyledLeftArrowIcon } from '../../../../../../assets/img/go-arrow-left.svg';

export const StyledInsight = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLeftArrow = styled(StyledLeftArrowIcon)``;

export const StyledPatronLabel = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledDarkReactTooltip = styled(StyledReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  font-weight: 500;
  &.__react_component_tooltip.show {
    color: #fff;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledTeamSquadContainer = styled.section``;
export const StyledChartContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
`;
export const StyledCard = styled.div`
  padding: 0px;
  background: #ffffff;
  > p {
    margin: 14px 0;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.222px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #000000;
  }
`;

export const StyledCardDetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
  width: auto;
  flex-grow: 1;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ half }) => {
    if (half)
      return css`
        width: 50%;
      `;
  }}
  ${({ oneThird }) => {
    if (oneThird)
      return css`
        width: 33%;
      `;
  }}
  ${({ topBorder }) => {
    if (topBorder)
      return css`
        border-top: 1px solid #e5e5e5;
      `;
  }}
  &:not(:last-child) {
    border-right: 1px solid #e5e5e5;
  }
`;

export const StyledValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ color }) => color};
`;

export const StyledContractValue = styled(StyledValue)`
  font-size: 15px;
`;

export const StyledLabel = styled.span`
  font-family: Gilroy;
  font-size: 11px;
  font-weight: 500;
  color: #868686;
  text-align: center;
  letter-spacing: 0.77px;
  padding-top: 5px;
`;
export const StyledPlayerData = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledPlayerControlButton = styled(StyledButton)`
  min-width: 84px;
  padding: 8px 6px;
  font-size: 12px;
  margin: 0 5px;
  width: 100%;
  cursor: ${({ nonClickable }) => nonClickable ? 'default' : 'pointer'};
  /* @media only screen and (max-width: 426px) {
    width: 49%;
  } */
`;

export const StyledButtonsContainer = styled(StyledButtonContainer)`
  justify-content: space-evenly;
  padding: 0 15px;
  margin: 10px 0;
  @media only screen and (max-width: 426px) {
    bottom: 0;
  }
`;

export const StyledControlContainer = styled(StyledButtonContainer)`
  justify-content: space-between;
  padding: 0 15px;
  margin: 10px 0;
  @media only screen and (max-width: 426px) {
    bottom: 0;
  }
`;

export const StyledTeamName = styled.span`
  font-size: 14px;
  color: #828282;
  font-weight: 500;
  font-family: 'Gilroy';
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: ${({ isPremium }) => (isPremium ? '0' : '20px')};
`;

export const StyledInjuredLabel = styled.div`
  height: 35px;
  padding: 11px 14px 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${({ type }) => {
    if (type === 'academy') {
      return css`
        background-color: #00996b;
      `;
    }
    if (type === 'injured') {
      return css`
        background-color: #de3e32;
      `;
    }
    if (type === 'loanedOut') {
      return css`
        background-color: #7f7f7f;
      `;
    }
    if (type === 'loanedIn') {
      return css`
        background-color: #fdca40;
        span {
          color: #000;
        }
      `;
    }
  }}
`;
export const StyledLeftLabel = styled.span`
  color: #fff;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  padding-left: ${({ type }) =>
    type === 'loanedOut' || type === 'loanedIn' ? '0' : '15px'};
`;
export const StyldRightLabel = styled.span`
  font-family: Gilroy;
  font-size: 12px;
  color: #fff;
`;

export const StyledPlus = styled.span`
  font-family: 'Gilroy-SemiBold', sans-serif;
  font-weight: bold;
  font-size: 26px;
  position: absolute;
  left: 5px;
  top: 2px;
`;

export const StyledHeader = styled.div`
  padding-top: ${({ isMobileView }) => (isMobileView ? '70px' : '20px')};
  background-image: linear-gradient(to bottom, #fcfcfc, #fafafa, #f8f8f8);
  padding-bottom: 20px;
  > h3 {
    text-align: center;
    margin-bottom: 0px;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0.2px;
    line-height: 34px;
  }
`;
export const StyledScoutingHeader = styled.div`
  padding-top: 20px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
`;

export const StyledMobileInfoHeader = styled.h4`
  position: absolute;
  top: 0;
  z-index: 2;
  height: 45px;
  align-items: center;
  display: flex;
  width: 100%;
  background-color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding-left: 10px;
  border-bottom: 1px solid #e5e5e5;
  svg {
    margin-right: 10px;
  }
`;

export const StyledSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-bottom: 1px solid #e5e5e5;
`;

export const StyledSwitchBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledSwitchContainer = styled.div`
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSwitchLabel = styled.span`
  font-size: 12px;
  width: ${({ labelWidth }) => (labelWidth ? `${labelWidth}px` : '160px')};
  color: #333;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledInfoDark = styled(InfoDark)`
  margin-left: 5px;
`;

export const StyledSidebarButton = styled(StyledButton)`
  min-width: 120px;
  @media only screen and (max-width: 768px) {
    min-width: 160px;
  }
  @media only screen and (max-width: 769px) and (max-width: 1199px) {
    min-width: 120px;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  margin: 0px auto;
  &.clicked {
    background: #0e4afb;
    color: white;
  }
  ${({ clicked }) => {
    if (clicked)
      return css`
        pointer-events: none;
      `;
  }}
`;

export const StyledSliderWrapper = styled.div`
  border-top: 1px solid #d1d1d1;
  padding-top: ${({ searchPage }) => (searchPage ? '0' : '30px')};
`;

export const StyledParagraph = styled.p`
  margin: 5px 0;
  padding: 0 15px;
  text-align: center;
  font-family: Gilroy;
  font-size: 13px;
  color: #666;
`;

export const StyledNicknameButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledNicknameButton = styled(StyledButton)`
  height: 16px;
  color: #fff;
  background-color: #000;
  border-radius: 4px;
  font-size: 10px;
  padding: 7px;
  min-width: 85px;
  margin: 10px 0 12px 0;
  ${({ isPremium }) => {
    if (!isPremium) {
      return css`
        opacity: 0.4;
      `;
    }
  }}

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const StyledNicknameLabel = styled.span`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const StyledHeaderName = styled.h3`
  padding-bottom: ${({ searchPage }) => (searchPage ? '20px' : '0px')};
`;

export const StyledToggleTeamView = styled.div`
  margin-bottom: 20px;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 4px;
  margin-top: 0px;
  border-radius: 18px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin: 0 15px;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    flex: 1;
    color: rgba(0, 0, 0, 0.4);
    border: 0px;
    outline: 0px;
    padding: 6px;
    border-radius: 18px;
    cursor: pointer;
    background-color: #fff;

    &:first-child {
      margin-right: 11px;
    }
    &.active {
      color: #fff;
      background-color: #000;
    }
    svg {
      margin-left: 5px;
    }
  }
`;

export const StyledTimePerformanceContainer = styled.div`
  font-size: 15px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const StyledPerformanceIcon = styled.span`
width: 12px;
height: 12px;
margin: 0 2px;
border-radius: 50%;
background-color: ${({ color }) => color}`;