import React from 'react';
import {
  StyledTransactionsBlock,
  StyledTransaction,
  StyledDate,
} from './styled';
import { cashFormat } from '../../../../../../service/functions';
import TransactionIcon from '../../../../../../components/TransactionIcon';

const Transactions = ({ posts, datesList }) =>
  datesList.map((date) => {
    const filteredDates = posts.filter(
      (post) => new Date(post.createdAt).toDateString() === date
    );
    return (
      <StyledTransactionsBlock key={date}>
        <StyledDate>{date}</StyledDate>
        {filteredDates.map(({ amount, description }, index) => (
          <StyledTransaction
            key={`${index}index`}
            data-type={amount > 0 ? 'revenue' : 'expenses'}
          >
            <h4>
              <TransactionIcon transactionName={description} />
              {description}
            </h4>
            <span>{cashFormat(Math.round(amount), '$')}</span>
          </StyledTransaction>
        ))}
      </StyledTransactionsBlock>
    );
  });
export default React.memo(Transactions);
