import { headers } from './requestConfig';
import { releaseRequest, autoFormation } from '../service/links';

export const sendReleaseRequest = async (playerID) => {
  const response = await fetch(releaseRequest, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      playerID,
    }),
  });
  return response;
};

export const sendAutoFormationRequest = async (strategy) => {
  const response = await fetch(autoFormation, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      strategy,
    }),
  });
  return response;
};
