import styled from 'styled-components';
import { ReactComponent as Shield } from '../../assets/img/shield.svg';

export const StyledWinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  height: 165px;
  img {
    max-width: 80px;
    max-height: 80px;
    width: 100%;
    height: 100%;
  }
`;
export const StyledLabel = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gilroy-Bold', sans-serif;
  letter-spacing: 1.11px;
`;

export const StyledSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
`;

export const StyledNoWinner = styled(Shield)`
  width: 60px;
`;
