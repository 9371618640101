import runtimeEnv from '@mars/heroku-js-runtime-env';
import mixpanel from 'mixpanel-browser';

export const digitFormater = function (num, isAbs, digits = 1) {
  const number = isAbs ? Math.abs(num) : num;
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (number >= si[i].value) {
      break;
    }
  }
  return `${isAbs && num < 0 ? '-' : ''}${
    (number / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
  }`;
};

export const randomInteger = function (min, max) {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
};

export const cashFormat = function (cash, currencySymbol = '') {
  const _cash = `${Number(cash)}`.replace(/[^0-9]/g, '');
  return cash < 0
    ? `-${currencySymbol}${_cash.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : `${currencySymbol}${_cash.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const goToDiscordLogin = () => {
  const env = runtimeEnv();
  const { REACT_APP_AUTH_LINK } = env;
  mixpanel.track('Discord auth page');
  window.location.href = REACT_APP_AUTH_LINK;
};
