import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { StyledButton } from '../../../../globalStyled';

export const StyledTableButton = styled(StyledButton)`
  height: 29px;
  min-width: 120px;
  margin: 0px auto;
  font-size: 13px;
  text-transform: capitalize;
`;

export const StyledScoutingSpan = styled.span`
  color: #ff4d09;
  border-radius: 5px;
  padding: 0px;
  background: transparent;
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Gilroy', sans-serif;
  line-height: 100%;
  font-weight: 500;
  font-size: 13px;
`;

export const StyledContent = styled.span`
  font-size: 13px;
`;

export const StyledUnknownValue = styled(StyledContent)`
  color: #7b7b7b;
  cursor: pointer;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  max-width: 190px;
  &.__react_component_tooltip.show {
    color: #000;
    border-radius: 6px;
    opacity: 1;
  }
`;
