import styled, { css } from 'styled-components';

export const StyledIcon = styled.span`
  position: absolute;
  width: 8px;
  height: 8px;
  left: 15px;
  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    ${({ type }) => {
      if (type === -1) {
        return css`
          background-color: #e94235;
        `;
      }
      if (type === 0) {
        return css`
          background-color: #fabb05;
        `;
      }
      if (type === 1) {
        return css`
          background-color: #00a272;
        `;
      }
    }}
  }
`;
