import React from 'react';
import ContentLoader from 'react-content-loader';

const SquadPreloader = (props) => (
  <ContentLoader
    speed={2}
    height={38}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="20" y="16" rx="3" ry="3" width="207" height="12" />
    <rect x="374" y="16" rx="4" ry="4" width="85" height="12" />
    <rect x="22" y="120" rx="3" ry="3" width="101" height="35" />
    <rect x="21" y="50" rx="3" ry="3" width="202" height="23" />
    <rect x="21" y="78" rx="3" ry="3" width="202" height="23" />
    <rect x="259" y="16" rx="3" ry="3" width="70" height="11" />
    <rect x="0" y="37" rx="0" ry="0" width="1000" height="1" />
    <rect x="481" y="16" rx="3" ry="3" width="207" height="12" />
    <rect x="691" y="16" rx="3" ry="3" width="207" height="12" />
  </ContentLoader>
);

export default SquadPreloader;
