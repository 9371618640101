import React from "react";
import { StyledWrapper } from "../styled";
import Communication from "./components/Communication";

export default function Manager() {
  return (
    <StyledWrapper>
      <Communication />
    </StyledWrapper>
  );
}
