import React, { useRef, useMemo } from 'react';
import groupBy from 'lodash/groupBy';

import PlayerCard from '../PlayerCard';
import { generalTeamRool } from '../../data';
import { ratingRangeStatus } from '../../../../../../../../service/constants';
import { StyledAway } from '../../styled';

export default function AwayTeam({ team, strategy, opponentTeamName }) {
  const teamPOSESSION = useRef(null);

  const teamField = useMemo(() => {
    if (strategy && strategy.length > 0) {
      teamPOSESSION.current = generalTeamRool[strategy];
      const EnemyTeam = groupBy(team, 'last_match_position');
      const newObjTeam = {};
      const keys = Object.keys(teamPOSESSION.current);
      keys.forEach((key) => {
        newObjTeam[key] = EnemyTeam[key]
          ? EnemyTeam[key]
          : teamPOSESSION.current[key];
      });
      return newObjTeam;
    }
    return [];
  }, [team, strategy]);

  if (teamField && strategy && strategy.length > 0) {
    return (
      <>
        {Object.keys(teamPOSESSION.current).map((playerPos, index) => {
          const {
            name,
            first_name: firstName,
            last_name: lastName,
            rating_range: range,
            form_range: formRange,
          } = teamField[playerPos][0];
          const ratingRange = ratingRangeStatus[range];
          return (
            <StyledAway
              key={`${firstName}+${lastName}+${index}`}
              className={`away away-${playerPos
                .replace(/[()]/g, '')
                .toLowerCase()} strategy-${strategy}`}
            >
              <PlayerCard
                isAwayTeam
                opponentTeamName={opponentTeamName}
                {...{
                  name,
                  firstName,
                  lastName,
                  ratingRange,
                  formRange,
                  playerPos,
                }}
              />
            </StyledAway>
          );
        })}
      </>
    );
  }
  return <></>;
}
