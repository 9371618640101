import styled from 'styled-components';
import { StyledButton } from '../../../globalStyled';
import { StyledHeader, StyledPopUp } from '../styled';

export const StyledPopupWrapper = styled(StyledPopUp)`
  overflow-y: scroll;
  top: 0;
`;

export const StyledTermsHeader = styled(StyledHeader)`
  font-size: 20px;
`;
export const StyledTermsContainer = styled.div`
  max-height: 400px;
  max-width: 680px;
  height: 100%;
  width: 500px;
  overflow-y: scroll;
  font-size: 13px;
  color: #333333;
  @media only screen and (min-width: 769px) and (max-width: 1199px) {
    width: 300px;
  }
  @media only screen and (max-width: 1199px) {
    width: 500px;
  }
`;

export const StyledTermsButton = styled(StyledButton)`
  min-width: 180px;
  margin-left: 10px;
  margin-right: 10px;
  @media only screen and (max-width: 991px) {
    min-width: 135px;
  }
`;

export const StyledTitle = styled.span`
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: #000;
  display: block;
`;

export const StyledShadow = styled.div`
  box-shadow: 0px -20px 10px 1px #fff;
  z-index: 3;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

export const StyledIframe = styled.iframe`
  border: 0;
  width: 500px;
  height: 400px;
  margin-top: 0px;
  overflow: hidden;
`;
