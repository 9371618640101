import styled from 'styled-components';

export const StyledTableContainer = styled.section`
  & > h4 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #949494;
    margin-bottom: 30px;
  }
  margin-bottom: 23px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledContainer = styled.div`
  box-sizing: border-box;
  border-radius: 15px;
`;
