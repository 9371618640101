import styled from 'styled-components';

export const StyledTableContainer = styled.section`
  width: 100%;
  padding-bottom: 50px;
  border-radius: 4px;
  @media only screen and (max-width: 1199px) {
    background-color: transparent;
    // display: flex;
    flex-direction: row;
    justify-content: center;
    // max-width: 400px;
  }
`;

export const StyledPlaceholder = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  border-radius: 4px;
`;
