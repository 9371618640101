import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const StyledInnerCircle = styled.circle`
  -webkit-filter: drop-shadow(inset 0px 0px 4px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
  fill: #fff;
  ${({ small }) => {
    if (small) {
      return css`
        -webkit-filter: drop-shadow(inset 0px 0px 2px rgba(0, 0, 0, 0.4));
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4));
      `;
    }
  }}
`;

export const StyledCircle = styled.circle`
  stroke-dasharray: ${({ strokeDasharray }) =>
    strokeDasharray}; /* this value is the pixel circumference of the circle */
  stroke-dashoffset: ${({ offset }) => `${offset}`};
  transition: 0.35s stroke-dashoffset;
  fill: transparent;
`;

export const StyledWrapper = styled.div`
  position: relative;
  margin: 0 5px;
  span {
    font-family: 'Gilroy', sans-serif;
    text-align: center;
    z-index: 1;
    font-size: ${({ small }) => (small ? '9px' : '12px')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #666666;
  }

  svg {
    transform: scale(-1, 1) rotate(-90deg);
    border-radius: 50%;
  }

  .circle_animation {
    stroke-dasharray: 360 360; /* this value is the pixel circumference of the circle */
    stroke-dashoffset: 360;
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  text-align: center;
  border: solid 0.5px #e1e1e1;
  min-width: 85px;
  &.__react_component_tooltip.show {
    background-color: #000;
    font-size: 11px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledHoverPlaceholder = styled.div`
  display: none;
  position: relative;
  flex-direction: row;
  align-items: center;
  color: #fff;
  background-color: #3164fb;
  border-radius: 50%;
  justify-content: center;
  font-size: 9px;
  width: 21px;
  height: 21px;
`;
