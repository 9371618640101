import styled from 'styled-components';

export const StyledIcon = styled.div`
  svg {
    margin-right: 10px;
    font-size: ${({ showBorder }) =>
      showBorder ? '1px solid #d1d1d1' : 'none'};
    border-radius: 5px;
  }
`;
