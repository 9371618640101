import styled from 'styled-components';
import { StyledButton } from '../../../globalStyled';

export const StyledWaitlistWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: 205px;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    margin: 0 20px;
  }
`;
export const StyledLeftSection = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 66.67%;
  max-width: 460px;
  line-height: 23px;
  background-color: ${({ isEmailSent }) =>
    isEmailSent ? '#191919' : '#ea4335'};
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(110, 110, 110, 0.1);
  color: #fff;
  margin-right: 15px;
  padding: 10px 75px;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 15px;
    padding: 10px 40px;
  }
`;
export const StyledRightSection = styled.div`
  display: flex;
  width: 33.33%;
  flex-direction: column;
  max-width: 295px;
  background-color: #191919;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(110, 110, 110, 0.1);
  border: solid 0.5px rgba(151, 151, 151, 0.31);
  color: #fff;
  margin-left: 15px;
  line-height: 23px;
  padding: 10px 30px;

  @media only screen and (max-width: 991px) {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-top: 15px;
  }
`;

export const StyledHeader = styled.div`
  font-family: 'Gilroy';
  font-size: 20px;
  font-weight: bold;
  padding-top: 15px;
`;
export const StyledSubheader = styled.div`
  padding-top: 10px;
  font-size: 13px;
`;

export const StyledInputContainer = styled.div`
  position: relative;
  width: 300px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
`;

export const StyledDiscordButton = styled(StyledButton)`
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background-color: #737df8;
  width: 100%;
  height: 40px;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
