import React, { useEffect } from 'react';
import { Ramp , RampUnit } from "@playwire/pw-react-component";

import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { Adsense } from '@ctrl/react-adsense';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isMobileOnly } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import usePortal from 'react-useportal';
import LastResult from '../../components/LastResult 2.0';
import TableContainer from './components/TableContainer';
import NextMatch from '../../components/NextMatch';
import {
  SignFreeAgentPopup,
  NotificationPopup,
  PlayerInfoPopup,
  DiscordRejoinPopup,
  WelcomePopup,
} from '../../components/Popup';
import { HomeTeamStat, TasksListPreloader, LeagueTeamsPreloader } from '../../components/ContentPreloaders';
import FallbackContentAnimation from '../../components/FallbackContentAnimation';
import TasksList from '../../components/TasksList';
import { getTasksList } from '../../redux/actions/Tasks';
import { homeGetHomeInitialData } from '../../redux/actions/Home';
// import { adsClickRequest } from '../../redux/actions/ads';
import { getPlayerInformation, leagueSetTeamInfo } from '../../redux/actions/League';
import { showPopupWindow } from '../../redux/actions/Popups';
import { getUniverses } from '../../redux/actions/SignUp';
import { teamGetMyTeam, showMobileSidebarSection } from '../../redux/actions/Team';
import { transferGetDataToSearchable } from '../../redux/actions/Transfers';
import { allowTour } from '../../redux/actions/TourControl';

import { getTourState } from '../../redux/selector/TourControl';
import { homeIsLoaded, homeData } from '../../redux/selector/Home';
import { tasksLoading, tasksData } from '../../redux/selector/Tasks';
import { getLoadingUniversesData } from '../../redux/selector/SignUp';
import { getMyAllSquad, myTeamLoading } from '../../redux/selector/Team';
import { getUser, isPremiumUser, isAuth } from '../../redux/selector/User';

import { getPlayerInformationSelector } from '../../redux/selector/League';
import { getSearchPlayer } from '../../redux/selector/Transfers';

import { getPopupByName } from '../../redux/selector/Popups';
import {
  StyledContainer,
  StyledBlockContainer,
  StyledNewWrapper,
  StyledLobbySection,
  StyledTeamName,
  StyledTeamStats,
  StyledInfoLabel,
  StyledRankingContainer,
  StyledBestBadge,
  StyledBestTitle,
  StyledBestLabel,
  StyledSmallStats,
  StyledLeagueLabel,
  StyledTeamTextFields,
  SyledTopLeftSection,
  StyledHalfWidthSection,
  StyledTeamCard,
  StyledTeamInfo,
} from './styled';
import { toOrdinalSuffix } from '../../service/utils';
import { teamRank } from '../../service/data';
import { POPUPS } from '../../service/constants';
import { tourStorageValue } from '../../helpers/tour/locationToStorage';

const Home = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isHomeLoaded = useSelector(homeIsLoaded);
  const isAuthenticated = useSelector(isAuth);
  const isTasksLoading = useSelector(tasksLoading);
  const tasksList = useSelector(tasksData);
  const serverData = useSelector(getSearchPlayer);
  const userTeamData = useSelector(homeData);
  const { universesList } = useSelector(getLoadingUniversesData);
  const isPremium = useSelector(isPremiumUser);
  const isTourStart = useSelector(getTourState);

  const team = useSelector(getMyAllSquad);
  const isTeamLoading = useSelector(myTeamLoading);
  const hideWelcomePopup = localStorage.getItem('hideWelcomePopup');
  useEffect(() => {
    dispatch(homeGetHomeInitialData());
    if (!hideWelcomePopup) {
      dispatch(showPopupWindow(POPUPS.WELCOME_POPUP, POPUPS.WELCOME_POPUP, false));
    }
    /* eslint-disable-next-line */
  }, [dispatch]);
  useEffect(() => {
    mixpanel.track('Visit home page');
    if (!isAuthenticated) {
      mixpanel.track('Preview lobby');
    }
  }, []);

  useEffect(() => {
    if (serverData.length === 0) dispatch(transferGetDataToSearchable());
  }, [dispatch, serverData.length]);

  useEffect(() => {
    if (!isEmpty(userTeamData) && !userTeamData.inServer) {
      dispatch(showPopupWindow(POPUPS.REJOIN_DISCORD_POPUP, POPUPS.REJOIN_DISCORD_POPUP, false));
    }
  }, [dispatch, userTeamData, userTeamData.inServer]);

  const { playerId } = useParams();

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  useEffect(() => {
    if (playerId) {
      dispatch(showPopupWindow(POPUPS.PLAYER_INFO_POPUP, POPUPS.PLAYER_INFO_POPUP, true));
      dispatch(getPlayerInformation(playerId, POPUPS.PLAYER_INFO_POPUP));
    }
  }, [playerId, dispatch]);

  useEffect(() => {
    if (isEmpty(universesList)) {
      dispatch(getUniverses());
    }
  }, [dispatch, universesList]);
  useEffect(() => {
    if (isEmpty(tasksList)) {
      dispatch(getTasksList());
    }
  }, [dispatch, tasksList]);

  useEffect(() => {
    if (isEmpty(team) && !isTeamLoading) {
      dispatch(teamGetMyTeam());
    }
  }, [dispatch, team, isTeamLoading]);

  useEffect(() => {
    const activeLocalStorageTourState = localStorage.getItem(tourStorageValue[location.pathname]);
    if (!isHomeLoaded && !isTourStart && JSON.parse(hideWelcomePopup) && !activeLocalStorageTourState) {
      dispatch(allowTour(true));
    }
  }, [isHomeLoaded, isTourStart, dispatch, location.pathname, hideWelcomePopup]);

  const popupData = useSelector(getPopupByName(POPUPS.NOTIFICATION_POPUP));
  const rejoinDiscordPopupData = useSelector(getPopupByName(POPUPS.REJOIN_DISCORD_POPUP));
  const playerInfoPopupData = useSelector(getPopupByName(POPUPS.PLAYER_INFO_POPUP));
  const welcomePopupData = useSelector(getPopupByName(POPUPS.WELCOME_POPUP));
  const freeAgentPopupData = useSelector(getPopupByName(POPUPS.FREE_AGENT_POPUP));
  const playerInfoData = useSelector(getPlayerInformationSelector);
  const { leagueName } = useSelector(getUser);
  const isFromRegister = localStorage.getItem('isFromRegister');
  const universeName =
    !isEmpty(universesList) &&
    leagueName &&
    universesList.find((universe) => universe.leagues.some((league) => league.code === leagueName)).name;
  useEffect(() => {
    if (universeName) {
      mixpanel.people.set({ 'Player universe name': universeName });
      mixpanel.register({ 'Player universe name': universeName });
    }
  }, [universeName]);

  const showTeamDetails = () => {
    dispatch(leagueSetTeamInfo({ _id: userTeamData.teamId }, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(POPUPS.TEAM_INFO_POPUP, POPUPS.TEAM_INFO_POPUP, true));
    }
  };

  const goToLeague = () => {
    history.push('/league');
  };
  const goToCup = () => {
    history.push('/domestic-cup');
  };

  /*   useEffect(() => {
    const handleAdsClick = () => {
      if (document.activeElement === document.querySelector('iframe')) {
        dispatch(adsClickRequest());
      }
    };

    window.addEventListener('blur', handleAdsClick);
    return () => {
      window.removeEventListener('blur', handleAdsClick);
    };
  }, []); */

  return (
    <>
      <StyledNewWrapper>
        {!isEmpty(welcomePopupData) && !hideWelcomePopup && (
          <Portal>
            <WelcomePopup popupName={POPUPS.WELCOME_POPUP} />
          </Portal>
        )}

        {!isEmpty(popupData) && isFromRegister && (
          <Portal>
            <NotificationPopup
              popupName={POPUPS.NOTIFICATION_POPUP}
              closeCallback={() => {
                localStorage.removeItem('isFromRegister');
              }}
            />
          </Portal>
        )}
        {!isEmpty(playerInfoPopupData) && (
          <Portal>
            <PlayerInfoPopup popupName={POPUPS.PLAYER_INFO_POPUP} playerInfoData={playerInfoData} />
          </Portal>
        )}
        {!isEmpty(rejoinDiscordPopupData) && isAuthenticated && (
          <Portal>
            <DiscordRejoinPopup popupName={POPUPS.REJOIN_DISCORD_POPUP} />
          </Portal>
        )}
        {!isEmpty(freeAgentPopupData) && (
          <Portal>
            <SignFreeAgentPopup
              id={freeAgentPopupData.id}
              popupName={POPUPS.FREE_AGENT_POPUP}
              cash={userTeamData.cash_summary}
              deselectClicked={() => { }}
            />
          </Portal>
        )}
        <StyledBlockContainer>
          <StyledContainer className="first">
            <StyledLobbySection>
              <SyledTopLeftSection>
                <StyledTeamInfo>
                  <FallbackContentAnimation loading={isHomeLoaded} fallback={<HomeTeamStat />}>
                    <StyledTeamName>
                      <>
                        <StyledTeamCard onClick={showTeamDetails}>
                          {userTeamData.symbol && <img src={userTeamData.symbol} alt="team logo" />}
                          <StyledTeamTextFields>
                            <h1>{userTeamData.teamName}</h1>
                            <StyledLeagueLabel>{userTeamData.leagueName}</StyledLeagueLabel>
                          </StyledTeamTextFields>
                        </StyledTeamCard>
                      </>
                      <StyledRankingContainer>
                        {userTeamData.current_season_ranking !== -1 && (
                          <StyledInfoLabel onClick={goToLeague}>
                            {toOrdinalSuffix(userTeamData.current_season_ranking + 1)} in league
                          </StyledInfoLabel>
                        )}
                        {userTeamData.current_cup_ranking !== -1 && (
                          <StyledInfoLabel onClick={goToCup}>
                            Cup {teamRank[userTeamData.current_cup_ranking]}
                          </StyledInfoLabel>
                        )}
                      </StyledRankingContainer>
                      <StyledRankingContainer>
                        <StyledBestBadge>
                          <StyledBestTitle>Best League finish</StyledBestTitle>
                          <StyledBestLabel>
                            {isNumber(userTeamData.best_season_ranking)
                              ? toOrdinalSuffix(userTeamData.best_season_ranking + 1)
                              : 'NONE'}
                          </StyledBestLabel>
                        </StyledBestBadge>
                        <StyledBestBadge>
                          <StyledBestTitle>Best Cup finish</StyledBestTitle>
                          <StyledBestLabel>{teamRank[userTeamData.best_cup_ranking] || 'None'}</StyledBestLabel>
                        </StyledBestBadge>
                      </StyledRankingContainer>
                    </StyledTeamName>
                  </FallbackContentAnimation>
                </StyledTeamInfo>
                <StyledTeamStats>
                  <StyledSmallStats>
                    <FallbackContentAnimation
                      loading={isTasksLoading || isHomeLoaded}
                      fallback={<TasksListPreloader />}
                    >
                      {tasksList ? <TasksList tasksList={tasksList} /> : null}
                    </FallbackContentAnimation>
                  </StyledSmallStats>
                </StyledTeamStats>
              </SyledTopLeftSection>

              <NextMatch
                isLoading={isHomeLoaded}
                data={userTeamData}
                teamKit={userTeamData?.teamKit}
                opponentKit={userTeamData?.opponentKit}
              />
            </StyledLobbySection>
          </StyledContainer>

          <StyledContainer className="second">
            <StyledHalfWidthSection>
              <FallbackContentAnimation
                loading={isHomeLoaded}
                repeat="10"
                fallback={
                  <LeagueTeamsPreloader
                    style={{
                      width: `${isMobile ? '100%' : '600px'}`,
                      height: '38px',
                    }}
                  />
                }
              >
                {userTeamData && userTeamData.rankingTable && <TableContainer ranking={userTeamData.rankingTable} />}
              </FallbackContentAnimation>
            </StyledHalfWidthSection>

            {!isEmpty(userTeamData?.last_match) && (
              <LastResult
                isLoading={isHomeLoaded}
                matchDate={userTeamData?.last_match?.match_date}
                our_team={userTeamData?.last_match?.results.home_team}
                away_team={userTeamData?.last_match?.results.away_team}
                isDomesticCup={userTeamData?.last_match?.domestic_cup}
                isFriendly={userTeamData?.last_match?.friendly}
                timezone={userTeamData?.timezone}
              />
            )}
          </StyledContainer>
        </StyledBlockContainer>

        {/* Only load this component once, at the top most level of your app */}
        {<Ramp
          publisherId='1025083'
          id={!isPremium ? '74579' : null}
        />}
        {/* Place this component as needed for all in-page units */}
       {/*  {!isPremium && (<RampUnit
          type="dfp-med-rect"
          cssClass="leaderboard"
        />)} */}
{/*         {!isPremium && (
          <Adsense
            client={`${!isPremium ? 'ca-pub-2739224896366063' : ''}`}
            slot={`${!isPremium ? '9437045662' : ''}`}
            style={{
              width: '100%',
              height: `${!isPremium ? 300 : 0}`,
              display: `${!isPremium ? 'flex' : 'none'}`,
              flexDirection: 'center',
              marginTop: '20px',
            }}
            layout="in-article"
            format="fluid"
          />
        )} */}
      </StyledNewWrapper>
    </>
  );
};

export default Home;
