import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import { StyledContainer, StyledTablePreloader } from './styled';
import SearchTable from './SearchTable';
import { SquadPreloader } from '../../../../components/ContentPreloaders';
import FallbackContentAnimation from '../../../../components/FallbackContentAnimation';
import TeamSquadPopups from '../../../../components/TeamSquadPopups';

import {
  transferGetDataToSearchable,
  transferSetUserWithOfferToBuy,
  transferShowUnshowFilter,
} from '../../../../redux/actions/Transfers';
import { sidebarOpenRightPanel } from '../../../../redux/actions/Sidebar';
import { allowTour } from '../../../../redux/actions/TourControl';
import {
  getSearchPlayer,
  getSearchPlayerLoading,
} from '../../../../redux/selector/Transfers';
import { getTourState } from '../../../../redux/selector/TourControl';
import { tourStorageValue } from '../../../../helpers/tour/locationToStorage';
import {
  playerAccBoost,
  playerDelayDecline,
} from '../../../../redux/actions/Team';
import { playerFromList } from '../../../../redux/selector/Team';

import { dataForSidebar } from '../../../../redux/selector/Sidebar';
import {
  formRangeStatus,
  sortingPriority,
  simplifiedPosition,
} from '../../../../service/data';
import { ratingRangeStatus } from '../../../../service/constants';

const SearchPlayersContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isTourStart = useSelector(getTourState);
  const serverData = useSelector(getSearchPlayer);
  const isLoading = useSelector(getSearchPlayerLoading);
  const selectedPlayerFromTable = useSelector(playerFromList);
  const { cash } = useSelector(dataForSidebar);

  const setDataToRightPanel = function (original) {
    dispatch(sidebarOpenRightPanel(true));
    dispatch(transferShowUnshowFilter(false));
    dispatch(transferSetUserWithOfferToBuy(original));
  };

  const onAccelerateDevelopment = (id) => {
    dispatch(playerAccBoost(id));
  };
  const onDelayDecline = (id) => {
    dispatch(playerDelayDecline(id));
  };

  const setScoutLabel = (scoutResult, ongoingScout) => {
    if (ongoingScout) {
      return 'Scouting';
    }
    if (scoutResult.length > 0) {
      return 'Scouted';
    }
    return 'Not scouted';
  };

  const getTeamName = (team, academyData) => {
    if (!isEmpty(team)) {
      return team.name;
    }
    if (!isEmpty(academyData?.team)) {
      console.log('academy', academyData);
      return academyData?.team.name;
    }
    return 'Free agent';
  };

  const data = useMemo(
    () =>
      serverData.length > 0
        ? serverData.map(
          ({
            first_name: firstName,
            last_name: lastName,
            potential_range: potentialRange,
            fav_position: favPosition,
            form_range: formRange,
            rating_range: ratingRange,
            listed,
            league,
            team,
            ...other
          }) => ({
            ...other,
            scoutResults: setScoutLabel(
              other.scout_results,
              other.ongoing_scout
            ),
            name: `${firstName} ${lastName}`,
            first_name: firstName,
            last_name: lastName,
            team,
            teamName: getTeamName(team, other?.academy),
            form: formRangeStatus[formRange],
            ratingId: ratingRange,
            ratingValue: ratingRangeStatus[ratingRange],
            potentialId: potentialRange,
            potentialValue: ratingRangeStatus[potentialRange],
            forSale: listed ? 'For sale' : 'Not for sale',
            leagueName: league ? league.name : 'None',
            sortPriority: sortingPriority[simplifiedPosition[favPosition]],
            fav_position: favPosition,
            actionField: 'actionField',
          })
        )
        : [],
    [serverData]
  );
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 230,
      },
      {
        Header: 'Position',
        accessor: 'sortPriority',
        filter: 'equals',
        width: 100,
      },
      {
        Header: 'Age',
        accessor: 'age',
        filter: 'ageRange',
        width: 80,
      },
      {
        Header: 'Zone',
        accessor: 'favZone',
        filter: 'equals',
        width: 90,
      },
      {
        Header: 'Rating',
        accessor: 'ratingId',
        filter: 'equals',
        width: 160,
      },
      {
        Header: 'Potential',
        accessor: 'potentialId',
        filter: 'equals',
        width: 140,
      },
      { Header: 'League', accessor: 'leagueName', width: 125 },
      { Header: 'Team', accessor: 'teamName', width: 210 },
      { Header: 'For sale', accessor: 'forSale', filter: 'equals' },
      { Header: 'Scouting', accessor: 'scoutResults', filter: 'equals' },
      {
        Header: 'Actions',
        accessor: 'actionField',
        disableSortBy: true,
        disableFilters: true,
        width: 145,
      },
    ],
    []
  );

  useEffect(() => {
    if (serverData.length === 0) dispatch(transferGetDataToSearchable());
  }, [dispatch, serverData.length]);

  useEffect(() => {
    const activeLocalStorageTourState = localStorage.getItem(
      tourStorageValue[location.pathname]
    );
    if (!isLoading && !isTourStart && !activeLocalStorageTourState) {
      dispatch(allowTour(true));
    }
  }, [isLoading, isTourStart, dispatch, location.pathname]);

  return (
    <StyledContainer>
      {!isEmpty(selectedPlayerFromTable) && (
        <TeamSquadPopups
          selectedPlayer={selectedPlayerFromTable}
          cash={cash}
          onDelayDecline={onDelayDecline}
          onAccelerateDevelopment={onAccelerateDevelopment}
          isFreeAgentPopup
        />
      )}
      <FallbackContentAnimation
        loading={isLoading || serverData.length === 0}
        repeat={15}
        fallback={
          <StyledTablePreloader>
            <SquadPreloader style={{ width: '100%' }} />
          </StyledTablePreloader>
        }
      >
        <SearchTable
          columns={columns}
          data={data}
          getUserToSell={(org) => setDataToRightPanel(org)}
        />
      </FallbackContentAnimation>
    </StyledContainer>
  );
};

export default React.memo(SearchPlayersContainer);
