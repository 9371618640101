import React from 'react';
import PropTypes from 'prop-types';
import { StyledSaleLabel } from './styled';

const NameCell = ({ cell }) => {
  const isListed = cell.row.original.forSale === 'For sale';
  const isAcademy = cell.row.original.academy?.status === 1;
  switch (true) {
    case isAcademy:
      return (
        <>
          {cell.row.original.name}
          <StyledSaleLabel isAcademy>Academy</StyledSaleLabel>
        </>
      );
    case isListed:
      return (
        <>
          {cell.row.original.name}
          <StyledSaleLabel>For sale</StyledSaleLabel>
        </>
      );
    default:
      return <>{cell.row.original.name}</>;
  }
};
NameCell.propTypes = {
  cell: PropTypes.object.isRequired,
};
export default React.memo(NameCell);
