import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  StyledWrapperContainer,
  StyledSelect,
  StyledHeader,
  StyledLeagueSelection,
  StyledTextedDiv,
  StyledLeagueContainer,
  StyledPlayingSectionPreloader,
} from './styled';
import { StyledInfoIcon, StyledReactTooltip } from '../../globalStyled';

import TableContainer from './components/TableContainer';
import CalendarContainer from './components/CalendarContainer';
import FallbackContentAnimation from '../../components/FallbackContentAnimation';
import { PlayingPreloader } from '../../components/ContentPreloaders';
import { leaugesOptions } from '../../components/SendMoney/constants';

import {
  getLeaguesList,
  leagueGetLeagueTableData,
  leagueCreateCalendar,
  getTeamsFromLeague,
  getCalendarFromLeague,
} from '../../redux/actions/League';

import {
  leagueTableLoading,
  getLeagueId,
  isTeamListLoaded,
  calendarData,
  teamListLoader,
  isLeaguesListLoaded,
} from '../../redux/selector/League';
import { isAuth, getMyCurrentTeam } from '../../redux/selector/User';

import img from '../../assets/img/league-cup.png';

export default function League() {
  const dispatch = useDispatch();
  const [selectedLeague, selectLeague] = React.useState(null);
  const [leaguesList, setLeaguesList] = React.useState([]);

  const isTableLoading = useSelector(leagueTableLoading);
  const leagueId = useSelector(getLeagueId);
  const teamListTable = useSelector(isTeamListLoaded);
  const fixturesData = useSelector(calendarData);
  const currentTeamData = useSelector(getMyCurrentTeam);
  const isAuthenticated = useSelector(isAuth);
  const areTeamsLoaded = useSelector(teamListLoader);
  const leagueOptionsList = useSelector(isLeaguesListLoaded);

  const selectLeagueFromList = React.useCallback(
    (leagueOption) => {
      if (leagueOption !== selectedLeague) {
        dispatch(getTeamsFromLeague(leagueOption));
        dispatch(getCalendarFromLeague(leagueOption));
        selectLeague(leagueOption);
      }
    },
    [dispatch, selectedLeague]
  );

  useEffect(() => {
    dispatch(leagueGetLeagueTableData());
  }, [dispatch]);

  useEffect(() => {
    if (leagueId && !selectedLeague) {
      selectLeague(leagueId);
    }
  }, [leagueId, selectedLeague]);

  useEffect(() => {
    if (!fixturesData.currentDate) {
      dispatch(leagueCreateCalendar());
    }
  }, [dispatch, fixturesData.currentDate]);

  useEffect(() => {
    if (isEmpty(leaguesList)) {
      dispatch(getLeaguesList());
    }
  }, [dispatch, leaguesList]);

  useEffect(() => {
    if (!isEmpty(leagueOptionsList)) {
      setLeaguesList(leaugesOptions(leagueOptionsList));
    }
  }, [leagueOptionsList]);

  const isLoading =
    isTableLoading ||
    !fixturesData.currentDate ||
    !fixturesData.timezone ||
    !leagueId ||
    isEmpty(teamListTable) ||
    isEmpty(leaguesList);

  const previewTeamData = JSON.parse(localStorage.getItem('previewTeamData'));
  const myTeamId = isAuthenticated ? currentTeamData._id : previewTeamData.id;

  return (
    <StyledWrapperContainer>
      <StyledLeagueContainer>
        <StyledHeader>
          <FallbackContentAnimation
            loading={isLoading}
            fallback={
              <StyledPlayingSectionPreloader>
                <PlayingPreloader style={{ width: '100%', height: '100%' }} />
              </StyledPlayingSectionPreloader>
            }
          >
            <StyledLeagueSelection>
              <StyledTextedDiv>
                <h1>
                  The League
                  <StyledInfoIcon
                    iconsize="15"
                    data-tip
                    clickable="true"
                    data-for="league-tooltip"
                  />
                  <StyledReactTooltip
                    id="league-tooltip"
                    type="light"
                    effect="solid"
                    place="bottom"
                  >
                    THE LEAGUE is our regular professional tournament. Ten teams
                    play each other nightly over the course of 18 days. A win
                    earns 3 points, a draw is 1 point and a loss means no point
                  </StyledReactTooltip>
                </h1>
              </StyledTextedDiv>
              <img src={img} alt="" />
              {leaguesList.length > 0 && (
                <StyledSelect
                  onChange={(e) => {
                    selectLeagueFromList(e.value);
                  }}
                  defaultValue={leaguesList.find(
                    (item) => item.value === leagueId
                  )}
                  isSearchable={false}
                  isClearable={false}
                  isDisabled={areTeamsLoaded}
                  options={leaguesList}
                  menuPlacement="auto"
                  placeholder="Select a league"
                  classNamePrefix="react-select"
                />
              )}
            </StyledLeagueSelection>
          </FallbackContentAnimation>
        </StyledHeader>
        <TableContainer
          leagueId={leagueId}
          table={teamListTable}
          selectedLeague={selectedLeague}
          areTeamsLoaded={areTeamsLoaded}
        />
      </StyledLeagueContainer>
      <CalendarContainer
        fixturesData={fixturesData}
        teamId={myTeamId}
        isLoading={isLoading || !selectedLeague || areTeamsLoaded}
      />
    </StyledWrapperContainer>
  );
}
