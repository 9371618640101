import React, { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { StyledMain } from './styled';
import { StyledContainerLoader, StyledLoader } from '../../page/styled';
import Header from './components/Header';
import HeaderBlock from './components/LeftSideBlock';
import RightSideBlock from './components/RightSideBlock';
import Menu from './components/Menu';

import {
  sidebarOpenRightPanel,
  sidebarOpenSideNavigation,
  dataForSidebarLoaded,
  sidebarGetDataForSideBar,
  headerShowPanel,
} from '../../redux/actions/Sidebar';
import { homeShowTips } from '../../redux/actions/Home';
import {
  isRightPanelOpen,
  isSideNavOpen,
  isLoaded,
  dataForSidebar,
  isHeaderVisible,
} from '../../redux/selector/Sidebar';
import { isFilterShown } from '../../redux/selector/Transfers';

function SideBar({ children }) {
  // REDUX
  const dispatch = useDispatch();
  const isRightOpen = useSelector(isRightPanelOpen);
  const showHeader = useSelector(isHeaderVisible);
  const isLoad = useSelector(isLoaded);
  const isSideOpen = useSelector(isSideNavOpen);
  const data = useSelector(dataForSidebar);
  const isFilter = useSelector(isFilterShown);
  // REDUX
  const location = useLocation();

  const openSideNav = function () {
    if (isRightOpen) dispatch(sidebarOpenRightPanel(false));
    dispatch(sidebarOpenSideNavigation(!isSideOpen));
  };

  const resizeFunction = useCallback(() => {
    if (isFilter || isMobile) return null;
    dispatch(sidebarOpenSideNavigation(false));
    dispatch(sidebarOpenRightPanel(false));
  }, [isFilter, dispatch]);

  useEffect(() => {
    mixpanel.track_links('nav a', 'click nav link', {
      referrer: document.referrer,
    });
  }, []);

  useEffect(() => {
    if (!data.cash && !data.ranking) {
      dispatch(dataForSidebarLoaded());
      dispatch(sidebarGetDataForSideBar());
    }
    window.addEventListener('resize', resizeFunction);
    return () => window.removeEventListener('resize', resizeFunction);
  }, [isFilter, data.cash, data.ranking, resizeFunction, dispatch]);

  useEffect(() => {
    mixpanel.track('Page View');
    switch (true) {
      case location.pathname === '/home':
        dispatch(sidebarOpenRightPanel(false));
        dispatch(sidebarOpenSideNavigation(false));
        dispatch(headerShowPanel(false));
        break;
      case /\/home\/player\/.*$/.test(location.pathname):
        dispatch(sidebarOpenRightPanel(false));
        dispatch(sidebarOpenSideNavigation(false));
        dispatch(headerShowPanel(false));
        break;
      case location.pathname === '/finance':
        dispatch(sidebarOpenRightPanel(false));
        dispatch(sidebarOpenSideNavigation(false));
        dispatch(headerShowPanel(false));
        break;
      case location.pathname === '/domestic-cup':
        dispatch(sidebarOpenRightPanel(false));
        dispatch(sidebarOpenSideNavigation(false));
        dispatch(headerShowPanel(false));
        break;
      case location.pathname === '/league':
        dispatch(sidebarOpenSideNavigation(false));
        dispatch(headerShowPanel(false));
        dispatch(sidebarOpenRightPanel(false));
        break;
      case location.pathname === '/team/strategy':
        dispatch(sidebarOpenRightPanel(false));
        dispatch(sidebarOpenSideNavigation(false));
        dispatch(homeShowTips(false));
        break;
      default:
        dispatch(sidebarOpenRightPanel(false));
        dispatch(sidebarOpenSideNavigation(false));
        dispatch(headerShowPanel(true));
    }
  }, [location, dispatch]);

  if (isLoad) {
    return (
      <StyledContainerLoader>
        <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
      </StyledContainerLoader>
    );
  }
  return (
    <>
      <Header location={location} onClick={() => openSideNav()} />
      <Menu isSideOpen={isSideOpen} showHeader={showHeader} />
      <HeaderBlock showHeader={showHeader} isRightOpen={isRightOpen} />
      <StyledMain
        data-right={isRightOpen}
        showHeader={showHeader}
        data-sideopen={isSideOpen}
      >
        {children}
      </StyledMain>
      <RightSideBlock
        hasTopHeader={showHeader}
        isRightOpen={isRightOpen}
        sidebarOpenRightPanel={(state) => {
          dispatch(sidebarOpenRightPanel(state));
        }}
      />
    </>
  );
}

export default SideBar;
