import React, { useRef, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import { isMobile } from 'react-device-detect';
import {
  DragOverlay,
  useDroppable,
  useDraggable,
  useDndMonitor,
} from '@dnd-kit/core';

import { useDispatch, useSelector } from 'react-redux';
import {
  teamSelectPlayerOnGameField,
  setSelectedTeamIds,
  showMobileSidebarSection,
  teamReplacePlayerFromTheirPosition,
  setFormationListFilter,
  pitchPlayerPosition,
} from '../../../../../../../../redux/actions/Team';
import {
  selectedPlayerPosition,
  getPlayersForCurrentPosition,
  getTeamKit,
} from '../../../../../../../../redux/selector/Team';

import { showPopupWindow } from '../../../../../../../../redux/actions/Popups';
import { getPlayerInformation } from '../../../../../../../../redux/actions/League';
import PlayerCard from '../PlayerCard';
import { generalTeamRool } from '../../data';

import {
  POPUPS,
  ratingRangeStatus,
} from '../../../../../../../../service/constants';
import {
  simplifiedPosition,
  playerEffectivenessIndicators,
  rangeColor,
} from '../../../../../../../../service/data';
import { StyledHome } from '../../styled';
import { ReactComponent as DragIcon } from '../../../../../../../../assets/img/drag-icon.svg';

import {
  StyledRatingCell,
  StyledTopSection,
  StyledBottomSection,
  StyledSection,
  StyledTitle,
  StyledCellName,
  StyledCell,
  StyledWrapper,
  StyledInfoSection,
  StyledDraggingCard,
  StyledPlayerDragIcon,
} from './styled';

export default function HomeTeam({
  team,
  strategy,
  activeDraggingId,
  teamView,
}) {
  const dispatch = useDispatch();
  const teamPOSESSION = useRef(null);
  const playersList = useSelector(getPlayersForCurrentPosition);
  const playerPosition = useSelector(selectedPlayerPosition);
  const teamKitData = useSelector(getTeamKit);
  const [isDragging, startDragging] = React.useState(false);

  const selectedPlayer =
    playerPosition && playerPosition.replace(/[()]/g, '').toLowerCase();
  const teamField = useMemo(() => {
    if (strategy && strategy.length > 0) {
      teamPOSESSION.current = generalTeamRool[strategy];
      const MyTeam = groupBy(team, 'current_position');
      const newObjTeam = {};
      const keys = Object.keys(teamPOSESSION.current);
      keys.forEach((key) => {
        newObjTeam[key] = MyTeam[key]
          ? MyTeam[key]
          : teamPOSESSION.current[key];
      });
      return newObjTeam;
    }
    return [];
  }, [team, strategy]);

  React.useEffect(() => {
    const strategyTeamIds = flatten(Object.values(teamField)).map(
      (item) => item._id
    );
    dispatch(setSelectedTeamIds(strategyTeamIds));
  }, [strategy, team, teamField, dispatch]);
  const replaceFunction = (playerOnPitch, draggablePlayerId) => {
    if (isMobile) {
      if (playerOnPitch._id !== undefined) {
        dispatch(
          showPopupWindow(
            POPUPS.PLAYER_INFO_POPUP_VIEW,
            POPUPS.PLAYER_INFO_POPUP_VIEW,
            true
          )
        );
        dispatch(
          getPlayerInformation(playerOnPitch._id, POPUPS.PLAYER_INFO_POPUP_VIEW)
        );
      } else {
        dispatch(showMobileSidebarSection('list'));
      }
      dispatch(teamSelectPlayerOnGameField(playerOnPitch));
    } else {
      const playerFromSidebar = playersList.find(
        ({ _id }) => _id === draggablePlayerId.split(' ')[0]
      );
      // replace player from their position
      dispatch(teamSelectPlayerOnGameField(playerOnPitch));
      dispatch(teamReplacePlayerFromTheirPosition(playerFromSidebar));
    }
  };

  function Draggable(props) {
    const { id, children } = props;

    const { attributes, listeners, setNodeRef } = useDraggable({
      id: `${id}`,
      disabled: !id,
    });
    return (
      <StyledPlayerDragIcon
        isHidden={teamView === 'opponnentTeam'}
        disabled={!id}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        {children}
      </StyledPlayerDragIcon>
    );
  }
  function DraggablePlaceholder(props) {
    const { id, children } = props;

    const { attributes, listeners, setNodeRef } = useDraggable({
      id: `${id}`,
      disabled: !id,
    });

    return (
      <div
        style={{
          borderRight: '1px solid #e5e5e5',
          alignItems: 'center',
          display: 'flex',
          padding: '0 10px',
        }}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        {children}
      </div>
    );
  }

  useDndMonitor({
    onDragStart() {
      startDragging(true);
    },
    onDragEnd({ active, over }) {
      if (!isMobile) {
        startDragging(false);
        if (over && over.id !== null && over.id !== 'playersList') {
          replaceFunction(
            {
              // TODO move selected dragging player data to local state
              ...teamField[over.id][0],
              current_position:
                teamField[over.id][0].current_position || over.id,
            },
            active.id.split(' ')[0]
          );
          if (!active.id.split(' ')[1]) {
            dispatch(setFormationListFilter('ALL'));
          }
          dispatch(pitchPlayerPosition(null));
        }
      }
    },
    onDragCancel() {
      startDragging(false);
    },
  });

  const showPositionEffectiveness = (dragPos, activePlayerId) => {
    const draggingPlayer = playersList.find(
      ({ _id }) => _id === activePlayerId.split(' ')[0]
    );

    return playerEffectivenessIndicators[draggingPlayer.fav_position][dragPos];
  };

  function Droppable(props) {
    const { isOver, setNodeRef, active } = useDroppable({
      id: `${props.id}`,
    });
    const {
      name,
      firstName,
      lastName,
      ratingRange,
      formRange,
      playerPos,
      selectedCard,
      cardId,
    } = props;
    const style = {
      background:
        isDragging && active
          ? showPositionEffectiveness(props.id, active.id)
          : undefined,
      padding: '1px 4px',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      boxShadow: `${isOver ? '0 0 5px 3px rgb(0 0 0 / 15%)' : 'none'}`,
    };

    return (
      <div ref={setNodeRef}>
        {!isMobile && (
          <Draggable id={cardId}>
            <DragIcon />
          </Draggable>
        )}

        <PlayerCard
          style={style}
          id={cardId}
          {...{
            name,
            firstName,
            lastName,
            ratingRange,
            formRange,
            playerPos,
            selectedCard,
            _id: cardId,
            teamKit: teamKitData,
          }}
        />
        {activeDraggingId === cardId && !isMobile && (
          <DragOverlay wrapperElement="div">
            <StyledDraggingCard>
              <StyledWrapper small>
                <DraggablePlaceholder id={cardId}>
                  <DragIcon />
                </DraggablePlaceholder>
                <StyledInfoSection>
                  <StyledTopSection small>
                    <StyledTitle small>
                      {firstName} {lastName}{' '}
                    </StyledTitle>
                  </StyledTopSection>
                  <StyledBottomSection>
                    <StyledSection>
                      <StyledCell>{simplifiedPosition[playerPos]}</StyledCell>
                      <StyledCellName>Position</StyledCellName>
                    </StyledSection>
                    <StyledSection>
                      <StyledCell>
                        <StyledRatingCell
                          color={rangeColor[ratingRangeStatus[ratingRange]]}
                        >
                          {ratingRange}
                        </StyledRatingCell>
                      </StyledCell>
                      <StyledCellName>Rating</StyledCellName>
                    </StyledSection>
                  </StyledBottomSection>
                </StyledInfoSection>
              </StyledWrapper>
            </StyledDraggingCard>
          </DragOverlay>
        )}
        {/*   </Draggable> */}
      </div>
    );
  }

  if (teamField && strategy && strategy.length > 0) {
    return (
      <>
        {Object.keys(teamPOSESSION.current).map((playerPos, index) => {
          const playerData = teamField[playerPos][0];
          const {
            name,
            first_name: firstName,
            last_name: lastName,
            rating_range: range,
            form_range: formRange,
            current_position: currentPosition,
            fav_zone: favouriteZone,
            ...other
          } = playerData;
          const ratingRange = ratingRangeStatus[range];
          return (
            <StyledHome
              style={{ position: 'relative' }}
              onClick={
                isMobile
                  ? () =>
                      replaceFunction({
                        ...other,
                        current_position: currentPosition || playerPos,
                        name,
                        _id: other._id,
                        firstName,
                        lastName,
                        rating: ratingRange,
                        selected: playerPos.replace(/[()]/g, '').toLowerCase(),
                      })
                  : undefined
              }
              key={`${other._id}+${index}`}
              className={`home home-${
                currentPosition
                  ? currentPosition.replace(/[()]/g, '').toLowerCase()
                  : playerPos.replace(/[()]/g, '').toLowerCase()
              } strategy-${strategy}`}
            >
              <Droppable
                id={playerPos}
                playerId={other._id}
                favouriteZone={favouriteZone}
                key={index}
                cardId={other._id}
                {...{
                  name,
                  firstName,
                  lastName,
                  ratingRange,
                  formRange,
                  playerPos,
                  selectedPlayer,
                  _id: other._id,
                }}
              />
            </StyledHome>
          );
        })}
      </>
    );
  }
  return <></>;
}
