import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
  StyledRedAlert,
  StyledBlackAlert,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { sendTransferConfirm } from '../../../redux/actions/Transfers';
import { getPopupByName } from '../../../redux/selector/Popups';
import { getDataForRequest } from '../../../redux/selector/Transfers';
import { numberWithCommas } from '../../../service/utils';

const AcceptOfferPopup = ({
  popupName,
  loanEndDate,
  hasLowerOffer,
  hasHigherOffer,
  noOfferLimit,
}) => {
  const [defaultStep, showDefaultStep] = useState(true);
  const { error, isLoading } = useSelector(getPopupByName(popupName));
  const data = useSelector(getDataForRequest);
  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();
  const confirmTransfer = () => {
    showDefaultStep(false);
    dispatch(sendTransferConfirm(data._id));
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#2AFC98"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && noOfferLimit && (
                <>
                  <StyledHeader>
                    {data.cardType} player confirmation
                  </StyledHeader>

                  <StyledParagraph>
                    {data.cardType === 'Loan Out' ? (
                      <>
                        Please confirm that you want to loan out{' '}
                        <strong>{data.name}</strong> for{' '}
                        <strong>
                          ${numberWithCommas(data.transfer_fee_offer)}
                        </strong>
                        . The player will be returned automatically to you on{' '}
                        <strong>
                          {moment(loanEndDate)
                            .utcOffset('-0000')
                            .format('D MMM YYYY')}
                        </strong>
                      </>
                    ) : (
                      <>
                        Please confirm that you want to transfer{' '}
                        <strong>{data.name}</strong> for{' '}
                        <strong>
                          ${numberWithCommas(data.transfer_fee_offer)}{' '}
                        </strong>
                        .
                      </>
                    )}
                  </StyledParagraph>
                </>
              )}
              {defaultStep && hasLowerOffer && !error && (
                <>
                  <StyledParagraph>
                    Please confirm that you want to transfer{' '}
                    <StyledBlackAlert>{data.name}</StyledBlackAlert> for{' '}
                    <StyledRedAlert>
                      ${numberWithCommas(data.transfer_fee_offer)}
                    </StyledRedAlert>
                    . The amount offered is{' '}
                    <StyledRedAlert>much lower</StyledRedAlert> than most offers
                    for similar players.
                  </StyledParagraph>
                </>
              )}
              {defaultStep && !error && hasHigherOffer && (
                <>
                  <StyledParagraph>
                    Please confirm that you want to transfer{' '}
                    <StyledBlackAlert>{data.name}</StyledBlackAlert> for{' '}
                    <StyledRedAlert>
                      ${numberWithCommas(data.transfer_fee_offer)}
                    </StyledRedAlert>
                    . The amount offered is{' '}
                    <StyledRedAlert>much higher</StyledRedAlert> than most
                    offers for similar players.
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader>{error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton basic onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      primary={noOfferLimit}
                      danger={hasLowerOffer || hasHigherOffer}
                      onClick={() => confirmTransfer()}
                    >
                      Accept offer
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};
AcceptOfferPopup.propTypes = {
  popupName: PropTypes.string.isRequired,
};
export default React.memo(AcceptOfferPopup);
