import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { SCOUTING, ratingRangeStatus } from './constants';

// Description: helper for setting "Potential" value for each player.
// Args:
// ongoing_scout - if scouting is running for current player
// scout_results - array of scouted results. We are taking the last scout result to show.
// Returns: string with name of Potential.
export const potentialFormatter = ({
  ongoing_scout,
  scout_results,
  potential,
  potential_range,
}) => {
  if (scout_results.length && !ongoing_scout && !potential_range) {
    const scoutPotentialRange = scout_results[0].data.potential_range;
    return ratingRangeStatus[scoutPotentialRange];
  }
  if (
    isEmpty(scout_results) &&
    !ongoing_scout &&
    potential &&
    !potential_range
  ) {
    return ratingRangeStatus[potential];
  }
  if (!ongoing_scout && potential_range) {
    return ratingRangeStatus[potential_range];
  }
  if (ongoing_scout) {
    return SCOUTING;
  }
  return null;
};

// Description: adds ordinal suffix for number.
// Args:
// num - Number
// Returns: string with number+ordinal.

export const toOrdinalSuffix = (num) => {
  const int = parseInt(num);
  const digits = [int % 10, int % 100];
  const ordinals = ['st', 'nd', 'rd', 'th'];
  const oPattern = [1, 2, 3, 4];
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3];
};

export const youthCampPlayerDataParser = (results) => {
  let potential;
  let rating;
  results.forEach((item) => {
    if (item.youth_camp_level === 3) {
      potential = item.data.potential_range;
    }
    if (item.youth_camp_level === 2) {
      rating = item.data.rating_range;
    }
  });
  return { rating, potential };
};

export const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const sortByValueFirst = (arr, val) => {
  const sortArr = [...arr];
  return sortArr.sort(function (a, b) {
    if (a.fav_position === b.fav_position) return 0;
    if (a.fav_position === val) return -1;
    if (b.fav_position === val) return 1;

    if (a.fav_position < b.fav_position) return -1;
    if (a.fav_position > b.fav_position) return 1;
    return 0;
  });
};

export const createBidLabel = (acquired, activeBid) => {
  switch (true) {
    case acquired:
      return 'Player signed';
    case activeBid:
      return 'Bid sent';
    default:
      return 'Bid';
  }
};

const statusFilter = (arr, filterVal) => {
  if (!filterVal) {
    return arr;
  }
  const statusValues = {
    scouting: 'Scouting',
    scouted: 'Scouted',
    bidding: 'Bidding',
    available: 'Available',
  };
  switch (filterVal) {
    case statusValues.scouting:
      return arr.filter((item) => item.selectedToBeScouted);
    case statusValues.scouted:
      return arr.filter((item) => item.scout_results.length > 0);
    case statusValues.bidding:
      return arr.filter((item) => item.activeBid);
    case statusValues.available:
      return arr.filter((item) => !item.acquired);
    default:
      return arr;
  }
};

export const filterYCPlayers = (playersList, zone, position, status) => {
  const filtersObj = {
    fav_zone: zone,
    fav_position: position,
  };
  const cleanObj = pickBy(filtersObj, (i) => i);
  if (!isEmpty(playersList)) {
    const sortedPlayers = sortByValueFirst(playersList, 'CF');
    if (isEmpty(cleanObj)) {
      return statusFilter(sortedPlayers, status);
    }

    const res = sortedPlayers.filter(function (item) {
      for (const key in cleanObj) {
        if (item[key] === undefined || item[key] !== cleanObj[key])
          return false;
      }
      return true;
    });
    return statusFilter(res, status);
  }
};

export const getDomesticCupCalendar = (date1, phase) => {
  const date = moment(date1);
  switch (phase) {
    case 0:
      return date.format('dddd, DD MMM YYYY');
    case 1:
      return moment(date).add(4, 'days').format('dddd, DD MMM YYYY');
    case 2:
      return moment(date).add(5, 'days').format('dddd, DD MMM YYYY');
    case 3:
      return moment(date).add(7, 'days').format('dddd, DD MMM YYYY');
    case 4:
      return moment(date).add(11, 'days').format('dddd, DD MMM YYYY');
    default:
      return '';
  }
};

export const getGroupWinner = (teamA, teamB) => {
  if (
    !isEmpty(teamA) &&
    !isEmpty(teamB) &&
    teamB.score !== -1 &&
    teamA.score !== -1
  ) {
    if (teamA.score > teamB.score) {
      return teamA;
    }
    return teamB;
  }
  return {};
};

export const getTransfersOfferType = (
  currentTeamId,
  sendingTeamId,
  category
) => {
  switch (true) {
    case currentTeamId !== sendingTeamId && category === 0:
      return 'Buy';
    case currentTeamId === sendingTeamId && category === 0:
      return 'Sell';
    case currentTeamId !== sendingTeamId && category === 1:
      return 'Loan In';
    case currentTeamId === sendingTeamId && category === 1:
      return 'Loan Out';
    default:
      return 'Buy';
  }
};

export const transferStatusConverter = {
  0: 'In progress',
  1: 'Accepted',
  2: 'Rejected',
  3: 'Canceled',
};

export const fitnessProgress = {
  '-1': '20%',
  0: '40%',
  1: '60%',
  2: '80%',
  3: '100%',
};
