import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { isMobileOnly } from 'react-device-detect';
import {
  StyledWrapper,
  StyledContainer,
  StyledLeftSection,
  StyledRightSection,
  StyledLogo,
  StyledTeamInfo,
  StyledName,
  StyledDate,
  StyledInfoLabel,
} from './styled';
import {
  calendarMatch,
  calendarCupPhase,
  POPUPS,
} from '../../service/constants';
import { toOrdinalSuffix } from '../../service/utils';
import { showMobileSidebarSection } from '../../redux/actions/Team';
import { leagueSetTeamInfo } from '../../redux/actions/League';
import { showPopupWindow } from '../../redux/actions/Popups';

const UpcomingCalendar = ({ data }) => {
  const { TEAM_INFO_POPUP } = POPUPS;
  const dispatch = useDispatch();
  const sortedCalendarData = orderBy(data, 'type', 'desc');
  const viewTeamInfo = (id) => {
    dispatch(leagueSetTeamInfo({ _id: id }, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
    }
  };
  return (
    <StyledWrapper>
      {sortedCalendarData.map((calendarEvent) => (
        <StyledContainer
          key={`${calendarEvent.team.name}${calendarEvent.date}`}
        >
          <StyledLeftSection>
            <StyledLogo
              onClick={() => viewTeamInfo(calendarEvent.team._id)}
              src={calendarEvent.team.symbol}
              alt="logo"
            />
            <StyledTeamInfo>
              <StyledName>{calendarEvent.team.name}</StyledName>
              <StyledDate>
                {moment(calendarEvent.date)
                  .local()
                  .format('D MMMM YYYY')}
              </StyledDate>
            </StyledTeamInfo>
          </StyledLeftSection>
          <StyledRightSection>
            <StyledInfoLabel>
              {calendarMatch[calendarEvent.type]}
            </StyledInfoLabel>
            <StyledInfoLabel>
              {calendarEvent.type === 0
                ? toOrdinalSuffix(calendarEvent.rank + 1)
                : calendarCupPhase[calendarEvent.rank + 1]}
            </StyledInfoLabel>
          </StyledRightSection>
        </StyledContainer>
      ))}
    </StyledWrapper>
  );
};
UpcomingCalendar.propTypes = {
  data: PropTypes.array,
};
UpcomingCalendar.defaultProps = {
  data: [],
};
export default React.memo(UpcomingCalendar);
