import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SideBar from '../components/SideBar';
import { isAuth, isAuthAsWaited } from '../redux/selector/User';

export default function PrivateRoute({
  component: Component,
  emptyLayout = false,
  ...rest
}) {
  const isUserAuth = useSelector(isAuth);
  const isAuthenticated =
    isUserAuth || !isEmpty(localStorage.getItem('previewTeamData'));
  const isAuthenticatedAsWaiter = useSelector(isAuthAsWaited);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && emptyLayout) {
          return <Component {...props} />;
        }
        if (isAuthenticated && !emptyLayout) {
          return (
            <SideBar>
              <Component {...props} />
            </SideBar>
          );
        }
        if (isAuthenticatedAsWaiter) {
          return <Redirect to="/signup" />;
        }

        // TODO Add check if promise isn't loading here, otherwise somethimes user can be redirected to '/' page
        if (isAuthenticated === false) {
          return <Redirect to="/" />;
        }
      }}
    />
  );
}
