import React from 'react';

import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledParagraph,
} from '../styled';

const NoSavingStrategyPopup = ({
  yes,
  no,
  visible,
  onCancel,
  onConfirm,
  content,
}) => {
  const confirm = () => {
    onConfirm();
  };

  return (
    <>
      {visible && (
        <StyledPopUp>
          <StyledPopUpBlock>
            <StyledPopUpContainer>
              <StyledParagraph>{content}</StyledParagraph>
              <StyledButtonContainer>
                <StyledButton basic onClick={confirm}>
                  {no}
                </StyledButton>
                <StyledButton primary onClick={() => onCancel()}>
                  {yes}
                </StyledButton>
              </StyledButtonContainer>
            </StyledPopUpContainer>
          </StyledPopUpBlock>
        </StyledPopUp>
      )}
    </>
  );
};

export default React.memo(NoSavingStrategyPopup);
