import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile, isAndroid } from 'react-device-detect';
import { isEmpty } from 'lodash';
import usePortal from 'react-useportal';
import { useTable } from 'react-table';
import { useSelector, useDispatch } from 'react-redux';

import {
  StyledTable,
  StyledThead,
  StyledTbody,
  StyledTh,
  StyledTd,
  StyledTrTh,
  StyledTrTd,
  StyledLabel,
  StyledCrown,
  StyledReactTooltip,
  StyledMobileSidebar,
  StyledTitle,
  StyledLeftArrow,
} from './styled';
import TeamInfo from '../SideBar/components/RightSideBlock/pages/TeamInfo';
import { TeamInfoPopup } from '../Popup';

import { showMobileSidebarSection } from '../../redux/actions/Team';
import { sectionNameToDisplay } from '../../redux/selector/Team';

import { getPopupByName } from '../../redux/selector/Popups';
import { dataForSidebar } from '../../redux/selector/Sidebar';

import { POPUPS } from '../../service/constants';

import TITLELOGO from '../../assets/img/title.png';

export default function TableContainer({
  columns,
  data,
  canSelectPlayer = false,
  getTeamInfo = (e) => console.log(e),
  isTeamInCurrentLeague,
}) {
  const dispatch = useDispatch();
  const teamData = useSelector(dataForSidebar);
  const mobileSectionNameToDisplay = useSelector(sectionNameToDisplay);

  const { TEAM_INFO_POPUP } = POPUPS;
  const teamInfoPopupData = useSelector(getPopupByName(TEAM_INFO_POPUP));
  const [clicked, setClicked] = useState(null);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });

  const goToPatreon = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (window.navigator.userAgent.includes('wv') && isAndroid) {
      window.open(
        'inappsubscription://?package=com.example.app.webview-master&successful_url=https://www.theopenleague.com/home&expired_url=https://www.yahoo.com',
        '_blank'
      );
    } else {
      window.open('https://www.patreon.com/theopenleague', '_blank');
    }
  };
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  return (
    <>
      {!isEmpty(teamInfoPopupData) && (
        <Portal>
          <TeamInfoPopup popupName={TEAM_INFO_POPUP} />
        </Portal>
      )}
      {isMobile && mobileSectionNameToDisplay === 'teamInfo' && (
        <StyledMobileSidebar
          isOpened={mobileSectionNameToDisplay === 'teamInfo'}
        >
          <StyledTitle
            onClick={() => {
              dispatch(showMobileSidebarSection(null));
            }}
          >
            <StyledLeftArrow />
            Team Information
          </StyledTitle>
          <TeamInfo />
        </StyledMobileSidebar>
      )}
      <StyledTable {...getTableProps()}>
        <StyledThead>
          {headerGroups.map((headerGroup, index) => (
            <StyledTrTh {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, ind) => (
                <StyledTh
                  type={column.Header}
                  key={ind}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </StyledTh>
              ))}
            </StyledTrTh>
          ))}
        </StyledThead>
        <StyledTbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <StyledTrTd
                key={`row-number-${parseInt(row.id)}`}
                className={`row-number-${parseInt(row.id)}`}
                clicked={clicked}
                currentRate={
                  parseInt(row.id) === teamData.ranking && isTeamInCurrentLeague
                }
                {...row.getRowProps()}
              >
                {row.cells.map((cell, ind) => (
                  <StyledTd
                    key={ind}
                    onClick={() => {
                      if (!canSelectPlayer) return;
                      setClicked(row.id);
                      getTeamInfo(cell.row.original);
                    }}
                    type={cell.column.Header}
                    titleCell={cell.column.Header === 'Number'}
                    {...cell.getCellProps()}
                  >
                    {(cell.column.Header === 'NO' ||
                      cell.column.Header === 'POS') &&
                      `${cell.value}`.slice(-2)}

                    {cell.column.Header === 'PTS' && (
                      <strong>{cell.render('Cell')}</strong>
                    )}

                    {cell.column.Header === 'Team' && (
                      <React.Fragment>
                        {cell.value}
                        {cell.row.original.stars > 0 && (
                          <>
                            <StyledReactTooltip
                              id={`${row.id}last-season-winner`}
                              type="light"
                              effect="solid"
                              place="bottom"
                            >
                              Last season's league winner
                            </StyledReactTooltip>
                            <span
                              data-tip
                              clickable
                              data-for={`${row.id}last-season-winner`}
                            >
                              <img src={TITLELOGO} alt="last season winner" />
                            </span>
                          </>
                        )}
                        {cell.row.original.bot === 1 && (
                          <>
                            <StyledLabel>
                              <StyledReactTooltip
                                id={row.id}
                                type="light"
                                effect="solid"
                                place="bottom"
                              >
                                Team managed by a bot until a new owner claims
                                it
                              </StyledReactTooltip>
                              <span data-tip clickable data-for={row.id}>
                                BOT
                              </span>
                            </StyledLabel>
                          </>
                        )}
                        {cell.row.original.premium && (
                          <>
                            <StyledReactTooltip
                              id={`${row.id}premium`}
                              type="light"
                              effect="solid"
                              place="bottom"
                            >
                              Pro subscribers help us keep the lights on. Click
                              on the crown to be redirected to our Patreon page.
                            </StyledReactTooltip>
                            <span
                              data-tip
                              clickable
                              data-for={`${row.id}premium`}
                            >
                              <StyledCrown onClick={(e) => goToPatreon(e)} />
                            </span>
                          </>
                        )}
                      </React.Fragment>
                    )}

                    {cell.column.Header !== 'NO' &&
                      cell.column.Header !== 'POS' &&
                      cell.column.Header !== 'PTS' &&
                      cell.column.Header !== 'Team' &&
                      cell.render('Cell')}
                  </StyledTd>
                ))}
              </StyledTrTd>
            );
          })}
        </StyledTbody>
      </StyledTable>
    </>
  );
}

TableContainer.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  canSelectPlayer: PropTypes.bool,
  getTeamInfo: PropTypes.func,
  isTeamInCurrentLeague: PropTypes.bool.isRequired,
};
TableContainer.defaultProps = {
  getTeamInfo: (e) => console.log(e),
  canSelectPlayer: false,
};
