import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 3px;
`;
export const StyledTaskItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 10px;
  align-items: center;
  &:nth-child(even) {
    background-color: #fafafa;
  }
`;

export const StyledTaskType = styled.span`
  font-size: 13px;
  color: ${({ disabled }) => (disabled ? '#999' : '#000000')};
`;

export const StyledTaskStatus = styled.button`
  border: 0;
  outline: none;
  border-radius: 4px;
  color: #fff;
  background-color: ${({ status }) => (status ? '#00A272' : '#000000')};
  padding: 3px;
  font-size: 12px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  &:disabled {
    cursor: default;
    background-color: #00a272;
    ${({ isTransferWindowClosed }) => {
      if (isTransferWindowClosed) {
        return css`
          background-color: #f6f6f4;
          color: #999999;
        `;
      }
    }}
  }
`;
export const StyledTitle = styled.span`
  display: flex;
  color: rgba(25, 25, 25, 0.5);
  font-family: Gilroy;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  padding: 5px 10px;
`;
