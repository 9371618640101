import styled from 'styled-components';

export const StyledContainer = styled.section`
  width: 100%;
`;

export const StyledViewDealsContainer = styled.section`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: row;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 1em;
  width: 100%;
  padding: 0px;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
