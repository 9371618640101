import styled, { css } from 'styled-components';
import { ReactComponent as Shield } from '../../../assets/img/shield.svg';

export const StyledGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 250px;
  align-items: center;
  border-radius: 1px;
  height: 21px;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  //padding-left: 10px;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
  /*  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1px;
    border-top: 1px solid #e5e5e5;
    right: -50px;
  } */
  ${({ cardPosition }) => {
    if (cardPosition === 'first') {
      return css`
        margin-bottom: 20px;
      `;
    }
  }}
  ${({ shortVersion }) => {
    if (shortVersion) {
      return css`
        border: 0;
        max-width: 90px;
        margin-bottom: 0;
      `;
    }
  }}
`;
export const StyledName = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  width: 150px;
  height: 100%;
  padding-right: 10px;
  //padding-right: 20px;
  justify-content: flex-end;
  span {
    padding-right: 10px;
    text-align: right;
  }
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
        padding-left: 10px;
        span {
          padding-left: 10px;
          text-align: left;
        }
      `;
    }
  }}
  img {
    /*  padding: 0 10px; */
    max-width: 22px;
    max-height: 22px;
    width: 100%;
    height: 100%;
  }

  ${({ shortVersion }) => {
    if (shortVersion) {
      return css`
        width: 30px;
        background-color: transparent;
      `;
    }
  }}
`;
export const StyledGoals = styled.div`
  width: 23px;
  font-size: 11px;
  background-color: #000;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
  ${({ goals }) => {
    if (goals === null) {
      return css`
        background-color: #e5e5e5;
        color: #999;
      `;
    }
  }}
  ${({ shortVersion }) => {
    if (shortVersion) {
      return css`
        margin-left: 10px;
      `;
    }
  }}
`;

export const StyledNoWinner = styled(Shield)`
  width: 22px;
`;

export const StyledNotAvailable = styled.span`
  color: #999999;
  font-size: 12px;
`;
