import React, { useRef } from 'react';

import PageSideBarNavigation from '../../../../../PageSideBarNavigation';

export default function TeamSide() {
  const links = useRef([
    { to: '/team/strategy', text: 'Set Strategy' },
    { to: '/team/squad', text: 'View Squad' },
  ]);

  return (
    <>
      <PageSideBarNavigation links={links.current} />
    </>
  );
}
