import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  StyledPopUp,
  StyledCross,
  StyledPopUpContainer,
  StyledSpan,
} from '../styled';
import {
  StyledPopupHeader,
  StyledPopUpBlockNoPadding,
  StyledParagraph,
} from './styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';

import { getSignUpTeamById } from '../../../redux/selector/SignUp';

const ReadMorePopup = ({ popupName, id }) => {
  const { name, context, symbol } = useSelector(getSignUpTeamById(id));

  const dispatch = useDispatch();

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlockNoPadding>
        <StyledPopUpContainer>
          <StyledPopupHeader>
            <img src={symbol} alt="team-logo" /> <StyledSpan>{name}</StyledSpan>
            <StyledCross onClick={() => hidePopup()} />
          </StyledPopupHeader>
          <StyledParagraph>{context}</StyledParagraph>
        </StyledPopUpContainer>
      </StyledPopUpBlockNoPadding>
    </StyledPopUp>
  );
};

export default React.memo(ReadMorePopup);
