import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { getPopupByName } from '../../../redux/selector/Popups';

import { cashFormat } from '../../../service/functions';

const ConfirmPlayerImprovementsPopup = ({
  header,
  text,
  cash,
  popupName,
  id,
  penaltiSize,
  confirmAction,
}) => {
  const [defaultStep, showDefaultStep] = useState(true);
  const NSF = cash - penaltiSize < 0;

  const popupData = useSelector(getPopupByName(popupName));
  const { error, isLoading } = popupData;
  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();
  const onConfirm = () => {
    showDefaultStep(false);
    if (!NSF) {
      confirmAction(id);
    }
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#2AFC98"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && (
                <>
                  <StyledHeader>{header}</StyledHeader>
                  <StyledParagraph>{text}</StyledParagraph>
                  <StyledParagraph>
                    This program costs <b>{cashFormat(penaltiSize, '$')}.</b>
                  </StyledParagraph>
                  <StyledParagraph>Would you like to proceed?</StyledParagraph>
                </>
              )}
              {NSF && !error && !defaultStep && (
                <>
                  <StyledHeader> Not enough funds.</StyledHeader>
                  <StyledParagraph>
                    You don't have enough money for this action.
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {!defaultStep && !error && !NSF && (
                <>
                  <StyledHeader>Confirmed.</StyledHeader>
                  <StyledParagraph>The program is now active</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton danger onClick={onConfirm}>
                      Yes, purchase
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(ConfirmPlayerImprovementsPopup);
