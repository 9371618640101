import React from 'react';
import ContentLoader from 'react-content-loader';

const HomeTeamStat = (props) => (
  <ContentLoader
    style={{ width: '100%' }}
    speed={2}
    width={400}
    height={200}
    viewBox="0 0 400 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="89" y="61" rx="3" ry="3" width="72" height="8" />
    <rect x="89" y="36" rx="3" ry="3" width="226" height="16" />
    <circle cx="49" cy="54" r="25" />
    <rect x="131" y="110" rx="3" ry="3" width="122" height="25" />
    <rect x="25" y="149" rx="3" ry="3" width="134" height="20" />
    <rect x="172" y="149" rx="3" ry="3" width="171" height="20" />
    <rect x="25" y="110" rx="3" ry="3" width="90" height="25" />
  </ContentLoader>
);

export default HomeTeamStat;
