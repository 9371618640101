import React from 'react';

import { useSelector } from 'react-redux';
import YouthCampDetailsItem from '../YouthCampDetailsItem';

import { getYouthCampDetails } from '../../redux/selector/YouthCamp';

import { StyledYouthCampDetailsContainer } from './styled';

const YouthCampDetails = () => {
  const { scoutsRemaining, totalScouts, bidsRemaining } = useSelector(
    getYouthCampDetails
  );

  return (
    <StyledYouthCampDetailsContainer>
      <YouthCampDetailsItem
        id="scout-details"
        firstNumber={scoutsRemaining}
        lastNumber={totalScouts}
        descriptionLabel="Scouts available"
        description={`This is your remaining scouting capacity. ${scoutsRemaining} of ${totalScouts} means you can scout an additional ${scoutsRemaining} players out of a total capacity of ${totalScouts} players.`}
      />
      <YouthCampDetailsItem
        id="bid-details"
        firstNumber={bidsRemaining}
        descriptionLabel="Bid points"
        description="Points available to bid on players. Bids are cleared daily at 12pm PT"
      />
    </StyledYouthCampDetailsContainer>
  );
};
export default React.memo(YouthCampDetails);
