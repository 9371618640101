import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Adsense } from '@ctrl/react-adsense';
import { RampUnit } from "@playwire/pw-react-component";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { tourStorageValue } from '../../../../helpers/tour/locationToStorage';
import ViewDealsContainer from './components/ViewDealsContainer';
import DealsTable from './components/DealsTable';
import FallbackContentAnimation from '../../../../components/FallbackContentAnimation';
import {
  DealsCardPreloader,
  SquadPreloader,
  MobileDealsCardPreloader,
} from '../../../../components/ContentPreloaders';
import {
  getTransfersList,
  getSearchPlayer,
} from '../../../../redux/selector/Transfers';
import { getTourState } from '../../../../redux/selector/TourControl';

import { myTeamLoading, getMyAllSquad } from '../../../../redux/selector/Team';
import { isPremiumUser } from '../../../../redux/selector/User';
import { transfersListRequest } from '../../../../redux/actions/Transfers';
import { allowTour } from '../../../../redux/actions/TourControl';
// import { adsClickRequest } from '../../../../redux/actions/ads';

import {
  StyledContainer,
  StyledNoTransfers,
  StyledLink,
  StyledWrapper,
  StyledLabel,
  StyledIcon,
  StyledDealsPreloader,
  StyledDealsTablePreloader,
  StyledTablePreloader,
} from './styled';
import { StyledReactTooltip, InfoDarkRelative } from '../../../../globalStyled';

const PlayerDealsContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const transfersList = useSelector(getTransfersList);
  const allPlayersList = useSelector(getSearchPlayer);
  const myTeam = useSelector(getMyAllSquad);
  const isLoad = useSelector(myTeamLoading);
  /* const isPremium = useSelector(isPremiumUser); */
  const [selectPlayer, setSelectPlayer] = useState(false);
  const isTourStart = useSelector(getTourState);
  useEffect(() => {
    if (!transfersList.teamId) {
      dispatch(transfersListRequest());
    }
  }, [transfersList.teamId, dispatch]);

  useEffect(() => {
    const activeLocalStorageTourState = localStorage.getItem(
      tourStorageValue[location.pathname]
    );
    if (!isLoad && !isTourStart && !activeLocalStorageTourState) {
      dispatch(allowTour(true));
    }
  }, [isLoad, isTourStart, dispatch, location.pathname]);

  const dealsToDisplay = [...transfersList.active, ...transfersList.pending];
  return (
    <>
      <StyledContainer className="first open-deals-section">
        <FallbackContentAnimation
          loading={isLoad}
          fallback={
            <>
              {' '}
              <h1>
                Open deals
                <InfoDarkRelative
                  iconsize="10"
                  data-tip
                  clickable="true"
                  data-for="open-deals"
                />{' '}
                <StyledReactTooltip
                  id="open-deals"
                  type="light"
                  effect="solid"
                  place="bottom"
                >
                  OPEN DEALS are transfer offers that have not been completed
                  yet. You can ACCEPT or REJECT offers others have made for your
                  players or CANCEL offers you have made for others’ players
                </StyledReactTooltip>
              </h1>
              <StyledDealsPreloader>
                {isMobile ? (
                  <MobileDealsCardPreloader />
                ) : (
                  <DealsCardPreloader />
                )}
              </StyledDealsPreloader>
            </>
          }
        >
          {dealsToDisplay.length > 0 &&
            allPlayersList.length > 0 &&
            myTeam.length > 0 ? (
            <ViewDealsContainer
              dealsList={dealsToDisplay}
              selectPlayer={selectPlayer}
              setSelectPlayer={setSelectPlayer}
              teamId={transfersList.teamId}
              allPlayersList={allPlayersList}
              myTeam={myTeam}
            />
          ) : (
            <StyledNoTransfers>
              <h1>
                Open deals
                <InfoDarkRelative
                  iconsize="10"
                  data-tip
                  clickable="true"
                  data-for="open-deals"
                />{' '}
                <StyledReactTooltip
                  id="open-deals"
                  type="light"
                  effect="solid"
                  place="bottom"
                >
                  OPEN DEALS are transfer offers that have not been completed
                  yet. You can ACCEPT or REJECT offers others have made for your
                  players or CANCEL offers you have made for others’ players
                </StyledReactTooltip>
              </h1>
              <StyledWrapper>
                <StyledIcon />
                <StyledLabel>
                  No open deals yet, search for players and start a deal
                </StyledLabel>
                <StyledLink to="/transfers/search-players">
                  Search Players
                </StyledLink>
              </StyledWrapper>
            </StyledNoTransfers>
          )}
        </FallbackContentAnimation>
      </StyledContainer>
      <StyledDealsTablePreloader className="closed-deals-section">
        <h1>
          Closed deals
          <InfoDarkRelative
            iconsize="10"
            data-tip
            clickable="true"
            data-for="closed-deals"
          />
          <StyledReactTooltip
            id="closed-deals"
            type="light"
            effect="solid"
            place="bottom"
          >
            CLOSED DEALS are past offers that have been completed or cancelled
          </StyledReactTooltip>
        </h1>
        <FallbackContentAnimation
          loading={isLoad}
          repeat={5}
          fallback={
            <StyledTablePreloader>
              <SquadPreloader style={{ width: '100%' }} />
            </StyledTablePreloader>
          }
        >
          <DealsTable
            deals={transfersList.closed}
            teamId={transfersList.teamId}
          />
        </FallbackContentAnimation>
      </StyledDealsTablePreloader>
      {/* {!isPremium && (<RampUnit
          type="dfp-med-rect"
          cssClass="leaderboard"
        />)} */}
      {/*       {!isPremium && (
        <Adsense
          client={`${!isPremium ? 'ca-pub-2739224896366063' : ''}`}
          slot={`${!isPremium ? '9437045662' : ''}`}
          style={{
            width: '100%',
            height: `${!isPremium ? 300 : 0}`,
            display: `${!isPremium ? 'flex' : 'none'}`,
            flexDirection: 'center',
            marginTop: '20px',
          }}
          layout="in-article"
          format="fluid"
        />
      )} */}
    </>
  );
};

export default React.memo(PlayerDealsContainer);
