import React from 'react';
import { StyledIframe, StyledWrapper } from './styled';

const PrivacyPage = () => (
  <StyledWrapper>
    <StyledIframe
      title="Privacy"
      src="https://app.termly.io/policy-viewer/policy.html?policyUUID=fcd7dca9-9b79-496c-9370-103a2bd03b61"
    />
  </StyledWrapper>
);

export default PrivacyPage;
