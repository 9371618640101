import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledCopyText,
} from '../styled';

import { hidePopupWindow } from '../../../redux/actions/Popups';

import { getUser } from '../../../redux/selector/User';

const InvitePopup = ({ popupName }) => {
  const [copied, copyLink] = useState(false);

  const dispatch = useDispatch();
  const { leagueName } = useSelector(getUser);

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };
  const env = runtimeEnv();
  const { REACT_APP_LINK } = env;
  const textToCopy = `${REACT_APP_LINK}/select-team/${leagueName}`;

  return (
    <StyledPopUp>
      <StyledPopUpBlock maxWidth={600}>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          <StyledHeader>Invite a friend</StyledHeader>
          <StyledParagraph>
            To invite a friend to your league direct them to the following link
          </StyledParagraph>
          <CopyToClipboard onCopy={() => copyLink(true)} text={textToCopy}>
            <StyledCopyText>{textToCopy}</StyledCopyText>
          </CopyToClipboard>
          <StyledButtonContainer>
            <CopyToClipboard onCopy={() => copyLink(true)} text={textToCopy}>
              <StyledButton primary>
                {!copied ? 'Copy link' : 'Copied!'}
              </StyledButton>
            </CopyToClipboard>
          </StyledButtonContainer>
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(InvitePopup);
