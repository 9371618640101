import styled from 'styled-components';

import { StyledLoader } from '../../styled';

export const StyledNewLoader = styled(StyledLoader)`
  position: absolute;
`;

export const StyledTableContainer = styled.section`
  background-color: #fff;
  border-radius: 4px;

  & > h4 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #949494;
    margin-bottom: 30px;
  }
`;

export const StyledContainer = styled.div`
  box-sizing: border-box;
  border-radius: 15px;
`;

export const StyledFallbackSvg = styled.div`
  @media only screen and (max-width: 991px) {
    svg {
      width: 100%;
    }
  }
`;
