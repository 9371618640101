import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import { WaitlistPopup } from '../Popup';
import SignUpLeagues from '../SignUpLeagues';
import ServerAvailability from '../ServerAvailability';
import {
  StyledHeader,
  StyledBody,
  StyledSignUpContainer,
  StyledServersContainer,
  StyledServer,
  StyledReactTooltip,
  StyledInfoIcon,
  StyledMobileSidebar,
  StyledUniverseLocation,
  StyledServerSelect,
  StyledServersList,
  StyledLocationIcon,
  StyledLocationWhiteIcon,
  StyledSelectContainer,
} from './styled';

import { showMobileSidebarSection } from '../../redux/actions/Team';
import { sectionNameToDisplay } from '../../redux/selector/Team';

const SignUpUniverses = ({ universes }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [universesList, setUniversesList] = React.useState(universes);
  const [activeUniverse, setActiveUniverse] = React.useState(null);

  const sortByName = (a, b) =>
    a.name.localeCompare(b.name, undefined, { numeric: true });

  React.useEffect(() => {
    if (location?.state?.universe) {
      setActiveUniverse(universes.find((universe) => universe.name === location?.state?.universe)?._id);
    } else {
      setActiveUniverse(universes[0]._id);
    }
  }, []);
  // TODO Move sectionNameToDisplay from team to Sidebar
  // TODO Move mobile sidebar actions from team to Sidebar
  const mobileSectionNameToDisplay = useSelector(sectionNameToDisplay);

  const setActiveServer = (universeId) => {
    setActiveUniverse(universeId);
    if (isMobile) {
      dispatch(showMobileSidebarSection('select-league'));
    }
  };

  const leaguesList = useMemo(
    () => universesList.find((universe) => universe._id === activeUniverse),
    [universesList, activeUniverse]
  );

  const options = useMemo(() => {
    const _options = new Set();
    universes.forEach((universe) => {
      _options.add(universe.timezone);
    });
    const timezoneList = Array.from(_options.values());
    const worldwideOption = [{ value: 'Worldwide', label: 'Worldwide' }];
    timezoneList.forEach((timezone) => {
      worldwideOption.push({
        value: timezone,
        label: timezone,
      });
    });
    return worldwideOption;
  }, [universes]);

  const setUniverse = (option) => {
    const valueFromSelect = option.value;
    if (valueFromSelect === 'Worldwide') {
      setUniversesList(universes);
    } else {
      const filteredArray = universes.filter((universe) => universe.timezone === valueFromSelect);
      filteredArray.sort(sortByName);
      setUniversesList(filteredArray);
      setActiveUniverse(filteredArray[0]._id);
    }
  };

  const totalFreeTeamsAmount = useMemo(
    () => universesList.reduce((acc, universe) => acc + universe.freeTeams, null),
    [universesList]
  );

  const noFreeTeams = totalFreeTeamsAmount < 15;

  const timezoneSortHelper = ['America', 'Asia', 'Europe'];

  const customSort = (universe1, universe2) => {
    if (
      timezoneSortHelper.indexOf(universe1.timezone.split('/')[0]) >
      timezoneSortHelper.indexOf(universe2.timezone.split('/')[0])
    ) {
      return 1;
    }
    if (
      timezoneSortHelper.indexOf(universe1.timezone.split('/')[0]) <
      timezoneSortHelper.indexOf(universe2.timezone.split('/')[0])
    ) {
      return -1;
    }
    return 0;
  };
  const customFreeTeamsSort = (a, b) => {
    if (a.freeTeams === 0 && b.freeTeams !== 0) {
      return 1;
    }
    if (a.freeTeams !== 0 && b.freeTeams === 0) {
      return -1;
    }
    return 0;
  };

  universesList.sort(customSort);
  universesList.sort(customFreeTeamsSort);
  universesList.sort(sortByName);
  return (
    <>
      {/*  {noFreeTeams && <WaitlistPopup />} */}
      <StyledSignUpContainer>
        {!isMobile && (
          <StyledHeader>
            <StyledReactTooltip id="servers" type="light" effect="solid" place="bottom">
              Teams are organized in servers, each including 3 leagues, 30 teams and 5,000 players. Most matches play
              nightly at 7pm server time. If you select UK-based server, your team will play at 7pm UK time every night
            </StyledReactTooltip>
            <h3>
              Available servers <StyledInfoIcon iconsize="10" data-tip clickable data-for="servers" />
            </h3>

            <h3>
              Leagues <StyledInfoIcon iconsize="10" data-tip clickable data-for="leagues" />
            </h3>

            <StyledReactTooltip id="leagues" type="light" effect="solid" place="bottom">
              We have three leagues in each server. League 1 is the most competitive league while League 3 is the least
              competitive. The top three teams at the end of the season are promoted while the bottom three get demoted.
              A season lasts three weeks.
            </StyledReactTooltip>
          </StyledHeader>
        )}
        <StyledBody>
          <StyledServersContainer>
            <StyledServersList>
              {noFreeTeams ? (
                <StyledServer noIcon>No free servers available</StyledServer>
              ) : (
                universesList.map((universe) => {
                  const isActive = universe._id === activeUniverse;
                  const isFull = universe.freeTeams === 0;
                  return (
                    <StyledServer
                      isFull={isFull || noFreeTeams}
                      key={universe._id}
                      isActive={isActive}
                      onClick={() => setActiveServer(universe._id)}
                    >
                      {universe.name}
                      <StyledUniverseLocation isActive={isActive}>{universe.timezone}</StyledUniverseLocation>
                      {isMobile && <ServerAvailability isFull={isFull || noFreeTeams} />}
                    </StyledServer>
                  );
                })
              )}
            </StyledServersList>
          </StyledServersContainer>
          <StyledSelectContainer>
            <StyledServerSelect
              defaultValue={options.find((item) => item.label === 'Worldwide')}
              onChange={setUniverse}
              options={options}
              isSearchable={false}
              menuPlacement="auto"
              placeholder="Select"
              classNamePrefix="react-select"
            />
            {isMobile ? <StyledLocationWhiteIcon /> : <StyledLocationIcon />}
          </StyledSelectContainer>
          {isMobile ? (
            <StyledMobileSidebar noPadding customBg="#191919" isOpened={mobileSectionNameToDisplay === 'select-league'}>
              <SignUpLeagues noFreeTeamsAtUniverse={noFreeTeams} leaguesList={leaguesList} mobile />
            </StyledMobileSidebar>
          ) : (
            <SignUpLeagues noFreeTeamsAtUniverse={noFreeTeams} leaguesList={leaguesList} />
          )}
        </StyledBody>
      </StyledSignUpContainer>
    </>
  );
};
export default SignUpUniverses;
