import React from 'react';
import { useSelector } from 'react-redux';

import PlayerToSell from '../TeamSquad';

import {
  isFilterShown,
  getSelectedPlayerByIndexInSearchable,
} from '../../../../../../redux/selector/Transfers';

import { StyledTransfersSearch, StyledContainerForFilter } from './styled';

export default React.memo(function TransfersSearch() {
  const isShownFilter = useSelector(isFilterShown);
  const playerWithOffer = useSelector(getSelectedPlayerByIndexInSearchable);

  return (
    <>
      <StyledTransfersSearch isShownFilter={isShownFilter}>
        <StyledContainerForFilter className="filter-container" />
      </StyledTransfersSearch>
      {!isShownFilter && playerWithOffer && <PlayerToSell searchPage />}
    </>
  );
});
