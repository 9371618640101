import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/League';

const initialValue = {
  teamInfo: null,
  leagueId: '',
  leagueTeamLoader: null,
  table: {
    data: null,
    loading: null,
  },
  calendar: {
    data: { currentDate: null, matches: [], timezone: null },
    selectedMatch: {
      data: null,
      loading: null,
    },
    loading: false,
  },
  leaguesList: [],
  teamsList: {
    isLoading: false,
    teamsList: [],
  },
  playerInfo: null,
};
export const league = handleActions(
  {
    [action.setTeam]: (draft, { payload }) => {
      draft.teamInfo = payload.team;
      draft.leagueTeamLoader = false;
    },
    [action.leagueInitialDataLoaded]: (draft, { payload }) => {
      draft.leagueTeamLoader = true;
    },
    // ---------------TEAM-----------------------
    [action.removeTeam]: (draft, { payload }) => {
      draft.teamInfo = null;
    },
    [action.createCalendar]: (draft, { payload }) => {
      draft.calendar.data.currentDate = payload.currentDate;
      draft.calendar.data.timezone = payload.timezone;
      draft.calendar.data.matches = payload.matches;
      draft.calendar.loading = false;
    },
    [action.loadDataAboutCalendar]: (draft, { payload }) => {
      draft.calendar.loading = true;
    },
    [action.selectTeamMatch]: (draft, { payload: { data } }) => {
      draft.calendar.selectedMatch.data = data;
      draft.calendar.selectedMatch.loading = false;
    },
    [action.selectTeamMatchLoading]: (draft, { payload }) => {
      draft.calendar.selectedMatch.loading = true;
    },
    [action.setTeamsList]: (draft, { payload }) => {
      draft.teamsList.teamsList = payload.teams;
    },
    [action.setTeamListLoading]: (draft, { payload }) => {
      draft.teamsList.isLoading = payload;
    },
    // ---------------------LEAGUE TABLE LOADING--------------
    [action.getLeagueTable]: (draft, { payload }) => {
      draft.table.data = payload.teams.map((team, index) => ({
        ...team,
        number: index,
      }));
      draft.teamInfo = payload.teamData;
      draft.leagueId = payload.league;
      draft.table.loading = false;
    },
    [action.leagueTableDataLoaded]: (draft, { payload }) => {
      draft.table.loading = true;
    },
    [action.selectTeamMatch]: (draft, { payload }) => {
      draft.table.loading = true;
    },
    [action.setLeaguesList]: (draft, { payload }) => {
      draft.leaguesList = payload.data;
    },
    [action.setPlayerInfo]: (draft, { payload }) => {
      draft.playerInfo = payload;
    },
  },
  initialValue
);
