import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Friendly';

const initialValue = {
  loading: true,
  teams: {},
  friendlyOfferLoading: false,
  friendlyOfferResponse: null,
};
export const friendly = handleActions(
  {
    [action.getFriendlyTeamsLoading]: (draft, { payload }) => {
      draft.loading = payload;
    },
    [action.getFriendlyTeamsData]: (draft, { payload }) => {
      draft.teams = payload;
    },
    [action.friendlyOfferLoading]: (draft, { payload }) => {
      draft.friendlyOfferLoading = payload;
    },
    [action.friendlyOfferResponse]: (draft, { payload }) => {
      draft.friendlyOfferResponse = payload;
    },
  },
  initialValue
);
