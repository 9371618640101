import styled from 'styled-components';
import { StyledPopUpBlock } from '../styled';

export const StyledPopupHeader = styled.div`
  position: relative;
  background-color: #f7f7f7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0px 30px;
  z-index: 1;
  width: 100%;
  height: 85px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 50px;
    width: auto;
    height: auto;
    max-height: 50px;
  }
`;
export const StyledPopupContent = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

export const StyledPopUpBlockNoPadding = styled(StyledPopUpBlock)`
  width: 420px;
  padding: 0;
`;

export const StyledParagraph = styled.p`
  font-family: 'Gilroy';
  font-size: 15px;
  line-height: 26px;
  padding: 30px;
`;

export const StyledReportTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledReportNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const StyledReportName = styled.div`
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  margin-right: 18px;
  line-height: 18px;
  color: #000000;
`;

export const StyledNotAvailable = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  padding: 20px 0px;
  font-size: 14px;
  border-bottom: 0.3px solid #ccc;
  color: #666666;
  &:last-child {
    border-bottom: 0;
  }
`;

export const StyledCommentary = styled.p`
  width: 100%;
`;
