import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import { isAuth } from '../../../../../../redux/selector/User';
import { RouteLeavingGuard } from '../../../../../../components/RouterLeavingGuard';

import { StyledSaveButton, StyledLiveLabel, StyledSaveLabel } from './styled';
import {
  getMyMajorSquad,
  getCurrentStrategy,
  isCreatingNextMatch,
  isCreatedNextMatch,
  getCurrentMatchRunning,
  isStrategyLoading,
  getUnsavedChanges,
} from '../../../../../../redux/selector/Team';
import {
  teamCreateRequestForSaveStrategy,
  updateStrategyData,
  setUnsavedChanges,
} from '../../../../../../redux/actions/Team';
import { toastRender } from '../../../../../../service/toaster';
import { goToDiscordLogin } from '../../../../../../service/functions';

const generalTeamRool = {
  '4-4-2': {
    LS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-3-3': {
    LW: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RW: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(C)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-2-3-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    // CAM: [
    //   {
    //     name: "Empty",
    //     rating: "0",
    //   },
    // ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-5-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],

    'DM(C)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '3-5-2': {
    LS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    // CAM: [
    //   {
    //     name: "Empty",
    //     rating: "0",
    //   },
    // ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],

    'DM(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(C)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-4-1-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    // CAM: [
    //   {
    //     name: "Empty",
    //     rating: "0",
    //   },
    // ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],

    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
};

const ConfirmMatch = ({ activeItem, mindset, effort, mobile = false }) => {
  const [saveError, setSaveError] = React.useState();
  const dispatch = useDispatch();
  const strategy = useSelector(getCurrentStrategy);
  const currentMatch = useSelector(getCurrentMatchRunning);
  const MyTeam = useSelector(getMyMajorSquad);
  const isStrategyDataLoading = useSelector(isStrategyLoading);
  const isLoad = useSelector(isCreatingNextMatch);
  const isCreated = useSelector(isCreatedNextMatch);
  const isAuthenticated = useSelector(isAuth);
  const areUnsavedChangesAvailable = useSelector(getUnsavedChanges);

  const history = useHistory();

  const checkIfStaffChanged = MyTeam.map((i) => i._id).join('');

  const checkForEmptySlot = function (elm) {
    return elm === undefined;
  };

  const checkForCreating = function () {
    switch (true) {
      case isStrategyDataLoading || isLoad:
        return 'Loading...';
      case isCreated:
        return 'Confirmed';
      case !isCreated && !isEmpty(currentMatch):
        return 'Send order';
      case !isCreated && isEmpty(currentMatch):
        return 'Save';
      default:
        return '...';
    }
  };

  React.useEffect(() => {
    dispatch(updateStrategyData());
  }, [activeItem, mindset, effort, dispatch, checkIfStaffChanged]);

  const saveStrategy = function () {
    if (!isAuthenticated) {
      goToDiscordLogin();
    } else {
      if (isCreated || isLoad) return null;
      const MyTeamGrouped = groupBy(MyTeam, 'current_position');
      const idealTeamConfig = generalTeamRool[strategy];
      const CHECKED = Object.keys(idealTeamConfig).map((teamType) => MyTeamGrouped[teamType]);
      // TODO: Need's to remove such a lot of function for testing purposes
      const fromCheckedToGrouped = flatMap(CHECKED);
      if (CHECKED.some(checkForEmptySlot)) {
        setSaveError(true);
        toastRender('warning', 'You need eleven players on the pitch!');
      } else {
        setSaveError(false);
        dispatch(setUnsavedChanges(false));
        dispatch(teamCreateRequestForSaveStrategy(effort, mindset, strategy, fromCheckedToGrouped, currentMatch));
      }
    }
  };

  return (
    <>
      <RouteLeavingGuard
        saveStrategyCb={() => saveStrategy()}
        setSaveError={setSaveError}
        saveError={saveError}
        when={areUnsavedChangesAvailable}
        navigate={(path) => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          if (areUnsavedChangesAvailable) {
            return true;
          }
          return false;
        }}
        yes="Save strategy"
        no="Discard changes"
        content="You’ve made changes to your strategy but did not save them"
      />
      <StyledSaveButton
        className="save-strategy-section"
        mobile={mobile}
        isLive={!isEmpty(currentMatch)}
        primary
        disabled={isLoad || isStrategyDataLoading}
        onClick={() => saveStrategy()}
      >
        <StyledSaveLabel isMobile={mobile}>{checkForCreating()}</StyledSaveLabel>
        {!isEmpty(currentMatch) && !isStrategyDataLoading && <StyledLiveLabel isMobile={mobile}>LIVE!</StyledLiveLabel>}
      </StyledSaveButton>
    </>
  );
};

export default ConfirmMatch;
