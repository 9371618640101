import React from 'react';
import ContentLoader from 'react-content-loader';

const WinnerPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={130}
    height={130}
    viewBox="0 0 130 130"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="68" cy="35" r="31" />
    <rect x="4" y="74" rx="0" ry="0" width="120" height="2" />
    <rect x="14" y="95" rx="0" ry="0" width="97" height="20" />
  </ContentLoader>
);

export default WinnerPreloader;
