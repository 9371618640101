import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import FootballBg from '../../assets/img/football-bg.png';
import FootballBgMobile from '../../assets/img/football-bg-mobile.png';
import { ReactComponent as Logo } from '../../assets/img/tol-logo.svg';
import { ReactComponent as LogoMobile } from '../../assets/img/tol-logo-mobile.svg';
import { ReactComponent as StyledRightArrowWhiteIcon } from '../../assets/img/go-arrow-white.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/tooltip-info-white.svg';

export const StyledInfoIcon = styled(InfoIcon)`
  width: 12px;
  height: 12px;
  margin-left: 0px;
  ${({ iconsize }) => {
    if (iconsize)
      return css`
        width: ${iconsize}px;
        height: ${iconsize}px;
      `;
  }}
  &:focus,
  &:active {
    outline: none;
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: calc(63px + 16px);
  @media only screen and (max-width: 768px) {
    padding-top: 55px;
    overflow-y: scroll;
  }
`;
export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledContainerLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
`;

export const StyledLoginContainer = styled.main`
  z-index: 1;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  background-image: url(${FootballBg});
  background-color: #fff;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    background-image: url(${FootballBgMobile});
  }
`;

export const StyledLeftBlock = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: row;
  position: relative;
  /*   z-index: 1; */
  padding: 0 4%;

  > h1 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-align: center;
    margin-bottom: 0px;
    text-transform: none;
    @media screen and (max-width: 425px) {
      font-weight: 800;
      font-size: 31px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-bottom: 25px;
    }
  }
  > p {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    margin: 17px 30px 0;
    opacity: 0.9;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: normal;
    color: #ffffff;
    z-index: 1;
    @media screen and (max-width: 768px) {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
  > button,
  > a {
    outline: none;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 50px;
    text-decoration: none;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
    padding: 5% 10%;
    transition: 0.3s;
    background: #ffffff;
    &:focus,
    &:active {
      color: white;
      background: #0e4afb;
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 768px;
    width: 100%;
    height: auto;
  }
`;

export const StyledTOLLogo = styled(Logo)`
  width: 185px;
  height: auto;
`;
export const StyledTOLMobileLogo = styled(LogoMobile)`
  width: 98px;
  height: auto;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 4;
  width: 100%;
  padding: 10px 36px;
  height: 46px;
  background: #101010;
  @media screen and (max-width: 1100px) {
    padding: 10px 24px 10px 36px;
    justify-content: space-between;
  }
  @media screen and (max-width: 426px) {
    min-height: 55px;
    padding: 19px 24px 19px 36px;
  }
`;

export const StyledLeftHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 50px;
    background-color: #858585;
    border-radius: 6px;
    padding: 10px;
    margin-right: 10px;
  }
`;
export const StyledRightHeaderSection = styled.div`
  font-family: Gilroy;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    font-size: 14px;
  }
`;

export const StyledSignUpContainer = styled.div`
  margin: 0px 20px;
  border-radius: 4px;
  box-shadow: 8px 0 14px -2px rgba(112, 112, 112, 0.08);
  background-color: #fff;
  width: 345px;
  h3 {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #fff;
    background-color: #333333;
    height: 42px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.18px;
  }
`;

export const StyledLeftWhiteArrow = styled(StyledRightArrowWhiteIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  transform: rotate(-180deg);
`;
export const StyledRightWhiteArrow = styled(StyledRightArrowWhiteIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
`;

export const StyledBackButton = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  left: 65px;
  cursor: pointer;
`;
export const StyledMobileBackButton = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: relative;
`;

export const StyledTitle = styled.h2`
  color: #fff;
  font-size: 25px;
  line-height: 1.44;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  @media only screen and (max-width: 991px) {
    text-align: center;
    width: 100%;
    font-size: 18px;
  }
`;

export const StyledFilterTitle = styled.h2`
  text-align: left;
  padding-left: 35px;
  color: #fff;
  font-size: 18px;
  line-height: 1.44;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  @media only screen and (max-width: 991px) {
    padding-left: 25px;
  }
`;

export const StyledSubheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
`;
export const StyledMobileSubheader = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 12px 15px;
  background-color: #191919;
  z-index: 1;
`;

export const StyledLeagueCode = styled.span`
  color: #b2b2b2;
  text-decoration: underline;
  font-size: 12px;
`;
