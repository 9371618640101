export const favPositionSortIds = {
  GK: 20,
  LB: 19,
  'DC(R)': 18,
  'DC(L)': 17,
  'DC(C)': 16,
  RB: 15,
  'DM(C)': 14,
  'DM(R)': 13,
  'DM(L)': 12,
  CM: 11,
  LCM: 10,
  RCM: 9,
  LM: 8,
  RM: 7,
  CAM: 6,
  LW: 5,
  RW: 4,
  CF: 3,
  RS: 2,
  LS: 1,
};

export const replacePlayerColumns = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'P', accessor: 'favPositionSortId' },
  { Header: 'R', accessor: 'ratingId' },
  { Header: 'P', accessor: 'potentialId' },
  { Header: 'F', accessor: 'formId' },
];

export const rankingTableColumns = [
  { Header: 'POS', accessor: 'number' },
  { Header: 'Team', accessor: 'name' },
  { Header: 'W', accessor: 'win' },
  { Header: 'D', accessor: 'draw' },
  { Header: 'L', accessor: 'loss' },
  { Header: 'GD', accessor: 'gd' },
  { Header: 'PTS', accessor: 'points' },
];

export const transactionTypes = {
  0: 'Matchday',
  1: 'Broadcasting',
  2: 'Sponsorship',
  3: 'Merchandise',
  4: 'Player purchase',
  5: 'Player wages',
  6: 'Other club expenses',
  7: 'Player sale',
  8: 'Philanthropy',
  9: 'League penalty',
  10: 'Player purchase - International',
  11: 'Free Agent fee',
  12: 'Scouting',
  13: 'League ranking',
  14: 'Youth camp player',
  15: 'Release Player',
  16: 'Sign free agent',
  17: 'Player signings',
  18: 'Match forfeit',
  19: 'Scouting - International',
  20: 'Cup prize',
  22: 'Boost player development',
  23: 'Decline player development',
  24: 'Sending money to team',
  25: 'Money received from club',
  27: 'Loan in fees',
  28: 'Loan out fees',
  30: 'Loaned in players wages',
  31: 'TOL adjustment',
  33: 'Friendly match',
  34: 'Friendly match win reward',
  35: 'Friendly match draw reward',
  40: 'Disboard reward',
  47: 'Organize practice',
  50: 'Academy cost',
  60: 'Ads reward',
};

export const ratingRangeAbbr = {
  Weakest: 'W-',
  Weak: 'Wk',
  Average: 'A',
  Strong: 'S',
  'World Class': 'WC',
  Legend: 'L',
};

export const averageRatingVal = {
  Weakest: 20,
  Weak: 35,
  Average: 55,
  Strong: 75,
  'World Class': 90,
  Legend: 97,
};
export const rangeColor = {
  Weakest: '#d63c30',
  Weak: '#D86731',
  Average: '#FABB05',
  Strong: '#00996b',
  'World Class': '#69C7AF',
  Legend: '#2253c7',
};

export const formRangeStatus = {
  0: 'Exhausted',
  1: 'Tired',
  2: 'OK',
  3: 'Great',
  '-1': 'Injured',
};
export const formRangeColor = {
  Injured: '#d63c30',
  Exhausted: '#D86731',
  Tired: '#FABB05',
  OK: '#00996b',
  Great: '#2253c7',
};
export const formStrategyColor = {
  Injured: '#d63c30',
  Exhausted: '#D86731',
  Tired: '#FABB05',
  OK: '#21f861',
  Great: '#2253c7',
};

export const getMediaColor = (index) => {
  if (index < 3) {
    return '#d63c30';
  }
  if (index === 3 || index === 4) {
    return '#D86731';
  }
  if (index === 5 || index === 6) {
    return '#FABB05';
  }
  if (index > 6 && index < 10) {
    return '#00996b';
  }
  return '#2253c7';
};

export const mindsetStatusToValue = {
  2: 'Attack',
  1: 'Neutral',
  0: 'Defend',
};

export const mindsetColor = {
  Attack: '#00996b',
  Neutral: '#FABB05',
  Defend: '#d63c30',
};

export const effortStatusToValue = [
  { value: 2, label: 'Extreme effort' },
  { value: 1, label: 'High effort' },
  { value: 0, label: 'Low effort' },
];

export const setEffortValue = (val) =>
  effortStatusToValue.filter((item) => item.value === val);

export const phaseToRound = {
  0: 'Qualifiers',
  1: 'Quarter Finals - Round 1',
  2: 'Quarter Finals - Round 2',
  3: 'Semi Finals',
  4: 'Final',
  5: 'Completed',
};
export const teamRank = {
  0: 'Qualifiers',
  1: 'Quarter Finals',
  2: 'Semi Finals',
  3: 'Final',
  4: 'Winner',
};

export const hoursList = [
  {
    value: 0,
    label: '00:00am',
  },
  {
    value: 1,
    label: '01:00am',
  },
  {
    value: 2,
    label: '02:00am',
  },
  {
    value: 3,
    label: '03:00am',
  },
  {
    value: 4,
    label: '04:00am',
  },
  {
    value: 5,
    label: '05:00am',
  },
  {
    value: 6,
    label: '06:00am',
  },
  {
    value: 7,
    label: '07:00am',
  },
  {
    value: 8,
    label: '08:00am',
  },
  {
    value: 9,
    label: '09:00am',
  },
  {
    value: 10,
    label: '10:00am',
  },
  {
    value: 11,
    label: '11:00am',
  },
  {
    value: 12,
    label: '12:00pm',
  },
  {
    value: 13,
    label: '01:00pm',
  },
  {
    value: 14,
    label: '02:00pm',
  },
  {
    value: 15,
    label: '03:00pm',
  },
  {
    value: 16,
    label: '04:00pm',
  },
  {
    value: 17,
    label: '05:00pm',
  },
  {
    value: 18,
    label: '06:00pm',
  },
  {
    value: 19,
    label: '07:00pm',
  },
  {
    value: 20,
    label: '08:00pm',
  },
  {
    value: 21,
    label: '09:00pm',
  },
  {
    value: 22,
    label: '10:00pm',
  },
  {
    value: 23,
    label: '11:00pm',
  },
];

export const sortingPriority = {
  GK: 10,
  LB: 9,
  CB: 8,
  RB: 7,
  DM: 6,
  CM: 5,
  CAM: 4,
  LW: 3,
  RW: 2,
  ST: 1,
};

export const simplifiedPosition = {
  GK: 'GK',
  LB: 'LB',
  'DC(R)': 'CB',
  'DC(L)': 'CB',
  'DC(C)': 'CB',
  RB: 'RB',
  'DM(C)': 'DM',
  'DM(R)': 'DM',
  'DM(L)': 'DM',
  RCM: 'CM',
  LCM: 'CM',
  CM: 'CM',
  LM: 'LW',
  LW: 'LW',
  RM: 'RW',
  RW: 'RW',
  CAM: 'CAM',
  CF: 'ST',
  RS: 'ST',
  LS: 'ST',
};

export const positionButtons = [
  {
    label: 'ALL',
    value: ['GK', 'LB', 'CB', 'DM', 'RB', 'CM', 'CAM', 'LW', 'RW', 'ST'],
  },
  { label: 'GK', value: ['GK'] },
  {
    label: 'DEF',
    value: ['LB', 'CB', 'RB'],
  },
  {
    label: 'MID',
    value: ['CM', 'LW', 'RW', 'DM', 'CAM', 'DM'],
  },
  {
    label: 'FWD',
    value: ['ST', 'RW', 'LW'],
  },
];

export const playerEffectivenessIndicators = {
  GK: {
    GK: '#2253C7',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#C1372B',
    LW: '#C1372B',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
    RM: '#C1372B',
  },
  RB: {
    RB: '#2253C7',
    LB: '#E0A804',
    'DC(R)': '#E0A804',
    'DC(L)': '#E0A804',
    'DC(C)': '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#C1372B',
    LW: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
    RM: '#C1372B',
  },
  LB: {
    LB: '#2253C7',
    'DC(R)': '#E0A804',
    'DC(L)': '#E0A804',
    'DC(C)': '#E0A804',
    RB: '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#C1372B',
    LW: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
    RM: '#C1372B',
  },
  'DC(R)': {
    'DC(R)': '#2253C7',
    'DC(L)': '#2253C7',
    'DC(C)': '#2253C7',
    LB: '#E0A804',
    RB: '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#C1372B',
    LW: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
    RM: '#C1372B',
  },
  'DC(L)': {
    'DC(R)': '#2253C7',
    'DC(L)': '#2253C7',
    'DC(C)': '#2253C7',
    LB: '#E0A804',
    RB: '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#C1372B',
    LW: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
    RM: '#C1372B',
  },
  'DC(C)': {
    'DC(R)': '#2253C7',
    'DC(L)': '#2253C7',
    'DC(C)': '#2253C7',
    LB: '#E0A804',
    RB: '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#C1372B',
    LW: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
    RM: '#C1372B',
  },
  'DM(C)': {
    'DM(C)': '#2253C7',
    CAM: '#2253C7',
    'DM(R)': '#2253C7',
    'DM(L)': '#2253C7',
    CM: '#E0A804',
    LM: '#C1372B',
    RM: '#C1372B',
    GK: '#C1372B',
    LCM: '#E0A804',
    RCM: '#E0A804',
    RW: '#C1372B',
    LW: '#C1372B',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
  },
  CAM: {
    'DM(C)': '#2253C7',
    CAM: '#2253C7',
    'DM(R)': '#2253C7',
    'DM(L)': '#2253C7',
    CM: '#E0A804',
    LM: '#C1372B',
    RM: '#C1372B',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    RW: '#C1372B',
    LW: '#C1372B',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
  },
  'DM(R)': {
    'DM(C)': '#2253C7',
    CAM: '#2253C7',
    'DM(R)': '#2253C7',
    'DM(L)': '#2253C7',
    CM: '#E0A804',
    LM: '#C1372B',
    RM: '#C1372B',
    GK: '#C1372B',
    LCM: '#E0A804',
    RCM: '#E0A804',
    RW: '#C1372B',
    LW: '#C1372B',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
  },
  'DM(L)': {
    'DM(C)': '#2253C7',
    CAM: '#2253C7',
    'DM(R)': '#2253C7',
    'DM(L)': '#2253C7',
    CM: '#E0A804',
    LM: '#C1372B',
    RM: '#C1372B',
    GK: '#C1372B',
    LCM: '#E0A804',
    RCM: '#E0A804',
    RW: '#C1372B',
    LW: '#C1372B',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
  },
  CM: {
    CM: '#2253C7',
    LM: '#E0A804',
    'DM(C)': '#E0A804',
    CAM: '#E0A804',
    'DM(R)': '#E0A804',
    'DM(L)': '#E0A804',
    RM: '#E0A804',
    GK: '#C1372B',
    LCM: '#2253C7',
    RCM: '#2253C7',
    RW: '#E0A804',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
  },
  RCM: {
    CM: '#2253C7',
    LM: '#E0A804',
    'DM(C)': '#E0A804',
    CAM: '#E0A804',
    'DM(R)': '#E0A804',
    'DM(L)': '#E0A804',
    RM: '#E0A804',
    GK: '#C1372B',
    LCM: '#2253C7',
    RCM: '#2253C7',
    RW: '#E0A804',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
  },
  LCM: {
    CM: '#2253C7',
    LM: '#E0A804',
    'DM(C)': '#E0A804',
    CAM: '#E0A804',
    'DM(R)': '#E0A804',
    'DM(L)': '#E0A804',
    RM: '#E0A804',
    GK: '#C1372B',
    LCM: '#2253C7',
    RCM: '#2253C7',
    RW: '#E0A804',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#C1372B',
    RS: '#C1372B',
    LS: '#C1372B',
  },
  RM: {
    RW: '#2253C7',
    RM: '#2253C7',
    CM: '#C1372B',
    LM: '#E0A804',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    CF: '#E0A804',
    RS: '#E0A804',
    LS: '#E0A804',
  },
  LM: {
    LM: '#2253C7',
    LW: '#2253C7',
    CM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RM: '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    RW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',

    CF: '#E0A804',
    RS: '#E0A804',
    LS: '#E0A804',
  },
  RW: {
    RM: '#2253C7',
    RW: '#2253C7',

    CF: '#E0A804',
    RS: '#E0A804',
    LS: '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    LW: '#C1372B',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
  },

  LW: {
    LM: '#2253C7',
    LW: '#2253C7',
    CM: '#C1372B',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RM: '#E0A804',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    RW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',

    CF: '#E0A804',
    RS: '#E0A804',
    LS: '#E0A804',
  },
  ST: {
    CF: '#2253C7',
    RS: '#2253C7',
    LS: '#2253C7',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#E0A804',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#E0A804',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    RM: '#E0A804',
  },
  LS: {
    CF: '#2253C7',
    RS: '#2253C7',
    LS: '#2253C7',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#E0A804',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#E0A804',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    RM: '#E0A804',
  },
  RS: {
    CF: '#2253C7',
    RS: '#2253C7',
    LS: '#2253C7',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#E0A804',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#E0A804',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    RM: '#E0A804',
  },
  CF: {
    CF: '#2253C7',
    RS: '#2253C7',
    LS: '#2253C7',
    GK: '#C1372B',
    LCM: '#C1372B',
    RCM: '#C1372B',
    CM: '#C1372B',
    LM: '#E0A804',
    'DM(C)': '#C1372B',
    CAM: '#C1372B',
    'DM(R)': '#C1372B',
    'DM(L)': '#C1372B',
    RW: '#E0A804',
    LW: '#E0A804',
    LB: '#C1372B',
    'DC(R)': '#C1372B',
    'DC(L)': '#C1372B',
    'DC(C)': '#C1372B',
    RB: '#C1372B',
    RM: '#E0A804',
  },
};

export const emptyPlayerToDragPositionName = {
  LCM: 'midfielder',
  RCM: 'midfielder',
  CM: 'midfielder',
  LM: 'midfielder',
  'DM(C)': 'midfielder',
  CAM: 'midfielder',
  'DM(R)': 'midfielder',
  'DM(L)': 'midfielder',
  RW: 'midfielder',
  LW: 'midfielder',
  LB: 'defender',
  'DC(R)': 'defender',
  'DC(L)': 'defender',
  'DC(C)': 'defender',
  RB: 'defender',
  GK: 'goalkeeper',
  CF: 'forward',
  RS: 'forward',
  LS: 'forward',
  RM: 'midfielder',
};

export const strategyIds = {
  0: '4-4-2',
  1: '4-3-3',
  2: '4-2-3-1',
  3: '4-5-1',
  4: '3-5-2',
  5: '4-4-1-1',
};

export const transferFeelingColor = {
  0: '#d63c30',
  1: '#00996b',
  '-1': '#828282',
};

export const transferFeeling = {
  0: 'Miserable',
  1: 'Thriving',
  '-1': 'Neutral',
};
