import { headers } from './requestConfig';
import { stuffItems, stuffSelectKit } from '../service/links';

export const getStuffItems = async () => {
  const response = await fetch(stuffItems, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
export const selectKit = async (kit) => {
  const response = await fetch(stuffSelectKit, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      kit,
    }),
  });
  return response;
};
