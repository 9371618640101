import React from 'react';
import ContentLoader from 'react-content-loader';

const TasksListPreloader = (props) => (
  <ContentLoader
    speed={2}
    height={150}
    viewBox="0 0 300 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="30" y="10" rx="3" ry="3" width="233" height="20" />
    <rect x="30" y="50" rx="3" ry="3" width="233" height="20" />
    <rect x="30" y="90" rx="3" ry="3" width="233" height="20" />
  </ContentLoader>
);

export default TasksListPreloader;
