import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import {
  StyledPopUp,
  StyledCross,
  StyledPopUpContainer,
  StyledSpan,
} from '../styled';
import {
  StyledPopupHeader,
  StyledPopUpBlockNoPadding,
  StyledPopupContent,
  StyledReportName,
  StyledReportTitle,
  StyledReportNameContainer,
  StyledNotAvailable,
  StyledCommentary,
} from './styled';
import {
  StyledReportLabel,
  StyledInfoIcon,
  StyledScoutReport,
} from '../../../globalStyled';
import CommentIcon from '../../CommentIcon';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { getYouthCampPlayerDataById } from '../../../redux/selector/YouthCamp';

const ReadMoreFullPopup = ({ popupName, id }) => {
  const dispatch = useDispatch();
  const { first_name, last_name, scout_results } = useSelector(
    getYouthCampPlayerDataById(id)
  );

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };
  const fillArray = (arr, requiredLength) => {
    let newArr = [];
    if (!arr) {
      newArr = [{}, {}, {}];
    } else {
      newArr = arr;
      let i;
      for (i = newArr.length; i < requiredLength; i += 1) {
        newArr.push({});
      }
    }
    return newArr;
  };
  const renderComments = (results) => {
    const sortedRes = sortBy(results, ['youth_camp_level']);
    return fillArray(sortedRes, 3);
  };

  const modifiedArray = renderComments(scout_results);
  return (
    <StyledPopUp>
      <StyledPopUpBlockNoPadding>
        <StyledPopUpContainer>
          <StyledPopupHeader>
            <StyledSpan>
              {first_name} {last_name}
            </StyledSpan>
            <StyledCross onClick={() => hidePopup()} />
          </StyledPopupHeader>
          <StyledPopupContent>
            <StyledReportLabel>
              Scouting Reports <StyledInfoIcon iconsize="10" />
            </StyledReportLabel>
            {modifiedArray.map((item, index) =>
              !isEmpty(item) ? (
                <StyledScoutReport full>
                  <StyledReportTitle>
                    <StyledReportNameContainer>
                      <StyledReportName>REPORT {index + 1}</StyledReportName>
                      <CommentIcon type={item.data.commentary.type} />
                    </StyledReportNameContainer>
                  </StyledReportTitle>
                  <StyledCommentary>
                    {item.data.commentary.text}
                  </StyledCommentary>
                </StyledScoutReport>
              ) : (
                <StyledNotAvailable>Not available</StyledNotAvailable>
              )
            )}
          </StyledPopupContent>
        </StyledPopUpContainer>
      </StyledPopUpBlockNoPadding>
    </StyledPopUp>
  );
};

export default React.memo(ReadMoreFullPopup);
