import styled from 'styled-components';
import { StyledLoader } from '../../styled';

export const StyledNewLoader = styled(StyledLoader)`
  position: absolute;
`;

export const StyledFixturesWrapper = styled.div`
  width: 49%;
  max-width: 640px;
  margin-left: 20px;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
`;
