import styled, { css } from 'styled-components';
import { StyledButton } from '../../../../../../globalStyled';

export const StyledSaveLabel = styled.span`
  text-decoration: ${({ isMobile }) => (isMobile ? 'underline' : 'none')};
`;
export const StyledSaveButton = styled(StyledButton)`
  width: 100%;
  position: relative;
  margin-bottom: 0;
  font-size: 13px;
  padding: 6px 10px;
  margin: 0;
  height: 30px;
  border: 0;
  min-width: 135px;
  ${({ isLive }) => {
    if (isLive) {
      return css`
        padding: 6px 25px 6px 5px;
      `;
    }
  }}

  ${({ mobile }) => {
    if (mobile) {
      return css`
        background-color: #fff;
        padding: 0 10px;
        color: #000;
        font-weight: bold;
        box-shadow: none;
        border: 0;
        font-size: 13px;
        max-width: 200px;
        min-width: 100px;
        width: auto;
        white-space: nowrap;
        padding: 0;
      `;
    }
  }}
`;

export const StyledLiveLabel = styled.span`
  background-color: #ff0404;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 4px 0px 2px;
  padding: 3px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      position: relative;
      background-color: transparent;
      color: #ff0404;
      text-decoration: none;
      padding-left: 10px;
      border-radius: 0;
    `}
`;
