import React from 'react';
import ContentLoader from 'react-content-loader';

const FormationPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={260}
    height={273}
    viewBox="0 0 260 273"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="26" y="61" rx="4" ry="4" width="66" height="90" />
    <rect x="374" y="16" rx="4" ry="4" width="85" height="12" />
    <rect x="259" y="16" rx="3" ry="3" width="70" height="11" />
    <rect x="481" y="16" rx="3" ry="3" width="207" height="12" />
    <rect x="691" y="16" rx="3" ry="3" width="207" height="12" />
    <rect x="26" y="161" rx="4" ry="4" width="66" height="90" />
    <rect x="103" y="61" rx="4" ry="4" width="66" height="90" />
    <rect x="103" y="161" rx="4" ry="4" width="66" height="90" />
    <rect x="179" y="60" rx="4" ry="4" width="66" height="90" />
    <rect x="179" y="160" rx="4" ry="4" width="66" height="90" />
    <rect x="25" y="20" rx="0" ry="0" width="121" height="18" />
  </ContentLoader>
);

export default FormationPreloader;
