import React from 'react';
import { useSelector } from 'react-redux';
import { isYouthCampAvailable } from '../../../../../../redux/selector/YouthCamp';
import PageSideBarNavigation from '../../../../../PageSideBarNavigation';

const TransfersSide = () => {
  const isYCEnabled = useSelector(isYouthCampAvailable);
  const links = [
    {
      to: '/transfers/deals',
      text: 'Deals',
      isDisabled: false,
      showTooltip: true,
      tooltipContent:
        'DEALS is were current and past player transfer offers are filed',
    },
    {
      to: '/transfers/search-players',
      text: 'Search players',
      isDisabled: false,
      showTooltip: true,
      tooltipContent:
        'SEARCH is where you search for players in other teams and formulate new transfer or loan offers',
    },
    {
      to: '/youth-camp-signup',
      text: 'Youth camp',
      isDisabled: !isYCEnabled,
      showTooltip: true,
      tooltipContent:
        'YOUTH CAMP opens at the end of the season. This is where clubs find their next legendary players. Youth camp in lower leagues typically has the best talent',
    },
  ];
  return (
    <>
      <PageSideBarNavigation links={links} />
    </>
  );
};
export default TransfersSide;
