import styled, { css } from 'styled-components';
import { ReactComponent as Shield } from '../../../assets/img/shield.svg';

export const StyledGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 250px;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 1px;
  height: 48px;
  width: 100%;
  position: relative;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
  ${({ shortVersion }) => {
    if (shortVersion) {
      return css`
        border: 0;
        max-width: 90px;
        margin-bottom: 0;
      `;
    }
  }}
`;
export const StyledName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  font-size: 13px;
  background-color: #f2f2f2;
  height: 100%;
  padding-left: 10px;
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
  img {
    /*  padding: 0 10px; */
    max-width: 38px;
    max-height: 38px;
    width: 100%;
    height: 100%;
  }
  span {
    padding-left: 10px;
  }
  ${({ shortVersion }) => {
    if (shortVersion) {
      return css`
        width: 45px;
        background-color: transparent;
      `;
    }
  }}
`;
export const StyledGoals = styled.div`
  width: 36px;
  height: 100%;
  background-color: #000;
  color: #fff;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ goals }) => {
    if (goals === null) {
      return css`
        background-color: #e5e5e5;
        color: #999;
      `;
    }
  }}
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
  ${({ shortVersion }) => {
    if (shortVersion) {
      return css`
        margin-left: 10px;
        width: 24px;
        height: 24px;
      `;
    }
  }}
`;

export const StyledNoWinner = styled(Shield)`
  width: 22px;
`;
