import React from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { getUser, isAuth } from '../../redux/selector/User';
import { goToDiscordLogin } from '../../service/functions';

import {
  StyledAvatarContainer,
  StyledAvatar,
  StyledDropdownList,
  StyledTitle,
  StyledItem,
  StyledFooter,
  StyledName,
  StyledTextNavLink,
  DiscordLogoIcon,
} from './styled';

const UserDropdown = ({
  extend = false,
  openInvitePopupCallback = () => {},
  logoutCallback = () => {},
  leaveGameCallback = () => {},
  showTutorialCallback = () => {},
}) => {
  const { avatarID, username, discord } = useSelector(getUser);
  const isAuthenticated = useSelector(isAuth);

  const avatarUrl = `https://cdn.discordapp.com/avatars/${discord}/${avatarID}.jpg`;

  return (
    <StyledDropdownList>
      {extend && (
        <StyledTitle>
          <StyledAvatarContainer>
            {isAuthenticated ? (
              <StyledAvatar src={avatarUrl} alt="avatar" />
            ) : (
              <DiscordLogoIcon onClick={() => goToDiscordLogin()} />
            )}
          </StyledAvatarContainer>
          <StyledName>{username}</StyledName>
        </StyledTitle>
      )}
      <StyledItem
        isDefaultStyles
        onClick={() => {
          openInvitePopupCallback();
        }}
      >
        Invite a Friend
      </StyledItem>
      <StyledItem isDefaultStyles onClick={() => showTutorialCallback()}>
        Tutorial
      </StyledItem>
      {isMobile && (
        <>
          <StyledTextNavLink
            to="/privacy"
            target="_blank"
            rel="noreferrer noopener"
            href="#"
          >
            <span>Privacy</span>
          </StyledTextNavLink>
          <StyledTextNavLink
            to="/terms"
            target="_blank"
            rel="noreferrer noopener"
            href="#"
          >
            <span>Terms of use</span>
          </StyledTextNavLink>
        </>
      )}
      <StyledItem
        isAuthenticated={isAuthenticated}
        onClick={() => {
          if (isAuthenticated) {
            logoutCallback();
          }
        }}
      >
        Logout
      </StyledItem>
      <StyledFooter
        isAuthenticated={isAuthenticated}
        onClick={() => {
          if (isAuthenticated) {
            leaveGameCallback();
          }
        }}
      >
        Leave game
      </StyledFooter>
    </StyledDropdownList>
  );
};
export default UserDropdown;
