import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { isNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import {
  StyledWrapper,
  StyledDateCard,
  StyledSlider,
  StyledMatch,
  StyledFixturesContainer,
  StyledTeamName,
  StyledGoals,
  StyledSliderDate,
  StyledMatchday,
  StyledNoMatches,
} from './styled';
import { showMobileSidebarSection } from '../../redux/actions/Team';
import SliderArrowLeft from '../SliderArrow/SliderArrowLeft';
import SliderArrowRight from '../SliderArrow/SliderArrowRight';
import MatchesLoader from './MatchesLoader';
import FallbackContentAnimation from '../FallbackContentAnimation';
import { HomeSmallStat } from '../ContentPreloaders';
import { leagueSetTeamInfo } from '../../redux/actions/League';
import { POPUPS } from '../../service/constants';
import { showPopupWindow } from '../../redux/actions/Popups';

const FixtureSlider = ({ datesData, matches, userTeamId, isLoading }) => {
  const [activeDate, setActiveDate] = React.useState();
  const sliderRef = React.useRef();

  const dispatch = useDispatch();
  const [datesArray, indexOfToday] = datesData;
  const handleOnClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const showTeamInfo = (id) => {
    const { TEAM_INFO_POPUP } = POPUPS;
    dispatch(leagueSetTeamInfo({ _id: id }, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
    }
  };

  React.useEffect(() => {
    const activeDateIndex = indexOfToday;
    handleOnClick(activeDateIndex);
    setActiveDate(activeDateIndex);
  }, [datesArray, indexOfToday]);

  const LeftNavButton = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick}>
        <SliderArrowRight />
      </div>
    );
  };
  const RightNavButton = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick}>
        <SliderArrowLeft />
      </div>
    );
  };

  const settings = {
    className: 'slider variable-width',
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    swipeToSlide: true,
    centerPadding: '10px',
    afterChange: (current) => setActiveDate(current),
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const fixturesList = React.useMemo(
    () =>
      !isEmpty(matches) &&
      matches.find(
        (fixture) =>
          moment(fixture.match_date).format('ddd, DD MMM YYYY') ===
          datesArray[activeDate]
      ),
    [activeDate, matches, datesArray]
  );
  return (
    <StyledWrapper>
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <StyledSlider>
        <h3>Fixtures &amp; Results</h3>
        <Slider {...settings} ref={sliderRef}>
          {datesArray.map((date, ind) => (
            <FallbackContentAnimation
              loading={isLoading}
              fallback={
                <HomeSmallStat style={{ height: '50px', width: '100%' }} />
              }
            >
              <StyledDateCard onClick={() => handleOnClick(ind)} key={date}>
                <StyledSliderDate>
                  {moment(date).format('ddd, DD MMM YYYY')}
                </StyledSliderDate>
                <StyledMatchday>Matchday {ind + 1}</StyledMatchday>
              </StyledDateCard>
            </FallbackContentAnimation>
          ))}
        </Slider>
      </StyledSlider>
      <StyledFixturesContainer>
        <FallbackContentAnimation
          loading={isLoading}
          repeat="3"
          fallback={
            <>
              <MatchesLoader />
            </>
          }
        >
          <>
            {fixturesList ? (
              fixturesList.matches.map((match) => {
                const isHomeTeamYours = match.home_team._id === userTeamId;
                const isAwayTeamYours = match.away_team._id === userTeamId;
                const pastMatch =
                  isNumber(match.home_goals) && isNumber(match.away_goals);
                const matchGoals = pastMatch
                  ? `${match.home_goals}-${match.away_goals}`
                  : 'TBD';
                return (
                  <StyledMatch
                    key={`${match.home_team._id}_${match.away_team._id}`}
                    isHomeTeamYours={isHomeTeamYours}
                    isAwayTeamYours={isAwayTeamYours}
                    hasUserTeam={
                      match.home_team._id === userTeamId ||
                      match.away_team._id === userTeamId
                    }
                  >
                    <StyledTeamName
                      isTeamOwner={isHomeTeamYours}
                      alignment="flex-end"
                      leftPart
                      onClick={() => showTeamInfo(match.home_team._id)}
                    >
                      {match.home_team.name}
                      <img src={match.home_team.symbol} alt="home team logo" />
                    </StyledTeamName>
                    <StyledGoals futureMatch={!pastMatch}>
                      {matchGoals}
                    </StyledGoals>
                    <StyledTeamName
                      isTeamOwner={isAwayTeamYours}
                      alignment="flex-start"
                      rightPart
                      onClick={() => showTeamInfo(match.away_team._id)}
                    >
                      <img src={match.away_team.symbol} alt="away team logo" />
                      {match.away_team.name}
                    </StyledTeamName>
                  </StyledMatch>
                );
              })
            ) : (
              <StyledNoMatches>No matches</StyledNoMatches>
            )}
          </>
        </FallbackContentAnimation>
      </StyledFixturesContainer>
    </StyledWrapper>
  );
};
FixtureSlider.propTypes = {
  datesData: PropTypes.array,
  matches: PropTypes.array,
  userTeamId: PropTypes.string.isRequired,
};
FixtureSlider.defaultProps = {
  datesData: [],
  matches: [],
};
export default FixtureSlider;
