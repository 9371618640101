import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Crown } from '../../assets/img/crown.svg';
import { ReactComponent as StyledLeftArrowIcon } from '../../assets/img/go-arrow-left.svg';

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  max-width: 190px;
  white-space: normal;
  text-transform: none;
  text-align: center;
  &.__react_component_tooltip.show {
    color: #000 !important;
    border-radius: 6px;
    opacity: 1;
  }
`;
export const StyledPagination = styled.section`
  margin: 10px 0;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
`;

export const StyledThead = styled.thead`
  border-bottom: 1px solid #eee;
  ${({ display }) => {
    if (display === 'none')
      return css`
        display: none;
      `;
  }}
`;

export const StyledTbody = styled.tbody`
  /* border-top: 12px solid #e5e5e5; */
  &::before {
    content: '';
    display: block;
    height: 0px;
  }
`;

export const StyledTrTh = styled.tr``;

export const StyledTh = styled.th`
  padding: 6px 0 6px;
  background: #fff;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 1px;
  color: #000;
  &[type='POS'] {
    font-size: 0;
  }
  @media screen and (max-width: 426px) {
    ${({ type }) => {
      if (type === 'W' || type === 'L' || type === 'D' || type === 'GD') {
        return css`
          // display: none;
        `;
      }
    }}
  }

  /* @media screen and (max-width: 425px) {
    padding: 0;
  } */
  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0px;
  }
  ${({ display, titleNumberName, type }) => {
    if (titleNumberName)
      return css`
        display: none;
      `;
    if (display === 'none')
      return css`
        display: none;
      `;
    if (type === 'Number') {
      return css`
        width: 5%;
        min-width: 50px;
      `;
    }
    if (type === 'Name') {
      return css`
        width: 15%;
        min-width: 105px;
      `;
    }
    if (type === 'Team') {
      return css`
        text-align: left;
      `;
    }
    if (type === 'W') {
      return css`
        @media screen and(max-width:425px) {
          //   display: none;
        }
      `;
    }
    if (type === 'L') {
      return css`
        @media screen and(max-width:425px) {
          // display: none;
        }
      `;
    }
    if (type === 'GD') {
      return css`
        @media screen and(max-width:425px) {
          // display: none;
        }
      `;
    }
  }}
`;

export const StyledTrTd = styled.tr`
  border-bottom: 1px solid #eee;
  background: white;
  &:hover {
    cursor: pointer;
  }
  &:hover,
  &.clicked {
    cursor: pointer;
    background: #0e4afb;

    * {
      color: #ffffff !important;
      opacity: 1;
    }
  }
  &:nth-child(even) {
    background: #fafafa;
  }
  ${({ currentRate }) => {
    if (currentRate) {
      return css`
        transform: scaleX(1.02);
        box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        td {
          &:first-of-type {
            opacity: 1;
            font-weight: bold;
            position: relative;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
            &:before {
              position: absolute;
              left: -1px;
              content: '';
              width: 6px;
              top: 0;
              border-top-left-radius: 4px;
              height: 37px;
              background: #fdca40;
              border-bottom-left-radius: 4px;
            }
            @media only screen and (max-width: 768px) {
              padding-left: 7px;
            }
          }
          &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-right: 12px;
            @media only screen and (max-width: 768px) {
              padding-right: 5px;
            }
          }
          @media only screen and (max-width: 768px) {
            &[type='Team'] {
              padding-left: 3px;
            }
          }
        }
      `;
    }
  }}
  ${({ clicked }) => css`
    &.row-number-${clicked} {
      background: #0e4afb;
      pointer-events: none;
      [type='PTS'] {
        span {
          background: white;
          color: black;
        }
      }
      * {
        color: #ffffff;
      }
      @media only screen and (max-width: 768px) {
        background: #fff;
        * {
          color: #000;
        }
      }
    }
  `}
  &:hover,
  &.clicked {
    background: #0e4afb;
    * {
      color: #ffffff;
    }
  }
  &:last-of-type {
    border-bottom: 0px solid #bdbdbd;
    td {
      &:first-child {
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

export const StyledTd = styled.td`
  text-align: center;
  padding: 8px 0px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 100px;
  /* background: white; */
  /* @media screen and (max-width: 425px) {
    padding: 0;
  } */
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  ${({ titleCell, type }) => {
    if (titleCell)
      return css`
        text-align: left;
      `;
    if (type === 'PTS') {
      return css`
        span {
          background: rgba(14, 74, 251, 0.1);
          border: 1px solid rgba(187, 199, 234, 0.45);
          border-radius: 5px;
          padding: 0 11px;
        }
      `;
    }
    if (type === 'GD') {
      return css`
        padding-right: 10px;
      `;
    }
    if (type === 'L') {
      return css`
        padding-right: 6px;
      `;
    }
    if (type === 'W') {
      return css`
        padding-left: 3px;
      `;
    }
    if (type === 'NO' || type === 'POS') {
      return css`
        width: 60px;
        opacity: 0.5;
      `;
    }
    if (type === 'Team') {
      return css`
        text-align: left;
        width: inherit;
        white-space: nowrap;
        img {
          width: 18px;
          margin-bottom: -4px;
          margin-left: 5px;
        }
      `;
    }
  }}
`;

export const StyledLabel = styled.span`
  margin-left: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  background-color: #7289da;
  @media only screen and (max-width: 768px) {
    font-size: 8px;
    padding: 3px 5px;
  }
`;

export const StyledCrown = styled(Crown)`
  margin-left: 5px;
`;
export const StyledTitle = styled.h3`
  margin-left: 15px;
  height: 46px;
  background-color: #fff;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: row;
  @media only screen and (max-width: 991px) {
    margin-left: 0px;
    padding-left: 15px;
    background-color: transparent;
  }
  svg {
    margin-right: 20px;
  }
`;

export const StyledLeftArrow = styled(StyledLeftArrowIcon)``;
export const StyledMobileSidebar = styled.div`
  background: ${({ customBg }) => customBg || '#fff'};
  height: 100%;
  width: 100%;
  position: fixed;
  margin-left: -100%;
  z-index: 11;
  top: 45px;
  left: 0px;
  padding-top: 0px;
  transition: all 0.2s ease 0s;
  overflow: auto;
  padding-bottom: 10px !important;
  ${({ isOpened }) => {
    if (isOpened) {
      return css`
        width: 100%;
        margin-left: 0;
      `;
    }
  }}
  ${({ isOpened }) => {
    if (isOpened) {
      return css`
        width: 100%;
      `;
    }
  }}
`;
