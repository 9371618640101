import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import UserToReplace from './components/UserToReplace';

import {
  getSelectedPlayer,
  sectionNameToDisplay,
} from '../../../../../../../../redux/selector/Team';
import FallbackContentAnimation from '../../../../../../../FallbackContentAnimation';
import { SelectPlayerPreloader } from '../../../../../../../ContentPreloaders';
import { StyledContainer, StyledMobileSidebar } from './styled';

function ReplacePlayer({ isLoad, activeDraggingId }) {
  const selectedPlayer = useSelector(getSelectedPlayer);
  const mobileSectionNameToDisplay = useSelector(sectionNameToDisplay);
  return (
    <>
      {isMobile ? (
        <StyledMobileSidebar
          noPadding
          customBg="#e5e5e5"
          isOpened={mobileSectionNameToDisplay === 'list'}
        >
          <UserToReplace selectedPlayer={selectedPlayer} />
        </StyledMobileSidebar>
      ) : (
        <StyledContainer isLoad={isLoad}>
          <FallbackContentAnimation
            loading={isLoad}
            fallback={<SelectPlayerPreloader />}
          >
            <UserToReplace
              selectedPlayer={selectedPlayer}
              activeDraggingId={activeDraggingId}
            />
          </FallbackContentAnimation>
        </StyledContainer>
      )}
    </>
  );
}

export default ReplacePlayer;
