import { createAction } from 'redux-act';
import { getFriendlyTeams, sendFriendlyOffer } from '../../../api/friendly';
import { POPUPS } from '../../../service/constants';
import { toastRender } from '../../../service/toaster';
import { hidePopupWindow } from '../Popups';

const { FRIENDLY_POPUP } = POPUPS;

export const getFriendlyTeamsLoading = createAction('friendly teams loading');
export const getFriendlyTeamsData = createAction('get friendly teams');

export const getFriendlyList = () => async (dispatch) => {
  dispatch(getFriendlyTeamsLoading(true));
  const response = await getFriendlyTeams();
  if (response.status === 200) {
    const data = await response.json();
    dispatch(getFriendlyTeamsData(data));
  } else {
    console.log('error');
  }
  dispatch(getFriendlyTeamsLoading(false));
};

export const friendlyOfferLoading = createAction('friendly offer loading');
export const friendlyOfferResponse = createAction('friendly offer done');

export const sendFriendlyRequest = (teamId) => async (dispatch) => {
  dispatch(friendlyOfferLoading(true));
  const response = await sendFriendlyOffer(teamId);
  if (response.status === 200) {
    dispatch(friendlyOfferResponse(teamId));
    toastRender(
      'success',
      'We reached out to the other team to confirm. Check DMs from Chris.'
    );

    setTimeout(() => {
      dispatch(hidePopupWindow(FRIENDLY_POPUP));
      dispatch(friendlyOfferResponse(null));
    }, 1500);
  } else if (response.status === 201) {
    toastRender('error', 'Teams cannot play two friendly at the same time.');
  } else if (response.status === 202) {
    toastRender(
      'error',
      'Friendly matches are not allowed at this time of day.'
    );
  } else if (response.status === 203) {
    toastRender('error', 'Bot-managed teams cannot play friendly matches.');
  } else {
    toastRender('error', 'We encountered an error');
  }
  dispatch(friendlyOfferLoading(false));
};
