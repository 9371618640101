import React from 'react';
import { useTable } from 'react-table';
import {
  StyledTable,
  StyledThead,
  StyledTbody,
  StyledTh,
  StyledTd,
  StyledTrTh,
  StyledTrTd,
} from './styled';
import { formRangeStatus } from '../../../../service/data';
import { ratingRangeStatus } from '../../../../service/constants';

const TableContainer = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <StyledTable {...getTableProps()}>
        <StyledThead>
          {headerGroups.map((headerGroup) => (
            <StyledTrTh {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledTh type={column.Header} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </StyledTh>
              ))}
            </StyledTrTh>
          ))}
        </StyledThead>
        <StyledTbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <StyledTrTd
                className={`row-number-${row.id}`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <StyledTd type={cell.column.Header} {...cell.getCellProps()}>
                    {(function () {
                      switch (cell.column.Header) {
                        case 'Date':
                          return cell.row.original.date;
                        case 'Potential':
                          return (
                            <>
                              {ratingRangeStatus[cell.row.original.potential]}
                            </>
                          );
                        case 'Rating':
                          return (
                            <>
                              {ratingRangeStatus[cell.row.original.rating] ||
                                'Unknown'}
                            </>
                          );
                        case 'Fitness':
                          return (
                            <>{formRangeStatus[cell.row.original.fitness]}</>
                          );
                        default:
                          return cell.render('Cell');
                      }
                    })()}
                  </StyledTd>
                ))}
              </StyledTrTd>
            );
          })}
        </StyledTbody>
      </StyledTable>
    </>
  );
};

export default React.memo(TableContainer);
