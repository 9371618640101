import React from 'react';
import { RampUnit } from "@playwire/pw-react-component";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  StyledLoginContainer,

  StyledHeader,
  StyledLeftHeaderSection,
  StyledTOLLogo,

} from './styled';

const RampComponent = () => (
  <>
  <HelmetProvider>
    <Helmet>
      <script type="text/javascript">
         {`window.ramp = window.ramp || {};
         window.ramp.que = window.ramp.que || [];
         window.ramp.passiveMode = true;`}
      </script>
      <script>
        {`      // Define the ad units that you will use with an array
      var pwUnits = [
        {
          // You can define the selectorId however you want, but the type must match to the ad unit's type
          selectorId: 'top-leaderboard',
          type: 'leaderboard_atf'
        },
        {
          selectorId: 'top-med-rect',
          type: 'med_rect_atf'
        }
      ]
  
      // Define the init function
      var init = function () {
          ramp
          // pass in the array 'pwUnits' defined right above
          .addUnits(pwUnits)
          .then(() => {
              ramp.displayUnits()
          }).catch( (e) =>{
              // catch errors
              ramp.displayUnits()
              console.log(e)
          })
  }
   ramp.que.push(init);`}
        </script>
        <script type="text/javascript"
       async="true"
       src="//cdn.intergient.com/{publisher ID}/{website ID}/ramp.js" />
        </Helmet>
            </HelmetProvider>
    <StyledLoginContainer>
      <StyledHeader>
        <StyledLeftHeaderSection>
          <StyledTOLLogo />
        </StyledLeftHeaderSection>
      
      </StyledHeader>
      </StyledLoginContainer>
    <section id="content">
      <div id="top-leaderboard" />
    </section>
    <section id="sidebar">
      <div id="top-med-rect" />
    </section>
  </>
);
export default RampComponent;
          