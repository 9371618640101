import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import UserGuide from '../../components/UserGuide';
import { StyledContainer } from './styled';
import { StyledWrapper } from '../styled';

export default function UserGuideComponent() {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/user-guide') {
      history.push('/user-guide/start');
    }
  }, [history, location.pathname]);

  return (
    <StyledWrapper>
      <StyledContainer>
        <Switch>
          <Route
            path="/user-guide/start"
            render={() => <UserGuide content="start" />}
          />
          <Route
            path="/user-guide/players"
            render={() => <UserGuide content="players" />}
          />
          <Route
            path="/user-guide/off-season"
            render={() => <UserGuide content="off-season" />}
          />
          <Route
            path="/user-guide/trading"
            render={() => <UserGuide content="trading" />}
          />
          <Route
            path="/user-guide/competitions"
            render={() => <UserGuide content="competitions" />}
          />
          <Route
            path="/user-guide/coaching"
            render={() => <UserGuide content="coaching" />}
          />
          <Route
            path="/user-guide/finances"
            render={() => <UserGuide content="finances" />}
          />
        </Switch>
      </StyledContainer>
    </StyledWrapper>
  );
}
