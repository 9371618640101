import React from 'react';
import ContentLoader from 'react-content-loader';

const FinanceCashPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={210}
    height={92}
    viewBox="0 0 210 92"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="25" y="45" rx="3" ry="3" width="80" height="12" />
    <rect x="25" y="20" rx="3" ry="3" width="90" height="16" />
  </ContentLoader>
);

export default FinanceCashPreloader;
