import React from 'react';

import PageSideBarNavigation from '../../../../../PageSideBarNavigation';

export default function StuffSide() {
  const links = [
    { to: '/stuff/team-kits', text: 'Team Kits', isDisabled: false },
    { to: '/stuff/special', text: 'Special Kits', isDisabled: true },
    { to: '/stuff/other', text: 'Other', isDisabled: true },
  ];

  return (
    <>
      <PageSideBarNavigation links={links} />
    </>
  );
}
