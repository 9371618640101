import styled from 'styled-components';
import Select from 'react-select';

export const StyledOpenFilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: -5px 7px 4px rgba(0, 0, 0, 0.03);
`;

export const StyledOpenFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > span {
    margin: 0 18px;
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    color: #333333;
  }
`;

export const StyledSelectContainer = styled.div`
  width: 420px;
  position: absolute;
  right: 0;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
  @media screen and (min-width: 992px) and (max-width: 1204px) {
    flex: none;
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    flex-direction: row;
    position: relative;
    width: 100%;
    display: flex;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
  }
  > input {
    border: none;
  }
`;

export const StyledSelect = styled(Select)`
  width: 165px;
  margin-right: 5px;
  margin-left: 5px;
  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type {
    margin-left: 0;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 5px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    width: 120px;
  }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    width: 33%;
  }
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    background-color: #efefef;
    color: #000;
    border: solid 1px #d1d1d1;
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }
`;

export const StyledFilterBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

export const StyledWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  @media only screen and (max-width: 991px) {
    width: 100%;
    max-width: 100%;
  }
`;
