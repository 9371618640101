import {
  HOME_LOCATION,
  SQUAD_LOCATION,
  STRATEGY_LOCATION,
  TRANSFER_DEALS_LOCATION,
  TRANSFER_SEARCH_LOCATION,
  FINANCE_LOCATION,
  HOME_STORAGE,
  SQUAD_STORAGE,
  STRATEGY_STORAGE,
  DEALS_STORAGE,
  SEARCH_STORAGE,
  FINANCE_STORAGE,
  PLAYER_INFO_STORAGE,
} from './constants';

export const locationToStorage = (location, additionalParam = false) => {
  if (location === HOME_LOCATION) {
    return localStorage.setItem(HOME_STORAGE, true);
  }
  if (location === SQUAD_LOCATION) {
    return additionalParam
      ? localStorage.setItem(PLAYER_INFO_STORAGE, true)
      : localStorage.setItem(SQUAD_STORAGE, true);
  }
  if (location === STRATEGY_LOCATION) {
    return localStorage.setItem(STRATEGY_STORAGE, true);
  }
  if (location === TRANSFER_DEALS_LOCATION) {
    return localStorage.setItem(DEALS_STORAGE, true);
  }
  if (location === TRANSFER_SEARCH_LOCATION) {
    return localStorage.setItem(SEARCH_STORAGE, true);
  }
  if (location === FINANCE_LOCATION) {
    return localStorage.setItem(FINANCE_STORAGE, true);
  }
};

export const tourStorageValue = {
  [HOME_LOCATION]: HOME_STORAGE,
  [SQUAD_LOCATION]: SQUAD_STORAGE,
  [STRATEGY_LOCATION]: STRATEGY_STORAGE,
  [TRANSFER_DEALS_LOCATION]: DEALS_STORAGE,
  [TRANSFER_SEARCH_LOCATION]: SEARCH_STORAGE,
  [FINANCE_LOCATION]: FINANCE_STORAGE,
};
