import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpContainer,
  StyledParagraph,
  StyledHeader,
  StyledLoader,
} from '../styled';

import { StyledPopUpBlockNoPadding, StyledCrossIcon } from './styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { getPopupByName } from '../../../redux/selector/Popups';
import TeamInfo from '../../SideBar/components/RightSideBlock/pages/TeamInfo';

const TeamInfoPopup = ({ popupName }) => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(getPopupByName(popupName));

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlockNoPadding>
        <StyledCrossIcon onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#2AFC98"
              height={100}
              width={100}
            />
          ) : (
            <>
              {error ? (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>

                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                </>
              ) : (
                <>
                  <TeamInfo />
                </>
              )}
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlockNoPadding>
    </StyledPopUp>
  );
};

export default React.memo(TeamInfoPopup);
