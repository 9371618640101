import React from 'react';
import PropTypes from 'prop-types';

const FallbackContentAnimation = ({
  loading,
  fallback: Fallback,
  repeat = 1,
  children,
}) => {
  const renderFallbackRepetition = Array(parseInt(repeat)).fill(Fallback);
  return <>{loading ? renderFallbackRepetition : <>{children}</>}</>;
};
FallbackContentAnimation.propTypes = {
  loading: PropTypes.bool,
  fallback: PropTypes.node.isRequired,
  repeat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
};
FallbackContentAnimation.defaultProps = {
  loading: false,
  repeat: 1,
};
export default FallbackContentAnimation;
