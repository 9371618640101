export const UNKNOWN_POTENTIAL = 'Unknown';
export const SCOUTING = 'Scouting';
// TODO Add popups names here

export const POPUPS = {
  ACCEPT_OFFER_POPUP: 'acceptOfferPopup',
  ADD_NICKNAME_POPUP: 'addNicknamePopup',
  BID_POPUP: 'bidPopup',
  CANCEL_OFFER_POPUP: 'cancelOfferPopup',
  CONFIRM_ACCELERATE_POPUP: 'confirmAcceleratePopup',
  CONFIRM_DECLINE_DELAY_POPUP: 'confirmDeclineDelayPopup',
  DISCORD_LOGIN_POPUP: 'discordLoginPopup',
  FREE_AGENT_POPUP: 'freeAgent',
  INVITE_POPUP: 'invitePopup',
  LEAVE_POPUP: 'leavePopup',
  LOAN_OFFER_POPUP: 'loanPopup',
  NO_TEAM_POPUP: 'noTeamPopup',
  NOTIFICATION_POPUP: 'notificationPopup',
  OWN_TEAM_POPUP: 'ownTeamPopup',
  PLAYER_INFO_POPUP: 'playerInfoPopup',
  PLAYER_INFO_POPUP_VIEW: 'playerInfoPopupView',
  READ_MORE_FULL_POPUP: 'readMoreFullPopup',
  READ_MORE_POPUP: 'readMore',
  REJECT_OFFER_POPUP: 'rejectOfferPopup',
  RELEASE_PLAYER_POPUP: 'releasePlayer',
  SCOUT_POPUP: 'scoutPopup',
  SELECT_LEAGUE_CODE_POPUP: 'selectLeagueCodePopup',
  TEAM_INFO_POPUP: 'teamInfoPopup',
  TRANSFER_SEARCH_POPUP: 'BuyOfferPopup',
  TERMS_POPUP: 'termsPopup',
  NO_FREE_TEAM_POPUP: 'noFreeTeam',
  FRIENDLY_POPUP: 'friendlyPopup',
  TRAINING_POPUP: 'trainingPopup',
  REJOIN_DISCORD_POPUP: 'rejoinDiscordPopup',
  CONFIRM_ACADEMY_POPUP: 'confirmAcademyPopup',
  WELCOME_POPUP: 'welcomePopup',
  NON_DISCORD_POPUP: 'noDiscordPopup',
  NO_SAVING_STRATEGY_POPUP: 'noSavingStrategyPopup',
  CONTRACT_OFFER_POPUP: 'contractOfferPopup',
  CONTRACT_EXTEND_POPUP: 'contractExtendPopup',
};

export const calendarMatch = {
  0: 'League',
  1: 'Cup',
};
export const calendarCupPhase = {
  1: 'Qual',
  2: 'Quarter',
  3: 'Quarter',
  4: 'Semi',
  5: 'Final',
};

export const ratingRangeStatus = {
  0: 'Weakest',
  1: 'Weak',
  2: 'Average',
  3: 'Strong',
  4: 'World Class',
  5: 'Legend',
};

export const STRATEGY_NAMES = {
  '4-4-2': 0,
  '4-3-3': 1,
  '4-2-3-1': 2,
  '4-5-1': 3,
  '3-5-2': 4,
  '4-4-1-1': 5,
};

export const minWageByRatingRange = [5000, 40000, 50000, 100000, 200000, 400000];