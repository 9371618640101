import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { StyledWrapper } from '../styled';

export const StyledWrapperContainer = styled(StyledWrapper)`
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 426px) {
    flex-direction: column;
  }
`;
export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledContainerLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
`;

export const StyledSelect = styled(Select)`
  width: 240px;
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    width: 220px;
    margin-top: 10px;
  }

  .react-select__menu {
    box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
    border: solid 0.5px #e1e1e1;
  }
  .react-select__value-container {
    span {
      font-size: 12px;
      color: #fff;
    }
  }

  .react-select__control {
    height: 25px;
    min-height: 25px;
    color: #fff;
    border: solid 1px #797979;
    background-color: #000;
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.43px;
    color: #7f7f7f;
  }
  .react-select__indicator {
    padding: 0 8px;
    svg {
      width: 15px;
      fill: #fff;
    }
  }

  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #666;
    svg {
      margin-right: 5px;
    }
    &--is-focused,
    &--is-selected {
      background-color: #fafafa;
      color: #333;
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 991px) {
    height: 145px;
  }
`;

export const StyledLeagueSelection = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  padding: 55px 50px;
  background: black;

  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 5px;
    background-image: linear-gradient(
      266deg,
      rgba(0, 0, 0, 0.23) 98%,
      rgba(0, 0, 0, 1) 20%
    );
    opacity: 0.65;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 20px;
    z-index: 1;
  }
`;

export const StyledTextedDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > p {
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: #ffffff;
    opacity: 0.5;
    margin-top: 10px;
    margin-bottom: 15px;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
  }
  > h1 {
    margin: 0;
    position: relative;
    z-index: 1;
    color: #ffffff;
    text-transform: none;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
      padding-bottom: 10px;
    }
    svg {
      margin-left: 5px;
    }
  }
`;

export const StyledLeagueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 46%;
  max-width: 600px;
  position: relative;
  margin-bottom: 23px;
  margin-right: 20px;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    margin-right: 0;
  }
  @media only screen and (min-width: 769px) and (max-width: 1199px) {
    max-width: 600px;
    width: 100%;
  }
`;

export const StyledPlayingSectionPreloader = styled.div`
  border-radius: 4px;
  background-color: #fff;
`;

export const StyledSectionPreloader = styled(StyledPlayingSectionPreloader)`
  margin-left: 25px;
`;
