import { createSelector } from 'reselect';

export const getInfoAboutTipsDisplay = (state) => state.home;
export const getInfoAboutDataHome = (state) => state.home.data;

export const tipsDisplay = createSelector(
  getInfoAboutTipsDisplay,
  ({ tipsShow }) => tipsShow
);
export const homeIsLoaded = createSelector(
  getInfoAboutTipsDisplay,
  ({ loading }) => loading
);
export const homeData = createSelector(
  getInfoAboutTipsDisplay,
  ({ data }) => data
);

export const learningFromLastMatch = createSelector(
  getInfoAboutDataHome,
  ({ learning }) => learning
);
export const isOpponentPremium = createSelector(
  getInfoAboutDataHome,
  ({ opponentTeamPremium }) => opponentTeamPremium
);
