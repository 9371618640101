import styled from "styled-components";

export const StyledWindow = styled.section`
  padding: 90px 0;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 15px;
  h2 {
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    letter-spacing: -0.63px;
    margin-bottom: 65px;
  }
`;

export const StyledForm = styled.form`
  padding-right: 7%;
`;

export const StyedGroupDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 31px 0;
  label {
    flex: 1;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    color: #979797;
    margin-right: 16px;
  }
  input {
    padding: 0 48px;
    flex: 2;
    min-width: 320px;
    min-height: 46px;
    background: #f3f3f3;
    border-radius: 10px;
    border: none;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    /* opacity: 0.3; */
  }
`;
