import React from 'react';
import { StyledSelectItem } from './styled';

export const leaugesOptions = (list) =>
  list.map((item) => ({
    label: (
      <StyledSelectItem>
        <img src={item.symbol} alt="logo" />
        <span>{item.name}</span>
      </StyledSelectItem>
    ),
    value: item._id,
  }));

export const teamsOptions = (list, _id) =>
  list.map((item) => ({
    label: (
      <StyledSelectItem>
        <img src={item.symbol} alt="logo" />
        <span>{item.name}</span>
      </StyledSelectItem>
    ),
    value: item._id,
  }));
