import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';

export const StyledOL = styled.ol`
  padding-left: 25px;
`;

export const StyledSectionHeading = styled.h3`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 20px;
`;

export const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  height: 100vh;
`;
export const StyledDarkSection = styled.section`
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #0a0b0d;

  height: auto;

  > img {
    position: absolute;
    max-height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    width: auto;
    left: 50%;
    top: 0;
    @media only screen and (max-width: 991px) {
      opacity: 0.4;
      max-width: 500px;
      width: 100%;
      left: 0;
    }
  }

  @media only screen and (max-width: 991px) {
    max-height: 500px;
  }
`;
export const StyledHeader = styled.header`
  z-index: 2;
  display: flex;
  height: 100px;
  width: 100%;
  background-color: #000;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  @media only screen and (max-width: 991px) {
    padding: 20px;
    height: 55px;
    background-color: #000;
  }
  img {
    width: 180px;
    margin-right: 10px;
    @media only screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;

export const StyledLeftHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  img {
    width: 180px;
    margin-right: 10px;
    @media only screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;

export const StyledDetailsContainer = styled.section`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-items: center;
  background-color: #fff;
  @media only screen and (max-width: 991px) {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 100%;
  }

  ${({ grey }) => {
    if (grey) {
      return css`
        margin-top: -70px;
        padding: 150px 0;

        background-color: #f8f8f8;
        @media only screen and (max-width: 991px) {
          flex-direction: column-reverse;
          margin-top: 0;
          padding: 50px 0 0 0;
        }
      `;
    }
  }}
  ${({ small }) => {
    if (small) {
      return css`
        padding: 50px 0;
      `;
    }
  }};
`;

export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledContainerLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
`;

export const StyledAuthor = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 100;
  line-height: 50px;
  font-size: 30px;
  @media only screen and (max-width: 991px) {
    font-size: 25px;
  }
`;

export const StyledSidebarContainer = styled.div`
  padding: 30px;
  width: 150px;
  background-color: #efecec;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
export const StyledMainContainer = styled.div`
  padding: 30px;
  width: 75%;
  background-color: #fff;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  .youtube-container {
    width: 100%;
    margin-bottom: 25px;
    iframe {
      width: 100%;
    }
  }
`;

export const StyledSectionTitle = styled.h2`
  font-family: 'Gilroy', sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 20px;
`;

export const StyledParagraph = styled.p`
  margin-bottom: 20px;

  img {
    max-width: 50%;
    padding: 20px;
  }
  ${({ small }) => {
    if (small) {
      return css`
        font-family: 'Gilroy', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.4px;
        text-align: left;
      `;
    }
  }}
  ${({ big }) => {
    if (big) {
      return css`
        font-family: 'Gilroy', sans-serif;
        font-weight: 300;
        font-size: 20px;
        line-height: 39px;
        letter-spacing: 0.5px;
        text-align: left;
      `;
    }
  }}
`;

export const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
  margin-top: 50px;
  margin-bottom: 20px;
`;
