import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Icon } from '../../assets/img/SimpleArrow.svg';
import {
  StyledOpenFilterButton,
  StyledOpenFilterContainer,
  StyledSelectContainer,
  StyledSelect,
  StyledFilterBlock,
  StyledWrapper,
} from './styled';

import { getYouthCampPlayers } from '../../redux/selector/YouthCamp';
import { setYouthCampFilteredPlayers } from '../../redux/actions/YouthCamp';

import { filterYCPlayers } from '../../service/utils';

const YouthCampFilter = () => {
  const [isFilterVisible, toggleFilter] = React.useState(false);
  const [zoneVal, setZoneValue] = React.useState('');
  const [positionVal, setPositionValue] = React.useState('');
  const [statusVal, setStatusValue] = React.useState('');
  const youthCampPlayers = useSelector(getYouthCampPlayers);
  const dispatch = useDispatch();
  const zoneOptions = [
    { value: 'OFF', label: 'OFF' },
    { value: 'MID', label: 'MID' },
    { value: 'DEF', label: 'DEF' },
    { value: 'GK', label: 'GK' },
  ];

  const positionOptions = [
    { value: 'GK', label: 'GK' },
    { value: 'LB', label: 'LB' },
    { value: 'CB', label: 'CB' },
    { value: 'RB', label: 'RB' },
    { value: 'DM', label: 'DM' },
    { value: 'CM', label: 'CM' },
    { value: 'RM', label: 'RW' },
    { value: 'CAM', label: 'CAM' },
    { value: 'LW', label: 'LW' },
    { value: 'RW', label: 'RW' },
    { value: 'ST', label: 'ST' },
  ];

  const statusOptions = [
    { value: 'Scouting', label: 'Scouting' },
    { value: 'Scouted', label: 'Scouted' },
    { value: 'Bidding', label: 'Bidding' },
    { value: 'Available', label: 'Available' },
  ];
  const handleMaxWidth = function () {
    const height = window.innerHeight;
    if (height <= 300) return 100;
    if (height <= 400) return 150;
    if (height <= 500) return 200;
    if (height <= 600) return 250;
    return 300;
  };
  const zoneCallback = (zoneValue) => {
    setZoneValue(zoneValue === null ? '' : zoneValue.value);
  };
  const positionCallback = (posVal) => {
    setPositionValue(posVal === null ? '' : posVal.value);
  };
  const statusCallback = (statusValue) => {
    setStatusValue(statusValue === null ? '' : statusValue.value);
  };

  useEffect(() => {
    const arr = filterYCPlayers(
      youthCampPlayers,
      zoneVal,
      positionVal,
      statusVal
    );
    dispatch(setYouthCampFilteredPlayers(arr));
  }, [youthCampPlayers, dispatch, zoneVal, positionVal, statusVal]);
  return (
    <StyledWrapper>
      <StyledOpenFilterContainer>
        <span>Filter</span>
        <StyledOpenFilterButton
          onClick={() => {
            toggleFilter(!isFilterVisible);
          }}
        >
          <Icon
            style={{
              transform: isFilterVisible ? 'rotate(90deg)' : 'rotate(-90deg)',
            }}
          />
        </StyledOpenFilterButton>
      </StyledOpenFilterContainer>
      <StyledFilterBlock isFilterVisible={isFilterVisible}>
        {isFilterVisible && (
          <StyledSelectContainer>
            <StyledSelect
              defaultValue="''"
              onChange={zoneCallback}
              options={zoneOptions}
              menuPlacement="auto"
              maxMenuHeight={handleMaxWidth()}
              placeholder="Zone"
              isClearable
              classNamePrefix="react-select"
            />
            <StyledSelect
              defaultValue="''"
              onChange={positionCallback}
              options={positionOptions}
              menuPlacement="auto"
              maxMenuHeight={handleMaxWidth()}
              placeholder="Position"
              isClearable
              classNamePrefix="react-select"
            />
            <StyledSelect
              onChange={statusCallback}
              options={statusOptions}
              menuPlacement="auto"
              maxMenuHeight={handleMaxWidth()}
              placeholder="Status"
              isClearable
              classNamePrefix="react-select"
            />
          </StyledSelectContainer>
        )}
      </StyledFilterBlock>
    </StyledWrapper>
  );
};

export default React.memo(YouthCampFilter);
