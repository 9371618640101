import styled from 'styled-components';
import Loader from 'react-loader-spinner';

export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledWrapper = styled.div`
  position: relative;
  background-color: #efefef;
  height: 100%;
`;
export const StyledYouthCampHeader = styled.div`
  height: 120px;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 60px;
  align-items: flex-end;
  @media only screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }
  @media only screen and (max-width: 575px) {
    padding: 30px 30px 0px 30px;
  }
`;
