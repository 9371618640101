import styled, { css } from 'styled-components';

// Z-INDEX DATA-GUIDE
// LeftSidebar - 9
// RightSidebar - 9
// SideNav - 10

export const StyledMain = styled.section`
  position: relative;
  transition: margin-left 0.2s, margin-right 0.2s;
  padding: 30px 45px;
  margin-left: 60px;
  @media only screen and (max-width: 576px) {
    padding: 10px 0;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 30px 15px;
  }

  /*   @media only screen and (min-width: 1100px) and (max-width: 1199px) {
  } */
  @media only screen and (max-width: 991px) {
    margin: 0 auto;
  }
  /*   @media screen and (min-width: 992px) and (max-width: 1199px) {
    padding: 30px 10px;
    margin: 0 auto;
    width: 100%;
    max-width: 768px;
  } */
  @media screen and (min-width: 769px) and (max-width: 991px) {
    padding: 30px 10px;
    margin: 0 auto;
    width: 100%;
    max-width: 768px;
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    padding: 30px 10px;
    margin: 0 auto;
    width: 100%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    padding: 30px 15px;
  }

  &[data-sideopen='true'] {
    &[data-right='true'] {
      margin-right: 18%;
      margin-left: 0;
    }
    &[data-right='false'] {
      // margin-left: calc(270px + 50px);
    }
  }
  &[data-sideopen='false'] {
    &[data-right='true'] {
      margin-left: 50px;
      margin-right: 18%;
      @media only screen and (min-width: 769px) and (max-width: 1199px) {
        margin-right: 0px;
        margin-left: 0px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        margin-right: 300px;
      }
      /*       @media only screen and (min-width: 1401px) {
        margin-right: 300px;
      } */
    }
  }
  &[data-right='true'] {
    ${({ showHeader }) => {
      if (showHeader) {
        return css`
          margin-right: 300px;
        `;
      }
    }}
  }
`;

export const StyledBackground = styled.div`
  ${({ screenHeight }) => css`
    height: ${screenHeight};
  `}
`;
