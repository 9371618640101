import styled, { css } from 'styled-components';

export const StyledRound = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
`;
export const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 116px;
  justify-content: space-between;
`;
export const StyledSemifinalGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 245px;
  justify-content: space-between;
`;
export const StyledBorder = styled.div`
  position: relative;
  width: 30px;
  height: 68px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    width: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    border-top: 1px solid #e5e5e5;
    right: -20px;
  }
  ${({ side }) => {
    if (side === 'right') {
      return css`
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        border-right: 0;
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          border-top: 1px solid #e5e5e5;
          left: -20px;
        }
      `;
    }
  }}
  ${({ large }) => {
    if (large) {
      return css`
        height: 197px;
      `;
    }
  }}
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 20px 40px 30px;
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    margin: 40px 5px 40px 5px;
  }
  ${({ type }) => {
    if (type === 'semifinal') {
      return css`
        margin: 0;
      `;
    }
  }}
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
        margin: 40px 30px 40px 20px;
        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
          margin: 40px 5px 40px 5px;
        }
      `;
    }
  }}
`;

export const StyledSemifinalSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}
`;

export const StyledRoundContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFinalSection = styled.div``;
export const StyledFinalGroup = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  ${({ side }) => {
    if (side === 'right') {
      return css`
        margin-right: 30px;
      `;
    }
  }}
`;
