import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Lobby } from '../../assets/img/Lobby.svg';
import YouthCampBg from '../../assets/img/youth-camp-bg.png';
import {
  StyledYouthCampHeader,
  StyledLobbyBtn,
  StyledImg,
  StyledLogoContainer,
  StyledLogo,
} from './styled';

const YouthCampHeader = () => (
  <StyledYouthCampHeader>
    <StyledLobbyBtn>
      <Link to="/home">
        <Lobby />
      </Link>
    </StyledLobbyBtn>
    <StyledLogoContainer>
      <StyledLogo />
    </StyledLogoContainer>
    <StyledImg>
      <img src={YouthCampBg} alt="youth camp bg" />
    </StyledImg>
  </StyledYouthCampHeader>
);

export default React.memo(YouthCampHeader);
