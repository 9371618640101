import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const StyledContainer = styled.div`
  height: 140px;
  width: 235px;
  grid-gap: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
  @media only screen and (max-width: 426px) {
    width: 315px;
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;
`;

export const StyledGridItem = styled.div`
  width: 100%;
  height: 16px;
  border: 1px solid #fff;
  background-color: ${({ bg }) => bg};
`;

export const StyledColumnItem = styled.div`
  display: grid;

  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
`;

export const StyledRowLabel = styled.span`
  position: absolute;
  font-size: 10px;
  color: #b2b2b2;
  top: 2px;
  left: -20px;
`;
export const StyledColLabel = styled.span`
  padding-top: 5px;
  font-size: 10px;
  color: #b2b2b2;
`;

export const StyledColumnCell = styled.div`
  position: relative;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  text-align: center;
  border: solid 0.5px #e1e1e1;
  min-width: 75px;
  &.__react_component_tooltip.show {
    background-color: #000;
    font-size: 11px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;
