export const backendUrl = process.env.REACT_APP_BACKENDLINK;
// export const backendUrl = "https://dev.api.theopenleague.com";
// export const backendUrl = "/toBACKEND";
// ----------------------------FINANCE---------------------
export const financeSummary = `${backendUrl}/finance/summary`;
export const financeAll = `${backendUrl}/finance/all`;
export const financeSendMoney = `${backendUrl}/finance/send`;
export const financeChart = `${backendUrl}/finance/chart`;
// ----------------------------HOME------------------------
export const home = `${backendUrl}/home`;
export const playerInfo = `${backendUrl}/home/player/`;
export const tasks = `${backendUrl}/home/tasks`;
// ----------------------------LEAGUE----------------------
export const leagueTeamsId = `${backendUrl}/league/teams/`;
export const leagueTeams = `${backendUrl}/league/teams`;
export const leagueFixtures = `${backendUrl}/league/fixtures`;
export const fixturesFromLeague = `${backendUrl}/league/fixtures?league=`;
export const leagueMatchId = `${backendUrl}/league/match/`;
export const scoutRequest = `${backendUrl}/league/players/scout`;
export const leagueCodeName = `${backendUrl}/league/code`;
export const leaguesList = `${backendUrl}/league/leagues`;
export const teamsListFromLeague = `${backendUrl}/league/teams?league=`;
export const exportPlayersDoc = `${backendUrl}/league/exportPlayers`;

export const leaguePlayers = `${backendUrl}/league/players/`;
// ---------------------------SIDEBAR-----------------------
export const homeBar = `${backendUrl}/home/bar`;
// --------------------------TEAM---------------------------
export const teamPlayerList = `${backendUrl}/team/player/list`;
export const teamStrategy = `${backendUrl}/team/strategy`;
export const teamSquad = `${backendUrl}/team/squad`;
export const freeAgentRequest = `${backendUrl}/team/player/signFreeAgent`;
export const releaseRequest = `${backendUrl}/team/player/release`;
export const autoFormation = `${backendUrl}/team/autoFormation`;
// --------------------------TRANSFERS---------------------------
export const transferBuying = `${backendUrl}/transfer/buying`;
export const transferOffers = `${backendUrl}/transfer/offers`;
export const transferCreateOffer = `${backendUrl}/transfer/createTransferOffer`;
export const transferCreateLoanOffer = `${backendUrl}/transfer/createLoanOffer`;
export const transferAcceptOffer = `${backendUrl}/transfer/acceptOffer`;
export const transferCancelOffer = `${backendUrl}/transfer/cancelOffer`;
export const transferRejectOffer = `${backendUrl}/transfer/rejectOffer`;

// TODO Remove Tranfer endpoints below, they are deprecated
export const transferBuyingAcceptCounter = `${backendUrl}/transfer/buying/acceptCounter`;
export const transferBuyingRejectCounter = `${backendUrl}/transfer/buying/rejectCounter`;

export const transferBuyingConfirmTransfer = `${backendUrl}/transfer/buying/confirmTransfer`;
export const transferBuyingDeclineTransfer = `${backendUrl}/transfer/buying/declineTransfer`;
export const transferBuyingOfferPlayer = `${backendUrl}/transfer/buying/offerPlayer`;
export const transferBuyingCancelTransfer = `${backendUrl}/transfer/buying/cancelTransfer`;
export const transferSelling = `${backendUrl}/transfer/selling`;
export const transferSellingAcceptOffer = `${backendUrl}/transfer/selling/acceptOffer`;
export const transferSellingRejectOffer = `${backendUrl}/transfer/selling/rejectOffer`;
export const transferSellingCounterOffer = `${backendUrl}/transfer/selling/counterOffer`;
// ---------------------- CONTRACTS ------------------------------
export const createContract = `${backendUrl}/contracts/offer`;
export const extendContract = `${backendUrl}/contracts/extend`;

// -------------------------LOGIN-------------------------------
export const logoutLink = `${backendUrl}/logout`;
export const loginLink = `${backendUrl}/login`;
export const previewLink = `${backendUrl}/login/preview`;
export const leaveTeam = `${backendUrl}/logout/leaveTeam`;
// -------------------------SIGNUP ---------------------------------
export const leagueCode = `${backendUrl}/signup/leagueCode`;
export const chooseTeam = `${backendUrl}/signup/chooseTeam`;
export const matchesCount = `${backendUrl}/signup/matchesCount`;
export const universesList = `${backendUrl}/signup/universes`;
export const addToWaitlist = `${backendUrl}/signup/addToWaitList`;

// -------------------------YOUTH CAMP ---------------------------------
export const youthcampPlayers = `${backendUrl}/youthCamp/players`;
export const youthcampRegister = `${backendUrl}/youthCamp/register`;
export const youthcampScout = `${backendUrl}/youthCamp/scout`;
export const youthcampUnscout = `${backendUrl}/youthCamp/unscout`;
export const youthcampBid = `${backendUrl}/youthCamp/bid`;
export const youthcampStatus = `${backendUrl}/youthCamp/status`;
export const isUserRegistered = `${backendUrl}/youthCamp/isUserRegister`;

// -------------------------DOMESTIC CUP ---------------------------------
export const domesticCup = `${backendUrl}/cup`;
export const cupLeagueGames = `${backendUrl}/cup?league=`;
// -------------------------ATTACK--------------------------------
export const hackRequest = `${backendUrl}/attack/hack`;

// ------------------------PLAYER--------------------------------
export const playerBoostRequest = `${backendUrl}/player/boostDev`;
export const playerDelayDeclineRequest = `${backendUrl}/player/declineDev`;
export const setPlayerNickname = `${backendUrl}/player/nickName`;
export const setPlayerToAcademy = `${backendUrl}/player/academy`;

// -----------------------FRIENDLY----------------------
export const friendlyTeams = `${backendUrl}/friendlies/teams`;
export const friendlyOffer = `${backendUrl}/friendlies/offer`;
// -----------------------TEAMS-----------------------------
export const trainingTeams = `${backendUrl}/training/teams`;
export const trainingOffer = `${backendUrl}/training/offer`;

// -----------------------ADS-------------------------------
export const adClicked = `${backendUrl}/ads/adclicked`;
// -----------------------KIT ITEMS ------------------------
export const stuffItems = `${backendUrl}/items`;
export const stuffSelectKit = `${backendUrl}/items/selectKit`;
