import React from 'react';
import ContentLoader from 'react-content-loader';

const MobileDealsCardPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={350}
    height={200}
    viewBox="0 0 350 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="16" y="153" rx="4" ry="4" width="315" height="29" />
    <rect x="127" y="47" rx="4" ry="4" width="94" height="19" />
    <rect x="37" y="106" rx="4" ry="4" width="94" height="15" />
    <rect x="43" y="128" rx="4" ry="4" width="85" height="10" />
    <rect x="202" y="106" rx="4" ry="4" width="101" height="15" />
    <rect x="206" y="129" rx="4" ry="4" width="85" height="10" />
    <rect x="276" y="11" rx="4" ry="4" width="63" height="15" />
    <rect x="15" y="12" rx="4" ry="4" width="63" height="15" />
  </ContentLoader>
);

export default MobileDealsCardPreloader;
