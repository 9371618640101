import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePortal from 'react-useportal';
import { leaugesOptions, teamsOptions } from './constants';
import {
  StyledSection,
  StyledContainer,
  StyledSelect,
  StyledSelectContainer,
  StyledInput,
  StyledSendButton,
} from './styled';
import { StyledReactTooltip, InfoDarkRelative } from '../../globalStyled';
import { SendMoneyPopup } from '../Popup';
import { showPopupWindow } from '../../redux/actions/Popups';
import { getTeamsFromLeague } from '../../redux/actions/League';
import { getPopupByName } from '../../redux/selector/Popups';
import { dataForSidebar } from '../../redux/selector/Sidebar';
import { getMyCurrentTeam, isAuth } from '../../redux/selector/User';
import {
  isLeaguesListLoaded,
  isTeamListLoaded,
  teamListLoader,
} from '../../redux/selector/League';
import { goToDiscordLogin } from '../../service/functions';

const SendMoney = () => {
  const [selectedLeague, selectLeague] = React.useState();
  const [selectedTeam, selectTeam] = React.useState();
  const [sendingCash, setCashAmount] = React.useState();
  const [leaguesList, setLeaguesList] = React.useState([]);
  const [teamsList, setTeamsList] = React.useState([]);

  const sendMoneyPopupName = 'sendMoneyPopup';
  const { cash } = useSelector(dataForSidebar);
  const dispatch = useDispatch();
  const sendMoneyPopupData = useSelector(getPopupByName(sendMoneyPopupName));
  const isAuthenticated = useSelector(isAuth);
  const leagueOptionsList = useSelector(isLeaguesListLoaded);
  const currentTeamData = useSelector(getMyCurrentTeam);
  const teamList = useSelector(isTeamListLoaded);
  const isLoading = useSelector(teamListLoader);

  const previewTeamData = JSON.parse(localStorage.getItem('previewTeamData'));
  const myTeamId = isAuthenticated ? currentTeamData._id : previewTeamData.id;

  React.useEffect(() => {
    setLeaguesList(leaugesOptions(leagueOptionsList));
  }, [leagueOptionsList]);
  React.useEffect(() => {
    if (teamList) {
      const teamListWithoutOwnTeam = teamList.filter(
        (item) => item._id !== myTeamId
      );
      setTeamsList(teamsOptions(teamListWithoutOwnTeam));
    }
  }, [teamList, myTeamId]);

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  const selectTeamFromLeague = (league) => {
    selectTeam(league);
  };
  const selectLeagueFromList = (league) => {
    dispatch(getTeamsFromLeague(league));
    selectLeague(league);
  };
  const onInputChange = (cashAmount) => {
    setCashAmount(cashAmount.value);
  };
  const showConfirmPopup = () => {
    if (!isAuthenticated) {
      goToDiscordLogin();
    } else {
      dispatch(showPopupWindow(sendMoneyPopupName, sendMoneyPopupName));
    }
  };
  const team = teamList.find((item) => item._id === selectedTeam);
  return (
    <>
      {sendMoneyPopupData && (
        <Portal>
          <SendMoneyPopup
            cash={cash}
            popupName={sendMoneyPopupName}
            selectedTeam={team}
            penaltiSize="100000"
            sendingCash={sendingCash}
          />
        </Portal>
      )}

      <StyledSection className="send-money-section">
        <h3>
          Send Money{' '}
          <InfoDarkRelative
            iconsize="10"
            data-tip
            clickable="true"
            data-for="send-money"
          />{' '}
        </h3>

        <StyledReactTooltip
          id="send-money"
          type="light"
          effect="solid"
          place="bottom"
        >
          After managing your club for more than 21 days, you will be allowed to
          transfer money to other clubs. To limit fraud, you will only be
          allowed to transfer $5 million per week
        </StyledReactTooltip>
        <StyledContainer>
          <StyledSelectContainer>
            <StyledSelect
              onChange={(e) => {
                selectLeagueFromList(e.value);
              }}
              isSearchable={false}
              isClearable={false}
              options={leaguesList}
              menuPlacement="auto"
              placeholder="Select a league"
              classNamePrefix="react-select"
            />
            <StyledSelect
              onChange={(e) => {
                selectTeamFromLeague(e.value);
              }}
              isSearchable={false}
              isClearable={false}
              isLoading={isLoading}
              isDisabled={!selectedLeague || isLoading}
              options={teamsList}
              menuPlacement="auto"
              placeholder="Select a team"
              classNamePrefix="react-select"
            />
          </StyledSelectContainer>
          <StyledSelectContainer>
            <StyledInput
              allowNegative={false}
              onValueChange={(val) => onInputChange(val)}
              thousandSeparator
              allowLeadingZeros={false}
              prefix="$"
              placeholder="$0,000,000"
            />
            <StyledSendButton
              disabled={!selectedLeague || !selectedTeam || !sendingCash}
              onClick={() => showConfirmPopup()}
              primary
            >
              Send Money
            </StyledSendButton>
          </StyledSelectContainer>
        </StyledContainer>
      </StyledSection>
    </>
  );
};
export default SendMoney;
