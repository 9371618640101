import styled, { css } from 'styled-components';

export const StyledCell = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
  color: ${({ color }) => color};
`;
export const StyledCellName = styled.span`
  color: #999;
  padding: 0;
`;

export const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
  height: 50px;
`;

export const StyledSection = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  text-align: left;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
  span {
    line-height: 15px;
  }
`;

export const StyledWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ small }) => {
    if (small) {
      return css`
        width: 170px;
      `;
    }
  }}
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        cursor: default;
      `;
    }
  }}
  ${({ selected }) => {
    if (selected) {
      return css`
        // box-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
        border-radius: 5px;
      `;
    }
  }}
`;

export const StyledRatingCell = styled.div`
  position: relative;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  margin: 0 auto;
  color: ${({ color }) => color};
  @media (max-width: 530px) {
    width: 100px;
  }
`;
export const StyledTitle = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  svg {
    margin-left: 5px;
  }
  ${({ small }) => {
    if (small) {
      return css`
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
      `;
    }
  }}
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
`;
export const StyledTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 1px 0px 1px 10px;
  height: 30px;
  background-color: #fafafa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  ${({ small }) => {
    if (small) {
      return css`
        padding-left: 0;
      `;
    }
  }}
`;
export const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledDraggingCard = styled.div`
  text-align: center;
  padding: 0px 1px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  border-radius: 5px;
  background-color: #fff;

  /*   line-height: 20px; */
  color: #000;
  width: 170px;
  box-shadow: 0 0 5px 3px rgb(0 0 0 / 5%);
`;

export const StyledPlayerDragIcon = styled.div`
  position: absolute;
  top: 0px;
  height: 35px;
  width: 25px;
  background-color: #ffffff3b;
  display: flex;
  flex-direction: row;
  padding-left: 6px;
  border-radius: 6px;
  border-top-right-radius: 6px;
  z-index: ${({ isHidden }) => (isHidden ? '0' : '1')};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;
