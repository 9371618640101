import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledRadioButtonLabel,
  StyledRadioButton,
  StyledFill,
  Root,
} from './styled';

const RadioButton = ({ value, onChange, children }) => (
  <Root checked={value}>
    <StyledRadioButtonLabel>
      {children}
      <StyledRadioButton
        type="radio"
        onChange={onChange}
        value={value}
        checked={value}
        aria-checked={value}
      />
      <StyledFill />
    </StyledRadioButtonLabel>
  </Root>
);
RadioButton.propTypes = {
  children: PropTypes.node,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
RadioButton.defaultProps = {
  value: false,
  onChange: () => {},
};
export default RadioButton;
