import styled, { css } from 'styled-components';

export const StyledWelcomePopUp = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: #191919;
  border: 1px solid rgba(0, 0, 0, 0.26);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  justify-content: center;
  text-align: center;
`;
export const StyledSliderDate = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

export const StyledWrapper = styled.div`
  .slick-slider {
    padding: 25px 25px 0;
    @media only screen and (max-width: 991px) {
      padding: 15px 25px 0;
    }
  }

  .slick-arrow {
    display: none;
  }
  .container {
    margin: 0 auto;
    padding: 0px 40px 0px 40px;
    width: 500px;
    background-color: #191919;
  }

  .slick-dots {
    bottom: 130px;
    left: 0;
  }
  .slick-dots li.slick-active button:before {
    color: #fff;
  }
  .slick-dots li button:before {
    color: #3e3e3e;
  }
`;

export const StyledImageContainer = styled.div`
  min-height: 200px;
`;

export const StyledSlideParagraph = styled.p`
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px;
  font-family: 'Gilroy', sans-serif;
`;
export const StyledSlideTitle = styled.p`
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  padding-top: 50px;
  font-family: 'Gilroy', sans-serif;
`;

export const StyledSlide = styled.div``;

export const StyledSlideHeader = styled.h3`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  color: white;
  font-size: 20px;
`;

export const StyledSlider = styled.div`
  background-color: #fff;
  border-radius: 4px;
`;

export const StyledMatchday = styled.p`
  font-size: 12px;
  color: #333;
  text-align: center;
  margin-top: 10px;
`;

export const StyledDateCard = styled.div`
  width: 180px;
  text-align: center;
  outline: none;
  cursor: pointer;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  span {
    max-width: 120px;
    text-align: center;
    height: 60px;
  }
`;
export const StyledFixturesContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
`;
export const StyledMatch = styled.div`
  position: relative;
  display: flex;
  height: 84px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  z-index: 1;

  ${({ hasUserTeam }) => {
    if (hasUserTeam) {
      return css`
        box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.05);
        &:before {
          content: '';
          position: absolute;
          border-radius: 4px;
          background-color: #fff;
          width: 100%;
          height: 84px;
          transform: scaleX(1.02);
          z-index: -1;
          @media only screen and (max-width: 991px) {
            transform: scaleX(1.05);
          }
        }
        &:after {
          position: absolute;

          content: '';
          width: 6px;
          top: 0;

          height: 84px;
          background: #fdca40;
          ${({ isHomeTeamYours }) => {
            if (isHomeTeamYours) {
              return css`
                left: -7px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                @media only screen and (max-width: 991px) {
                  left: -9px;
                }
              `;
            }
          }};
          ${({ isAwayTeamYours }) => {
            if (isAwayTeamYours) {
              return css`
                right: -7px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                @media only screen and (max-width: 991px) {
                  right: -9px;
                }
              `;
            }
          }};
        }
      `;
    }
  }}
  &:last-of-type {
    border-bottom: 0;
  }
`;

export const StyledTeamName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  color: #333;
  width: 270px;
  font-family: 'Gilroy', sans-serif;
  font-weight: ${({ isTeamOwner }) => (isTeamOwner ? 600 : 500)};
  justify-content: ${({ alignment }) => alignment};
  text-align: ${({ rightPart }) => (rightPart ? 'left' : 'right')};
  cursor: pointer;
  img {
    max-width: 35px;
    height: auto;
    margin: ${({ rightPart }) => (rightPart ? '0 15px 0 0' : '0 0 0 15px')};
  }
  @media only screen and (max-width: 991px) {
    flex-direction: ${({ rightPart }) =>
      rightPart ? 'column' : 'column-reverse'};
    text-align: center;
    font-size: 11px;
    max-width: 120px;
    white-space: normal;
    img {
      margin: 0;
    }
  }
`;
export const StyledGoals = styled.div`
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Gilroy', sans-serif;
  border: 1px solid #e5e5e5;
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  letter-spacing: 5px;
  ${({ futureMatch }) => {
    if (futureMatch) {
      return css`
        color: #7f7f7f;
        font-weight: normal;
        font-size: 12px;
        letter-spacing: 0.55px;
      `;
    }
  }}
  @media only screen and (max-width: 991px) {
    width: 55px;
  }
`;
export const StyledNoMatches = styled.div`
  height: 84px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
