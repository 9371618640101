import styled, { css } from 'styled-components';

export const StyledPagination = styled.section`
  margin: 10px 0;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
`;

export const StyledTrTd = styled.tr`
  background: white;
  ${({ active }) => {
    if (active) {
      return css`
        background: #f7f7f7;
        pointer-events: none;
        * {
          color: #000 !important;
        }
      `;
    }
  }};
`;

export const StyledTd = styled.td`
  text-align: left;
  padding: 6px 10px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  width: 100px;
  white-space: nowrap;
  position: relative;
  ${({ statusCell }) => {
    if (statusCell === 'status') {
      return css`
        line-height: 0;
        text-align: right;
        padding-right: 20px;
      `;
    }
  }}
`;

export const StyledContainer = styled.section`
  height: 255px;
  width: 50%;
  margin-left: 30px;
  background-color: #fff;
  padding: 20px 15px 20px;
  border-radius: 5px;
  @media only screen and (max-width: 1199px) {
    display: none;
  }

  > h6 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #949494;
    margin-bottom: 30px;
  }
  margin-bottom: 31px;
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  max-height: 295px;
  overflow-y: auto;
  padding-bottom: 15px;
`;

export const StyledTbody = styled.tbody``;

export const StyledSubheader = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #666;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1.17px;
  padding-left: 10px;
`;

export const StyledHeader = styled.h3`
  font-family: 'Gilroy', sans-serif;
  color: #191919;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
  padding-left: 10px;
`;

export const StyledStatus = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledStatusIcon = styled.span`
  position: absolute;
  width: 5px;
  height: 5px;
  right: -6px;
  top: -2px;

  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ccc;
    ${({ status }) => {
      if (!status) {
        return css`
          background-color: #7f7f7f;
        `;
      }
    }}
  }
`;
