export const HOME_LOCATION = '/home';
export const SQUAD_LOCATION = '/team/squad';
export const STRATEGY_LOCATION = '/team/strategy';
export const TRANSFER_DEALS_LOCATION = '/transfers/deals';
export const TRANSFER_SEARCH_LOCATION = '/transfers/search-players';
export const FINANCE_LOCATION = '/finance';

export const HOME_STORAGE = 'home-tour';
export const SQUAD_STORAGE = 'squad-tour';
export const STRATEGY_STORAGE = 'strategy-tour';
export const DEALS_STORAGE = 'deals-tour';
export const PLAYER_INFO_STORAGE = 'playerInfo-tour';
export const SEARCH_STORAGE = 'search-tour';
export const FINANCE_STORAGE = 'finance-tour';
