import { createSelector } from 'reselect';

export const getSignUpData = (state) => state.signUp;
export const universesData = (state) => state.signUp.universesList;
export const getLoadingUniversesData = createSelector(
  getSignUpData,
  ({ universesList, universesListLoading }) => ({
    universesList,
    universesListLoading,
  })
);

export const getSignUpTeamById = (id) =>
  createSelector(
    getSignUpData,
    ({ selectTeamList }) => selectTeamList.filter((i) => i._id === id)[0] || []
  );
export const isAnyTeamAvailable = () =>
  createSelector(getSignUpData, ({ selectTeamList }) =>
    selectTeamList.some((i) => i.free === true)
  );

export const waitlistData = createSelector(
  getSignUpData,
  ({ waitlistRequestLoading, waitlistRequestStatus }) => ({
    waitlistRequestLoading,
    waitlistRequestStatus,
  })
);
