import { createAction } from 'redux-act';
import { getTasks } from '../../../api/tasks';

export const getTasksListLoading = createAction('Tasks list loading');
export const getTasksData = createAction('Tasks list loaded');

export const getTasksList = () => async (dispatch) => {
  dispatch(getTasksListLoading(true));
  const response = await getTasks();
  if (response.status === 200) {
    const data = await response.json();
    dispatch(getTasksData(data));
    dispatch(getTasksListLoading(false));
  }
};
