import React from 'react';
import ContentLoader from 'react-content-loader';

const MobileNextMatchPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={345}
    height={200}
    viewBox="0 0 345 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="75" y="16" rx="3" ry="3" width="202" height="12" />
    <rect x="542" y="16" rx="4" ry="4" width="85" height="12" />
    <rect x="75" y="114" rx="3" ry="3" width="202" height="35" />
    <rect x="75" y="46" rx="3" ry="3" width="202" height="23" />
    <rect x="75" y="74" rx="3" ry="3" width="202" height="23" />
  </ContentLoader>
);

export default MobileNextMatchPreloader;
