import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import {
  getPlayersForCurrentPosition,
  getPlayersListIds,
} from '../../../../../../../../../../redux/selector/Team';
import { showMobileSidebarSection } from '../../../../../../../../../../redux/actions/Team';
import {
  favPositionSortIds,
  sortingPriority,
  simplifiedPosition,
} from '../../../../../../../../../../service/data';
import { ratingRangeStatus } from '../../../../../../../../../../service/constants';

import ReplacePlayerList from '../../../../../../../../../ReplacePlayerList';
import { StyledContainer, StyledLeftArrow } from './styled';

export default React.memo(function UserToReplace({
  selectedPlayer,
  activeDraggingId,
}) {
  const playersForSelectedPos = useSelector(getPlayersForCurrentPosition);
  const strategyIds = useSelector(getPlayersListIds);
  const dispatch = useDispatch();
  const [playersData, setPlayers] = React.useState([]);
  const [showScrollStyles, setScrollStyles] = React.useState(false);
  React.useEffect(() => {
    if (!isEmpty(playersForSelectedPos)) {
      setPlayers(playersForSelectedPos);
    }
  }, [playersForSelectedPos]);

  const players = useMemo(
    () =>
      playersData.map(
        ({
          first_name: firstName,
          last_name: lastName,
          fav_position: favPosition,
          potential_range: potentialRange,
          second_fav_position: secondFavPosition,
          media_rating,
          ...other
        }) => ({
          ...other,
          first_name: firstName,
          last_name: lastName,
          name: `${firstName} ${lastName}`,
          potentialId: potentialRange,
          potentialValue: ratingRangeStatus[potentialRange],
          formId: other.form_range,
          ratingId: other.rating_range,
          favPosition,
          secondFavPosition,
          media_rating,
          favPositionSortId: favPositionSortIds[favPosition],
          sortPriority: sortingPriority[simplifiedPosition[favPosition]],
          feeling: other.transfer_feeling,
        })
      ),
    [playersData]
  );
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Position',
        accessor: 'sortPriority',
        filter: 'positionRange',
      },
      { Header: 'Age', accessor: 'age' },
      { Header: 'Rating', accessor: 'ratingId' },
      { Header: 'Potential', accessor: 'potentialId' },
      { Header: 'Fitness', accessor: 'formId' },
      { Header: 'Media Rating', accessor: 'media_rating', disableSortBy: true },
    ],
    []
  );
  const myRef = React.useRef(null);
  const onScroll = () => {
    if (!isMobile) {
      const { scrollTop } = myRef.current;
      if (scrollTop.toFixed(0) > 15) {
        setScrollStyles(true);
      } else if (showScrollStyles === true) {
        setScrollStyles(false);
      }
    }
  };
  return (
    <>
      <StyledContainer
        mobileDevice={isMobile}
        ref={myRef}
        onScroll={onScroll}
        showScrollStyles={showScrollStyles}
      >
        {isMobile && (
          <h3
            onClick={() => {
              dispatch(showMobileSidebarSection(null));
            }}
          >
            <StyledLeftArrow />
            Player List
          </h3>
        )}
        <ReplacePlayerList
          showScrollStyles={showScrollStyles}
          columns={columns}
          data={players}
          selectedPlayer={selectedPlayer}
          activeSquadIds={strategyIds}
          activeDraggingId={activeDraggingId}
        />
      </StyledContainer>
    </>
  );
});
