import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import Table from '../../../../components/LeagueTable';
import FallbackContentAnimation from '../../../../components/FallbackContentAnimation';
import { LeagueTeamsPreloader } from '../../../../components/ContentPreloaders';

import { StyledContainer, StyledTableContainer } from './styled';

import { leagueSetTeamInfo } from '../../../../redux/actions/League';
import { showPopupWindow } from '../../../../redux/actions/Popups';
import { showMobileSidebarSection } from '../../../../redux/actions/Team';

import { leagueTableLoading } from '../../../../redux/selector/League';

import { POPUPS } from '../../../../service/constants';

const TableContainer = ({
  leagueId,
  table,
  selectedLeague,
  areTeamsLoaded,
}) => {
  // REDUX
  const { TEAM_INFO_POPUP } = POPUPS;
  const dispatch = useDispatch();

  const isLoading = useSelector(leagueTableLoading);

  // REDUX

  const data = useMemo(
    () =>
      table &&
      table.length > 0 &&
      table.map((tab, index) => ({ ...tab, number: index + 1 })),
    [table]
  );
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'number',
      },
      {
        Header: 'Team',
        accessor: 'name',
      },
      {
        Header: 'W',
        accessor: 'win',
      },
      {
        Header: 'D',
        accessor: 'draw',
      },
      {
        Header: 'L',
        accessor: 'loss',
      },

      {
        Header: 'GD',
        accessor: 'gd',
      },
      {
        Header: 'PTS',
        accessor: 'points',
      },
    ],
    []
  );
  const addInfoAboutTeam = (teamData) => {
    dispatch(leagueSetTeamInfo(teamData, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
    }
  };

  const isTeamInCurrentLeague = leagueId === selectedLeague;
  return (
    <StyledTableContainer>
      <FallbackContentAnimation
        loading={isLoading || !selectedLeague || areTeamsLoaded}
        repeat="10"
        fallback={<LeagueTeamsPreloader />}
      >
        <StyledContainer>
          {data && data.length > 0 && (
            <Table
              isTeamInCurrentLeague={isTeamInCurrentLeague}
              getTeamInfo={addInfoAboutTeam}
              data={data}
              columns={columns}
              canSelectPlayer
            />
          )}
        </StyledContainer>
      </FallbackContentAnimation>
    </StyledTableContainer>
  );
};
TableContainer.propTypes = {
  leagueId: PropTypes.string.isRequired,
  table: PropTypes.array,
  selectedLeague: PropTypes.string,
  areTeamsLoaded: PropTypes.bool,
};
TableContainer.defaultProps = {
  table: [],
  areTeamsLoaded: false,
  selectedLeague: '',
};
export default React.memo(TableContainer);
