// TODO move all league calls here

import { headers } from './requestConfig';
import { domesticCup, cupLeagueGames } from '../service/links';

export const getDomesticCup = async () => {
  const response = await fetch(domesticCup, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const getCupGamesList = async (id) => {
  const response = await fetch(`${cupLeagueGames}${id}`, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
