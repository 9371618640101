import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { StyledSectionContainer } from '../../../../globalStyled';

export const StyledTransactionSelect = styled(Select)`
  bottom: 0;
  background-color: transparent;
  width: 250px;
  margin-right: 15px;
  font-size: 13px;

  .react-select__menu {
    z-index: 2;
  }
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    min-height: 25px;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    box-shadow: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    padding: 8px 10px;
    &--is-selected {
      background-color: #333333;
      color: #fff;
    }
  }
  .react-select__indicator {
    svg {
      fill: #4b4b4a;
      width: 12px;
    }
  }
  .react-select__single-value {
    padding-left: 25px;
    width: 100%;
    text-align: right;
    margin-right: 0;
  }
  .react-select__value-container {
    padding: 2px 5px;
  }
`;

export const StyledHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  align-items: center;
`;

export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 25%;
  transform: translate(-25%, -50%);
`;

export const StyledSection = styled(StyledSectionContainer)`
  position: relative;
  h3 {
    width: 100%;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.18px;
    padding: 17px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledContainer = styled.section`
  display: block;
`;

export const StyledTransactionContainer = styled.div`
  padding: 24px 25px;
  background: #ffffff;
  border-radius: 5px;
  max-height: 600px;
  overflow-y: scroll;
`;

export const StyledButton = styled.div`
  height: 45px;
  border-top: 1px solid #f2f2f2;
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    margin: 0 15px;
  }
  &[data-last='true'] {
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
    svg {
      transform: rotate(90deg);
    }
  }
  button {
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
  }
`;
