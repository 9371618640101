import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Transfers';

const initialValue = {
  showFilter: false,
  buy: {
    data: null,
    loading: false,
    selectedPlayer: null,
    selectedPlayerLoading: false,
  },
  sell: {
    data: null,
    loading: false,
    selectedPlayer: null,
    selectedPlayerLoading: false,
  },
  // TODO maybe, merge deals and transfersList, because it's the same data entity.
  deals: {
    selectedPlayer: null,
    selectedPlayerLoading: false,
    dataForRequest: null,
  },
  transfersList: {
    isLoading: false,
    active: [],
    closed: [],
    pending: [],
    teamId: null,
  },
  searchPlayer: {
    data: [],
    offerToBuyLoading: null,
    loading: true,
    selectedIndex: null,
    scoutingAllowed: false,
    league: '',
    loadingScoutRequest: false,
    isPopupLoading: false,
  },
  transferWindow: false,
};

export const transfers = handleActions(
  {
    [action.transfersListLoading]: (draft, { payload }) => {
      draft.isLoading = payload;
    },
    [action.setTransfersList]: (draft, { payload }) => {
      const { active, closed, pending, teamId } = payload;
      draft.transfersList.isLoading = false;
      draft.transfersList.active = active;
      draft.transfersList.closed = closed;
      draft.transfersList.pending = pending;
      draft.transfersList.teamId = teamId;
    },
    [action.showUnShowFilter]: (draft, { payload }) => {
      draft.showFilter = payload.filter;
    },
    [action.setUserWithOfferToBuy]: (draft, { payload }) => {
      const indexInData = draft.searchPlayer.data.findIndex(
        (element) => element._id === payload.user._id
      );
      if (indexInData !== -1) draft.searchPlayer.selectedIndex = indexInData;
    },
    [action.setUserWithOffer]: (draft, { payload }) => {
      const indexInData = draft.searchPlayer.data.findIndex(
        (element) => element._id === payload
      );
      if (indexInData !== -1) draft.searchPlayer.selectedIndex = indexInData;
    },
    [action.deleteUserWithOfferToBuy]: (draft) => {
      draft.searchPlayer.selectedIndex = null;
    },

    // -----------------------------------------
    // ----------------BUY----------------------
    // -----------------------------------------
    [action.getDataToBuyLoading]: (draft) => {
      draft.buy.loading = true;
    },
    [action.getDataToBuy]: (draft, { payload }) => {
      // TODO: Negotiate with back-end to simplify
      draft.buy.data = payload.data;
      draft.buy.loading = false;
    },
    [action.getDataToBuyLoading]: (draft) => {
      draft.buy.loading = true;
    },

    [action.selectPlayerToBuy]: (draft, { payload }) => {
      draft.buy.selectedPlayer = payload;
      draft.buy.selectedPlayerLoading = false;
    },
    [action.readMessageFromSellerInBuy]: (draft, { payload }) => {
      const indexInData = draft.buy.data.findIndex(
        (element) => element.player === payload.name
      );
      if (indexInData !== -1)
        draft.buy.data[indexInData].messageFromSeller = payload.message;
    },
    [action.declineTransfer]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.buy.selectedPlayer.transfer = false;
      }
      draft.buy.selectedPlayer.messageOffer = payload.message;
      // const indexInData = draft.buy.data.findIndex(
      //   (element) => element.player === payload.name
      // );
      // if (indexInData !== -1) draft.buy.data[indexInData].transfer = false;
      // draft.buy.data[indexInData].declineTransfer = true;
    },
    [action.confirmTransfer]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.buy.selectedPlayer.transfer = true;
      }
      draft.buy.selectedPlayer.messageOffer = payload.message;
      // const indexInData = draft.buy.data.findIndex(
      //   (element) => element.player === payload.name
      // );
      // if (indexInData !== -1) draft.buy.data[indexInData].transfer = true;
      // draft.buy.data[indexInData].confirmTransfer = true;
    },
    [action.acceptOffer]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.buy.selectedPlayer.offer = true;
      }
      draft.buy.selectedPlayer.messageOffer = payload.message;
      // const indexInData = draft.buy.data.findIndex(
      //   (element) => element._id === payload.transferID
      // );
      // if (indexInData !== -1) draft.buy.data[indexInData].offer = true;
    },
    [action.rejectOffer]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.buy.selectedPlayer.offer = false;
      }
      draft.buy.selectedPlayer.messageOffer = payload.message;
      // const indexInData = draft.buy.data.findIndex(
      //   (element) => element._id === payload.transferID
      // );
      // if (indexInData !== -1) draft.buy.data[indexInData].offer = false;
    },
    [action.createNewSalaryOffer]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.buy.selectedPlayer.newOffer = true;
      }
      draft.buy.selectedPlayer.messageOffer = payload.message;
      // const indexInData = draft.buy.data.findIndex(
      //   (element) => element.player === payload.name
      // );
      // if (indexInData !== -1) {
      //   draft.buy.data[indexInData].newOffer = true;
      //   draft.buy.data[indexInData].offerCounter = payload.counter;
      // }
    },

    // -------------------------------------------
    // ------------------DEALS---------------------
    // -------------------------------------------

    [action.selectingPlayerInOfferLoading]: (draft) => {
      draft.deals.selectedPlayerLoading = true;
    },
    [action.setDataForCardRequest]: (draft, { payload }) => {
      draft.deals.dataForRequest = payload;
    },
    [action.selectPlayerToOffer]: (draft, { payload }) => {
      draft.deals.selectedPlayer = payload;
      draft.deals.selectedPlayerLoading = false;
    },

    // TODO Deprecated flow, remove
    // -------------------------------------------
    // ------------------SELL---------------------
    // -------------------------------------------
    [action.getDataToSellLoading]: (draft) => {
      draft.sell.loading = true;
    },
    [action.getDataToSell]: (draft, { payload }) => {
      draft.sell.data = payload.data;
      draft.sell.loading = false;
    },
    [action.setLoadingStateForSelectingPlayerInSell]: (draft) => {
      draft.sell.selectedPlayerLoading = true;
    },
    [action.selectPlayerToSell]: (draft, { payload }) => {
      draft.sell.selectedPlayer = payload;
      draft.sell.selectedPlayerLoading = false;
      // const indexInData = draft.sell.data.findIndex(
      //   (element) => element.player === payload.player.player
      // );
      // if (indexInData !== -1) draft.sell.selectedIndex = indexInData;
    },
    [action.acceptOfferInSell]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.sell.selectedPlayer.offer = true;
      }
      draft.sell.selectedPlayer.messageOffer = payload.message;
      // const indexInData = draft.sell.data.findIndex(
      //   (element) => element.player === payload.name
      // );
      // if (indexInData !== -1) draft.sell.data[indexInData].offer = true;
    },
    [action.declineOfferInSell]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.sell.selectedPlayer.offer = false;
        draft.sell.selectedPlayer.message = payload.message;
      }
      draft.sell.selectedPlayer.messageOffer = payload.messageFromServer;
      // const indexInData = draft.sell.data.findIndex(
      //   (element) => element.player === payload.name
      // );
      // if (indexInData !== -1) draft.sell.data[indexInData].offer = false;
    },
    [action.changeCashAmountOfOfferInSell]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.sell.selectedPlayer.cashOffer = true;
        draft.sell.selectedPlayer.offerCounter = payload.counterOffer;
      }
      draft.sell.selectedPlayer.messageOffer = payload.message;
      // const indexInData = draft.sell.data.findIndex(
      //   (element) => element.player === payload.name
      // );
      // if (indexInData !== -1) {
      //   draft.sell.data[indexInData].cashOffer = true;
      //   draft.sell.data[indexInData].offerCounter = payload.counter;
      // }
    },
    [action.cancelSellTransfer]: (draft, { payload }) => {
      if (payload.message === 'Success operation!') {
        draft.sell.selectedPlayer.transfer = false;
      }
      draft.sell.selectedPlayer.messageOffer = payload.message;
    },

    // ------------------------------------------------
    // --------------------SEARCH----------------------
    // ------------------------------------------------
    [action.getDataToSearchPlayer]: (draft, { payload }) => {
      draft.searchPlayer.data = payload.data;
      draft.searchPlayer.scoutingAllowed = payload.scoutingAllowed;
      draft.searchPlayer.endOfSeasonDate = payload.endOfSeasonDate;
      draft.searchPlayer.league = payload.league;
      draft.transferWindow = payload.transferWindow;
      draft.searchPlayer.loading = false;
    },
    [action.getDataToSearchPlayerLoading]: (draft) => {
      draft.searchPlayer.loading = true;
    },
    [action.setCreateNewOfferSearchLoading]: (draft) => {
      draft.searchPlayer.offerToBuyLoading = false;
    },
    [action.setCreateNewOfferSearch]: (draft, { payload }) => {
      // playerID;
      const indexInData = draft.searchPlayer.data.findIndex(
        (element) => element._id === payload.playerID
      );
      if (indexInData !== -1)
        if (!payload.textError) {
          draft.searchPlayer.data[indexInData].createNewOffer = true;
          draft.searchPlayer.data[indexInData].offerToBuyLoading = true;
        } else {
          draft.searchPlayer.data[indexInData].createNewOffer = false;
          draft.searchPlayer.data[indexInData].offerToBuyLoading = true;
        }
    },
    // ------------------------------------------------
    // --------------------SCOUT----------------------
    // ------------------------------------------------
    [action.editPlayerData]: (draft, { payload }) => {
      const { playerID, data } = payload;
      const indexInData = draft.searchPlayer.data.findIndex(
        (element) => element._id === playerID
      );
      const result = draft.searchPlayer.data[indexInData];
      const keys = Object.keys(data);
      keys.forEach((item) => {
        result[item] = data[item];
      });
      draft.searchPlayer.loadingScoutRequest = false;
    },
    [action.removePlayer]: (draft, { payload }) => {
      const newData = draft.searchPlayer.data.filter(
        (player) => player._id !== payload
      );
      draft.searchPlayer.data = newData;
    },
    [action.scoutCallLoading]: (draft, { payload }) => {
      draft.searchPlayer.isPopupLoading = payload;
    },
    [action.declineScoutRequest]: (draft) => {
      draft.searchPlayer.scoutingAllowed = false;
    },
    [action.freeAgentCallLoading]: (draft, { payload }) => {
      draft.searchPlayer.isPopupLoading = payload;
    },
  },
  initialValue
);
