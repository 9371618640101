import styled from 'styled-components';
import { StyledPopUpBlock } from '../styled';
import { StyledButton } from '../../../globalStyled';

export const StyledPopupHeader = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledPopUpBlockNoPadding = styled(StyledPopUpBlock)`
  padding: 0;
  max-width: 300px;
`;
export const StyledParagraph = styled.p`
  margin-top: 20px;
  font-family: 'Gilroy';
  font-size: 15px;
  line-height: 26px;
  padding: 30px;
  text-align: center;
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const StyledPopupButton = styled(StyledButton)`
  width: 100%;
`;
