import styled, { css } from 'styled-components';
import Select from 'react-select';
import { ReactComponent as Sorted } from './assets/sorted.svg';
import { ReactComponent as Cross } from '../../assets/img/cross.svg';
import { ReactComponent as StyledInfo } from '../../assets/img/info-card-icon.svg';

export const StyledDraggableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 120px;
`;
export const StyledPlaceholderWrapper = styled.div`
  background-color: #fafafa;
  border: 1px dashed red;
`;
export const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
  height: 96px;
`;
export const StyledSorted = styled(Sorted)`
  margin-left: 5px;

  ${({ type }) => {
    if (type === 'fromMinToMax') {
      return css`
        path:first-of-type {
          display: none;
        }
      `;
    }
    if (type === 'fromMaxToMin') {
      return css`
        path:last-of-type {
          display: none;
        }
      `;
    }
  }}
`;

export const StyledTableWrapper = styled.div`
  &.is-tour-available {
    overflow-y: hidden;
  }
  @media only screen and (max-width: 1199px) {
    max-width: 380px;
    align-self: center;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    ${({ mobileDevice }) => {
    if (mobileDevice) {
      return css`
          width: 380px;
          align-self: center;
        `;
    }
  }}
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-style: hidden;
  border-collapse: separate;
  border-spacing: 0 5px;
  @media only screen and (max-width: 1199px) {
    margin-top: 0px;

    padding: 0 10px;
  }
`;

export const StyledThead = styled.thead`
  display: none;
`;

export const StyledTbody = styled.tbody``;

export const StyledTrTh = styled.tr``;

export const StyledTh = styled.th`
  padding: 12px 0 12px;
  background: black;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 1px;
  color: #ffffff;
  &:first-of-type {
    border-top-left-radius: 9px;
  }
  &:last-of-type {
    border-top-right-radius: 9px;
  }
`;

export const StyledDraggingPlaceholder = styled.div`
  margin: 0;
  border-radius: 5px;
  ${({ placeholder }) => {
    if (placeholder) {
      return css`
        transform: none !important;
      `;
    }
  }}
  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
`;

export const StyledTrTd = styled.tr`
  /* box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05); */
  margin: 0px 0;
  border-radius: 5px;

  ${({ placeholder }) => {
    if (placeholder) {
      return css`
        transform: none !important;
      `;
    }
  }}
  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
  &.clicked {
    background: #0e4afb;
    * {
      color: #ffffff;
    }
  }
  &:last-of-type {
    border-bottom: 0px solid #bdbdbd;
  }
`;

export const StyledTd = styled.td`
  text-align: center;
  padding: 0px 1px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  border-radius: 5px;
  background-color: #fff;
  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 0;
        `
      : ``}

  /*   line-height: 20px; */
  color: #000;
  width: 350px;
  box-shadow: 0 0 5px 3px rgb(0 0 0 / 5%);
  ${({ small }) => {
    if (small) {
      return css`
        width: 120px;
      `;
    }
  }}/*   &:first-of-type {
    font-size: 14px;
    border-bottom-left-radius: 9px;
  }
  &:last-of-type {
    border-bottom-right-radius: 9px;
  } */
`;

export const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
  height: 50px;
`;
export const StyledCell = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
  color: ${({ color }) => color};
`;
export const StyledCellName = styled.span`
  color: #999;
  padding: 0;
`;
export const StyledChartContainer = styled.div`
  padding: ${({ small }) => (small ? '0px' : '15px 20px')};
  display: flex;
  flex-direction: row;
  justify-content: start;
  line-height: 10px;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
  span {
    font-size: 9px;
  }
`;
export const StyledSection = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  text-align: left;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
  span {
    line-height: 15px;
  }
`;
export const StyledRatingCell = styled.div`
  position: relative;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  margin: 0 auto;
  color: ${({ color }) => color};
  @media (max-width: 530px) {
    width: 100px;
  }
`;
export const StyledTitle = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  svg {
    margin-left: 5px;
  }
  ${({ small }) => {
    if (small) {
      return css`
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
      `;
    }
  }}
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.5;
      `;
    }
  }}
`;
export const StyledTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 1px 0px 1px 10px;
  height: 30px;
  background-color: #fafafa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  ${({ small }) => {
    if (small) {
      return css`
        padding-left: 0;
      `;
    }
  }}
`;
export const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
export const StyledWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ small }) => {
    if (small) {
      return css`
        width: 170px;
      `;
    }
  }}
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        cursor: default;
      `;
    }
  }}
  ${({ selected }) => {
    if (selected) {
      return css`
        // box-shadow: 0px 1px 5px rgb(0 0 0 / 30%);
        border-radius: 5px;
      `;
    }
  }}
`;

export const StyledSortSelect = styled(Select)`
  background-color: transparent;
  width: 180px;
  margin-right: 15px;
  font-size: 13px;
  @media only screen and (max-width: 1199px) {
    display: flex;
    width: 180px;
    margin-right: 0px;
  }
  .react-select__menu {
    z-index: 2;
  }
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    min-height: 25px;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    box-shadow: none;
    @media only screen and (max-width: 1199px) {
      background-color: #fff;
      color: #000;
      border: solid 1px #d1d1d1;
      width: 100%;
      min-height: 38px;
      filter: none;
    }
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    padding: 8px 10px;
  }
  .react-select__indicator {
    svg {
      fill: #4b4b4a;
      width: 12px;
    }
  }
  .react-select__placeholder {
    @media only screen and (max-width: 1199px) {
      font-family: 'Gilroy', sans-serif;
      font-size: 14.222px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .react-select__option {
    @media only screen and (max-width: 1199px) {
      font-family: 'Gilroy', sans-serif;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }
`;

export const SortLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
`;

export const StyledSortContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 0px;
  width: 100%;
  border-bottom: ${({ showScrollStyles }) => `1px solid ${showScrollStyles ? '#fff' : '#f2f2f2'}`};

  @media only screen and (max-width: 1199px) {
    border: 0;
    padding: 0px;
    margin-bottom: 0px;
  }
  h3 {
    font-size: 50px;
  }
`;
export const StyledCloseIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const StyledCross = styled(Cross)`
  width: 8px;
  height: 8px;
  path {
    stroke: #fff;
    fill: #fff;
  }
`;

export const StyledInUse = styled.div`
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    text-transform: uppercase;
    margin-right: 5px;
    font-size: 10px;
    font-weight: 600;
    line-height: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledInfoIcon = styled(StyledInfo)``;

export const StyledFilterContainer = styled.div`
  position: relative;
  /* top: 40px; */
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 15px;
  height: 43px;
  @media only screen and (max-width: 1199px) {
    justify-content: space-between;
    padding: 2px 0px;
    align-items: center;
  }
`;

export const StyledButton = styled.button`
  height: 23px;
  min-width: 60px;
  padding: 5px 15px;
  background-color: ${({ isActive }) => (isActive ? '#000' : '#f6f6f4')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#999999')};
  border: 0;
  border-radius: 15px;
  font-size: 12px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  width: 380px;
  height: 90px;
  z-index: 3;
  left: 0;
  margin: 0 -20px;
  ${({ showScrollStyles }) => {
    if (showScrollStyles) {
      return css`
        box-shadow: 0 1px 6px 0px rgb(0 0 0 / 20%);
      `;
    }
  }}
  @media only screen and (max-width: 1199px) {
    background-color: #e5e5e5;
    left: 0px;
    width: 100%;
    box-shadow: none;
    margin: 0;
    padding: 13px 10px;
    height: 88px;
    width: 380px;
  }
  @media only screen and (max-width: 768px) {
    top: 45px;
  }
  @media only screen and (min-width: 769px) and (max-width: 1199px) {
    top: 38px;
  }

  @media only screen and (max-width: 1199px) {
    align-self: center;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    width: 325px;
  }
`;

export const StyledFeeling = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  position: relative;
  ${({ feeling }) => {
    if (feeling === -1) {
      return css`
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 0;
          border-color: #d9d9d9 transparent transparent transparent;
          top: 0px;
          left: 0;
        }
      `;
    }
    if (feeling === 0) {
      return css`
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 0;
          border-color: #cc392d transparent transparent transparent;
          top: 0px;
          left: 0;
        }
      `;
    }
    if (feeling === 1) {
      return css`
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 0;
          border-color: #009568 transparent transparent transparent;
          top: 0px;
          left: 0;
        }
      `;
    }
  }}
`;
