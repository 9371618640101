import React, { useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import usePortal from 'react-useportal';

import Cloaser from '../Cloaser';

import { InvitePopup, LeavePopup } from '../../../Popup';

import { showPopupWindow } from '../../../../redux/actions/Popups';
import { userLogout } from '../../../../redux/actions/User';

import { dataForSidebar } from '../../../../redux/selector/Sidebar';
import { getUser, isAuth } from '../../../../redux/selector/User';
import { getPopupByName } from '../../../../redux/selector/Popups';

import { cashFormat } from '../../../../service/functions';
import { toOrdinalSuffix } from '../../../../service/utils';
import useOutsideClick from '../../../../service/hooks';

import {
  HOME_STORAGE,
  SQUAD_STORAGE,
  STRATEGY_STORAGE,
  DEALS_STORAGE,
  PLAYER_INFO_STORAGE,
  SEARCH_STORAGE,
  FINANCE_STORAGE,
} from '../../../../helpers/tour/constants';
import { allowTour } from '../../../../redux/actions/TourControl';

import { ReactComponent as TOLLogo } from '../../../../assets/img/tol-logo.svg';
import {
  StyledHeader,
  StyledUserInfo,
  StyledMoneyContainer,
  StyledRankingContainer,
  StyledArrow,
  StyledAvatar,
  StyledTOLLogo,
  StyledDropdownTrigger,
  StyledDropdownWrapper,
  StyledDropdownContainer,
  StyledLightGray,
  StyledDarkGray,
  StyledValue,
  DiscordLogoIcon,
} from './styled';
import UserDropdown from '../../../UserDropdown';
import { POPUPS } from '../../../../service/constants';

export default React.memo(function Header({ location, isleftopen, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector(dataForSidebar);
  const isAuthenticated = useSelector(isAuth);
  const { avatarID, discord, leaveTeam } = useSelector(getUser);
  const invitePopupData = useSelector(getPopupByName(POPUPS.INVITE_POPUP));
  const leavePopupData = useSelector(getPopupByName(POPUPS.LEAVE_POPUP));

  const avatarUrl = `https://cdn.discordapp.com/avatars/${discord}/${avatarID}.jpg`;
  const [isDropdownVisible, showDropdown] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => {
    if (isDropdownVisible) showDropdown(false);
  });

  React.useEffect(() => {
    if (leaveTeam) {
      localStorage.removeItem('isFromRegister');
      history.push('/');
    }
  }, [leaveTeam, history]);
  const toggleDropdown = () => {
    showDropdown(!isDropdownVisible);
  };

  const openInvitePopup = () => {
    showDropdown(false);
    dispatch(showPopupWindow(POPUPS.INVITE_POPUP, POPUPS.INVITE_POPUP));
  };

  const showTutorial = () => {
    const localStorageTourList = [
      HOME_STORAGE,
      SQUAD_STORAGE,
      STRATEGY_STORAGE,
      DEALS_STORAGE,
      PLAYER_INFO_STORAGE,
      SEARCH_STORAGE,
      FINANCE_STORAGE,
    ];
    localStorageTourList.forEach((localStorageItem) => {
      if (localStorageItem) {
        localStorage.removeItem(localStorageItem);
      }
    });
    showDropdown(false);
    dispatch(allowTour(true));
  };

  const logout = () => {
    dispatch(userLogout());
    localStorage.removeItem('isFromRegister');
  };

  const leaveGame = () => {
    dispatch(showPopupWindow(POPUPS.LEAVE_POPUP, POPUPS.LEAVE_POPUP));
  };

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  return (
    <StyledHeader>
      {!isEmpty(invitePopupData) && (
        <Portal>
          <InvitePopup popupName={POPUPS.INVITE_POPUP} />
        </Portal>
      )}
      {!isEmpty(leavePopupData) && (
        <Portal>
          <LeavePopup popupName={POPUPS.LEAVE_POPUP} />
        </Portal>
      )}
      <Link to="/home">
        <StyledTOLLogo src={TOLLogo} alt="TOL logo" />
      </Link>
      <Cloaser {...props} />
      <StyledUserInfo>
        <StyledLightGray>
          <StyledRankingContainer to="/league">
            <StyledValue>{toOrdinalSuffix(data.ranking + 1)}</StyledValue>
            <span>in League</span>
          </StyledRankingContainer>
        </StyledLightGray>
        <StyledDarkGray>
          <StyledMoneyContainer
            data-total-sign={`${data.cash < 0 ? 'negative' : 'positive'}`}
            to="/finance"
          >
            <StyledValue>{cashFormat(data.cash, '$')}</StyledValue>
            <span>Cash balance</span>
          </StyledMoneyContainer>
        </StyledDarkGray>
        <StyledDropdownWrapper>
          <StyledDropdownTrigger type="button" onClick={toggleDropdown}>
            {isAuthenticated ? (
              <StyledAvatar src={avatarUrl} alt="avatar" />
            ) : (
              <DiscordLogoIcon />
            )}
          </StyledDropdownTrigger>
          {isDropdownVisible && (
            <StyledDropdownContainer ref={ref}>
              <UserDropdown
                extend
                openInvitePopupCallback={() => {
                  openInvitePopup();
                }}
                logoutCallback={() => {
                  logout();
                }}
                leaveGameCallback={() => {
                  leaveGame();
                }}
                showTutorialCallback={() => {
                  showTutorial();
                }}
              />
            </StyledDropdownContainer>
          )}
        </StyledDropdownWrapper>
        {/* <Discord /> */}
        <StyledArrow
          active={String(
            isleftopen ||
              location.pathname === '/home' ||
              location.pathname === '/finance'
          )}
          className="arrow"
        />
      </StyledUserInfo>
    </StyledHeader>
  );
});
