import React from 'react';
import ReactTooltip from 'react-tooltip';
import { isMobile } from 'react-device-detect';

import {
  StyledRatingAndPotentialContent,
  StyledUnknownTableValue,
  StyledReactTooltip,
  StyledScoutingSpan,
} from '../../../../globalStyled';
import {
  UNKNOWN_POTENTIAL,
  SCOUTING,
  ratingRangeStatus,
} from '../../../../service/constants';

const RatingCell = ({ cell }) => {
  const resizeFunction = React.useCallback(() => {
    ReactTooltip.rebuild();
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    return () => window.removeEventListener('resize', resizeFunction);
  }, [resizeFunction]);
  const cellWithScout = cell.row?.original;

  if (cellWithScout.potential === SCOUTING && !cellWithScout.ratingValue) {
    return (
      <StyledScoutingSpan className="scouting">Scouting</StyledScoutingSpan>
    );
  }
  const ratingFromScout = cellWithScout.scout_results.length
    ? ratingRangeStatus[cellWithScout.scout_results[0].data.rating_range]
    : undefined;
  const ratingVal = cellWithScout.ratingValue || ratingFromScout;
  return (
    <>
      {ratingVal ? (
        <StyledRatingAndPotentialContent>
          {ratingVal}
        </StyledRatingAndPotentialContent>
      ) : (
        <StyledUnknownTableValue
          data-tip-disable={isMobile}
          data-tip
          clickable={false}
          data-for={`${cellWithScout._id}-rating`}
        >
          {UNKNOWN_POTENTIAL}

          <StyledReactTooltip
            id={`${cellWithScout._id}-rating`}
            type="light"
            effect="solid"
          >
            Scout this player to estimate rating
          </StyledReactTooltip>
        </StyledUnknownTableValue>
      )}
    </>
  );
};
export default React.memo(RatingCell);
