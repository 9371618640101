import React from 'react';
import ContentLoader from 'react-content-loader';

const SendMoneyPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={545}
    height={185}
    viewBox="0 0 545 185"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="23" y="74" rx="3" ry="3" width="235" height="40" />
    <rect x="579" y="185" rx="3" ry="3" width="545" height="16" />
    <rect x="24" y="128" rx="3" ry="3" width="235" height="40" />
    <rect x="286" y="73" rx="3" ry="3" width="235" height="40" />
    <rect x="287" y="127" rx="3" ry="3" width="235" height="40" />
    <rect x="24" y="20" rx="3" ry="3" width="143" height="23" />
  </ContentLoader>
);

export default SendMoneyPreloader;
