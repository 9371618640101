import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import SignUpForm from '../../SignUpForm';

import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledHeader,
} from '../styled';

import { hidePopupWindow } from '../../../redux/actions/Popups';

const SelectLeaguePopup = ({ popupName }) => {
  const dispatch = useDispatch();

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock paddingAround="15px">
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          <StyledHeader> League code</StyledHeader>
          <SignUpForm submitCallback={() => hidePopup()} />
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};
SelectLeaguePopup.propTypes = {
  popupName: PropTypes.string.isRequired,
};

export default SelectLeaguePopup;
