import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormInput from '../FormInput';
import {
  StyledFormContainer,
  StyledFormParagraph,
  StyledValidation,
  StyledInputContainer,
} from './styled';
import { ErrorCross } from '../../globalStyled';
import { sendLeagueCode, clearSignUpData } from '../../redux/actions/SignUp';
import { getSignUpData } from '../../redux/selector/SignUp';

const SignUpForm = ({ submitCallback = null }) => {
  const dispatch = useDispatch();
  const { selectTeamList, isLoading, error } = useSelector(getSignUpData);
  const [input, setInput] = React.useState('');

  const history = useHistory();
  useEffect(() => {
    dispatch(clearSignUpData());
  }, [dispatch]);
  useEffect(() => {
    if (!isEmpty(selectTeamList) && input) {
      submitCallback();
      history.push(`/select-team/${input}`);
    }
  }, [history, selectTeamList, input, error, submitCallback]);

  const sendCodeHandler = () => {
    dispatch(sendLeagueCode(input));
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && input) {
      e.preventDefault();
      e.stopPropagation();
      sendCodeHandler();
    }
  };
  const onInputChange = (e) => {
    const val = e.target.value;
    setInput(val);
  };
  return (
    <StyledFormContainer>
      {' '}
      <StyledFormParagraph>
        Have a league code? Use it here to join your friends league.
      </StyledFormParagraph>
      <StyledInputContainer>
        <FormInput
          inputVal={input}
          onChange={onInputChange}
          onKeyPress={(e) => onKeyDown(e)}
          placeholder="Enter league code"
          disabledButton={isLoading || !input}
          onButtonClick={sendCodeHandler}
        />
        {error && (
          <StyledValidation>
            <ErrorCross />
            {error}
          </StyledValidation>
        )}
      </StyledInputContainer>
    </StyledFormContainer>
  );
};
SignUpForm.propTypes = {
  submitCallback: PropTypes.func,
};

SignUpForm.defaultProps = {
  submitCallback: () => {},
};

export default SignUpForm;
