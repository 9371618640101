import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledLoanButton,
  StyledInput,
  StyledLoanInputWrapper,
} from './styled';

const NumberRangeSelect = ({
  callback = () => { },
  className = '',
  stepAmount = 100000,
  prefix,
  placeholder = "$0",
  suffix = "",
  maxValue = null,
  minValue = 0,
  thousandSeparator = true
}) => {
  const [amountNumber, changeAmount] = React.useState(minValue);
  const decrementAmount = (amount) => {
    const newVal = parseInt(amount) - stepAmount;
    if (newVal <= 0) {
      changeAmount(0);
      callback(0);
    } else {
      changeAmount(parseInt(amount) - stepAmount);
      callback(parseInt(amount) - stepAmount);
    }
  };
  const incrementAmount = (amount) => {
    changeAmount(parseInt(amount) + stepAmount);
    callback(parseInt(amount) + stepAmount);
  };

  return (
    <StyledLoanInputWrapper className={className}>
      <StyledLoanButton
        primary
        disabled={amountNumber <= minValue || !amountNumber}
        onClick={() => {
          decrementAmount(amountNumber);
        }}
        type="button"
      >
        -
      </StyledLoanButton>
      <StyledInput
        isNumericString
        defaultValue={minValue}
        value={amountNumber}
        allowNegative={false}
        onValueChange={(val) => {
          const { value } = val;
          changeAmount(value);
          callback(value);
        }}
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        suffix={suffix}
        placeholder={placeholder}
      />
      <StyledLoanButton
        disabled={maxValue ? amountNumber >= maxValue : false}
        primary
        onClick={() => {
          incrementAmount(amountNumber);
        }}
        type="button"
      >
        +
      </StyledLoanButton>
    </StyledLoanInputWrapper>
  );
};
NumberRangeSelect.propTypes = {
  stepAmount: PropTypes.number,
  callback: PropTypes.func,
  className: PropTypes.string,
};
NumberRangeSelect.defaultProps = {
  stepAmount: 100000,
  callback: () => { },
  className: '',
};
export default NumberRangeSelect;
