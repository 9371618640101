import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { StyledButton } from '../../../../globalStyled';
import { ReactComponent as Arrow } from '../../../../assets/img/SimpleArrow.svg';
import { ReactComponent as Logo } from '../../../../assets/img/tol-logo.svg';
import { ReactComponent as ReactLogo } from '../../../../assets/img/Discord-Logo-Black.svg';
import { ReactComponent as DiscordLogo } from '../../../../assets/img/discord-logo.svg';

export const DiscordLogoIcon = styled(DiscordLogo)`
  width: 90px;
  cursor: pointer;
`;

export const StyledLogo = styled(ReactLogo)`
  width: 50px;
`;

export const StyledValue = styled.h3`
  color: #f2f2f2;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1px;
`;

export const StyledArrow = styled(Arrow)`
  cursor: pointer;
  @media only screen and (max-width: 1199px) {
    display: none;
  }
  @media screen and (min-width: 1201px) {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    ${({ active }) => {
      if (active === 'true')
        return css`
          opacity: 0;
          pointer-events: none;
        `;
    }}/* display: ${({ active }) => (active ? 'none' : 'block')}; */
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 11;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.02);
  width: 100%;
  padding: 0px 20px;
  min-height: 46px;
  height: 46px;
  background: #101010;
  @media screen and (max-width: 1199px) {
    padding: 20px;
    justify-content: space-between;
    /* z-index: 10; */
  }
  /*   @media screen and (max-width: 991px) {
    height: 60px;
    padding: 20px;
  } */
`;

export const StyledName = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
`;

export const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;

  .empty-block-widget {
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: flex-end; */
    margin: 0 36px 0 24px;
    width: 95px;
    height: 55px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /* svg {
      transition: 0.8s;
      &:hover {
        transform: rotate(90deg);
      }
    } */
    @media screen and (max-width: 1199px) {
      width: 60px;
      margin: 0 24px 0 24px;
    }
    @media screen and (max-width: 426px) {
      /* display: none; */
      height: initial;
      position: fixed;
      right: 21px;
    }
    @media screen and (max-width: 330px) {
      margin: 0 15px 0 0;
    }
  }
`;

export const StyledMoneyContainer = styled(NavLink)`
  position: relative;
  z-index: 2;

  height: 46px;

  display: flex;
  flex-direction: column;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.001em;
  color: #fff;
  margin: 0 24px;
  text-decoration: none;
  span {
    font-size: 10px;
    color: #b2b2b2;
    font-weight: normal;
    line-height: 12px;
  }
  @media screen and (max-width: 426px) {
    display: none;
  }
  &[data-total-sign='negative'] {
    color: #eb5757;
    &:before {
      position: absolute;
      margin-right: 7px;
      content: '-';
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 19px;
      color: #eb5757;
    }
  }
`;

export const StyledRankingContainer = styled(NavLink)`
  position: relative;
  z-index: 2;

  height: 46px;

  display: flex;
  flex-direction: column;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.001em;
  color: #fff;
  margin: 0 24px;
  text-decoration: none;
  span {
    font-size: 10px;
    color: #b2b2b2;
    font-weight: normal;
    line-height: 12px;
  }
  /* margin: 0 10px; */
  @media screen and (max-width: 426px) {
    display: none;
  }
`;

export const StyledDropdownTrigger = styled(StyledButton)`
  border: 0;
  background-color: #101010;

  width: 40px;
  height: 46px;
  border-radius: 50%;
  min-width: 40px;
  z-index: 2;
  position: relative;
  padding: 0;
  margin: 0;
  img {
    max-width: 30px;
  }
  @media only screen and (max-width: 1199px) {
    display: none;
  }
`;

export const StyledAvatar = styled.img`
  max-width: 45px;
  border-radius: 50%;
`;

export const StyledDropdownWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -3px;
    right: -50px;
    width: 109px;
    height: 46px;
    transform: skew(-25deg, 0);
    background: #101010;

    z-index: 1;
    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }
`;

export const StyledDropdownContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.03);
  border: solid 0.2px #cccccc;
  position: fixed;
  top: 46px;
  right: 10px;
  background-color: #fff;
  width: 250px;
  z-index: 3;
`;

export const StyledTOLLogo = styled(Logo)`
  width: 185px;
  height: auto;
`;

export const StyledLightGray = styled.div`
  position: relative;
  height: 46px;
  width: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1199px) {
    display: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 170px;
    height: 46px;
    transform: skew(-25deg, 0);
    background: #000;
    z-index: 1;

    @media only screen and (max-width: 1199px) {
      height: 50px;
      width: 100%;
    }
  }
`;

export const StyledDarkGray = styled.div`
  position: relative;
  height: 46px;
  width: 170px;
  text-align: center;
  margin-right: 30px;
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 1199px) {
    display: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 170px;
    height: 46px;
    transform: skew(-25deg, 0);
    background: #000;

    z-index: 1;

    /*  @media only screen and (min-width: 769px) and(max-width: 991px) {
      height: 50px;
      width: 100%;
    } */
  }
`;
