import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as SuccessToast } from '../assets/img/checkmark.svg';
import { StyledToastCloseIcon } from '../globalStyled';

export const toastRender = (toastType = 'error', toastMessage) => {
  if (toastType === 'success') {
    toast.success(toastMessage, {
      icon: <SuccessToast />,
      closeButton: <StyledToastCloseIcon />,
    });
    return true;
  }
  if (toastType === 'error') {
    toast.error(toastMessage, { closeButton: <StyledToastCloseIcon /> });
    return true;
  }
  if (toastType === 'warning') {
    toast.warn(toastMessage, { closeButton: <StyledToastCloseIcon /> });
    return true;
  }
};
