import { createAction } from "redux-act";
import { sendContractOffer, extendContractOffer } from "../../../api/contracts";
import { isPopupLoading, setErrorToPopup } from "../Popups";
import { POPUPS } from "../../../service/constants";
// CONTRACTS
export const createNewContractOfferAction = createAction('Create new contract');
export const createNewContractOfferLoading = createAction('Creating new contract loading');

export const createNewContractOffer = ({ player, wage, playTime, length }) => async (dispatch) => {
    dispatch(isPopupLoading(POPUPS.CONTRACT_OFFER_POPUP, true));
    const response = await sendContractOffer({ player, wage, playTime, length });
    if (response.status === 200) {
    } else {
        const error = getContractError(response);

        dispatch(setErrorToPopup(POPUPS.CONTRACT_OFFER_POPUP, error));
    }
    dispatch(isPopupLoading(POPUPS.CONTRACT_OFFER_POPUP, false));
};
export const extendExistingContractOffer = ({ player, wage, playTime, length }) => async (dispatch) => {
    dispatch(isPopupLoading(POPUPS.CONTRACT_EXTEND_POPUP, true));
    const response = await extendContractOffer({ player, wage, playTime, length });

    if (response.status === 200) {
    } else {
        const error = getContractError(response)
        dispatch(setErrorToPopup(POPUPS.CONTRACT_EXTEND_POPUP, error));
    }
    dispatch(isPopupLoading(POPUPS.CONTRACT_EXTEND_POPUP, false));
};


/**
 * Returns the contract error with a header and a message depending on 
 * the response status
 */
const getContractError = (response) => {
    let error;
    if (response.status === 201) {
        error = {
            header: 'Illegal contract',
            text: 'Offered wage is too low/high',
        };
    } else if (response.status === 202) {
        error = {
            header: 'Illegal contract',
            text: 'Guaranteed playtime must be between 1 and 24 matches',
        };
    } else if (response.status === 203) {
        error = {
            header: 'Illegal contract',
            text: 'Length must be between 1 and 5 seasons',
        };
    } else if (response.status === 204) {
        error = {
            header: 'Can’t make this offer',
            text: 'The player is not considering offers at this point',
        };
    } else if (response.status === 205) {
        error = {
            header: 'Can’t make this offer',
            text: 'Player is already retired',
        };
    } else if (response.status === 206) {
        error = {
            header: 'Can’t make this offer',
            text: 'The player is not considering offers at this point',
        };
    } else if (response.status === 207) {
        error = {
            header: 'Can’t make this offer',
            text: 'The player is not considering offers at this point',
        };
    }else if (response.status === 208) {
        error = {
            header: 'Contract was declined',
            text: 'The player is looking to retire soon and not looking for a new contract',
        };
    }else if (response.status === 209) {
        error = {
            header: 'Unable to transfer this player yet',
            text: 'The player is on loan at another club',
        };
    }else if (response.status === 210) {
        error = {
            header: 'Unable to make this offer',
            text: 'We do not have enough cash available',
        };
    }else if (response.status === 211) {
        error = {
            header: 'Unable to sign this free agent',
            text: 'We`ve already signed too many free agents recently',
        };
    }else if (response.status === 212) {
        error = {
            header: 'We can´t buy this player at this point',
            text: 'Our roster is full',
        };
    }else if (response.status === 213) {
        error = {
            header: 'We can´t transfer this player just yet',
            text: 'One of the parties is playing a match',
        };
    } else if (response.status === 404) {
        error = {
            header: 'This isn’t right',
            text: 'Something is wrong with the contract terms',
        };
    }
    else {
        error = {
            header: 'Server error',
            text: 'We must have forgotten to pay our bills, again',
        };
    }
    return error;
}