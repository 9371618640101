import { createAction } from 'redux-act';
import mixpanel from 'mixpanel-browser';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { getLeagueCodeName } from '../../../api/league';
import { previewLoginRequest, leaveTeamCall } from '../../../api/user';
import { logoutLink, loginLink } from '../../../service/links';
import { addNotificationPopupRequest } from '../Popups';

export const login = createAction('login user in site');
export const logout = createAction('logout user from site');
export const testAuthUser = createAction('test if user authorized');
export const testAuthLoader = createAction('show loader when tested auth');
export const loggedAsWaited = createAction('user logged as waiter');
export const loginTeam = createAction('Login with team data');
export const setLeagueName = createAction('Set league name');
export const leaveTeam = createAction('Leave team request');
export const isLoggingOut = createAction('Logout started');

export const userLogout = () => async (dispatch) => {
  const env = runtimeEnv();
  const { REACT_APP_LINK } = env;
  const response = await fetch(logoutLink, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });
  dispatch(isLoggingOut(true));
  if (response.status === 200) {
    window.location.href = REACT_APP_LINK;
    dispatch(logout());
  }
};

export const testAuth = () => async (dispatch) => {
  dispatch(testAuthLoader({ loader: true }));
  // setTimeout(async () => {
  const response = await fetch(loginLink, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });
  // alert(`RESPONSE STATUS WHEN TESTED - ${response.status}`);
  if (response.status === 200) {
    const json = await response.json();
    mixpanel.identify(json.discord_id);
    mixpanel.people.set({
      $name: json.user_team.name,
      user_team: json.user_team.name,
      USER_ID: json.discord_id, // use human-readable names
    });
    dispatch(
      login({
        isAuth: true,
        discord: json.discord_id,
        team_info: json.user_team,
        username: json.username,
        avatarID: json.avatar,
        isPremium: json.isPremium,
      })
    );

    // dispatch(
    //   testAuthUser({
    //     isAuth: true,
    //     discord: json.discord_id,
    //     team_info: json.user_team,
    //   })
    // );
    dispatch(testAuthLoader({ loader: false }));
  } else if (response.status === 201) {
    const json = await response.json();
    mixpanel.identify(json.discord_id);
    mixpanel.people.set({
      $name: json.discord_id,
      user_team: 'None',
      USER_ID: json.discord_id, // use human-readable names
    });
    dispatch(
      loggedAsWaited({
        isAuthLikeWithoutTeam: true,
        discord: json.discord_id,
      })
    );
    dispatch(
      addNotificationPopupRequest({
        name: 'noTeamPopup',
        message:
          'It seems you do not own a team yet. Please select a league and a team first.',
      })
    );
  } else if (response.status === 406) {
    dispatch(testAuthUser({ isAuth: false }));
    dispatch(testAuthLoader({ loader: false }));
  } else {
    dispatch(testAuthUser({ isAuth: null }));
    dispatch(testAuthLoader({ loader: false }));
  }
  // }, 300);
};

export const previewLogin = (teamId, teamName) => async (dispatch) => {
  const response = await previewLoginRequest(teamId);
  if (response.status === 200) {
    const data = await response.json();
    let teamData;
    if (!teamId && !teamName) {
      teamData = {
        id: data.teamId,
        name: data.teamName,
      };
    } else {
      teamData = { id: teamId, name: teamName };
    }
    localStorage.setItem('previewTeamData', JSON.stringify(teamData));
    dispatch(
      login({
        team_info: {
          name: teamData.name,
          _id: teamData.id,
        },
      })
    );
  }
};

export const userLogin = (access_token) => async (dispatch) => {
  const response = await fetch(loginLink, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
    body: JSON.stringify({ access_token }),
  });

  if (response.status === 200) {
    const json = await response.json();
    mixpanel.identify(json.discord_id);
    mixpanel.people.set({
      $name: json.user_team.name,
      user_team: json.user_team.name,
      USER_ID: json.discord_id, // use human-readable names
      username: json.username,
      avatarID: json.avatar,
    });
    // alert("YOU LOGGED AS MEMBER");
    dispatch(
      login({
        isAuth: true,
        discord: json.discord_id,
        team_info: json.user_team,
        username: json.username,
        avatarID: json.avatar,
        isPremium: json.isPremium,
      })
    );

    // alert(`RECIVED DATA AFTER LOGGINING - ${JSON.stringify(json)}`);
  } else if (response.status === 201) {
    const json = await response.json();
    mixpanel.identify(json.discord_id);
    mixpanel.people.set({
      $name: json.discord_id,
      user_team: 'None',
      USER_ID: json.discord_id, // use human-readable names
    });
    // alert("YOU LOGGED AS WAITED");
    dispatch(
      addNotificationPopupRequest({
        name: 'noTeamPopup',
        message:
          'It seems you do not own a team yet. Please select a league and a team first.',
      })
    );
    dispatch(
      loggedAsWaited({
        isAuthLikeWithoutTeam: true,
        discord: json.discord_id,
      })
    );
  } else if (response.status === 406) {
    dispatch(login({ isAuth: null }));
  } else {
    dispatch(login({ isAuth: false }));
  }
};

export const getLeagueName = () => async (dispatch) => {
  const response = await getLeagueCodeName();
  if (response.status === 200) {
    const data = await response.json();
    dispatch(setLeagueName(data));
  }
};

export const leaveTeamRequest = () => async (dispatch) => {
  const response = await leaveTeamCall();
  if (response.status === 200) {
    dispatch(leaveTeam(true));
  }
};
