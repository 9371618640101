import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import { ReactComponent as FootballBGOpposite } from '../../../../../../assets/img/football-field-opposite.svg';
import { ReactComponent as FootballBGMobile } from '../../../../../../assets/img/football-field-mobile.svg';
import { ReactComponent as FootballBG } from '../../../../../../assets/img/football-field.svg';
import { ReactComponent as StyledRightArrowIcon } from '../../../../../../assets/img/go-arrow.svg';
import { ReactComponent as StyledRightArrowWhiteIcon } from '../../../../../../assets/img/go-arrow-white.svg';
import {
  StyledButton,
  InfoDark,
  StyledSectionContainer,
} from '../../../../../../globalStyled';

export const StyledSpinner = styled(Loader)`
  position: relative;
`;

export const StyledTooltipText = styled.span`
  text-transform: none;
`;

export const StyledInfoDark = styled(InfoDark)`
  margin-left: 5px;
  /* @media only screen and (max-width: 1100px) {
    right: -10px;
  } */
`;

export const StyledMarkup = styled.div`
  display: block;
  position: absolute;
  width: 870px;
  height: 620px;
  left: -110px;
  top: 150px;
  ${({ isOpponent }) => {
    if (isOpponent) {
      return css`
        top: 5px;
      `;
    }
  }}
  @media only screen and (max-width: 991px) {
    right: -140px;
    left: -140px;
    height: auto;
    top: 225px;
    width: auto;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  @media only screen and (min-width: 769px) and (max-width: 1199px) {
    /*         width: 550px;
        left: -107px;
        top: 200px; */
    //right: -140px;
    // left: -140px;
    right: 0;
    left: 0;
    height: auto;
    top: 225px;
    width: auto;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    left: -145px;
    ${({ mobileDevice }) => {
      if (mobileDevice) {
        return css`
          right: 0;
          left: 0;
          height: auto;
          top: 225px;
          width: auto;
          padding: 0;
          margin: 0;
          overflow: hidden;
        `;
      }
    }}
  }
`;

export const StyledGamedField = styled.div`
  &.football-container {
    margin-top: 30px;
    display: block;
    position: relative;

    max-width: 650px;
    max-height: 867px;
    height: 82vh;
    min-height: 750px;
    width: 100%;
    margin: 0px;
    @media screen and (max-width: 991px) {
      overflow-x: hidden;
      max-width: 650px;
    }
    @media screen and (max-width: 1199px) {
      /*       margin: 0 0px;
      height: 100%;
      overflow: hidden;
      max-height: 650px;
      min-height: 540px;
      margin-top: 0px; */

      margin: 0 -15px;
      height: 100%;
      // overflow: hidden;
      max-height: 650px;
      //min-height: 600px;
      max-width: 510px;
      margin-top: 0px;
      width: auto;
      padding: 0 15px;

      margin: 0 -15px;
      /* height: auto; */
      max-height: 574px;
      min-height: 790px;
      max-width: 510px;
      margin-top: 0px;
      width: auto;
      padding: 0 15px;
    }
    @media screen and(max-height: 560px) {
      height: 675px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1300px) {
      max-width: 585px;
    }

    * {
      user-select: none;
    }

    ul.grass {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      li {
        display: block;
        width: 100%;
        height: 10%;
      }
    }

    .border {
      display: block;
      width: auto;
      height: 100%;
    }
    .separator {
      display: block;
      position: absolute;
      top: 49%;
      width: 100%;
      transform: translate(0, -50%);
      border-top: 3px solid #939393;
    }
    .central-circle {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 3px solid #939393;
      border-radius: 50%;
      width: 186px;
      height: 186px;
      background-color: #fff;
      @media screen and (max-width: 1199px) {
        height: 186px;
        width: 186px;
      }
      @media screen and (max-width: 500px) {
        height: 119px;
        width: 119px;
      }
    }
    .penalty {
      display: flex;
      position: absolute;
      width: 60%;
      height: 22%;
      max-width: 405px;
      min-width: 196px;
      max-height: 183px;
      left: 50%;
      transform: translate(-50%, 0);
      border: 3px solid #939393;
      @media screen and (max-width: 500px) {
        max-width: 196px;
        max-height: 83px;
      }
      .gk-field {
        height: 55%;
        width: 51%;
        max-height: 100px;
        max-width: 205px;
        border: 3px solid #939393;
        margin: 0 auto;
      }
      &.top {
        top: 0;
        border-top: none;
        .gk-field {
          border-top: none;
        }
      }
      &.bottom {
        align-items: flex-end;
        bottom: 0;
        border-bottom: none;
        .gk-field {
          border-bottom: none;
        }
      }
    }
  }

  .playing-field {
    display: grid;
    width: 100%;
    height: 620px;
    position: absolute;
    top: 163px;
    left: 0;
    grid-template-columns: 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // gap: 1px 1px;
    grid-template-areas:
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .'
      '. . . . . . . . . . .';
    @media only screen and (max-width: 1199px) {
      grid-template-rows: 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%;
      height: 640px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1300px) {
      ${({ mobileDevice }) => {
        if (mobileDevice) {
          return css`
            height: 300px;
          `;
        }
      }}
    }
    &.away {
      //background-color: rgba(0, 177, 88, 0.8);
      .markup {
        top: 7px;
      }
    }
    /* @media only screen and (min-width: 768px) and (max-width: 1199px) {
        top: 200px;
        //outline: 12px solid rgba(255, 255, 255, 0.85);
      } */
    @media (max-width: 1199px) {
      margin-top: 220px;
      top: 0;
      //outline: 12px solid rgba(255, 255, 255, 0.85);
    }
  }
`;

export const StyledToggleTeamView = styled.div`
  display: flex;
  position: relative;
  width: 350px;
  flex-direction: row;
  padding: 4px;
  margin-top: 0px;
  border-radius: 18px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin: 0 auto;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    flex: 1;
    color: rgba(0, 0, 0, 0.4);
    border: 0px;
    outline: 0px;
    padding: 6px;
    border-radius: 18px;
    cursor: pointer;
    /*     box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06); */
    background-color: #fff;

    &:first-child {
      margin-right: 11px;
    }
    &.active {
      color: #fff;
      background-color: #000;
    }
  }
`;

export const StyledHome = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ activeclass }) => css`
    &.${activeclass} {
      .circle {
        background: #0e4afb !important;
        color: white;
      }
    }
  `}
  &.home-gk {
    .player {
      .name {
        position: static;
      }
    }
    grid-column: 10 / span 2;
    @media only screen and (max-width: 1199px) {
      grid-row: 1 / span 2;
    }
    grid-row: 1 / span 1;
  }
  &.home-lb {
    grid-column: 16 / span 5;
    grid-row: 4 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 4 / span 2;
    }
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-2-3-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
        grid-column: 16 / span 4;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-5-1 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
  }
  &.home-dcl {
    grid-column: 11 / span 5;
    grid-row: 4 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 4 / span 2;
    }
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-2-3-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }

    &.strategy-3-5-2 {
      grid-column: 14 / span 4;
      grid-row: 5 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-5-1 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
  }
  &.home-dcc {
    grid-column: 10 / span 2;
    grid-row: 7 / span 1;
    &.strategy-3-5-2 {
      grid-row: 5 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
  }
  &.home-dcr {
    grid-column: 6 / span 5;
    grid-row: 4 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 4 / span 2;
    }
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-2-3-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-3-5-2 {
      grid-column: 5 / span 2;
      grid-row: 5 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-column: 5 / span 2;
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-5-1 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
  }
  &.home-rb {
    grid-column: 1 / span 5;
    grid-row: 4 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 4 / span 2;
    }
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-2-3-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
        grid-column: 1 / span 6;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 4 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
    &.strategy-4-5-1 {
      @media only screen and (max-width: 1199px) {
        grid-row: 2 / span 4;
      }
    }
  }
  &.home-dml {
    grid-column: 10 / span 2;
    grid-row: 11 / span 1;
    &.strategy-4-2-3-1 {
      grid-column: 11 / span 5;
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
    &.strategy-3-5-2 {
      grid-column: 11 / span 5;
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
  }
  &.home-dmc {
    grid-column: 10 / span 2;
    grid-row: 8 / span 1;
    &.strategy-4-5-1 {
      grid-column: 10 / span 2;
    }
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
    &.strategy-4-5-1 {
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
  }
  &.home-dmr {
    grid-column: 14 / span 2;
    grid-row: 9 / span 1;
    &.strategy-4-2-3-1 {
      grid-column: 6 / span 5;
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
    &.strategy-3-5-2 {
      grid-column: 6 / span 5;
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
  } //
  &.home-lm {
    grid-column: 16 / span 5;
    grid-row: 9 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 7 / span 4;
    }
    &.strategy-4-2-3-1 {
      grid-column: 14 / span 4;
      grid-row: 9 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-5-1 {
      grid-column: 18 / span 2;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-3-5-2 {
      grid-column: 14 / span 4;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
  }
  &.home-lcm {
    grid-column: 11 / span 5;
    grid-row: 9 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 7 / span 4;
    }
    &.strategy-4-3-3 {
      grid-column: 11 / span 5;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-5-1 {
      grid-column: 14 / span 2;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
  }
  &.home-cam {
    grid-column: 6 / span 2;
    grid-row: 9 / span 1;
  }
  &.home-cm {
    grid-column: 6 / span 2;
    grid-row: 9 / span 1;
    &.strategy-4-2-3-1 {
      grid-column: 10 / span 2;
      grid-row: 9 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-4-1-1 {
      grid-column: 10 / span 2;
      grid-row: 9 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-3-5-2 {
      grid-column: 10 / span 2;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
  }
  &.home-rcm {
    grid-column: 6 / span 5;
    grid-row: 9 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 7 / span 4;
    }
    &.strategy-4-3-3 {
      grid-column: 6 / span 5;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-5-1 {
      grid-column: 6 / span 2;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
  }
  &.home-rm {
    grid-column: 1 / span 5;
    grid-row: 9 / span 1;

    @media only screen and (max-width: 1199px) {
      grid-row: 7 / span 4;
    }
    &.strategy-4-2-3-1 {
      grid-column: 4 / span 4;
      grid-row: 9 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-5-1 {
      grid-column: 2 / span 2;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-3-5-2 {
      grid-column: 5 / span 2;
      @media only screen and (max-width: 1199px) {
        grid-row: 8 / span 3;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 8 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 6 / span 1;
      }
    }
  }
  &.home-lw {
    grid-column: 14 / span 4;
    grid-row: 12 / span 1;
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
  }
  &.home-ls {
    grid-column: 11 / span 5;
    grid-row: 14 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 12 / span 1;
    }
    &.strategy-3-5-2 {
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
  }
  &.home-cf {
    grid-column: 10 / span 2;
    grid-row: 12 / span 1;
    &.strategy-4-2-3-1 {
      grid-row: 12 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
    &.strategy-4-4-1-1 {
      grid-row: 14 / span 1;
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
    &.strategy-4-5-1 {
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
  }
  &.home-rs {
    grid-column: 6 / span 5;
    grid-row: 14 / span 1;
    @media only screen and (max-width: 1199px) {
      grid-row: 12 / span 1;
    }
    &.strategy-3-5-2 {
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
  }
  &.home-rw {
    grid-column: 5 / span 2;
    grid-row: 12 / span 1;
    &.strategy-4-3-3 {
      @media only screen and (max-width: 1199px) {
        grid-row: 11 / span 2;
      }
    }
  }

  // make center player up a little bit

  /*   @media only screen and (max-width: 1199px) {
    // 3-5-2
    // 4-4-2
    &.home-rb,
    &.home-lb,
    &.home-dcr,
    &.home-dcl {
      grid-row: 3 / span 1;
    }
    &.home-lb {
      grid-column: 18 / span 1;
    }
    &.home-dcr {
      grid-column: 8 / span 1;
    }
    &.home-dcl {
      grid-column: 13 / span 1;
    }
    &.home-rb {
      grid-column: 3 / span 1;
    }
  } */
  /*   @media (max-width: 450px) {
    &.home-cf,
    &.home-rs,
    &.home-ls,
    &.home-lw,
    &.home-rw {
      grid-row: 3 / span 1;
    }
    &.home-dcl {
      grid-column: 4 / span 2;
      &.strategy-3-5-2 {
        grid-column: 9 / span 2;
      }
    }
    &.home-dcr {
      grid-column: 7 / span 2;
    }
    &.home-rcm {
      grid-column: 7 / span 2;
    }
    &.home-lcm {
      grid-column: 4 / span 2;
    }

    &.strategy-3-5-2.home-dcr {
      grid-column: 2 / span 2;
    }
  } */
`;

export const StyledAway = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &.away-gk {
    grid-column: 9 / span 1;
    grid-row: 14 / span 1;
    display: none;
  }
  &.away-lb {
    grid-column: 4 / span 2;
    grid-row: 14 / span 1;
  }
  &.away-dcl {
    grid-column: 8 / span 2;
    grid-row: 14 / span 1;
    &.strategy-3-5-2 {
      grid-column: 6 / span 2;
    }
  }
  &.away-dcc {
    grid-column: 10 / span 2;
    grid-row: 3 / span 1;
    &.strategy-3-5-2 {
      grid-row: 5 / span 1;
      grid-row: 14 / span 1;
    }
  }
  &.away-dcr {
    grid-column: 12 / span 2;
    grid-row: 14 / span 1;
    &.strategy-3-5-2 {
      grid-column: 14 / span 2;
    }
  }
  &.away-rb {
    grid-column: 16 / span 2;
    grid-row: 14 / span 1;
  }
  &.away-dml {
    grid-column: 8 / span 2;
    grid-row: 12 / span 1;
    &.strategy-3-5-2 {
      grid-column: 6 / span 2;
      grid-row: 11 / span 1;
    }
  }
  &.away-dmc {
    grid-column: 10 / span 2;
    grid-row: 10 / span 1;
    &.strategy-4-5-1 {
      grid-row: 11 / span 1;
      grid-row: 9 / span 1;
    }
  }
  &.away-dmr {
    grid-column: 12 / span 2;
    grid-row: 12 / span 1;
    &.strategy-3-5-2 {
      grid-column: 14 / span 2;
      grid-row: 11 / span 1;
    }
  }
  &.away-lm {
    grid-column: 4 / span 2;
    grid-row: 11 / span 1;
    &.strategy-4-2-3-1 {
      grid-column: 6 / span 2;
      grid-row: 10 / span 1;
    }
    &.strategy-4-5-1 {
      grid-column: 2 / span 2;
      grid-row: 9 / span 1;
    }
    &.strategy-3-5-2 {
      grid-column: 2 / span 2;
    }
  }
  &.away-lcm {
    grid-column: 8 / span 2;
    grid-row: 11 / span 1;
    &.strategy-4-3-3 {
      grid-column: 6 / span 2;
      grid-row: 10 / span 1;
    }
    &.strategy-4-5-1 {
      grid-column: 6 / span 2;
      grid-row: 9 / span 1;
    }
  }
  &.away-cm {
    grid-column: 6 / span 1;
    grid-row: 7 / span 1;
    &.strategy-4-2-3-1 {
      grid-column: 10 / span 2;
      grid-row: 10 / span 1;
    }
    &.strategy-3-5-2 {
      grid-column: 10 / span 2;
      grid-row: 11 / span 1;
    }
    &.strategy-4-4-1-1 {
      grid-column: 10 / span 2;
      grid-row: 9 / span 1;
    }
  }
  &.away-cam {
    grid-column: 6 / span 1;
    grid-row: 8 / span 1;
  }
  &.away-rcm {
    grid-column: 12 / span 2;
    grid-row: 11 / span 1;
    &.strategy-4-3-3 {
      grid-column: 14 / span 2;
      grid-row: 10 / span 1;
    }
    &.strategy-4-5-1 {
      grid-column: 14 / span 2;
      grid-row: 9 / span 1;
    }
  }
  &.away-rm {
    grid-column: 16 / span 2;
    grid-row: 11 / span 1;
    &.strategy-4-2-3-1 {
      grid-column: 14 / span 2;
      grid-row: 10 / span 1;
    }
    &.strategy-4-5-1 {
      grid-column: 18 / span 2;
      grid-row: 9 / span 1;
    }
    &.strategy-3-5-2 {
      grid-column: 18 / span 2;
    }
  }
  &.away-lw {
    grid-column: 6 / span 2;
    grid-row: 6 / span 1;
  }
  &.away-ls {
    grid-column: 8 / span 2;
    grid-row: 6 / span 1;
  }
  &.away-cf {
    grid-column: 10 / span 2;
    grid-row: 6 / span 1;
  }
  &.away-rs {
    grid-column: 12 / span 2;
    grid-row: 6 / span 1;
  }
  &.away-rw {
    grid-column: 14 / span 2;
    grid-row: 6 / span 1;
  }
  /* 
  @media (max-width: 450px) {
    &.away-dcl {
      grid-column: 7 / span 2;
    }
    &.away-dcr {
      grid-column: 4 / span 2;
    }
    &.away-rcm {
      grid-column: 4 / span 2;
    }
    &.away-lcm {
      grid-column: 7 / span 2;
    }
    &.strategy-3-5-2.away-dcl {
      grid-column: 9 / span 2;
    }
    &.strategy-3-5-2.away-dcr {
      grid-column: 2 / span 2;
    }
  } */
`;

export const StyledFootballBG = styled(FootballBG)`
  width: 100%;
`;
export const StyledLeftSponsorLogo = styled.img`
  background-color: #fff;
  padding: 3px 29px;
  position: absolute;
  left: 70px;
  width: 130px;
  top: 20px;
  cursor: pointer;
  @media only screen and (max-width: 1199px) {
    left: 5%;
    width: 105px;
    top: 51px;
    width: 105px;
    position: absolute;
    z-index: 1;
  }
`;

export const StyledRightSponsorLogo = styled.img`
  cursor: pointer;
  background-color: #fff;
  padding: 3px 29px;
  position: absolute;
  right: 70px;
  width: 130px;
  top: 20px;
  @media only screen and (max-width: 1199px) {
    top: 51px;
    right: 5%;
    width: 105px;
    z-index: 1;
  }
`;

export const StyledFootballBGOpposite = styled(FootballBGOpposite)`
  width: 100%;
`;
export const StyledFootballBGMobile = styled(FootballBGMobile)`
  width: 100%;
`;

export const StyledSaveFormation = styled(StyledSectionContainer)`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 10px;
  max-width: 570px;
  margin: 0 auto 25px auto;
  h3 {
    width: 100%;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.18px;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 20px;
  }
  @media screen and (max-width: 1199px) {
    width: 100%;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    ${({ mobileDevice }) => {
      if (mobileDevice) {
        return css`
          width: 100%;
          margin-bottom: 10px;
        `;
      }
    }}
  }
`;

export const StyledLeftSection = styled.div`
  h3 {
    padding-left: 0;
    font-size: 18px;
  }
  @media screen and (max-width: 1199px) {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    h3 {
      font-size: 16px;
      border: 0;
    }
  }
  ${({ mobileDevice }) => {
    if (mobileDevice) {
      return css`
        @media only screen and (min-width: 1200px) and (max-width: 1300px) {
          border-bottom: 1px solid #e5e5e5;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          h3 {
            font-size: 16px;
            border: 0;
          }
        }
      `;
    }
  }}
`;
export const StyledRightSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1199px) {
    width: 100%;
    align-items: center;
  }
  ${({ mobileDevice }) => {
    if (mobileDevice) {
      return css`
        @media only screen and (min-width: 1200px) and (max-width: 1300px) {
          width: 100%;
          align-items: center;
        }
      `;
    }
  }}
`;

export const StyledAutoPickButton = styled(StyledButton)`
  position: relative;
  width: 105px;
  height: 30px;
  font-size: 13px;
  color: #666666;
  border-radius: 4px;
  box-shadow: none;
  border: solid 0.5px #999;
  margin: 0;
  min-width: 105px;
  padding: 0;
  margin-right: 10px;
  background-color: #e5e5e5;
  color: #666666;
  border: 1px solid #e5e5e5;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
  @media screen and (max-width: 1199px) {
    width: 50%;
  }
`;

export const StyledMobileFormationButton = styled(StyledButton)`
  //background-color: #fff;
  height: 30px;
  //color: #333;
  border: 0;
  box-shadow: none;
  width: 50%;
  font-size: 12px;
`;

export const StyledMobileButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*   @media only screen and (max-width: 1199px) {
    max-width: 480px;
  } */
  button {
    width: 32%;
    font-size: 13px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    min-width: 32%;
  }
`;
export const StyledRightArrow = styled(StyledRightArrowIcon)``;
export const StyledRightWhiteArrow = styled(StyledRightArrowWhiteIcon)`
  width: 10px;
  height: 10px;
  margin-left: 10px;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledSwitchContainer = styled.div`
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1199px) {
    padding: 5px 20px 5px 0;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    ${({ mobileDevice }) => {
      if (mobileDevice) {
        return css`
          padding: 5px 20px 5px 0;
        `;
      }
    }}
  }
`;

export const StyledLogoWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 1px;
  z-index: 1;
`;
