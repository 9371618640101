import styled, { css } from 'styled-components';
import { StyledSectionContainer } from '../../globalStyled';
import { ReactComponent as ArrowRed } from '../../assets/img/arrow-red.svg';
import { ReactComponent as ArrowGreen } from '../../assets/img/arrow-green.svg';

export const StyledBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 210px;
  margin-right: 20px;
  @media only screen and (max-width: 1199px) {
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
  }
`;
export const StyledBalanceContainer = styled(StyledSectionContainer)`
  padding: 20px 25px;
  max-width: 210px;
  margin-bottom: 15px;
  @media only screen and (max-width: 1199px) {
    max-width: 48%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;
export const StyledCashContainer = styled(StyledSectionContainer)`
  padding: 20px 25px;
  max-width: 210px;
  @media only screen and (max-width: 1199px) {
    max-width: 48%;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 15px;
  }
`;

export const StyledBalanceLabel = styled.span`
  font-size: 12px;
  font-family: 'Gilroy', sans-serif;
  color: #999999;
  text-transform: uppercase;
  margin-bottom: 15px;
  ${({ isOut }) => {
    if (isOut) {
      return css`
        margin-top: 25px;
        color: #ea4335;
        font-weight: 600;
        @media only screen and (max-width: 1199px) {
          margin-top: 0;
        }
      `;
    }
  }};
  ${({ isIn }) => {
    if (isIn) {
      return css`
        color: #34a853;
        font-weight: 600;
      `;
    }
  }};
`;

export const StyledArrowRed = styled(ArrowRed)`
  margin-right: 5px;
`;
export const StyledArrowGreen = styled(ArrowGreen)`
  margin-right: 5px;
`;

export const StyledBalanceAmount = styled.span`
  font-size: 21px;
  font-family: 'Gilroy', sans-serif;
  font-weight: bold;
  color: #000;
`;
export const StyledCash = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #7f7f7f;
  font-size: 16px;
`;

export const StyledCashBlock = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1199px) {
    background-color: #fff;
    box-shadow: 0 1px 8px 0 rgba(110, 110, 110, 0.1);
    border-radius: 4px;
    padding: 12px;
    align-items: center;
    &:first-of-type {
      margin-bottom: 15px;
    }
  }
`;
export const StyledPreloaderContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        flex-direction: column;
        width: 49%;
        align-items: center;
        display: flex;
      `;
    }
  }}
`;
