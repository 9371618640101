import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import { UNKNOWN_POTENTIAL, SCOUTING } from '../../../../service/constants';
import { StyledScoutingSpan } from './styled';
import {
  StyledRatingAndPotentialContent,
  StyledUnknownTableValue,
  StyledReactTooltip,
} from '../../../../globalStyled';

const PotentialCell = ({ cell }) => {
  const cellWithScout = cell.row?.original;
  if (cellWithScout.potential === SCOUTING) {
    return (
      <StyledScoutingSpan className="scouting">Scouting</StyledScoutingSpan>
    );
  }
  if (cellWithScout.potential !== SCOUTING && cellWithScout.potentialValue) {
    return (
      <StyledRatingAndPotentialContent>
        {cellWithScout.potential}
      </StyledRatingAndPotentialContent>
    );
  }
  if (isEmpty(cellWithScout.potential) && cellWithScout.potentialValue) {
    return (
      <StyledRatingAndPotentialContent>
        {cellWithScout.potentialValue}
      </StyledRatingAndPotentialContent>
    );
  }
  return (
    <>
      {cellWithScout.potential ? (
        <StyledRatingAndPotentialContent>
          {cellWithScout.potential}
        </StyledRatingAndPotentialContent>
      ) : (
        <StyledUnknownTableValue
          data-tip-disable={isMobile}
          data-tip
          clickable={false}
          data-for={`${cellWithScout._id}-potential`}
        >
          {UNKNOWN_POTENTIAL}

          <StyledReactTooltip
            id={`${cellWithScout._id}-potential`}
            type="light"
            effect="solid"
          >
            Scout this player to estimate potential.
          </StyledReactTooltip>
        </StyledUnknownTableValue>
      )}
    </>
  );
};
export default React.memo(PotentialCell);
