import React from 'react';
import { useDispatch } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledWhiteCross,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';

const DiscordRejoinPopup = ({ popupName }) => {
  const dispatch = useDispatch();

  const rejoinDiscord = () => {
    window.open('https://discord.gg/jAjgAURECT', '_blank');
  };

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };
  return (
    <StyledPopUp>
      <StyledPopUpBlock maxWidth={500} theme="dark">
        <StyledWhiteCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          <StyledHeader theme="dark">You are missing out</StyledHeader>
          <StyledParagraph small aligned="left">
            Look... It’s quasi impossible, and zero fun, to manage a team on
            your own, without contacts with your peers and your players. It is
            sort of like eating a great meal when you have a cold... or drinking
            a hot drink on a crazy hot summer day... It could be so much more...
          </StyledParagraph>
          <StyledButtonContainer>
            <StyledButton discord onClick={rejoinDiscord}>
              Rejoin our Discord Server
            </StyledButton>
          </StyledButtonContainer>
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default DiscordRejoinPopup;
