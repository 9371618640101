import React from 'react';
import { StyledNoWinner, StyledWinner, StyledLabel } from './styled';

const QualifiedWinner = ({ winner = {} }) => {
  const { symbol = '' } = winner;
  return (
    <>
      <StyledWinner>
        {symbol ? <img src={symbol} alt="winner logo" /> : <StyledNoWinner />}
        <StyledLabel>QUALIFIED</StyledLabel>
      </StyledWinner>
    </>
  );
};

export default QualifiedWinner;
