import styled, { css } from 'styled-components';
import { ReactComponent as StyledLeftArrowIcon } from '../../../../../../../../../../assets/img/go-arrow-left.svg';
import { StyledSectionContainer } from '../../../../../../../../../../globalStyled';

export const StyledLeftArrow = styled(StyledLeftArrowIcon)``;
export const StyledContainer = styled(StyledSectionContainer)`
  z-index: 2;
  overflow-x: hidden;
  display: flex;
  position: relative;
  padding: 0px 20px;
  width: 380px;
  height: 100%;
  max-height: 750px;
  overflow-y: scroll;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  @media only screen and (max-width: 1300px) {
    ${({ mobileDevice }) => {
    if (mobileDevice) {
      return css`
          background-color: transparent;
          height: 100%;
          box-shadow: none;
          width: 100%;
          padding: 0px;
        `;
    }
  }}
  }
  /*   @media only screen and (min-width: 1101px) and (max-width: 1400px) {
  } */
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    ${({ mobileDevice }) => {
    if (!mobileDevice) {
      return css`
          width: 335px;
          padding: 0 5px;
        `;
    }
  }}
  }

  h3 {
    width: 100%;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.18px;
    padding: 9px 0 9px 20px;
  }
  /*   ${({ mobileDevice }) => {
    if (mobileDevice) {
      return css`
        background-color: transparent;
        height: 100%;
        box-shadow: none;
        width: 100%;
        padding: 0px;
      `;
    }
  }} */
`;

export const StyledSortContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const SortLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
`;
