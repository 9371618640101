import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledSideNav = styled.nav`
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 11; /* Stay on top */
  top: 46px; /* Stay at the top */
  left: 0;
  background: #1c1c1c;

  /* overflow-x: hidden; Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.2s;
  overflow: auto;
  /* overflow-y: auto; */
  // padding-bottom: 100px !important;
  @media screen and (max-width: 1100px) {
    top: 45px;
  }
  @media screen and (max-width: 426px) {
    top: 45px;
  }
  @media screen and (max-height: 450px) {
    padding-top: 15px;
  }
  span {
    transition: all 0.3s linear;
  }
  &[data-sideopen='false'] {
    width: 60px;
    padding: 0;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    @media screen and (max-width: 1199px) {
      width: 100%;
      z-index: 2;
      /* -100% */
      transform: translate3d(-100%, 0, 0);
      /* transform: translate3d(0,-100%, 0); */
    }
    span {
      /* width: 0; */
      opacity: 1;
      color: #b2b2b2;
      margin-top: 8px;
      font-size: 9px;
      opacity: 0.8;
    }
  }
  &[data-sideopen='true'] {
    width: 305px;
    padding: 34px 24px 0 17px;
    @media screen and (max-width: 1199px) {
      width: 100%;
      &[data-left='true'] {
        /* transform: translate3d(100%, 0, 0); */
        transform: translate3d(-100%, 0, 0);
      }
      &[data-left='false'] {
        width: 100%;
        z-index: 2;
      }
    }
    @media only screen and (max-width: 1199px) {
      padding: 0;
      padding-bottom: 50px;
    }
  }
`;
export const StyledSummary = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1199px) {
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
  }

  p {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 41px;
    color: #000000;
  }
  @media screen and (min-width: 1199px) {
    justify-content: center;
    p {
      width: 0;
      opacity: 0;
    }
  }
  &[data-left='true'] {
    opacity: 0;
    user-select: none;
    width: 0;
  }
`;
export const StyledNavLinks = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex !important;
  align-items: center;
  text-decoration: none;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12.642px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  transition: 0.3s;
  margin: 0;
  padding: 14px 21px;
  user-select: none;

  @media only screen and (max-width: 1199px) {
    margin: 0;
    padding: 13px 21px;
    flex-direction: row;
    justify-content: flex-start;
    color: #b2b2b2;
    opacity: 0.8;
  }
  &.blocked {
    pointer-events: none;
    opacity: 0.3;
  }
  &.selected {
    background: #383838;
    pointer-events: none;
    color: #ffffff;
    span {
      color: #ffffff;
      opacity: 1;
    }
    // TODO fix icon later
    #cup_icon {
      path {
        fill: #fff;
      }
    }
  }
  svg {
    transition: all 0.3s linear;
    margin-right: 36px;
    /*  path {
      stroke: #bbb;
    } */

    /*  g {
      stroke: black;
    } */
  }
  span {
    display: flex;
    color: #fff;
    opacity: 0.8;

    opacity: 0.5;
    @media screen and (max-width: 1199px) {
      display: block;
    }
  }

  @media screen and (min-width: 1199px) {
    /* padding: 15px 21px; */
    /* padding: 27% 20%; */
    /* padding: 25%; */
    justify-content: center;
    /*  &::before {
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translate(-50%, 0);
      content: attr(data-content);
      font-family: 'Gilroy', sans-serif;
      font-weight: 500;
      font-size: 10px;
      line-height: 101%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #b2b2b2;
    } */
    svg {
      margin: 0;
    }
  }
`;

export const StyledTextNavLink = styled(StyledNavLinks)`
  padding: 5px;
  text-align: center;
`;

export const StyledAvatar = styled.img`
  max-width: 65px;
  border-radius: 50%;
  @media only screen and (max-width: 1199px) {
    max-width: 60px;
    max-height: 60px;
    width: auto;
    height: auto;
  }
`;

export const StyledMobileContainer = styled.div`
  display: none;
  @media only screen and (max-width: 1199px) {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 20px;
  }
`;

export const StyledRankingContainer = styled.div`
  position: relative;
  z-index: 2;

  height: 60px;

  display: flex;
  flex-direction: column;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.001em;
  color: #000000;
  margin: 0 24px;
  text-decoration: none;
  @media only screen and (max-width: 1199px) {
    color: #fff;
  }
  span {
    font-size: 12px;
    color: #999999;
    font-weight: normal;
  }
`;
export const StyledMoneyContainer = styled(NavLink)`
  height: 60px;
  padding: 10px 18px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.001em;
  position: relative;
  color: #000000;
  text-decoration: none;
  z-index: 2;
  @media only screen and (max-width: 1199px) {
    color: #fff;
    text-align: center;
  }
  span {
    font-size: 12px;
    color: #999999;
    font-weight: normal;
  }
`;
export const StyledSidebar = styled.div`
  @media only screen and (min-width: 1199px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 50px;
  }
`;
