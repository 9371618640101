import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as NoDeals } from '../../../../assets/img/no-deals.svg';

export const StyledContainer = styled.div`
  height: 100%;
  h1 {
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-size: 25px;
    font-weight: bold;
    line-height: 41px;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
    text-transform: none;
  }
  &.first {
    &:empty {
      display: none;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    /* flex: 1; */
    /*     @media (min-width: 48em) {
      flex: 1;
      width: 23.5%;
      margin: 0 25px;
    } */
    @media screen and (max-width: 450px) {
      width: 100%;
      flex: none;
      margin: 0;
      padding-top: 30px;
    }
    @media screen and (min-width: 451px) and (max-width: 1024px) {
      width: 100%;
      flex: none;
      margin: 0;

      /* flex: 1;
      width: 35%;
      margin: 0 15px; */
    }
  }
  &.second {
    &:empty {
      display: none;
    }
    width: 46%;
    /* flex: 2.5; */
    @media (min-width: 48em) {
      /* flex: 2.5; */
      flex: 1;
      width: 48%;
      margin: 0 10px;
      margin-right: 0;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
      flex: none;
      padding-top: 30px;
    }
    @media screen and (min-width: 451px) and (max-width: 1024px) {
      width: 100%;
      flex: none;
    }
  }
`;

export const StyledNoTransfers = styled.div`
  h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledLink = styled(Link)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin: 15px 0;
  padding: 12px 20px;
  color: #ffffff;
  text-decoration: none;
  font-family: 'Gilroy', sans-serif;
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.01em;
  background-color: #000;
  transition: 0.3s;
  text-align: center;
  min-width: 180px;
  width: fit-content;
  outline: none;
  cursor: pointer;
`;

export const StyledWrapper = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-family: 'Gilroy', sans-serif;
  padding: 30px 30px 20px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 7px rgba(0, 0, 0, 0.02);
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const StyledLabel = styled.span`
  font-size: 14px;
  color: #999999;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
`;

export const StyledIcon = styled(NoDeals)`
  width: 50px;
`;

export const StyledDealsPreloader = styled.div`
  margin-left: 0;
  margin-top: 0;
  background-color: #fff;
  border-radius: 4px;
  width: 227px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    svg {
      width: 100%;
    }
  }
`;

export const StyledDealsTablePreloader = styled.div`
  width: 100%;
  margin-top: 20px;
  > h1 {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-size: 25px;
    font-weight: bold;
    line-height: 41px;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
    text-transform: none;
  }
`;

export const StyledTablePreloader = styled.div`
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
`;
