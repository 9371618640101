import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import usePortal from 'react-useportal';
import SearchTableFilter from '../../SearchTableFilter';
import RatingCell from '../../RatingCell';
import PotentialCell from '../../PotentialCell';
import ActionCell from '../../ActionCell';
import NameCell from '../../NameCell';
import { ScoutPopup, SignFreeAgentPopup, TeamInfoPopup } from '../../../../../components/Popup';

import {
  showScoutPopupVisibility,
  transferSetUserWithOfferToBuy,
  transferShowUnshowFilter,
  setDataForCardRequest,
} from '../../../../../redux/actions/Transfers';
import { showPopupWindow } from '../../../../../redux/actions/Popups';
import { teamAddToList } from '../../../../../redux/actions/Team';

import { leagueSetTeamInfo } from '../../../../../redux/actions/League';

import { dataForSidebar } from '../../../../../redux/selector/Sidebar';
import { getPopupsList, getPopupByName } from '../../../../../redux/selector/Popups';

import { playerFromList } from '../../../../../redux/selector/Team';
import { isAuth } from '../../../../../redux/selector/User';

// easy to create sorter by this
// how to make sorting by range - https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/filtering
import {
  StyledTable,
  StyledTableContainer,
  StyledThead,
  StyledTbody,
  StyledTh,
  StyledTd,
  StyledTrTh,
  StyledTrTd,
  StyledSelect,
  StyledSelectContainer,
  StyledSorted,
  StyledPagination,
  ArrowRight,
  ArrowLeft,
  StyledPaginationButton,
  StyledPageNumber,
  StyledPageLabel,
  StyledUnderlineTeam,
  StyledNameContainer,
} from './styled';

import { POPUPS, ratingRangeStatus } from '../../../../../service/constants';
import { simplifiedPosition } from '../../../../../service/data';
import { goToDiscordLogin } from '../../../../../service/functions';

const SortingPriority = {
  10: 'GK',
  9: 'LB',
  8: 'CB',
  7: 'RB',
  6: 'DM',
  5: 'CM',
  4: 'CAM',
  3: 'LW',
  2: 'RW',
  1: 'ST',
};

const { SCOUT_POPUP, FREE_AGENT_POPUP, TRANSFER_SEARCH_POPUP, LOAN_OFFER_POPUP, TEAM_INFO_POPUP, CONTRACT_OFFER_POPUP
} = POPUPS;

const isInRange = (number, range) => number >= range[0] && number <= range[1];

const DefaultColumnFilter = ({ column: { Header, id, preFilteredRows, setFilter } }) => {
  const handleMaxWidth = function () {
    const height = window.innerHeight;
    if (height <= 300) return 100;
    if (height <= 400) return 150;
    if (height <= 500) return 200;
    if (height <= 600) return 250;
    return 300;
  };

  const handleChange = (selectedOption) => {
    setFilter((selectedOption && selectedOption.value) || undefined);
  };
  const ageOptions = React.useMemo(() => {
    const _options = new Set();
    preFilteredRows.forEach((row) => {
      _options.add(row.values.age); // Get data with 'age' accessor
    });
    const data = Array.from(_options.values());
    const ageArr = [
      {
        label: '15-17',
        value: [15, 17],
      },
      { label: '18-20', value: [18, 20] },
      { label: '21-25', value: [21, 25] },
      { label: '26-30', value: [26, 30] },
      { label: '31-35', value: [31, 35] },
      { label: '36-40', value: [36, 40] },
      { label: '41+', value: [41, 100] },
    ];
    const availableAgeRange = [];
    ageArr.forEach((ageRange, index) => {
      if (data.find((ageElement) => isInRange(ageElement, ageRange.value)) !== undefined) {
        availableAgeRange.push(ageArr[index]);
      }
    });
    return availableAgeRange;
  }, [preFilteredRows]);

  const options = React.useMemo(() => {
    const _options = new Set();
    preFilteredRows.forEach((row) => {
      _options.add(row.values[id]);
    });
    const data = Array.from(_options.values())
      .sort(function (a, b) {
        return a - b;
      })
      .filter((element) => element)
      .map(function (element) {
        if (id === 'sortPriority') return { value: element, label: SortingPriority[element] };
        if (id === 'ratingId' || id === 'potentialId') return { value: element, label: ratingRangeStatus[element] };
        return { value: element, label: element };
      });
    return data;
  }, [id, preFilteredRows]);

  if (id === 'age') {
    return (
      <StyledSelectContainer>
        <StyledSelect
          onChange={handleChange}
          options={ageOptions}
          menuPlacement="auto"
          placeholder="Age"
          isClearable
          classNamePrefix="react-select"
        />
      </StyledSelectContainer>
    );
  }
  if (id === 'teamName') {
    return (
      <StyledSelectContainer>
        <StyledSelect
          onChange={handleChange}
          options={options}
          menuPlacement="auto"
          maxMenuHeight={handleMaxWidth()}
          placeholder={`${Header}`}
          isClearable
          /* onMenuClose={() => {
            alert('team hidden');
          }} */
          // isSearchable
          classNamePrefix="react-select"
        />
      </StyledSelectContainer>
    );
  }
  return (
    <StyledSelectContainer>
      <StyledSelect
        onChange={handleChange}
        options={options}
        menuPlacement="auto"
        maxMenuHeight={handleMaxWidth()}
        placeholder={`${Header}`}
        isClearable
        isSearchable={Header === 'Name'}
        classNamePrefix="react-select"
      />
    </StyledSelectContainer>
  );
};

export default React.memo(function Table({ columns, data, getUserToSell }) {
  const [clicked, setClicked] = useState(null);
  const dispatch = useDispatch();
  React.useEffect(() => () => dispatch(transferShowUnshowFilter(false)), [dispatch]);
  const freeAgentPopupData = useSelector(getPopupByName(POPUPS.FREE_AGENT_POPUP));
  const popupData = useSelector(getPopupsList);
  const dataForCash = useSelector(dataForSidebar);

  const teamInfoPopupData = useSelector(getPopupByName(TEAM_INFO_POPUP));
  const selectedPlayerFromTable = useSelector(playerFromList);
  const isAuthenticated = useSelector(isAuth);

  const customRange = useMemo(
    () => (rows, id, filterValue) =>
      rows.filter((row) => {
        const rowValue = row.values[id];

        return rowValue !== undefined ? isInRange(rowValue, filterValue) : false;
      }),
    []
  );
  customRange.autoRemove = (val) => !val;
  const filterTypes = useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      ageRange: customRange,
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: isMobile ? 15 : 50,
      },
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const showScoutModal = (cell) => {
    const { _id } = cell.row.original;
    dispatch(showScoutPopupVisibility(_id));
    dispatch(showPopupWindow(_id, SCOUT_POPUP, false));
  };
  const showSignModal = (cell) => {
    const { transfer, newOffer, teamName, _id } = cell.row.original;
    if (transfer === undefined && !newOffer && teamName !== 'Free agent') {
      dispatch(showPopupWindow(_id, TRANSFER_SEARCH_POPUP, false));
    }
    if (teamName === 'Free agent') {
      dispatch(showPopupWindow(_id, CONTRACT_OFFER_POPUP, false));
    }
  };

  const showLoanModal = (cell) => {
    const { first_name, last_name, _id } = cell.row.original;
    dispatch(
      setDataForCardRequest({
        id: _id,
        name: `${first_name} ${last_name}`,
        transfer_fee_offer: null,
      })
    );
    dispatch(showPopupWindow(LOAN_OFFER_POPUP, LOAN_OFFER_POPUP, false));
  };

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });
  return (
    <>
      {popupData[SCOUT_POPUP] && (
        <Portal>
          <ScoutPopup id={popupData[SCOUT_POPUP].id} popupName={SCOUT_POPUP} cash={dataForCash.cash} />
        </Portal>
      )}
      {!isEmpty(freeAgentPopupData) && (
        <Portal>
          <SignFreeAgentPopup
            id={freeAgentPopupData.id}
            popupName={FREE_AGENT_POPUP}
            cash={dataForCash.cash}
            deselectClicked={() => {
              setClicked(null);
            }}
          />
        </Portal>
      )}

      {!isEmpty(teamInfoPopupData) && (
        <Portal>
          <TeamInfoPopup popupName={TEAM_INFO_POPUP} />
        </Portal>
      )}
      <SearchTableFilter filterCallback={() => { }} headerGroups={headerGroups} />
      <StyledTableContainer>
        <StyledTable {...getTableProps()}>
          <StyledThead>
            {headerGroups.map((headerGroup) => (
              <StyledTrTh {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTh type={column.Header} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        <React.Fragment>
                          {column.isSortedDesc ? (
                            <StyledSorted type="fromMaxToMin" />
                          ) : (
                            <StyledSorted type="fromMinToMax" />
                          )}
                        </React.Fragment>
                      ) : (
                        <StyledSorted />
                      )}
                    </span>
                  </StyledTh>
                ))}
              </StyledTrTh>
            ))}
          </StyledThead>
          <StyledTbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <StyledTrTd className={`row-number-${row.id} search-cell`} clicked={clicked} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <StyledTd
                      onClick={(e) => {
                        if (selectedPlayerFromTable?._id !== cell.row.original._id) {
                          dispatch(teamAddToList(cell.row.original));
                        }
                        setClicked(row.id);
                        if (cell.column.Header === 'Team') {
                          if (!isEmpty(cell.row.original.team)) {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            dispatch(leagueSetTeamInfo(cell.row.original.team, false));
                            dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
                          }
                        }
                        if (cell.column.Header === 'Actions') {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          dispatch(transferSetUserWithOfferToBuy(cell.row.original));
                          return false;
                        }
                        if (!isEmpty(e) && (e.target.name === 'scoutCell' || e.target.name === 'signCell')) {
                          dispatch(transferSetUserWithOfferToBuy(cell.row.original));
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        } else {
                          getUserToSell(cell.row.original);
                        }
                      }}
                      type={cell.column.Header}
                      titleCell={cell.column.Header === 'Number'}
                      {...cell.getCellProps()}
                    >
                      {(function () {
                        switch (cell.column.Header) {
                          case 'Name':
                            return (
                              <StyledNameContainer>
                                <NameCell cell={cell} />
                              </StyledNameContainer>
                            );
                          case 'Potential':
                            return <PotentialCell cell={cell} />;
                          case 'Position':
                            return simplifiedPosition[cell.row.original.fav_position];
                          case 'Rating':
                            return <RatingCell cell={cell} />;
                          case 'Team':
                            return (
                              <>
                                {cell.row.original.teamName && cell.row.original.teamName !== 'Free agent' ? (
                                  <StyledUnderlineTeam>{cell.row.original.teamName}</StyledUnderlineTeam>
                                ) : (
                                  <span>Free agent</span>
                                )}
                              </>
                            );
                          case 'Actions':
                            return (
                              <ActionCell
                                name="signCell"
                                cell={cell}
                                openSignPopup={() => {
                                  if (!isAuthenticated) {
                                    goToDiscordLogin();
                                  } else {
                                    showSignModal(cell);
                                  }
                                }}
                                openLoanPopup={() => {
                                  if (!isAuthenticated) {
                                    goToDiscordLogin();
                                  } else {
                                    showLoanModal(cell);
                                  }
                                }}
                                openScoutPopup={() => {
                                  if (!isAuthenticated) {
                                    goToDiscordLogin();
                                  } else {
                                    showScoutModal(cell);
                                  }
                                }}
                              />
                            );
                          default:
                            return cell.render('Cell');
                        }
                      })()}
                    </StyledTd>
                  ))}
                </StyledTrTd>
              );
            })}
          </StyledTbody>
        </StyledTable>
      </StyledTableContainer>
      <StyledPagination>
        <StyledPageNumber>
          <StyledPaginationButton type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ArrowLeft />
          </StyledPaginationButton>
          <StyledPageLabel>
            Page <strong>{pageIndex + 1}</strong> of {pageOptions.length}
          </StyledPageLabel>
          <StyledPaginationButton type="button" onClick={() => nextPage()} disabled={!canNextPage}>
            <ArrowRight />
          </StyledPaginationButton>
        </StyledPageNumber>
      </StyledPagination>
    </>
  );
});
