import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/YouthCamp';

const initialValue = {
  isYouthCampAvailable: null,
  youthCampPlayers: [],
  youthCampFilteredPlayers: [],
  scoutsEnabled: false,
  scoutsRemaining: null,
  totalScouts: null,
  isLoading: false,
  isYouthCampPopupLoading: false,
  youthCampStatus: null,
  bidsRemaining: null,
  registeredStatus: null,
  scoutLoadingId: null,
  bidLoadingId: null,
};
export const youthCamp = handleActions(
  {
    // --------------GET YOUTH CAMP PLAYERS DATA-------------------
    [action.youthCampPlayersLoadingStart]: (draft) => {
      draft.isLoading = true;
    },
    [action.youthCampPlayersLoadingFinish]: (draft) => {
      draft.isLoading = false;
    },
    [action.setYouthCampData]: (draft, { payload }) => {
      draft.youthCampPlayers = payload.players;
      draft.scoutsEnabled = payload.scoutsEnabled;
      draft.scoutsRemaining = payload.scoutsRemaining;
      draft.totalScouts = payload.totalScouts;
      draft.bidsRemaining = payload.bidsRemaining;
    },
    [action.setYouthCampFilteredPlayers]: (draft, { payload }) => {
      draft.youthCampFilteredPlayers = payload;
    },
    [action.isScoutingPopupLoading]: (draft, { payload }) => {
      draft.isYouthCampPopupLoading = payload;
    },
    [action.setYouthCampScoutStatus]: (draft, { payload }) => {
      draft.youthCampStatus = payload;
    },
    [action.changePlayerDataByKey]: (draft, { payload }) => {
      const { id, key, value } = payload;
      const replaceIndex = draft.youthCampPlayers.findIndex(
        (player) => player._id === id
      );
      const newData = draft.youthCampPlayers;
      newData[replaceIndex][key] = value;
      draft.youthCampPlayers = newData;
    },
    [action.removePlayerByKey]: (draft, { payload }) => {
      const newData = draft.youthCampPlayers.filter(
        (player) => player._id !== payload
      );
      draft.youthCampPlayers = newData;
    },
    [action.setYouthCampAvailability]: (draft, { payload }) => {
      draft.isYouthCampAvailable = payload;
    },
    [action.decreaseScoutNumber]: (draft) => {
      draft.scoutsRemaining -= 1;
    },
    [action.increaseScoutNumber]: (draft) => {
      draft.scoutsRemaining += 1;
    },
    [action.decreaseBidNumber]: (draft, { payload }) => {
      draft.bidsRemaining -= payload;
    },
    [action.setRegisteredStatus]: (draft, { payload }) => {
      draft.registeredStatus = payload;
    },
    [action.setScoutButtonLoadingId]: (draft, { payload }) => {
      draft.scoutLoadingId = payload;
    },
    [action.setBidButtonLoadingId]: (draft, { payload }) => {
      draft.bidLoadingId = payload;
    },
  },
  initialValue
);
