import React from 'react';
import ContentLoader from 'react-content-loader';

const MatchesLoader = (props) => (
  <ContentLoader
    style={{ width: '100%' }}
    speed={2}
    width={600}
    height={85}
    viewBox="0 0 600 85"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="370" cy="40" r="18" />
    <rect x="399" y="31" rx="0" ry="0" width="100" height="18" />
    <rect x="91" y="31" rx="0" ry="0" width="100" height="18" />
    <circle cx="221" cy="40" r="18" />
    <rect x="271" y="26" rx="4" ry="4" width="53" height="29" />
    <rect x="3" y="84" rx="0" ry="0" width="597" height="1" />
  </ContentLoader>
);

export default MatchesLoader;
