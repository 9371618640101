import styled from 'styled-components';
import { ReactComponent as StyledRightArrowWhiteIcon } from '../../assets/img/go-arrow-white.svg';

export const StyledArrowLeft = styled(StyledRightArrowWhiteIcon)`
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;
export const StyledRightArrow = styled(StyledArrowLeft)`
  transform: rotate(180deg);
`;
