import React from 'react';
import { useSelector } from 'react-redux';
import { GroupTeamMobile } from '../../GroupTeam';

import {
  StyledRound,
  StyledGroup,
  StyledSection,
  StyledRoundContainer,
  StyledHeader,
  StyledTitle,
  StyledDate,
} from './styled';
import {
  qualFirstSet,
  qualSecondSet,
  firstRoundFirstSetTop,
  firstRoundFirstSetBottom,
  firstRoundSecondSetTop,
  firstRoundSecondSetBottom,
  secondRoundFirstSet,
  secondRoundSecondSet,
  finalRound,
} from '../../../redux/selector/DomesticCup';

const CupGroupTableMobile = ({ calendar }) => {
  const { homeTeam: qualFirstHome, awayTeam: qualFirstAway } =
    useSelector(qualFirstSet);
  const { homeTeam: qualSecondHome, awayTeam: qualSecondAway } =
    useSelector(qualSecondSet);
  const {
    homeTeam: firstRoundFirstSetTopHome,
    awayTeam: firstRoundFirstSetTopAway,
  } = useSelector(firstRoundFirstSetTop);
  const {
    homeTeam: firstRoundFirstSetBottomHome,
    awayTeam: firstRoundFirstSetBottomAway,
  } = useSelector(firstRoundFirstSetBottom);

  const {
    homeTeam: firstRoundSecondSetTopHome,
    awayTeam: firstRoundSecondSetTopAway,
  } = useSelector(firstRoundSecondSetTop);
  const {
    homeTeam: firstRoundSecondSetBottomHome,
    awayTeam: firstRoundSecondSetBottomAway,
  } = useSelector(firstRoundSecondSetBottom);

  const {
    homeTeam: secondRoundFirstSetHome,
    awayTeam: secondRoundFirstSetAway,
  } = useSelector(secondRoundFirstSet);
  const {
    homeTeam: secondRoundSecondSetHome,
    awayTeam: secondRoundSecondSetAway,
  } = useSelector(secondRoundSecondSet);

  const { homeTeam: finalRoundFirstSetHome, awayTeam: finalRoundFirstSetAway } =
    useSelector(finalRound);

  return (
    <>
      <StyledRound>
        <StyledRoundContainer>
          <StyledSection>
            <StyledHeader>
              <StyledTitle>Qualifiers</StyledTitle>
              <StyledDate>{calendar[0].date}</StyledDate>
            </StyledHeader>
            <StyledGroup>
              <GroupTeamMobile teamData={qualFirstHome} />
              <GroupTeamMobile side="right" teamData={qualFirstAway} />
            </StyledGroup>
            <StyledGroup>
              <GroupTeamMobile teamData={qualSecondHome} />
              <GroupTeamMobile side="right" teamData={qualSecondAway} />
            </StyledGroup>
          </StyledSection>
        </StyledRoundContainer>
        <StyledRoundContainer>
          <StyledSection>
            <StyledHeader>
              <StyledTitle>Quarter Finals - Round 1</StyledTitle>
              <StyledDate>{calendar[1].date}</StyledDate>
            </StyledHeader>
            <StyledGroup>
              <GroupTeamMobile teamData={firstRoundFirstSetTopHome} />
              <GroupTeamMobile
                side="right"
                teamData={firstRoundFirstSetTopAway}
              />
            </StyledGroup>
            <StyledGroup>
              <GroupTeamMobile teamData={firstRoundFirstSetBottomHome} />
              <GroupTeamMobile
                side="right"
                teamData={firstRoundFirstSetBottomAway}
              />
            </StyledGroup>
          </StyledSection>
        </StyledRoundContainer>
        <StyledRoundContainer>
          <StyledSection>
            <StyledHeader>
              <StyledTitle>Quarter Finals - Round 2</StyledTitle>
              <StyledDate>{calendar[2].date}</StyledDate>
            </StyledHeader>
            <StyledGroup>
              <GroupTeamMobile teamData={firstRoundSecondSetTopHome} />
              <GroupTeamMobile
                side="right"
                teamData={firstRoundSecondSetTopAway}
              />
            </StyledGroup>
            <StyledGroup>
              <GroupTeamMobile teamData={firstRoundSecondSetBottomHome} />
              <GroupTeamMobile
                side="right"
                teamData={firstRoundSecondSetBottomAway}
              />
            </StyledGroup>
          </StyledSection>
        </StyledRoundContainer>
        <StyledRoundContainer>
          <StyledSection>
            <StyledHeader>
              <StyledTitle>Semi Finals</StyledTitle>
              <StyledDate>{calendar[3].date}</StyledDate>
            </StyledHeader>
            <StyledGroup>
              <GroupTeamMobile teamData={secondRoundFirstSetHome} />
              <GroupTeamMobile
                side="right"
                teamData={secondRoundFirstSetAway}
              />
            </StyledGroup>
            <StyledGroup>
              <GroupTeamMobile teamData={secondRoundSecondSetHome} />
              <GroupTeamMobile
                side="right"
                teamData={secondRoundSecondSetAway}
              />
            </StyledGroup>
          </StyledSection>
        </StyledRoundContainer>
        <StyledRoundContainer>
          <StyledSection>
            <StyledHeader>
              <StyledTitle>Final</StyledTitle>
              <StyledDate>{calendar[4].date}</StyledDate>
            </StyledHeader>
            <StyledGroup>
              <GroupTeamMobile teamData={finalRoundFirstSetHome} />
              <GroupTeamMobile side="right" teamData={finalRoundFirstSetAway} />
            </StyledGroup>
          </StyledSection>
        </StyledRoundContainer>
      </StyledRound>
    </>
  );
};

export default CupGroupTableMobile;
