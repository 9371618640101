import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  StyledStuffContainer,
  StyledStuffImage,
  StyledStuffList,
  StyledNameBlock,
  StyledImgContainer,
  StyledStuffButton,
  StyledStuffWrapper,
  StyledSelect,
} from './styled';
import { selectKitRequest } from '../../redux/actions/Stuff';

const TeamKits = ({ stuffData, selectedKit, isStuffLoading }) => {
  const stuffFilter = [
    { label: 'All', value: 'all' },
    {
      label: 'Available',
      value: 'available',
    },
    { label: 'For purchase', value: 'purchase' },
  ];
  const dispatch = useDispatch();
  const [filteredStuffData, setFilteredStuffData] = React.useState(stuffData);
  console.log('isStuffLoading', isStuffLoading);
  const changeStuffKits = (e) => {
    if (e.value === 'available') {
      setFilteredStuffData(
        stuffData.filter(
          (stuffItem) =>
            (stuffItem.type === 0 && stuffItem.status === 1) ||
            (stuffItem.type === 1 && stuffItem.status === 1)
        )
      );
    } else if (e.value === 'purchase') {
      setFilteredStuffData(
        stuffData.filter(
          (stuffItem) =>
            (stuffItem.type === 0 && stuffItem.status === 0) ||
            (stuffItem.type === 1 && stuffItem.status === 2)
        )
      );
    } else {
      setFilteredStuffData(stuffData);
    }
  };
  React.useEffect(() => {
    if (!isEmpty(stuffData)) {
      setFilteredStuffData(stuffData);
    }
  }, [stuffData]);
  const goToShop = (link) => window.open(link, '_blank');
  const kitSubmitType = (
    forPurchase,
    forSelect,
    forDisabled,
    id,
    availableForAll,
    shop_link
  ) => {
    if (selectedKit && selectedKit._id === id) {
      return (
        <StyledStuffButton disabled={isStuffLoading} blue>
          Current
        </StyledStuffButton>
      );
    }
    if (forPurchase) {
      return (
        <StyledStuffButton
          disabled={isStuffLoading}
          yellow
          onClick={() => goToShop(shop_link)}
        >
          Purchase
        </StyledStuffButton>
      );
    }
    if (forSelect || availableForAll) {
      return (
        <StyledStuffButton
          primary
          disbled={isStuffLoading}
          onClick={() => dispatch(selectKitRequest(id))}
        >
          Select
        </StyledStuffButton>
      );
    }
    if (forDisabled) {
      return (
        <StyledStuffButton blue disabled>
          Not available
        </StyledStuffButton>
      );
    }
    return null;
  };

  return (
    <>
      <StyledSelect
        onChange={changeStuffKits}
        options={stuffFilter}
        menuPlacement="auto"
        isSearchable={false}
        isClearable={false}
        classNamePrefix="react-select"
        defaultValue={stuffFilter[0]}
      />
      <StyledStuffList>
        {filteredStuffData?.map((stuff) => {
          const availableForAll = stuff.type === 3;
          const forPurchase =
            (stuff.status === 0 && stuff.type === 0) ||
            (stuff.status === 0 && stuff.type === 4) ||
            (stuff.status === 2 && stuff.type === 1);
          const forSelect =
            (stuff.status === 1 && stuff.type === 0) ||
            (stuff.status === 1 && stuff.type === 1) ||
            (stuff.status === 1 && stuff.type === 4);
          const forDisabled = stuff.status === 0 && stuff.type === 1;
          return (
            <StyledStuffWrapper disabled={forDisabled}>
              <StyledStuffContainer>
                <StyledImgContainer>
                  <StyledStuffImage src={stuff.image_1_link} />
                </StyledImgContainer>
                <StyledNameBlock>
                  <span>{stuff.name}</span>
                </StyledNameBlock>
              </StyledStuffContainer>
              {kitSubmitType(
                forPurchase,
                forSelect,
                forDisabled,
                stuff._id,
                availableForAll,
                stuff?.shop_link
              )}
            </StyledStuffWrapper>
          );
        })}
      </StyledStuffList>
    </>
  );
};
TeamKits.propTypes = {
  stuffData: PropTypes.array,
  selectedKit: PropTypes.shape,
  isStuffLoading: PropTypes.bool,
};
TeamKits.defaultProps = {
  stuffData: [],
  selectedKit: {},
  isStuffLoading: true,
};
export default TeamKits;
