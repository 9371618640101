import { headers } from './requestConfig';
import { hackRequest } from '../service/links';

export const sendHackRequest = async () => {
  const response = await fetch(hackRequest, {
    method: 'POST',
    headers,
    credentials: 'include',
  });
  return response;
};
