import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Rewards = () => {
  function adStatusCallback(status) {
    // This can contain whatever code you like. The err parameter will return the
    // following values (see online documentation for other values):
    //	'ad-blocker' = an ad blocker was detected
    //	'network-error' = network connection not available
    //  'cors-error' = cross-origin error (try clearing browser cache)
    //  'no-zoneId' = the required zoneId value is missing
    //	'ad-watched' = an ad was presented and ran for more than 5 seconds
    //	'ad-interrupted' = ad was ended prior to 5 seconds (abnormal end)
    //	'ads-unavailable' = no ads were returned to the player

    if (status) console.log(`Applixir status: ${status}`);
  }

  const options = {
    // see online documentation for additional informaiton
    zoneId: 5664, // required: the zone ID from the "Games" page
    accountId: 7326, // optional: your account ID (required for RMS)
    gameId: 7837, // optional: the ID for this game from the "Games"
    userId: 'fb05e08d-7cb0-403e-99c1-b5a1e90cfbd4', // required: UUID value for current customer (required for targeted ads - UUID4 recommended)
    adStatusCb: adStatusCallback, // required: callback function to provide information regarding ad status
  };

  React.useEffect(() => {
    console.log('applixir called');
  }, []);
  const watchIt = () => {
    window.invokeApplixirVideoUnit(options);
  };
  return (
    <>
      {/*       <HelmetProvider>
        <Helmet>
          <script
            type="text/javascript"
            src="https://cdn.applixir.com/applixir.sdk3.0m.js"
          />
        </Helmet>
      </HelmetProvider> */}
      <button type="button" onClick={watchIt}>
        press to invoke
      </button>
      <div id="applixir_vanishing_div" hidden>
        <iframe title="applixir" id="applixir_parent" />
      </div>
    </>
  );
};
export default Rewards;
