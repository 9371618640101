import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import {
  StyledBody,
  StyledSignUpContainer,
  StyledLeaguesContainer,
  StyledLeagueItem,
  StyledLeagueName,
  StyledLeagueAvailability,
  StyledMobileSubheader,
  StyledMobileBackButton,
  StyledLeftWhiteArrow,
  StyledLeagueCode,
  StyledTitle,
} from './styled';

import { sendLeagueCode } from '../../redux/actions/SignUp';
import { showMobileSidebarSection } from '../../redux/actions/Team';
import { showPopupWindow } from '../../redux/actions/Popups';
import { POPUPS } from '../../service/constants';

const SignUpLeagues = ({ noFreeTeamsAtUniverse, leaguesList, mobile }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const listToExclude = ['61284d3c5e4423001f8302d2'];
  const filteredLeaguesList = leaguesList?.leagues.filter(
    (league) => !listToExclude.includes(league._id)
  );

  // TODO Move sectionNameToDisplay from team to Sidebar
  // TODO Move mobile sidebar actions from team to Sidebar

  const goToTeamSelect = (isFreeTeamsAvailable, code) => {
    if (isFreeTeamsAvailable) {
      dispatch(sendLeagueCode(code));
      history.push(`/select-team/${code}`);
    }
  };
  const showLeagueCodePopup = () => {
    dispatch(
      showPopupWindow(
        POPUPS.SELECT_LEAGUE_CODE_POPUP,
        POPUPS.SELECT_LEAGUE_CODE_POPUP
      )
    );
  };
  return (
    <>
      {mobile && (
        <>
          <StyledMobileSubheader>
            <StyledMobileBackButton
              onClick={() => dispatch(showMobileSidebarSection(null))}
            >
              <StyledLeftWhiteArrow />
              Select Server
            </StyledMobileBackButton>
            <StyledLeagueCode onClick={showLeagueCodePopup}>
              Have league code?
            </StyledLeagueCode>
          </StyledMobileSubheader>
          <StyledTitle>Select a League</StyledTitle>
        </>
      )}
      <StyledSignUpContainer mobile={mobile}>
        <StyledBody>
          <StyledLeaguesContainer>
            {filteredLeaguesList?.map((league) => {
              const { freeTeams } = league;
              const isFull = freeTeams === 0 || noFreeTeamsAtUniverse;
              return (
                <StyledLeagueItem
                  isFull={isFull}
                  key={league._id}
                  onClick={() => goToTeamSelect(league.freeTeams, league.code)}
                >
                  <StyledLeagueName>
                    <img src={league.symbol} alt="league logo" />
                    <span>{league.name}</span>
                  </StyledLeagueName>
                  <StyledLeagueAvailability
                    mobile={mobile}
                    color={isFull ? '#ea4335' : '#34a853'}
                  >
                    {isFull ? 'No' : freeTeams}{' '}
                    {`team${freeTeams !== 1 ? 's' : ''}`} available
                  </StyledLeagueAvailability>
                </StyledLeagueItem>
              );
            })}
          </StyledLeaguesContainer>
        </StyledBody>
      </StyledSignUpContainer>
    </>
  );
};
SignUpLeagues.propTypes = {
  leaguesList: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  noFreeTeamsAtUniverse: PropTypes.bool,
};
SignUpLeagues.defaultProps = {
  mobile: false,
  noFreeTeamsAtUniverse: false,
};

export default SignUpLeagues;
