import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import TeamKits from '../../components/TeamKits';
import {
  getStuffData,
  getStuffKit,
  stuffLoading,
} from '../../redux/selector/Stuff';
import { getStuffResponse } from '../../redux/actions/Stuff';
import { StyledContainer } from './styled';
import { StyledWrapper } from '../styled';

export default function Stuff() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const stuffData = useSelector(getStuffData);
  const selectedKit = useSelector(getStuffKit);
  const isStuffLoading = useSelector(stuffLoading);
  useEffect(() => {
    if (location.pathname === '/stuff') {
      history.push('/stuff/team-kits');
    }
  }, [history, location.pathname]);

  useEffect(() => {
    if (isEmpty(stuffData)) {
      dispatch(getStuffResponse());
    }
  }, [dispatch]);
  return (
    <StyledWrapper>
      <StyledContainer>
        <Switch>
          <Route
            path="/stuff/team-kits"
            render={() => (
              <TeamKits
                stuffData={stuffData}
                selectedKit={selectedKit}
                isStuffLoading={isStuffLoading}
              />
            )}
          />
        </Switch>
      </StyledContainer>
    </StyledWrapper>
  );
}
