import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { ReactComponent as Logo } from '../../assets/img/tol-logo.svg';
import { ReactComponent as LogoMobile } from '../../assets/img/tol-logo-mobile.svg';
import { ReactComponent as StyledRightArrowWhiteIcon } from '../../assets/img/go-arrow-white.svg';
import FootballBg from '../../assets/img/football-bg.png';

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledTOLLogo = styled(Logo)`
  width: 185px;
  height: auto;
`;
export const StyledTOLMobileLogo = styled(LogoMobile)`
  width: 98px;
  height: auto;
`;

export const StyledMain = styled.section`
  position: relative;
  transition: margin-left 0.2s, margin-right 0.2s;
  padding: 16px;
  @media screen and (max-width: 1100px) {
    margin: 0 !important;
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 4;
  width: 100%;
  padding: 10px 36px;
  height: 46px;
  background: #101010;
  @media screen and (max-width: 1100px) {
    padding: 10px 24px 10px 36px;
    justify-content: space-between;
  }
  @media screen and (max-width: 426px) {
    min-height: 55px;
    padding: 19px 24px 19px 36px;
  }
`;

export const StyledLeftHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 50px;
    background-color: #858585;
    border-radius: 6px;
    padding: 10px;
    margin-right: 10px;
  }
`;
export const StyledRightHeaderSection = styled.div`
  font-family: Gilroy;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    font-size: 14px;
  }
`;

export const StyledCardWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 40px;
  width: 100%;
  margin: 0px 50px 20px 50px;
  z-index: 0;
  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
    margin-top: 20px;
  }
`;

export const StyledLeftWhiteArrow = styled(StyledRightArrowWhiteIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  transform: rotate(-180deg);
`;

export const StyledBackButton = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  left: 65px;
  cursor: pointer;
`;

export const StyledTitle = styled.h2`
  color: #fff;
  font-size: 25px;
  line-height: 1.44;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  @media only screen and (max-width: 991px) {
    text-align: center;
    width: 100%;
    font-size: 18px;
  }
`;

export const StyledSubheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
`;

export const StyledWrapper = styled.main`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  background-image: url(${FootballBg});
  background-color: #fff;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  > h1 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 28.833px;
    line-height: 41px;
    width: 100%;
    text-transform: none;
    margin-bottom: 24px;
    @media (min-width: 48em) {
      margin: 0 25px;
      margin-bottom: 24px;
    }
  }
  /*   @media screen and (max-width: 426px) {
    padding-top: 88px;
  } */
  @media only screen and (max-width: 991px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 105px;
    width: 100%;
  }
  @media (min-width: 1440px) {
    max-width: 1640px;
    width: 100%;
  }
  padding-top: 90px;
  @media (min-width: 48em) {
    padding-top: calc(63px + 16px);
    /* padding-top: 141px; */
  }
`;

export const StyledMobileSubheader = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 55px;
  z-index: 1;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 15px;
  background-color: #191919;
`;

export const StyledMobileBackButton = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: relative;
`;
