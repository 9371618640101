// TODO move all league calls here

import { headers } from './requestConfig';
import { createContract, extendContract } from '../service/links';


export const sendContractOffer = async ({
    player,
    wage,
    playTime,
    length
}) => {
    const response = await fetch(createContract, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify({
            player,
            wage,
            playTime,
            length
        }),
    });
    return response;
};

export const extendContractOffer = async ({
    player,
    wage,
    playTime,
    length
}) => {
    const response = await fetch(extendContract, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify({
            player,
            wage,
            playTime,
            length
        })
    });
    return response;
};
