import styled from 'styled-components';

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  @media only screen and (max-width: 991px) {
    height: 100%;
    //  overflow-y: hidden;
  }
`;

export const StyledAdsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    width: 1310px;
  }
  @media only screen and (max-width: 991px) {
    max-height: 200px;
  }
`;
