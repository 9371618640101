import { headers } from './requestConfig';
import {
  leagueCode,
  chooseTeam,
  matchesCount,
  universesList,
  addToWaitlist,
} from '../service/links';

export const sendLeagueCodeRequest = async (code) => {
  const response = await fetch(leagueCode, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      code,
    }),
  });
  return response;
};

export const selectTeamRequest = async (team) => {
  const response = await fetch(chooseTeam, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      team,
    }),
  });
  return response;
};

export const getMatchesCountRequest = async () => {
  const response = await fetch(matchesCount, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
export const getUniversesRequest = async () => {
  const response = await fetch(universesList, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const sendEmailForWaitlist = async (email) => {
  const response = await fetch(addToWaitlist, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      email,
    }),
  });
  return response;
};
