import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import {
  StyledInfoIcon,
  StyledReportLabel,
  StyledScoutReport,
} from '../../globalStyled';

import { youthCampPlayerDataParser, createBidLabel } from '../../service/utils';
import { ratingRangeStatus, POPUPS } from '../../service/constants';

import CommentIcon from '../CommentIcon';
import ReadMore from '../ReadMore';

import {
  StyledCardContainer,
  StyledPlayerHeader,
  StyledCardDetails,
  StyledCardDetailItem,
  StyledHeaderContent,
  StyledPlayerName,
  StyledLabel,
  StyledRow,
  StyledCardButton,
  StyledValue,
  StyledWrapper,
  StyledButtonContainer,
  StyledScoutContainer,
  StyledNotAvailable,
  StyledReactTooltip,
  StyledCross,
} from './styled';

import { showPopupWindow } from '../../redux/actions/Popups';

import {
  scoutYouthCampRequest,
  unscoutYouthCampRequest,
  removePlayerByKey,
} from '../../redux/actions/YouthCamp';

import {
  getScoutLoadingId,
  getBidLoadingId,
} from '../../redux/selector/YouthCamp';

const PlayerCard = ({
  id,
  firstName,
  lastName,
  age,
  favPosition,
  scoutResults,
  acquired,
  selectedToBeScouted,
  activeBid,
}) => {
  const dispatch = useDispatch();
  const scoutLoadingId = useSelector(getScoutLoadingId);
  const bidLoadingId = useSelector(getBidLoadingId);
  const showReadMoreFullPopup = () => {
    dispatch(showPopupWindow(id, POPUPS.READ_MORE_FULL_POPUP));
  };
  const showBidPopup = () => {
    dispatch(showPopupWindow(id, POPUPS.BID_POPUP));
  };
  const toggleScoutRequest = () => {
    if (selectedToBeScouted) {
      dispatch(unscoutYouthCampRequest(id));
    } else {
      dispatch(scoutYouthCampRequest(id));
    }
  };

  const fillCommentsList = (arr, requiredLength) => {
    let newArr = [];
    if (!arr) {
      newArr = [{}, {}, {}];
    } else {
      newArr = arr;
      let i;
      for (i = newArr.length; i < requiredLength; i += 1) {
        newArr.push({});
      }
    }
    return newArr;
  };

  const removePlayer = () => {
    dispatch(removePlayerByKey(id));
  };

  const { rating, potential } = youthCampPlayerDataParser(scoutResults);

  const renderComments = (originalResults) => {
    const results = sortBy(originalResults, ['youth_camp_level']);
    if (results && results.length < 3) {
      fillCommentsList(results, 3);
    }
    return !isEmpty(results) ? (
      results.map((item, index) => (
        <StyledScoutReport key={index}>
          {!isEmpty(item) ? (
            <>
              <CommentIcon type={item.data.commentary.type} />
              <ReadMore
                strLimit={50}
                str={item.data.commentary.text}
                onReadMoreClick={() => {
                  showReadMoreFullPopup();
                }}
              />
            </>
          ) : (
            <StyledNotAvailable>Not available</StyledNotAvailable>
          )}
        </StyledScoutReport>
      ))
    ) : (
      <>
        <StyledScoutReport>
          <StyledNotAvailable>Not available</StyledNotAvailable>
        </StyledScoutReport>
        <StyledScoutReport>
          <StyledNotAvailable>Not available</StyledNotAvailable>
        </StyledScoutReport>
        <StyledScoutReport>
          <StyledNotAvailable>Not available</StyledNotAvailable>
        </StyledScoutReport>
      </>
    );
  };
  return (
    <StyledWrapper>
      <StyledCardContainer isAcquired={acquired}>
        <StyledCross
          onClick={() => {
            removePlayer();
          }}
        />
        <StyledPlayerHeader>
          <StyledHeaderContent>
            <StyledPlayerName>
              {firstName} {lastName}
            </StyledPlayerName>
          </StyledHeaderContent>
          <StyledRow extendedPadding>
            <StyledCardDetailItem>
              <>
                <StyledValue>{age || 'N/A'}</StyledValue>
                <StyledLabel>age</StyledLabel>
              </>
            </StyledCardDetailItem>
            <StyledCardDetailItem>
              <StyledValue>{favPosition || 'N/A'}</StyledValue>
              <StyledLabel>position</StyledLabel>
            </StyledCardDetailItem>
          </StyledRow>
        </StyledPlayerHeader>
        <StyledScoutContainer>
          <StyledReportLabel>
            Scouting Reports
            <StyledInfoIcon iconsize="10" data-tip clickable data-for={id} />
            <StyledReactTooltip
              id={id}
              type="light"
              effect="solid"
              place="bottom"
            >
              Scouting reports will be added at the end of each day at camp.
            </StyledReactTooltip>
          </StyledReportLabel>
          {renderComments(scoutResults)}
        </StyledScoutContainer>
        <StyledCardDetails>
          <StyledRow>
            <StyledCardDetailItem>
              <StyledValue>{ratingRangeStatus[rating] || 'N/A'}</StyledValue>
              <StyledLabel>rating</StyledLabel>
            </StyledCardDetailItem>
            <StyledCardDetailItem>
              <StyledValue>{ratingRangeStatus[potential] || 'N/A'}</StyledValue>
              <StyledLabel>potential</StyledLabel>
            </StyledCardDetailItem>
          </StyledRow>
        </StyledCardDetails>
      </StyledCardContainer>
      <StyledButtonContainer>
        <StyledCardButton
          isActive={selectedToBeScouted}
          disabled={scoutLoadingId === id}
          primary
          onClick={() => {
            toggleScoutRequest();
          }}
        >
          {selectedToBeScouted ? 'Scouting' : 'Scout'}
        </StyledCardButton>
        <StyledCardButton
          disabled={activeBid || acquired || bidLoadingId === id}
          primary
          onClick={() => {
            showBidPopup();
          }}
        >
          {createBidLabel(acquired, activeBid)}
        </StyledCardButton>
      </StyledButtonContainer>
    </StyledWrapper>
  );
};
export default React.memo(PlayerCard);
