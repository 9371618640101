import styled, { css } from 'styled-components';
import { ReactComponent as Cross } from '../../../../assets/img/cross.svg';

export const StyledCross = styled(Cross)`
  position: absolute;
  top: 20px;
  right: 15px;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;
export const StyledRightBlock = styled.aside`
  padding: 0px 0px 100px 0px;
  max-height: calc(100vh - 65px);
  z-index: 10;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 46px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  transition: 0.2s;
  width: 300px;
  ${({ hasTopHeader }) => {
    if (hasTopHeader) {
      return css`
        top: 46px;
      `;
    }
  }}
  @media screen and (max-width: 1100px) {
    top: 45px;
  }
  @media screen and (max-width: 426px) {
    padding: 0px;
    top: 69px;
    max-height: calc(100vh - 69px);
    ${({ hasTopHeader }) => {
    if (hasTopHeader) {
      return css`
          top: 45px;
          max-height: calc(100vh - 45px);
        `;
    }
  }}
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    // width: 100%;
    max-width: inherit;
  }
  &[data-right='false'] {
    transform: translate3d(100%, 0, 0);
  }
  &.right-sidebar {
    @media screen and (max-width: 768px) {
      height: 100vh;
    }
  }
`;
