import styled from 'styled-components';
import { StyledSectionContainer } from '../../globalStyled';

export const StyledChartContainer = styled(StyledSectionContainer)`
  h3 {
    width: 100%;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.18px;
    padding: 17px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .recharts-cartesian-axis-tick {
    font-size: 10px;
  }
`;

export const StyledTooltip = styled.div`
  padding: 6px;
  background: #000;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
`;

export const StyledDate = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 6px;
`;
export const StyledVal = styled.p`
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
`;
