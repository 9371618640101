import { headers } from './requestConfig';
import { trainingTeams, trainingOffer } from '../service/links';

export const getTrainingTeams = async () => {
  const response = await fetch(trainingTeams, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const sendTrainingOffer = async (opponent) => {
  const response = await fetch(trainingOffer, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      opponent,
    }),
  });
  return response;
};
