import { createAction } from 'redux-act';
/* import mixpanel from 'mixpanel-browser'; */

import {
  getYouthCampPlayers,
  registerYouthCamp,
  scoutYouthCamp,
  unscoutYouthCamp,
  bidYouthCamp,
  getYouthCampStatus,
  checkIfUserRegistered,
} from '../../../api/youthCamp';
import { setErrorToPopup } from '../Popups';
import { toastRender } from '../../../service/toaster';
import { POPUPS } from '../../../service/constants';
import { simplifiedPosition } from '../../../service/data';

export const youthCampPlayersLoadingStart = createAction(
  'Youth camp loading start'
);
export const youthCampPlayersLoadingFinish = createAction(
  'Youth camp loading finish'
);
export const setYouthCampData = createAction('Set youth camp data');
export const setYouthCampFilteredPlayers = createAction(
  'Set youth camp filtered players'
);

export const isScoutingPopupLoading = createAction('Set scout loading value');

export const changePlayerDataByKey = createAction('Change player data by key');

export const setYouthCampScoutStatus = createAction(
  'Set Youth Camp Scout status'
);

export const setYouthCampAvailability = createAction(
  'Set Youth Camp availability'
);

export const decreaseScoutNumber = createAction('Decrease scouts number');
export const increaseScoutNumber = createAction('Increase scouts number');
export const decreaseBidNumber = createAction('Decrease bids number');
export const removePlayerByKey = createAction('Remove player from list');
export const setRegisteredStatus = createAction('Set registered status');
export const setScoutButtonLoadingId = createAction('Load scout request');
export const setBidButtonLoadingId = createAction('Load bid request');

export const getYouthCampPlayersRequest = () => async (dispatch) => {
  dispatch(youthCampPlayersLoadingStart());
  const response = await getYouthCampPlayers();
  if (response.status === 200) {
    const res = await response.json();
    const responseWithSimplifiedPositions = res.players.map((player) => ({
      ...player,
      fav_position: simplifiedPosition[player.fav_position],
    }));
    const formattedResponse = {
      ...res,
      players: responseWithSimplifiedPositions,
    };
    dispatch(setYouthCampData(formattedResponse));
    dispatch(youthCampPlayersLoadingFinish());
  } else {
    console.log('error');
  }
};
export const youthCampRegisterRequest = (scouts) => async (dispatch) => {
  dispatch(isScoutingPopupLoading(true));
  const response = await registerYouthCamp(scouts);
  dispatch(isScoutingPopupLoading(false));
  if (response.status === 200) {
    dispatch(setYouthCampScoutStatus(response.status));
  } else {
    let error;
    if (response.status === 402) {
      dispatch(setYouthCampScoutStatus(response.status));
      error = {
        header: 'Already registered',
        text: 'You are already registered for camp.',
      };
    } else if (response.status === 406) {
      error = {
        header: 'Error',
        text: 'User is not logged in.',
      };
    } else if (response.status === 407) {
      error = {
        header: 'Warning',
        text: 'Not enough money.',
      };
    }
    dispatch(setErrorToPopup('youthCampScoutPopup', error));
  }

  /*   else if (response.status === 203) {
    dispatch(setTeamResponseCode(203));
  } 
  else if (response.status === 405) {
    dispatch(setTeamResponseCode(405));
  }  */
  console.log('error');
};

export const scoutYouthCampRequest = (playerID) => async (dispatch) => {
  dispatch(setScoutButtonLoadingId(playerID));
  const response = await scoutYouthCamp(playerID);
  if (response.status === 200) {
    dispatch(
      changePlayerDataByKey({
        id: playerID,
        key: 'selectedToBeScouted',
        value: true,
      })
    );
    toastRender('success', 'Scout was sent!');
    dispatch(decreaseScoutNumber());
  } else if (response.status === 402) {
    toastRender('error', 'You have not enough scouts.');
  } else if (response.status === 409) {
    toastRender('error', 'Camp is closed.');
  } else if (response.status === 412) {
    toastRender(
      'error',
      'Camp is in progress - you cannot change who to scout between 12:00pm and 5:00pm. Please try again later.'
    );
  } else {
    toastRender('error', 'Server error. Please try again .');
  }
  dispatch(setScoutButtonLoadingId(null));
};
export const unscoutYouthCampRequest = (playerID) => async (dispatch) => {
  dispatch(setScoutButtonLoadingId(playerID));
  const response = await unscoutYouthCamp(playerID);
  if (response.status === 200) {
    dispatch(
      changePlayerDataByKey({
        id: playerID,
        key: 'selectedToBeScouted',
        value: false,
      })
    );
    dispatch(increaseScoutNumber());
    toastRender('success', 'Scout was unsent.');
  } else if (response.status === 402) {
    toastRender('error', 'You have not enough scouts.');
  } else if (response.status === 409) {
    toastRender('error', 'Camp is closed.');
  } else if (response.status === 412) {
    toastRender(
      'error',
      'Camp is in progress - you cannot change who to scout between 12:00pm and 5:00pm. Please try again later.'
    );
  } else {
    toastRender('error', 'Server error. Please try again .');
  }
  dispatch(setScoutButtonLoadingId(null));
};
export const bidYouthCampRequest = (bidsVal, playerID) => async (dispatch) => {
  dispatch(setBidButtonLoadingId(playerID));
  const response = await bidYouthCamp({ bidsVal, playerID });
  dispatch(isScoutingPopupLoading(true));
  if (response.status === 200) {
    dispatch(
      changePlayerDataByKey({
        id: playerID,
        key: 'activeBid',
        value: true,
      })
    );
    dispatch(decreaseBidNumber(bidsVal));
  } else {
    let error;
    if (response.status === 402) {
      error = {
        header: 'Not enought points',
        text: 'Please revise your bid accordingly',
      };
    } else {
      error = {
        header: 'Error.',
        text: response.status,
      };
    }
    dispatch(setErrorToPopup(POPUPS.BID_POPUP, error));
  }

  dispatch(setBidButtonLoadingId(null));
  dispatch(isScoutingPopupLoading(false));
};

export const getYouthCampStatusRequest = () => async (dispatch) => {
  const response = await getYouthCampStatus();
  if (response.status === 200) {
    dispatch(setYouthCampAvailability(true));
  } else {
    dispatch(setYouthCampAvailability(false));
  }
};

export const isUserRegistered = () => async (dispatch) => {
  const response = await checkIfUserRegistered();
  if (response.status === 200) {
    dispatch(setRegisteredStatus(true));
  } else {
    dispatch(setRegisteredStatus(false));
  }
};
