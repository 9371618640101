import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Finance';

const initialValue = {
  revenueAndExpanses: {
    data: {
      transactionsSummary: null,
      teamTotalSummary: null,
      loansAndTransfers: null,
    },
    loading: null,
  },
  financeChart: null,
  transactions: { data: {}, loading: null },
};
export const finance = handleActions(
  {
    // --------------GET DATA ABOUT REVENUE AND EXPANSES-------------------
    [action.setDataAboutRevenueAndExpensesToLoad]: (draft) => {
      draft.revenueAndExpanses.loading = true;
    },
    [action.getDataAboutRevenueAndExpenses]: (draft, { payload }) => {
      draft.revenueAndExpanses.data.transactionsSummary =
        payload.transactions_summary;
      draft.revenueAndExpanses.data.teamTotalSummary =
        payload.team_total_summary;
      draft.revenueAndExpanses.data.loansAndTransfers =
        payload.transactions_summary_transfers;
      draft.revenueAndExpanses.loading = false;
    },

    // --------------GET CHART DATA ------------------------
    [action.getFinanceChart]: (draft, { payload }) => {
      draft.financeChart = payload;
    },

    // --------------GET DATA ABOUT TRANSACTIONS-------------------
    [action.setDataAboutTransactionToLoad]: (draft) => {
      draft.transactions.loading = true;
    },
    [action.getDataAboutTransaction]: (draft, { payload }) => {
      draft.transactions.data = payload.transactions;
      draft.transactions.loading = false;
    },
  },
  initialValue
);
