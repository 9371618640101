import styled, { css } from 'styled-components';

export const StyledOpenFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  background-color: #000;
  width: 120px;
  border-radius: 3px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 95px;
    height: 28px;
  }
  > span {
    margin: 0 18px;
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    line-height: 26px;
    font-weight: 500;
    color: #fff;
  }
`;

export const StyledOpenFilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 26px;
  border-left: 1px solid #fff;
  background: #000;
  padding: 3px 0;
`;

export const StyledFilterContainer = styled.section`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const StyledManipulatorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  ${({ openFilter }) => {
    if (openFilter === 'false') {
      return css`
        display: none;
      `;
    }
  }}
`;
