import { headers } from './requestConfig';
import { home } from '../service/links';

export const getHomeData = async () => {
  try {

    const response = await fetch(home, {
      method: 'GET',
      headers,
      credentials: 'include',
    });
    console.log('response in getHomeData', response);
    return response;
  } catch (error) {
    console.log('error in getHomeData======', error)
  }
};
