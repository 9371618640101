import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as StyledRightArrowIcon } from '../../../../assets/img/go-arrow.svg';
import Transactions from './components/TransactBlock';
import { getTransactionsData } from '../../../../redux/selector/Finance';
import { transactionTypes } from '../../../../service/data';
import {
  StyledSection,
  StyledTransactionContainer,
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledTransactionSelect,
} from './styled';
import { StyledReactTooltip, InfoDarkRelative } from '../../../../globalStyled';

export default function TransactionContainer() {
  const transactionList = useSelector(getTransactionsData);
  const [transaction, setTransactionsList] = React.useState([]);

  React.useEffect(() => {
    setTransactionsList(transactionList);
  }, [transactionList]);
  const recordsPerPage = 10;
  const [noMoreRecords, setNoMoreRecords] = useState(false);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (transaction && transaction.length > 0) {
      setRecords(transaction.slice(0, recordsPerPage));
    }
  }, [transaction]);

  const handleShowMorePosts = () => {
    if (!noMoreRecords) {
      setRecords([
        ...records,
        ...transaction.slice(records.length, records.length + recordsPerPage),
      ]);
      setNoMoreRecords(records.length === transaction.length);
    }
  };

  const datesArray = React.useMemo(() => {
    const dates = new Set();
    records.forEach((record) => {
      dates.add(new Date(record.createdAt).toDateString());
    });
    return Array.from(dates.values());
  }, [records]);

  const transactionsFilterOptions = React.useMemo(() => {
    const typesArr = Object.keys(transactionTypes);
    const transactionOptionsList = [{ value: 'All', label: 'All' }];
    typesArr.forEach((type) => {
      if (transactionList.find((record) => record.type === parseInt(type))) {
        transactionOptionsList.push({
          label: transactionTypes[type],
          value: type,
        });
      }
    });
    return transactionOptionsList;
  }, [transactionList]);

  const setTransactionType = React.useCallback(
    (option) => {
      const valueFromSelect = option.value;
      if (valueFromSelect === 'All') {
        setTransactionsList(transactionList);
      } else {
        const filteredArray = transactionList.filter(
          (record) => record.type === parseInt(valueFromSelect)
        );
        setTransactionsList(filteredArray);
      }
    },
    [transactionList]
  );
  return (
    <StyledSection className="transactions-section">
      <StyledHeader>
        <h3 role="presentation">
          Transactions
          <InfoDarkRelative
            iconsize="10"
            data-tip
            clickable="true"
            data-for="transactions-tooltip"
          />
        </h3>

        <StyledReactTooltip
          id="transactions-tooltip"
          type="light"
          effect="solid"
          place="bottom"
        >
          Details all the transactions your club has registered each day
        </StyledReactTooltip>
        <StyledTransactionSelect
          defaultValue={
            !isEmpty(transactionsFilterOptions) && transactionsFilterOptions[0]
          }
          onChange={setTransactionType}
          options={transactionsFilterOptions}
          menuPlacement="auto"
          placeholder="Select"
          classNamePrefix="react-select"
          isSearchable={false}
        />
      </StyledHeader>

      <StyledContainer>
        <StyledTransactionContainer>
          {records && <Transactions posts={records} datesList={datesArray} />}
        </StyledTransactionContainer>
        <StyledButton data-last={noMoreRecords} onClick={handleShowMorePosts}>
          <button type="button">
            {noMoreRecords ? 'All data' : 'Load more'}
          </button>
          <StyledRightArrowIcon />
        </StyledButton>
      </StyledContainer>
    </StyledSection>
  );
}
