import { createSelector } from 'reselect';

export const getTeamFromLeague = (state) => state.league;

export const teamInformation = createSelector(
  getTeamFromLeague,
  ({ teamInfo }) => teamInfo
);
export const isLeaguesListLoaded = createSelector(
  getTeamFromLeague,
  ({ leaguesList }) => leaguesList
);
export const getLeagueId = createSelector(
  getTeamFromLeague,
  ({ leagueId }) => leagueId
);
export const isTeamListLoaded = createSelector(
  getTeamFromLeague,
  ({ teamsList }) => teamsList.teamsList
);
export const teamListLoader = createSelector(
  getTeamFromLeague,
  ({ teamsList }) => teamsList.isLoading
);
export const playerIsLoaded = createSelector(
  getTeamFromLeague,
  ({ leagueTeamLoader }) => leagueTeamLoader
);
// --------------------------CALENDAR----------------------

export const getCalendarFromLeague = (state) => state.league.calendar;

export const calendarDataLoading = createSelector(
  getCalendarFromLeague,
  ({ loading }) => loading
);
export const calendarData = createSelector(
  getCalendarFromLeague,
  ({ data }) => data
);
export const selectedMatchInCalendarLoading = createSelector(
  getCalendarFromLeague,
  ({ selectedMatch: { loading } }) => loading
);
export const selectedMatchInCalendar = createSelector(
  getCalendarFromLeague,
  ({ selectedMatch: { data } }) => data
);

// --------------------------LEAGUE TABLE--------------------------

export const getLeagueTable = (state) => state.league.table;

export const leagueTable = createSelector(getLeagueTable, ({ data }) => data);

export const leagueTableLoading = createSelector(
  getLeagueTable,
  ({ loading }) => loading
);

export const getPlayerInformationSelector = createSelector(
  getTeamFromLeague,
  ({ playerInfo }) => playerInfo
);
