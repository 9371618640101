import React from 'react';
import ContentLoader from 'react-content-loader';

const CupSidePreloader = (props) => (
  <ContentLoader
    speed={2}
    width={540}
    height={392}
    viewBox="0 0 540 392"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="339" cy="99" r="15" />
    <circle cx="340" cy="299" r="15" />
    <rect x="30" y="41" rx="0" ry="0" width="239" height="51" />
    <rect x="29" y="110" rx="0" ry="0" width="239" height="51" />
    <rect x="30" y="236" rx="0" ry="0" width="239" height="51" />
    <rect x="30" y="304" rx="0" ry="0" width="239" height="51" />
    <rect x="374" y="88" rx="0" ry="0" width="26" height="24" />
    <rect x="511" y="187" rx="0" ry="0" width="26" height="24" />
    <rect x="372" y="286" rx="0" ry="0" width="26" height="24" />
    <circle cx="479" cy="198" r="15" />
    <rect x="270" y="65" rx="0" ry="0" width="26" height="1" />
    <rect x="270" y="130" rx="0" ry="0" width="26" height="1" />
    <rect x="270" y="260" rx="0" ry="0" width="26" height="1" />
    <rect x="270" y="325" rx="0" ry="0" width="26" height="1" />
    <rect x="296" y="65" rx="0" ry="0" width="2" height="67" />
    <rect x="296" y="260" rx="0" ry="0" width="1" height="65" />
    <rect x="426" y="102" rx="0" ry="0" width="1" height="195" />
    <rect x="400" y="295" rx="0" ry="0" width="26" height="1" />
    <rect x="401" y="99" rx="0" ry="0" width="26" height="1" />
  </ContentLoader>
);

export default CupSidePreloader;
