import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledPopupButton, StyledPopupBlock } from './styled';
import {
  StyledPopUp,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { sendMoney } from '../../../redux/actions/Finance';
import { getPopupByName } from '../../../redux/selector/Popups';
import { numberWithCommas } from '../../../service/utils';

const SendMoneyPopup = ({
  cash,
  popupName,
  penaltiSize,
  sendingCash,
  selectedTeam,
}) => {
  const [defaultStep, showDefaultStep] = useState(true);
  const NSF = cash - penaltiSize < 0;

  const { error, isLoading } = useSelector(getPopupByName(popupName));

  useEffect(() => {
    showDefaultStep(true);
  }, []);
  const dispatch = useDispatch();
  const sendMoneyHandler = () => {
    showDefaultStep(false);
    if (!NSF) {
      dispatch(sendMoney(selectedTeam._id, sendingCash));
    }
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopupBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#2AFC98"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && !NSF && (
                <>
                  <StyledHeader>Confirm Transaction</StyledHeader>
                  <StyledParagraph>
                    You’re sending{' '}
                    <strong>${numberWithCommas(sendingCash)}</strong> to{' '}
                    {selectedTeam.name}.
                  </StyledParagraph>
                  <StyledParagraph>
                    Do you confirm this transaction?
                  </StyledParagraph>
                </>
              )}
              {NSF && defaultStep && (
                <>
                  <StyledHeader> Not enough money</StyledHeader>
                  <StyledParagraph>
                    You don't have enough money to perform this operation
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {!defaultStep && !error && !NSF && (
                <>
                  <StyledHeader>Thank you.</StyledHeader>
                  <StyledParagraph>Transaction is completed</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledPopupButton basic onClick={() => hidePopup()}>
                      Cancel
                    </StyledPopupButton>
                    <StyledPopupButton danger onClick={sendMoneyHandler}>
                      Yes, send
                    </StyledPopupButton>
                  </>
                ) : (
                  <StyledPopupButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledPopupButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopupBlock>
    </StyledPopUp>
  );
};
SendMoneyPopup.propTypes = {
  cash: PropTypes.number.isRequired,
  popupName: PropTypes.string.isRequired,
  penaltiSize: PropTypes.string.isRequired,
  sendingCash: PropTypes.string.isRequired,
  selectedTeam: PropTypes.object.isRequired,
};
export default React.memo(SendMoneyPopup);
