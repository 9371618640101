import { createAction } from 'redux-act';
import {
  sendLeagueCodeRequest,
  selectTeamRequest,
  getMatchesCountRequest,
  getUniversesRequest,
  sendEmailForWaitlist,
} from '../../../api/signUp';
import { POPUPS } from '../../../service/constants';
import { showPopupWindow } from '../Popups';

export const leagueCodeSendLoading = createAction('league code send loading');
export const chooseTeamSendLoading = createAction(
  'Choose team request was sent'
);
export const setTeamList = createAction('Team list is added');
export const setErrorToSignUp = createAction('SignUp error has occured');
export const clearSignUpData = createAction('Clear signUp data');
export const setTeamResponseCode = createAction(
  'Set choose team response code'
);
export const loadMatchesCount = createAction('Matches count are loading');
export const getMatchesCount = createAction('Set matches count');
export const sendLeagueCode = (code) => async (dispatch) => {
  dispatch(setErrorToSignUp(null));
  dispatch(leagueCodeSendLoading(true));
  const response = await sendLeagueCodeRequest(code);
  dispatch(leagueCodeSendLoading(false));
  if (response.status === 200) {
    const data = await response.json();
    data.sort((a, b) => b.free - a.free);
    dispatch(setTeamList({ data, code }));
  } else {
    let error;
    if (response.status === 404) {
      error = 'Invalid league code, please try again.';
    } else {
      error = 'There is some error. Please try again later.';
    }
    dispatch(setErrorToSignUp(error));
  }
};

export const sendSelectTeamRequest = (teamID) => async (dispatch) => {
  dispatch(chooseTeamSendLoading(true));
  const response = await selectTeamRequest(teamID);
  dispatch(chooseTeamSendLoading(false));
  if (response.status === 200) {
    dispatch(setTeamResponseCode(200));
    dispatch(
      showPopupWindow(POPUPS.DISCORD_LOGIN_POPUP, POPUPS.DISCORD_LOGIN_POPUP)
    );
  } else if (response.status === 203) {
    dispatch(
      showPopupWindow(POPUPS.DISCORD_LOGIN_POPUP, POPUPS.DISCORD_LOGIN_POPUP)
    );
    dispatch(setTeamResponseCode(203));
  } else if (response.status === 405) {
    dispatch(
      showPopupWindow(POPUPS.DISCORD_LOGIN_POPUP, POPUPS.DISCORD_LOGIN_POPUP)
    );
    dispatch(setTeamResponseCode(405));
  } else {
    console.log('error');
  }
};

export const getMatchesCountResponse = () => async (dispatch) => {
  dispatch(loadMatchesCount(true));
  const response = await getMatchesCountRequest();
  dispatch(loadMatchesCount(false));
  if (response.status === 200) {
    const data = await response.json();
    dispatch(getMatchesCount({ ...data }));
  }
};
export const universesListLoading = createAction('Lists of universes loading');
export const getUniversesList = createAction('List of universes loaded');
export const getUniverses = () => async (dispatch) => {
  dispatch(universesListLoading(true));
  const response = await getUniversesRequest();
  if (response.status === 200) {
    const data = await response.json();
    dispatch(getUniversesList(data));
  }
};

export const waitlistRequestLoading = createAction('Waitlist request loading');
export const setWaitlistRequestStatus = createAction('Waitlist request finish');

export const sendRequestToWaitList = (email) => async (dispatch) => {
  dispatch(waitlistRequestLoading(true));
  const response = await sendEmailForWaitlist(email);
  dispatch(setWaitlistRequestStatus(response.status));
};
