import styled from 'styled-components';

export const StyledBigBlock = styled.div`
  display: flex;
  width: 35%;
  padding: 20px 35px;
  justify-content: space-around;
  flex-direction: column;
  height: 195px;
`;
export const StyledSmallBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
  padding: 20px 0;
  text-align: center;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  height: 195px;
  font-family: 'Gilroy', sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #191919;
  opacity: 0.7;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const StyledDot = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: ${({ isDefault }) => (isDefault ? '#d9d9d9' : '#00996B')}; ;
`;

export const StyledDotsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isAway }) => (isAway ? 'row' : 'row-reverse')}; ;
`;
