import React from 'react';
import {
  DoubleUpArrow,
  UpArrow,
  DownArrow,
  FlatArrow,
} from '../../globalStyled';

const PlayerRating = ({ rate }) => {
  switch (true) {
    case rate > 4.9:
      return <DoubleUpArrow />;
    case rate > 0.1 && rate < 4.9:
      return <UpArrow />;
    case rate === 0:
      return <FlatArrow />;
    case rate < 0:
      return <DownArrow />;
    default:
      return null;
  }
};

export default PlayerRating;
