import styled from 'styled-components';

export const StyledYouthCampWrapper = styled.div`
  margin-top: 30px;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  row-gap: 1em;
  width: 100%;
  padding: 30px;
  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
    margin-top: 20px;
  }
`;
export const StyledYouthCampPlayerCard = styled.div``;
