import { headers } from './requestConfig';
import { leaveTeam, previewLink } from '../service/links';

export const leaveTeamCall = async () => {
  const response = await fetch(leaveTeam, {
    method: 'POST',
    headers,
    credentials: 'include',
  });
  return response;
};

export const previewLoginRequest = async (team) => {
  const previewUrl = team ? `${previewLink}?team=${team}` : `${previewLink}`;
  const response = await fetch(previewUrl, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
