import React, { useMemo } from 'react';
import moment from 'moment';
import Table from './Table';
import { StyledContainer, StyledTableContainer } from './styled';

export default function ScoutResultTableContainer({ scoutData }) {
  const data = useMemo(
    () =>
      scoutData.map((item) => ({
        date: `${moment(item.updatedAt)
          .utcOffset('-0800')
          .format('D MMM YYYY, ha')} PST`,
        potential: item.data.potential_range,
        fitness: item.data.form_range,
        rating: item.data.rating_range,
      })),
    [scoutData]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Rating',
        accessor: 'rating',
      },
      {
        Header: 'Potential',
        accessor: 'potential',
      },
      {
        Header: 'Fitness',
        accessor: 'fitness',
      },
    ],
    []
  );
  return (
    <StyledContainer>
      <StyledTableContainer>
        <Table data={data} columns={columns} />
      </StyledTableContainer>
    </StyledContainer>
  );
}
