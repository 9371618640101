import styled from 'styled-components';
import Select from 'react-select';

export const StyledContainer = styled.section`
  width: 100%;
`;

export const StyledSelectContainer = styled.div`
  width: 100%;
  margin: 5px;
  > input {
    border: none;
  }
  @media screen and (max-width: 1204px) {
    flex: none;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    max-width: inherit;
  }
`;

export const StyledSelect = styled(Select)`
  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }
`;

export const StyledTablePreloader = styled.div`
  background-color: #fff;
  border-radius: 4px;
`;
