import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { ReactComponent as InfoIcon } from '../../assets/img/tooltip-info-white.svg';
import { ReactComponent as LocationIcon } from '../../assets/img/server-location.svg';
import { ReactComponent as LocationWhiteIcon } from '../../assets/img/server-location-white.svg';

export const StyledServerSelect = styled(Select)`
  bottom: 0;
  background-color: transparent;
  width: 180px;
  margin-right: 15px;
  font-size: 13px;

  @media only screen and (max-width: 768px) {
    display: flex;
    width: 130px;
    margin-right: 0px;
    top: 0px;
    bottom: 100%;
    right: 0px;
  }

  .react-select__menu {
    z-index: 2;
  }
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    min-height: 25px;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    box-shadow: none;
    @media only screen and (max-width: 768px) {
      background-color: #000;
      color: #fff;
      border: solid 1px #d1d1d1;
      width: 100%;
      min-height: 38px;
      filter: none;
    }
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    padding: 8px 10px;
    &--is-focused,
    &--is-selected {
      background-color: #333333;
      color: #fff;
    }
  }
  .react-select__indicator {
    svg {
      fill: #4b4b4a;
      width: 12px;
    }
  }
  .react-select__single-value {
    padding-left: 25px;
    @media only screen and (max-width: 768px) {
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      padding-left: 20px;
    }
  }
  .react-select__placeholder {
    @media only screen and (max-width: 768px) {
      font-family: 'Gilroy', sans-serif;
      font-size: 14.222px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .react-select__option {
    @media only screen and (max-width: 768px) {
      font-family: 'Gilroy', sans-serif;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }
`;
export const StyledLocationIcon = styled(LocationIcon)`
  width: 14px;
  height: 14px;
  position: absolute;
  left: 10px;
`;
export const StyledLocationWhiteIcon = styled(LocationWhiteIcon)`
  width: 14px;
  height: 14px;
  position: absolute;
  left: 10px;
  z-index: 0;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  width: 12px;
  height: 12px;
  margin-left: 0px;
  ${({ iconsize }) => {
    if (iconsize)
      return css`
        width: ${iconsize}px;
        height: ${iconsize}px;
      `;
  }}
  &:focus,
  &:active {
    outline: none;
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;
export const StyledHeader = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  h3 {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.18px;
    width: 450px;
    &:first-of-type {
      width: 250px;
      border-right: 1px solid #7f7f7f;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
    }
  }
`;
export const StyledBody = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
`;
export const StyledSignUpContainer = styled.div`
  margin: 0 20px;
  border-radius: 4px;
  box-shadow: 8px 0 14px -2px rgba(112, 112, 112, 0.08);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 700px;
  @media only screen and (max-width: 991px) {
    background-color: transparent;
    margin: 0;
  }

  h3 {
    color: #fff;
    background-color: #333333;
    height: 42px;
  }
`;

export const StyledServersContainer = styled.div`
  min-width: 250px;
  box-shadow: 8px 0 14px -2px rgba(112, 112, 112, 0.08);
  height: 380px;
  /*   overflow-y: scroll; */
  @media only screen and (max-width: 991px) {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;
export const StyledServersList = styled.ul`
  height: 340px;
  overflow-y: scroll;
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  @media only screen and (max-width: 991px) {
    height: 100%;
    border: 0;
  }
`;
export const StyledServer = styled.li`
  position: relative;
  height: 30px;
  margin: 5px 0;
  display: ${(props) => (props.isFull ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  padding: 10px 10px 10px 20px;
  border: 0.1em solid transparent;
  cursor: pointer;
  background-color: #fff;
  @media only screen and (max-width: 991px) {
    height: 48px;
    justify-content: start;
    font-size: 15px;
    font-weight: 600;
    margin: 15px 0;
  }
  &:before {
    position: absolute;
    display: ${({ noIcon }) => (noIcon ? 'none' : 'flex')};
    top: 10px;
    left: 10px;

    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => (props.isFull ? '#ea4335' : '#34a853')};
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  &:hover {
    font-weight: 600;
    border: 0.1em solid #cccccc69;
    background-color: #f2f2f2;
  }
  ${({ isActive }) => {
    if (isActive) {
      return css`
        font-weight: 600;
        border: 0.1em solid #cccccc69;
        background-color: #f2f2f2;
        @media only screen and (max-width: 768px) {
          background-color: #fff;
          border: 0.1em solid transparent;
        }
      `;
    }
  }}
`;

export const StyledMobileSidebar = styled.div`
  background: ${({ customBg }) => customBg || '#fff'};
  height: 100%;
  width: 100%;
  position: fixed;
  margin-left: -100%;
  z-index: 11;
  top: 55px;
  left: 0px;
  padding-top: 0px;
  transition: all 0.5s ease 0s;
  overflow: auto;
  padding-bottom: 10px !important;
  ${({ isOpened }) => {
    if (isOpened) {
      return css`
        width: 100%;
        margin-left: 0;
      `;
    }
  }}
  h3 {
    margin-left: 15px;
    height: 46px;
    background-color: #fff;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    @media only screen and (max-width: 768px) {
      margin-left: 0px;
      padding-left: 15px;
      border-bottom: 1px solid #e5e5e5;
    }
    svg {
      margin-right: 20px;
    }
  }
  ${({ isOpened }) => {
    if (isOpened) {
      return css`
        width: 100%;
      `;
    }
  }}
`;
export const StyledUniverseLocation = styled.span`
  font-family: 'Gilroy', sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  max-width: 150px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 1.27;
  color: ${(props) => (props.isActive ? '#00000080' : '#00000059')};
  @media only screen and (max-width: 768px) {
    color: #00000080;
    margin-left: 3px;
    &:before {
      content: '- ';
    }
  }
`;

export const StyledSelectContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  height: 40px;
  @media only screen and (max-width: 991px) {
    position: absolute;
    width: 130px;
    right: 9px;
    display: flex;
    flex-direction: row;
    top: -25px;
    height: 30px;
    bottom: 100%;
    right: 25px;
  }
`;
