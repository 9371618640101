import { createAction } from 'redux-act';
import {
  transferBuying,
  leaguePlayers,
  transferBuyingAcceptCounter,
  transferBuyingRejectCounter,
  transferBuyingConfirmTransfer,
  transferBuyingDeclineTransfer,
  transferBuyingOfferPlayer,
  transferBuyingCancelTransfer,
  transferSelling,
  transferSellingAcceptOffer,
  transferSellingRejectOffer,
  transferSellingCounterOffer,
} from '../../../service/links';
import { sidebarGetDataForSideBar, sidebarOpenRightPanel } from '../Sidebar';
import {
  setScoutRequestForPlayer,
  sendSignFreeAgentRequest,
} from '../../../api/SearchTable';
import {
  getTransfersList,
  sendTransferRequest,
  sendTransferCancelRequest,
  sendTransferAcceptRequest,
  sendTransferRejectRequest,
  sendTransferLoanRequest,
} from '../../../api/transfers';

import { setErrorToPopup, isPopupLoading, hidePopupWindow } from '../Popups';
import { potentialFormatter } from '../../../service/utils';
import { SCOUTING, POPUPS } from '../../../service/constants';

const { TRANSFER_SEARCH_POPUP, FREE_AGENT_POPUP } = POPUPS;

export const showUnShowFilter = createAction('show/unshow transfers filter');
export const setUserWithOfferToBuy = createAction(
  'select user from search table'
);
export const setUserWithOffer = createAction('set user with offer');
export const deleteUserWithOfferToBuy = createAction(
  'delete user with offer to buy'
);

export const scoutCallLoading = createAction('scout request was sent');
export const editPlayerData = createAction('scouting request is successful');
export const loadScoutRequest = createAction('scout request loading');
export const declineScoutRequest = createAction('scout was disabled');
export const showScoutPopup = createAction('show scout popup visibility');
export const hideScoutPopup = createAction('hide scout popup visibility');

export const sendScoutRequest = (playerID) => async (dispatch) => {
  dispatch(scoutCallLoading(true));

  const response = await setScoutRequestForPlayer(playerID);
  dispatch(scoutCallLoading(false));
  if (response.status === 200) {
    dispatch(
      editPlayerData({
        playerID,
        data: { ongoing_scout: true, potential: SCOUTING },
      })
    );
    dispatch(sidebarGetDataForSideBar());
  } else {
    let error;
    if (response.status === 406) {
      error = {
        header: 'No scouts available.',
        text: 'We can only have 6 scouts out at the same time. Try again in a few hours.',
      };
    } else if (response.status === 405) {
      error = {
        header: 'Cannot complete your request',
        text: 'There is a scout already running for this player.',
      };
    } else if (response.status === 409) {
      error = {
        header: 'Not enough funds.',
        text: "We don't have sufficient funds to scout this player.",
      };
    } else {
      error = {
        header: 'There is some error.',
        text: 'Please try again later.',
      };
    }

    dispatch(setErrorToPopup('scoutPopup', error));
  }
};

export const showScoutPopupVisibility = (id) => async (dispatch) => {
  dispatch(showScoutPopup(id));
};
export const hideScoutPopupVisibility = () => async (dispatch) => {
  dispatch(hideScoutPopup());
};
export const transferShowUnshowFilter = (filter) => async (dispatch) => {
  dispatch(showUnShowFilter({ filter }));
};

export const transferSetUserWithOfferToBuy = (user) => async (dispatch) => {
  dispatch(setUserWithOfferToBuy({ user }));
};
export const transferSetUserWithOffer = (id) => async (dispatch) => {
  dispatch(setUserWithOffer(id));
};
export const transferDeleteUserWithOfferToBuy = () => async (dispatch) => {
  dispatch(deleteUserWithOfferToBuy());
};

// ---------OFFERS---------

export const transfersListLoading = createAction('Transfers list loading');
export const setTransfersList = createAction('Set transfers list data');
export const setDataForCardRequest = createAction(
  'Set offer card data for request'
);
export const transfersListRequest = () => async (dispatch) => {
  dispatch(transfersListLoading(true));

  const response = await getTransfersList();
  if (response.status === 200) {
    const data = await response.json();

    dispatch(setTransfersList({ ...data }));
    dispatch(transfersListLoading(false));
  }
};
export const setDataForCard =
  (id, playerName, transfer_fee_offer = 0, cardType) =>
    async (dispatch) => {
      dispatch(
        setDataForCardRequest(id, playerName, transfer_fee_offer, cardType)
      );
    };
// ------------------------------------BUY--------------------------

export const getDataToBuy = createAction('get transfers buy data');
export const getDataToBuyLoading = createAction(
  'set loading state for buy data'
);
export const selectPlayerToBuy = createAction('select player in transfers buy');
export const transferGetDataToBuy = () => async (dispatch) => {
  dispatch(getDataToBuyLoading());
  const response1 = await fetch(transferBuying, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });

  if (response1.status === 200) {
    let data = await response1.json();
    data = data.sort(function (a, b) {
      if (
        a.status === 2 ||
        a.status === 4 ||
        a.status === 6 ||
        a.status === 7
      ) {
        return -1;
      }
      if (
        a.status !== 2 ||
        a.status !== 4 ||
        a.status !== 7 ||
        a.status !== 7
      ) {
        return 1;
      }
      return 0;
    });
    dispatch(
      getDataToBuy({
        data,
      })
    );
  }
  // dispatch(getDataToBuy({ data: makeDataBuy(100) }));
};

/* export const transferSelectPlayerInBuy = (player) => async (dispatch) => {
  dispatch(setLoadingStateForSelectingPlayerInBuy());
  const response1 = await fetch(leaguePlayers + player.player._id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });

  if (response1.status === 200) {
    const data = await response1.json();
    dispatch(selectPlayerToBuy({ ...player, ...data }));
  }
};
 */
export const acceptOffer = createAction('accept buy offer');
export const transferAcceptOffer = (transferID) => async (dispatch) => {
  // /transfer/selling / acceptOffer
  const response1 = await fetch(transferBuyingAcceptCounter, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
    body: JSON.stringify({ transferID }),
  });
  if (response1.status === 200) {
    const message = 'Success operation!';
    window.location.reload();
    dispatch(acceptOffer({ message, transferID }));
  } else if (response1.status === 403) {
    const message = 'Not allowed!';
    dispatch(acceptOffer({ message, transferID }));
  } else if (response1.status === 405) {
    const message =
      'The transfer window is now closed. Try again when it opens at the end of the season.';
    dispatch(acceptOffer({ message, transferID }));
  }
  // dispatch(acceptOffer({ name }));
};

export const rejectOffer = createAction('reject buy offer');
export const transferRejectOffer =
  (transferID, message) => async (dispatch) => {
    // /transfer/selling/rejectOffer
    // fetch()
    const response1 = await fetch(transferBuyingRejectCounter, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ transferID, message }),
    });
    if (response1.status === 200) {
      const message = 'Success operation!';
      window.location.reload();

      dispatch(rejectOffer({ message, transferID }));
    } else if (response1.status === 403) {
      const message = 'Not allowed!';
      dispatch(rejectOffer({ message, transferID }));
    } else if (response1.status === 405) {
      const message =
        'The transfer window is now closed. Try again when it opens at the end of the season.';
      dispatch(rejectOffer({ message, transferID }));
    }
  };

export const confirmTransfer = createAction('confirm transfer');
export const transferConfirmAction =
  (transferID, cash, fee_offer) => async (dispatch) => {
    // if (cash >= fee_offer) {
    const response1 = await fetch(transferBuyingConfirmTransfer, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ transferID }),
    });
    if (response1.status === 200) {
      const message = 'Success operation!';
      window.location.reload();

      dispatch(confirmTransfer({ message, transferID }));
    } else if (response1.status === 403) {
      const message = 'Not allowed!';
      dispatch(confirmTransfer({ message, transferID }));
    } else if (response1.status === 405) {
      const message =
        'The transfer window is now closed. Try again when it opens at the end of the season.';
      dispatch(confirmTransfer({ message, transferID }));
    }
    // }
    // else{
    //   const message="You "
    //         dispatch(confirmTransfer({ message, transferID }));

    // }
  };
export const declineTransfer = createAction('decline transfer');
export const transferRequestForDeclineTransfer =
  (transferID) => async (dispatch) => {
    // /transfer/buying / declineTransfer
    // fetch()
    const response1 = await fetch(transferBuyingDeclineTransfer, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ transferID }),
    });
    if (response1.status === 200) {
      const message = 'Success operation!';
      window.location.reload();

      dispatch(declineTransfer({ message, transferID }));
    } else if (response1.status === 403) {
      const message = 'Not allowed!';
      dispatch(declineTransfer({ message, transferID }));
    } else if (response1.status === 405) {
      const message =
        'The transfer window is now closed. Try again when it opens at the end of the season.';
      dispatch(declineTransfer({ message, transferID }));
    }
  };

export const createNewSalaryOffer = createAction('create new salary offer');
export const transferCreateNewSalaryOffer =
  (transferID, offer) => async (dispatch) => {
    const response1 = await fetch(transferBuyingOfferPlayer, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ transferID, offer }),
    });
    if (response1.status === 200) {
      const message = 'Success operation!';
      window.location.reload();

      dispatch(createNewSalaryOffer({ message, transferID }));
    } else if (response1.status === 403) {
      const message = 'Not allowed!';
      dispatch(createNewSalaryOffer({ message, transferID }));
    } else if (response1.status === 404) {
      const message = 'Wrong data';
      dispatch(createNewSalaryOffer({ message, transferID }));
    } else if (response1.status === 405) {
      const message =
        'The transfer window is now closed. Try again when it opens at the end of the season.';
      dispatch(createNewSalaryOffer({ message, transferID }));
    }
    // dispatch(createNewSalaryOffer({ id, counter }));
  };

export const readMessageFromSellerInBuy = createAction(
  'read message from seller in buy'
);
export const requestInBuyTextMessageFromSeller = (name) => async (dispatch) => {
  const message = `${Math.random()}Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;
  dispatch(readMessageFromSellerInBuy({ message, name }));
  // setTimeout(() => {
  //   dispatch(readMessageFromSellerInBuy({ message, name }));
  // }, 5000);
};

export const cancelBuyTransfer = createAction('cancel transfer for buy');
export const transferRequestForCancelTransfer =
  (transferID) => async (dispatch) => {
    // /transfer/buying / declineTransfer
    // fetch()
    const response1 = await fetch(transferBuyingCancelTransfer, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ transferID }),
    });
    if (response1.status === 200) {
      const message = 'Success operation!';
      window.location.reload();
      dispatch(declineTransfer({ message, transferID }));
    } else if (response1.status === 403) {
      const message = 'Not allowed!';
      dispatch(declineTransfer({ message, transferID }));
    } else if (response1.status === 405) {
      const message =
        'The transfer window is now closed. Try again when it opens at the end of the season.';
      dispatch(declineTransfer({ message, transferID }));
    }
  };

// ---------------------------SELL--------------------------------

export const getDataToSell = createAction('get transfers sell data');
export const getDataToSellLoading = createAction(
  'set loading state for sell data'
);

export const transferGetDataToSell = () => async (dispatch) => {
  dispatch(getDataToSellLoading());
  const response1 = await fetch(transferSelling, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });

  if (response1.status === 200) {
    let data = await response1.json();
    // it's return you data, where the notification-card will be first
    data = data.sort(function (a, b) {
      if (a.status === 1) {
        return -1;
      }
      if (a.status !== 1) {
        return 1;
      }
      return 0;
    });

    dispatch(getDataToSell({ data }));
  }
};

// --------DEALS -------

export const selectPlayerToOffer = createAction(
  'select player in transfers deals'
);
export const selectingPlayerInOfferLoading = createAction(
  'set loading state for selecting state in deals'
);
export const transferSelectPlayerInOffer = (player) => async (dispatch) => {
  dispatch(selectingPlayerInOfferLoading());
  const response1 = await fetch(leaguePlayers + player.player._id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });

  if (response1.status === 200) {
    const data = await response1.json();
    // console.log({ ...player, ...data });
    dispatch(selectPlayerToOffer({ ...player, ...data }));
  }
};

export const sendTransferReject = (offerId) => async (dispatch) => {
  dispatch(isPopupLoading('rejectOfferPopup', true));
  const response = await sendTransferRejectRequest(offerId);
  if (response.status === 200) {
    dispatch(hidePopupWindow('rejectOfferPopup'));
    dispatch(transfersListRequest());
  } else {
    let error;
    if (response.status === 201) {
      error = {
        header: 'Reject failed',
        text: 'Not authroized to take action.',
      };
    } else if (response.status === 202) {
      error = {
        header: 'Request failed',
        text: 'Wrong offer status.',
      };
    } else if (response.status === 211) {
      error = {
        header: 'Request failed',
        text: 'Transfer period is closed',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(isPopupLoading('rejectOfferPopup', false));
    dispatch(setErrorToPopup('rejectOfferPopup', error));
  }
};

export const sendLoanRequest = (offerId, money, date) => async (dispatch) => {
  dispatch(isPopupLoading('loanPopup', true));
  const response = await sendTransferLoanRequest(offerId, money, date);
  if (response.status === 200) {
    dispatch(transfersListRequest());
  } else {
    let error;
    if (response.status === 201) {
      error = {
        header: 'Loan request failed',
        text: 'Player does not exist',
      };
    } else if (response.status === 202) {
      error = {
        header: 'Request failed',
        text: 'Player is already in that team.',
      };
    } else if (response.status === 203) {
      error = {
        header: 'Request failed',
        text: 'Player is loaned.',
      };
    } else if (response.status === 204) {
      error = {
        header: 'Request failed',
        text: 'Team does not have cash.',
      };
    } else if (response.status === 205) {
      error = {
        header: 'Request failed',
        text: 'There is an offer already for this player.',
      };
    } else if (response.status === 210) {
      error = {
        header: 'Request failed',
        text: 'Loan is expired.',
      };
    } else if (response.status === 211) {
      error = {
        header: 'Request failed',
        text: 'Transfer period is closed.',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup('loanPopup', error));
  }
  dispatch(isPopupLoading('loanPopup', false));
};
export const sendTransferConfirm = (offerId) => async (dispatch) => {
  dispatch(isPopupLoading('acceptOfferPopup', true));
  const response = await sendTransferAcceptRequest(offerId);
  if (response.status === 200) {
    dispatch(transfersListRequest());
    dispatch(hidePopupWindow('acceptOfferPopup'));
  } else {
    let error;
    if (response.status === 201) {
      error = {
        header: 'Error',
        text: 'Player does not exist.',
      };
    } else if (response.status === 202) {
      error = {
        header: 'Request failed',
        text: 'Player is already in that team',
      };
    } else if (response.status === 203) {
      error = {
        header: 'Request failed',
        text: 'Player is loaned',
      };
    } else if (response.status === 204) {
      error = {
        header: 'Request failed',
        text: 'Team does not have cash',
      };
    } else if (response.status === 205) {
      error = {
        header: 'Request failed',
        text: 'There is an offer already for this player',
      };
    } else if (response.status === 206) {
      error = {
        header: 'Request failed',
        text: 'Player is no longer in team',
      };
    } else if (response.status === 207) {
      error = {
        header: 'Request failed',
        text: 'Not authroized to take action',
      };
    } else if (response.status === 208) {
      error = {
        header: 'Request failed',
        text: 'Wrong offer status',
      };
    } else if (response.status === 209) {
      error = {
        header: 'Request failed',
        text: 'The buyer does not have enough room on their roster to complete this deal. They will submit a new offer once their house is in order.',
      };
    } else if (response.status === 210) {
      error = {
        header: 'Request failed',
        text: 'Transfer period is closed',
      };
    } else if (response.status === 211) {
      error = {
        header: 'Request failed',
        text: 'Transfer period is closed',
      };
    } else if (response.status === 212) {
      error = {
        header: 'Cannot transfer player',
        text: 'Either your team or the other team is currently playing a match.',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup('acceptOfferPopup', error));
    dispatch(isPopupLoading('acceptOfferPopup', false));
  }
};
export const sendTransferCancel = (offerId) => async (dispatch) => {
  dispatch(isPopupLoading('cancelOfferPopup', true));
  const response = await sendTransferCancelRequest(offerId);
  if (response.status === 200) {
    dispatch(transfersListRequest());
    dispatch(hidePopupWindow('cancelOfferPopup'));
    dispatch(sidebarOpenRightPanel(false));
  } else {
    let error;

    if (response.status === 201) {
      error = {
        header: 'Need to log in.',
        text: 'You are not authorized to complete this action.',
      };
    } else if (response.status === 202) {
      error = {
        header: 'This request cannot be completed',
        text: 'Wrong offer status',
      };
    } else if (response.status === 203) {
      error = {
        header: 'This request cannot be completed',
        text: 'Loan date expired',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup('cancelOfferPopup', error));
    dispatch(isPopupLoading('cancelOfferPopup', false));
  }
};

// TODO player to sell is deprecated flow, remove

export const selectPlayerToSell = createAction(
  'select player in transfers sell'
);
export const setLoadingStateForSelectingPlayerInSell = createAction(
  'set loading state for selecting state in sell'
);
export const transferSelectPlayerToSell = (player) => async (dispatch) => {
  dispatch(setLoadingStateForSelectingPlayerInSell());
  const response1 = await fetch(leaguePlayers + player.player._id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });

  if (response1.status === 200) {
    const data = await response1.json();
    // console.log({ ...player, ...data });
    dispatch(selectPlayerToSell({ ...player, ...data }));
  }
};

export const acceptOfferInSell = createAction('accept offer in sell');
export const changeCashAmountOfOfferInSell = createAction(
  "change amount of cash in sell's offer"
);
export const declineOfferInSell = createAction('decline offer in sell');
export const transferAcceptOfferInSell = (transferID) => async (dispatch) => {
  // dispatch(acceptOfferInSell({ name }));
  const response1 = await fetch(transferSellingAcceptOffer, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
    body: JSON.stringify({ transferID }),
  });
  if (response1.status === 200) {
    const message = 'Success operation!';
    window.location.reload();

    dispatch(acceptOfferInSell({ message, transferID }));
  } else if (response1.status === 403) {
    const message = 'Not allowed!';
    dispatch(acceptOfferInSell({ message, transferID }));
  } else if (response1.status === 405) {
    const message =
      'The transfer window is now closed. Try again when it opens at the end of the season.';
    dispatch(acceptOfferInSell({ message, transferID }));
  }
};
export const transferChangeCashAmountOfOfferInSell =
  (transferID, counterOffer) => async (dispatch) => {
    const response1 = await fetch(transferSellingCounterOffer, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ transferID, counterOffer }),
    });
    if (response1.status === 200) {
      const message = 'Success operation!';
      window.location.reload();

      dispatch(
        changeCashAmountOfOfferInSell({ message, transferID, counterOffer })
      );
    } else if (response1.status === 403) {
      const message = 'Not allowed!';
      dispatch(
        changeCashAmountOfOfferInSell({ message, transferID, counterOffer })
      );
    } else if (response1.status === 405) {
      const message =
        'The transfer window is now closed. Try again when it opens at the end of the season.';
      dispatch(
        changeCashAmountOfOfferInSell({ message, transferID, counterOffer })
      );
    }
  };
export const transferDeclineOfferInSell =
  (transferID, message) => async (dispatch) => {
    const response1 = await fetch(transferSellingRejectOffer, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ transferID, message }),
    });
    if (response1.status === 200) {
      const messageFromServer = 'Success operation!';
      window.location.reload();
      dispatch(declineOfferInSell({ messageFromServer, transferID, message }));
    } else if (response1.status === 403) {
      const messageFromServer = 'Not allowed!';
      dispatch(declineOfferInSell({ messageFromServer, transferID, message }));
    } else if (response1.status === 405) {
      const messageFromServer =
        'The transfer window is now closed. Try again when it opens at the end of the season.';
      dispatch(declineOfferInSell({ messageFromServer, transferID, message }));
    }
  };
export const cancelSellTransfer = createAction('cancel transfer for sell');

// --------------------PLAYER---------------------------------

export const getDataToSearchPlayer = createAction(
  'get data for searchable players to transfers'
);
export const getDataToSearchPlayerLoading = createAction(
  'load data for searchable players to transfers'
);
export const transferGetDataToSearchable = () => async (dispatch) => {
  dispatch(getDataToSearchPlayerLoading());
  const response = await fetch(leaguePlayers, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });
  if (response.status === 200) {
    const {
      players,
      team,
      scoutingAllowed,
      league,
      endOfSeasonDate,
      transferWindow,
    } = await response.json();
    const data = players.map((player) => ({
      ...player,
      myTeam: player.team && player.team._id === team,
      potential: potentialFormatter(player),
      favZone: player.fav_zone || 'None',
    }));
    dispatch(
      getDataToSearchPlayer({
        data,
        scoutingAllowed,
        league,
        endOfSeasonDate,
        transferWindow,
      })
    );
  }
};
export const setCreateNewOfferSearch = createAction('create new offer');
export const setCreateNewOfferSearchLoading = createAction(
  'creating new offer loading'
);
// TODO rewrite with current error approach and add all statuses
export const transferSetCreateNewOfferSearch =
  (playerID, offer) => async (dispatch) => {
    dispatch(isPopupLoading(TRANSFER_SEARCH_POPUP, true));
    dispatch(setCreateNewOfferSearchLoading());
    const response = await sendTransferRequest(playerID, offer);
    if (response.status === 200) {
      dispatch(setCreateNewOfferSearch({ playerID }));
    } else {
      let error;
      if (response.status === 203) {
        error = {
          header: 'Request failed',
          text: 'Player is currently on a loan.',
        };
      } else if (response.status === 204) {
        error = {
          header: 'Something went wrong',
          text: 'Check that you have enough cash',
        };
      } else if (response.status === 210) {
        error = {
          header: 'Request failed',
          text: 'The transfer window is now closed. Try again when it opens at the end of the season.',
        };
      } else if (response.status === 211) {
        error = {
          header: 'Request failed',
          text: 'The transfer window is currently closed. It will re-open after the league season ends.',
        };
      } else if (response.status === 205) {
        error = {
          header: 'Request failed',
          text: 'You already have an outstanding offer on this player',
        };
      } else if (response.status === 208) {
        error = {
          header: 'Request failed',
          text: 'Given your recent start in The Open League, the anti-fraud agency blocked your offer attempt because it is OVER or UNDER the recommended value range for this player. This restriction will be lifted after you have been playing TOL for at least seven days. You can still make an offer within the recommended value range.',
        };
      } else {
        error = {
          header: 'There is some server error.',
          text: 'Please try again later.',
        };
      }
      dispatch(setErrorToPopup(TRANSFER_SEARCH_POPUP, error));
    }
    dispatch(isPopupLoading(TRANSFER_SEARCH_POPUP, false));
  };

// --------------------- FREE AGENT -----------------------------------------

export const freeAgentCallLoading = createAction('call Free agent');
export const allowFreeAgentPopupRequest = createAction(
  'Free agent request allowed'
);

export const removePlayer = createAction('Remove player from transfer');

export const removeSelectedPlayer = (playerID) => async (dispatch) => {
  dispatch(removePlayer(playerID));
};

export const teamCallFreeAgent = (playerID) => async (dispatch) => {
  dispatch(freeAgentCallLoading(true));
  const response = await sendSignFreeAgentRequest(playerID);
  dispatch(freeAgentCallLoading(false));
  if (response.status === 200) {
    dispatch(sidebarGetDataForSideBar());
    dispatch(sidebarOpenRightPanel(false));
  } else {
    let error;
    if (response.status === 410) {
      error = {
        header: 'Full Roster',
        text: 'Your roster is full. Please make some room and try again.',
      };
    } else if (response.status === 411) {
      error = {
        header: 'Free agent limit.',
        text: 'You can only sign 10 free agents per days.',
      };
    } else if (response.status === 420) {
      error = {
        header: 'Free agent limit.',
        text: 'You can’t sign any additional free agents today. Try again tomorrow.',
      };
    } else {
      error = {
        header: 'There is some error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup(FREE_AGENT_POPUP, error));
  }
};
