import { createAction } from 'redux-act';
import {
  leagueTeams,
  leagueTeamsId,
  leagueMatchId,
} from '../../../service/links';
import {
  leaguesListRequest,
  getLeagueTeamsList,
  getPlayerInfo,
  leagueFixturesRequest,
  fixturesFromLeagueRequest,
} from '../../../api/league';
import { setErrorToPopup, isPopupLoading } from '../Popups';
import { setSeasonNumber } from '../Team';

import { POPUPS } from '../../../service/constants';

const { TEAM_INFO_POPUP } = POPUPS;

export const setTeam = createAction('set information about team');

export const removeTeam = createAction('remove information about team');

export const leagueInitialDataLoaded = createAction('league team info loaded');

export const leagueSetTeamInfo =
  (data, noPopup = false) =>
    async (dispatch) => {
      dispatch(leagueInitialDataLoaded());

      const response1 = await fetch(leagueTeamsId + data._id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        credentials: 'include',
      });
      if (response1.status === 200) {
        const team = await response1.json();
        dispatch(
          setTeam({
            team: { ...data, ...team },
          })
        );
        if (!noPopup) dispatch(isPopupLoading(TEAM_INFO_POPUP, false));
      }
    };

export const leagueRemoveTeamInfo = (team) => async (dispatch) => {
  dispatch(removeTeam({ team }));
};

// ---------------------LEAGUE TABLE--------------------------------------
export const leagueTableDataLoaded = createAction(
  'league table data loaded from backend'
);
export const getLeagueTable = createAction('get league table from backend');

// ---------------------CALENDAR--------------------------------
export const createCalendar = createAction('get new data to calendar');
export const loadDataAboutCalendar = createAction('load data about calendar');

export const leagueCreateCalendar = () => async (dispatch) => {
  dispatch(loadDataAboutCalendar());
  const response = await leagueFixturesRequest();
  if (response.status === 200) {
    const data = await response.json();
    const { currentDate, matches, timezone } = data;
    dispatch(createCalendar({ currentDate, matches, timezone }));
  } else {
    console.log('ERROR', response.status);
  }
};

export const getCalendarFromLeague = (id) => async (dispatch) => {
  dispatch(loadDataAboutCalendar());
  const response = await fixturesFromLeagueRequest(id);
  if (response.status === 200) {
    const { currentDate, matches, timezone } = await response.json();
    dispatch(createCalendar({ currentDate, matches, timezone }));
  } else {
    console.log('ERROR', response.status);
  }
};

export const selectTeamMatch = createAction('get data about selected match');
export const selectTeamMatchLoading = createAction(
  'load data about match by id'
);

export const leagueSelectTeamMatch = (id) => async (dispatch) => {
  // dispatch(selectTeamMatch({ match, type }));
  dispatch(selectTeamMatchLoading());
  const response = await fetch(leagueMatchId + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });
  if (response.status === 200) {
    const data = await response.json();
    dispatch(selectTeamMatch({ data }));
  }
};

export const setLeaguesList = createAction('Set leagues list');

export const getLeaguesList = () => async (dispatch) => {
  const response = await leaguesListRequest();
  if (response.status === 200) {
    const data = await response.json();
    dispatch(setLeaguesList({ data }));
  }
};

export const setTeamsList = createAction('Set teams list from league');
export const setTeamListLoading = createAction('Loading teams list');

export const getTeamsFromLeague = (id) => async (dispatch) => {
  dispatch(setTeamListLoading(true));
  const response = await getLeagueTeamsList(id);
  if (response.status === 200) {
    const { teams } = await response.json();
    dispatch(setTeamsList({ teams }));
  }
  dispatch(setTeamListLoading(false));
};

export const setPlayerInfo = createAction('Set player info');
export const getPlayerInformation = (id, popupName) => async (dispatch) => {
  dispatch(isPopupLoading(popupName, true));
  const response = await getPlayerInfo(id);
  if (response.status === 200) {
    const data = await response.json();
    dispatch(setPlayerInfo({ ...data.players, contract: data.contract }));
    dispatch(setSeasonNumber(data.seasonNumber));
  } else {
    let error;
    if (response.status === 406) {
      error = {
        header: 'Request failed',
        text: 'User is not logged in',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup(popupName, error));
    dispatch(isPopupLoading(popupName, false));
  }
  dispatch(isPopupLoading(popupName, false));
};

export const leagueGetLeagueTableData = () => async (dispatch) => {
  dispatch(leagueTableDataLoaded());
  const response = await fetch(leagueTeams, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });
  if (response.status === 200) {
    const { teams, teamData, league } = await response.json();
    dispatch(getLeagueTable({ teams, teamData, league }));
    dispatch(getTeamsFromLeague(league));
    dispatch(getCalendarFromLeague(league));
  }
};
