import React from 'react';
import { useDispatch } from 'react-redux';
import { Range } from 'react-range';

import {
  StyledTacticContainer,
  StyledManipulator,
  StyledTrack,
  StyledProgress,
  StyledLabels,
  StyledRangeWrapper,
  StyledInfoDark,
} from './styled';
import { InfoDarkRelative, StyledReactTooltip } from '../../globalStyled';

import { setMindsetData, setEffortData } from '../../redux/actions/Team';

const SelectTactic = ({ mindset, effort, mobile = false }) => {
  const STEP = 1;
  const MIN = 0;
  const MAX = 2;
  const dispatch = useDispatch();
  const setMindsetStep = (value) => {
    dispatch(setMindsetData(...value));
  };
  const setEffortStep = (value) => {
    dispatch(setEffortData(...value));
  };
  return (
    <StyledTacticContainer>
      {!mobile && (
        <h3>
          Tactic
          <InfoDarkRelative
            iconsize="10"
            data-tip
            clickable="true"
            data-for="tactic-tooltip"
          />
          <StyledReactTooltip
            id="tactic-tooltip"
            type="light"
            effect="solid"
            place="bottom"
          >
            TACTIC allows to change how your team behaves during the match.
          </StyledReactTooltip>
        </h3>
      )}
      <StyledRangeWrapper>
        <h4>
          <span>MINDSET</span>
          <StyledReactTooltip
            id="mindset"
            type="light"
            effect="solid"
            place="bottom"
          >
            An attacking team will put more efforts toward scoring goals and
            less toward defending.
          </StyledReactTooltip>
          <StyledInfoDark iconsize="11" data-tip clickable data-for="mindset" />
        </h4>
        <Range
          values={[mindset]}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => setMindsetStep(values)}
          renderTrack={({ props, children }) => (
            <StyledTrack
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
            >
              <StyledProgress
                values={[mindset]}
                colors={['#383838', '#ccc']}
                min={MIN}
                max={MAX}
                ref={props.ref}
              >
                {children}
              </StyledProgress>
            </StyledTrack>
          )}
          renderThumb={({ props, isDragged }) => (
            <StyledManipulator {...props} />
          )}
        />
        <StyledLabels>
          <span>Defend</span>
          <span>Neutral</span>
          <span>Attack</span>
        </StyledLabels>
      </StyledRangeWrapper>

      <StyledRangeWrapper>
        <h4>
          <span>EFFORT</span>
          <StyledReactTooltip
            id="effort"
            type="light"
            effect="solid"
            place="bottom"
          >
            No secrets here. Teams who put in the extra effort are more likely
            to win. But they will also fatigue faster and be more likely to get
            injured.
          </StyledReactTooltip>
          <StyledInfoDark iconsize="11" data-tip clickable data-for="effort" />
        </h4>
        <Range
          values={[effort]}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => setEffortStep(values)}
          renderTrack={({ props, children }) => (
            <StyledTrack
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
            >
              <StyledProgress
                values={[effort]}
                colors={['#383838', '#ccc']}
                min={MIN}
                max={MAX}
                ref={props.ref}
              >
                {children}
              </StyledProgress>
            </StyledTrack>
          )}
          renderThumb={({ props, isDragged }) => (
            <StyledManipulator {...props} />
          )}
        />
        <StyledLabels>
          <span>Medium</span>
          <span>High</span>
          <span>Extreme</span>
        </StyledLabels>
      </StyledRangeWrapper>
    </StyledTacticContainer>
  );
};

export default SelectTactic;
