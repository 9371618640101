import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { InfoDark } from '../../globalStyled';

export const StyledLinksContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const StyledNavContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledNavLink = styled(NavLink)`
  padding: 1px 37px;
  background: #fff;
  text-decoration: none;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 0;
  transition: 0.3s;
  min-width: 180px;

  justify-content: center;
  height: 35px;
  text-align: center;
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1100px) {
    padding: 1px 5px;
    min-width: 50px;
  }

  &:first-of-type {
  }
  &:last-of-type {
  }
  &.active-button {
    position: relative;
    color: #0e4afb;
    &:before {
      content: '';
      width: 100%;
      bottom: 0;
      border-bottom: 2px solid #0e4afb;
      position: absolute;
    }
  }
  ${({ disabled }) => {
    if (disabled) {
      return css`
        pointer-events: none;

        color: #989898;
        svg {
          pointer-events: all;
        }
      `;
    }
  }}
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledInfoDark = styled(InfoDark)`
  position: absolute;
  right: 35px;
  top: 14px;
  @media only screen and (max-width: 1100px) {
    right: -10px;
  }
`;
export const StyledTabsInfo = styled(InfoDark)`
  position: relative;
  margin-left: 10px;
`;
