import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as DiscordLogo } from '../../assets/img/discord-logo.svg';

export const DiscordLogoIcon = styled(DiscordLogo)`
  width: 90px;
  cursor: pointer;
`;

export const StyledNavLinks = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex !important;
  align-items: center;
  text-decoration: none;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12.642px;
  line-height: 100%;
  letter-spacing: 0.02em;
  display: block;
  transition: 0.3s;
  margin: 0;
  padding: 14px 21px;
  user-select: none;

  @media only screen and (max-width: 1199px) {
    margin: 0;
    padding: 13px 21px;
    flex-direction: row;
    justify-content: flex-start;
  }
  &.blocked {
    pointer-events: none;
    opacity: 0.3;
  }
  /*   &.selected {
    background: #383838;
    pointer-events: none;
    color: #ffffff;
    span {
      color: #ffffff;
      opacity: 1;
    }
    // TODO fix icon later
    #cup_icon {
      path {
        fill: #fff;
      }
    }
  } */
`;

export const StyledTextNavLink = styled(StyledNavLinks)`
  padding: 5px;
  text-align: center;
  cursor: pointer;
  color: #333333;
  font-size: 14px;
  padding: 15px;
  @media only screen and (max-width: 1199px) {
    color: #b2b2b2;
  }
`;
export const StyledAvatarContainer = styled.span`
  min-width: 40px;
  padding: 10px 12px;
  line-height: 10px;
  margin-right: 10px;
`;

export const StyledName = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
`;

export const StyledDropdownList = styled.ul`
  list-style: none;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    border-top: 1px solid #e5e5e5;
  }
`;

export const StyledAvatar = styled.img`
  max-width: 45px;
  border-radius: 50%;
`;

export const StyledTitle = styled.li`
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledItem = styled.li`
  cursor: pointer;
  color: ${({ isAuthenticated }) => (isAuthenticated ? '#333333' : '#999')};
  font-size: 14px;
  padding: 15px;
  ${({ isDefaultStyles }) => {
    if (isDefaultStyles) {
      return css`
        color: #333;
      `;
    }
  }}

  @media only screen and (max-width: 1199px) {
    color: #b2b2b2;
  }
  &:hover {
    background-color: ${({ isAuthenticated }) =>
      !isAuthenticated ? '#fff' : '#f6f6f6'};
  }
`;

export const StyledFooter = styled.li`
  cursor: pointer;
  border-top: 1px solid #e5e5e5;
  padding: 15px;
  font-size: 14px;
  color: ${({ isAuthenticated }) => (isAuthenticated ? '#e94235' : '#999')};
  &:hover {
    background-color: ${({ isAuthenticated }) =>
      !isAuthenticated ? '#fff' : '#f6f6f6'};
  }
`;

export const StyledLightGray = styled.div`
  position: relative;
  height: 60px;
  width: 170px;
  text-align: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 170px;
    height: 60px;
    transform: skew(-25deg, 0);
    background: #fdfdfd;
    z-index: 1;
    @media only screen and (max-width: 1199px) {
      height: 50px;
      width: 100%;
    }
  }
`;

export const StyledDarkGray = styled.div`
  position: relative;
  height: 60px;
  width: 170px;
  text-align: center;
  margin-right: 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 170px;
    height: 60px;
    transform: skew(-25deg, 0);
    background: #f6f6f6;

    z-index: 1;
    @media only screen and (max-width: 1199px) {
      height: 50px;
      width: 100%;
    }
  }
`;
