import { createAction } from 'redux-act';
import { getSidebarData } from '../../../api/sidebar';

export const openRightPanel = createAction('open right panel of sidebar');
export const showHeaderPanel = createAction('open header of sidebar');
export const openSideNavigation = createAction(
  'open side navigation panel of sidebar'
);

export const sidebarOpenRightPanel = (view) => async (dispatch) => {
  dispatch(openRightPanel({ view }));
};
export const headerShowPanel = (view) => async (dispatch) => {
  dispatch(showHeaderPanel({ view }));
};
export const sidebarOpenSideNavigation = (view) => async (dispatch) => {
  dispatch(openSideNavigation({ view }));
};
// ----------------------------GET DATA FOR SIDEBAR--------------------------

export const getDataForSidebar = createAction(
  'get data for sidebar from backend'
);
export const dataForSidebarLoaded = createAction('set loading for sidebar');

export const sidebarGetDataForSideBar = () => async (dispatch) => {
  // https://cleats.herokuapp.com/home/bar

  const response1 = await getSidebarData();

  if (response1.status === 200) {
    let {
      ranking,
      cash_summary: { current_cash },
      user_id
    } = await response1.json();
    current_cash = Math.round(current_cash);
    dispatch(
      getDataForSidebar({
        ranking,
        current_cash,
        user_id
      })
    );
  }
};
