import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledTeamSquadContainer = styled.section`
  max-height: 650px;
  overflow-y: scroll;
  width: 100%;
  > h3 {
    text-align: center;
    margin-bottom: 22px;
  }
  @media only screen and (max-width: 1199px) {
    margin-bottom: 40px;
  }
`;
export const StyledMatch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 15px;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
  img {
    max-width: 25px;
    height: auto;
    width: 100%;
    max-height: 25px;
  }
  &[data-type='transfers'] {
    span {
      display: block;
      flex: none;
    }
  }
`;

export const StyledCard = styled.div`
  max-height: 400px;
  padding: 0;
  background: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(110, 110, 110, 0.15);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin: 20px 30px;
  @media only screen and (max-width: 991px) {
    margin: 20px 15px;
  }
  h4 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.222px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #000000;
  }
  p {
    margin: 14px 0;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.222px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #949494;
  }
`;

export const StyledToggleView = styled.div`
  display: flex;
  position: relative;
  width: 350px;
  flex-direction: row;
  padding: 3px 4px;
  margin-top: 0px;
  border-radius: 28px;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  margin: 0 auto;
`;
export const StyledToggleButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  flex: 1;
  color: rgba(0, 0, 0, 0.4);
  border: 0px;
  outline: 0px;
  padding: 6px;
  border-radius: 14px;
  cursor: pointer;
  /*     box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06); */
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;

  &:first-child {
    margin-right: 11px;
  }
  ${({ isActive }) => {
    if (isActive) {
      return css`
        color: #fff;
        background-color: #000;
      `;
    }
  }}
`;

export const StyledContext = styled.div`
  padding: 0 30px 20px 30px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  h4 {
    font-weight: bold;
    font-size: 15px;
    color: #333;
  }
  p {
    font-size: 13px;
    line-height: 22px;
    opacity: 0.9;
  }
  //max-height: 66px;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f4f4;
  height: 130px;
  z-index: 1;
  position: sticky;
  top: 0;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    height: 170px;
    padding-top: 10px;
  }
`;
export const StyledImage = styled.div`
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 25px;
  @media only screen and (max-width: 991px) {
    width: 70px;
    height: 70px;
  }
  img {
    width: 60px;
    height: 60px;
  }
`;
export const StyledTeamInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledTeamName = styled.h3`
  font-family: 'Gilroy', sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.14;
  margin-bottom: 15px;
  @media only screen and (max-width: 991px) {
    text-align: center;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
export const StyledLeagueName = styled.span`
  color: #666;
  font-size: 13px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  @media only screen and (max-width: 991px) {
    text-align: center;
  }
`;

export const StyledWinnerSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
`;
export const StyledManagerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
  @media only screen and (max-width: 991px) {
    text-align: center;
  }

  h4 {
    color: #333;
    font-size: 12px;
    opacity: 0.5;
  }
`;
export const StyledManagerName = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  text-decoration: ${({ isClickable }) => (isClickable ? 'underline' : 'none')};
`;
export const StyledWinnerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
`;
export const StyledWinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-left: 1px solid #cccccc;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
export const StyledWinnerSeasonNumber = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-size: 12px;
  font-weight: 500;
`;
export const StyledWinnerLabel = styled.span`
  margin-top: 5px;
  width: 20px;
  height: 20px;
  background-color: #fdca40;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: 'Gilroy', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
`;

export const StyledTitle = styled.span`
  font-size: 12px;
  color: #000;
`;

export const StyledMatchName = styled.div`
  display: flex;
  flex-direction: row;
  width: 215px;
  align-items: center;
  justify-content: ${({ left }) => (left ? 'flex-start' : 'flex-end')};
  ${StyledTitle} {
    padding: ${({ left }) => (left ? '0 0 0 15px' : '0 15px 0 0 ')};
    @media only screen and (max-width: 991px) {
      max-width: 110px;
      text-align: ${({ left }) => (left ? 'left' : 'right')};
      padding: ${({ left }) => (left ? '0 0 0 10px' : '0 10px 0 0 ')};
    }
  }
`;

export const StyledScore = styled.div`
  display: flex;
  flex-direction: row;
  width: 35px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  @media only screen and (max-width: 991px) {
    width: 65px;
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  text-align: center;
  border: solid 0.5px #e1e1e1;
  min-width: 75px;
  &.__react_component_tooltip.show {
    background-color: #000;
    font-size: 11px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledTransaction = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 15px 0 10px;
  height: 40px;
  &[data-type='revenue'] {
    span {
      color: #219653;
      &:before {
        margin-right: 1px;
        content: '+';
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 26px;
        color: #219653;
      }
    }
  }
  &[data-type='expenses'] {
    span {
      color: #eb5757;
      &:before {
        margin-right: 7px;

        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 26px;
        color: #eb5757;
      }
    }
  }
  h4 {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #000000;
  }
  span {
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #000000;
  }
  h6 {
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #bdbdbd;
    span {
      font-family: 'Gilroy', sans-serif;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: -0.03em;
      color: #bdbdbd;
    }
  }
`;
