import React from 'react';
import ContentLoader from 'react-content-loader';

const PlayingPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={658}
    height={255}
    viewBox="0 0 658 255"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="42" y="47" rx="0" ry="0" width="97" height="20" />
    <rect x="40" y="87" rx="0" ry="0" width="373" height="49" />
    <rect x="42" y="161" rx="0" ry="0" width="229" height="20" />
  </ContentLoader>
);

export default PlayingPreloader;
