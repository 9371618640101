import React from 'react';
import { StyledWindow, StyledForm, StyedGroupDiv } from './styled';

export default function Communication() {
  return (
    <StyledWindow>
      <h2>Account details</h2>
      <StyledForm>
        <StyedGroupDiv>
          <label>First Name</label>
          <input placeholder="..." className="w3-input" type="text" />
        </StyedGroupDiv>
        <StyedGroupDiv>
          <label>Last Name</label>
          <input placeholder="..." className="w3-input" type="text" />
        </StyedGroupDiv>
        <StyedGroupDiv>
          <label>Email</label>
          <input placeholder="..." className="w3-input" type="email" />
        </StyedGroupDiv>
        <StyedGroupDiv>
          <label>Email</label>
          <input placeholder="..." className="w3-input" type="email" />
        </StyedGroupDiv>
        <StyedGroupDiv>
          <label>Country</label>
          <input placeholder="..." className="w3-input" type="text" />
        </StyedGroupDiv>
      </StyledForm>
    </StyledWindow>
  );
}
