import styled, { css } from 'styled-components';
import Select from 'react-select';
import { ReactComponent as Sorted } from '../../../../../assets/img/sort-arrows.svg';

export const StyledNameContainer = styled.div`
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledManipulator = styled.div`
  margin-bottom: 27px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > h3 {
    font-family: 'Gilroy', sans-serif;
    font-size: 34px;
    line-height: 41px;
    font-weight: 900;
    letter-spacing: 0.005em;
    color: #333333;
  }
`;
export const StyledSelectContainer = styled.div`
  width: 100%;
  margin: 5px;
  > input {
    border: none;
  }
  @media screen and (max-width: 1204px) {
    flex: none;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    max-width: inherit;
  }
`;

export const StyledSelect = styled(Select)`
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }
`;

export const StyledTableContainer = styled.section`
  width: 100%;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  @media screen and (max-width: 768px) {
    [type='Age'],
    [type='Actions'],
    [type='Zone'],
    [type='League'],
    [type='Team'],
    [type='Status'] {
      display: none;
    }
    th[type='Actions'] {
      border-top-right-radius: 9px;
    }
  }
  @media screen and (max-width: 991px) {
    [type='League'],
    [type='Team'] {
      display: none;
    }
  }
`;

export const StyledThead = styled.thead`
  ${({ display }) => {
    if (display === 'none')
      return css`
        display: none;
      `;
  }}
`;

export const StyledTbody = styled.tbody`
  /* border-top: 12px solid #e5e5e5; */
  &::before {
    content: '';
    display: block;
    height: 0px;
  }
`;

export const StyledTrTh = styled.tr``;

export const StyledTh = styled.th`
  text-align: left;
  padding: 9px 0 9px 15px;
  background: #fff;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 1px;
  color: #000;
  border-bottom: 1px solid #eee;
  @media screen and (max-width: 425px) {
    font-size: 11px;
    padding: 9px 0 9px 5px;
  }
  &:first-of-type {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 0px;
  }
  &:last-of-type {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 0px;
  }
  ${({ display, titleNumberName, type }) => {
    if (titleNumberName)
      return css`
        display: none;
      `;
    if (display === 'none')
      return css`
        display: none;
      `;
    if (type === 'Number') {
      return css`
        min-width: 50px;
      `;
    }
    if (type === 'Name') {
      return css`
        min-width: 105px;
      `;
    }
    if (type === 'For sale' || type === 'Scouting') {
      return css`
        display: none;
        font-size: 0;
        span {
          display: none;
        }
      `;
    }
    if (type === 'Actions') {
      return css`
        span {
          display: none;
        }
      `;
    }
  }}
`;
export const StyledSorted = styled(Sorted)`
  margin-bottom: -5px;
  margin-left: 5px;
  width: 10px;
  height: 20px;
  fill: #7f7f7f;
  ${({ type }) => {
    if (type === 'fromMinToMax') {
      return css`
        path:first-of-type {
          display: none;
        }
      `;
    }
    if (type === 'fromMaxToMin') {
      return css`
        path:last-of-type {
          display: none;
        }
      `;
    }
  }}
`;

export const StyledTrTd = styled.tr`
  border-bottom: 1px solid #eee;
  background: white;
  &:last-of-type {
    border-bottom: 0px solid #bdbdbd;
    td {
      &:first-of-type {
        border-bottom-left-radius: 5px;
      }
      &:last-of-type {
        border-bottom-right-radius: 5px;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:nth-child(even) {
    background: #fafafa;
  }
  &:nth-child(odd),
  &:nth-child(even) {
    &:hover,
    &.clicked {
      cursor: pointer;
      background: #0e4afb;
      td {
        color: #ffffff !important;
      }
      button {
        background: #fff;
        color: #000 !important;
        &:hover {
          color: #000 !important;
        }
      }
    }
  }
  ${({ clicked }) => css`
    &.row-number-${clicked} {
      background: #0e4afb;
      td {
        color: #ffffff;
      }
      @media screen and (max-width: 768px) {
        background: #fff;
        td {
          color: #000;
        }
      }
      button {
        background: #fff;
        color: #000 !important;
        &:hover {
          color: #000 !important;
        }
      }

      .scouting {
        color: #ff4d09 !important;
      }
    }
  `}
`;

export const StyledTd = styled.td`
  height: 40px;
  text-align: left;
  padding-left: 15px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  @media screen and (max-width: 425px) {
    font-size: 12px;
    padding-left: 5px;
  }
  ${({ titleCell, type }) => {
    if (titleCell)
      return css`
        text-align: left;
      `;
    if (type === 'PTS') {
      return css`
        span {
          background: rgba(14, 74, 251, 0.1);
          border: 1px solid rgba(187, 199, 234, 0.45);
          border-radius: 5px;
          padding: 0 11px;
        }
      `;
    }
    if (type === 'Name') {
      return css`
        @media only screen and (max-width: 768px) {
          padding-left: 5px;
          width: 120px;
        }
      `;
    }
    if (type === 'NO') {
      return css`
        opacity: 0.5;
      `;
    }
    if (type === 'For sale' || type === 'Scouting') {
      return css`
        display: none;
      `;
    }
  }}
`;

export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const ArrowRight = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
export const ArrowLeft = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-225deg);
`;

export const StyledPaginationButton = styled.button`
  border: 0;
  box-shadow: none;
  background-color: #fff;
  border-radius: 3px;
  align-self: center;
  display: flex;
  flex-direction: row;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;
export const StyledPageNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

export const StyledPageLabel = styled.span`
  font-size: 13px;
  margin: 0 10px;
`;

export const StyledUnderlineTeam = styled.span`
  text-decoration: underline;
`;
