import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { DragOverlay, useDraggable } from '@dnd-kit/core';
import { ReactComponent as DragIcon } from '../../assets/img/drag-icon.svg';
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTrTd,
  StyledRatingCell,
  StyledChartContainer,
  StyledTopSection,
  StyledBottomSection,
  StyledSection,
  StyledTitle,
  StyledCellName,
  StyledCell,
  StyledWrapper,
  StyledSortContainer,
  SortLabel,
  StyledSortSelect,
  StyledCross,
  StyledCloseIcon,
  StyledInUse,
  StyledInfoIcon,
  StyledFilterContainer,
  StyledButton,
  StyledHeader,
  StyledTableWrapper,
  StyledInfoSection,
  StyledFeeling,
} from './styled';

import { DonutChart } from '../Charts';
import {
  teamReplacePlayerFromTheirPosition,
  removePlayerFromStaff,
  setFormationListFilter,
  showMobileSidebarSection,
} from '../../redux/actions/Team';
import { getFormationFilter, getClickedPositionOnPitch } from '../../redux/selector/Team';
import { isPremiumUser } from '../../redux/selector/User';
import { getTourState } from '../../redux/selector/TourControl';
import { showPopupWindow } from '../../redux/actions/Popups';

import { getPlayerInformation } from '../../redux/actions/League';
import { rangeColor, formRangeColor, formRangeStatus, positionButtons, simplifiedPosition } from '../../service/data';

import { POPUPS, ratingRangeStatus } from '../../service/constants';

const SortingPriority = {
  10: 'GK',
  9: 'LB',
  8: 'CB',
  7: 'RB',
  6: 'DM',
  5: 'CM',
  4: 'CAM',
  3: 'LW',
  2: 'RW',
  1: 'ST',
};

const isInRange = (value, arr) => Array.isArray(arr) && arr.includes(value);

const DefaultColumnFilter = ({ column: { id, setFilter } }) => {
  const dispatch = useDispatch();
  const activeFormationFilter = useSelector(getFormationFilter);
  const handleChange = (selectedOption) => {
    dispatch(setFormationListFilter(selectedOption.label));
    setFilter((selectedOption && selectedOption.value) || undefined);
  };
  if (id === 'sortPriority') {
    return (
      <StyledFilterContainer>
        {positionButtons.map((item) => (
          <StyledButton
            key={item.label}
            isActive={activeFormationFilter === item.label}
            onClick={() => {
              handleChange(item);
            }}
          >
            {item.label}
          </StyledButton>
        ))}
      </StyledFilterContainer>
    );
  }
  return null;
};
function ReplacePlayerList({ showScrollStyles, activeSquadIds, columns, data, selectedPlayer, activeDraggingId }) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const customRange = (rowsData, id, filterValue) =>
    rowsData.filter((row) => {
      const rowValue = row.values[id];
      return isInRange(SortingPriority[rowValue], filterValue);
    });
  customRange.autoRemove = (val) => !val;
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      positionRange: customRange,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, setFilter, rows, prepareRow, toggleSortBy } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        sortBy: [{ id: 'sortPriority', desc: false }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const dispatch = useDispatch();
  const clickedPosition = useSelector(getClickedPositionOnPitch);
  const isTourStart = useSelector(getTourState);
  const isPremium = useSelector(isPremiumUser);
  React.useEffect(() => {
    if (clickedPosition) {
      const res = positionButtons.filter(
        (item) => item.label !== 'ALL' && item.value.includes(simplifiedPosition[clickedPosition])
      )[0];
      dispatch(setFormationListFilter(res.label));
      setFilter('sortPriority', res.value);
    } else {
      setFilter('sortPriority', '');
    }
  }, [clickedPosition, dispatch, setFilter]);

  const playerCardHandler = React.useCallback(
    (playerId, isDisabled) => {
      if (!isDisabled) {
        const _player = data.filter(({ _id }) => _id === playerId)[0];
        dispatch(teamReplacePlayerFromTheirPosition(_player));
        if (isMobile && clickedPosition) {
          dispatch(showMobileSidebarSection(null));
        }
      }
    },
    [dispatch, clickedPosition, data]
  );
  const mobileSortValues = [
    { value: 0, label: 'Position' },
    { value: 1, label: 'Name: A-Z' },
    { value: 2, label: 'Age: Youngest to oldest' },
    { value: 3, label: 'Rating: High to Low' },
    { value: 4, label: 'Potential: High to Low' },
    { value: 5, label: 'Fitness: High to Low' },
  ];

  const setSortValue = (e) => {
    const x = e.value;
    if (x === 0) toggleSortBy('sortPriority', false, false);
    if (x === 1) toggleSortBy('name', false, false);
    if (x === 2) toggleSortBy('age', false, false);
    if (x === 3) toggleSortBy('ratingId', true, false);
    if (x === 4) toggleSortBy('potentialId', true, false);
    if (x === 5) toggleSortBy('formId', true, false);
  };

  const removePlayerFromSquad = React.useCallback(
    (id) => {
      dispatch(removePlayerFromStaff(id));
    },
    [dispatch]
  );
  const getPlayerInfo = React.useCallback(
    (e, id) => {
      // show player info here
      dispatch(showPopupWindow(POPUPS.PLAYER_INFO_POPUP_VIEW, POPUPS.PLAYER_INFO_POPUP_VIEW, true));
      dispatch(getPlayerInformation(id, POPUPS.PLAYER_INFO_POPUP_VIEW));
      e.stopPropagation();
    },
    [dispatch]
  );

  const DroppablePlaceholder = (cell) => (
    <StyledWrapper small>
      <Draggable id={cell.row.original._id}>
        <DragIcon />
      </Draggable>
      <StyledInfoSection>
        <StyledTopSection small>
          <StyledTitle small>{cell.row.original.name} </StyledTitle>
        </StyledTopSection>
        <StyledBottomSection>
          <StyledSection>
            <StyledCell>
              {isPremium
                ? `${simplifiedPosition[cell.row.original.favPosition]}/${simplifiedPosition[cell.row.original.secondFavPosition]
                }`
                : simplifiedPosition[cell.row.original.favPosition]}
            </StyledCell>
            <StyledCellName>Position</StyledCellName>
          </StyledSection>
          <StyledSection>
            <StyledCell>
              <StyledRatingCell color={rangeColor[ratingRangeStatus[cell.row.original.rating_range]]}>
                {ratingRangeStatus[cell.row.original.rating_range]}
              </StyledRatingCell>
            </StyledCell>
            <StyledCellName>Rating</StyledCellName>
          </StyledSection>
        </StyledBottomSection>
      </StyledInfoSection>
    </StyledWrapper>
  );

  const renderCell = useCallback(
    (cell) => {
      if (cell.column.Header === 'Name') {
        const isInjured = cell.row.original.form_range === -1;
        const isDisabled = activeSquadIds.includes(cell.row.original._id);
        const reverseRating = [...cell.row.original.media_rating];
        reverseRating.reverse();

        return (
          <>
            <StyledWrapper
              isDisabled={isDisabled || isInjured}
              selected={selectedPlayer._id === cell.row.original._id}
              onClick={isMobile ? () => playerCardHandler(cell.row.original._id, isDisabled || isInjured) : undefined}
            >
              {!isMobile && (
                <Draggable isDisabled={cell.row.original.form_range === -1} id={`${cell.row.original._id}`}>
                  <DragIcon />
                </Draggable>
              )}
              <StyledInfoSection>
                {isMobile && <StyledFeeling feeling={cell.row.original.transfer_feeling} />}
                <StyledTopSection>
                  <StyledTitle isDisabled={isDisabled || isInjured}>
                    {cell.row.original.name} <StyledInfoIcon onClick={(e) => getPlayerInfo(e, cell.row.original._id)} />
                  </StyledTitle>
                  {isDisabled ? (
                    <StyledInUse>
                      <span>Player in use</span>

                      <StyledCloseIcon onClick={() => removePlayerFromSquad(cell.row.original._id)}>
                        <StyledCross />
                      </StyledCloseIcon>
                    </StyledInUse>
                  ) : (
                    <StyledChartContainer small isDisabled={isInjured}>
                      {reverseRating.map((item, itemIndex) => (
                        <DonutChart key={`${item}${itemIndex}`} index={itemIndex + 1} arcSize={item} small />
                      ))}
                    </StyledChartContainer>
                  )}
                </StyledTopSection>
                <StyledBottomSection>
                  <StyledSection isDisabled={isDisabled || isInjured}>
                    <StyledCell>
                      {' '}
                      {isPremium
                        ? `${simplifiedPosition[cell.row.original.favPosition]}/${simplifiedPosition[cell.row.original.secondFavPosition]
                        }`
                        : simplifiedPosition[cell.row.original.favPosition]}
                    </StyledCell>
                    <StyledCellName>Position</StyledCellName>
                  </StyledSection>
                  <StyledSection isDisabled={isDisabled || isInjured}>
                    <StyledCell>{cell.row.original.age}</StyledCell>
                    <StyledCellName>Age</StyledCellName>
                  </StyledSection>
                  <StyledSection isDisabled={isDisabled || isInjured}>
                    <StyledCell>
                      <StyledRatingCell color={rangeColor[ratingRangeStatus[cell.row.original.rating_range]]}>
                        {ratingRangeStatus[cell.row.original.rating_range]}
                      </StyledRatingCell>
                    </StyledCell>
                    <StyledCellName>Rating</StyledCellName>
                  </StyledSection>
                  <StyledSection isDisabled={isDisabled || isInjured}>
                    <StyledCell color={rangeColor[cell.row.original.potentialValue]}>
                      {cell.row.original.potentialValue}
                    </StyledCell>
                    <StyledCellName>Potential</StyledCellName>
                  </StyledSection>
                  <StyledSection isDisabled={isDisabled || isInjured}>
                    <StyledCell color={formRangeColor[formRangeStatus[cell.row.original.form_range]]}>
                      {formRangeStatus[cell.row.original.form_range]}
                    </StyledCell>
                    <StyledCellName>Fitness</StyledCellName>
                  </StyledSection>
                </StyledBottomSection>
              </StyledInfoSection>
            </StyledWrapper>
          </>
        );
      }
    },
    [activeSquadIds, getPlayerInfo, playerCardHandler, removePlayerFromSquad, selectedPlayer._id]
  );

  function Draggable(props) {
    const { id, children, isDisabled } = props;
    const { attributes, listeners, setNodeRef } = useDraggable({
      id: `${id} sidebar`,
      disabled: isDisabled,
    });

    return (
      <div
        style={{
          cursor: isDisabled ? 'default' : 'pointer',
          borderRight: '1px solid #e5e5e5',
          alignItems: 'center',
          display: 'flex',
          padding: '0 5px',
        }}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        {children}
      </div>
    );
  }

  const tableCellsFormatter = useCallback(
    (row) =>
      row.cells.map((cell) =>
        cell.column.Header === 'Name' ? <StyledTd isMobile={isMobile}>{renderCell(cell)}</StyledTd> : null
      ),
    [renderCell]
  );
  const DraggableTableRow = ({ row }) => (
    <>
      <StyledTrTd {...row.getRowProps()}>
        {tableCellsFormatter(row)}
        {!isEmpty(activeDraggingId) &&
          activeDraggingId.includes(`${row.original._id}`) &&
          activeDraggingId.includes('sidebar') &&
          !isMobile && (
            <DragOverlay wrapperElement="div">
              <StyledTrTd {...row.getRowProps()}>
                {row.cells.map((cell) =>
                  cell.column.Header === 'Name' ? (
                    <StyledTd small {...cell.getCellProps()}>
                      {DroppablePlaceholder(cell)}
                    </StyledTd>
                  ) : null
                )}
              </StyledTrTd>
            </DragOverlay>
          )}
      </StyledTrTd>
    </>
  );

  return (
    <>
      <StyledHeader className="players-sort-section" showScrollStyles={showScrollStyles}>
        <StyledSortContainer showScrollStyles={showScrollStyles}>
          {!isMobile && <h3>Select Players</h3>}
          <SortLabel>Sort</SortLabel>
          <StyledSortSelect
            defaultValue={mobileSortValues[0]}
            onChange={setSortValue}
            options={mobileSortValues}
            menuPlacement="auto"
            placeholder="Select"
            classNamePrefix="react-select"
          />
        </StyledSortContainer>
        {headerGroups.map((headerGroup, index) =>
          headerGroup.headers.map((column, colIndex) => (
            <React.Fragment key={colIndex}>
              {column.canFilter ? column.render('Filter', { key: index, data: column }) : null}
            </React.Fragment>
          ))
        )}
      </StyledHeader>
      <StyledTableWrapper
        mobileDevice={isMobile}
        className={`replace-player-section ${isTourStart && !isMobile ? 'is-tour-available' : ''}`}
      >
        <StyledTable {...getTableProps()}>
          <StyledTbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return <DraggableTableRow key={row.original.id} row={row} />;
            })}
          </StyledTbody>
        </StyledTable>
      </StyledTableWrapper>
    </>
  );
}
ReplacePlayerList.propTypes = {
  showScrollStyles: PropTypes.bool,
  activeSquadIds: PropTypes.array,
  columns: PropTypes.array,
  data: PropTypes.array,
  selectedPlayer: PropTypes.object,
};
ReplacePlayerList.defaultProps = {
  showScrollStyles: false,
  activeSquadIds: [],
  columns: [],
  data: [],
  selectedPlayer: null,
};

export default ReplacePlayerList;
