import { createSelector } from 'reselect';

export const getTraining = (state) => state.training;
export const getTrainingLoading = (state) => state.training.loading;

export const trainingTeams = createSelector(getTraining, ({ teams }) => teams);

export const getTrainingOfferLoading = (state) =>
  state.training.trainingOfferLoading;
export const getTrainingOfferResponse = (state) =>
  state.training.trainingOfferResponse;
