import React from 'react';
import { StyledReadMoreLink } from './styled';

const ReadMore = ({
  str,
  strLimit = 200,
  onReadMoreClick,
  additionalLabelStyles = null,
  toggleOnClick = false,
}) => {
  const [isToggleOnClick, setToggle] = React.useState(false);
  const testToggle = (v) => {
    setToggle(v);
  };
  const strLength = str ? str.length : 0;
  const renderContent = () => {
    switch (true) {
      case toggleOnClick && isToggleOnClick:
        return (
          <p>
            {str}{' '}
            <StyledReadMoreLink
              additionalLabelStyles={additionalLabelStyles}
              onClick={() => testToggle(false)}
            >
              Show Less
            </StyledReadMoreLink>
          </p>
        );
      case toggleOnClick && !isToggleOnClick:
        return (
          <p>
            {str.substring(0, strLimit)}...
            <StyledReadMoreLink
              additionalLabelStyles={additionalLabelStyles}
              onClick={() => testToggle(true)}
            >
              Read More
            </StyledReadMoreLink>
          </p>
        );
      case strLength > strLimit + 15:
        return (
          <p>
            {str.substring(0, strLimit)}...
            <StyledReadMoreLink
              additionalLabelStyles={additionalLabelStyles}
              onClick={() => onReadMoreClick()}
            >
              Read More
            </StyledReadMoreLink>
          </p>
        );
      case strLength <= strLimit + 15:
        return <p>{str}</p>;
      case !str:
        return <></>;
      default:
        return <></>;
    }
  };
  return <>{renderContent()}</>;
};

export default ReadMore;
