import React from 'react';
import { useTable } from 'react-table';
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTrTd,
  StyledStatusIcon,
  StyledStatus,
} from './styled';

const TableContainer = ({ columns, data, phase }) => {
  const { getTableProps, getTableBodyProps, prepareRow, rows } = useTable({
    columns,
    data,
  });
  const renderCell = (cell, index) => {
    switch (cell.column.id) {
      case 'round':
        return cell.row.original.round;

      case 'date':
        return cell.row.original.date;

      case 'status':
        return (
          <StyledStatus>
            {index >= phase ? 'Upcoming' : 'Played'}
            <StyledStatusIcon status={index >= phase} />
          </StyledStatus>
        );

      default:
        return cell.render('Cell');
    }
  };

  return (
    <>
      <StyledTable {...getTableProps()}>
        <StyledTbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <StyledTrTd
                active={phase === index}
                className={`row-number-${row.id}`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <StyledTd
                    statusCell={cell.column.id}
                    {...cell.getCellProps()}
                  >
                    {renderCell(cell, index)}
                  </StyledTd>
                ))}
              </StyledTrTd>
            );
          })}
        </StyledTbody>
      </StyledTable>
    </>
  );
};

export default React.memo(TableContainer);
