import { createSelector } from 'reselect';

export const domesticCupInfo = (state) => state.domesticCup.domesticCupData;
export const domesticDataLoading = (state) => state.domesticCup.isLoading;
export const domesticTableLoading = (state) => state.domesticCup.isTableLoading;
export const domesticCupCalendar = (state) => state.domesticCup.cupCalendar;

export const firstRoundFirstSetTop = createSelector(
  domesticCupInfo,
  ({ first_round_first_set }) => {
    if (first_round_first_set.length) {
      const homeTeam = first_round_first_set[0].home_team;
      const awayTeam = first_round_first_set[0].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);

export const firstRoundFirstSetBottom = createSelector(
  domesticCupInfo,
  ({ first_round_first_set }) => {
    if (first_round_first_set.length) {
      const homeTeam = first_round_first_set[1].home_team;
      const awayTeam = first_round_first_set[1].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);

export const firstRoundSecondSetTop = createSelector(
  domesticCupInfo,
  ({ first_round_second_set }) => {
    if (first_round_second_set.length) {
      const homeTeam = first_round_second_set[0].home_team;
      const awayTeam = first_round_second_set[0].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);

export const firstRoundSecondSetBottom = createSelector(
  domesticCupInfo,
  ({ first_round_second_set }) => {
    if (first_round_second_set.length) {
      const homeTeam = first_round_second_set[1].home_team;
      const awayTeam = first_round_second_set[1].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);

export const secondRoundFirstSet = createSelector(
  domesticCupInfo,
  ({ second_round }) => {
    if (second_round.length) {
      const homeTeam = second_round[0].home_team;
      const awayTeam = second_round[0].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);

export const secondRoundSecondSet = createSelector(
  domesticCupInfo,
  ({ second_round }) => {
    if (second_round.length) {
      const homeTeam = second_round[1].home_team;
      const awayTeam = second_round[1].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);

export const finalRound = createSelector(domesticCupInfo, ({ final_round }) => {
  if (final_round.length) {
    const homeTeam = final_round[0].home_team;
    const awayTeam = final_round[0].away_team;

    return { homeTeam, awayTeam };
  }
  return {};
});

export const qualFirstSet = createSelector(
  domesticCupInfo,
  ({ qual_round }) => {
    if (qual_round.length) {
      const homeTeam = qual_round[0].home_team;
      const awayTeam = qual_round[0].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);

export const qualSecondSet = createSelector(
  domesticCupInfo,
  ({ qual_round }) => {
    if (qual_round.length) {
      const homeTeam = qual_round[1].home_team;
      const awayTeam = qual_round[1].away_team;

      return { homeTeam, awayTeam };
    }
    return {};
  }
);
