import { headers } from './requestConfig';
import {
  playerBoostRequest,
  playerDelayDeclineRequest,
  setPlayerNickname,
  setPlayerToAcademy,
} from '../service/links';

export const boostRequest = async (playerID) => {
  const response = await fetch(playerBoostRequest, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      playerId: playerID,
    }),
  });
  return response;
};

export const delayDeclineRequest = async (playerID) => {
  const response = await fetch(playerDelayDeclineRequest, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      playerId: playerID,
    }),
  });
  return response;
};

export const addNicknameRequest = async (playerId, nickName) => {
  const response = await fetch(setPlayerNickname, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      playerId,
      nickName,
    }),
  });
  return response;
};

export const setToAcademy = async (playerId) => {
  const response = await fetch(setPlayerToAcademy, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      playerId,
    }),
  });
  return response;
};
