import { createAction } from 'redux-act';
import {
  dataAboutRevenueAndExpensesRequest,
  dataAboutTransactionRequest,
  sendMoneyRequest,
  financeChartRequest,
} from '../../../api/finance';
import { isPopupLoading, setErrorToPopup } from '../Popups';

// -----------------------------get revenue and expenses--------------
export const getDataAboutRevenueAndExpenses = createAction(
  'get data about revenue and expenses from backend'
);

export const setDataAboutRevenueAndExpensesToLoad = createAction(
  'set loading state about revenue and expenses'
);
export const financeGetDataAboutRevenueAndExpenses = () => async (dispatch) => {
  dispatch(setDataAboutRevenueAndExpensesToLoad());
  const response1 = await dataAboutRevenueAndExpensesRequest();
  if (response1.status === 200) {
    const {
      transactions_summary,
      team_total_summary,
      transactions_summary_transfers,
    } = await response1.json();
    dispatch(
      getDataAboutRevenueAndExpenses({
        transactions_summary,
        team_total_summary,
        transactions_summary_transfers,
      })
    );
  }
};
// -----------------------------get transactions--------------
export const getDataAboutTransaction = createAction(
  'get data about transactions from backend'
);

export const setDataAboutTransactionToLoad = createAction(
  'set loading state about transactions'
);
export const financeGetDataAboutTransaction = () => async (dispatch) => {
  dispatch(setDataAboutTransactionToLoad());
  const response1 = await dataAboutTransactionRequest();
  if (response1.status === 200) {
    const transactions = await response1.json();
    dispatch(
      getDataAboutTransaction({
        transactions,
      })
    );
  }
};

export const sendMoney = (teamId, cash) => async (dispatch) => {
  dispatch(isPopupLoading('sendMoneyPopup', true));
  const response = await sendMoneyRequest(teamId, parseInt(cash));
  let error;
  if (response.status === 205) {
    error = {
      header: 'Wrong destination team',
      text: "You've selected a wrong team. Please choose the other one",
    };
    dispatch(setErrorToPopup('sendMoneyPopup', error));
  }
  if (response.status === 204) {
    error = {
      header: 'Anti-fraud restriction',
      text: 'You must own a team for more than 3 weeks to send money to another club.',
    };
    dispatch(setErrorToPopup('sendMoneyPopup', error));
  }
  if (response.status === 203) {
    error = {
      header: 'Send Limit reached',
      text: "You've reached the weekly limit",
    };
    dispatch(setErrorToPopup('sendMoneyPopup', error));
  }
  if (response.status === 202) {
    error = {
      header: 'Limit reached',
      text: 'To limit fraud, clubs can’t wire more than $5M per week',
    };
    dispatch(setErrorToPopup('sendMoneyPopup', error));
  }
  if (response.status === 201) {
    error = {
      header: 'Not enough money',
      text: "You don't have enough money to perform this operation",
    };
    dispatch(setErrorToPopup('sendMoneyPopup', error));
  }
  dispatch(isPopupLoading('sendMoneyPopup', false));
};

export const getFinanceChart = createAction('Finance chart data loaded');
export const financeChartLoading = createAction('Finance chart loading');
export const getFinanceChartData = () => async (dispatch) => {
  dispatch(financeChartLoading());
  const response = await financeChartRequest();
  if (response.status === 200) {
    const chartData = await response.json();
    dispatch(
      getFinanceChart({
        ...chartData,
      })
    );
  }
};
