import React from 'react';
import { useSelector } from 'react-redux';
import usePortal from 'react-useportal';
import { AcceptOfferPopup, ContractOfferPopup } from '../../../../components/Popup';
import { isRightPanelOpen } from '../../../../redux/selector/Sidebar';
import { getPopupByName } from '../../../../redux/selector/Popups';
import {
  getSelectedPlayerInDeals,
  isSelectedPlayerLoadingInDeals,
} from '../../../../redux/selector/Transfers';
import OfferCard from './OfferCard';
import { POPUPS } from '../../../../service/constants';

export default function TransfersPlayerCard({
  cardType,
  type,
  status = 1,
  className,
  offer,
  selectPlayer,
  setSelectPlayer,
  minPotentialValue,
  maxPotentialValue,
  ratingRange,
  salary,
  ...props
}) {
  const { ACCEPT_OFFER_POPUP, CONTRACT_OFFER_POPUP } = POPUPS;
  const teamData =
    cardType === 'Buy' || cardType === 'Loan In'
      ? offer.sending_team
      : offer.receiving_team;
  const rightSidebarOpen = useSelector(isRightPanelOpen);
  const selectedPlayer = useSelector(getSelectedPlayerInDeals);
  const isLoading = useSelector(isSelectedPlayerLoadingInDeals);

  const acceptOfferPopupData = useSelector(getPopupByName(ACCEPT_OFFER_POPUP));
  const contractOfferPopupData = useSelector(getPopupByName(CONTRACT_OFFER_POPUP));
  const isCardDisabled =
    selectedPlayer?._id !== offer.player._id && rightSidebarOpen && !isLoading;
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  const hasLowerOffer = parseInt(offer.transfer_fee_offer) < minPotentialValue;
  const hasHigherOffer = parseInt(offer.transfer_fee_offer) > maxPotentialValue;

  const noOfferLimit = !hasLowerOffer && !hasHigherOffer;
  return (
    <>
      {acceptOfferPopupData && acceptOfferPopupData.id === offer._id && (
        <Portal>
          <AcceptOfferPopup
            popupName={ACCEPT_OFFER_POPUP}
            loanEndDate={offer.loan_end_date}
            hasLowerOffer={hasLowerOffer}
            hasHigherOffer={hasHigherOffer}
            noOfferLimit={noOfferLimit}
          />
        </Portal>
      )}
      {contractOfferPopupData && contractOfferPopupData.id === offer.player._id && <Portal>
        <ContractOfferPopup
          popupName={CONTRACT_OFFER_POPUP}
          id={offer.player._id}
          teamName={teamData.name}
          teamId={teamData._id}
          ratingRange={ratingRange}
          salary={salary}
        />
      </Portal>}
      <OfferCard
        offer={offer}
        cardType={cardType}
        isDisabled={isCardDisabled}
        type={type}
        status={offer.status}
        className={className}
        name={teamData.name}
        player={offer.player}
        _id={offer._id}
        selectPlayer={selectPlayer}
        setSelectPlayer={setSelectPlayer}
        transfer_fee_offer={offer.transfer_fee_offer}
        createdAt={offer.createdAt}
        loan_end_date={offer.loan_end_date}
        {...props}
      />
    </>
  );
}
