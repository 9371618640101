import React from 'react';
import { useDispatch } from 'react-redux';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import mixpanel from 'mixpanel-browser';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledPopUpContainer,
  StyledHeaderWithLogo,
  StyledParagraph,
  DiscordLogoIcon,
} from '../styled';
import {
  showPopupWindow,
  hidePopupWindow,
} from '../../../redux/actions/Popups';
import { POPUPS } from '../../../service/constants';

const DiscordLoginPopup = () => {
  const dispatch = useDispatch();
  const env = runtimeEnv();
  const { REACT_APP_AUTH_LINK } = env;

  const loginWithDiscord = () => {
    mixpanel.track('Discordpop1 accepted');
    window.location.href = REACT_APP_AUTH_LINK;
  };
  const loginWithoutDiscord = () => {
    mixpanel.track('Discordpop1 declined');
    dispatch(
      showPopupWindow(POPUPS.NON_DISCORD_POPUP, POPUPS.NON_DISCORD_POPUP)
    );
    /*  dispatch(
      showPopupWindow(POPUPS.NON_DISCORD_POPUP, POPUPS.NON_DISCORD_POPUP, true)
    ); */
    dispatch(hidePopupWindow(POPUPS.DISCORD_LOGIN_POPUP));
  };
  return (
    <StyledPopUp>
      <StyledPopUpBlock maxWidth={500} darkBg>
        <StyledPopUpContainer>
          <StyledHeaderWithLogo>
            Join with Discord
            <DiscordLogoIcon />
          </StyledHeaderWithLogo>
          <StyledParagraph small aligned="left">
            You need Discord, a popular app with 300+ million users, to play The
            Open League. The next page will let you login with your Discord
            account to complete the registration and start talking other team
            managers. Have fun!
          </StyledParagraph>
          <StyledButton discord onClick={loginWithDiscord}>
            Login with Discord Now
          </StyledButton>
          <StyledButton
            fullWidth
            customBgColor="#979797"
            customFontColor="#fff"
            onClick={loginWithoutDiscord}
          >
            Login with Discord Later
          </StyledButton>
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(DiscordLoginPopup);
