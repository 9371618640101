import React, { useRef } from 'react';

import PageSideBarNavigation from '../../../../../PageSideBarNavigation';

export default function UserGuideSide() {
  const links = useRef([
    { to: '/user-guide/start', text: 'Start' },
    { to: '/user-guide/players', text: 'Players' },
    { to: '/user-guide/off-season', text: 'Off season' },
    { to: '/user-guide/trading', text: 'Trading' },
    { to: '/user-guide/competitions', text: 'Competitions' },
    { to: '/user-guide/coaching', text: 'Coaching' },
    { to: '/user-guide/finances', text: 'Finances' },
  ]);

  return (
    <>
      <PageSideBarNavigation links={links.current} />
    </>
  );
}
