import React from 'react';
import ContentLoader from 'react-content-loader';

const CashChartPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={480}
    height={265}
    viewBox="0 0 480 265"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="24" y="18" rx="4" ry="4" width="133" height="19" />
    <rect x="59" y="120" rx="0" ry="0" width="51" height="100" />
    <rect x="114" y="97" rx="0" ry="0" width="103" height="123" />
    <rect x="222" y="172" rx="0" ry="0" width="84" height="49" />
    <rect x="311" y="148" rx="0" ry="0" width="46" height="74" />
    <rect x="362" y="58" rx="0" ry="0" width="74" height="164" />
    <rect x="26" y="57" rx="4" ry="4" width="26" height="12" />
    <rect x="26" y="88" rx="4" ry="4" width="26" height="12" />
    <rect x="26" y="122" rx="4" ry="4" width="26" height="12" />
    <rect x="26" y="155" rx="4" ry="4" width="26" height="12" />
    <rect x="26" y="186" rx="4" ry="4" width="26" height="12" />
    <rect x="26" y="218" rx="4" ry="4" width="26" height="12" />
    <rect x="47" y="229" rx="4" ry="4" width="26" height="12" />
    <rect x="131" y="229" rx="4" ry="4" width="26" height="12" />
    <rect x="214" y="229" rx="4" ry="4" width="26" height="12" />
    <rect x="299" y="229" rx="4" ry="4" width="26" height="12" />
    <rect x="379" y="229" rx="4" ry="4" width="26" height="12" />
  </ContentLoader>
);

export default CashChartPreloader;
