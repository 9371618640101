import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import usePortal from 'react-useportal';
import {
  CupGroupTable,
  QualifiersTable,
  CupGroupTableMobile,
} from '../../components/DomesticCup';
import LeagueCup from '../../components/LeagueCup';
import LeagueCalendar from '../../components/LeagueCalendar';
import FallbackContentAnimation from '../../components/FallbackContentAnimation';
import {
  UpcomingCalendarPreloader,
  CupSidePreloader,
  WinnerPreloader,
  PlayingPreloader,
  MobileCupGroupPreloader,
} from '../../components/ContentPreloaders';
import { TeamInfoPopup } from '../../components/Popup';

import {
  domesticCupRequest,
  domesticCupLeaguesRequest,
} from '../../redux/actions/DomesticCup';
import {
  leagueGetLeagueTableData,
  getLeaguesList,
} from '../../redux/actions/League';

import { getLeagueId } from '../../redux/selector/League';
import {
  domesticCupInfo,
  domesticDataLoading,
  domesticCupCalendar,
  domesticTableLoading,
} from '../../redux/selector/DomesticCup';
import { getPopupByName } from '../../redux/selector/Popups';

import {
  StyledBlockContainer,
  StyledNewWrapper,
  StyledLeague,
  StyledGroupMatch,
  StyledMobileLeague,
  StyledPlayingSectionPreloader,
  StyledSectionPreloader,
} from './styled';
import { POPUPS } from '../../service/constants';

const DomesticCup = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(domesticDataLoading);
  const data = useSelector(domesticCupInfo);
  const calendarDate = useSelector(domesticCupCalendar);
  const isDomesticTableLoading = useSelector(domesticTableLoading);
  const leagueId = useSelector(getLeagueId);
  const { TEAM_INFO_POPUP } = POPUPS;
  const teamInfoPopupData = useSelector(getPopupByName(TEAM_INFO_POPUP));

  useEffect(() => {
    dispatch(getLeaguesList());
    if (isEmpty(data.phase)) {
      dispatch(domesticCupRequest());
    }
    if (!leagueId) {
      dispatch(leagueGetLeagueTableData());
    }
  }, [dispatch, data.phase, leagueId]);

  useEffect(() => {
    if (leagueId) {
      dispatch(domesticCupLeaguesRequest(leagueId));
    }
  }, [leagueId, dispatch]);
  const isLoad = isLoading || !leagueId;

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  return (
    <StyledNewWrapper>
      {!isEmpty(teamInfoPopupData) && (
        <Portal>
          <TeamInfoPopup popupName={TEAM_INFO_POPUP} />
        </Portal>
      )}
      <StyledBlockContainer>
        <FallbackContentAnimation
          loading={isLoad}
          fallback={
            <StyledPlayingSectionPreloader>
              <PlayingPreloader style={{ width: '100%', height: '100%' }} />
            </StyledPlayingSectionPreloader>
          }
        >
          <LeagueCup phase={data.phase} leagueId={leagueId} />
        </FallbackContentAnimation>

        {!isMobile && (
          <FallbackContentAnimation
            loading={isLoad}
            fallback={
              <StyledSectionPreloader>
                <UpcomingCalendarPreloader />
              </StyledSectionPreloader>
            }
          >
            <LeagueCalendar calendar={calendarDate} phase={data.phase} />
          </FallbackContentAnimation>
        )}
      </StyledBlockContainer>
      <StyledLeague>
        <FallbackContentAnimation
          loading={isLoad || isDomesticTableLoading}
          fallback={
            <>
              <CupSidePreloader />
              <WinnerPreloader />
              <CupSidePreloader rtl />
            </>
          }
        >
          <StyledGroupMatch>
            {data.phase === 0 ? <QualifiersTable /> : <CupGroupTable />}
          </StyledGroupMatch>
        </FallbackContentAnimation>
      </StyledLeague>
      <FallbackContentAnimation
        repeat={5}
        loading={isLoad || isDomesticTableLoading}
        fallback={
          <StyledPlayingSectionPreloader>
            <MobileCupGroupPreloader />
          </StyledPlayingSectionPreloader>
        }
      >
        <StyledMobileLeague>
          <CupGroupTableMobile calendar={calendarDate} phase={data.phase} />
        </StyledMobileLeague>
      </FallbackContentAnimation>
    </StyledNewWrapper>
  );
};
export default DomesticCup;
