import { headers } from './requestConfig';
import {
  transferOffers,
  transferCreateOffer,
  transferAcceptOffer,
  transferCancelOffer,
  transferRejectOffer,
  transferCreateLoanOffer,
} from '../service/links';

export const getTransfersList = async () => {
  const response = await fetch(transferOffers, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const sendTransferRequest = async (player, money) => {
  const response = await fetch(transferCreateOffer, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      player,
      money,
    }),
  });
  return response;
};
export const sendTransferRejectRequest = async (offer) => {
  const response = await fetch(transferRejectOffer, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      offer,
    }),
  });
  return response;
};
export const sendTransferAcceptRequest = async (offerId) => {
  const response = await fetch(transferAcceptOffer, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      offer: offerId,
    }),
  });
  return response;
};

export const sendTransferCancelRequest = async (offerId) => {
  const response = await fetch(transferCancelOffer, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      offer: offerId,
    }),
  });
  return response;
};
export const sendTransferLoanRequest = async (player, money, date) => {
  const response = await fetch(transferCreateLoanOffer, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      player,
      money,
      date,
    }),
  });
  return response;
};
