import { createAction } from 'redux-act';
import { getStuffItems, selectKit } from '../../../api/stuff';
import { toastRender } from '../../../service/toaster';

export const getStuffLoading = createAction('stuff items loading');
export const getStuff = createAction('stuff items get');

export const getStuffResponse = () => async (dispatch) => {
  dispatch(getStuffLoading(true));
  const response = await getStuffItems();
  dispatch(getStuffLoading(false));
  if (response.status === 200) {
    const data = await response.json();
    dispatch(getStuff(data));
  }
};
export const selectKitRequest = (kit) => async (dispatch) => {
  dispatch(getStuffLoading(true));
  const response = await selectKit(kit);
  dispatch(getStuffLoading(false));
  if (response.status === 200) {
    toastRender('success', 'I’ll let our kit manager know to use this jersey.');
    dispatch(getStuffResponse());
  }
};
