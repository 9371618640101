import React from 'react';

import { ReactComponent as Lobby } from '../../assets/img/Lobby.svg';

import {
  StyledLobbyBtn,
  StyledHeaderContainer,
  StyledLink,
  StyledLogoContainer,
  StyledLogo,
} from './styled';

const YouthCampSignUpHeader = () => (
  <StyledHeaderContainer>
    <StyledLobbyBtn>
      <StyledLink to="/home">
        <Lobby />
      </StyledLink>
    </StyledLobbyBtn>
    <StyledLogoContainer>
      <StyledLogo />
    </StyledLogoContainer>
  </StyledHeaderContainer>
);
export default YouthCampSignUpHeader;
