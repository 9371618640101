import React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import Reports from '../Reports';
import { ReactComponent as Arrow } from '../../assets/img/SimpleArrow.svg';
import { getMyCurrentTeam } from '../../redux/selector/User';
import { leagueSetTeamInfo } from '../../redux/actions/League';

import {
  StyledContainer,
  StyledBlock,
  StyledSeason,
  StyledMatchResult,
  StyledButton,
  StyledScore,
  StyledTeamsContainer,
  StyledHeader,
  StyledDate,
  StyledCenterBlock,
  StyledTeam,
} from './styled';
import { LastResultPreloader } from '../ContentPreloaders';
import FallbackContentAnimation from '../FallbackContentAnimation';
import { showPopupWindow } from '../../redux/actions/Popups';
import { showMobileSidebarSection } from '../../redux/actions/Team';
import { POPUPS } from '../../service/constants';

const LastResult = ({
  matchDate,
  away_team = null,
  our_team = null,
  isDomesticCup,
  isLoading,
  timezone = null,
  isFriendly,
}) => {
  const dispatch = useDispatch();
  const myTeam = useSelector(getMyCurrentTeam);
  const dateAtUTC = moment.utc();
  const tzOffset = timezone
    ? moment.tz.zone(timezone).utcOffset(dateAtUTC)
    : '';
  const formattedMatchDate = moment(matchDate).utcOffset('-0000');
  const timezoneDateRelatedToUTC = `${formattedMatchDate
    .subtract(tzOffset / 60, 'hours')
    .format('MMM D')} 7:00PM`;

  const showTeamInfo = (id) => {
    const { TEAM_INFO_POPUP } = POPUPS;

    dispatch(leagueSetTeamInfo({ _id: id }, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
    }
  };

  const matchType = () => {
    if (isFriendly) {
      return 'Friendly';
    }
    if (isDomesticCup) {
      return 'Cup';
    }

    return 'League';
  };

  return (
    <StyledContainer>
      <FallbackContentAnimation
        loading={isLoading}
        fallback={<LastResultPreloader />}
      >
        <StyledBlock className="last-match-section">
          <StyledHeader>
            <StyledSeason>Last Result</StyledSeason>

            <StyledButton to="/league">
              View Fixtures
              <Arrow
                style={{
                  transform: 'scale(-1)',
                  width: '7px',
                  height: '7px',
                  marginLeft: '10px',
                }}
              />
            </StyledButton>
          </StyledHeader>

          <StyledTeamsContainer>
            <StyledTeam onClick={() => showTeamInfo(our_team._id)}>
              <img src={our_team?.symbol} alt="logo" />
              <span>{our_team?.name}</span>
            </StyledTeam>
            <StyledCenterBlock>
              <StyledDate>{timezoneDateRelatedToUTC}</StyledDate>
              <StyledMatchResult>
                <StyledScore
                  whichTeam={
                    myTeam?.name === our_team?.name ? 'my' : 'opponent'
                  }
                >
                  <h6>{our_team?.goals}</h6>
                </StyledScore>
                <span>-</span>
                <StyledScore
                  whichTeam={
                    myTeam?.name === away_team?.name ? 'my' : 'opponent'
                  }
                >
                  <h6>{away_team?.goals}</h6>
                </StyledScore>
              </StyledMatchResult>
              <StyledDate>{matchType()} Match</StyledDate>
            </StyledCenterBlock>
            <StyledTeam onClick={() => showTeamInfo(away_team._id)}>
              <img src={away_team?.symbol} alt="logo" />
              <span>{away_team?.name}</span>
            </StyledTeam>
          </StyledTeamsContainer>
          <Reports />
        </StyledBlock>
      </FallbackContentAnimation>
    </StyledContainer>
  );
};

LastResult.propTypes = {
  matchDate: PropTypes.string.isRequired,
  away_team: PropTypes.object.isRequired,
  our_team: PropTypes.object.isRequired,
  isDomesticCup: PropTypes.oneOf([0, 1]),
  isLoading: PropTypes.bool,
  isFriendly: PropTypes.bool,
};

LastResult.defaultProps = {
  isDomesticCup: 0,
  isLoading: true,
  isFriendly: false,
};

export default React.memo(LastResult);
