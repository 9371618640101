import styled from 'styled-components';

export const StyledTransactionsBlock = styled.div`
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 20px;
  &:last-of-type {
    border-bottom: 0;
  }
`;
export const StyledTransaction = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  &[data-type='revenue'] {
    span {
      color: #219653;
      &:before {
        margin-right: 0px;
        content: '+';
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 26px;
        color: #219653;
      }
    }
  }
  &[data-type='expenses'] {
    span {
      color: #eb5757;
    }
  }
  h4 {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #000000;
  }
  span {
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #000000;
  }
  h6 {
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #bdbdbd;
    span {
      font-family: 'Gilroy', sans-serif;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: -0.03em;
      color: #bdbdbd;
    }
  }
`;

export const StyledDate = styled.span`
  margin: 4px 0;
  display: flex;
  justify-content: space-between;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.03em;
  color: #999;
`;
