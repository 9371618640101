import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTable, useSortBy, usePagination } from 'react-table';
import {
  StyledTable,
  StyledTrTh,
  StyledThead,
  StyledTh,
  StyledTbody,
  StyledTd,
  StyledTrTd,
  StyledPagination,
  ArrowRight,
  ArrowLeft,
  StyledPaginationButton,
  StyledPageNumber,
  StyledPageLabel,
  StyledSelectContainer,
  StyledSelect,
  StyledTableContainer,
  StyledDealsPlayerName,
} from './styled';
import { transferSelectPlayerInOffer } from '../../../../../../redux/actions/Transfers';
import { sidebarOpenRightPanel } from '../../../../../../redux/actions/Sidebar';
import { numberWithCommas } from '../../../../../../service/utils';

const TableContainer = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [{ id: 'date', desc: true }],
      },
    },
    useSortBy,
    usePagination
  );
  const showOptions = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
  ];

  const [tableSize, setTableSize] = React.useState(showOptions[0]);

  const dispatch = useDispatch();

  const setRowSize = (e) => {
    setPageSize(e.value);
    setTableSize(e);
  };

  const openSidebarWithPlayer = (playerId) => {
    const player = {
      player: { _id: playerId },
    };
    dispatch(sidebarOpenRightPanel(true));
    dispatch(transferSelectPlayerInOffer(player));
  };

  const renderCell = (cell, index) => {
    switch (cell.column.id) {
      case 'date':
        return (
          <span>
            {moment(cell.row.original.date)
              .utcOffset('-0000')
              .format('D MMM YYYY')}
          </span>
        );

      case 'dealType':
        return cell.row.original.dealType;

      case 'playerName':
        return (
          <StyledDealsPlayerName
            onClick={() => openSidebarWithPlayer(cell.row.original.playerId)}
          >
            {cell.row.original.playerName}
          </StyledDealsPlayerName>
        );

      case 'amount':
        return `$${numberWithCommas(cell.row.original.amount)}`;

      case 'status':
        return cell.row.original.status;

      default:
        return cell.render('Cell');
    }
  };

  return (
    <>
      <StyledTableContainer>
        <StyledTable {...getTableProps()}>
          <StyledThead>
            {headerGroups.map((headerGroup) => (
              <StyledTrTh {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTh type={column.Header} {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </StyledTh>
                ))}
              </StyledTrTh>
            ))}
          </StyledThead>
          <StyledTbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <StyledTrTd
                  className={`row-number-${row.id}`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <StyledTd
                      type={cell.column.Header}
                      statusCell={cell.column.id}
                      pointerCursor={cell.column.Header === 'Player Name'}
                      {...cell.getCellProps()}
                    >
                      {renderCell(cell, index)}
                    </StyledTd>
                  ))}
                </StyledTrTd>
              );
            })}
          </StyledTbody>
        </StyledTable>
      </StyledTableContainer>

      <StyledPagination>
        <StyledPageNumber>
          <StyledPaginationButton
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <ArrowLeft />
          </StyledPaginationButton>
          <StyledPageLabel>
            Page <strong>{pageIndex + 1}</strong> of {pageOptions.length}
          </StyledPageLabel>
          <StyledPaginationButton
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <ArrowRight />
          </StyledPaginationButton>
        </StyledPageNumber>

        <StyledSelectContainer>
          <span>Showing players: </span>
          <StyledSelect
            classNamePrefix="react-select"
            defaultValue={showOptions[0]}
            value={tableSize}
            options={showOptions}
            onChange={setRowSize}
            menuPlacement="auto"
          />
        </StyledSelectContainer>
      </StyledPagination>
    </>
  );
};

export default React.memo(TableContainer);
