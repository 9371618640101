import styled from 'styled-components';
import Select from 'react-select';
import { StyledButton } from '../../../../globalStyled';

export const StyledActionSelect = styled(Select)`
  bottom: 0;
  background-color: #fff;
  width: 120px;
  margin-right: 15px;
  font-size: 13px;
  border: 1px solid #000;
  border-radius: 3px;
  min-height: 24px;
  height: 24px;
  @media only screen and (max-width: 768px) {
    width: 95px;
    margin-right: 5px;
  }
  .react-select__menu {
    z-index: 2;
    width: 140px;
    @media only screen and (max-width: 768px) {
      width: 96px;
    }
  }
  .react-select__menu-list {
    background-color: #000;
    border-radius: 4px;
    box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.2);
  }
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    min-height: 24px;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    box-shadow: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    padding: 8px 10px;
    color: #fff;
    &--is-focused,
    &--is-selected {
      background-color: #333333;
      color: #fff;
    }
  }
  .react-select__indicator {
    padding: 2px 8px;
    svg {
      fill: #4b4b4a;
      width: 12px;
    }
  }
  .react-select__single-value {
    font-weight: normal;
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }

  .react-select__indicator-separator {
    margin-bottom: 4px;
    margin-top: 2px;
    background-color: #333333;
  }
`;
export const StyledSelectContainer = styled.div`
  width: 100%;
  display: flex;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;
export const StyledTableButton = styled(StyledButton)`
  height: 29px;
  min-width: 120px;
  margin: 0px;
  font-size: 13px;
  text-transform: capitalize;
`;

export const StyledScoutingSpan = styled.span`
  color: #ff4d09 !important;
  border-radius: 5px;
  padding: 0px;
  background: transparent;
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Gilroy', sans-serif;
  line-height: 100%;
  font-weight: 500;
  font-size: 13px;
`;

export const StyledScoutResult = styled.span`
  font-size: 13px;
`;

export const StyledButtonContainer = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
