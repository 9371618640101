import { createAction } from 'redux-act';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';

import { isEmpty } from 'lodash';
import {
  teamPlayerList,
  teamStrategy,
  teamSquad,
} from '../../../service/links';
import {
  sendReleaseRequest,
  sendAutoFormationRequest,
} from '../../../api/team';
import { getPlayerInfo } from '../../../api/league';
import {
  boostRequest,
  delayDeclineRequest,
  addNicknameRequest,
  setToAcademy,
} from '../../../api/player';
import { POPUPS } from '../../../service/constants';
import { toastRender } from '../../../service/toaster';
import { sidebarGetDataForSideBar } from '../Sidebar';
import { setErrorToPopup, isPopupLoading } from '../Popups';

const {
  CONFIRM_ACCELERATE_POPUP,
  CONFIRM_DECLINE_DELAY_POPUP,
  ADD_NICKNAME_POPUP,
  RELEASE_PLAYER_POPUP,
  CONFIRM_ACADEMY_POPUP,
} = POPUPS;
const generalTeamRool = {
  '4-4-2': {
    LS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-3-3': {
    LW: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RW: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(C)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-2-3-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    // CAM: [
    //   {
    //     name: "Empty",
    //     rating: "0",
    //   },
    // ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-5-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],

    'DM(C)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '3-5-2': {
    LS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RS: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    // CAM: [
    //   {
    //     name: "Empty",
    //     rating: "0",
    //   },
    // ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],

    'DM(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DM(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(C)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
  '4-4-1-1': {
    CF: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    LCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    CM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    // CAM: [
    //   {
    //     name: "Empty",
    //     rating: "0",
    //   },
    // ],
    RCM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RM: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],

    LB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(L)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    'DC(R)': [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    RB: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
    GK: [
      {
        name: 'Empty',
        rating: '0',
      },
    ],
  },
};

export const addToList = createAction('add player to sidebar view list');
export const loadPlayerInfo = createAction('add player loading');
export const removeFromList = createAction(
  'remove player from sidebar view list'
);
export const setUnsavedChanges = createAction('Unsaved changes added');

export const teamAddToList = (data) => async (dispatch) => {
  dispatch(loadPlayerInfo());
  const playerDataResponse = await getPlayerInfo(data._id);
  if (playerDataResponse.status === 200) {
    const playerData = await playerDataResponse.json();
    const {
      recovery_time,
      fav_formation,
      fav_mindset,
      fav_opponent,
      fav_zone,
      worst_mindset,
      worst_opponent,
      second_fav_position,
      transfer_feeling,
      rating_range,
    } = playerData.players;
    const player = {
      ...data,

      recovery_time,
      name: `${data.first_name} ${data.last_name}`,
      fav_formation,
      fav_mindset,
      fav_opponent,
      fav_zone,
      worst_mindset,
      worst_opponent,
      second_fav_position,
      transfer_feeling,
      contract: playerData.contract,
      rating_range
    };
    dispatch(addToList({ player }));
  }
};
export const teamRemoveFromList = (player) => async (dispatch) => {
  dispatch(removeFromList({ player }));
};

// ----------------------STRATEGY----------------------------------

export const pickOpponentStrategy = createAction(
  'select strategy for our match for opponent'
);

export const teamPickUpOpponentStrategy = (strategy) => async (dispatch) => {
  dispatch(pickOpponentStrategy({ strategy }));
};

export const pickStrategy = createAction('select strategy for our match');

export const teamPickUpStrategy = (strategy) => async (dispatch, getState) => {
  const {
    team: {
      strategy: { majorStaff, allTeam },
    },
  } = getState();
  const MyTeamGrouped = groupBy(majorStaff, 'current_position');
  const idealTeamConfig = generalTeamRool[strategy];
  const CHECKED = Object.keys(idealTeamConfig).map(
    (teamType) => MyTeamGrouped[teamType]
  );
  // TODO: Need's to remove such a lot of function for testing purposes
  const fromCheckedToGrouped = flatMap(CHECKED);
  const grouped = groupBy(fromCheckedToGrouped, 'id');
  const newAll = allTeam.map(({ main, id, ...all }) => {
    if (grouped[id])
      return {
        ...all,
        id,
        main: true,
      };
    return {
      ...all,
      id,
      main: false,
    };
  });
  dispatch(pickStrategy({ strategy, newAll }));
};
export const getOpponentStrategy = createAction(
  'select strategy for our match'
);

export const teamGetOpponentStrategy = (strategy) => async (dispatch) => {
  dispatch(getOpponentStrategy({ strategy }));
};

// -------------------------LIST PLAYER LOGIC---------------------------
export const listPlayerLoader = createAction('list/remove player loader');
export const listPlayer = createAction('list/remove player');
export const listPlayerError = createAction('list/remove player error');
export const teamListPlayer =
  (playerID, listed = true) =>
    async (dispatch) => {
      dispatch(listPlayerLoader());
      const response = await fetch(teamPlayerList, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        credentials: 'include',
        body: JSON.stringify({
          playerID,
          listed,
        }),
      });
      if (response.status === 200) {
        dispatch(listPlayer({ playerID, status: listed }));
        toastRender(
          'success',
          `Player was ${listed
            ? 'added to the transfer list'
            : 'removed from the transfer list'
          }`
        );
      } else {
        dispatch(listPlayerError());
        if (response.status === 403) {
          toastRender('error', 'Player is not in team');
        } else {
          toastRender(
            'error',
            'Red card. We’re having issues with our club platform'
          );
        }
      }
    };

// -----------------------GET STRATEGY-----------------------
export const getMyStrategyLoading = createAction(
  'set loading state for getting information about my own strategy'
);
export const getMyStrategy = createAction(
  'get information about my own strategy'
);
export const teamGetMyStrategy = () => async (dispatch) => {
  dispatch(getMyStrategyLoading());
  const response = await fetch(teamStrategy, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });
  if (response.status === 200) {
    const formationDescription = {
      0: '4-4-2',
      1: '4-3-3',
      2: '4-2-3-1',
      3: '4-5-1',
      4: '3-5-2',
      5: '4-4-1-1',
    };
    const {
      teamPlayers,
      opponentPlayers,
      teamFormation,
      opponentName,
      opponentLastFormation,
      tactics,
      upcomingCalender,
      isAutoFormationEnabled,
      matchRunning,
      discordChannel,
      isOpponentPremium,
      teamKit,
      opponentKit,
    } = await response.json();
    const findNotInMainSquad = teamPlayers.find(({ status }) => status === 0);
    const notInMainSquad = findNotInMainSquad
      ? findNotInMainSquad.players.map(({ ...data }) => ({
        ...data,
        main: false,
      }))
      : [];
    const mainSquad = teamPlayers.find(({ status }) => status === 1);
    const inMainSquad =
      mainSquad?.players.map(({ ...data }) => ({ ...data, main: true })) || [];
    const allTeam = [...notInMainSquad, ...inMainSquad];
    dispatch(
      getMyStrategy({
        allTeam,
        notInMainSquad,
        inMainSquad,
        opponentPlayers,
        opponentName,
        teamFormation: formationDescription[teamFormation],
        opponentLastFormation: formationDescription[opponentLastFormation],
        tactics,
        upcomingCalendar: upcomingCalender,
        isAutoFormationEnabled,
        matchRunning,
        discordChannel,
        isOpponentPremium,
        teamKit,
        opponentKit,
      })
    );
  }
  // GENERATE DATA
  // dispatch(getMyTeam({ all: makeDataTeam(80) }));
};

// -------------------MAKE REQUEST FOR CREATING MATCH-------------
export const createRequestForSaveStrategy = createAction(
  'make request for save strategy'
);
export const setRequestForSaveStrategyLoading = createAction(
  'make request for save strategy loading'
);
export const updateStrategyData = createAction('update strategy data');
export const teamCreateRequestForSaveStrategy =
  (effort, mindset, teamTYPE, soccerTeam, matchRunning) => async (dispatch) => {
    dispatch(setRequestForSaveStrategyLoading());
    const formationDescription = {
      '4-4-2': 0,
      '4-3-3': 1,
      '4-2-3-1': 2,
      '4-5-1': 3,
      '3-5-2': 4,
      '4-4-1-1': 5,
    };

    const formation = formationDescription[teamTYPE];
    const players = soccerTeam.map(({ _id, current_position }) => ({
      _id,
      pos: current_position,
    }));
    const response = await fetch(teamStrategy, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify({ effort, mindset, formation, players }),
    });
    if (response.status === 200) {
      dispatch(
        createRequestForSaveStrategy({
          formation,
          players,
          competeLoading: true,
        })
      );
      toastRender(
        'success',
        `${matchRunning
          ? 'You got it boss. We’ll make these changes as soon as possible and hopefully win this match.'
          : 'Strategy submitted. We’ll brief the players.'
        } `
      );
    } else {
      dispatch(
        createRequestForSaveStrategy({
          formation,
          players,
          competeLoading: false,
        })
      );
      toastRender(
        'error',
        'Red card. We’re having issues with our club platform'
      );
    }
  };
// -----------------------TEAM---------------------------
export const setSeason = createAction('Set season number');
export const setSeasonNumber = (number) => async (dispatch) => {
  dispatch(setSeason(number));
};

export const getMyTeamLoading = createAction(
  'set loading state for getting information about my own team'
);
export const getMyTeam = createAction('get information about my own team');
export const teamGetMyTeam = () => async (dispatch) => {
  dispatch(getMyTeamLoading());
  const response = await fetch(teamSquad, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    credentials: 'include',
  });
  if (response.status === 200) {
    const {
      players,
      loanedOutPlayers = [],
      seasonNumber,
      academyPlayers,
    } = await response.json();
    const formattedLoanedOutPlayers = loanedOutPlayers.map((obj) => ({
      ...obj,
      loanedOut: true,
    }));
    const allPlayers = [
      ...players,
      ...formattedLoanedOutPlayers,
      ...academyPlayers,
    ];
    const academyFormattedPlayers = academyPlayers.map((player) => player._id);
    dispatch(setSeasonNumber(seasonNumber));
    dispatch(
      getMyTeam({
        allPlayers,
        academyList: academyFormattedPlayers,
        academyPlayers,
      })
    );
  }
};
export const removePlayerFromMajorStaff = createAction(
  'Player was removed from major staff'
);
export const removePlayerFromStaff = (id) => async (dispatch) => {
  dispatch(removePlayerFromMajorStaff(id));
};
// ----------------------EXPERT TIPS-----------------------------------------

export const showExpertTips = createAction('show expert tips');
export const teamShowExpertTips = (state) => async (dispatch) => {
  dispatch(showExpertTips({ state }));
};
// ---------------------REPLACE PLAYER-------------------------------------

export const selectPlayerOnGameField = createAction(
  'select player on game field'
);
export const teamSelectPlayerOnGameField = (player) => async (dispatch) => {
  dispatch(selectPlayerOnGameField({ player }));
};
export const replacePlayerFromTheirPosition = createAction(
  'replace player from their position'
);
export const clickedPitchPlayerPosition = createAction(
  'player position on pitch click'
);
export const teamReplacePlayerFromTheirPosition =
  (player) => async (dispatch) => {
    dispatch(replacePlayerFromTheirPosition({ player }));
  };
export const pitchPlayerPosition = (position) => async (dispatch) => {
  dispatch(clickedPitchPlayerPosition(position));
};

// -------------------RELEASE PLAYER -------------------------------
export const releasePlayerLoading = createAction('Release request loading');
export const sendReleasePlayer = (playerID) => async (dispatch) => {
  dispatch(isPopupLoading(RELEASE_PLAYER_POPUP, true));
  const response = await sendReleaseRequest(playerID);
  if (response.status === 200) {
    dispatch(sidebarGetDataForSideBar());
  } else {
    // TODO create mapping for statuses from separate file
    let error;
    if (response.status === 410) {
      error = {
        header: 'Can’t release this player',
        text: 'Our agreement with the players prevents clubs from releasing Strong players aged 34 and under, World Class players 36 and under, and Legend players 38 and under. Try to sell them instead.',
      };
    } else if (response.status === 411) {
      error = {
        header: 'Unable to release player',
        text: ' Teams are not allowed to release players during matches. Try again later.',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup(RELEASE_PLAYER_POPUP, error));
  }
  dispatch(isPopupLoading(RELEASE_PLAYER_POPUP, false));
};

export const removePlayer = createAction('Player was removed from team table');

export const removeReleasedPlayer = (playerID) => async (dispatch) => {
  dispatch(removePlayer(playerID));
};
export const setMindset = createAction('Set mindset');
export const setTactic = createAction('Set tactic');
export const setEffort = createAction('Set effort');
export const setTacticData = (data) => async (dispatch) => {
  dispatch(setTactic(data));
};
export const setMindsetData = (data) => async (dispatch) => {
  dispatch(setMindset(data));
  dispatch(setUnsavedChanges(true));
};
export const setEffortData = (data) => async (dispatch) => {
  dispatch(setEffort(data));
  dispatch(setUnsavedChanges(true));
};

export const setSelectedListIds = createAction(
  'Set selected strategy players ids'
);
export const setSelectedTeamIds = (list) => async (dispatch) => {
  dispatch(setSelectedListIds({ list }));
};

export const setAutoFormationVal = createAction('Set auto formation value');
export const setAutoFormationValue = (val) => async (dispatch) => {
  dispatch(setAutoFormationVal(val));
};

export const autoFormationLoading = createAction('Start auto format loading');
export const setAutoFormationList = createAction('Start auto format loaded');

export const setAutoFormation = (strategyFormat) => async (dispatch) => {
  dispatch(autoFormationLoading(true));
  const response = await sendAutoFormationRequest(strategyFormat);
  if (response.status === 200) {
    const autoFormation = await response.json();
    if (!isEmpty(autoFormation)) {
      const findNotInMainSquad = autoFormation.find(
        ({ status }) => status === 0
      );
      const notInMainSquad = findNotInMainSquad
        ? findNotInMainSquad.players.map(({ ...data }) => ({
          ...data,
          main: false,
        }))
        : [];
      const inMainSquad = autoFormation
        .find(({ status }) => status === 1)
        .players.map(({ ...data }) => ({ ...data, main: true }));
      const allTeam = [...notInMainSquad, ...inMainSquad];
      dispatch(setAutoFormationList({ allTeam, inMainSquad, notInMainSquad }));
      toastRender(
        'success',
        'We selected today’s most competitive starting 11 for you'
      );
    } else {
      dispatch(autoFormationLoading(false));
      toastRender('success', 'Chris won’t pick a starting lineup anymore.');
    }
  }
};
// TODO move mobile sidebar actions to Sidebar
export const showMobileSection = createAction('Show mobile strategy section');
export const showMobileSidebarSection = (value) => async (dispatch) => {
  dispatch(showMobileSection(value));
};

export const setFormationFilter = createAction('Set formation table filter');

export const setFormationListFilter = (value) => async (dispatch) => {
  dispatch(setFormationFilter(value));
};

export const editSelectedPlayerData = createAction('Change team player data');
/* export const accBoostLoading = createAction(
  'Acceleration development request loading'
); */

export const playerAccBoost = (playerId) => async (dispatch) => {
  dispatch(isPopupLoading(CONFIRM_ACCELERATE_POPUP, true));
  const response = await boostRequest(playerId);
  if (response.status === 200) {
    dispatch(
      editSelectedPlayerData({
        id: playerId,
        dataToChange: { boost_enabled: true },
      })
    );
    dispatch(sidebarGetDataForSideBar());
  } else {
    let error;
    if (response.status === 201) {
      error = {
        header: 'Reject failed',
        text: 'Player is not in team.',
      };
    } else if (response.status === 202) {
      error = {
        header: 'Request failed',
        text: 'Player is already in this program.',
      };
    } else if (response.status === 203) {
      error = {
        header: 'Not enough money',
        text: "You don't have enough money to perform this operation",
      };
    } else if (response.status === 404) {
      error = {
        header: 'Request failed',
        text: 'Invalid data',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup(CONFIRM_ACCELERATE_POPUP, error));
  }
  dispatch(isPopupLoading(CONFIRM_ACCELERATE_POPUP, false));
};

export const playerDelayDecline = (playerId) => async (dispatch) => {
  dispatch(isPopupLoading(CONFIRM_DECLINE_DELAY_POPUP, true));
  const response = await delayDeclineRequest(playerId);
  if (response.status === 200) {
    dispatch(
      editSelectedPlayerData({
        id: playerId,
        dataToChange: { decline_enabled: true },
      })
    );
    dispatch(sidebarGetDataForSideBar());
  } else {
    let error;
    if (response.status === 201) {
      error = {
        header: 'Reject failed',
        text: 'Player is not in team.',
      };
    } else if (response.status === 202) {
      error = {
        header: 'Request failed',
        text: 'Player is already in this program.',
      };
    } else if (response.status === 203) {
      error = {
        header: 'Not enough money',
        text: "You don't have enough money to perform this operation",
      };
    } else if (response.status === 404) {
      error = {
        header: 'Request failed',
        text: 'Invalid data',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup(CONFIRM_DECLINE_DELAY_POPUP, error));
  }
  dispatch(isPopupLoading(CONFIRM_DECLINE_DELAY_POPUP, false));
};

export const setPlayerNickname = (playerId, nickname) => async (dispatch) => {
  dispatch(isPopupLoading(ADD_NICKNAME_POPUP, true));
  const response = await addNicknameRequest(playerId, nickname);
  if (response.status === 200) {
    dispatch(
      editSelectedPlayerData({
        id: playerId,
        dataToChange: { nick_name: nickname },
      })
    );
  } else {
    let error;
    if (response.status === 201) {
      error = {
        header: 'Reject failed',
        text: 'Player is not in team.',
      };
    } else if (response.status === 202) {
      error = {
        header: 'Request failed',
        text: 'Player already has a nickname',
      };
    } else if (response.status === 203) {
      error = {
        header: 'Nickname is already taken.',
        text: 'Let’s be more creative…',
      };
    } else if (response.status === 404) {
      error = {
        header: 'Request failed',
        text: 'Invalid data',
      };
    } else {
      error = {
        header: 'There is some server error.',
        text: 'Please try again later.',
      };
    }
    dispatch(setErrorToPopup(ADD_NICKNAME_POPUP, error));
  }
  dispatch(isPopupLoading(ADD_NICKNAME_POPUP, false));
};

export const setToAcademyAction = createAction('Show mobile strategy section');

export const setToAcademyRequest = (playerId) => async (dispatch) => {
  dispatch(isPopupLoading(CONFIRM_ACADEMY_POPUP, true));
  const response = await setToAcademy(playerId);
  if (response.status === 200) {
    dispatch(setToAcademyAction(playerId));
  } else {
    let error;
    if (response.status === 201) {
      error = {
        header: 'Cannot send player',
        text: 'Player is already in our academy',
      };
    } else if (response.status === 202) {
      error = {
        header: 'Cannot send player',
        text: 'We do not own this player',
      };
    } else if (response.status === 203) {
      error = {
        header: 'Cannot send player',
        text: 'We can only send players who are still developing',
      };
    } else if (response.status === 204) {
      error = {
        header: 'Cannot send player',
        text: 'Player is lent to another team',
      };
    } else if (response.status === 205) {
      error = {
        header: 'Cannot send player',
        text: 'All our academy slots are full',
      };
    } else if (response.status === 206) {
      error = {
        header: 'Cannot send player',
        text: ' All our academy slots are full. Become a patron to open up one additional slot',
      };
    } else {
      error = {
        header: 'Ooops.',
        text: 'Something is wrong with the portal',
      };
    }
    dispatch(setErrorToPopup(CONFIRM_ACADEMY_POPUP, error));
  }
  dispatch(isPopupLoading(CONFIRM_ACADEMY_POPUP, false));
};
