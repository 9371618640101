import React, { useMemo } from 'react';

import Table from './Table';
import {
  StyledContainer,
  StyledTableContainer,
  StyledSubheader,
  StyledHeader,
} from './styled';
import { phaseToRound } from '../../service/data';

const LeagueCalendar = ({ calendar, phase }) => {
  const data = useMemo(
    () =>
      calendar &&
      calendar.map((item) => ({
        round: item.round,
        date: item.date,
        status: item.status,
        active: item.active,
      })),
    [calendar]
  );

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        accessor: 'round',
      },
      {
        Header: () => null,
        accessor: 'date',
      },
      {
        Header: () => null,
        accessor: 'status',
      },
    ],
    []
  );
  return (
    <StyledContainer>
      <StyledSubheader>Upcoming Round</StyledSubheader>
      <StyledHeader>{phaseToRound[phase]}</StyledHeader>
      <StyledTableContainer>
        <Table data={data} columns={columns} phase={phase} />
      </StyledTableContainer>
    </StyledContainer>
  );
};

export default LeagueCalendar;
