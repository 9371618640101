import { createSelector } from 'reselect';

export const getFriendly = (state) => state.friendly;
export const getFriendlyLoading = (state) => state.friendly.loading;

export const friendlyTeams = createSelector(getFriendly, ({ teams }) => teams);

export const getFriendlyOfferLoading = (state) =>
  state.friendly.friendlyOfferLoading;
export const getFriendlyOfferResponse = (state) =>
  state.friendly.friendlyOfferResponse;
