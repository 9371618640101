import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const StyledContainer = styled.div`
  font-family: 'Gilroy', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  &:nth-of-type(2n + 1) {
    background-color: #f2f2f2;
    border-bottom: 1px solid #e5e5e5;
  }
  &:first-of-type {
    border: 0;
  }
`;

export const StyledLeftSection = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledRightSection = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    flex-direction: column;
  }
`;
export const StyledLogo = styled.img`
  cursor: pointer;
  max-width: 22px;
  max-height: 22px;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    max-width: 40px;
    height: auto;
    max-height: 40px;
  }
`;
export const StyledTeamInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 10px;
  justify-content: center;
`;
export const StyledName = styled.span`
  font-size: 12px;
  color: #000;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
export const StyledDate = styled.span`
  font-size: 10px;
  color: #999;
  @media only screen and (max-width: 768px) {
    font-size: 11px;
  }
`;
export const StyledInfoLabel = styled.div`
  padding: 3px 15px;
  border-radius: 4px;
  background-color: #fdca40;
  letter-spacing: 0.5;
  font-family: Gilroy;
  font-size: 9px;
  color: #000;
  font-weight: 600;
  font-stretch: normal;
  margin-left: 5px;
  text-align: center;
  &:first-of-type {
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    font-size: 10px;
    margin: 3px 0;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    margin: 1px 0;
  }
`;
