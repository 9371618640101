export const drawGridCell = (item) => {
  if (item === 0) {
    return { color: '#d63c30', index: 5 };
  }
  if (item === 1) {
    return { color: '#D86731', index: 4 };
  }
  if (item === 2) {
    return { color: ' #FABB05', index: 3 };
  }
  if (item === 3) {
    return { color: '#00996B', index: 2 };
  }
  if (item === 4) {
    return { color: ' #69C7AF', index: 1 };
  }
  if (item === 5) {
    return { color: '#2253c7', index: 0 };
  }
  return false;
};

export const getLastSeasons = (currentSeason, lastSeasonsNumber = 5) => {
  const seasonNumbers = [];
  let lastSeasons;
  if (currentSeason > lastSeasonsNumber) {
    lastSeasons = currentSeason - lastSeasonsNumber;
    for (let i = lastSeasons; i < currentSeason; i += 1) {
      seasonNumbers.push(i);
    }
    return seasonNumbers;
  }

  lastSeasons = currentSeason;
  for (let i = 1; i <= currentSeason; i += 1) {
    seasonNumbers.push(i);
  }
  return seasonNumbers;
};
