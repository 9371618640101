import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import { useSelector } from 'react-redux';

import {
  getTransactionSummary,
  getTeamTotalSummary,
  getLoansAndTransfers,
} from '../../../../redux/selector/Finance';
import { cashFormat } from '../../../../service/functions';
import { transactionTypes } from '../../../../service/data';

import {
  StyledRevenueSection,
  StyledRevenue,
  StyledInfoInRevenue,
  StyledTextArrow,
  StyledMarkedTitle,
  StyledCashValue,
} from './styled';
import { StyledReactTooltip, InfoDarkRelative } from '../../../../globalStyled';

export default function RevenueExpenses() {
  // PropTypes for useSelector

  const propTypes = {
    start_cash: PropTypes.number,
    total_earnings: PropTypes.number,
    total_spendings: PropTypes.number,
    current_cash: PropTypes.number,
  };

  // TODO: Add propTypes like this example to all components with useSelector inside
  const withPropsValidation = (props) => {
    PropTypes.checkPropTypes(propTypes, props, 'prop', '');
    return props;
  };

  const { start_cash, total_earnings, total_spendings, current_cash } =
    withPropsValidation(useSelector(getTeamTotalSummary));

  const transactions = useSelector(getTransactionSummary);
  const loansAndTransfers = useSelector(getLoansAndTransfers);
  const sortedTransactionSummary = orderBy(transactions, ['total'], ['desc']);
  const sortedLoans = orderBy(loansAndTransfers, ['total'], ['desc']);
  return (
    <StyledRevenueSection className="revenues-section">
      <StyledTextArrow>
        <h3 role="presentation">
          Revenues &amp; Expenses{' '}
          <InfoDarkRelative
            iconsize="10"
            data-tip
            clickable="true"
            data-for="revenues-expenses"
          />
        </h3>

        <StyledReactTooltip
          id="revenues-expenses"
          type="light"
          effect="solid"
          place="bottom"
        >
          Summary of your club’s revenues and expenses during the season
        </StyledReactTooltip>
      </StyledTextArrow>
      <StyledRevenue>
        <StyledMarkedTitle>
          <>
            <h3>Starting balance</h3>
            <StyledCashValue>
              {cashFormat(Math.round(start_cash), '$')}
            </StyledCashValue>
          </>
        </StyledMarkedTitle>

        <StyledInfoInRevenue>
          {total_earnings && (
            <h4>
              {isMobile ? 'Operating Rev & Exp' : 'Operating Revenues/Expenses'}
              <span
                data-total-sign={`${
                  total_earnings - total_spendings < 0 ? 'negative' : 'positive'
                }`}
              >
                {cashFormat(Math.round(total_earnings - total_spendings), '$')}
              </span>
            </h4>
          )}
          {sortedTransactionSummary && (
            <ul>
              {sortedTransactionSummary.map(({ total, type }, index) => (
                <li key={index}>
                  <p>{transactionTypes[type]}</p>
                  <span
                    className="money-amount"
                    data-total-sign={`${total < 0 ? 'negative' : 'positive'}`}
                  >
                    {cashFormat(Math.round(total), '$')}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </StyledInfoInRevenue>
        <StyledInfoInRevenue>
          <h4>
            Transfers and loans
            {!isEmpty(sortedLoans) ? (
              <span
                data-total-sign={`${
                  sumBy(sortedLoans, 'total') < 0 ? 'negative' : 'positive'
                }`}
              >
                {cashFormat(Math.round(sumBy(sortedLoans, 'total')), '$')}
              </span>
            ) : (
              <span>-</span>
            )}
          </h4>
          {sortedLoans && (
            <ul>
              {sortedLoans.map(({ total, type }, index) => (
                <li key={index}>
                  <p>{transactionTypes[type]}</p>
                  <span
                    className="money-amount"
                    data-total-sign={`${total < 0 ? 'negative' : 'positive'}`}
                  >
                    {cashFormat(Math.round(total), '$')}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </StyledInfoInRevenue>
        <StyledMarkedTitle>
          <>
            <h3>Ending balance</h3>
            <StyledCashValue>
              {cashFormat(Math.round(current_cash), '$')}
            </StyledCashValue>
          </>
        </StyledMarkedTitle>
      </StyledRevenue>
    </StyledRevenueSection>
  );
}
