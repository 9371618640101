import * as action from '../../actions/Team';
import { handleActions } from '../../helpers/immer';
// import groupBy from "lodash/groupBy";

const initialValue = {
  playerToSell: null,
  seasonNumber: null,
  isPlayerLoading: false,
  areUnsavedChangesAvailable: false,
  myTeam: {
    academyList: [],
    playersListIds: [],
    allSquad: { players: [], seasonNumber: null, academyPlayers: [] },
    loading: false,
    listPlayerLoader: null,
    isReleaseLoading: false,
    effort: null,
    mindset: null,
    tactic: null,
  },
  strategy: {
    currentStrategy: '4-4-2',
    opponentStrategy: '4-4-2',
    isAutoFormatLoading: false,
    opponentName: null,
    allTeam: [],
    majorStaff: [],
    notMajorStaff: [],
    opponentSquad: [],
    upcomingCalendar: null,
    displayedSection: null,
    formationFilter: 'ALL',
    clickedPitchPlayerPosition: null,
    discordChannel: null,
    isOpponentPremium: null,
    teamKit: null,
    opponentKit: null,
    selectedPlayer: {
      index: null,
      type: null,
    },
    tactics: { mindset: 0, effort: 0 },
    empty: {
      name: '-',
      first_name: '-',
      last_name: '-',
      fav_position: '-',
      current_position: '-',
      rating_range: null,
      potential: null,
      potential_range: null,
      form_range: null,
    },
    setTeam: {
      loading: false,
      competeLoading: false,
    },
    loading: true,
  },
  tips: {
    show: false,
  },
};
export const team = handleActions(
  {
    [action.loadPlayerInfo]: (draft) => {
      draft.isPlayerLoading = true;
      draft.playerToSell = null;
    },
    [action.addToList]: (draft, { payload }) => {
      draft.playerToSell = payload.player;
      draft.isPlayerLoading = false;
    },
    [action.removeFromList]: (draft) => {
      draft.playerToSell = null;
    },
    // ------------------SET STRATEGY-------------------
    [action.pickStrategy]: (draft, { payload }) => {
      draft.strategy.currentStrategy = payload.strategy;
      draft.strategy.allTeam = payload.newAll;
      draft.strategy.setTeam.competeLoading = false;
    },
    [action.pickOpponentStrategy]: (draft, { payload }) => {
      draft.strategy.opponentStrategy = payload.strategy;
      draft.strategy.setTeam.competeLoading = false;
    },
    [action.getOpponentStrategy]: (draft, { payload }) => {
      draft.strategy.opponentStrategy = payload.strategy;
    },
    // ------------------GET STRATEGY------------------
    [action.getMyStrategyLoading]: (draft) => {
      draft.strategy.loading = true;
    },
    [action.getMyStrategy]: (draft, { payload }) => {
      draft.strategy.allTeam = payload.allTeam;
      draft.strategy.majorStaff = payload.inMainSquad;
      draft.strategy.notMajorStaff = payload.notInMainSquad;
      draft.strategy.opponentSquad = payload.opponentPlayers;
      draft.strategy.currentStrategy = payload.teamFormation;
      draft.strategy.opponentName = payload.opponentName;
      draft.strategy.opponentStrategy = payload.opponentLastFormation;
      draft.strategy.tactics = payload.tactics;
      draft.strategy.upcomingCalendar = payload.upcomingCalendar;
      draft.strategy.isAutoFormationEnabled = payload.isAutoFormationEnabled;
      draft.strategy.matchRunning = payload.matchRunning;
      draft.strategy.discordChannel = payload.discordChannel;
      draft.strategy.isOpponentPremium = payload.isOpponentPremium;
      draft.strategy.teamKit = payload.teamKit;
      draft.strategy.opponentKit = payload.opponentKit;

      draft.strategy.loading = false;
    },

    [action.setAutoFormationVal]: (draft, { payload }) => {
      draft.strategy.isAutoFormationEnabled = payload;
    },
    [action.autoFormationLoading]: (draft, { payload }) => {
      draft.strategy.isAutoFormatLoading = payload;
    },
    [action.setAutoFormationList]: (draft, { payload }) => {
      draft.strategy.allTeam = payload.allTeam;
      draft.strategy.majorStaff = payload.inMainSquad;
      draft.strategy.notMajorStaff = payload.notInMainSquad;
      draft.strategy.isAutoFormatLoading = false;
    },
    // ------------------REQUEST FOR NEXT MATCH---------
    [action.setRequestForSaveStrategyLoading]: (draft) => {
      draft.strategy.setTeam.loading = true;
    },
    [action.createRequestForSaveStrategy]: (draft, { payload }) => {
      draft.strategy.setTeam.loading = false;
      draft.strategy.setTeam.competeLoading = payload.competeLoading;
      draft.strategy.selectedPlayer = { index: null, type: null };
    },
    [action.updateStrategyData]: (draft) => {
      draft.strategy.setTeam.loading = false;
      draft.strategy.setTeam.competeLoading = false;
    },
    // ------------------TEAM----------------------
    [action.getMyTeamLoading]: (draft) => {
      draft.myTeam.loading = true;
    },
    [action.setUnsavedChanges]: (draft, { payload }) => {
      draft.areUnsavedChangesAvailable = payload;
    },
    [action.setSeason]: (draft, { payload }) => {
      draft.seasonNumber = payload;
    },

    [action.getMyTeam]: (draft, { payload }) => {
      draft.myTeam.allSquad.academyPlayers = payload.academyPlayers;
      draft.myTeam.allSquad.players = payload.allPlayers;
      draft.myTeam.allSquad.seasonNumber = payload.seasonNumber;
      draft.myTeam.loading = false;
      draft.myTeam.academyList = payload.academyList;
    },
    [action.setSelectedListIds]: (draft, { payload }) => {
      draft.myTeam.playersListIds = payload.list;
    },
    [action.removePlayerFromMajorStaff]: (draft, { payload }) => {
      const filteredSquad = draft.strategy.majorStaff.filter(
        (player) => player._id !== payload
      );
      draft.strategy.selectedPlayer.index = null;
      draft.strategy.majorStaff = filteredSquad;
    },
    // draft.strategy.majorStaff[draft.strategy.selectedPlayer.index].id
    // -------------------GET TIPS----------------------

    [action.showExpertTips]: (draft, { payload }) => {
      draft.tips.show = payload.state;
    },
    // ------------------SELECT CURRENT PLAYER-------------
    [action.selectPlayerOnGameField]: (draft, { payload }) => {
      // console.log("Selected ",payload.player)

      const indexInData = draft.strategy.majorStaff.findIndex(
        (element) => element._id === payload.player._id
      );
      // const indexInAll=draft.strategy.allTeam.findIndex(
      //     (element) => element.id === payload.player.id
      //   );
      if (indexInData !== -1) {
        // IF YOU PICK NOT AN EMPTY PLAYER
        // console.log("PICK NOT AN EMPTY PLAYER",indexInData)
        // draft.strategy.allTeam[indexInAll].main=true;
        draft.strategy.selectedPlayer.index = indexInData;
        draft.strategy.selectedPlayer.type = payload.player.current_position;
      } else {
        // console.log("PICK AN EMPTY",payload.player)
        // IF YOU PICK AN EMPTY PLAYER
        draft.strategy.selectedPlayer.index = null;
        draft.strategy.selectedPlayer.type = payload.player.current_position;
      }
      // if (payload.player.data) {
      // const indexInData = draft.myTeam.allSquad.findIndex(
      //   (element) => element.name === payload.player.data.name
      // );
      // if (indexInData !== -1) draft.myTeam.selectedPlayer.index = indexInData;
      // } else {
      //   draft.myTeam.selectedPlayer.index = null;
      // }
      // draft.myTeam.selectedPlayer.type = payload.player.type;
    },
    // -----------------REPLACE PLAYER IN MAIN TEAM ARRAY------
    [action.clickedPitchPlayerPosition]: (draft, { payload }) => {
      if (payload === null) {
        draft.strategy.clickedPitchPlayerPosition = null;
      } else {
        draft.strategy.clickedPitchPlayerPosition = payload;
      }
    },
    [action.replacePlayerFromTheirPosition]: (draft, { payload }) => {
      const indexOfGivenPlayer = draft.strategy.allTeam.findIndex(
        (player) => player._id === payload.player._id
      );
      draft.strategy.allTeam[indexOfGivenPlayer].main = true;
      // console.log(indexInAll,indexOfGivenPlayer,draft.strategy.allTeam[indexInAll])
      const { ...resPayloadPlayer } = payload.player;
      if (indexOfGivenPlayer !== -1) {
        // RIGHT NOW WE HAVE 2 SITUATION. WHEN USER HAS PLAYER ON THIS POSITION AND WHEN POSITION WAS EMPTY.
        if (Number.isInteger(draft.strategy.selectedPlayer.index)) {
          const indexInAll = draft.strategy.allTeam.findIndex(
            (player) =>
              player._id ===
              draft.strategy.majorStaff[draft.strategy.selectedPlayer.index]._id
          );
          draft.strategy.allTeam[indexInAll].main = false;
          // THIS IS CODE FOR SITUATION WHEN WE HAVE PLAYER ON THE FIELD AND WE NEED TO REPLACE IT. I MAKE IT CROSSWISE
          const indexOfFoundedSuchElement = draft.strategy.majorStaff.findIndex(
            (player) => player._id === payload.player._id
          );
          draft.strategy.majorStaff[draft.strategy.selectedPlayer.index] = {
            ...resPayloadPlayer,
            current_position: draft.strategy.selectedPlayer.type,
          };
          // IF WE WOULD LIKE TO REPLACE CURRENT PLAYER WITH SOMEONE WHO ALREADY BE ON THE GAME FIELD - I REMOVE PLAYER THAT PRESENTED ON THE FIELD
          if (
            indexOfFoundedSuchElement !== -1 &&
            indexOfFoundedSuchElement !== draft.strategy.selectedPlayer.index
          ) {
            draft.strategy.majorStaff.splice(indexOfFoundedSuchElement, 1);
          }
          const newIndexOfPayload = draft.strategy.majorStaff.findIndex(
            (player) => player._id === payload.player._id
          );
          if (newIndexOfPayload !== -1) {
            draft.strategy.selectedPlayer.index = newIndexOfPayload;
          }
        } else {
          // THIS IS CODE FOR SITUATION WHEN WE DON'T HAVE PLAYER ON THE FIELD AND WE NEED TO PLACE IT.
          const indexOfFoundedSuchElement = draft.strategy.majorStaff.findIndex(
            (player) => player._id === payload.player._id
          );
          // ADD GIVEN USER FOR MAIN SQUAD;
          draft.strategy.majorStaff.push({
            ...resPayloadPlayer,
            current_position: draft.strategy.selectedPlayer.type,
          });
          if (
            indexOfFoundedSuchElement !== -1 &&
            indexOfFoundedSuchElement !== draft.strategy.selectedPlayer.index
          ) {
            draft.strategy.majorStaff.splice(indexOfFoundedSuchElement, 1);
          }
          // SET INFORMATION ABOUT WHICH USER BECOME SELECTED
          const newIndexOfPayload = draft.strategy.majorStaff.findIndex(
            (player) => player._id === payload.player._id
          );
          if (newIndexOfPayload !== -1) {
            draft.strategy.selectedPlayer.index = newIndexOfPayload;
          }
        }
      }
      // const indexOfGivenPlayer = draft.strategy.notMajorStaff.findIndex(
      //   (player) => player.id === payload.player.id
      // );
      // if (indexOfGivenPlayer !== -1) {
      //   //RIGHT NOW WE HAVE 2 SITUATION. WHEN USER HAS PLAYER ON THIS POSITION AND WHEN POSITION WAS EMPTY.
      //   if (Number.isInteger(draft.strategy.selectedPlayer.index)) {
      //     //THIS IS CODE FOR SITUATION WHEN WE HAVE PLAYER ON THE FIELD AND WE NEED TO REPLACE IT. I MAKE IT CROSSWISE
      //     draft.strategy.notMajorStaff[indexOfGivenPlayer] =
      //       draft.strategy.majorStaff[draft.strategy.selectedPlayer.index];
      //     draft.strategy.majorStaff[draft.strategy.selectedPlayer.index] =
      //       payload.player;
      //   } else {
      //     //THIS IS CODE FOR SITUATION WHEN WE DON'T HAVE PLAYER ON THE FIELD AND WE NEED TO PLACE IT.
      //     //REMOVE USER FROM NOT MAJOR SQUAD
      //     draft.strategy.notMajorStaff.splice(indexOfGivenPlayer, 1);
      //     //ADD GIVEN USER FOR MAIN SQUAD;
      //     draft.strategy.majorStaff.push(payload.player);
      //     //SET INFORMATION ABOUT WHICH USER BECOME SELECTED
      //     draft.strategy.selectedPlayer.index =
      //       draft.strategy.majorStaff.length - 1;
      //   }
      // }
    },
    // -----------------LIST PLAYER LOGIC------------------
    [action.listPlayerLoader]: (draft) => {
      draft.myTeam.listPlayerLoader = true;
      draft.playerToSell.isLoading = true;
    },
    [action.listPlayer]: (draft, { payload }) => {
      if (payload.playerID) {
        const indexInData = draft.myTeam.allSquad.players.findIndex(
          ({ _id }) => _id === payload.playerID
        );
        if (indexInData !== -1) {
          draft.myTeam.allSquad.players[indexInData].listed = payload.status;
          draft.playerToSell.listed = payload.status;
          draft.myTeam.listPlayerLoader = false;
          draft.playerToSell.isLoading = false;
        }
      }
    },
    [action.listPlayerError]: (draft) => {
      draft.myTeam.listPlayerLoader = false;
      draft.playerToSell.isLoading = false;
    },
    [action.releasePlayerLoading]: (draft, { payload }) => {
      draft.myTeam.isReleaseLoading = payload;
    },
    [action.removePlayer]: (draft, { payload }) => {
      const newData = draft.myTeam.allSquad.players.filter(
        (player) => player._id !== payload
      );
      draft.myTeam.allSquad.players = newData;
    },
    // Formation save
    [action.setTactic]: (draft, { payload }) => {
      draft.myTeam.tactic = payload;
    },
    [action.setMindset]: (draft, { payload }) => {
      draft.strategy.tactics.mindset = payload;
    },
    [action.setEffort]: (draft, { payload }) => {
      draft.strategy.tactics.effort = payload;
    },
    [action.setFormationFilter]: (draft, { payload }) => {
      draft.strategy.formationFilter = payload;
    },
    // Mobile team sidebar
    [action.showMobileSection]: (draft, { payload }) => {
      draft.strategy.displayedSection = payload;
    },
    // Player Edit
    [action.editSelectedPlayerData]: (draft, { payload }) => {
      // Make this change on UI to be aligned with actual BE data until user wil reload page
      const newData = draft.myTeam.allSquad.players.map((player) =>
        player._id === payload.id
          ? { ...player, ...payload.dataToChange }
          : player
      );
      draft.myTeam.allSquad.players = newData;
      const updatedPlayerToSell = {
        ...draft.playerToSell,
        ...payload.dataToChange,
      };
      draft.playerToSell = updatedPlayerToSell;
    },
    [action.setToAcademyAction]: (draft, { payload }) => {
      draft.myTeam.academyList = [...draft.myTeam.academyList, payload];
    },
  },
  initialValue
);
