import React from 'react';
import ContentLoader from 'react-content-loader';

const CalendarPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={260}
    height={202}
    viewBox="0 0 260 202"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="24" y="49" rx="4" ry="4" width="210" height="38" />
    <rect x="25" y="20" rx="0" ry="0" width="121" height="18" />
    <rect x="25" y="100" rx="4" ry="4" width="210" height="38" />
    <rect x="25" y="150" rx="4" ry="4" width="210" height="38" />
  </ContentLoader>
);
export default CalendarPreloader;
