import styled, { css } from 'styled-components';
import { StyledButton } from '../../globalStyled';

export const StyledFormContainer = styled.div`
  position: relative;
  background-color: #fff;
  max-width: 500px;
  padding: 60px;
  border-radius: 6px;
  @media only screen and (max-width: 768px) {
    background-color: #efefef;
    ${({ bg }) => {
      if (bg) {
        return css`
          background-color: ${bg};
        `;
      }
    }}
    padding: 45px;
    height: 440px;
    margin: 0 20px;
  }
`;

export const StyledFormHeader = styled.h2`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  line-height: 48px;
  letter-spacing: 0.02em;
  color: #000;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 0px;
  text-transform: none;
  text-align: center;
`;

export const StyledFormParagraph = styled.p`
  font-family: Gilroy;
  font-size: 15px;
  line-height: 18px;
  font-weight: normal;
  color: #000000;
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const StyledBidContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export const StyledFormButton = styled(StyledButton)`
  width: 100%;
  max-width: 210px;
  height: 45px;
  font-size: 16px;
  margin: 30px auto 25px auto;
  &:disabled {
    background-color: #ebebeb;
    color: #989898;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  }
`;

export const StyledNumberScout = styled.p`
  font-size: 13px;
  text-align: center;
  span {
    font-weight: bold;
    font-size: 16px;
  }
`;
