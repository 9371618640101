import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';

export const getUserFromTeam = (state) => state.team;

export const getUnsavedChanges = createSelector(
  getUserFromTeam,
  ({ areUnsavedChangesAvailable }) => areUnsavedChangesAvailable
);
export const playerFromList = createSelector(
  getUserFromTeam,
  ({ playerToSell: _playerToSell }) => _playerToSell
);

export const getSeasonNumber = createSelector(
  getUserFromTeam,
  ({ seasonNumber }) => seasonNumber
);
export const isPlayerDataLoading = createSelector(
  getUserFromTeam,
  ({ isPlayerLoading }) => isPlayerLoading
);
export const isOpponentUserPremium = createSelector(
  getUserFromTeam,
  ({ isOpponentPremium }) => isOpponentPremium
);

// --------------------SET STRATEGY-------------------------

export const getTeamStrategy = (state) => state.team.strategy;
export const getClickedPositionOnPitch = createSelector(
  getTeamStrategy,
  ({ clickedPitchPlayerPosition }) => clickedPitchPlayerPosition
);
export const getOpponentTeamName = createSelector(
  getTeamStrategy,
  ({ opponentName }) => opponentName
);
export const isFormationLoading = createSelector(
  getTeamStrategy,
  ({ isAutoFormatLoading }) => isAutoFormatLoading
);
export const getCurrentStrategy = createSelector(
  getTeamStrategy,
  ({ currentStrategy }) => currentStrategy
);
export const getStrategyTactics = createSelector(
  getTeamStrategy,
  ({ tactics }) => tactics
);
export const getOpponentStrategy = createSelector(
  getTeamStrategy,
  ({ opponentStrategy }) => opponentStrategy
);
export const MyAndOtherStrategyExist = createSelector(
  getTeamStrategy,
  ({ currentStrategy, opponentStrategy }) => currentStrategy || opponentStrategy
);
export const getMyMajorSquad = createSelector(
  getTeamStrategy,
  ({ majorStaff }) => majorStaff
);
export const getOpponentSquad = createSelector(
  getTeamStrategy,
  ({ opponentSquad }) => opponentSquad
);
export const getPlayersForReplace = createSelector(
  getTeamStrategy,
  ({ notMajorStaff }) => notMajorStaff
);
export const isHaveMyAndOpponentSquad = createSelector(
  getTeamStrategy,
  ({ opponentSquad, majorStaff }) =>
    majorStaff.length > 0 && opponentSquad.length > 0
);
export const isStrategyLoading = createSelector(
  getTeamStrategy,
  ({ loading }) => loading
);
export const getSelectedPlayer = createSelector(
  getTeamStrategy,
  ({ selectedPlayer: { index }, majorStaff, empty }) =>
    majorStaff[index] || empty
);
export const selectedPlayerPosition = createSelector(
  getTeamStrategy,
  ({ selectedPlayer }) => selectedPlayer.type
);

export const getPlayersForCurrentPosition = createSelector(
  getTeamStrategy,
  ({ allTeam }) => allTeam
);
export const isCreatingNextMatch = createSelector(
  getTeamStrategy,
  ({ setTeam: { loading } }) => loading
);
export const isCreatedNextMatch = createSelector(
  getTeamStrategy,
  ({ setTeam: { competeLoading } }) => competeLoading
);
export const upcomingCalendarList = createSelector(
  getTeamStrategy,
  ({ upcomingCalendar }) => upcomingCalendar
);
export const getTeamKit = createSelector(
  getTeamStrategy,
  ({ teamKit }) => teamKit
);
export const getOpponentKit = createSelector(
  getTeamStrategy,
  ({ opponentKit }) => opponentKit
);
export const isAutoFormatEnabled = createSelector(
  getTeamStrategy,
  ({ isAutoFormationEnabled }) => isAutoFormationEnabled
);
export const sectionNameToDisplay = createSelector(
  getTeamStrategy,
  ({ displayedSection }) => displayedSection
);
export const getFormationFilter = createSelector(
  getTeamStrategy,
  ({ formationFilter }) => formationFilter
);
export const getCurrentMatchRunning = createSelector(
  getTeamStrategy,
  ({ matchRunning }) => matchRunning
);
export const getCurrrentMatchDiscordChannel = createSelector(
  getTeamStrategy,
  ({ discordChannel }) => discordChannel
);

// ----------------------GET TEAM-----------------
export const getTeamSquad = (state) => state.team.myTeam;

export const getPlayersListIds = createSelector(
  getTeamSquad,
  ({ playersListIds }) => playersListIds
);
export const getMyAllSquad = createSelector(
  getTeamSquad,
  ({ allSquad }) => allSquad.players
);

export const getPlayerById = (id) =>
  createSelector(getTeamSquad, ({ allSquad }) =>
    allSquad.players.find((player) => player._id === id)
  );

export const getMyOtherPlayers = createSelector(
  getTeamSquad,
  ({ allSquad }) => groupBy(allSquad.players, 'status')['0']
);
export const myTeamLoading = createSelector(
  getTeamSquad,
  ({ loading }) => loading
);
export const myTeamMindset = createSelector(
  getTeamSquad,
  ({ mindset }) => mindset
);
export const myTeamEffort = createSelector(
  getTeamSquad,
  ({ effort }) => effort
);
export const myTeamTactic = createSelector(
  getTeamSquad,
  ({ tactic }) => tactic
);

export const getAcademyList = createSelector(
  getTeamSquad,
  ({ academyList }) => academyList
);

// ------------------GET TIPS------------------------
export const getTeamTips = (state) => state.team.tips;
