import React from 'react';
import usePortal from 'react-useportal';
import { useSelector } from 'react-redux';
import {
  StyledButtonContainer,
  StyledSelectContainer,
  StyledActionSelect,
} from './styled';
import { getPopupByName } from '../../../../redux/selector/Popups';
import { POPUPS } from '../../../../service/constants';
import { ContractOfferPopup } from '../../../../components/Popup';

const ActionCell = ({ cell, openSignPopup, openLoanPopup, openScoutPopup }) => {
  const contractOfferPopupData = useSelector(
    getPopupByName(POPUPS.CONTRACT_OFFER_POPUP)
  );
  const { _id, teamName, ratingId, salary } = cell.row.original;
  const isFreeAgent = cell.row.original.teamName === 'Free agent';
  const isActionsDisabled = cell.row.original?.academy?.status === 1;
  const options = [
    {
      label: `${isFreeAgent ? 'Sign free agent' : 'Buy offer'}`,
      value: 'buyOffer',
    },
    isFreeAgent
      ? { label: 'Scout', value: 'scoutRequest' }
      : { label: 'Loan Offer', value: 'loanOffer' },
  ];
  const openWindow = (e) => {
    const requestName = e.value;
    if (requestName === 'buyOffer') {
      openSignPopup();
    }
    if (requestName === 'loanOffer') {
      openLoanPopup();
    }
    if (requestName === 'scoutRequest') {
      openScoutPopup();
    }
    if (requestName === 'managerInfo') {
      window.open(
        `https://discordapp.com/users/${cell.row.original.managerId}`,
        '_blank'
      );
    }
  };
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  return (
    <StyledButtonContainer>
      {contractOfferPopupData && contractOfferPopupData.id === _id &&
        <Portal>
          <ContractOfferPopup
            isExtended={false}
            popupName={POPUPS.CONTRACT_OFFER_POPUP}
            id={_id}
            team={teamName}
            ratingRange={ratingId}
            isFreeAgentPopup
            salary={salary}
          />
        </Portal>}
      <StyledSelectContainer className="action-cell">
        <StyledActionSelect
          isDisabled={isActionsDisabled}
          value={null}
          isOptionDisabled={(option) => option.disabled}
          onChange={(e) => openWindow(e)}
          options={options}
          isSearchable={false}
          menuPlacement="auto"
          placeholder="Action"
          classNamePrefix="react-select"
        />
      </StyledSelectContainer>
    </StyledButtonContainer>
  );
};
export default React.memo(ActionCell);
