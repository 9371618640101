import { createAction } from 'redux-act';
import isEmpty from 'lodash/isEmpty';
import { getHomeData } from '../../../api/home';

export const showTips = createAction('show tips from home page');

export const homeShowTips = (state) => async (dispatch) => {
  dispatch(showTips({ state }));
};

// ----------------------GET DATA FROM BACKEND-------------------------------

export const getHomeInitialData = createAction(
  'get initial data for home page'
);
export const homeInitialDataLoading = createAction(
  'set loading state for loaded data'
);
export const homeGetHomeInitialData = () => async (dispatch) => {
  dispatch(homeInitialDataLoading());
  const response1 = await getHomeData();
  // alert(`RESPONSE STATUS FOR HOME INITIAL DATA - ${response1.status}`);
  if (response1.status === 200) {
    let {
      last_match,
      next_match,
      symbol,
      teamName,
      ranking,
      current_cup_ranking,
      current_season_ranking,
      best_cup_ranking,
      best_season_ranking,
      players_avg_rating,
      leagueName,
      timezone,
      inServer,
      teamId,
      cash_summary: { current_cash },
      discordChannel,
      matchRunning,
      opponentTeamPremium,
      teamKit,
      opponentKit,
    } = await response1.json();
    current_cash = Math.round(current_cash);
    let learning = null;
    if (
      !isEmpty(last_match?.away_learnings) ||
      !isEmpty(last_match?.home_learnings)
    ) {
      learning = {
        awayLearnings: last_match.away_learnings,
        homeLearnings: last_match.home_learnings,
      };
    }
    dispatch(
      getHomeInitialData({
        rankingTable: ranking,
        last_match,
        next_match,
        current_cash,
        learning,
        symbol,
        teamName,
        current_cup_ranking,
        current_season_ranking,
        best_cup_ranking,
        best_season_ranking,
        players_avg_rating,
        leagueName,
        timezone,
        inServer,
        teamId,
        discordChannel,
        matchRunning,
        opponentTeamPremium,
        teamKit,
        opponentKit,
      })
    );
  }
};
