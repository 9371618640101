import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Adsense } from '@ctrl/react-adsense';
import { RampUnit } from "@playwire/pw-react-component";
import { useLocation } from 'react-router-dom';
import { DndContext } from '@dnd-kit/core';
import { StyledContainer, StyledAdsContainer } from './styled';
import GameFields from './components/GameFields 3.0';
import {
  isStrategyLoading,
  getMyMajorSquad,
  getCurrentStrategy,
} from '../../../../redux/selector/Team';

import {
  teamGetMyStrategy,
  removePlayerFromStaff,
} from '../../../../redux/actions/Team';
// import { adsClickRequest } from '../../../../redux/actions/ads';
import { allowTour } from '../../../../redux/actions/TourControl';
import { getTourState } from '../../../../redux/selector/TourControl';
import { isPremiumUser } from '../../../../redux/selector/User';
import { tourStorageValue } from '../../../../helpers/tour/locationToStorage';
import ReplacePlayer from '../../../../components/SideBar/components/RightSideBlock/pages/TeamStrategy/component/ReplacePlayer';
import SelectFormation from './components/SelectFormation';

export default function StrategyContainer() {
  const [activeDraggingId, setActiveDraggingId] = React.useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const isPremium = useSelector(isPremiumUser);
  const isLoad = useSelector(isStrategyLoading);
  const MyTeam = useSelector(getMyMajorSquad);
  const MyStrategy = useSelector(getCurrentStrategy);
  const isTourStart = useSelector(getTourState);

  const removePlayerFromSquad = (id) => {
    dispatch(removePlayerFromStaff(id));
  };

  useEffect(() => {
    dispatch(teamGetMyStrategy());
  }, [dispatch]);
  const onDragStart = ({ active }) => {
    setActiveDraggingId(active.id);
  };
  const onDragEnd = ({ active, over }) => {
    if (active.id && !over?.id) {
      setActiveDraggingId(null);
      removePlayerFromSquad(active.id);
    }
    setActiveDraggingId(null);
  };

  useEffect(() => {
    const activeLocalStorageTourState = localStorage.getItem(
      tourStorageValue[location.pathname]
    );
    if (!isLoad && !isTourStart && !activeLocalStorageTourState) {
      dispatch(allowTour(true));
    }
  }, [isLoad, isTourStart, dispatch, location.pathname]);

  /*   useEffect(() => {
    const handleAdsClick = () => {
      if (document.activeElement === document.querySelector('iframe')) {
        dispatch(adsClickRequest());
      }
    };

    window.addEventListener('blur', handleAdsClick);
    return () => {
      window.removeEventListener('blur', handleAdsClick);
    };
  }, []); */

  return (
    <>
      <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <StyledContainer>
            <SelectFormation isLoad={isLoad} />
            <GameFields
              MyStrategy={MyStrategy}
              MyTeam={MyTeam}
              activeDraggingId={activeDraggingId}
            />
            <ReplacePlayer
              isLoad={isLoad}
              activeDraggingId={activeDraggingId}
            />
          </StyledContainer>
{/*           {!isPremium && (<RampUnit
          type="dfp-med-rect"
          cssClass="leaderboard"
        />)} */}
{/*           {!isPremium && (
            <StyledAdsContainer>
              <Adsense
                client={`${!isPremium ? 'ca-pub-2739224896366063' : ''}`}
                slot={`${!isPremium ? '9437045662' : ''}`}
                style={{
                  width: '100%',
                  height: `${!isPremium ? 300 : 0}`,
                  display: `${!isPremium ? 'flex' : 'none'}`,
                  flexDirection: 'center',
                  marginTop: '20px',
                }}
                layout="in-article"
                format="fluid"
              />
            </StyledAdsContainer>
          )} */}
        </div>
      </DndContext>
    </>
  );
}
