import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from './CustomTooltip';
import { digitFormater } from '../../service/functions';
import { StyledChartContainer } from './styled';
import { StyledReactTooltip, InfoDarkRelative } from '../../globalStyled';

export const FinanceChart = ({ chartData }) => {
  const axisTickDrawer = (currentIndex, indexOfToday) => {
    const weeks = ['1w', '2w', '3w'];

    if (
      currentIndex === indexOfToday + 7 ||
      currentIndex === indexOfToday - 7
    ) {
      return weeks[0];
    }
    if (
      currentIndex === indexOfToday + 14 ||
      currentIndex === indexOfToday - 14
    ) {
      return weeks[1];
    }
    if (
      currentIndex === indexOfToday + 21 ||
      currentIndex === indexOfToday - 21
    ) {
      return weeks[2];
    }
    return '';
  };

  const amountSetter = (chartElement, todayDate) =>
    moment(chartElement.date).isBefore(todayDate, 'day')
      ? chartElement.amount.toFixed()
      : undefined;

  const predictionSetter = (chartElement, todayDate) =>
    moment(chartElement.date).isAfter(todayDate, 'day')
      ? chartElement.amount.toFixed()
      : undefined;

  const chartDateFormatter = (chartInfo) => {
    const todayDate = moment().toDate();
    todayDate.setHours(6);
    todayDate.setMinutes(0);
    const formattedList = [
      ...chartInfo.past,
      {
        amount: chartInfo.current.toFixed(),
        prediction: chartInfo.current.toFixed(),
        date: moment(todayDate),
        tick: 'Today',
      },
      ...chartInfo.future,
    ];

    const indexOfToday = formattedList.findIndex((i) => i.tick === 'Today');

    const finalArr = [];

    formattedList.forEach((chartElement, index) => {
      if (chartElement.tick !== 'Today') {
        if (moment(chartElement.date).isSame(todayDate, 'day')) {
          return null;
        }
        finalArr.push({
          tick: axisTickDrawer(index, indexOfToday),
          yAxisValues: chartElement.amount,
          date: chartElement.date,
          amount: amountSetter(chartElement, todayDate),
          prediction: predictionSetter(chartElement, todayDate),
        });
      } else {
        finalArr.push(chartElement);
      }
    });
    return finalArr;
  };
  const moneyFormatter = (val) => `$${digitFormater(val, true)}`;

  return (
    <StyledChartContainer className="cash-balance-section">
      <h3>
        Cash Balance
        <InfoDarkRelative
          iconsize="10"
          data-tip
          clickable="true"
          data-for="cash-balance"
        />
      </h3>

      <StyledReactTooltip
        id="cash-balance"
        type="light"
        effect="solid"
        place="bottom"
      >
        This chart shows your past cash balance as well as your forecast balance
      </StyledReactTooltip>
      {!isEmpty(chartData) && (
        <ResponsiveContainer width="100%" height={210}>
          <LineChart
            data={chartDateFormatter(chartData)}
            margin={{
              top: 10,
              right: 30,
              bottom: 10,
              left: 0,
            }}
          >
            <XAxis
              stroke="#ccc"
              interval={0}
              dataKey="tick"
              tickCount={7}
              axisLine={false}
              tickLine={false}
              width={120}
              styles={{ fontSize: '10px' }}
              padding="gap"
            />
            <YAxis
              type="number"
              tickCount={6}
              ticks={6}
              domain={[(minValue) => minValue * 0.9, 'auto']}
              stroke="#ccc"
              hasTick
              axisLine={false}
              tickLine={false}
              tickFormatter={moneyFormatter}
              dataKey="yAxisValues"
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="amount"
              strokeWidth={3}
              stroke="#000"
              dot={false}
              activeDot={{ stroke: '#000', fill: '#fff', strokeWidth: 3, r: 6 }}
            />
            <Line
              type="monotone"
              dataKey="prediction"
              strokeWidth={3}
              stroke="#000"
              strokeDasharray="2 5"
              dot={false}
              activeDot={{ fill: '#fff', stroke: '#000', strokeWidth: 3, r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </StyledChartContainer>
  );
};

export default FinanceChart;
