import React from 'react';
import { useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import {
  StyledGroupContainer,
  StyledName,
  StyledGoals,
  StyledNoWinner,
} from './styled';
import { showMobileSidebarSection } from '../../../redux/actions/Team';
import { leagueSetTeamInfo } from '../../../redux/actions/League';
import { POPUPS } from '../../../service/constants';
import { showPopupWindow } from '../../../redux/actions/Popups';

const GroupTeamDesktop = ({ teamData = {}, shortVersion = false, side }) => {
  const dispatch = useDispatch();
  const { symbol = '', name = '', score = null } = teamData;
  const notScored = score === -1 || score === null;
  const showName = name && !shortVersion;
  const showTeamInfo = (id) => {
    const { TEAM_INFO_POPUP } = POPUPS;
    dispatch(leagueSetTeamInfo({ _id: id }, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
    }
  };
  return (
    <StyledGroupContainer shortVersion={shortVersion} side={side}>
      <StyledName
        onClick={() => showTeamInfo(teamData._id)}
        shortVersion={shortVersion}
      >
        {symbol ? <img src={symbol} alt="team logo" /> : <StyledNoWinner />}
        {showName ? <span>{name}</span> : <></>}
      </StyledName>
      <StyledGoals goals={score} shortVersion={shortVersion} side={side}>
        {notScored ? '?' : score}
      </StyledGoals>
    </StyledGroupContainer>
  );
};

export default GroupTeamDesktop;
