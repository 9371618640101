import styled, { css } from 'styled-components';

export const StyledToggleContainer = styled.div`
  width: 45px;
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 43px;
  height: 22px;
  border-radius: 100px;
  background-color: #999999;
  position: relative;
  transition: background-color 0.2s;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &:before {
    ${({ viewMode }) => {
      if (viewMode) {
        return css`
          content: 'OFF';
          color: #fff;
          font-size: 8px;
          position: absolute;
          right: 5px;
          top: 8px;
        `;
      }
    }}
    ${({ isOnAndOffLabelVisible, toggled }) => {
      if (isOnAndOffLabelVisible && toggled) {
        return css`
          color: #fff;
          font-size: 8px;
          position: absolute;
          left: 5px;
          top: 7px;
          content: 'ON';
        `;
      }

      return css`
        color: #fff;
        font-size: 8px;
        position: absolute;
        right: 5px;
        top: 7px;
        content: 'OFF';
      `;
    }}
  }
`;

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  &:checked + ${SwitchLabel} {
    background: ${({ viewMode }) => (viewMode ? '#999' : '#000')};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 21px;
      transition: 0.2s;
    }
    &:before {
      ${({ viewMode }) => {
        if (viewMode) {
          return css`
            content: 'ON';
            color: #fff;
            font-size: 8px;
            position: absolute;
            left: 5px;
          `;
        }
      }}
    }
  }
`;

export const SwitchButton = styled.span`
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: 0.2s;
  background: #fff;
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
`;
