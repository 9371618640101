import React from 'react';
import {
  StyledWrapper,
  StyledColumnItem,
  StyledGridItem,
  StyledContainer,
  StyledColumnCell,
  StyledRowLabel,
  StyledColLabel,
  StyledReactTooltip,
} from './styled';
import { averageRatingVal } from '../../../service/data';
import { drawGridCell, getLastSeasons } from './helpers';

const GridChart = ({ seasonNumber, data, currentRating }) => {
  const [gridData, setGridData] = React.useState([]);
  const setInactiveSeasons = React.useCallback(
    (chartData) => {
      const newData = [];
      if (chartData.length <= 5) {
        const seasonLength = seasonNumber > 5 ? 5 : seasonNumber;
        const seasonArr = getLastSeasons(seasonNumber, 4);
        for (let i = 1; i <= 5; i += 1) {
          if (i === seasonLength) {
            newData.push({
              value: averageRatingVal[currentRating],
              label: 'Now',
            });
          }
          if (i > seasonLength) {
            newData.push({ value: 'n/a', label: 'n/a' });
          }
          if (i < seasonLength) {
            newData.unshift({
              value: chartData[i - 1],
              label: `S${seasonArr[seasonLength - i - 1]}`,
            });
          }
        }
      }
      setGridData(newData);
    },
    [seasonNumber, currentRating]
  );
  React.useEffect(() => {
    if (data.length > 4) {
      const splicedData = data.slice(-4);
      setInactiveSeasons(splicedData);
    } else {
      setInactiveSeasons(data);
    }
    return () => {
      setGridData([]);
    };
  }, [data, setInactiveSeasons]);
  const drawVertical = (item, seasonIndex) => {
    const colorResult = drawGridCell(item.value);
    const arr = [
      'Legend',
      'World Class',
      'Strong',
      'Average',
      'Weak',
      'Weakest',
    ];

    return (
      <>
        {arr.map((i, index) => (
          <StyledColumnCell key={`${i}${index}`}>
            {seasonIndex === 0 && (
              <>
                <StyledRowLabel data-tip clickable data-for={i}>
                  {i[0]}
                </StyledRowLabel>
                <StyledReactTooltip
                  id={i}
                  type="dark"
                  effect="solid"
                  place="right"
                >
                  {i}
                </StyledReactTooltip>
              </>
            )}
            {!colorResult ? (
              <StyledGridItem className="item" bg="#f6f4f4" />
            ) : (
              <StyledGridItem
                key={`${colorResult.color}${colorResult.index}`}
                className="item"
                bg={colorResult.index === index ? colorResult.color : '#f6f4f4'}
              />
            )}
          </StyledColumnCell>
        ))}
        <StyledColLabel>{item.label}</StyledColLabel>
      </>
    );
  };
  return (
    <StyledWrapper>
      <StyledContainer>
        {gridData.map((i, index) => (
          <StyledColumnItem key={index} className="column-item">
            {drawVertical(i, index)}
          </StyledColumnItem>
        ))}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default GridChart;
