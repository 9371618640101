import { combineReducers } from 'redux';

import { user } from './User';
import { counter } from './Counter';
import { cash } from './CurrentCash';
import { sidebar } from './Sidebar';
import { team } from './Team';
import { transfers } from './Transfers';
import { league } from './League';
import { home } from './Home';
import { finance } from './Finance';
import { popups } from './Popups';
import { signUp } from './SignUp';
import { youthCamp } from './youthCamp';
import { domesticCup } from './DomesticCup';
import { tourControl } from './TourControl';
import { tasks } from './Tasks';
import { friendly } from './Friendly';
import { training } from './Training';
import { stuff } from './Stuff';

export const rootReducer = combineReducers({
  team,
  home,
  user,
  counter,
  league,
  cash,
  sidebar,
  transfers,
  finance,
  popups,
  signUp,
  youthCamp,
  domesticCup,
  tourControl,
  tasks,
  friendly,
  training,
  stuff,
});
