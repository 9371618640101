import styled from 'styled-components';

export const StyledRadioButtonLabel = styled.label`
  cursor: pointer;
  padding-left: 30px;
`;

export const Root = styled.div`
  margin: 5px;
  width: 100%;
  height: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &::before {
    content: '';
    border-radius: 100%;
    border: 2px solid ${(props) => (props.checked ? '#000' : '#eee')};
    background: #fff;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

export const StyledFill = styled.div`
  background: #000;
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 12px;
  left: 12px;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    opacity: 0;
    width: 11px;
    position: absolute;
    height: 11px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #000;
    border-radius: 100%;
  }
`;

export const StyledRadioButton = styled.input`
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  margin: 0;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${StyledFill} {
      width: 14px;
      height: 14px;
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;
