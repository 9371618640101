import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/User';

const initialValue = {
  discord: null,
  email: null,
  password: null,
  team_info: null,
  username: null,
  avatarID: null,
  isAuth: null,
  isAuthLikeWithoutTeam: null,
  leagueName: null,
  loader: false,
  leaveTeam: null,
  isLoggingOut: false,
  isPremium: null,
};
export const user = handleActions(
  {
    [action.login]: (draft, { payload }) => {
      draft.isAuth = payload.isAuth;
      draft.discord = payload.discord;
      draft.team_info = payload.team_info;
      draft.username = payload.username;
      draft.avatarID = payload.avatarID;
      draft.isPremium = payload.isPremium;
    },
    [action.loginTeam]: (draft, { payload }) => {
      draft.isAuth = payload.isAuth;
      draft.team_info = payload.team_info;
      draft.isAuthLikeWithoutTeam = false;
    },
    [action.loggedAsWaited]: (draft, { payload }) => {
      draft.isAuthLikeWithoutTeam = payload.isAuthLikeWithoutTeam;
      draft.discord = payload.discord;
    },
    [action.isLoggingOut]: (draft, { payload }) => {
      draft.isLoggingOut = payload;
    },
    [action.logout]: (draft) => {
      draft.discord = null;
      draft.email = null;
      draft.team_info = null;
      draft.username = null;
      draft.avatarID = null;
      draft.isAuth = null;
      draft.isAuthLikeWithoutTeam = null;
      draft.leagueName = null;
      draft.isPremium = false;
    },
    [action.testAuthLoader]: (draft, { payload }) => {
      draft.loader = payload.loader;
    },
    [action.testAuthUser]: (draft, { payload }) => {
      draft.isAuth = payload.isAuth;
      draft.discord = payload.discord;
      draft.team_info = payload.team_info;
    },
    [action.setLeagueName]: (draft, { payload }) => {
      draft.leagueName = payload.code;
    },
    [action.leaveTeam]: () => initialValue,
  },
  initialValue
);
