import styled from 'styled-components';
import { StyledPopUpBlock } from '../styled';

export const StyledPopupHeader = styled.div`
  background-color: #f7f7f7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0px 30px;
  z-index: 1;
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  img {
    max-width: 50px;
    width: auto;
    height: auto;
    max-height: 50px;
  }
`;

export const StyledPopUpBlockNoPadding = styled(StyledPopUpBlock)`
  width: 340px;
  padding: 0;
`;
export const StyledParagraph = styled.p`
  font-family: 'Gilroy';
  font-size: 15px;
  line-height: 26px;
  padding: 30px;
`;
