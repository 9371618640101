import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as YouthCampLogo } from '../../assets/img/youth-camp-logo.svg';

export const StyledLobbyBtn = styled.div`
  position: relative;
  background-color: #151515;
  width: 50px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    height: 50px;
}
  svg {
      z-index: 1;
    path {
      fill: transparent;
      stroke: white;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    /*    max-width: 500px; */
    width: 90px;
    height: 120px;
    background-color: #151515;
    transform: skew(-25deg, 0);
    z-index: 1;
  }
`;

export const StyledHeaderContainer = styled.div`
  height: 50px;
  width: 100%;
  background-color: #000;
  display: flex;
`;

export const StyledLink = styled(Link)`
  z-index: 1;
`;

export const StyledLogoContainer = styled.div`
  position: relative;
  width: 225px;
  display: flex;
  align-items: center;
  margin-left: 50px;
`;

export const StyledLogo = styled(YouthCampLogo)`
  z-index: 2;
  position: relative;
`;
