import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { getSignUpData } from '../../redux/selector/SignUp';
import { getMatchesCountResponse } from '../../redux/actions/SignUp';
import { StyledBigSymbol, StyledSmallSymbol, StyledParagraph } from './styled';

const Matchday = ({ isHeaderBased = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMatchesCountResponse());
  }, [dispatch]);
  const { matchesCount } = useSelector(getSignUpData);

  return (
    <StyledParagraph isHeaderBased={isHeaderBased}>
      Matchday{' '}
      {!isEmpty(matchesCount) ? (
        <>
          <StyledBigSymbol isHeaderBased={isHeaderBased}>
            {matchesCount.current}
          </StyledBigSymbol>
          /
          <StyledSmallSymbol isHeaderBased={isHeaderBased}>
            {matchesCount.total}
          </StyledSmallSymbol>{' '}
        </>
      ) : (
        '... '
      )}
      now playing
    </StyledParagraph>
  );
};

export default Matchday;
