import React from 'react';
import { useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import {
  StyledGroupContainer,
  StyledName,
  StyledGoals,
  StyledNoWinner,
  StyledNotAvailable,
} from './styled';
import { showMobileSidebarSection } from '../../../redux/actions/Team';
import { leagueSetTeamInfo } from '../../../redux/actions/League';
import { POPUPS } from '../../../service/constants';
import { showPopupWindow } from '../../../redux/actions/Popups';

const GroupTeamMobile = ({ teamData = {}, side }) => {
  const dispatch = useDispatch();
  const { symbol = '', name = '', score = null } = teamData;
  const notScored = score === -1 || score === null;
  const showTeamInfo = (id) => {
    const { TEAM_INFO_POPUP } = POPUPS;
    dispatch(leagueSetTeamInfo({ _id: id }, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
    }
  };
  return (
    <StyledGroupContainer side={side}>
      <StyledName onClick={() => showTeamInfo(teamData._id)} side={side}>
        {name ? (
          <span>{name}</span>
        ) : (
          <StyledNotAvailable>Not available</StyledNotAvailable>
        )}
        {symbol ? <img src={symbol} alt="team logo" /> : <StyledNoWinner />}
      </StyledName>
      <StyledGoals side={side} goals={score}>
        {notScored ? '?' : score}
      </StyledGoals>
    </StyledGroupContainer>
  );
};

export default GroupTeamMobile;
