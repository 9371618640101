import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import img from '../../../../assets/img/footbal-field.png';
import {
  StyledPlayerCard,
  StyledName,
  StyledHeader,
  StyledLabel,
  StyledButtonContainer,
  StyledInlineContainer,
  StyledDate,
  StyledInfoContainer,
  StyledInfoItem,
  StyledAmount,
  StyledInfoLabel,
  StyledCardButton,
  StyledInfoIcon,
} from './styled';
import {
  transferSelectPlayerInOffer,
  setDataForCardRequest,
} from '../../../../redux/actions/Transfers';
import { showPopupWindow } from '../../../../redux/actions/Popups';
import { sidebarOpenRightPanel } from '../../../../redux/actions/Sidebar';
import { numberWithCommas } from '../../../../service/utils';
import { POPUPS } from '../../../../service/constants';

export default function OfferCard({
  isDisabled,
  offer,
  cardType,
  player,
  type = 0,
  status = 1,
  className,
  name,
  _id,
  selectPlayer,
  transfer_fee_offer,
  createdAt,
  loan_end_date,
}) {
  const dispatch = useDispatch();
  const sendToReduxCurrentPlayer = () => {
    dispatch(sidebarOpenRightPanel(true));
    dispatch(transferSelectPlayerInOffer(offer));
  };
  const cardRequestHandler = (e) => {
    dispatch(
      setDataForCardRequest({
        _id,
        name: player.name,
        transfer_fee_offer,
        cardType,
      })
    );
    dispatch(
      showPopupWindow(
        POPUPS.CANCEL_OFFER_POPUP,
        POPUPS.CANCEL_OFFER_POPUP,
        false
      )
    );
    e.preventDefault();
    e.stopPropagation();
  };
  const rejectOffer = (e) => {
    dispatch(
      setDataForCardRequest({
        _id,
        name: player.name,
        transfer_fee_offer,
        cardType,
      })
    );
    dispatch(
      showPopupWindow(
        POPUPS.REJECT_OFFER_POPUP,
        POPUPS.REJECT_OFFER_POPUP,
        false
      )
    );
    e.preventDefault();
    e.stopPropagation();
  };
  const acceptOffer = (e) => {
    dispatch(
      setDataForCardRequest({
        _id,
        name: player.name,
        transfer_fee_offer,
        cardType,
        createdAt,
      })
    );
    dispatch(showPopupWindow(_id, POPUPS.ACCEPT_OFFER_POPUP, false));
    e.preventDefault();
    e.stopPropagation();
  };
  const handleContractRequest = (e) => {
    dispatch(showPopupWindow(player._id, POPUPS.CONTRACT_OFFER_POPUP, false));
    e.preventDefault();
    e.stopPropagation();
  }
  const currentTeam = cardType === 'Buy' || cardType === 'Loan In';
  const returnDate = moment(loan_end_date)
    .utcOffset('-0000')
    .format('D MMM YYYY');

  return (
    <>
      <StyledPlayerCard
        isDisabled={isDisabled}
        cardType="buy"
        selectPlayer={selectPlayer}
        className={`data-${className}`}
        status={status}
        type={type}
        onClick={sendToReduxCurrentPlayer}
      >
        <StyledHeader>
          <StyledLabel>{cardType} Player</StyledLabel>
          <StyledDate>
            <StyledInfoIcon />
            {moment(createdAt).utcOffset('-0000').format('D MMM YYYY')}
          </StyledDate>
          <StyledName>{player.name}</StyledName>
          <img src={img} alt="" />
        </StyledHeader>
        {cardType === 'Loan Out' ? (
          <>
            <StyledInlineContainer>
              <StyledInfoItem small>
                <StyledAmount>
                  ${numberWithCommas(transfer_fee_offer)}
                </StyledAmount>
                <StyledInfoLabel>Amount offered</StyledInfoLabel>
              </StyledInfoItem>
              <StyledInfoItem small>
                <StyledAmount>{returnDate}</StyledAmount>
                <StyledInfoLabel>Loan return</StyledInfoLabel>
              </StyledInfoItem>
            </StyledInlineContainer>
            <StyledInfoItem>
              <StyledAmount>{name}</StyledAmount>
              <StyledInfoLabel>
                {currentTeam ? 'Current' : 'Offering'} Team
              </StyledInfoLabel>
            </StyledInfoItem>
          </>
        ) : (
          <StyledInfoContainer>
            <StyledInfoItem>
              <StyledAmount>
                ${numberWithCommas(transfer_fee_offer)}
              </StyledAmount>
              <StyledInfoLabel>Amount offered</StyledInfoLabel>
            </StyledInfoItem>
            <StyledInfoItem>
              <StyledAmount>{name}</StyledAmount>
              <StyledInfoLabel>
                {currentTeam ? 'Current' : 'Offering'} Team
              </StyledInfoLabel>
            </StyledInfoItem>
          </StyledInfoContainer>
        )}

        <StyledButtonContainer>
          {currentTeam ? (
            <>
              {type === 2 ?
                <StyledCardButton disabled primary onClick={() => { }}>
                  Pending community vote
                </StyledCardButton>
                : <>
                  {status === 6 ?
                    <StyledCardButton
                      customBgColor="#E5E5E5"
                      customFontColor="#CE4646"
                      small
                      onClick={(e) => cardRequestHandler(e)}>
                      Cancel
                    </StyledCardButton> : status !== 6 ?
                      <StyledCardButton
                        customBgColor="#E5E5E5"
                        customFontColor="#CE4646"
                        small
                        onClick={(e) => cardRequestHandler(e)}>
                        Cancel deal
                      </StyledCardButton>
                      : null
                  }
                  {
                    status === 7 ?
                      <StyledCardButton disabled primary small onClick={() => { }}>
                        Pending player
                      </StyledCardButton> : status !== 6 ? <StyledCardButton primary small disabled>
                        Pending club
                      </StyledCardButton> : <StyledCardButton primary small onClick={e => handleContractRequest(e)}>
                        Make Offer
                      </StyledCardButton>}
                </>}
            </>
          ) : (
            <>
              {/*   eslint-disable no-nested-ternary */}
              {type === 2 ?
                <StyledCardButton disabled primary onClick={() => { }}>
                  Pending community vote
                </StyledCardButton> :
                status === 6 || status === 7 ?
                  <StyledCardButton disabled primary onClick={() => { }}>
                    Pending player
                  </StyledCardButton> :
                  (
                    <>
                      <StyledCardButton
                        small
                        customBgColor="#E5E5E5"
                        customFontColor="#CE4646"
                        onClick={(e) => rejectOffer(e)}>
                        Cancel
                      </StyledCardButton>
                      <StyledCardButton small primary onClick={(e) => acceptOffer(e)}>
                        Accept
                      </StyledCardButton>
                    </>
                  )

              }
            </>
          )}
        </StyledButtonContainer>
      </StyledPlayerCard>
    </>
  );
}

OfferCard.propTypes = {
  isDisabled: PropTypes.bool,
  offer: PropTypes.object.isRequired,
  cardType: PropTypes.string.isRequired,
  player: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  selectPlayer: PropTypes.bool.isRequired,
  transfer_fee_offer: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  loan_end_date: PropTypes.string,
};
OfferCard.defaultProps = {
  isDisabled: false,
  className: '',
  transfer_fee_offer: 0,
  loan_end_date: '',
};
