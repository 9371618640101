import { createAction } from 'redux-act';
import { sendHackRequest } from '../../../api/attack';
import { setErrorToPopup, isPopupLoading } from '../Popups';

export const sendHack = createAction('Hack request was sent');

export const hackRequest = () => async (dispatch) => {
  dispatch(isPopupLoading('hackPopup', true));
  const response = await sendHackRequest();
  let error;
  if (response.status === 200) {
    error = {
      header: 'Hack completed',
      text: "Our team successfully hacked the opponent team's players code. Their players will lose some of their capabilities in their next match",
    };
    dispatch(setErrorToPopup('hackPopup', error));
  }
  if (response.status === 202) {
    error = {
      header: 'Your hack attempt failed',
      text: 'Unfortunately our hack did not work. We got busted by the league and had to pay a $500,000 fine',
    };
    dispatch(setErrorToPopup('hackPopup', error));
  }
  if (response.status === 201) {
    error = {
      header: 'Not enough money',
      text: "You don't have enough money to perform this operation",
    };
    dispatch(setErrorToPopup('hackPopup', error));
  }
  dispatch(isPopupLoading('hackPopup', false));
};
