import styled, { css } from 'styled-components';
import { StyledButton } from '../../globalStyled';

export const StyledWrapper = styled.div``;
export const StyledCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 230px;
  width: 100%;
  ${({ isFree }) => {
    if (isFree) {
      return css`
        &:hover {
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
        }
      `;
    }
    if (!isFree)
      return css`
        opacity: 0.55;
      `;
  }}
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
export const StyledCardButton = styled(StyledButton)`
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
  margin: 0;
  ${({ primary }) => {
    if (primary) {
      return css`
        &:disabled {
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
        }
      `;
    }
  }}
`;
export const StyledCardHeader = styled.div`
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 6px 7px rgba(0, 0, 0, 0.02);
  padding: 0px 10px 25px;
  z-index: 1;
  width: 230px;
  img {
    max-width: 50px;
    width: auto;
    height: auto;
    max-height: 50px;
    padding-right: 5px;
  }
`;
export const StyledCardDetails = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #fafafa;
  box-shadow: 0 0 6px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
`;
export const StyledCardDetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
  width: 50%;
  border-bottom: 1px solid #ddd;
  padding: 16px 10px;
  align-items: center;
  justify-content: center;
  img {
    width: 100px;
    height: auto;
  }
  &:first-child {
    border-right: 1px solid #ddd;
  }
`;

export const StyledHeaderContent = styled.h1`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 70px;
`;

export const StyledDescription = styled.div`
  margin-top: 30px;
  color: #868686;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.58;
  padding: 0 15px;
  height: 130px;
`;

export const StyledTeamName = styled.p`
  color: #000;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 600;
  text-transform: none;
  line-height: 1.24;
`;
export const StyledLabel = styled.span`
  font-family: Gilroy;
  font-size: 11px;
  font-weight: 500;
  color: #868686;
  text-align: center;
  letter-spacing: 0.77px;
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #000;
`;
export const StyledMore = styled.span`
  font-family: 'Gilroy-SemiBold', sans-serif;
  color: #000;
`;

export const StyledRecommended = styled.div`
  padding: 3px;
  position: absolute;
  z-index: 2;
  text-align: center;
  top: 0;
  width: 100%;
  background-color: #fdca40;
  color: #000;
  font-family: 'Gilroy', sans-serif;
  font-weight: 900;
  font-size: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
