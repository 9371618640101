import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { isAndroid } from 'react-device-detect';
import { StyledDownloadContainer } from './styled';
import { ReactComponent as DownloadIcon } from '../../../../assets/img/download-icon.svg';
import { getExportPlayersDocRequest } from '../../../../api/league';

const CSVReport = ({ isPremium }) => {
  const [csvData, setData] = React.useState('');
  const csvRef = React.useRef();
  const getCSVData = async () => {
    const response = await getExportPlayersDocRequest();
    if (response.status === 200) {
      const res = await response.text();
      setData(res);
    }
  };
  React.useEffect(() => {
    setTimeout(() => {
      if (csvData) {
        csvRef.current.link.click();
      }
    });
  }, [csvData]);
  const downloadFile = () => {
    if (!isPremium) {
      if (window.navigator.userAgent.includes('wv') && isAndroid) {
        window.open(
          'inappsubscription://?package=com.example.app.webview-master&successful_url=https://www.theopenleague.com/home&expired_url=https://www.yahoo.com',
          '_blank'
        );
      } else {
        window.open('https://www.patreon.com/join/theopenleague?', '_blank');
      }
    } else {
      getCSVData();
    }
  };
  return (
    <StyledDownloadContainer isPremium={isPremium} onClick={downloadFile}>
      <DownloadIcon />
      <CSVLink filename="export-players.csv" data={csvData} ref={csvRef} />
    </StyledDownloadContainer>
  );
};
CSVReport.propTypes = {
  isPremium: PropTypes.bool,
};

CSVReport.defaultProps = {
  isPremium: false,
};
export default CSVReport;
