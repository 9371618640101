import styled from 'styled-components';
import { ReactComponent as YouthCampLogo } from '../../assets/img/youth-camp-logo.svg';

export const StyledLogo = styled(YouthCampLogo)`
  z-index: 2;
  position: relative;
`;
export const StyledYouthCampHeader = styled.div`
  height: 120px;
  display: flex;
  flex-direction: row;
  position: relative;
  @media only screen and (max-width: 991px) {
    height: 50px;
  }
`;
// TODO Replace styled with real logo
export const StyledLogoContainer = styled.div`
  position: relative;
  width: 40%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 991px) {
    margin-left: 20px;
    width: 100%;
    height: 50px;
    background-color: #000;
    justify-content: center;
  }
  @media only screen and (max-width: 550px) {
    svg {
      width: 200px;
    }
  }
  /*   max-width: 500px; */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    /*    max-width: 500px; */
    width: 115%;
    height: 120px;
    transform: skew(-25deg, 0);
    background: #000;
    z-index: 1;
    @media only screen and (max-width: 991px) {
      height: 50px;
      width: 100%;
    }
  }
`;

export const StyledLobbyBtn = styled.div`
  position: relative;
  background-color: #151515;
  max-width: 120px;
  width: 15%;
  display: flex;
  align-items: center;
  padding-left: 30px;
  @media only screen and (max-width: 991px) {
    z-index: 2;
    width: 75px;
    height: 50px;
    position: absolute;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -13px;
      width: 30px;
      height: 50px;
      transform: skew(-25deg, 0);
      background: #151515;
      z-index: 1;
    }
  }

  svg {
    path {
      fill: transparent;
      stroke: white;
    }
  }
`;

export const StyledImg = styled.div`
  overflow: hidden;
  position: relative;

  width: 100%;
  height: auto;
  padding: 0px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
  img {
    max-width: 1400px;
    height: auto;
    top: -80px;
    position: absolute;
  }
  &:before {
    content: '';
    position: absolute;
    top: -50px;
    left: 25%;
    width: 90px;
    height: 90px;
    transform: skew(-25deg, 0);
    background: transparent;
    z-index: 1;
    border: 5px solid #ffc80f;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -50px;
    right: 20%;
    width: 90px;
    height: 90px;
    transform: skew(-25deg, 0);
    background: transparent;
    z-index: 1;
    border: 5px solid #ffc80f;
  }
`;
