import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { StyledContainer } from './styled';
import TeamSquadContainer from './components/TableContainer';
import StrategyContainer from './components/StrategyContainer';

export default function Team() {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes('squad')) {
      history.push('/team/strategy');
    }
  }, [history, location.pathname]);

  return (
    <>
      <StyledContainer>
        <Switch>
          <Route path="/team/strategy" component={StrategyContainer} />
          <Route path="/team/squad" component={TeamSquadContainer} />
        </Switch>
      </StyledContainer>
    </>
  );
}
