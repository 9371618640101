import styled, { css } from 'styled-components';
import { ReactComponent as StyledRightArrowWhiteIcon } from '../../assets/img/go-arrow-white.svg';

export const StyledLeftWhiteArrow = styled(StyledRightArrowWhiteIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  transform: rotate(-180deg);
`;

export const StyledBody = styled.div`
  flex-direction: row;
  display: flex;
`;

export const StyledMobileSubheader = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 15px;
`;

export const StyledSignUpContainer = styled.div`
  border-radius: 4px;
  box-shadow: 8px 0 14px -2px rgba(112, 112, 112, 0.08);

  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ mobile }) => {
    if (mobile) {
      return css`
        background-color: #191919;
        height: auto;
      `;
    }
  }}

  h3 {
    color: #fff;
    background-color: #333333;
    height: 42px;
  }
`;

export const StyledLeaguesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 15px;
`;
export const StyledLeagueAvailability = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  font-family: 'Gilroy', sans-serif;
  text-transform: uppercase;
  color: ${(props) => props.color};
  @media only screen and (max-width: 991px) {
    text-transform: none;
  }
  &:before {
    position: absolute;
    top: 2px;
    left: -13px;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`;
export const StyledLeagueName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Gilroy', sans-serif;
  font-size: 13px;
`;

export const StyledLeagueItem = styled.div`
  height: 60px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.1em solid #cccccc69;
  border-radius: 4px;
  margin: 10px;
  height: 60px;
  font-weight: 400;
  cursor: pointer;
  background-color: #fff;
  pointer-events: ${(props) => (props.isFull ? 'none' : 'all')};

  &:hover {
    //font-weight: 600;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    border: 0.1em solid #45454569;
    margin: 10px 0;
    ${StyledLeagueName} {
      padding-left: 10px;
    }
    ${StyledLeagueAvailability} {
      font-weight: 400;
      padding-right: 10px;
    }
  }
  img {
    max-width: 30px;
    height: auto;
    margin-right: 10px;
  }
`;

export const StyledLeagueCode = styled.span`
  color: #b2b2b2;
  text-decoration: underline;
  font-size: 12px;
`;

export const StyledMobileBackButton = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: relative;
`;

export const StyledTitle = styled.h2`
  color: #fff;
  font-size: 25px;
  line-height: 1.44;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  @media only screen and (max-width: 991px) {
    text-align: center;
    width: 100%;
    font-size: 18px;
  }
`;
