import styled, { css } from 'styled-components';

export const StyledPagination = styled.section`
  margin: 10px 0;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
`;

export const StyledThead = styled.thead`
  ${({ display }) => {
    if (display === 'none')
      return css`
        display: none;
      `;
  }}
`;

export const StyledTh = styled.th`
  padding: 12px 0 12px;
  background: #fff;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 1px;
  color: #000;

  &:first-of-type {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 0px;
  }
  &:last-of-type {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 0px;
  }
`;

export const StyledTrTd = styled.tr`
  border-bottom: 1px solid #bdbdbd;
  background: white;
  &:last-of-type {
    border-bottom: 0px solid #bdbdbd;
    td {
      &:first-child {
        border-bottom-left-radius: 9px;
      }
      &:last-child {
        border-bottom-right-radius: 9px;
      }
    }
  }
`;

export const StyledTd = styled.td`
  text-align: center;
  padding: 12px 10px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 100px;
  white-space: nowrap;
`;

export const StyledContainer = styled.section`
  > h6 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #949494;
    margin-bottom: 30px;
  }
  margin-bottom: 31px;
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  max-height: 295px;
  overflow-y: auto;
  padding-bottom: 15px;
`;

export const StyledTbody = styled.tbody`
  &::before {
    content: '';
    display: block;
    height: 15px;
  }
`;

export const StyledTrTh = styled.tr``;
