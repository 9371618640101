import React from 'react';
import ContentLoader from 'react-content-loader';

const SelectPlayerPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={380}
    height={450}
    viewBox="0 0 380 450"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="23" y="14" rx="3" ry="3" width="137" height="13" />
    <rect x="32" y="110" rx="3" ry="3" width="107" height="16" />
    <rect x="228" y="14" rx="3" ry="3" width="137" height="13" />
    <rect x="23" y="58" rx="8" ry="8" width="56" height="18" />
    <rect x="93" y="58" rx="8" ry="8" width="56" height="18" />
    <rect x="162" y="58" rx="8" ry="8" width="56" height="18" />
    <rect x="232" y="57" rx="8" ry="8" width="56" height="18" />
    <rect x="301" y="58" rx="8" ry="8" width="56" height="18" />
    <rect x="31" y="142" rx="3" ry="3" width="16" height="14" />
    <rect x="32" y="164" rx="3" ry="3" width="41" height="6" />
    <rect x="101" y="143" rx="3" ry="3" width="16" height="14" />
    <rect x="102" y="165" rx="3" ry="3" width="41" height="6" />
    <rect x="163" y="143" rx="3" ry="3" width="16" height="14" />
    <rect x="164" y="165" rx="3" ry="3" width="41" height="6" />
    <rect x="232" y="143" rx="3" ry="3" width="16" height="14" />
    <rect x="233" y="165" rx="3" ry="3" width="41" height="6" />
    <rect x="311" y="143" rx="3" ry="3" width="16" height="14" />
    <rect x="312" y="165" rx="3" ry="3" width="41" height="6" />
    <rect x="23" y="186" rx="0" ry="0" width="343" height="1" />
    <rect x="32" y="237" rx="3" ry="3" width="16" height="14" />
    <rect x="33" y="259" rx="3" ry="3" width="41" height="6" />
    <rect x="102" y="238" rx="3" ry="3" width="16" height="14" />
    <rect x="103" y="260" rx="3" ry="3" width="41" height="6" />
    <rect x="164" y="238" rx="3" ry="3" width="16" height="14" />
    <rect x="165" y="260" rx="3" ry="3" width="41" height="6" />
    <rect x="233" y="238" rx="3" ry="3" width="16" height="14" />
    <rect x="234" y="260" rx="3" ry="3" width="41" height="6" />
    <rect x="312" y="238" rx="3" ry="3" width="16" height="14" />
    <rect x="313" y="260" rx="3" ry="3" width="41" height="6" />
    <rect x="24" y="281" rx="0" ry="0" width="343" height="1" />
    <rect x="32" y="207" rx="3" ry="3" width="107" height="16" />
    <circle cx="216" cy="120" r="11" />
    <circle cx="245" cy="121" r="11" />
    <circle cx="343" cy="121" r="11" />
    <circle cx="309" cy="120" r="11" />
    <circle cx="278" cy="120" r="11" />
    <circle cx="215" cy="213" r="11" />
    <circle cx="244" cy="214" r="11" />
    <circle cx="342" cy="214" r="11" />
    <circle cx="308" cy="213" r="11" />
    <circle cx="277" cy="213" r="11" />
    <rect x="29" y="333" rx="3" ry="3" width="16" height="14" />
    <rect x="30" y="355" rx="3" ry="3" width="41" height="6" />
    <rect x="99" y="334" rx="3" ry="3" width="16" height="14" />
    <rect x="100" y="356" rx="3" ry="3" width="41" height="6" />
    <rect x="161" y="334" rx="3" ry="3" width="16" height="14" />
    <rect x="162" y="356" rx="3" ry="3" width="41" height="6" />
    <rect x="230" y="334" rx="3" ry="3" width="16" height="14" />
    <rect x="231" y="356" rx="3" ry="3" width="41" height="6" />
    <rect x="309" y="334" rx="3" ry="3" width="16" height="14" />
    <rect x="310" y="356" rx="3" ry="3" width="41" height="6" />
    <rect x="29" y="303" rx="3" ry="3" width="107" height="16" />
    <circle cx="212" cy="309" r="11" />
    <circle cx="241" cy="310" r="11" />
    <circle cx="339" cy="310" r="11" />
    <circle cx="305" cy="309" r="11" />
    <circle cx="274" cy="309" r="11" />
    <rect x="17" y="373" rx="0" ry="0" width="343" height="1" />
  </ContentLoader>
);

export default SelectPlayerPreloader;
