import React from 'react';
import { useDispatch } from 'react-redux';

import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { leaveTeamRequest } from '../../../redux/actions/User';

const LeavePopup = ({ popupName }) => {
  const dispatch = useDispatch();

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };
  const leaveGame = () => {
    dispatch(leaveTeamRequest());
    localStorage.removeItem('previewTeamData');
    hidePopup();
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock maxWidth={600}>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          <StyledHeader>Leave a game</StyledHeader>
          <StyledParagraph>
            You will lose your team and progress.
          </StyledParagraph>
          <StyledParagraph>Are you sure you want to leave?</StyledParagraph>

          <StyledButtonContainer>
            <StyledButton basic onClick={() => hidePopup()}>
              Cancel
            </StyledButton>
            <StyledButton
              onClick={() => {
                leaveGame();
              }}
              danger
            >
              Leave
            </StyledButton>
          </StyledButtonContainer>
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(LeavePopup);
