import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import ReadMore from '../ReadMore';
import { toOrdinalSuffix } from '../../service/utils';
import {
  StyledCardContainer,
  StyledCardHeader,
  StyledCardDetails,
  StyledCardDetailItem,
  StyledDescription,
  StyledHeaderContent,
  StyledTeamName,
  StyledLabel,
  StyledRow,
  StyledCardButton,
  StyledValue,
  StyledWrapper,
  StyledButtonContainer,
  StyledRecommended,
} from './styled';
import { setTeamResponseCode } from '../../redux/actions/SignUp';
import { showPopupWindow } from '../../redux/actions/Popups';
import { getSignUpData } from '../../redux/selector/SignUp';
import { POPUPS } from '../../service/constants';

const TeamCard = ({
  _id,
  name,
  symbol,
  current_cash,
  ranking,
  free,
  previous_ranking,
  context,
  playerAvgAge,
  sendTeamIdCallback,
  isTeamOwner,
  isTeamRecommended,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(getSignUpData);

  React.useEffect(
    () => () => {
      dispatch(setTeamResponseCode(null));
    },
    [dispatch]
  );

  const handleChooseTeam = () => {
    mixpanel.track('Team is selected');
    sendTeamIdCallback(_id);
    dispatch(showPopupWindow(_id, POPUPS.TERMS_POPUP, false));
  };
  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`;
    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`;
  };
  const showReadMorePopup = () => {
    dispatch(showPopupWindow(_id, POPUPS.READ_MORE_POPUP));
  };

  return (
    <StyledWrapper>
      <StyledCardContainer isFree={free}>
        {isTeamRecommended && (
          <StyledRecommended>Recommended</StyledRecommended>
        )}
        <StyledCardHeader>
          <StyledHeaderContent>
            <img src={symbol} alt="logo" />
            <StyledTeamName>{name}</StyledTeamName>
          </StyledHeaderContent>
          <StyledDescription>
            <ReadMore
              isActive={free}
              str={context}
              onReadMoreClick={() => {
                showReadMorePopup();
              }}
            />
          </StyledDescription>
        </StyledCardHeader>
        <StyledCardDetails>
          <StyledRow>
            <StyledCardDetailItem>
              {ranking > 0 ? (
                <>
                  <StyledValue>{toOrdinalSuffix(ranking)}</StyledValue>
                  <StyledLabel>this season</StyledLabel>
                </>
              ) : (
                <StyledLabel>Season not started</StyledLabel>
              )}
            </StyledCardDetailItem>
            <StyledCardDetailItem>
              {!free ? (
                <StyledLabel>Confidential</StyledLabel>
              ) : (
                <>
                  <StyledValue>${formatCash(current_cash)}</StyledValue>
                  <StyledLabel>cash</StyledLabel>
                </>
              )}
            </StyledCardDetailItem>
          </StyledRow>
          <StyledRow>
            <StyledCardDetailItem>
              {previous_ranking ? (
                <>
                  <StyledValue>{toOrdinalSuffix(previous_ranking)}</StyledValue>
                  <StyledLabel>prev. season</StyledLabel>
                </>
              ) : (
                <StyledLabel>No previous season</StyledLabel>
              )}
            </StyledCardDetailItem>
            <StyledCardDetailItem>
              <StyledValue>{playerAvgAge}</StyledValue>
              <StyledLabel>avg. age</StyledLabel>
            </StyledCardDetailItem>
          </StyledRow>
          <StyledButtonContainer>
            <StyledCardButton
              isFree={free}
              disabled={!free || isLoading || isTeamOwner}
              primary
              onClick={() => {
                handleChooseTeam();
              }}
            >
              {free ? 'Select team' : 'Not Available'}
            </StyledCardButton>
          </StyledButtonContainer>
        </StyledCardDetails>
      </StyledCardContainer>
    </StyledWrapper>
  );
};
export default React.memo(TeamCard);
