import React from 'react';
import ContentLoader from 'react-content-loader';

const RevenuesPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={710}
    height={50}
    viewBox="0 0 710 50"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="41" y="15" rx="3" ry="3" width="77" height="13" />
    <rect x="569" y="13" rx="3" ry="3" width="96" height="16" />
  </ContentLoader>
);

export default RevenuesPreloader;
