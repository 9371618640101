import React from 'react';
import {
  StyledWinner,
  StyledLabel,
  StyledSeparator,
  StyledNoWinner,
} from './styled';

const DomesticCupWinner = ({ logo }) => (
  <>
    <StyledWinner>
      {logo ? <img src={logo} alt="winner logo" /> : <StyledNoWinner />}
      <StyledSeparator />
      <StyledLabel>WINNER</StyledLabel>
    </StyledWinner>
  </>
);

export default DomesticCupWinner;
