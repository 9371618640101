import styled, { css } from 'styled-components';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { InfoDark } from '../../../../../../globalStyled';

export const StyledCellName = styled.span`
  color: #999;
  padding: 7px 0;
`;
export const StyledCell = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
  color: #000;
`;
export const InfoDarkAbsolute = styled(InfoDark)`
  position: relative;
  top: 1px;
  left: 2px;
`;

export const StyledNumber = styled.span`
  color: #646464;
`;

export const StyledTableContainer = styled.section`
  width: 100%;
  margin-top: 20px;
  > h1 {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-size: 25px;
    font-weight: bold;
    line-height: 41px;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
    text-transform: none;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
`;

export const StyledThead = styled.thead`
  ${({ display }) => {
    if (display === 'none')
      return css`
        display: none;
      `;
  }}
`;

export const StyledTbody = styled.tbody`
  &::before {
    content: '';
    display: block;
    height: 0px;
  }
`;

export const StyledTrTh = styled.tr``;

export const StyledTh = styled.th`
  padding: 6px 0 6px 12px;
  background: #fff;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 1px;
  color: #000;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 11px;
    &[type='Deal type'],
    &[type='Amount'] {
      display: none;
    }
  }

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0px;
  }
  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
  }
`;

export const StyledTrTd = styled.tr`
  border-bottom: 1px solid #eeeeee;
  background: white;
  &:nth-child(even) {
    background: #fafafa;
  }

  &:last-of-type {
    border-bottom: 0px solid #bdbdbd;
    td {
      &:first-child {
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
`;

export const StyledTd = styled.td`
  text-align: left;
  padding: 12px 10px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 80px;
  line-height: 1;
  ${({ pointerCursor }) => {
    if (pointerCursor)
      return css`
        cursor: pointer;
        text-decoration: underline;
      `;
  }}

  ${({ titleCell, type }) => {
    if (titleCell)
      return css`
        text-align: left;
      `;
    if (type === 'Deal type' || type === 'Amount') {
      return css`
        @media screen and (max-width: 768px) {
          display: none;
        }
      `;
    }
  }}
`;

export const StyledRatingCell = styled.div`
  position: relative;
  width: 120px;
  margin: 0 auto;
  /*  @media (max-width: 530px) {
    width: 100px;
  } */
`;

export const StyledRowNumber = styled.span`
  max-width: 50px;
`;

export const StyledLifetimeCell = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const StyledChartContainer = styled.div`
  padding: ${({ small }) => (small ? '0px' : '15px 20px')};
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const StyledSelect = styled(Select)`
  background-color: transparent;

  font-size: 13px;
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    min-height: 25px;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    box-shadow: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    padding: 8px 10px;
  }
  .react-select__indicator {
    padding: 0;
    position: relative;
    svg {
      display: none;
    }
    &:before {
      display: block;
      content: ' ';
      width: 0;
      height: 0;
      opacity: 0.5;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

      border-top: 4px solid #000;
    }
  }
  .react-select__value-container {
    width: 30px;
    padding: 3px;
  }
  .react-select__indicator-separator {
    display: none;
  }
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-size: 13px;
  }
`;

export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const ArrowRight = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
export const ArrowLeft = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-225deg);
`;

export const StyledPaginationButton = styled.button`
  border: 0;
  box-shadow: none;
  background-color: #fff;
  border-radius: 3px;
  align-self: center;
  display: flex;
  flex-direction: row;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;
export const StyledPageNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

export const StyledPageLabel = styled.span`
  font-size: 13px;
  margin: 0 10px;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
`;

export const StyledTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
`;

export const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
`;

export const StyledMobileSection = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 11px;
  text-align: center;
`;

export const StyledTitle = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.13px;
`;

export const StyledSortSelect = styled(Select)`
  display: none;

  .react-select__control {
    background-color: #fff;
    color: #000;
    border: solid 1px #d1d1d1;
    width: 100%;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__menu {
    z-index: 2;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }
`;

export const StyledSortContainer = styled.div`
  display: none;
`;
export const SortLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
`;

export const StyledDealsPlayerName = styled.span``;
