import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { InfoDark } from '../../../../globalStyled';

export const StyledSaleLabel = styled.span`
  border-radius: 3px;
  padding: 1px 8px;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  color: ${({ isAcademy }) => (isAcademy ? '#fff' : '#000')};
  background-color: ${({ isAcademy }) => (isAcademy ? '#00996b' : '#fdca40')};
  margin-left: 5px;
  line-height: 13px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const StyledInfoDark = styled(InfoDark)`
  margin-left: 3px;
  height: 10px;
  width: 10px;
`;
export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  max-width: 190px;
  white-space: normal;
  line-height: 20px;
  text-transform: none;
  text-align: center;
  &.__react_component_tooltip {
    pointer-events: auto !important;
    &.show {
      color: #4c4c4c !important;
      border-radius: 6px;
      opacity: 1;
      text-align: left;
    }
  }
`;
