import styled, { css } from 'styled-components';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Sorted } from '../../../../../../assets/img/sort-arrows.svg';
import { InfoDark } from '../../../../../../globalStyled';
import {
  StyledWrapper,
  StyledHoverPlaceholder,
} from '../../../../../../components/Charts/DonutChart/styled';

export const StyledCellName = styled.p`
  color: #999;
  padding: 7px 0;
`;
export const StyledCell = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
  color: #000;
`;
export const InfoDarkAbsolute = styled(InfoDark)`
  position: relative;
  top: 1px;
  left: 2px;
`;

export const StyledNumber = styled.span`
  border: 2px solid transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${({ feeling }) => {
    if (feeling === -1) {
      return css`
        border-color: #d9d9d9;
      `;
    }
    if (feeling === 0) {
      return css`
        border-color: #cc392d;
      `;
    }
    if (feeling === 1) {
      return css`
        border-color: #009568;
      `;
    }
  }}
`;

export const StyledSorted = styled(Sorted)`
  margin-bottom: -1px;
  margin-left: 5px;
  width: 10px;
  height: 12px;
  fill: #7f7f7f;
  ${({ type }) => {
    if (type === 'fromMinToMax') {
      return css`
        path:first-of-type {
          display: none;
        }
      `;
    }
    if (type === 'fromMaxToMin') {
      return css`
        path:last-of-type {
          display: none;
        }
      `;
    }
  }}
`;

export const StyledInTeamIcon = styled.span`
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: #0e4afb;
  border-radius: 50%;
  left: -10px;
`;

export const StyledTableContainer = styled.section`
  width: 100%;
  overflow: auto;
  @media screen and (max-width: 1199px) {
    /*  max-width: 400px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    border-collapse: separate;
    max-width: 400px;
    border-spacing: 0 1em;
    [type='No'],
    [type='Age'],
    [type='Position'],
    [type='Rating'],
    [type='Fitness'],
    [type='Media Rating'],
    [type='Potential'], 
    [type='Contract left'] {
      display: none;
    }
  }
`;

export const StyledThead = styled.thead`
  border-bottom: 1px solid #eee;
  ${({ display }) => {
    if (display === 'none')
      return css`
        display: none;
      `;
  }}
`;

export const StyledTbody = styled.tbody`
  /* border-top: 12px solid #e5e5e5; */
  &::before {
    content: '';
    display: block;
    height: 0px;
  }
`;

export const StyledTrTh = styled.tr``;

export const StyledTh = styled.th`
  padding: 12px 0 12px;
  background: #fff;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 0px;
  letter-spacing: 1px;
  color: #000;
  &[type='Lifetime Matches'] {
    max-width: 90px;
  }
  &[type='Media Rating'] {
    text-align: left;
    padding-left: 10px;
    span {
      display: none;
    }
  }
  &[type='No'] {
    font-size: 0;
    span {
      display: none;
    }
  }
  @media screen and (max-width: 400px) {
    font-size: 11px;
  }

  &:first-of-type {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 0px;
  }
  &:last-of-type {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 0px;
  }
  ${({ display, titleNumberName, type }) => {
    if (titleNumberName)
      return css`
        display: none;
      `;
    if (display === 'none')
      return css`
        display: none;
      `;
    if (type === 'Name') {
      return css`
        text-align: left;
        padding-left: 20px;
      `;
    }
  }}
`;

export const StyledTitle = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.13px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
`;

export const StyledTrTd = styled.tr`
  border-bottom: 1px solid #eeeeee;
  /*   position: relative; */
  background: white;
  @media only screen and (max-width: 1199px) {
    border: 0;
    border-radius: 9px;
  }
  &:hover,
  &.clicked {
    cursor: pointer;
    background: #0e4afb;
    @media only screen and (max-width: 1199px) {
      background: #fff;
      box-shadow: inset 0px 0px 2px #0e4afb;
    }
    ${StyledInTeamIcon} {
      background-color: #fff !important;
      opacity: 1 !important;
    }
    td,
    ${StyledNumber} {
      color: #ffffff;
    }
    td {
      &[type='Name'] {
        span {
          opacity: 0;
          @media only screen and (max-width: 1199px) {
            opacity: 1;
            color: #000;
          }
        }
        &[data-listed='true'] {
          ${StyledTitle} {
            @media only screen and (max-width: 1199px) {
              color: red !important;
            }
          }
        }
      }
      &[type='Rating'] {
        svg {
          opacity: 0.5;
        }
      }
      &[type='Media Rating'] {
        filter: grayscale(0%);
        ${StyledWrapper} {
          svg,
          span {
            display: none;
          }
        }
        ${StyledHoverPlaceholder} {
          display: flex;
        }
      }
    }
  }
  /*   &:hover ${StyledWrapper} {
    display: none;
  } */
  &:nth-child(even) {
    background: #fafafa;
    @media only screen and (max-width: 1199px) {
      background: #fff;
    }
    &:hover,
    &.clicked {
      cursor: pointer;
      background: #0e4afb;
      td {
        color: #ffffff;
        @media only screen and (max-width: 1199px) {
          color: #000;
        }
      }
      @media only screen and (max-width: 1199px) {
        background: #fff;
        color: #000;
      }
    }
    &:hover ${StyledInTeamIcon} {
      background-color: #fff;
      opacity: 1;
    }
  }

  ${({ clicked }) => css`
    &.row-number-${clicked} {
      background: #0e4afb;
      td {
        color: #ffffff;
        ${StyledCell} {
          color: #ffffff;
        }
        @media only screen and (max-width: 1199px) {
          background: #fff;
          color: #000;
          ${StyledCell} {
            color: #000;
          }
        }
      }
      ${StyledInTeamIcon} {
        background-color: #fff !important;
        opacity: 1;
      }
    }
  `}

  &:last-of-type {
    border-bottom: 0px solid #bdbdbd;
    td {
      &:first-child {
        border-bottom-left-radius: 9px;
      }
      &:last-child {
        border-bottom-right-radius: 9px;
      }
    }
  }
`;

export const StyledTd = styled.td`
  text-align: center;
  padding: 7px 8px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ isLoaned }) => (isLoaned ? '#999' : '#000')};
  width: 80px;
  line-height: 1;
  &[type='Media Rating'] {
    ${({ isLoaned }) => {
    if (isLoaned) {
      return css`
          filter: grayscale(100%);
        `;
    }
  }}
  }

  &[data-listed='true'] {
    color: red;
    @media only screen and (max-width: 1199px) {
      color: red !important;
    }
  }
  &[type='No'] {
    width: 35px;
  }
  &[type='Name'] {
    ${({ isLoaned }) => {
    if (isLoaned) {
      return css`
          color: #000;
        `;
    }
  }}
  }
  &[type='Media Rating'] {
    text-align: left;
    padding: 0px;
  }
  &[type='Lifetime Matches'] {
    max-width: 80px;
  }
  ${({ titleCell, type }) => {
    if (titleCell)
      return css`
        text-align: left;
      `;
    if (type === 'PTS') {
      return css`
        span {
          background: rgba(14, 74, 251, 0.1);
          border: 1px solid rgba(187, 199, 234, 0.45);
          border-radius: 5px;
          padding: 0 11px;
        }
      `;
    }
    if (type === 'NO') {
      return css`
        width: 60px;
        opacity: 0.5;
      `;
    }
    if (type === 'Name') {
      return css`
        text-align: left;
        padding-left: 20px;
      `;
    }
  }}
  @media screen and (max-width: 1199px) {
    padding: 10px 0;
    border-radius: 9px;
  }
`;

export const StyledRatingCell = styled.div`
  position: relative;
  width: 120px;
  margin: 0 auto;
  ${({ isLoaned }) => {
    if (isLoaned) {
      return css`
        polygon {
          fill: #b2b2b2;
        }
      `;
    }
  }}
  @media (max-width: 530px) {
    width: 100px;
  }
`;

export const StyledRowNumber = styled.span`
  max-width: 50px;
`;

export const StyledLifetimeCell = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const StyledChartContainer = styled.div`
  padding: ${({ small }) => (small ? '0px' : '15px 20px')};
  display: flex;
  flex-direction: row;
  justify-content: start;
  span {
    font-size: ${({ small }) => (small ? '9px' : '12px')};
  }
`;

export const StyledSelect = styled(Select)`
  background-color: transparent;

  font-size: 13px;
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    min-height: 25px;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    box-shadow: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    padding: 8px 10px;
  }
  .react-select__indicator {
    padding: 0;
    position: relative;
    svg {
      display: none;
    }
    &:before {
      display: block;
      content: ' ';
      width: 0;
      height: 0;
      opacity: 0.5;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;

      border-top: 4px solid #000;
    }
  }
  .react-select__value-container {
    width: 30px;
    padding: 3px;
  }
  .react-select__indicator-separator {
    display: none;
  }
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-size: 13px;
  }
`;

export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  justify-content: flex-end;
  margin-top: 30px;
  z-index: 100;
`;

export const ArrowRight = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;
export const ArrowLeft = styled.i`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-225deg);
`;

export const StyledPaginationButton = styled.button`
  border: 0;
  box-shadow: none;
  background-color: #fff;
  border-radius: 3px;
  align-self: center;
  display: flex;
  flex-direction: row;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;
export const StyledPageNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

export const StyledPageLabel = styled.span`
  font-size: 13px;
  margin: 0 10px;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
`;

export const StyledTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  position: relative;
  ${({ feeling }) => {
    if (feeling === -1) {
      return css`
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 0;
          border-color: #d9d9d9 transparent transparent transparent;
          top: -10px;
          left: 0;
        }
      `;
    }
    if (feeling === 0) {
      return css`
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 0;
          border-color: #cc392d transparent transparent transparent;
          top: -10px;
          left: 0;
        }
      `;
    }
    if (feeling === 1) {
      return css`
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 0;
          border-color: #009568 transparent transparent transparent;
          top: -10px;
          left: 0;
        }
      `;
    }
  }}
`;

export const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
`;

export const StyledMobileSection = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 11px;
  text-align: center;
`;

export const StyledSortSelect = styled(Select)`
  display: none;
  @media only screen and (max-width: 1199px) {
    display: flex;
    width: 180px;
  }
  .react-select__control {
    background-color: #fff;
    color: #000;
    border: solid 1px #d1d1d1;
    width: 100%;
    @media only screen and (max-width: 1199px) {
      background-color: #fff;
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__menu {
    z-index: 2;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }
`;

export const StyledSortContainer = styled.div`
  display: none;
  @media only screen and (max-width: 1199px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
  }
`;
export const SortLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gilroy', sans-serif;
`;

export const StyledLoanOut = styled.span`
  border-radius: 3px;
  padding: 3px 8px;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  color: #fff;
  background-color: #7f7f7f;
  margin-left: 5px;
  line-height: 7px;
  white-space: nowrap;
`;
export const StyledLoanIn = styled.span`
  border-radius: 3px;
  padding: 3px 8px;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  color: #000;
  background-color: #fdca40;
  margin-left: 5px;
  line-height: 7px;
  white-space: nowrap;
`;
export const StyledAcademy = styled.span`
  border-radius: 3px;
  padding: 3px 8px;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  color: #fff;
  background-color: #00996b;
  margin-left: 5px;
  line-height: 7px;
  white-space: nowrap;
`;

export const StyledTableCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
