import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { userLogin } from '../../redux/actions/User';
import { isAuthAsWaited, isAuth } from '../../redux/selector/User';
import { StyledContainer, StyledNewLoader } from './styled';
import { headers } from '../../api/requestConfig';
import { homeBar } from '../../service/links';

export default function LoginCallback() {
  const dispatch = useDispatch();
  const authWaited = useSelector(isAuthAsWaited);
  const auth = useSelector(isAuth);

  const location = useLocation();
  const history = useHistory();

  const getAccessToken = function (hash) {
    return hash
      .split('&')
      .find((hashSTR) => hashSTR.includes('access_token'))
      .split('=')
      .find((hashSTR) => !hashSTR.includes('access_token'));
  };

  useEffect(() => {
    if (auth) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(homeBar, {
            method: 'GET',
            headers,
            credentials: 'include',
          });
          
          if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`HTTP error. status: ${response.status}, details: ${errorDetails}`);
          }

          const data = await response.json();
          const userId = data.user_id; 
          mixpanel.track('Discord authorized', { user_id: userId });
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
      history.push('/home');
    }
  }, [auth, history]);

  useEffect(() => {
    if (authWaited) history.push('/signup');
  }, [authWaited, history]);

  useEffect(() => {
    const { hash } = location;
    if (hash.length === 0) {
      history.push('/login');
    } else {
      const accessToken = getAccessToken(hash);
      if (accessToken.length === 0) history.push('/login');
      dispatch(userLogin(accessToken));
    }
  }, [location, history, dispatch]);

  return (
    <StyledContainer>
      <StyledNewLoader type="Bars" color="#2AFC98" height={100} width={100} />
    </StyledContainer>
  );
}
