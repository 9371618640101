import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  StyledWrapper,
  StyledInnerCircle,
  StyledCircle,
  StyledReactTooltip,
  StyledHoverPlaceholder,
} from './styled';
import { getMediaColor } from '../../../service/data';

const DonutChart = ({ arcSize = 1, index, small }) => {
  const circumference = small ? 56 : 113;
  const strokeDasharray = `${circumference} ${circumference}`;

  const percent = arcSize * 10;
  const offset = circumference - (percent / 100) * circumference;
  const arcColor = getMediaColor(arcSize);
  const chartId = `${index}${arcSize}`;
  return (
    <StyledWrapper data-tip clickable data-for={chartId}>
      <span>{arcSize}</span>
      <StyledHoverPlaceholder>{arcSize}</StyledHoverPlaceholder>

      <svg
        width={small ? '21' : '42'}
        height={small ? '22' : '42'}
        className="shadow"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <StyledInnerCircle
            small={small}
            r={small ? '7' : '14'}
            cy={small ? '11' : '21'}
            cx={small ? '10' : '20'}
            strokeDasharray={strokeDasharray}
            dashArray={circumference}
            offset={offset}
          />
          <StyledCircle
            r={small ? '9' : '17'}
            cy={small ? '11' : '21'}
            cx={small ? '10' : '20'}
            strokeWidth={small ? '3' : '6'}
            stroke={arcColor}
            strokeDasharray={strokeDasharray}
            dashArray={circumference}
            offset={offset}
          />
        </g>
      </svg>

      <StyledReactTooltip
        id={chartId}
        type="dark"
        effect="solid"
        place="bottom"
        disable={isMobile}
      >
        {`${index} Match${index > 1 ? 'es' : ''} ago`}
      </StyledReactTooltip>
    </StyledWrapper>
  );
};
export default DonutChart;
