import styled, { css } from 'styled-components';

export const StyledDownloadContainer = styled.div`
  background-color: ${({ isPremium }) => (isPremium ? '#000' : '#E5E5E5')};
  border: 1px solid ${({ isPremium }) => (isPremium ? '#000' : '#B6B6B6')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 5px;
  border-radius: 3px;
  cursor: pointer;
  ${({ isPremium }) => {
    if (!isPremium) {
      return css`
        svg {
          fill: #b6b6b6;
          path {
            fill: #b6b6b6;
          }
        }
      `;
    }
  }}
`;
