import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const StyledDetailsContainer = styled.div`
  width: 225px;
  padding: 30px 25px;
  border-radius: 8px;
  background-image: linear-gradient(to top, #000000, #303030);
  color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 49%;
  @media only screen and (max-width: 991px) {
    width: 50%;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 10px;
  }
`;
export const StyledDetailsNumber = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #373737;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const StyledFirstNumber = styled.span`
  font-size: 38px;
  font-family: Gilroy;
  font-weight: 800;
  margin-right: 5px;
`;

export const StyledLastNumber = styled.span`
  font-size: 15px;
  font-family: Gilroy;
`;

export const StyledDetailsDescription = styled.div`
  text-transform: uppercase;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 2px;
`;
export const StyledDetailsDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-left: 10px;
    min-width: 12px;
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  max-width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;

    border-radius: 6px;
    opacity: 1;
  }
`;
