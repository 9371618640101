import styled from 'styled-components';
import FootballBg from '../../assets/img/football-bg.png';
import FootballBgMobile from '../../assets/img/football-bg-mobile.png';
import { ReactComponent as Logo } from '../../assets/img/tol-logo.svg';



export const StyledLoginContainer = styled.main`
  z-index: 1;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  background-image: url(${FootballBg});
  background-color: #fff;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    background-image: url(${FootballBgMobile});
  }
`;

export const StyledTOLLogo = styled(Logo)`
  width: 185px;
  height: auto;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 4;
  width: 100%;
  padding: 10px 36px;
  height: 46px;
  background: #101010;
  @media screen and (max-width: 1100px) {
    padding: 10px 24px 10px 36px;
    justify-content: space-between;
  }
  @media screen and (max-width: 426px) {
    min-height: 55px;
    padding: 19px 24px 19px 36px;
  }
`;

export const StyledLeftHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 50px;
    background-color: #858585;
    border-radius: 6px;
    padding: 10px;
    margin-right: 10px;
  }
`;