import React, { useMemo } from 'react';

import Table from './Table';
import {
  getTransfersOfferType,
  transferStatusConverter,
} from '../../../../../../service/utils';

const DealsTable = ({ deals, teamId }) => {
  const data = useMemo(
    () =>
      deals &&
      deals.map((item) => ({
        date: item.createdAt,
        dealType: getTransfersOfferType(
          teamId,
          item.sending_team._id,
          item.category
        ),
        playerName: item.player.name,
        playerId: item.player._id,
        amount: item.transfer_fee_offer,
        status: transferStatusConverter[item.status],
      })),
    [deals, teamId]
  );
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Deal type',
        accessor: 'dealType',
      },
      {
        Header: 'Player Name',
        accessor: 'playerName',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  );
  return <Table data={data} columns={columns} />;
};

export default DealsTable;
