import React from 'react';
import {
  FINANCE_LOCATION,
  HOME_LOCATION,
  SQUAD_LOCATION,
  STRATEGY_LOCATION,
  TRANSFER_DEALS_LOCATION,
  TRANSFER_SEARCH_LOCATION,
} from './constants';

export const tourSteps = (location, isSidebarOpen, isMobile) => {
  switch (true) {
    // Home page for desktop
    case location === HOME_LOCATION && !isMobile:
      return [
        {
          target: '.sidebar',
          disableBeacon: true,
          content: 'Main menu to navigate through your club’s portal',
          placement: 'right',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: -5,
        },
        {
          target: '.rank-table',
          disableBeacon: true,
          content:
            'Current rankings for your league. There are three leagues in each server with promotions and demotions.',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.last-match-section',
          disableBeacon: true,
          content: 'Results and key takeaways from your club’s last match.',
          placement: 'top',
          spotlightPadding: -5,
        },
        {
          target: '.next-match-section',
          disableBeacon: true,
          content: 'Next match your club is playing.',
          placement: 'top',
          spotlightPadding: -5,
        },
      ];
    // Home page for mobile
    case location === HOME_LOCATION && isMobile:
      return [
        {
          target: '.mobile-sidebar',
          disableBeacon: true,
          content: 'Open your club portal’s menu',
          placement: 'bottom',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: 0,
        },
        {
          target: '.next-match-section',
          disableBeacon: true,
          content: 'Next match your club is playing',
          placement: 'top',
          spotlightPadding: -5,
        },
        {
          target: '.rank-table',
          disableBeacon: true,
          content:
            'Current rankings for your league. There are three leagues in each server with promotions and demotions.',
          placement: 'bottom',
          spotlightPadding: -5,
        },

        {
          target: '.last-match-section',
          disableBeacon: true,
          content: 'Results and key takeaways from your club’s last match',
          placement: 'top',
          spotlightPadding: -5,
        },
      ];
    // Squad page
    case location === SQUAD_LOCATION && isSidebarOpen:
      return [
        {
          target: '.grid-chart-section',
          disableBeacon: true,
          placement: 'top',
          content: 'Player rating progression over the past few seasons.',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: -5,
        },
        {
          target: '.key-statistics-section',
          disableBeacon: true,
          content: 'Player’s key statistics for the current season.',
          placement: 'left',
          spotlightPadding: -5,
        },
        {
          target: '.player-programs-section',
          disableBeacon: true,
          content:
            'Player programs allow for accelerated development for younger players and delayed decline for older ones.',
          placement: 'left',
          spotlightPadding: -5,
        },
        {
          target: '.player-list-section',
          disableBeacon: true,
          content:
            'List a player for sale to let other clubs know you are seeking offers.',
          placement: 'left',
          spotlightPadding: -5,
        },
        {
          target: '.release-section',
          disableBeacon: true,
          content:
            'Releasing a player will make him/her available to other clubs to sign for free. Player releases are final.',
          placement: 'left',
          spotlightPadding: 5,
        },
      ];
    // Squad location for mobile
    case location === SQUAD_LOCATION && !isSidebarOpen && isMobile:
      return [
        {
          target: '.view-squad',
          disableBeacon: true,
          content: 'Clubs can carry up to 27 players on their rosters',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: 5,
        },

        {
          target: '.squad-table tbody > tr:first-of-type .rating-cell-mobile',
          disableBeacon: true,
          content:
            'RATING indicates how "good" a player is and can range from WEAKEST to LEGEND.',
          placement: 'left',
          spotlightPadding: 3,
        },
        {
          target:
            '.squad-table tbody > tr:first-of-type .potential-cell-mobile',
          disableBeacon: true,
          content:
            'POTENTIAL shows how “good” a player might become over time. Players develop by playing matches. It’s rare, but possible, for players to develop beyond their potential',
          placement: 'left',
          spotlightPadding: 3,
        },
        {
          target: '.squad-table tbody > tr:first-of-type .fitness-cell-mobile',
          disableBeacon: true,
          content:
            'FITNESS is an indication of how fit, or tired, a player is. It can range from INJURED to GREAT. Fitness impacts a player’s performance during matches. Players recover fitness when not playing matches',
          placement: 'left',
          spotlightPadding: 3,
        },
        {
          target: '.chart-section',
          disableBeacon: true,
          content:
            'MEDIA RATING is a measure of how well a player played in recent matches. 10 means the player did exceptionally well. 1 means the player performed poorly',
          placement: 'bottom',
          spotlightPadding: 3,
        },
        {
          target: '.set-strategy',
          disableBeacon: true,
          content:
            'Go to Set Strategy to prepare your team for your next match',
          placement: 'bottom',
          spotlightPadding: 5,
        },
      ];
    // Squad page for desktop
    case location === SQUAD_LOCATION && !isSidebarOpen && !isMobile:
      return [
        {
          target: '.squad-table',
          disableBeacon: true,
          content: 'Clubs can carry up to 27 players on their rosters',
          placement: 'bottom',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: -5,
        },
        {
          target: '.rating-cell',
          disableBeacon: true,
          content:
            'RATING indicates how "good" a player is and can range from WEAKEST to LEGEND.',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.potential-cell',
          disableBeacon: true,
          content:
            'POTENTIAL shows how “good” a player might become over time. Players develop by playing matches. It’s rare, but possible, for players to develop beyond their potential',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.fitness-cell',
          disableBeacon: true,
          content:
            'FITNESS is an indication of how fit, or tired, a player is. It can range from INJURED to GREAT. Fitness impacts a player’s performance during matches. Players recover fitness when not playing matches',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.media-rating-cell',
          disableBeacon: true,
          content:
            'MEDIA RATING is a measure of how well a player played in recent matches. 10 means the player did exceptionally well. 1 means the player performed poorly',
          placement: 'bottom',
          spotlightPadding: -5,
        },
      ];
    // Strategy page for desktop
    case location === STRATEGY_LOCATION && !isMobile:
      return [
        {
          target: '.formation-section',
          disableBeacon: true,
          content:
            'FORMATION allows you to choose how you want to organize your team on the field. There is no perfect formation. It depends on the players you have and the formation your next opponent might be using',
          placement: 'bottom',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: -5,
        },
        {
          target: '.tactic-section',
          disableBeacon: true,
          content:
            'TACTIC allows to change how your team behaves during the match.',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.replace-player-section',
          disableBeacon: true,
          content:
            'These are the players you have at your disposal. Select who will start in your next match.',

          spotlightPadding: 0,
        },
        {
          target: '.players-sort-section',
          disableBeacon: true,
          content: 'Sort players by zone for faster selection',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.formations-section',
          disableBeacon: true,
          content:
            'Show the formation and players your opponent used in their last match',
          placement: 'bottom',
          spotlightPadding: 5,
        },
        {
          target: '.save-strategy-section',
          disableBeacon: true,
          content: 'Don’t forget to save your strategy!',
          placement: 'bottom',
          spotlightPadding: 5,
        },
      ];
    // Strategy page for mobile
    case location === STRATEGY_LOCATION && isMobile:
      return [
        {
          target: '.formation-section',
          disableBeacon: true,
          content:
            'FORMATION allows you to choose how you want to organize your team on the field. There is no perfect formation. It depends on the players you have and the formation your next opponent might be using',
          placement: 'bottom',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: 0,
        },
        {
          target: '.tactic-section',
          disableBeacon: true,
          content:
            'TACTIC allows to change how your team behaves during the match.',
          placement: 'bottom',
          spotlightPadding: 0,
        },
        {
          target: '.calendar-section',
          disableBeacon: true,
          content:
            'CALENDAR shows you upcoming matches so you can optimize which players to line up in your next match.',

          spotlightPadding: 0,
        },
        {
          target: '.home-dcr',
          disableBeacon: true,
          content: 'Tap on a position to add or change a player',
          placement: 'bottom',
          spotlightPadding: 0,
        },
        {
          target: '.save-strategy-section',
          disableBeacon: true,
          content: 'Don’t forget to save your strategy!',
          placement: 'bottom',
          spotlightPadding: 0,
        },
      ];
    // Deals page
    case location === TRANSFER_DEALS_LOCATION:
      return [
        {
          target: '.open-deals-section',
          disableBeacon: true,
          content:
            'This area includes outstanding transfers including offers you have made awaiting response or offers made to you by other clubs',
          placement: 'bottom',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
        },
        {
          target: '.closed-deals-section',
          disableBeacon: true,
          content: 'Summary of transfers your club has made in the past',
          placement: 'bottom',
        },
      ];
    // Search page for desktop
    case location === TRANSFER_SEARCH_LOCATION && !isMobile:
      return [
        {
          target: '.filter-section',
          disableBeacon: true,
          content:
            'Database of all the players in your server. Use the filtering section to find players that meet your needs',
          placement: 'bottom',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
        },
        {
          target: '.row-number-0.search-cell',
          disableBeacon: true,
          content: 'Click on a row to expand the player’s card',
          placement: 'bottom',
        },
        {
          target: '.action-cell',
          disableBeacon: true,
          content:
            'When ready, make an offer. Note that it is HIGHLY recommended to negotiate transfers with other club managers in Discord before submitting offers',
          placement: 'bottom',
        },
      ];
    // Finance page
    case location === FINANCE_LOCATION:
      return [
        {
          target: '.cash-balance-section',
          disableBeacon: true,
          content: 'Historical and forecasted cash balance for your club',
          placement: 'bottom',
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          spotlightPadding: -5,
        },
        {
          target: '.revenues-section',
          disableBeacon: true,
          content: 'Detailed revenues and expenses for the current season',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.transactions-section',
          disableBeacon: true,
          content:
            'Register includes all financial transactions your club made since inception',
          placement: 'bottom',
          spotlightPadding: -5,
        },
        {
          target: '.send-money-section',
          disableBeacon: true,
          content:
            'Managers who have been at their club for more than one week  have the ability to send up to $5 million per week to other clubs',
          placement: 'bottom',
          spotlightPadding: -5,
        },
      ];
    default:
      return [];
  }
};
