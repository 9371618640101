import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Training';

const initialValue = {
  loading: true,
  teams: {},
  trainingOfferLoading: false,
  trainingOfferResponse: null,
};
export const training = handleActions(
  {
    [action.getTrainingTeamsLoading]: (draft, { payload }) => {
      draft.loading = payload;
    },
    [action.getTrainingTeamsData]: (draft, { payload }) => {
      draft.teams = payload;
    },
    [action.trainingOfferLoading]: (draft, { payload }) => {
      draft.trainingOfferLoading = payload;
    },
    [action.trainingOfferResponse]: (draft, { payload }) => {
      draft.trainingOfferResponse = payload;
    },
  },
  initialValue
);
