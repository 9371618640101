import styled from 'styled-components';

export const StyledFormContainer = styled.div`
  position: relative;
  padding: 25px;

  @media only screen and (max-width: 768px) {
    padding: 0 15px 15px 15px;
  }
`;

export const StyledFormParagraph = styled.p`
  color: #666;
  display: flex;
  justify-content: center;
  font-family: Gilroy;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
`;

export const StyledInputContainer = styled.div`
  position: relative;
  // padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const StyledInput = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  text-align: left;

  width: calc(100% - 50px);
  height: 40px;
  position: relative;
  background: #ffffff;
  border: 0.1em solid rgba(0, 0, 0, 0.26);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.015em;
  color: #666666;

  padding: 13px 8px;
  margin: 25px 0 15px 0;
  outline: none;
  &:focus {
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);
  }
`;

export const StyledValidation = styled.span`
  font-size: 13px;
  color: #ea4335;
  position: absolute;
  left: 0;
  top: 100%;
  bottom: 0;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: start;
`;
