import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { StyledButton } from '../../globalStyled';
import { ReactComponent as Cross } from '../../assets/img/cross.svg';
import { ReactComponent as WhiteCross } from '../../assets/img/white-cross.svg';
import { ReactComponent as Send } from '../../assets/img/Send.svg';
import { ReactComponent as DiscordLogo } from '../../assets/img/discord-logo.svg';

import NumberRangeSelect from '../NumberRangeSelect';

export const StyledDiscordLogin = styled.span`
  font-family: 'Gilroy', sans-serif;
  color: #5865f2;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  font-size: 13px;
`;
export const StyledText = styled.p``;
export const StyledLoader = styled(Loader)`
  position: relative;
`;

export const StyledParagraph = styled.p`
  text-align: center;
  margin: 5px 0;
  line-height: 1.87;
  font-family: 'Gilroy', sans-serif;
  font-size: 15px;

  ${({ lineHeight }) => {
    if (lineHeight) {
      return css`
        line-height: ${lineHeight};
      `;
    }
  }}
  ${({ small }) => {
    if (small) {
      return css`
        font-size: 13px;
      `;
    }
  }}
  ${({ aligned }) => {
    if (aligned) {
      return css`
        text-align: ${aligned};
      `;
    }
  }}
  ${({ customColor }) => {
    if (customColor) {
      return css`
        color: ${customColor};
      `;
    }
  }}
`;

export const StyledHeader = styled.h3`
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  ${({ theme }) => {
    if (theme === 'dark') {
      return css`
        color: #fff;
      `;
    }
  }}
  @media only screen and (max-width: 768px) {
    font-size: 22px;
    text-align: center;
  }
`;
export const StyledHeaderWithLogo = styled(StyledHeader)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 20px;
`;

export const StyledPopUpConfirmation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  transform: translateX(calc(-100% - 20px));
  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 22px;
  > p {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.22px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
    text-align: center;
    user-select: none;
  }
  ${({ clickPopUpSend }) => {
    if (clickPopUpSend) {
      return css`
        transform: translateX(calc(0%));
      `;
    }
  }}
`;

export const StyledPopUpContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  border-radius: 5px;
  ${({ noOverflow }) => {
    if (noOverflow) {
      return css`
        overflow: visible;
      `;
    }
  }}
`;

export const StyledCross = styled(Cross)`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  z-index: 2;
`;
export const StyledWhiteCross = styled(WhiteCross)`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  z-index: 2;
`;

export const StyledPopUpBlockNoPadding = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 34px 44px 25px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.26);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  @media screen and (max-width: 425px) {
    width: 90%;
  }
`;

export const StyledMultisectionPopup = styled(StyledPopUpBlockNoPadding)`
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;
  max-width: 750px;
  height: 100%;
`;

export const StyledPopUpBlock = styled(StyledPopUpBlockNoPadding)`
  padding: 34px 34px 25px;
  ${({ maxWidth }) => {
    if (maxWidth) {
      return css`
        max-width: ${maxWidth}px;
      `;
    }
  }}
  ${({ darkBg }) => {
    if (darkBg) {
      return css`
        background: #191919;
        ${StyledParagraph},
        ${StyledHeaderWithLogo} {
          color: #fff;
        }
      `;
    }
  }}
  ${({ maxHeight }) => {
    if (maxHeight) {
      return css`
        max-width: ${maxHeight}px;
      `;
    }
  }}
  ${({ paddingAround }) => {
    if (paddingAround) {
      return css`
        padding: ${paddingAround};
      `;
    }
  }}
  ${({ theme }) => {
    if (theme === 'dark') {
      return css`
        background: #191919;
        color: #fff;
      `;
    }
  }}
`;

export const StyledPopUp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.8);
`;

export const StyledButtonContainer = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
`;

export const StyledTableButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin: 15px 0;
  padding: 12px 37px;
  background: #ffffff;
  text-decoration: none;
  font-family: 'Gilroy', sans-serif;
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
  transition: 0.3s;
  text-align: center;
  min-width: 180px;
  width: fit-content;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${({ primary }) => {
    if (primary)
      return css`
        background: #000;
        color: #fff;
      `;
  }}

  ${({ clicked }) => {
    if (clicked)
      return css`
        pointer-events: none;
      `;
  }}
`;

export const StyledCSSHidePopUp = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.3);
  ${({ needtoshow }) => {
    if (needtoshow)
      return css`
        display: flex;
        justify-content: center;
        align-items: center;
      `;
  }}
`;

export const StyledCSSPopUp = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.3);
`;

export const StyledSend = styled(Send)`
  position: relative;
  right: 40px;
  cursor: pointer;
  transition: 0.5s;
  ${({ clickPopUpSend }) => {
    if (clickPopUpSend)
      return css`
        transform: scale(0);
      `;
  }}
`;

export const StyledPopUpInputContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledInput = styled.div`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  width: 100%;
  position: relative;
  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14.22px;
  line-height: 100%;
  letter-spacing: 0.015em;
  color: #949494;
  cursor: pointer;
  padding: 13px 43px 13px 8px;
`;

export const StyledInputField = styled.input`
  width: 100%;
  color: #000;
  position: relative;
  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14.22px;
  line-height: 100%;
  letter-spacing: 0.015em;
  padding: 13px 43px 13px 8px;
`;

export const StyledCopyText = styled.div`
  margin-top: 20px;
  width: 100%;
  position: relative;
  background: #ffffff;
  border: solid 0.8px #5f5f5f;
  border-radius: 5px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.015em;
  color: #949494;
  cursor: pointer;
  padding: 13px 43px 13px 8px;
`;

export const StyledNumberFormat = styled(NumberFormat)`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  width: 100%;
  position: relative;
  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14.22px;
  line-height: 100%;
  letter-spacing: 0.015em;
  color: #949494;

  padding: 13px 43px 13px 8px;
`;

export const StyledSpan = styled.span`
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 600;
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const StyledRangeSelect = styled(NumberRangeSelect)`
    input {
      width: 160px;
      padding: 10px 5px;
      font-size: 22px;
      margin: 0 25px;
    }
    button {
      padding: 2px 4px;
      min-width: 22px;
    }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledPopupNoPadding = styled(StyledPopUpBlock)`
  padding: 0;
`;

export const DiscordLogoIcon = styled(DiscordLogo)`
  width: 90px;
`;

export const StyledRedAlert = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  color: #ea4335;
`;
export const StyledBlackAlert = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  color: #000;
`;

export const StyledSubmitButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: 0;
  font-size: 13px;
  padding: 2px 2px;
  margin: 0;
  height: 16px;
  min-width: 60px;
  max-width: 60px;
  width: 60px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  border: none;
  ${({ isGrey }) => {
    if (isGrey) {
      return css`
        background-color: #ebebeb;
        color: #989898;
        border: 1px solid #ebebeb;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
      `;
    }
  }}
  ${({ isCompleted }) => {
    if (isCompleted) {
      return css`
        background-color: #fabb05;
        color: #000;
      `;
    }
  }}
`;

export const StyledRangeLabel = styled.label`
  color: #b2b2b2;
  font-size: 11px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  
`
export const StyledPopupButton = styled(StyledButton)`
  min-width: 88px;
  width: 49%;
  max-width: 100px;
  margin: 0 5px;
  flex-direction: row;
  font-size: 10px;
  height: 24px;
`;