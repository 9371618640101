import {
  youthcampPlayers,
  youthcampRegister,
  youthcampScout,
  youthcampUnscout,
  youthcampStatus,
  youthcampBid,
  isUserRegistered,
} from '../service/links';
import { headers } from './requestConfig';

export const getYouthCampPlayers = async () => {
  const response = await fetch(youthcampPlayers, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};

export const registerYouthCamp = async (scouts) => {
  const response = await fetch(youthcampRegister, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      scouts,
    }),
  });
  return response;
};
export const scoutYouthCamp = async (playerID) => {
  const response = await fetch(youthcampScout, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      player: playerID,
    }),
  });
  return response;
};
export const unscoutYouthCamp = async (playerID) => {
  const response = await fetch(youthcampUnscout, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      player: playerID,
    }),
  });
  return response;
};
export const bidYouthCamp = async ({ bidsVal, playerID }) => {
  const response = await fetch(youthcampBid, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({
      bids: bidsVal,
      player: playerID,
    }),
  });
  return response;
};
export const getYouthCampStatus = async () => {
  const response = await fetch(youthcampStatus, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
export const checkIfUserRegistered = async () => {
  const response = await fetch(isUserRegistered, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
