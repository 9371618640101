import React from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { StyledFixturesWrapper } from './styled';

import FixtureSlider from '../../../../components/FixtureSlider';

const CalendarContainer = ({ fixturesData, teamId, isLoading }) => {
  const dateAtUTC = moment.utc();
  // get the zone offsets for this time, in minutes
  const tzOffset = fixturesData.timezone
    ? moment.tz.zone(fixturesData.timezone).utcOffset(dateAtUTC)
    : '';
  const formattedCurrentDate = moment(fixturesData.currentDate);

  const timezoneDateRelatedToUTC =
    tzOffset < 0
      ? `${formattedCurrentDate
          .add(tzOffset / 60, 'hours')
          .format('ddd, DD MMM YYYY')}`
      : `${formattedCurrentDate
          .subtract(tzOffset / 60, 'hours')
          .format('ddd, DD MMM YYYY')}`;
  const createDatesArray = React.useCallback(() => {
    const datesArray = !isEmpty(fixturesData.matches)
      ? [
          ...fixturesData.matches
            .map((fixture) =>
              moment(fixture.match_date).format('ddd, DD MMM YYYY')
            )
            .sort((a, b) => new Date(a) - new Date(b)),
        ]
      : [];
    let sortedDates;
    if (
      !datesArray.find((todayDate) => todayDate === timezoneDateRelatedToUTC)
    ) {
      datesArray.push(timezoneDateRelatedToUTC);
      sortedDates = datesArray.sort((a, b) => new Date(a) - new Date(b));
    } else {
      sortedDates = datesArray;
    }
    const indexOfToday = sortedDates.indexOf(timezoneDateRelatedToUTC);
    return [sortedDates, indexOfToday];
  }, [fixturesData.matches, timezoneDateRelatedToUTC]);
  return (
    <StyledFixturesWrapper>
      <FixtureSlider
        userTeamId={teamId}
        matches={fixturesData.matches}
        datesData={createDatesArray()}
        isLoading={isLoading}
      />
    </StyledFixturesWrapper>
  );
};

export default React.memo(CalendarContainer);
