import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isAndroid } from 'react-device-detect';
import isEmpty from 'lodash/isEmpty';
import usePortal from 'react-useportal';
import HomeTeam from './components/HomeTeam';
import AwayTeam from './components/AwayTeam';
import img from '../../../../../../assets/img/angels-cup.png';
import SwitchToggle from '../../../../../../components/SwitchToggle';
import { PlayerInfoPopupView, TeamInfoPopup } from '../../../../../../components/Popup';
import { StyledSwitchLabel } from '../../../../../../components/SideBar/components/RightSideBlock/pages/TeamSquad/styled';
import LiveMatch from '../../../../../../components/LiveMatch';
import ConfirmMatch from '../ConfirmMatchButton';
import {
  getOpponentStrategy,
  getOpponentSquad,
  myTeamTactic,
  getStrategyTactics,
  getOpponentTeamName,
  isFormationLoading,
  isAutoFormatEnabled,
  getCurrentMatchRunning,
  getCurrrentMatchDiscordChannel,
} from '../../../../../../redux/selector/Team';
import { getPopupByName } from '../../../../../../redux/selector/Popups';
import { isAuth, isPremiumUser } from '../../../../../../redux/selector/User';
import { getPlayerInformationSelector } from '../../../../../../redux/selector/League';
import {
  setAutoFormation,
  showMobileSidebarSection,
  setAutoFormationValue,
} from '../../../../../../redux/actions/Team';

import {
  StyledGamedField,
  StyledToggleTeamView,
  StyledFootballBG,
  StyledFootballBGOpposite,
  StyledFootballBGMobile,
  StyledSaveFormation,
  StyledLeftSection,
  StyledRightSection,
  StyledMobileFormationButton,
  StyledMobileButtonSection,
  StyledRightArrow,
  StyledReactTooltip,
  StyledInfoDark,
  StyledRightWhiteArrow,
  StyledSwitchContainer,
  StyledLeftSponsorLogo,
  StyledRightSponsorLogo,
  StyledLogoWrapper,
  StyledMarkup,
} from './styled';
import { POPUPS, STRATEGY_NAMES } from '../../../../../../service/constants';

export default function GameFields({ MyStrategy, MyTeam, activeDraggingId }) {
  const { PLAYER_INFO_POPUP_VIEW, TEAM_INFO_POPUP } = POPUPS;
  const isAutoFormationEnabled = useSelector(isAutoFormatEnabled);

  const OpponentTeam = useSelector(getOpponentSquad);
  const OpponentStrategy = useSelector(getOpponentStrategy);
  const discordChannel = useSelector(getCurrrentMatchDiscordChannel);
  const opponentTeamName = useSelector(getOpponentTeamName);
  const currentMatch = useSelector(getCurrentMatchRunning);
  const isPremium = useSelector(isPremiumUser);
  const isAuthenticated = useSelector(isAuth);
  const playerInfoPopupViewData = useSelector(getPopupByName(PLAYER_INFO_POPUP_VIEW));
  const tactics = useSelector(getStrategyTactics);
  const isLoading = useSelector(isFormationLoading);
  const { mindset, effort } = tactics;
  const active = useSelector(myTeamTactic);
  const playerInfoData = useSelector(getPlayerInformationSelector);

  const teamInfoPopupData = useSelector(getPopupByName(TEAM_INFO_POPUP));

  const [teamView, setTeamView] = useState('myTeam');

  const dispatch = useDispatch();
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  const autoPickHandler = (e) => {
    if (!isPremium) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (window.navigator.userAgent.includes('wv') && isAndroid) {
        window.open(
          'inappsubscription://?package=com.example.app.webview-master&successful_url=https://www.theopenleague.com/home&expired_url=https://www.yahoo.com',
          '_blank'
        );
      } else {
        window.open('https://www.patreon.com/join/theopenleague?', '_blank');
      }
    } else {
      dispatch(setAutoFormationValue(!isAutoFormationEnabled));
      dispatch(setAutoFormation(STRATEGY_NAMES[MyStrategy]));
    }
  };

  const goToSponsorPage = () => {
    window.open('https://angelscup.com/', '_blank');
  };

  return (
    <>
      {!isEmpty(playerInfoPopupViewData) && (
        <Portal>
          <PlayerInfoPopupView popupName={POPUPS.PLAYER_INFO_POPUP_VIEW} data={playerInfoData} isMobile={isMobile} />
        </Portal>
      )}
      {!isEmpty(teamInfoPopupData) && (
        <Portal>
          <TeamInfoPopup popupName={TEAM_INFO_POPUP} />
        </Portal>
      )}
      <StyledGamedField
        mobileDevice={isMobile}
        className={`football-container outline-border ${MyStrategy && `my-strategy-${MyStrategy}`}  ${OpponentStrategy && `opponent-strategy-${OpponentStrategy}`
          }`}
      >
        {isMobile ? (
          <>
            <StyledSaveFormation mobileDevice>
              <StyledLeftSection mobileDevice>
                {!isEmpty(currentMatch) ? (
                  <LiveMatch
                    theme="light"
                    title="Follow Live"
                    discordChannelId={discordChannel}
                    isAuthenticated={isAuthenticated}
                  />
                ) : (
                  <h3>Select starting 11</h3>
                )}
                <ConfirmMatch activeItem={active} mindset={mindset} effort={effort} mobile />
              </StyledLeftSection>

              <StyledRightSection mobileDevice>
                <StyledSwitchContainer mobileDevice>
                  <StyledSwitchLabel>Delegate to Chris</StyledSwitchLabel>

                  <SwitchToggle
                    isOnAndOffLabelVisible
                    id="auto-pick"
                    toggled={isAutoFormationEnabled}
                    onChange={(e) => autoPickHandler(e)}
                    isDisabled={isLoading}
                  />
                </StyledSwitchContainer>
                <StyledMobileFormationButton
                  primary
                  onClick={() => {
                    dispatch(showMobileSidebarSection('list'));
                  }}
                >
                  Player list <StyledRightWhiteArrow />
                </StyledMobileFormationButton>
              </StyledRightSection>
            </StyledSaveFormation>
            <StyledMobileButtonSection>
              <StyledMobileFormationButton
                className="formation-section"
                onClick={() => {
                  dispatch(showMobileSidebarSection('formation'));
                }}
              >
                {MyStrategy}
                <StyledRightArrow />
              </StyledMobileFormationButton>
              <StyledMobileFormationButton
                className="tactic-section"
                onClick={() => {
                  dispatch(showMobileSidebarSection('tactic'));
                }}
              >
                Tactic
                <StyledRightArrow />
              </StyledMobileFormationButton>

              <StyledMobileFormationButton
                className="calendar-section"
                onClick={() => {
                  dispatch(showMobileSidebarSection('calendar'));
                }}
              >
                Calendar
                <StyledRightArrow />
              </StyledMobileFormationButton>
            </StyledMobileButtonSection>
          </>
        ) : (
          <StyledSaveFormation>
            <StyledLeftSection>
              {!isEmpty(currentMatch) ? (
                <LiveMatch
                  theme="light"
                  title="Watch Live on Discord"
                  discordChannelId={discordChannel}
                  isAuthenticated={isAuthenticated}
                />
              ) : (
                <h3>Pick starting 11</h3>
              )}
            </StyledLeftSection>
            <StyledRightSection>
              <StyledSwitchContainer>
                <StyledSwitchLabel labelWidth="130">
                  Delegate to Chris
                  <StyledInfoDark iconsize="11" data-tip clickable data-for="auto-pick" />
                </StyledSwitchLabel>

                <SwitchToggle
                  isOnAndOffLabelVisible
                  id="auto-pick"
                  toggled={isAutoFormationEnabled}
                  onChange={(e) => autoPickHandler(e)}
                  isDisabled={isLoading}
                />
              </StyledSwitchContainer>
              <StyledReactTooltip id="auto-pick" type="light" effect="solid" place="bottom">
                At the end of each match, your assistant manager, Chris, will attempt to pick the best 11 players for
                your next match
              </StyledReactTooltip>
              <ConfirmMatch activeItem={active} mindset={mindset} effort={effort} />
            </StyledRightSection>
          </StyledSaveFormation>
        )}
        {!isMobile && (
          <StyledToggleTeamView className="formations-section">
            <button
              className={teamView === 'myTeam' ? 'active' : ''}
              onClick={() => setTeamView('myTeam')}
              type="button"
            >
              Your formation
            </button>
            <button
              className={teamView === 'opponnentTeam' ? 'active' : ''}
              onClick={() => setTeamView('opponnentTeam')}
              type="button"
            >
              Opponent’s formation
              {teamView === 'myTeam' && (
                <>
                  <StyledReactTooltip id="opponent-formation" type="light" effect="solid" place="bottom">
                    Show the formation and players your opponent used in their last match
                  </StyledReactTooltip>

                  <StyledInfoDark iconsize="14" data-tip clickable data-for="opponent-formation" />
                </>
              )}
            </button>
          </StyledToggleTeamView>
        )}
        <StyledLogoWrapper>
          <StyledLeftSponsorLogo onClick={goToSponsorPage} src={img} alt="sponsor-logo" />
          <StyledRightSponsorLogo onClick={goToSponsorPage} src={img} alt="sponsor-logo" />
        </StyledLogoWrapper>
        <StyledMarkup mobileDevice={isMobile}>
          {isMobile ? <StyledFootballBGMobile /> : <StyledFootballBG />}
        </StyledMarkup>
        <div className="playing-field">
          <HomeTeam strategy={MyStrategy} teamView={teamView} team={MyTeam} activeDraggingId={activeDraggingId} />
        </div>
        {teamView === 'opponnentTeam' && OpponentTeam && OpponentTeam.length > 0 && (
          <div className="playing-field away">
            <StyledMarkup isOpponent={teamView === 'opponnentTeam'}>
              <StyledFootballBGOpposite />
            </StyledMarkup>
            <AwayTeam strategy={OpponentStrategy} team={OpponentTeam} opponentTeamName={opponentTeamName} />
          </div>
        )}
      </StyledGamedField>
    </>
  );
}
