import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { headerShowPanel } from '../../redux/actions/Sidebar';
import { isHeaderVisible } from '../../redux/selector/Sidebar';
import {
  StyledNavLink,
  StyledLinksContainer,
  StyledReactTooltip,
  StyledNavContainer,
  StyledTabsInfo,
} from './styled';

export default function PageSideBarNavigation({ links }) {
  // REDUX
  const dispatch = useDispatch();
  const showHeader = useSelector(isHeaderVisible);
  React.useEffect(() => {
    if (!showHeader) {
      dispatch(headerShowPanel(true));
    }
  }, [dispatch, showHeader]);
  // REDUX
  return (
    <StyledLinksContainer>
      {links.map(
        ({ to, text, isDisabled, showTooltip, tooltipContent = '' }, index) =>
          showTooltip ? (
            <StyledNavContainer key={text}>
              <StyledNavLink
                disabled={isDisabled}
                key={index}
                to={to}
                activeClassName="active-button"
              >
                <StyledReactTooltip
                  id={to}
                  type="light"
                  effect="solid"
                  place="bottom"
                >
                  {tooltipContent}
                </StyledReactTooltip>
                {text}
                <StyledTabsInfo
                  iconsize="10"
                  data-tip
                  clickable
                  data-for={to}
                />
              </StyledNavLink>
            </StyledNavContainer>
          ) : (
            <StyledNavContainer
              className={text.replace(/ /g, '-').toLowerCase()}
              key={text}
            >
              <StyledNavLink
                disabled={isDisabled}
                key={index}
                to={to}
                activeClassName="active-button"
              >
                {text}
              </StyledNavLink>
            </StyledNavContainer>
          )
      )}
    </StyledLinksContainer>
  );
}
