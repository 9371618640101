import React, { useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledButton } from '../../../globalStyled';
import { hoursList } from '../../../service/data';
import { numberWithCommas } from '../../../service/utils';
import RadioButton from '../../RadioButton';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
  StyledRangeSelect,
  StyledInputWrapper,
} from '../styled';
// TODO move calendar to separate container
import {
  StyledInput,
  StyledSelect,
  StyledDateSelectContainer,
  StyledClock,
  CalendarIcon,
  StyledSelectContainer,
  StyledCalendarContainer,
} from './styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { sendLoanRequest } from '../../../redux/actions/Transfers';
import { getPopupByName } from '../../../redux/selector/Popups';
import { endOfSeason } from '../../../redux/selector/Transfers';
import 'react-calendar/dist/Calendar.css';

const LoanPopup = ({ playerId, playerName, popupName, team }) => {
  const DEFAULT_STEP = 'default';
  const LOAN_DATES_SELECT_STEP = 'loanDatesSelect';
  const DATE_STEP = 'date';
  const CONFIRMATION_STEP = 'confirmation';
  const COMPLETE_STEP = 'complete';
  const [step, showStep] = useState(DEFAULT_STEP);
  const [isCalendarVisible, showCalendar] = useState(false);
  const [isEndOfSeasonDate, setEndOfSeasonDate] = useState(true);
  const [amount, setAmount] = useState(0);
  const [date, selectDate] = useState();
  const [hours, setHours] = useState();
  const { error, isLoading } = useSelector(getPopupByName(popupName));
  const endOfSeasonDate = useSelector(endOfSeason);
  const dispatch = useDispatch();

  const dateCallback = (e) => {
    selectDate(e);
    showCalendar(false);
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
  };
  const checkCallback = (e) => {
    setAmount(e);
  };
  const moveToDatesSelect = () => {
    showStep(LOAN_DATES_SELECT_STEP);
  };
  const confirmLoan = () => {
    showStep(CONFIRMATION_STEP);
  };
  const approveLoan = () => {
    const selectedDate = !isEmpty(hours)
      ? moment(date).add(1, 'days').add(hours.value, 'hours')
      : moment(date).add(1, 'days').startOf('day').format().toString();
    showStep(COMPLETE_STEP);
    dispatch(sendLoanRequest(playerId, amount, selectedDate));
  };
  const maxDate = new Date();
  maxDate.setDate(new Date().getDate() + 50);

  const handleHoursChange = (e) => {
    setHours(e);
  };

  const finishEndOfSeasonStep = () => {
    if (isEndOfSeasonDate) {
      selectDate(endOfSeasonDate);
      showStep(CONFIRMATION_STEP);
    } else {
      showStep(DATE_STEP);
    }
  };
  const backFromConfirmation = () => {
    selectDate(null);
    showStep(isEndOfSeasonDate ? LOAN_DATES_SELECT_STEP : DATE_STEP);
  };
  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer noOverflow>
          {isLoading ? (
            <StyledLoader
              type="Bars"
              color="#2AFC98"
              height={100}
              width={100}
            />
          ) : (
            <>
              {step === DEFAULT_STEP && !error && (
                <>
                  <StyledHeader>Loan Offer</StyledHeader>
                  <StyledParagraph>
                    You are offering to pay this amount to borrow this player
                    for a fixed amount of time.
                  </StyledParagraph>
                  <StyledRangeSelect
                    prefix="$"
                    className="loan-amount"
                    callback={(e) => checkCallback(e)}
                  />
                </>
              )}
              {step === LOAN_DATES_SELECT_STEP && !error && (
                <>
                  <StyledHeader>Loan Dates</StyledHeader>
                  <StyledParagraph>
                    Enter the day and time at which the player shall be
                    returned. The player will be returned to its club
                    automatically.
                    <RadioButton
                      onChange={() => setEndOfSeasonDate(!isEndOfSeasonDate)}
                      value={isEndOfSeasonDate}
                    >
                      <>
                        Return at the last day of the season –{' '}
                        <b>
                          {moment(endOfSeasonDate)
                            .add(1, 'days')
                            .format('D MMM YYYY')}
                        </b>
                      </>
                    </RadioButton>
                    <RadioButton
                      onChange={() => setEndOfSeasonDate(!isEndOfSeasonDate)}
                      value={!isEndOfSeasonDate}
                    >
                      Return at a custom date and time
                    </RadioButton>
                  </StyledParagraph>
                </>
              )}
              {step === DATE_STEP && !isEndOfSeasonDate && !error && (
                <>
                  <StyledHeader>Loan dates</StyledHeader>
                  <StyledParagraph>
                    Enter the day and time at which the player shall be
                    returned. The player will be returned to its club
                    automatically.
                  </StyledParagraph>
                  <StyledSelectContainer>
                    <StyledCalendarContainer>
                      <StyledInputWrapper>
                        <StyledInput
                          placeholder="Pick return date"
                          value={date ? moment(date).format('D MMM YYYY') : ''}
                          onClick={() => showCalendar(true)}
                        />
                        <CalendarIcon />
                      </StyledInputWrapper>
                      {isCalendarVisible && (
                        <Calendar
                          value={date || new Date()}
                          prev2Label={null}
                          next2Label={null}
                          className="loan-date"
                          onClickDay={(e) => {
                            dateCallback(e);
                          }}
                          minDate={new Date()}
                          maxDate={maxDate}
                          tileClassName="loan-tile"
                        />
                      )}
                    </StyledCalendarContainer>
                    <StyledDateSelectContainer>
                      <StyledSelect
                        onChange={handleHoursChange}
                        options={hoursList}
                        menuPlacement="auto"
                        placeholder="Pick return time"
                        isClearable
                        classNamePrefix="react-select"
                      />
                      <StyledClock />
                    </StyledDateSelectContainer>
                  </StyledSelectContainer>
                </>
              )}
              {step === CONFIRMATION_STEP && !error && (
                <>
                  <StyledHeader>Loan confirmation</StyledHeader>

                  <StyledParagraph>
                    You are sending a loan offer for{' '}
                    <strong>{playerName}</strong>. You will pay{' '}
                    <strong>{team.name} </strong>
                    the total sum of{' '}
                    <strong>${numberWithCommas(amount)}</strong> for the loan.
                    The loan will end on{' '}
                    <strong>
                      {moment(date).add(1, 'days').format('D MMM YYYY')}
                    </strong>
                    . Do you confirm?
                  </StyledParagraph>
                </>
              )}
              {step === COMPLETE_STEP && !error && (
                <>
                  <StyledHeader>Offer sent</StyledHeader>
                  <StyledParagraph>
                    Waiting for the club to respond
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {step === DEFAULT_STEP && !error && (
                  <>
                    <StyledButton basic onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton
                      primary
                      disabled={amount < 1}
                      onClick={() => moveToDatesSelect()}
                    >
                      Pick Return Date
                    </StyledButton>
                  </>
                )}
                {step === LOAN_DATES_SELECT_STEP && !error && (
                  <>
                    <StyledButton basic onClick={() => showStep(DEFAULT_STEP)}>
                      Back
                    </StyledButton>
                    <StyledButton
                      primary
                      onClick={() => finishEndOfSeasonStep()}
                    >
                      Set date
                    </StyledButton>
                  </>
                )}
                {step === DATE_STEP && !error && (
                  <>
                    <StyledButton
                      basic
                      onClick={() => showStep(LOAN_DATES_SELECT_STEP)}
                    >
                      Back
                    </StyledButton>
                    <StyledButton
                      primary
                      disabled={isEmpty(date) && !hours}
                      onClick={() => confirmLoan()}
                    >
                      Send Offer
                    </StyledButton>
                  </>
                )}
                {step === CONFIRMATION_STEP && !error && (
                  <>
                    <StyledButton basic onClick={() => backFromConfirmation()}>
                      Back
                    </StyledButton>
                    <StyledButton primary onClick={() => approveLoan()}>
                      Confirm
                    </StyledButton>
                  </>
                )}
                {error && (
                  <StyledButton primary onClick={() => hidePopup()}>
                    Got it!
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};
LoanPopup.propTypes = {
  playerId: PropTypes.string.isRequired,
  playerName: PropTypes.string.isRequired,
  popupName: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
};
export default React.memo(LoanPopup);
