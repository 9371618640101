import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as DoubleUpIcon } from './assets/img/double-up.svg';
import { ReactComponent as FlatIcon } from './assets/img/flat.svg';
import { ReactComponent as UpIcon } from './assets/img/up.svg';
import { ReactComponent as DownIcon } from './assets/img/down.svg';
import { ReactComponent as ErrorCrossIcon } from './assets/img/errorCross.svg';
import { ReactComponent as InfoIcon } from './assets/img/tooltip-info-white.svg';
import { ReactComponent as InfoIconDark } from './assets/img/tooltip-info-black.svg';
import { ReactComponent as CloseToast } from './assets/img/white-cross.svg';

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin: 15px 0;
  padding: 12px 20px;
  background: #ffffff;
  text-decoration: none;
  font-family: 'Gilroy', sans-serif;
  font-size: 15px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #000000;
  transition: 0.3s;
  text-align: center;
  min-width: 180px;
  width: fit-content;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @media (max-width: 768px) {
    min-width: 100px;
  }
  ${({ primary }) => {
    if (primary)
      return css`
        background: #000;
        color: #fff;
        &:disabled {
          background-color: #ebebeb;
          color: #989898;
          border: 1px solid #ebebeb;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
          cursor: default;
        }
      `;
  }}
  ${({ danger }) => {
    if (danger)
      return css`
        background-color: #e94235;
        border: 1px solid #e94235;
        color: #fff;
        box-shadow: none;
      `;
  }}
  ${({ blue }) => {
    if (blue)
      return css`
        background-color: #2253c7;
        border: 1px solid #2253c7;
        color: #fff;
        box-shadow: none;
      `;
  }}
  ${({ yellow }) => {
    if (yellow)
      return css`
        background: #ffc80f;
        color: #000;
        border: 1px solid #ffc80f;
        box-shadow: none;
      `;
  }}
  ${({ basic }) => {
    if (basic)
      return css`
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        color: #7f7f7f;
        box-shadow: none;
      `;
  }}
  ${({ customBgColor }) => {
    if (customBgColor)
      return css`
        background: ${customBgColor};
        border: 1px solid ${customBgColor};
        box-shadow: none;
      `;
  }}
  ${({ customFontColor }) => {
    if (customFontColor)
      return css`
        color: ${customFontColor};
      `;
  }}
  ${({ secondary }) => {
    if (secondary)
      return css`
        background: #00996b;
        border: 1px solid #00996b;
        color: #fff;
        box-shadow: none;
      `;
  }}
  ${({ discord }) => {
    if (discord)
      return css`
        background: #5865f2;
        border: 1px solid #5865f2;
        color: #fff;
        box-shadow: none;
        width: 100%;
        border-radius: 6px;
        margin: 0;
      `;
  }}

${({ fullWidth }) => {
    if (fullWidth)
      return css`
        width: 100%;
      `;
  }}

  ${({ clicked }) => {
    if (clicked)
      return css`
        pointer-events: none;
      `;
  }}
`;

const PlayerRateIcon = css`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 1px;
  margin-left: 5px;
  right: 5px;
`;

export const StyledButtonContainer = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
`;

export const DoubleUpArrow = styled(DoubleUpIcon)`
  ${PlayerRateIcon};
`;
export const FlatArrow = styled(FlatIcon)`
  ${PlayerRateIcon};
`;
export const UpArrow = styled(UpIcon)`
  ${PlayerRateIcon};
`;
export const DownArrow = styled(DownIcon)`
  ${PlayerRateIcon};
  top: 2px;
  @media (max-width: 530px) {
    right: 0;
  }
`;
export const ErrorCross = styled(ErrorCrossIcon)`
  width: 20px;
`;

export const StyledRatingAndPotentialContent = styled.span`
  font-size: 13px;
`;

export const StyledUnknownTableValue = styled(StyledRatingAndPotentialContent)`
  color: #7b7b7b;
  cursor: pointer;
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  font-weight: 500;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledScoutingSpan = styled.span`
  color: #ff4d09 !important;
  border-radius: 5px;
  padding: 0px;
  background: transparent;
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Gilroy', sans-serif;
  line-height: 100%;
  font-weight: 500;
  font-size: 13px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  width: 12px;
  height: 12px;
  margin-left: 0px;
  ${({ iconsize }) => {
    if (iconsize)
      return css`
        width: ${iconsize}px;
        height: ${iconsize}px;
      `;
  }}
  &:focus,
  &:active {
    outline: none;
  }
`;
export const InfoDark = styled(InfoIconDark)`
  width: 12px;
  height: 12px;
  margin-left: 0px;
  ${({ iconsize }) => {
    if (iconsize)
      return css`
        width: ${iconsize}px;
        height: ${iconsize}px;
      `;
  }}
  &:focus,
  &:active {
    outline: none;
  }
`;

export const InfoDarkRelative = styled(InfoDark)`
  position: relative;
  top: 1px;
  left: 2px;
`;

export const StyledReportLabel = styled.span`
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: #333333;
  line-height: 6px;
  color: #fff;
  font-family: Gilroy;
  font-size: 10px;
  font-weight: 500;
  width: 135px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  svg {
    margin-left: 10px;
  }
`;

export const StyledScoutReport = styled.div`
  color: #666666;
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  height: 50px;
  border-bottom: 0.3px solid #ccc;
  align-items: center;
  padding: 10px 15px 10px 35px;
  text-align: left;

  &:nth-child(2) {
    height: 65px;
    padding-top: 25px;
  }
  &:nth-child(4) {
    border-bottom: 0;
  }
  ${({ full }) => {
    if (full) {
      return css`
        flex-direction: column;
        background-color: #fff;
        text-align: left;
        height: 100%;
        &:nth-child(2) {
          height: 100%;
        }
      `;
    }
  }}
`;

export const StyledTableLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 rgba(110, 110, 110, 0.1);
  padding: 0px;
  border-radius: 4px;
  margin-bottom: 25px;
`;

export const StyledToastContainer = styled(ToastContainer).attrs({})`
  &.Toastify__toast-container--top-right {
    top: 55px !important;
  }
  .Toastify__toast {
    min-height: 30px;
    background: #000;
    color: #fff;
  }
  .Toastify__toast-body {
    font-size: 14px;
  }
  .Toastify__progress-bar {
  }
  .Toastify__close-button {
    padding: 7px 3px;
    &--light {
      opacity: 1;
      > svg {
        fill: #fff;
      }
    }
  }
`;

export const StyledToastCloseIcon = styled(CloseToast)`
  width: 12px;
  height: 12px;
  margin-top: 9px;
`;
