import React from 'react';
import isEmpty from 'lodash/isEmpty';
import usePortal from 'react-useportal';
import { useSelector } from 'react-redux';
import { getTransfersOfferType } from '../../../../../../service/utils';
import {
  CancelOfferPopup,
  RejectOfferPopup,
} from '../../../../../../components/Popup';
import { getPopupByName } from '../../../../../../redux/selector/Popups';
import { getDataForRequest } from '../../../../../../redux/selector/Transfers';

import TransfersPlayerCard from '../../../TransfersPlayerCard';
import { StyledContainer, StyledViewDealsContainer } from './styled';

const ViewDealsContainer = ({
  dealsList,
  selectPlayer,
  setSelectPlayer,
  teamId,
  allPlayersList,
  myTeam,
}) => {
  const cancelOfferPopupName = 'cancelOfferPopup';
  const rejectOfferPopupName = 'rejectOfferPopup';
  const cancelOfferPopupData = useSelector(
    getPopupByName(cancelOfferPopupName)
  );
  const rejectOfferPopupData = useSelector(
    getPopupByName(rejectOfferPopupName)
  );
  const dataForPopup = useSelector(getDataForRequest);

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });
  return (
    <StyledContainer>
      {cancelOfferPopupData && !isEmpty(dataForPopup) && (
        <Portal>
          <CancelOfferPopup
            popupName={cancelOfferPopupName}
            data={dataForPopup}
          />
        </Portal>
      )}
      {rejectOfferPopupData && !isEmpty(dataForPopup) && (
        <Portal>
          <RejectOfferPopup
            popupName={rejectOfferPopupName}
            data={dataForPopup}
          />
        </Portal>
      )}
      <h1>Open deals</h1>
      <StyledViewDealsContainer>
        {dealsList.length > 0 &&
          dealsList.map((offer, index) => {
            const { _id } = offer.player;
            const playerFromList =
              allPlayersList.find((player) => player._id === _id) ||
              myTeam.find((player) => player._id === _id);
            if (!isEmpty(playerFromList)) {
              const {
                potential_min: minPotentialValue,
                potential_max: maxPotentialValue,
                rating_range: ratingRange,
                salary,
              } = playerFromList;
              return (
                <TransfersPlayerCard
                  cardType={getTransfersOfferType(
                    teamId,
                    offer.sending_team._id,
                    offer.category
                  )}
                  className={`${index}index`}
                  offer={offer}
                  type={offer.type}
                  key={`${index}index-${Math.random()}`}
                  selectPlayer={selectPlayer}
                  setSelectPlayer={setSelectPlayer}
                  minPotentialValue={minPotentialValue}
                  maxPotentialValue={maxPotentialValue}
                  ratingRange={ratingRange}
                  salary={salary}
                />
              );
            }
            return true;
          })}
      </StyledViewDealsContainer>
    </StyledContainer>
  );
};

export default React.memo(ViewDealsContainer);
