import styled from 'styled-components';
import { getTrackBackground } from 'react-range';

import ReactTooltip from 'react-tooltip';

import { InfoDark } from '../../globalStyled';

export const StyledInfoDark = styled(InfoDark)`
  margin-left: 3px;
  height: 10px;
  width: 10px;
`;
export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;

export const StyledTacticContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    width: 100%;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const StyledManipulator = styled.div`
  height: 22px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(171, 175, 179, 0.6),
    inset 0 -3px 6px 0 rgba(122, 125, 128, 0.2);
  border: solid 1px rgba(122, 125, 128, 0.3);
  background-color: #fff;
  outline: none;
`;

export const StyledTrack = styled.div`
  height: 30px;
  display: flex;
  width: 100%;
`;

export const StyledProgress = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
  background: ${(props) =>
    getTrackBackground({
      values: props.values,
      colors: props.colors,
      min: props.min,
      max: props.max,
    })};
`;

export const StyledLabels = styled.div`
  color: #7f7f7f;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

export const StyledRangeWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin: 10px 20px;
  @media only screen and (max-width: 768px) {
    margin: 20px;
  }
  h4 {
    font-family: 'Gilroy', sans-serif;
    //text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #4c4c4c;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      line-height: 8px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 13px;
    }
  }
`;
