import styled from 'styled-components';

export const StyledRound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledGroup = styled.div`
  display: flex;
  flex-direction: row;
  height: 44px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
`;

export const StyledRoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const StyledTitle = styled.h4`
  font-size: 15px;
  font-weight: 700;
  font-family: 'Gilroy', sans-serif;
`;
export const StyledDate = styled.span`
  font-size: 11px;
  color: #666666;
  font-family: 'Gilroy', sans-serif;
`;
