import React from 'react';
import PropTypes from 'prop-types';
import { StyledIcon } from './styled';
import { ReactComponent as Sponsorship } from '../../assets/img/transactions/sponsorship.svg';
import { ReactComponent as BoughtPlayer } from '../../assets/img/transactions/bought-player.svg';
import { ReactComponent as Broadcasting } from '../../assets/img/transactions/broadcasting.svg';
import { ReactComponent as LoanedIn } from '../../assets/img/transactions/loanedin-player.svg';
import { ReactComponent as LoanedOut } from '../../assets/img/transactions/loanedout-player.svg';
import { ReactComponent as Merchandising } from '../../assets/img/transactions/merchandising.svg';
import { ReactComponent as OtherExpenses } from '../../assets/img/transactions/other-expenses.svg';
import { ReactComponent as PlayerWages } from '../../assets/img/transactions/player-wages.svg';

const teamNameToImageName = (transactionName) => {
  switch (transactionName) {
    case 'sponsorship':
      return <Sponsorship />;
    case 'bought player':
      return <BoughtPlayer />;
    case 'broadcasting':
      return <Broadcasting />;
    case 'loan in':
      return <LoanedIn />;
    case 'loan out':
      return <LoanedOut />;
    case 'merchandise':
      return <Merchandising />;
    case 'player wages':
      return <PlayerWages />;
    default:
      return <OtherExpenses />;
  }
};

const TransactionIcon = ({ transactionName, showBorder = false }) => (
  <StyledIcon showBorder={showBorder}>
    {teamNameToImageName(transactionName.toLowerCase())}
  </StyledIcon>
);
TransactionIcon.propTypes = {
  transactionName: PropTypes.string,
};
TransactionIcon.defaultProps = {
  transactionName: null,
};
export default TransactionIcon;
