import styled from 'styled-components';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { StyledButton, StyledSectionContainer } from '../../globalStyled';

export const StyledSection = styled(StyledSectionContainer)`
  h3 {
    width: 100%;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.18px;
    padding: 17px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledContainer = styled.div`
  padding: 5px 30px 26px 30px;
  background: #ffffff;
  border-radius: 5px;
`;
export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const StyledSelect = styled(Select)`
  width: 49%;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .react-select__menu {
    box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
    border: solid 0.5px #e1e1e1;
  }
  .react-select__control {
    height: 40px;
    color: #000;
    border: solid 1px #d1d1d1;
    background-color: #fafafa;
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    &--is-disabled {
      background-color: hsl(0, 0%, 95%);
    }
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.43px;
    color: #7f7f7f;
  }
  .react-select__indicator {
    svg {
      width: 15px;
      fill: #4a4a4a;
    }
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #666;
    svg {
      margin-right: 5px;
    }
    &--is-focused,
    &--is-selected {
      background-color: #fafafa;
      color: #333;
    }
  }
`;

export const StyledInput = styled(NumberFormat)`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  height: 40px;
  width: 49%;
  position: relative;
  background-color: #fafafa;
  border: solid 1px #d1d1d1;
  border-radius: 5px;

  font-family: 'Gilroy', sans-serif;

  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.015em;
  color: #191919;
  cursor: pointer;
  padding: 13px 43px 13px 8px;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    color: #7f7f7f;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const StyledSendButton = styled(StyledButton)`
  width: 49%;
  margin-bottom: 0;
  margin: 0;
  box-shadow: none;
  &:disabled {
    box-shadow: none;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledSelectItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  img {
    margin-right: 8px;
    position: relative;
    width: 18px;
  }
  span {
    font-size: 13px;
  }
`;
