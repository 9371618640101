import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';
import { StyledButton } from '../../globalStyled';

export const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  height: 100vh;
`;
export const StyledDarkSection = styled.section`
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #0a0b0d;

  height: auto;
  padding-bottom: 100px;
  > img {
    position: absolute;
    max-height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    width: auto;
    left: 50%;
    top: 0;
    @media only screen and (max-width: 991px) {
      opacity: 0.4;
      max-width: 500px;
      width: 100%;
      left: 0;
    }
  }

  @media only screen and (max-width: 991px) {
    max-height: 500px;
  }
`;
export const StyledHeader = styled.header`
  z-index: 2;
  display: flex;
  height: 100px;
  width: 100%;
  background-color: transparent;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  @media only screen and (max-width: 991px) {
    padding: 20px;
    height: 55px;
    background-color: #000;
  }
  img {
    width: 180px;
    margin-right: 10px;
    @media only screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;
export const StyledSection = styled.section`
  height: 100%;
  max-width: 525px;
  width: 36%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin: 0 60px;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin: 0px;
    padding: 0 25px;
  }
`;

export const StyledLeftHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  img {
    width: 180px;
    margin-right: 10px;
    @media only screen and (max-width: 425px) {
      width: 150px;
    }
  }
`;

export const StyledRightHeaderSection = styled.div`
  font-family: Gilroy;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    font-size: 17px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: Gilroy;
  font-size: 60px;
  font-weight: 900;
  text-transform: none;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.92px;
  color: #ffffff;
  margin-bottom: 40px;
  @media only screen and (max-width: 991px) {
    font-size: 42px;
    margin-top: 25px;
  }
`;

export const StyledSubtitle = styled.h1`
  font-family: 'Gilroy-Medium', sans-serif;
  font-size: 20px;
  max-width: 400px;
  line-height: 33px;
  text-transform: none;
  color: #fff;
  font-weight: normal;
  b {
    font-weight: 700;
  }
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    margin-top: 20px;
    width: 100%;
  }
`;
export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${({ loadButtonsSection }) => {
    if (loadButtonsSection) {
      return css`
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 400px;
        button {
          width: auto;
          min-width: 135px;
        }
      `;
    }
  }}
  button {
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
`;
export const StyledLandingButton = styled(StyledButton)`
  width: 100%;
  min-width: 175px;
  height: 40px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #0a0b0d;
  svg {
    margin-right: 5px;
  }
  ${({ small }) => {
    if (small) {
      return css`
        max-width: 120px;
        min-width: 120px;
        font-size: 16px;
      `;
    }
  }}
  ${({ primary }) => {
    if (primary) {
      return css`
        background-color: #0a0b0d;
        color: #fff;
      `;
    }
  }}
  ${({ brand }) => {
    if (brand) {
      return css`
        background-color: #737df8;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
        color: #fff;
        width: auto;
      `;
    }
  }}
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    height: 35px;

    min-width: 70px;
  }
  &:disabled {
    background-color: #ebebeb;
    color: #989898;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  }
`;

export const StyledDetailsContainer = styled.section`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-items: center;
  background-color: #fff;
  @media only screen and (max-width: 991px) {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 100%;
  }

  ${({ grey }) => {
    if (grey) {
      return css`
        margin-top: -70px;
        padding: 150px 0;

        background-color: #f8f8f8;
        @media only screen and (max-width: 991px) {
          flex-direction: column-reverse;
          margin-top: 0;
          padding: 50px 0 0 0;
        }
      `;
    }
  }}
  ${({ small }) => {
    if (small) {
      return css`
        padding: 50px 0;
      `;
    }
  }};
`;

export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledContainerLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
`;

export const StyledAuthor = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 100;
  line-height: 50px;
  font-size: 30px;
  @media only screen and (max-width: 991px) {
    font-size: 25px;
  }
`;

export const StyledSidebarContainer = styled.div`
  padding: 30px;
  width: 25%;
  background-color: #efecec;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`;
export const StyledMainContainer = styled.div`
  padding: 30px;
  width: 75%;
  background-color: #fff;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  .youtube-container {
    width: 100%;
    margin-bottom: 25px;
    iframe {
      width: 100%;
    }
  }
`;

export const StyledSectionTitle = styled.h2`
  font-family: 'Gilroy', sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 20px;
`;
export const StyledSectionSubtitle = styled.h3`
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.4px;
  text-align: left;
`;

export const StyledParagraph = styled.p`
  margin-bottom: 20px;

  img {
    max-width: 50%;
    padding: 20px;
  }
  ${({ small }) => {
    if (small) {
      return css`
        font-family: 'Gilroy', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.4px;
        text-align: left;
      `;
    }
  }}
  ${({ big }) => {
    if (big) {
      return css`
        font-family: 'Gilroy', sans-serif;
        font-weight: 300;
        font-size: 20px;
        line-height: 39px;
        letter-spacing: 0.5px;
        text-align: left;
      `;
    }
  }}
`;

export const StyledList = styled.ul`
  list-style: none;
`;
export const StyledListItem = styled.li`
  position: relative;
  padding-left: 20px;
  &:before {
    content: '•';
    position: absolute;
    font-size: 24px;
    left: 0;
  }
`;
export const StyledUnderline = styled.span`
  text-decoration: underline;
`;

export const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
  margin-top: 50px;
  margin-bottom: 20px;
`;
