import React from 'react';
import ContentLoader from 'react-content-loader';

const UpcomingCalendarPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={600}
    height={255}
    viewBox="0 0 600 255"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="23" y="84" rx="3" ry="3" width="150" height="11" />
    <rect x="203" y="84" rx="3" ry="3" width="165" height="11" />
    <rect x="25" y="25" rx="3" ry="3" width="143" height="9" />
    <rect x="25" y="45" rx="3" ry="3" width="213" height="22" />
    <rect x="413" y="84" rx="3" ry="3" width="165" height="11" />
    <rect x="23" y="120" rx="3" ry="3" width="150" height="11" />
    <rect x="203" y="120" rx="3" ry="3" width="165" height="11" />
    <rect x="413" y="120" rx="3" ry="3" width="165" height="11" />
    <rect x="25" y="156" rx="3" ry="3" width="150" height="11" />
    <rect x="205" y="156" rx="3" ry="3" width="165" height="11" />
    <rect x="415" y="156" rx="3" ry="3" width="165" height="11" />
    <rect x="26" y="191" rx="3" ry="3" width="150" height="11" />
    <rect x="206" y="191" rx="3" ry="3" width="165" height="11" />
    <rect x="416" y="191" rx="3" ry="3" width="165" height="11" />
    <rect x="27" y="231" rx="3" ry="3" width="150" height="11" />
    <rect x="207" y="231" rx="3" ry="3" width="165" height="11" />
    <rect x="417" y="231" rx="3" ry="3" width="165" height="11" />
  </ContentLoader>
);

export default UpcomingCalendarPreloader;
