import styled, { keyframes } from 'styled-components';

export const blinking = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const StyledLiveMatch = styled.span`
  margin: 5px 0;
  position: relative;
  justify-content: center;
  z-index: 1;
  display: flex;
  padding: 8px 25px;
  border: ${({ theme }) =>
    theme === 'dark' ? '2px solid #ffffff' : '2px solid #999'};
  background: ${({ theme }) => (theme === 'dark' ? 'transparent' : '#fff')};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.01em;
  height: 32px;
  color: #fff;
  color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#000')};
  text-decoration: none;
  transition: 0.3s;
  width: 200px;
  padding-left: 25px;

  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    width: auto;
    padding-left: 30px;
  }
`;

export const StyledOnlineIcon = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 10px;
  position: absolute;
  left: 10px;
  top: 8px;
  background-color: red;
  animation: ${blinking} 5s infinite;
`;
