import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import Table from '../../../../components/LeagueTable';

import { leagueSetTeamInfo } from '../../../../redux/actions/League';
import { showMobileSidebarSection } from '../../../../redux/actions/Team';
import { showPopupWindow } from '../../../../redux/actions/Popups';

import { rankingTableColumns } from '../../../../service/data';
import { POPUPS } from '../../../../service/constants';

import { StyledContainer, StyledTableContainer } from './styled';

export default function TableContainer({ ranking }) {
  const { TEAM_INFO_POPUP } = POPUPS;
  const dispatch = useDispatch();
  const addInfoAboutTeam = (_data) => {
    dispatch(leagueSetTeamInfo(_data, isMobileOnly));
    if (isMobileOnly) {
      dispatch(showMobileSidebarSection('teamInfo'));
    } else {
      dispatch(showPopupWindow(TEAM_INFO_POPUP, TEAM_INFO_POPUP, true));
    }
  };

  const data = useMemo(
    () => ranking.map((rank, index) => ({ ...rank, number: index + 1 })),
    [ranking]
  );

  return (
    <StyledTableContainer>
      <StyledContainer className="rank-table">
        <Table
          canSelectPlayer
          getTeamInfo={addInfoAboutTeam}
          data={data}
          columns={rankingTableColumns}
          isTeamInCurrentLeague
        />
      </StyledContainer>
    </StyledTableContainer>
  );
}

TableContainer.propTypes = {
  ranking: PropTypes.array.isRequired,
};
