import React from 'react';
import ContentLoader from 'react-content-loader';

const TacticPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={260}
    height={192}
    viewBox="0 0 260 192"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="374" y="16" rx="4" ry="4" width="85" height="12" />
    <rect x="259" y="16" rx="3" ry="3" width="70" height="11" />
    <rect x="481" y="16" rx="3" ry="3" width="207" height="12" />
    <rect x="691" y="16" rx="3" ry="3" width="207" height="12" />
    <rect x="25" y="59" rx="4" ry="4" width="212" height="11" />
    <rect x="25" y="20" rx="0" ry="0" width="121" height="18" />
    <rect x="25" y="129" rx="4" ry="4" width="212" height="11" />
  </ContentLoader>
);
export default TacticPreloader;
