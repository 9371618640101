import React from 'react';
import ContentLoader from 'react-content-loader';

const NextMatchPreloader = (props) => (
  <ContentLoader
    style={{ width: '100%' }}
    speed={2}
    width={630}
    height={230}
    viewBox="0 0 630 230"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="50" y="70" rx="3" ry="3" width="289" height="26" />
    <rect x="50" y="37" rx="3" ry="3" width="190" height="12" />
    <rect x="542" y="16" rx="4" ry="4" width="85" height="12" />
    <rect x="50" y="170" rx="3" ry="3" width="113" height="35" />
    <rect x="50" y="103" rx="3" ry="3" width="289" height="26" />
  </ContentLoader>
);

export default NextMatchPreloader;
