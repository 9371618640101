import { createAction } from 'redux-act';
import { getTrainingTeams, sendTrainingOffer } from '../../../api/training';
import { POPUPS } from '../../../service/constants';
import { toastRender } from '../../../service/toaster';
import { hidePopupWindow } from '../Popups';

const { TRAINING_POPUP } = POPUPS;

export const getTrainingTeamsLoading = createAction('training teams loading');
export const getTrainingTeamsData = createAction('get training teams');

export const getTrainingList = () => async (dispatch) => {
  dispatch(getTrainingTeamsLoading(true));
  const response = await getTrainingTeams();
  if (response.status === 200) {
    const data = await response.json();
    dispatch(getTrainingTeamsData(data));
  } else {
    console.log('error');
  }
  dispatch(getTrainingTeamsLoading(false));
};

export const trainingOfferLoading = createAction('training offer loading');
export const trainingOfferResponse = createAction('training offer done');

export const sendTrainingRequest = (teamId) => async (dispatch) => {
  dispatch(trainingOfferLoading(true));
  const response = await sendTrainingOffer(teamId);
  if (response.status === 200) {
    dispatch(trainingOfferResponse(teamId));
    toastRender(
      'success',
      'We reached out to the other team to confirm. Check DMs from Chris.'
    );
    setTimeout(() => {
      dispatch(hidePopupWindow(TRAINING_POPUP));
      dispatch(trainingOfferResponse(null));
    }, 1500);
  } else if (response.status === 201) {
    toastRender('error', 'Bot-managed teams cannot practice.');
  } else if (response.status === 202) {
    toastRender(
      'error',
      'Your team already practiced today. You can only practice once a day.'
    );
  } else if (response.status === 203) {
    toastRender(
      'error',
      'The opposing team already practiced today. Teams can only practice once a day.'
    );
  } else if (response.status === 204) {
    toastRender('error', 'Practices are not allowed at this time of day.');
  } else {
    toastRender('error', 'We encountered an error.');
  }

  dispatch(trainingOfferLoading(false));
};
