import styled from 'styled-components';

export const StyledWrapper = styled.div`
  background-color: #fff;
  height: 100vh;
`;
export const StyledIframe = styled.iframe`
  border: 0;
  width: 100%;
  margin-top: 100px;
  height: 600px;
`;
