import { homeBar } from '../service/links';

const headers = {
  'Content-Type': 'application/json;charset=utf-8',
};
export const getSidebarData = async () => {
  const response = await fetch(homeBar, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
