import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Revenue from './components/RevenueExpenses';
import SendMoney from '../../components/SendMoney';
import FinanceBalance from '../../components/FinanceBalance';
import { FinanceChart } from '../../components/FinanceChart';
import Transactions from './components/Transactions';
import FallbackContentAnimation from '../../components/FallbackContentAnimation';
import {
  SendMoneyPreloader,
  TransactionsPreloader,
  CashChartPreloader,
  RevenuesPreloader,
} from '../../components/ContentPreloaders';

import { tourStorageValue } from '../../helpers/tour/locationToStorage';

import {
  isTransactionDataLoaded,
  isRevenueAndExpansesDataLoaded,
  getChart,
  getTeamTotalSummary,
} from '../../redux/selector/Finance';
import { isLeaguesListLoaded } from '../../redux/selector/League';
import { getTourState } from '../../redux/selector/TourControl';

import {
  financeGetDataAboutRevenueAndExpenses,
  financeGetDataAboutTransaction,
  getFinanceChartData,
} from '../../redux/actions/Finance';
import { allowTour } from '../../redux/actions/TourControl';
import { getLeaguesList } from '../../redux/actions/League';
import { cashFormat } from '../../service/functions';
import {
  StyledLeftContainer,
  StyledRightContainer,
  StyledNewWrapper,
  StyledTopSection,
  StyledPreloaderContainer,
  StyledTransactionPreloaderContainer,
} from './styled';

export default function Finance() {
  const dispatch = useDispatch();
  const isLoadedTransaction = useSelector(isTransactionDataLoaded);
  const isLoadedRevenue = useSelector(isRevenueAndExpansesDataLoaded);
  const { current_cash, total_earnings, total_spendings } =
    useSelector(getTeamTotalSummary);
  const leagueList = useSelector(isLeaguesListLoaded);
  const chartData = useSelector(getChart);

  useEffect(() => {
    dispatch(financeGetDataAboutTransaction());
    dispatch(financeGetDataAboutRevenueAndExpenses());
    dispatch(getLeaguesList());
    dispatch(getFinanceChartData());
  }, [dispatch]);

  const isLoading =
    isLoadedRevenue || isLoadedTransaction || !leagueList || !chartData;
  const location = useLocation();
  const isTourStart = useSelector(getTourState);
  useEffect(() => {
    const activeLocalStorageTourState = localStorage.getItem(
      tourStorageValue[location.pathname]
    );
    if (!isLoading && !isTourStart && !activeLocalStorageTourState) {
      dispatch(allowTour(true));
    }
  }, [isLoading, isTourStart, dispatch, location.pathname]);
  return (
    <StyledNewWrapper>
      <StyledLeftContainer className="first">
        <StyledTopSection>
          <FinanceBalance
            isLoading={isLoading}
            current_cash={cashFormat(current_cash?.toFixed())}
            total_earnings={cashFormat(total_earnings?.toFixed())}
            total_spendings={cashFormat(total_spendings?.toFixed())}
          />

          <FallbackContentAnimation
            loading={isLoading}
            fallback={
              <StyledPreloaderContainer>
                <CashChartPreloader style={{ width: '100%' }} />
              </StyledPreloaderContainer>
            }
          >
            <FinanceChart chartData={chartData} />
          </FallbackContentAnimation>
        </StyledTopSection>

        <FallbackContentAnimation
          loading={isLoading}
          repeat={10}
          fallback={
            <StyledTransactionPreloaderContainer>
              <RevenuesPreloader style={{ width: '100%' }} />
            </StyledTransactionPreloaderContainer>
          }
        >
          <Revenue />
        </FallbackContentAnimation>
      </StyledLeftContainer>
      <StyledRightContainer>
        <FallbackContentAnimation
          loading={isLoading}
          fallback={
            <StyledPreloaderContainer>
              <SendMoneyPreloader style={{ width: '100%' }} />
            </StyledPreloaderContainer>
          }
        >
          <SendMoney leagueList={leagueList} />
        </FallbackContentAnimation>

        <FallbackContentAnimation
          loading={isLoading}
          repeat={10}
          fallback={
            <StyledTransactionPreloaderContainer>
              <TransactionsPreloader style={{ width: '100%' }} />
            </StyledTransactionPreloaderContainer>
          }
        >
          <Transactions />
        </FallbackContentAnimation>
      </StyledRightContainer>
    </StyledNewWrapper>
  );
}
