import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { isEmpty } from 'lodash';
import {
  ratingRangeAbbr,
  simplifiedPosition,
  emptyPlayerToDragPositionName,
} from '../../../../../../../../service/data';
import { POPUPS } from '../../../../../../../../service/constants';
import { showPopupWindow } from '../../../../../../../../redux/actions/Popups';
import { getPlayerInformation } from '../../../../../../../../redux/actions/League';
import {
  pitchPlayerPosition,
  setFormationListFilter,
} from '../../../../../../../../redux/actions/Team';
import {
  getMyCurrentTeam,
  isPremiumUser,
} from '../../../../../../../../redux/selector/User';
import {
  getClickedPositionOnPitch,
  isOpponentUserPremium,
} from '../../../../../../../../redux/selector/Team';
import {
  StyledPlayerCard,
  StyledFitness,
  StyledRatingContainer,
  StyledRatingBadge,
  StyledLabel,
  StyledNameContainer,
  StyledDescription,
  StyledName,
  StyledInfoWrapper,
  StyledPlayerInfoIcon,
  StyledInjuredLabel,
} from './styled';
import TeamShirt from '../../../../../../../../components/TeamShirt';

const PlayerCard = ({
  isAwayTeam,
  opponentTeamName,
  name,
  firstName,
  lastName,
  ratingRange,
  formRange,
  playerPos,
  selectedCard,
  _id,
  teamKit,
  ...rest
}) => {
  const { style } = rest;
  const noPlayer = !firstName || !lastName;
  const dispatch = useDispatch();
  const teamData = useSelector(getMyCurrentTeam);
  const isPremium = useSelector(isPremiumUser);
  const isOpponentPremium = useSelector(isOpponentUserPremium);
  const clickedPosition = useSelector(getClickedPositionOnPitch);
  const previewTeamData = JSON.parse(localStorage.getItem('previewTeamData'));
  const desktopDisplayName = (fName, lName, emptyName) =>
    fName && lName
      ? `${fName} ${lName[0]}.`
      : `Drag ${emptyPlayerToDragPositionName[playerPos]}`;
  const getPlayerInfo = (e) => {
    dispatch(
      showPopupWindow(
        POPUPS.PLAYER_INFO_POPUP_VIEW,
        POPUPS.PLAYER_INFO_POPUP_VIEW,
        true
      )
    );
    dispatch(getPlayerInformation(_id, POPUPS.PLAYER_INFO_POPUP_VIEW));
    e.stopPropagation();
  };

  const getTeamShirt = () => {
    if (noPlayer) {
      return null;
    }
    if (isAwayTeam) {
      return opponentTeamName;
    }
    if (isEmpty(teamData)) {
      return previewTeamData.name;
    }
    return teamData.name;
  };

  const setActivePlayerCard = () => {
    if (clickedPosition === playerPos) {
      dispatch(pitchPlayerPosition(null));
      dispatch(setFormationListFilter('ALL'));
    } else {
      dispatch(pitchPlayerPosition(playerPos));
    }
  };

  return (
    <StyledPlayerCard
      style={{
        boxShadow:
          clickedPosition === playerPos
            ? '0 0 5px 3px rgb(0 0 0 / 5%)'
            : 'none',
      }}
      onClick={() => setActivePlayerCard()}
      awayTeam={isAwayTeam}
      isSelected={String(
        playerPos.replace(/[()]/g, '').toLowerCase() === selectedCard
      )}
      type={`${!firstName || !lastName ? 'empty' : 'player'}`}
    >
      <div className="Body">
        <div className="position">{simplifiedPosition[playerPos]}</div>
        {!isAwayTeam && formRange === -1 && (
          <StyledInjuredLabel>+</StyledInjuredLabel>
        )}
        <TeamShirt
          style={style}
          team={getTeamShirt()}
          isPremium={
            (!isAwayTeam && isPremium) || (isOpponentPremium && isAwayTeam)
          }
          teamKit={!isAwayTeam && teamKit ? teamKit.pic : null}
        />
        {!noPlayer && (
          <StyledRatingContainer>
            <StyledLabel>{ratingRangeAbbr[ratingRange]}</StyledLabel>
            <StyledRatingBadge />
          </StyledRatingContainer>
        )}
        <StyledNameContainer>
          <StyledDescription>
            {!noPlayer && !isAwayTeam && (
              <StyledInfoWrapper onClick={getPlayerInfo}>
                <StyledPlayerInfoIcon />
              </StyledInfoWrapper>
            )}

            <StyledName>
              {isMobile
                ? firstName || name
                : desktopDisplayName(firstName, lastName, name)}
            </StyledName>
          </StyledDescription>
        </StyledNameContainer>
        {!noPlayer && !isAwayTeam && <StyledFitness form={formRange} />}
      </div>
    </StyledPlayerCard>
  );
};
PlayerCard.propTypes = {
  isAwayTeam: PropTypes.bool,
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  ratingRange: PropTypes.string,
  formRange: PropTypes.number,
  playerPos: PropTypes.string,
  selectedCard: PropTypes.string,
  _id: PropTypes.string,
  style: PropTypes.object,
};
PlayerCard.defaultProps = {
  isAwayTeam: false,
  name: '',
  firstName: '',
  lastName: '',
  ratingRange: '',
  formRange: null,
  playerPos: '',
  selectedCard: '',
  _id: '',
  style: {},
};
export default PlayerCard;
