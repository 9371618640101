import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
  StyledLoader,
} from '../styled';
import { hidePopupWindow, showPopupWindow } from '../../../redux/actions/Popups';

import {
  sendReleasePlayer,
  removeReleasedPlayer,
} from '../../../redux/actions/Team';

import { getTeamSquad } from '../../../redux/selector/Team';
import { getPopupsList } from '../../../redux/selector/Popups';
import { getPlayerInfo } from '../../../api/league';

const ReleasePlayerPopup = ({ cash, popupName, id, salary, seasonsLeft }) => {
  
  const dispatch = useDispatch();

  const penaltySize = seasonsLeft > 1 ? (salary * 3 * seasonsLeft * 0.5) : 25000;
  const formatter = new Intl.NumberFormat('en-US');

  const [defaultStep, showDefaultStep] = useState(true);
  const [penaltyToLocaleString, setPenaltyToLocaleString] = useState(null);

  const NSF = cash - penaltySize < 0;

  const { isPopupLoading } = useSelector(getTeamSquad);
  const releasePlayerData = useSelector(getPopupsList);
  const { error, isLoading } = releasePlayerData[popupName];



  useEffect(() => {
    getPlayerReleaseFee()
  }, []);

  /**
   * get the player release fee
   */
  const getPlayerReleaseFee = async () => {
    try{
      const response = await getPlayerInfo(id)
      const playerData = await response.json()
  
      const releaseFee = playerData.players.releaesFee
  
      setPenaltyToLocaleString(formatter.format(releaseFee))

      showDefaultStep(true)
  
    }catch(error){
    }finally{
      dispatch(showPopupWindow(id, popupName, false));
    }

  }
  
  const sendRelease = () => {
    showDefaultStep(false);
    if (!NSF) {
      dispatch(sendReleasePlayer(id));
    }
  };

  const hidePopup = () => {
    if (!isLoading) {
      dispatch(hidePopupWindow(popupName));
    }
    if (!error) {
      dispatch(removeReleasedPlayer(id));
    }
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          {isPopupLoading || isLoading ? (
            <StyledLoader
              type="Bars"
              color="#2AFC98"
              height={100}
              width={100}
            />
          ) : (
            <>
              {defaultStep && (
                <>
                  <StyledHeader>Release Player</StyledHeader>
                  <StyledParagraph>
                    There is a ${penaltyToLocaleString} penalty for releasing a player.
                  </StyledParagraph>
                  <StyledParagraph>Would you like to proceed?</StyledParagraph>
                </>
              )}
              {NSF && !error && !defaultStep && (
                <>
                  <StyledHeader> Not enough funds.</StyledHeader>
                  <StyledParagraph>
                    You don't have enough funds to pay the release penalty.
                  </StyledParagraph>
                </>
              )}
              {error && (
                <>
                  <StyledHeader> {error.header}</StyledHeader>
                  <StyledParagraph>{error.text}</StyledParagraph>
                </>
              )}
              {!defaultStep && !error && !NSF && !isLoading && (
                <>
                  <StyledHeader>Thank you.</StyledHeader>
                  <StyledParagraph>Release request was sent</StyledParagraph>
                </>
              )}
              <StyledButtonContainer>
                {defaultStep ? (
                  <>
                    <StyledButton onClick={() => hidePopup()}>
                      Cancel
                    </StyledButton>
                    <StyledButton danger onClick={sendRelease}>
                      Yes, release player
                    </StyledButton>
                  </>
                ) : (
                  <StyledButton primary onClick={() => hidePopup()}>
                    OK, I understand
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </>
          )}
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(ReleasePlayerPopup);
