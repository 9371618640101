import styled, { css } from 'styled-components';
import { ReactComponent as RatingBadge } from '../../../../../../../../assets/img/strategy/rating-badge.svg';
import { ReactComponent as InfoIcon } from '../../../../../../../../assets/img/strategy/info-icon.svg';
import { ReactComponent as InjuredIcon } from '../../../../../../../../assets/img/injured-icon.svg';
import { fitnessProgress } from '../../../../../../../../service/utils';
import {
  formStrategyColor,
  formRangeStatus,
} from '../../../../../../../../service/data';

export const StyledPlayerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 116px;
  border-radius: 5px;
  cursor: ${({ awayTeam }) => (awayTeam ? 'auto' : 'pointer')};
  @media (max-width: 1199px) {
    width: 70px;
    height: auto;
  }

  .Body {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: relative;
    width: 120px;
    @media (max-width: 1199px) {
      width: 70px;
    }
    .position {
      bottom: 37px;
      right: 0;
      position: absolute;
      font-family: Gilroy;
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.23px;
      text-align: center;
      background-color: #fff;
      color: #000;
      border-radius: 50%;
      padding: 5px;
      width: 37px;
      height: 23px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      opacity: 0.4;
      @media (max-width: 991px) {
        font-size: 9px;
        font-size: 8px;
        bottom: 25px;
        width: 33px;
        height: 20px;
        letter-spacing: 0.5px;
      }
    }

    .Info {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:nth-child(2) {
        padding-top: 8px;
      }

      .icon {
        width: 16px;
        height: 16px;
        @media (max-width: 678px) {
          width: 13px;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        padding-left: 5px;

        .title {
          font-family: Gilroy;
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          white-space: nowrap;
          @media (max-width: 678px) {
            font-size: 7px;
          }
        }
        .subtitle {
          opacity: 0.4;
          font-family: Gilroy;
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          white-space: nowrap;
          @media (max-width: 678px) {
            font-size: 7px;
          }
        }
      }
    }
  }

  ${({ type, isSelected }) => {
    if (isSelected === 'true')
      return css`
        // box-shadow: 0 0 5px 3px rgb(0 0 0 / 5%);
        //border: solid 1px #0e4afb;
        .Header {
          background-color: #0e4afb;
        }
      `;
    if (type === 'empty')
      return css`
        /* border: solid 1px red; */
        .Body {
          .Info {
            .details {
              .title {
                color: #d1d1d1;
              }
            }
          }
        }
      `;
  }}
`;

export const StyledFitness = styled.div`
  height: 7px;
  position: relative;
  background-color: #00a853;
  width: 120px;
  &:before {
    content: '';
    height: 7px;
    position: absolute;
    width: ${(props) => fitnessProgress[props.form]};
    background-color: ${(props) =>
      formStrategyColor[formRangeStatus[props.form]]};
  }
  @media (max-width: 1199px) {
    width: 70px;
  }
`;

export const StyledRatingBadge = styled(RatingBadge)`
  position: relative;
  @media (max-width: 1199px) {
    width: 18px;
  }
`;

export const StyledRatingContainer = styled.div`
  position: absolute;
  width: 23px;
  height: 25px;
  bottom: 45px;
  left: 30px;
  z-index: 0;
  @media (max-width: 1199px) {
    left: 5px;
    width: 18px;
    height: 20px;
    bottom: 32px;
}


  }
`;
export const StyledLabel = styled.span`
  color: #fff;
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
  font-size: 11px;
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

export const StyledNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;
export const StyledInfoWrapper = styled.div`
  background-color: #01813f;
  border-top-left-radius: 2px;
  padding: 7px 2px;
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1199px) {
    display: none;
  }
`;
export const StyledPlayerInfoIcon = styled(InfoIcon)`
  width: 15px;
`;
export const StyledDescription = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  padding: 0px;
  display: flex;
  flex-basis: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  border-top-right-radius: 2px;
  background-color: #02964a;
  @media (max-width: 1199px) {
    font-size: 12px;
    flex-basis: 70px;
  }
`;

export const StyledName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
  @media (max-width: 991px) {
    font-size: 10px;
    padding: 3px 0;
  }
`;

export const StyledInjuredLabel = styled(InjuredIcon)`
  position: absolute;
  top: 0px;
  right: 0px;
`;
