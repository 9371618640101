import React from 'react';
import { useDispatch } from 'react-redux';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopUp,
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
  StyledHeader,
  StyledParagraph,
} from '../styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';

const SimplePopup = ({ popupContent, popupName }) => {
  const { header, text, buttonText } = popupContent;
  const dispatch = useDispatch();

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlock>
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer>
          <StyledHeader> {header}</StyledHeader>
          <StyledParagraph>{text}</StyledParagraph>
          <StyledButtonContainer>
            <StyledButton primary onClick={() => hidePopup()}>
              {buttonText}
            </StyledButton>
          </StyledButtonContainer>
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopUp>
  );
};

export default React.memo(SimplePopup);
