import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { ReactComponent as Strategy442 } from '../../../../../../assets/img/transfers-tactics/4-4-2.svg';
import { ReactComponent as Strategy433 } from '../../../../../../assets/img/transfers-tactics/4-3-3.svg';
import { ReactComponent as Strategy4231 } from '../../../../../../assets/img/transfers-tactics/4-2-3-1.svg';
import { ReactComponent as Strategy451 } from '../../../../../../assets/img/transfers-tactics/4-5-1.svg';
import { ReactComponent as Strategy352 } from '../../../../../../assets/img/transfers-tactics/3-5-2.svg';
import { ReactComponent as Strategy4411 } from '../../../../../../assets/img/transfers-tactics/4-4-1-1.svg';
import {
  StyledContainer,
  StyledTacticContainer,
  StyledButton,
  StyledButtonsWrapper,
  StyledFormationButtons,
  StyledIconPlaceholder,
  StyledMobileSidebar,
  StyledLeftArrow,
  StyledTitle,
  StyledBlockWrapper,
} from './styled';
import {
  StyledReactTooltip,
  InfoDarkRelative,
} from '../../../../../../globalStyled';
import SelectTactic from '../../../../../../components/SelectTactic';
import UpcomingCalendar from '../../../../../../components/UpcomingCalendar';
import FallbackContentAnimation from '../../../../../../components/FallbackContentAnimation';
import {
  FormationPreloader,
  TacticPreloader,
  CalendarPreloader,
} from '../../../../../../components/ContentPreloaders';

import {
  teamPickUpStrategy,
  setTacticData,
  showMobileSidebarSection,
  setUnsavedChanges,
} from '../../../../../../redux/actions/Team';
import {
  getCurrentStrategy,
  myTeamTactic,
  getStrategyTactics,
  upcomingCalendarList,
  sectionNameToDisplay,
} from '../../../../../../redux/selector/Team';

const SelectFormation = ({ isLoad }) => {
  // REDUX
  const dispatch = useDispatch();
  const OurStrategy = useSelector(getCurrentStrategy);
  const upcomingCalendarData = useSelector(upcomingCalendarList);
  const mobileSectionNameToDisplay = useSelector(sectionNameToDisplay);

  const active = useSelector(myTeamTactic);
  const { mindset, effort } = useSelector(getStrategyTactics);
  // REDUX

  React.useEffect(() => {}, []);
  const currentCls = useRef('button-0');
  const currentStrategieList = useRef([
    '4-4-2',
    '4-3-3',
    '4-2-3-1',
    '4-5-1',
    '3-5-2',
    '4-4-1-1',
  ]);

  const selectTeamStrategy = (strategy, cls) =>
    function () {
      if (currentCls.current !== cls) {
        dispatch(setTacticData(cls));
        currentCls.current = cls;
        dispatch(teamPickUpStrategy(strategy));
        if (isMobile) {
          dispatch(showMobileSidebarSection(null));
        }
        dispatch(setUnsavedChanges(true));
      }
    };

  useEffect(() => {
    if (OurStrategy) {
      currentCls.current = `button-${OurStrategy}`;
      dispatch(setTacticData(currentCls.current));
    }
  }, [OurStrategy, dispatch]);

  const icons = {
    '4-4-2': <Strategy442 />,
    '4-5-1': <Strategy451 />,
    '3-5-2': <Strategy352 />,
    '4-2-3-1': <Strategy4231 />,
    '4-3-3': <Strategy433 />,
    '4-4-1-1': <Strategy4411 />,
  };
  return (
    <>
      <StyledMobileSidebar isOpened={mobileSectionNameToDisplay === 'tactic'}>
        <StyledTitle
          onClick={() => {
            dispatch(showMobileSidebarSection(null));
          }}
        >
          <StyledLeftArrow /> Tactic
        </StyledTitle>
        <SelectTactic mobile mindset={mindset} effort={effort} />
      </StyledMobileSidebar>
      <StyledMobileSidebar
        isOpened={mobileSectionNameToDisplay === 'formation'}
      >
        <StyledTitle
          onClick={() => {
            dispatch(showMobileSidebarSection(null));
          }}
        >
          <StyledLeftArrow />
          Formation
        </StyledTitle>
        <StyledFormationButtons>
          <FallbackContentAnimation
            loading={isLoad}
            fallback={<FormationPreloader />}
          >
            {currentStrategieList.current.map((strategy, index) => (
              <StyledButton
                className={`button-${strategy}`}
                active={active}
                onClick={selectTeamStrategy(strategy, `button-${index}`)}
                key={`${index}index${Math.random()}`}
              >
                <span>{strategy}</span>
                <StyledIconPlaceholder>{icons[strategy]}</StyledIconPlaceholder>
              </StyledButton>
            ))}
          </FallbackContentAnimation>
        </StyledFormationButtons>
      </StyledMobileSidebar>
      {upcomingCalendarData && (
        <StyledMobileSidebar
          isOpened={mobileSectionNameToDisplay === 'calendar'}
        >
          <StyledTitle
            onClick={() => {
              dispatch(showMobileSidebarSection(null));
            }}
          >
            <StyledLeftArrow /> Calendar
          </StyledTitle>
          <UpcomingCalendar data={upcomingCalendarData} />
        </StyledMobileSidebar>
      )}
      <StyledBlockWrapper>
        {!isMobile && (
          <StyledButtonsWrapper>
            <StyledContainer className="formation-section">
              <FallbackContentAnimation
                loading={isLoad}
                fallback={<FormationPreloader />}
              >
                <>
                  <h3>
                    Formation
                    <InfoDarkRelative
                      iconsize="10"
                      data-tip
                      clickable="true"
                      data-for="formation-tooltip"
                    />
                    <StyledReactTooltip
                      id="formation-tooltip"
                      type="light"
                      effect="solid"
                      place="bottom"
                    >
                      FORMATION allows you to choose how you want to organize
                      your team on the field. There is no perfect formation. It
                      depends on the players you have and the formation your
                      next opponent might be using
                    </StyledReactTooltip>
                  </h3>
                  <StyledFormationButtons>
                    {currentStrategieList.current.map((strategy, index) => (
                      <StyledButton
                        className={`button-${strategy}`}
                        active={active}
                        onClick={selectTeamStrategy(
                          strategy,
                          `button-${index}`
                        )}
                        key={`${index}index${Math.random()}`}
                      >
                        <span>{strategy}</span>
                        <StyledIconPlaceholder>
                          {icons[strategy]}
                        </StyledIconPlaceholder>
                      </StyledButton>
                    ))}
                  </StyledFormationButtons>
                </>
              </FallbackContentAnimation>
            </StyledContainer>
            <StyledTacticContainer className="tactic-section">
              <FallbackContentAnimation
                loading={isLoad}
                fallback={<TacticPreloader />}
              >
                <SelectTactic mindset={mindset} effort={effort} />
              </FallbackContentAnimation>
            </StyledTacticContainer>
            {upcomingCalendarData && (
              <StyledContainer>
                <FallbackContentAnimation
                  loading={isLoad}
                  fallback={<CalendarPreloader />}
                >
                  <h3>
                    Calendar
                    <InfoDarkRelative
                      iconsize="10"
                      data-tip
                      clickable="true"
                      data-for="calendar"
                    />
                    <StyledReactTooltip
                      id="calendar"
                      type="light"
                      effect="solid"
                      place="bottom"
                    >
                      CALENDAR shows you upcoming matches so you can optimize
                      which players to line up in your next match
                    </StyledReactTooltip>
                  </h3>
                  <UpcomingCalendar data={upcomingCalendarData} />
                </FallbackContentAnimation>
              </StyledContainer>
            )}
          </StyledButtonsWrapper>
        )}
      </StyledBlockWrapper>
    </>
  );
};

export default SelectFormation;
