import Select from 'react-select';
import styled, { css } from 'styled-components';
import { StyledButton } from '../../globalStyled';

export const StyledStuffButton = styled(StyledButton)`
  transition: none;
  margin-top: 5px;
  width: 100%;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        background-color: #bcbcbc;
        color: #fff;
        border-color: #bcbcbc;
      `;
    }
  }}
`;
export const StyledStuffContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  img {
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-height: 195px;
  }
`;

export const StyledStuffImageContainer = styled.div`
  border-radius: 5px;
`;
export const StyledStuffImage = styled.img``;
export const StyledStuffList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

export const StyledNameBlock = styled.div`
  height: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledImgContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  height: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledStuffWrapper = styled.div`
  &:hover {
    cursor: pointer;
    ${StyledStuffButton} {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: default;
        img {
          opacity: 0.3;
        }
        &:hover {
          cursor: default;
          ${StyledStuffButton} {
            box-shadow: none;
          }
        }
      `;
    }
  }}
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 20px;
  width: 165px;
  margin-right: 5px;
  margin-left: 5px;
  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type {
    margin-left: 0;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 5px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    width: 120px;
  }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    width: 33%;
  }
  .react-select__control {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    background-color: #efefef;
    color: #000;
    border: solid 1px #d1d1d1;
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    font-family: 'Gilroy', sans-serif;
    font-size: 14.222px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .react-select__option {
    font-family: 'Gilroy', sans-serif;
  }
`;
