import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Adsense } from '@ctrl/react-adsense';
import { RampUnit } from "@playwire/pw-react-component";
import Table from './components/Table';

import { SquadPreloader } from '../../../../components/ContentPreloaders';
import FallbackContentAnimation from '../../../../components/FallbackContentAnimation';

import { StyledTableContainer, StyledPlaceholder } from './styled';
import {
  teamAddToList,
  teamGetMyTeam,
  teamGetMyStrategy,
} from '../../../../redux/actions/Team';
import { sidebarOpenRightPanel } from '../../../../redux/actions/Sidebar';
// import { adsClickRequest } from '../../../../redux/actions/ads';
import { allowTour } from '../../../../redux/actions/TourControl';
import { isRightPanelOpen } from '../../../../redux/selector/Sidebar';
import { getMyAllSquad, myTeamLoading } from '../../../../redux/selector/Team';
import {
  getMyCurrentTeam,
  isPremiumUser,
  isAuth,
} from '../../../../redux/selector/User';
import { getTourState } from '../../../../redux/selector/TourControl';

import {
  formRangeStatus,
  sortingPriority,
  simplifiedPosition,
} from '../../../../service/data';
import { ratingRangeStatus } from '../../../../service/constants';
import { tourStorageValue } from '../../../../helpers/tour/locationToStorage';
import { PLAYER_INFO_STORAGE } from '../../../../helpers/tour/constants';

export default function TableContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const team = useSelector(getMyAllSquad);
  const isSidebarOpen = useSelector(isRightPanelOpen);
  const isTourStart = useSelector(getTourState);
  const isTeamLoading = useSelector(myTeamLoading);
  const previewTeamData = JSON.parse(localStorage.getItem('previewTeamData'));
  const isAuthenticated = useSelector(isAuth);

  const myTeamData = useSelector(getMyCurrentTeam);

  const myTeamName = isAuthenticated ? myTeamData.name : previewTeamData.name;
  const isPremium = useSelector(isPremiumUser);
  const getUserToSell = function (user) {
    dispatch(sidebarOpenRightPanel(true));
    dispatch(teamAddToList(user));
  };

  useEffect(() => {
    if (!isTeamLoading) {
      dispatch(teamGetMyTeam());
    }
  }, []);

  useEffect(() => {
    dispatch(teamGetMyStrategy());
  }, [dispatch]);

  useEffect(() => {
    const activeLocalStorageTourState = isSidebarOpen
      ? localStorage.getItem(PLAYER_INFO_STORAGE)
      : localStorage.getItem(tourStorageValue[location.pathname]);
    if (!isTeamLoading && !isTourStart && !activeLocalStorageTourState) {
      dispatch(allowTour(true));
    }
  }, [
    isTeamLoading,
    isTourStart,
    team,
    dispatch,
    location.pathname,
    isSidebarOpen,
  ]);

  /*   useEffect(() => {
    const handleAdsClick = () => {
      if (document.activeElement === document.querySelector('iframe')) {
        dispatch(adsClickRequest());
      }
    };

    window.addEventListener('blur', handleAdsClick);
    return () => {
      window.removeEventListener('blur', handleAdsClick);
    };
  }, []);
 */
  const data = useMemo(
    () =>
      team.map(
        ({
          first_name,
          nick_name: nickName,
          last_name,
          fav_position: favPosition,
          second_fav_position: secondFavPosition,
          potential_range: potentialRange,
          form_range: formRange,
          rating_range: ratingRange,
          media_rating,
          ...args
        }) => ({
          ...args,
          potentialId: potentialRange,
          potentialRange,
          ratingRange,
          first_name,
          last_name,
          potentialValue: ratingRangeStatus[potentialRange] || '-',
          name: `${first_name} ${nickName ? `"${nickName}"` : ''
            }  ${last_name}`,
          formId: formRange,
          form: formRangeStatus[formRange],
          ratingId: ratingRange,
          ratingValue: ratingRangeStatus[ratingRange],
          fav_position: favPosition,
          second_fav_position: secondFavPosition,
          favPosition,
          secondFavPosition,
          media_rating,
          nickName,
          loanedOut: args.loanedOut,
          lenderTeam: args.lender_team,
          sortPriority: sortingPriority[simplifiedPosition[favPosition]],
          team: myTeamName,
          teamName: myTeamName,
        })
      ),
    [team, myTeamName]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        disableSortBy: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 120, // accessor is the "key" in the data
      },
      {
        Header: 'Position',
        accessor: 'sortPriority',
      },
      { Header: 'Age', accessor: 'age' },
      { Header: 'Rating', accessor: 'ratingId' },
      { Header: 'Potential', accessor: 'potentialId' },
      { Header: 'Fitness', accessor: 'formId' },
      { Header: 'Media Rating', accessor: 'media_rating', disableSortBy: true, width: 150 },
      { Header: 'Contract left', accessor: 'contract_remaining_length', width: 200 },
    ],
    []
  );

  return (
    <>
      <FallbackContentAnimation
        loading={isTeamLoading}
        repeat="22"
        fallback={
          <StyledPlaceholder>
            <SquadPreloader
              style={{
                width: '100%',
                height: '38px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
              }}
            />
          </StyledPlaceholder>
        }
      >
        <StyledTableContainer>
          <Table
            data={data}
            columns={columns}
            getUserToSell={(usr) => getUserToSell(usr)}
          />
        </StyledTableContainer>
      </FallbackContentAnimation>
      {/*       {!isPremium && (<RampUnit
          type="dfp-med-rect"
          cssClass="leaderboard"
        />)} */}
      {/*       {!isPremium && (
        <Adsense
          client={`${!isPremium ? 'ca-pub-2739224896366063' : ''}`}
          slot={`${!isPremium ? '9437045662' : ''}`}
          style={{
            width: '100%',
            height: `${!isPremium ? 300 : 0}`,
            display: `${!isPremium ? 'flex' : 'none'}`,
            flexDirection: 'center',
            marginTop: '20px',
          }}
          layout="in-article"
          format="fluid"
        />
      )} */}
    </>
  );
}
