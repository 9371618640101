import styled from 'styled-components';

export const StyledYouthCampDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
