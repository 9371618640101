import React from 'react';
import {
  StyledBigBlock,
  StyledSmallBlock,
  StyledDot,
  StyledDotsRow,
} from './styled';

const Learnings = ({ learnings }) => {
  const titlesArr = learnings?.homeLearnings?.map((result) => result.title);
  const homeLearnings = learnings?.homeLearnings?.map((result) => result.dots);
  const awayLearnings = learnings?.awayLearnings?.map((result) => result.dots);
  return (
    <>
      <StyledBigBlock>
        {homeLearnings?.map((item, i) => {
          const initialResult = [...new Array(5)].map((_, ind) => (
            <StyledDot key={`empty-${ind}-away`} isDefault />
          ));
          return (
            <StyledDotsRow key={`${titlesArr[i]}`}>
              {initialResult?.map((dot, index) =>
                index <= item - 1 ? (
                  <StyledDot key={`${titlesArr[i]}-${index}`} />
                ) : (
                  dot
                )
              )}
            </StyledDotsRow>
          );
        })}
      </StyledBigBlock>
      <StyledSmallBlock>
        {titlesArr?.map((title) => (
          <span key={title}>{title}</span>
        ))}
      </StyledSmallBlock>
      <StyledBigBlock>
        {awayLearnings?.map((item, i) => {
          const initialResult = [...new Array(5)].map((_, ind) => (
            <StyledDot key={`empty-${ind}-away`} isDefault />
          ));
          return (
            <StyledDotsRow key={`${titlesArr[i]}-away`} isAway>
              {initialResult?.map((dot, index) =>
                index <= item - 1 ? (
                  <StyledDot key={`${titlesArr[i]}-${index}-away`} />
                ) : (
                  dot
                )
              )}
            </StyledDotsRow>
          );
        })}
      </StyledBigBlock>
    </>
  );
};

export default Learnings;
