import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useTable, useSortBy, usePagination } from 'react-table';
import { isMobile } from 'react-device-detect';
import {
  StyledTable,
  StyledTableContainer,
  StyledThead,
  StyledTbody,
  StyledTh,
  StyledTd,
  StyledTrTh,
  StyledTrTd,
  StyledSorted,
  StyledRatingCell,
  StyledLifetimeCell,
  StyledChartContainer,
  StyledSelect,
  StyledSelectContainer,
  StyledPagination,
  ArrowRight,
  ArrowLeft,
  StyledPaginationButton,
  StyledPageNumber,
  StyledPageLabel,
  StyledReactTooltip,
  InfoDarkAbsolute,
  StyledNumber,
  StyledTopSection,
  StyledBottomSection,
  StyledMobileSection,
  StyledTitle,
  StyledCellName,
  StyledCell,
  StyledSortSelect,
  StyledSortContainer,
  SortLabel,
  StyledLoanOut,
  StyledLoanIn,
  StyledTableCell,
  StyledAcademy,
  StyledInTeamIcon,
} from './styled';
import PlayerRating from '../../../../../../components/PlayerRating';
import { DonutChart } from '../../../../../../components/Charts';
import { getAcademyList } from '../../../../../../redux/selector/Team';
import { isPremiumUser } from '../../../../../../redux/selector/User';
import { simplifiedPosition } from '../../../../../../service/data';

export default React.memo(function TableContainer({
  columns,
  data,
  getUserToSell,
}) {
  const showOptions = [
    { value: 16, label: '16' },
    (isMobile ? { value: 27, label: '27' } : { value: 30, label: '30' }),
  ];

  const [clicked, setClicked] = useState(null);
  const [tableSize, setTableSize] = React.useState(showOptions[1]);
  const academyList = useSelector(getAcademyList);
  const isPremium = useSelector(isPremiumUser);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
    toggleSortBy,
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        pageIndex: 0,
        pageSize: 30,
        sortBy: [{ id: 'sortPriority', desc: false }],
      },
    },
    useSortBy,
    usePagination
  );

  const getItemNumber = (index) => {
    if (pageIndex < 1) {
      return index + 1;
    }
    if (pageIndex >= 1) {
      return pageIndex * tableSize.value + index + 1;
    }
  };

  const renderCell = (cell, index) => {
    const loanedIn =
      !isEmpty(cell.row.original.lenderTeam) && !cell.row.original.loanedOut;
    const reverseRating = [...cell.row.original.media_rating];
    reverseRating.reverse();
    switch (cell.column.id) {
      case 'sortPriority':
        return isPremium
          ? `${simplifiedPosition[cell.row.original.fav_position]}/${simplifiedPosition[cell.row.original.second_fav_position]
          }`
          : simplifiedPosition[cell.row.original.fav_position];

      case 'name':
        return (
          <>
            {isMobile ? (
              <>
                <StyledTopSection feeling={cell.row.original.transfer_feeling}>
                  <StyledTitle>
                    {cell.row.original.status ? <StyledInTeamIcon /> : <></>}
                    {cell.row.original.name}
                    {cell.row.original.loanedOut && (
                      <StyledLoanOut>lnd out</StyledLoanOut>
                    )}
                    {loanedIn && <StyledLoanIn>lnd in</StyledLoanIn>}
                    {academyList.includes(cell.row.original._id) && (
                      <StyledAcademy>ACA</StyledAcademy>
                    )}
                  </StyledTitle>
                  <StyledChartContainer className="chart-section" small>
                    {reverseRating.map((item, itemIndex) => (
                      <DonutChart
                        key={`${item}${itemIndex}`}
                        index={itemIndex + 1}
                        arcSize={item}
                        small
                      />
                    ))}
                  </StyledChartContainer>
                </StyledTopSection>
                <StyledBottomSection>
                  <StyledMobileSection>
                    <StyledCellName>Position</StyledCellName>
                    <StyledCell>
                      {isPremium
                        ? `${simplifiedPosition[cell.row.original.fav_position]
                        }/${simplifiedPosition[
                        cell.row.original.second_fav_position
                        ]
                        }`
                        : simplifiedPosition[cell.row.original.fav_position]}
                    </StyledCell>
                  </StyledMobileSection>
                  <StyledMobileSection>
                    <StyledCellName>Age</StyledCellName>
                    <StyledCell>{cell.row.original.age}</StyledCell>
                  </StyledMobileSection>
                  <StyledMobileSection className="rating-cell-mobile">
                    <StyledCellName>Rating</StyledCellName>
                    <StyledCell>
                      <StyledRatingCell>
                        {cell.row.original.ratingValue}
                        <PlayerRating rate={cell.row.original.rating_diff} />
                      </StyledRatingCell>
                    </StyledCell>
                  </StyledMobileSection>
                  <StyledMobileSection className="potential-cell-mobile">
                    <StyledCellName>Potential</StyledCellName>
                    <StyledCell>{cell.row.original.potentialValue}</StyledCell>
                  </StyledMobileSection>
                  <StyledMobileSection className="fitness-cell-mobile">
                    <StyledCellName>Fitness</StyledCellName>
                    <StyledCell>{cell.row.original.form}</StyledCell>
                  </StyledMobileSection>
                  <StyledMobileSection>
                    <StyledCellName>Contract</StyledCellName>
                    <StyledCell>{`${cell.row.original.contract_remaining_length > 1 ? `${cell.row.original.contract_remaining_length} seasons` : `${cell.row.original.contract_remaining_length} season`}`}</StyledCell>
                  </StyledMobileSection>
                </StyledBottomSection>
              </>
            ) : (
              <StyledTableCell>
                {cell.row.original.status ? <StyledInTeamIcon /> : <></>}
                {cell.row.original.name}
                {cell.row.original.loanedOut && (
                  <StyledLoanOut>loaned out</StyledLoanOut>
                )}
                {academyList.includes(cell.row.original._id) && (
                  <StyledAcademy>Academy</StyledAcademy>
                )}
                {loanedIn && <StyledLoanIn>loaned in</StyledLoanIn>}
              </StyledTableCell>
            )}
          </>
        );
      case 'ratingId':
        return (
          <StyledRatingCell isLoaned={cell.row.original.loanedOut || loanedIn}>
            {cell.row.original.ratingValue}
            <PlayerRating
              rate={cell.row.original.rating_diff}
              isLoaned={cell.row.original.loanedOut || loanedIn}
            />
          </StyledRatingCell>
        );
      case 'lifetimeMatches':
        return (
          <StyledLifetimeCell>
            {cell.row.original.lifetimeMatches}
          </StyledLifetimeCell>
        );

      case 'potentialId':
        return cell.row.original.potentialValue;

      case 'formId':
        return cell.row.original.form;

      case 'number':
        return (
          <StyledNumber
            data-tip
            clickable="true"
            data-for={`${index}-number-tooltip}`}
            feeling={cell.row.original.transfer_feeling}
          >
            {getItemNumber(index)}
            <StyledReactTooltip
              id={`${index}-number-tooltip}`}
              type="light"
              effect="solid"
              place="bottom"
            >
              A green circle indicates your player really loves it at your club
              while a red circle means they are having a miserable time. Grey
              indicates your player is indifferent or their sentiment is not
              known yet.
            </StyledReactTooltip>
          </StyledNumber>
        );

      case 'media_rating':
        return (
          <StyledChartContainer small>
            {reverseRating.map((item, itemIndex) => (
              <DonutChart
                key={`${item}${itemIndex}`}
                index={itemIndex + 1}
                arcSize={item}
                small
              />
            ))}
          </StyledChartContainer>
        );
      case 'contract_remaining_length':
        return (
          <span>{`${cell.row.original.contract_remaining_length > 1 ? `${cell.row.original.contract_remaining_length} seasons` : `${cell.row.original.contract_remaining_length} season`}`}</span>
        )
      default:
        return cell.render('Cell');
    }
  };

  const setRowSize = (e) => {
    setPageSize(e.value);
    setTableSize(e);
  };

  const mobileSortValues = [
    { value: 0, label: 'Name: A-Z' },
    { value: 1, label: 'Age: Youngest to oldest' },
    { value: 2, label: 'Position' },
    { value: 3, label: 'Rating: High to Low' },
    { value: 4, label: 'Potential: High to Low' },
    { value: 5, label: 'Fitness: High to Low' },
  ];

  const setSortValue = (e) => {
    const x = e.value;
    if (x === 0) toggleSortBy('name', false, false);
    if (x === 1) toggleSortBy('age', false, false);
    if (x === 2) toggleSortBy('sortPriority', false, false);
    if (x === 3) toggleSortBy('ratingId', true, false);
    if (x === 4) toggleSortBy('potentialId', true, false);
    if (x === 5) toggleSortBy('formId', true, false);
  };
  return (
    <>
      <StyledSortContainer>
        <SortLabel>Sort by</SortLabel>
        <StyledSortSelect
          isSearchable={false}
          onChange={setSortValue}
          options={mobileSortValues}
          menuPlacement="auto"
          placeholder="Select sort value"
          classNamePrefix="react-select"
        />
      </StyledSortContainer>
      <StyledTableContainer>
        <StyledTable className="rtable--flip squad-table" {...getTableProps()}>
          {!isMobile ? (
            <StyledThead>
              {headerGroups.map((headerGroup) => (
                <StyledTrTh {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (column.Header === 'Rating') {
                      return (
                        <StyledTh
                          className="rating-cell"
                          type={column.Header}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <InfoDarkAbsolute
                            data-tip
                            clickable="true"
                            data-for="rating"
                          />

                          <StyledReactTooltip
                            id="rating"
                            type="light"
                            effect="solid"
                            place="bottom"
                          >
                            RATING indicates how "good" a player is and can
                            range from WEAKEST to LEGEND
                          </StyledReactTooltip>
                          <span>
                            {column.isSorted ? (
                              <StyledSorted
                                type={
                                  column.isSortedDesc
                                    ? 'fromMaxToMin'
                                    : 'fromMinToMax'
                                }
                              />
                            ) : (
                              <StyledSorted />
                            )}
                          </span>
                        </StyledTh>
                      );
                    }
                    if (column.Header === 'Potential') {
                      return (
                        <StyledTh
                          className="potential-cell"
                          type={column.Header}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <InfoDarkAbsolute
                            data-tip
                            clickable="true"
                            data-for="potential"
                          />

                          <StyledReactTooltip
                            id="potential"
                            type="light"
                            effect="solid"
                            place="bottom"
                          >
                            POTENTIAL shows how “good” a player might become
                            over time. Players develop by playing matches. It’s
                            rare, but possible, for players to develop beyond
                            their potential
                          </StyledReactTooltip>
                          <span>
                            {column.isSorted ? (
                              <StyledSorted
                                type={
                                  column.isSortedDesc
                                    ? 'fromMaxToMin'
                                    : 'fromMinToMax'
                                }
                              />
                            ) : (
                              <StyledSorted />
                            )}
                          </span>
                        </StyledTh>
                      );
                    }
                    if (column.Header === 'Fitness') {
                      return (
                        <StyledTh
                          className="fitness-cell"
                          type={column.Header}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                          <InfoDarkAbsolute
                            data-tip
                            clickable="true"
                            data-for="fitness"
                          />

                          <StyledReactTooltip
                            id="fitness"
                            type="light"
                            effect="solid"
                            place="bottom"
                          >
                            FITNESS is an indication of how fit, or tired, a
                            player is. It can range from INJURED to GREAT.
                            Fitness impacts a player’s performance during
                            matches. Players recover fitness when not playing
                            matches
                          </StyledReactTooltip>
                          <span>
                            {column.isSorted ? (
                              <StyledSorted
                                type={
                                  column.isSortedDesc
                                    ? 'fromMaxToMin'
                                    : 'fromMinToMax'
                                }
                              />
                            ) : (
                              <StyledSorted />
                            )}
                          </span>
                        </StyledTh>
                      );
                    }
                    if (column.Header === 'Media Rating') {
                      return (
                        <StyledTh
                          className="media-rating-cell"
                          type={column.Header}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}

                          <InfoDarkAbsolute
                            data-tip
                            clickable="true"
                            data-for="media-rating"
                          />

                          <StyledReactTooltip
                            id="media-rating"
                            type="light"
                            effect="solid"
                            place="bottom"
                          >
                            MEDIA RATING is a measure of how well a player
                            played in recent matches. 10 means the player did
                            exceptionally well. 1 means the player performed
                            poorly
                          </StyledReactTooltip>

                          <span>
                            {column.isSorted ? (
                              <StyledSorted
                                type={
                                  column.isSortedDesc
                                    ? 'fromMaxToMin'
                                    : 'fromMinToMax'
                                }
                              />
                            ) : (
                              <StyledSorted />
                            )}
                          </span>
                        </StyledTh>
                      );
                    }
                    return (
                      <StyledTh
                        type={column.Header}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            <StyledSorted
                              type={
                                column.isSortedDesc
                                  ? 'fromMaxToMin'
                                  : 'fromMinToMax'
                              }
                            />
                          ) : (
                            <StyledSorted />
                          )}
                        </span>
                      </StyledTh>
                    );
                  })}
                </StyledTrTh>
              ))}
            </StyledThead>
          ) : (
            <></>
          )}
          <StyledTbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <StyledTrTd
                  className={`row-number-${row.id}`}
                  clicked={clicked}
                  index={index}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <StyledTd
                      onClick={() => {
                        setClicked(row.id);
                        getUserToSell(cell.row.original);
                      }}
                      isLoaned={cell.row.original.loanedOut}
                      type={cell.column.Header}
                      data-main={cell.row.original.status === 1}
                      data-listed={cell.row.original.listed}
                      titleCell={cell.column.Header === 'Number'}
                      {...cell.getCellProps()}
                    >
                      {renderCell(cell, index)}
                    </StyledTd>
                  ))}
                </StyledTrTd>
              );
            })}
          </StyledTbody>
        </StyledTable>
      </StyledTableContainer>
      <StyledPagination>
        <StyledPageNumber>
          <StyledPaginationButton
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <ArrowLeft />
          </StyledPaginationButton>
          <StyledPageLabel>
            Page <strong>{pageIndex + 1}</strong> of {pageOptions.length}
          </StyledPageLabel>
          <StyledPaginationButton
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <ArrowRight />
          </StyledPaginationButton>
        </StyledPageNumber>

        <StyledSelectContainer>
          <span>Showing players: </span>
          <StyledSelect
            classNamePrefix="react-select"
            defaultValue={showOptions[1]}
            value={tableSize}
            options={showOptions}
            onChange={setRowSize}
            menuPlacement="auto"
          />
        </StyledSelectContainer>
      </StyledPagination>
    </>
  );
});
