import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { StyledSectionContainer } from '../../../../globalStyled';

export const StyledLoader = styled(Loader)`
  position: fixed;
  top: 50%;
  left: 25%;
  transform: translate(-25%, -50%);
`;

export const StyledTextArrow = styled.div`
  position: relative;
`;

export const StyledRevenueSection = styled(StyledSectionContainer)`
  h3 {
    width: 100%;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.18px;
    border-bottom: 1px solid #f2f2f2;
    padding: 17px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledRevenue = styled.div`
  padding: 15px 30px;
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: block;
  @media screen and (max-width: 991px) {
    padding: 15px 20px;
  }
`;

export const StyledInfoInRevenue = styled.div`
  padding: 0 15px;
  margin: 15px 0;
  &:last-of-type {
    h4 span {
      text-decoration-line: underline;
    }
  }
  h4,
  ul li {
    span {
      &[data-total-sign='positive'] {
        &:before {
          margin-right: 0px;
          content: '+';
          font-family: 'Gilroy', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          color: #219653;
        }
      }
      &.money-amount {
        &[data-total-sign='positive'] {
          color: #219653;
        }

        &[data-total-sign='negative'] {
          color: #eb5757;
        }
      }
    }
  }
  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #000000;
    span {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: -0.03em;
      color: #000000;
    }
  }
  &[data-total-sign='negative'] {
    h4 {
      span {
        color: #eb5757;
        text-decoration-line: none;
      }
    }
  }
  ul > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #000000;
    padding: 12px 0;
    list-style-type: none;
    span {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 26px;
      letter-spacing: -0.03em;
      color: #000000;
    }
  }
`;

export const StyledMarkedTitle = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  border-radius: 2px;
  h3 {
    font-weight: bold;
    font-size: 15px;
    padding: 0;
  }
`;

export const StyledCashValue = styled.span`
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  font-size: 15px;
`;
