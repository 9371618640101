import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import usePortal from 'react-useportal';

import {
  ReleasePlayerPopup,
  ConfirmPlayerImprovementsPopup,
  LoanPopup,
  BuyOfferPopup,
  NicknamePopup,
  ConfirmAcademyPopup,
  ContractOfferPopup
} from '../Popup';
import { getPopupByName } from '../../redux/selector/Popups';
import { POPUPS } from '../../service/constants';

const TeamSquadPopups = ({
  selectedPlayer,
  cash,
  onDelayDecline,
  onAccelerateDevelopment
}) => {
  const { name, _id, team, ratingRange, salary, contract } = selectedPlayer;
  const seasons = contract?.data?.length;
  const seasonsCount = contract?.current_stats.seasons_count;
  const seasonsLeft = seasons - seasonsCount;
  const {
    CONFIRM_ACCELERATE_POPUP,
    CONFIRM_DECLINE_DELAY_POPUP,
    TRANSFER_SEARCH_POPUP,
    LOAN_OFFER_POPUP,
    RELEASE_PLAYER_POPUP,
    ADD_NICKNAME_POPUP,
    CONFIRM_ACADEMY_POPUP,
    CONTRACT_EXTEND_POPUP,
  } = POPUPS;

  const releasePlayerPopupData = useSelector(
    getPopupByName(RELEASE_PLAYER_POPUP)
  );
  const sendToAcademyPopupData = useSelector(
    getPopupByName(CONFIRM_ACADEMY_POPUP)
  );
  const loanPopupData = useSelector(getPopupByName(LOAN_OFFER_POPUP));
  const transferSearchPopup = useSelector(
    getPopupByName(TRANSFER_SEARCH_POPUP)
  );
  const acceleratePopupData = useSelector(
    getPopupByName(CONFIRM_ACCELERATE_POPUP)
  );
  const isNicknamePopupVisible = useSelector(
    getPopupByName(ADD_NICKNAME_POPUP)
  );
  const declineDelayPopupData = useSelector(
    getPopupByName(CONFIRM_DECLINE_DELAY_POPUP)
  );
  const contractExtendPopupData = useSelector(
    getPopupByName(CONTRACT_EXTEND_POPUP)
  );
  const isAcceleratePopupVisible = !isEmpty(acceleratePopupData);
  const isDeclineDelayPopupVisible = !isEmpty(declineDelayPopupData);

  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  return (
    <>
      {loanPopupData && (
        <Portal>
          <LoanPopup
            popupName={LOAN_OFFER_POPUP}
            playerId={_id}
            playerName={name}
            team={team}
          />
        </Portal>
      )}
      {releasePlayerPopupData && (
        <Portal>
          <ReleasePlayerPopup
            cash={cash}
            popupName={RELEASE_PLAYER_POPUP}
            id={_id}
            salary={salary}
            seasonsLeft={seasonsLeft}

          />
        </Portal>
      )}
      {sendToAcademyPopupData && (
        <Portal>
          <ConfirmAcademyPopup
            cash={cash}
            popupName={CONFIRM_ACADEMY_POPUP}
            id={_id}
          />
        </Portal>
      )}
      {isDeclineDelayPopupVisible && (
        <Portal>
          <ConfirmPlayerImprovementsPopup
            header="Delay decline"
            text="This program will delay your player’s decline until the player retires"
            cash={cash}
            popupName={CONFIRM_DECLINE_DELAY_POPUP}
            id={_id}
            penaltiSize="2000000"
            confirmAction={onDelayDecline}
          />
        </Portal>
      )}
      {isAcceleratePopupVisible && (
        <Portal>
          <ConfirmPlayerImprovementsPopup
            header="Accelerate development"
            text="This program will accelerate your player’s development until peak performance is reached"
            cash={cash}
            popupName={CONFIRM_ACCELERATE_POPUP}
            id={_id}
            penaltiSize="2000000"
            confirmAction={onAccelerateDevelopment}
          />
        </Portal>
      )}
      {transferSearchPopup && (
        <Portal>
          <BuyOfferPopup
            playerId={selectedPlayer._id}
            minPotentialValue={selectedPlayer.potential_min}
            maxPotentialValue={selectedPlayer.potential_max}
            popupName={TRANSFER_SEARCH_POPUP}
            team={selectedPlayer.teamName}
            playerName={selectedPlayer.name}
          />
        </Portal>
      )}
      {isNicknamePopupVisible && (
        <Portal>
          <NicknamePopup
            playerId={selectedPlayer._id}
            popupName={ADD_NICKNAME_POPUP}
            playerName={selectedPlayer.name}
          />
        </Portal>
      )}
      {contractExtendPopupData && contractExtendPopupData.id === selectedPlayer._id &&
        <Portal>
          <ContractOfferPopup
            isExtended
            popupName={CONTRACT_EXTEND_POPUP}
            id={selectedPlayer._id}
            team={selectedPlayer.teamName}
            ratingRange={ratingRange}
            isFreeAgentPopup={false}
            salary={salary}
          />
        </Portal>
      }
    </>
  );
};
export default TeamSquadPopups;
