import React from 'react';
import PropTypes from 'prop-types';
import emptyField from '../../assets/img/team-shirts/empty-field.png';
// Default jerseys
import albertaSC from '../../assets/img/team-shirts/default/Alberta SC.png';
import sycamoreFC from '../../assets/img/team-shirts/default/Sycamore.png';
import gizaClub from '../../assets/img/team-shirts/default/Giza Club.png';
import valparaisoMariners from '../../assets/img/team-shirts/default/Valparaiso Mariners AC.png';
import luchadores from '../../assets/img/team-shirts/default/Luchadores.png';
import ileDeFranceFoot from '../../assets/img/team-shirts/default/Ile-de-France Foot.png';
import himachalAvalanche from '../../assets/img/team-shirts/default/Himachal Avalanche.png';
import melbourneUnited from '../../assets/img/team-shirts/default/Melbourne United.png';
import blueDevilsBusan from '../../assets/img/team-shirts/default/Blue Devils Busan.png';
import sarabiSC from '../../assets/img/team-shirts/default/Sarabi SC.png';
import copthorne from '../../assets/img/team-shirts/default/Copthorne S.A.png';
import cuscoConductores from '../../assets/img/team-shirts/default/Cusco Conductores.png';
import raizesSaoPaulo from '../../assets/img/team-shirts/default/Raizes Sao Paulo.png';
import royalCambridgeFC from '../../assets/img/team-shirts/default/Royal Cambridge FC.png';
import theGymnasticSociety from '../../assets/img/team-shirts/default/The Gymnastic Society.png';
import mumbaiLeopards from '../../assets/img/team-shirts/default/Mumbai Leopards.png';
import greatOSV from '../../assets/img/team-shirts/default/Great OSV.png';
import olympiansCariocas from '../../assets/img/team-shirts/default/Olympians Cariocas.png';
import sanLorenzoBulls from '../../assets/img/team-shirts/default/San Lorenzo Bulls.png';
import portoAlegreInternational from '../../assets/img/team-shirts/default/Porto Alegre International.png';
import peterboroughPreachers from '../../assets/img/team-shirts/default/Peterborough Preachers.png';
import bartsHospital from '../../assets/img/team-shirts/default/Barts Hospital.png';
import berlinMayhem from '../../assets/img/team-shirts/default/Berlin Mayhem.png';
import rotoruaSavages from '../../assets/img/team-shirts/default/Rotorua Savages.png';
import cobrasUnited from '../../assets/img/team-shirts/default/Cobras United.png';
import sonsOfSahya from '../../assets/img/team-shirts/default/Sons of the Sahya.png';
import dohaFalcons from '../../assets/img/team-shirts/default/Doha Falcons.png';
import cordobaGauchos from '../../assets/img/team-shirts/default/Cordoba Gauchos.png';
import revolucaoSaoPaulo from '../../assets/img/team-shirts/default/Revolucao Sao Paulo.png';
import lagosSunshine from '../../assets/img/team-shirts/default/Lagos Sunshine FC.png';
import BBZFC from '../../assets/img/team-shirts/default/BBZ F.C.png';
// Premium jerseys
import premiumAlbertaSC from '../../assets/img/team-shirts/premium/Alberta SC.png';
import premiumSycamoreFC from '../../assets/img/team-shirts/premium/Sycamore.png';
import premiumGizaClub from '../../assets/img/team-shirts/premium/Giza Club.png';
import premiumValparaisoMariners from '../../assets/img/team-shirts/premium/Valparaiso Mariners AC.png';
import premiumLuchadores from '../../assets/img/team-shirts/premium/Luchadores.png';
import premiumIleDeFranceFoot from '../../assets/img/team-shirts/premium/Ile-de-France Foot.png';
import premiumHimachalAvalanche from '../../assets/img/team-shirts/premium/Himachal Avalanche.png';
import premiumMelbourneUnited from '../../assets/img/team-shirts/premium/Melbourne United.png';
import premiumBlueDevilsBusan from '../../assets/img/team-shirts/premium/Blue Devils Busan.png';
import premiumSarabiSC from '../../assets/img/team-shirts/premium/Sarabi SC.png';
import premiumCopthorne from '../../assets/img/team-shirts/premium/Copthorne S.A.png';
import premiumCuscoConductores from '../../assets/img/team-shirts/premium/Cusco Conductores.png';
import premiumRaizesSaoPaulo from '../../assets/img/team-shirts/premium/Raizes Sao Paulo.png';
import premiumRoyalCambridgeFC from '../../assets/img/team-shirts/premium/Royal Cambridge FC.png';
import premiumTheGymnasticSociety from '../../assets/img/team-shirts/premium/The Gymnastic Society.png';
import premiumMumbaiLeopards from '../../assets/img/team-shirts/premium/Mumbai Leopards.png';
import premiumGreatOSV from '../../assets/img/team-shirts/premium/Great OSV.png';
import premiumOlympiansCariocas from '../../assets/img/team-shirts/premium/Olympians Cariocas.png';
import premiumSanLorenzoBulls from '../../assets/img/team-shirts/premium/San Lorenzo Bulls.png';
import premiumPortoAlegreInternational from '../../assets/img/team-shirts/premium/Porto Alegre International.png';
import premiumBartsHospital from '../../assets/img/team-shirts/premium/Barts Hospital.png';
import premiumBerlinMayhem from '../../assets/img/team-shirts/premium/Berlin Mayhem.png';
import premiumRotoruaSavages from '../../assets/img/team-shirts/premium/Rotorua Savages.png';
import premiumCobrasUnited from '../../assets/img/team-shirts/premium/Cobras United.png';
import premiumSonsOfSahya from '../../assets/img/team-shirts/premium/Sons of the Sahya.png';
import premiumDohaFalcons from '../../assets/img/team-shirts/premium/Doha Falcons.png';
import premiumCordobaGauchos from '../../assets/img/team-shirts/premium/Cordoba Gauchos.png';
import premiumRevolucaoSaoPaulo from '../../assets/img/team-shirts/premium/Revolucao Sao Paulo.png';
import premiumLagosSunshine from '../../assets/img/team-shirts/premium/Lagos Sunshine FC.png';
import premiumBBZFC from '../../assets/img/team-shirts/premium/BBZ F.C.png';
import { StyledShirt } from './styled';

const teamNameToTshirtName = {
  'Sycamore FC': sycamoreFC,
  'Giza Club': gizaClub,
  'Alberta SC': albertaSC,
  'Valparaiso Mariners AC': valparaisoMariners,
  Luchadores: luchadores,
  'Ile-de-France Foot': ileDeFranceFoot,
  'Himachal Avalanche': himachalAvalanche,
  'Blue Devils Busan': blueDevilsBusan,
  'Sarabi SC': sarabiSC,
  'Melbourne United': melbourneUnited,
  'Copthorne S.A.': copthorne,
  'Cusco Conductores': cuscoConductores,
  'Raizes Sao Paulo': raizesSaoPaulo,
  'Royal Cambridge FC': royalCambridgeFC,
  'The Gymnastic Society': theGymnasticSociety,
  'Mumbai Leopards': mumbaiLeopards,
  'Great OSV': greatOSV,
  'Olympians Cariocas': olympiansCariocas,
  'San Lorenzo Bulls': sanLorenzoBulls,
  'Porto Alegre International': portoAlegreInternational,
  'Peterborough Preachers': peterboroughPreachers,
  'Barts Hospital': bartsHospital,
  'Berlin Mayhem': berlinMayhem,
  'Rotorua Savages': rotoruaSavages,
  'Cobras United': cobrasUnited,
  'Sons of the Sahya': sonsOfSahya,
  'Doha Falcons': dohaFalcons,
  'Cordoba Gauchos': cordobaGauchos,
  'Revolucao Sao Paulo': revolucaoSaoPaulo,
  'Lagos Sunshine FC': lagosSunshine,
  'BBZ F.C.': BBZFC,
};

const teamNameToPremiumShirtName = {
  'Sycamore FC': premiumSycamoreFC,
  'Giza Club': premiumGizaClub,
  'Alberta SC': premiumAlbertaSC,
  'Valparaiso Mariners AC': premiumValparaisoMariners,
  Luchadores: premiumLuchadores,
  'Ile-de-France Foot': premiumIleDeFranceFoot,
  'Himachal Avalanche': premiumHimachalAvalanche,
  'Blue Devils Busan': premiumBlueDevilsBusan,
  'Sarabi SC': premiumSarabiSC,
  'Melbourne United': premiumMelbourneUnited,
  'Copthorne S.A.': premiumCopthorne,
  'Cusco Conductores': premiumCuscoConductores,
  'Raizes Sao Paulo': premiumRaizesSaoPaulo,
  'Royal Cambridge FC': premiumRoyalCambridgeFC,
  'The Gymnastic Society': premiumTheGymnasticSociety,
  'Mumbai Leopards': premiumMumbaiLeopards,
  'Great OSV': premiumGreatOSV,
  'Olympians Cariocas': premiumOlympiansCariocas,
  'San Lorenzo Bulls': premiumSanLorenzoBulls,
  'Porto Alegre International': premiumPortoAlegreInternational,
  'Barts Hospital': premiumBartsHospital,
  'Berlin Mayhem': premiumBerlinMayhem,
  'Rotorua Savages': premiumRotoruaSavages,
  'Cobras United': premiumCobrasUnited,
  'Sons of the Sahya': premiumSonsOfSahya,
  'Doha Falcons': premiumDohaFalcons,
  'Cordoba Gauchos': premiumCordobaGauchos,
  'Revolucao Sao Paulo': premiumRevolucaoSaoPaulo,
  'Lagos Sunshine FC': premiumLagosSunshine,
  'BBZ F.C.': premiumBBZFC,
};

const TeamShirt = ({ team, style, isPremium = false, teamKit }) => {
  const setTeamShirts = () => {
    if (team && teamKit) {
      return teamKit;
    }
    if (team && !isPremium) {
      return teamNameToTshirtName[team];
    }
    if (team && isPremium) {
      return teamNameToPremiumShirtName[team];
    }

    return emptyField;
  };
  return <StyledShirt style={style} src={setTeamShirts()} />;
};
TeamShirt.propTypes = {
  team: PropTypes.string,
  style: PropTypes.object,
};
TeamShirt.defaultProps = {
  team: null,
  style: {},
};
export default TeamShirt;
