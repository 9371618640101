import styled from 'styled-components';

export const StyledShirt = styled.img`
  width: 70px;
  max-height: 83px;
  margin: 0 auto;
  ${({ style }) => ({ ...style })}

  @media (max-width: 768px) {
    width: 35px;
    height: auto;
  }
  @media only screen and (min-width: 769px) and (max-width: 1199px) {
    width: 45px;
    height: auto;
  }
`;
