import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { ReactComponent as Lobby } from '../../../../assets/img/sidebar/Lobby.svg';

import { ReactComponent as Team } from '../../../../assets/img/sidebar/Team.svg';
import { ReactComponent as Transfers } from '../../../../assets/img/sidebar/Transfers.svg';
import { ReactComponent as League } from '../../../../assets/img/sidebar/League.svg';
import { ReactComponent as Finance } from '../../../../assets/img/sidebar/Finance.svg';
import { ReactComponent as Store } from '../../../../assets/img/sidebar/Store.svg';
import { ReactComponent as DomesticCup } from '../../../../assets/img/sidebar/Cup.svg';
import { ReactComponent as UserGuide } from '../../../../assets/img/sidebar/UserGuide.svg';
import { ReactComponent as Kits } from '../../../../assets/img/sidebar/kits.svg';
import {
  StyledSideNav,
  StyledSummary,
  StyledNavLinks,
  StyledTextNavLink,
  StyledMobileContainer,
  StyledAvatar,
  StyledRankingContainer,
  StyledMoneyContainer,
  StyledSidebar,
} from './styled';
import UserDropdown from '../../../UserDropdown';

import { showPopupWindow } from '../../../../redux/actions/Popups';
import { allowTour } from '../../../../redux/actions/TourControl';
import { userLogout } from '../../../../redux/actions/User';
import { sidebarOpenSideNavigation } from '../../../../redux/actions/Sidebar';

import { dataForSidebar } from '../../../../redux/selector/Sidebar';
import { getUser, isAuth } from '../../../../redux/selector/User';

import { cashFormat } from '../../../../service/functions';
import { toOrdinalSuffix } from '../../../../service/utils';
import { POPUPS } from '../../../../service/constants';

import {
  HOME_STORAGE,
  SQUAD_STORAGE,
  STRATEGY_STORAGE,
  DEALS_STORAGE,
  PLAYER_INFO_STORAGE,
  SEARCH_STORAGE,
  FINANCE_STORAGE,
} from '../../../../helpers/tour/constants';

export default React.memo(function Menu({ showHeader, isSideOpen }) {
  const dispatch = useDispatch();

  const { avatarID, discord } = useSelector(getUser);
  const isAuthenticated = useSelector(isAuth);
  const data = useSelector(dataForSidebar);
  const avatarUrl = `https://cdn.discordapp.com/avatars/${discord}/${avatarID}.jpg`;

  const openInvitePopup = () => {
    dispatch(showPopupWindow(POPUPS.INVITE_POPUP, POPUPS.INVITE_POPUP));
  };

  const logout = () => {
    dispatch(userLogout());
    localStorage.removeItem('isFromRegister');
  };

  const showTutorial = () => {
    const localStorageTourList = [
      HOME_STORAGE,
      SQUAD_STORAGE,
      STRATEGY_STORAGE,
      DEALS_STORAGE,
      PLAYER_INFO_STORAGE,
      SEARCH_STORAGE,
      FINANCE_STORAGE,
    ];
    localStorageTourList.forEach((localStorageItem) => {
      if (localStorageItem) {
        localStorage.removeItem(localStorageItem);
      }
    });
    dispatch(sidebarOpenSideNavigation(false));
    dispatch(allowTour(true));
  };

  const leaveGame = () => {
    dispatch(showPopupWindow(POPUPS.LEAVE_POPUP, POPUPS.LEAVE_POPUP));
  };

  return (
    <StyledSideNav className="sidebar" data-sideopen={isSideOpen}>
      <StyledSummary className={showHeader ? '' : 'isOnMain'}>
        <StyledMobileContainer>
          <StyledAvatar src={avatarUrl} alt="avatar" />
          <StyledRankingContainer to="/league">
            {toOrdinalSuffix(data.ranking + 1)}
            <span>in League</span>
          </StyledRankingContainer>

          <StyledMoneyContainer
            data-total-sign={`${data.cash < 0 ? 'negative' : 'positive'}`}
            to="/finance"
          >
            {cashFormat(data.cash, '$')}
            <span>Cash balance</span>
          </StyledMoneyContainer>
        </StyledMobileContainer>
      </StyledSummary>
      <StyledSidebar>
        <div>
          <StyledNavLinks to="/home" activeClassName="selected" href="#">
            <Lobby />
            <span>Lobby</span>
          </StyledNavLinks>
          <StyledNavLinks activeClassName="selected" to="/team" href="#">
            <Team />
            <span>Team</span>
          </StyledNavLinks>
          <StyledNavLinks activeClassName="selected" to="/transfers" href="#">
            <Transfers />
            <span>Transfers</span>
          </StyledNavLinks>
          <StyledNavLinks activeClassName="selected" to="/stuff" href="#">
            <Kits />
            <span>Kits</span>
          </StyledNavLinks>
          <StyledNavLinks
            activeClassName="selected"
            to={{ pathname: 'https://the-open-league.myshopify.com/' }}
            target="_blank"
          >
            <Store />
            <span>Store</span>
          </StyledNavLinks>

          <StyledNavLinks activeClassName="selected" to="/league" href="#">
            <League />
            <span>League</span>
          </StyledNavLinks>
          <StyledNavLinks
            activeClassName="selected"
            to="/domestic-cup"
            href="#"
          >
            <DomesticCup />
            <span>Cup</span>
          </StyledNavLinks>
          <StyledNavLinks activeClassName="selected" to="/finance" href="#">
            <Finance />
            <span>Finance</span>
          </StyledNavLinks>


          <StyledNavLinks activeClassName="selected" to="/user-guide" href="#">
            <UserGuide />
            <span>User guide</span>
          </StyledNavLinks>
        </div>
        {!isMobile && (
          <div>
            <StyledTextNavLink
              to="/privacy"
              target="_blank"
              rel="noreferrer noopener"
              href="#"
            >
              <span>Privacy</span>
            </StyledTextNavLink>
            <StyledTextNavLink
              to="/terms"
              target="_blank"
              rel="noreferrer noopener"
              href="#"
            >
              <span>Terms of use</span>
            </StyledTextNavLink>
          </div>
        )}
      </StyledSidebar>
      <StyledMobileContainer>
        <UserDropdown
          openInvitePopupCallback={() => {
            openInvitePopup();
          }}
          logoutCallback={() => {
            if (isAuthenticated) {
              logout();
            }
          }}
          leaveGameCallback={() => {
            if (isAuthenticated) {
              leaveGame();
            }
          }}
          showTutorialCallback={() => {
            showTutorial();
          }}
        />
      </StyledMobileContainer>
    </StyledSideNav>
  );
});
