import styled from 'styled-components';
import { ReactComponent as Shield } from '../../assets/img/shield.svg';

export const StyledWinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  width: 90px;
  justify-content: space-between;
  img {
    max-width: 70px;
    max-height: 70px;
    width: 100%;
    height: 100%;
  }
`;
export const StyledLabel = styled.span`
  font-size: 18px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  color: #e5e5e5;
`;

export const StyledSeparator = styled.div`
  width: 100%;
  border: 1px solid #e5e5e5;
  margin: 15px 0;
`;
export const StyledNoWinner = styled(Shield)`
  width: 70px;
  height: 70px;
`;
