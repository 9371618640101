import * as action from '../../actions/TourControl';
import { handleActions } from '../../helpers/immer';

const initialValue = {
  runTour: false,
};

export const tourControl = handleActions(
  {
    [action.tourShow]: (draft, { payload }) => {
      draft.runTour = payload;
    },
  },
  initialValue
);
