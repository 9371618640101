import { createSelector } from 'reselect';

export const getFinance = (state) => state.finance;
export const getRevenueAndExpanses = (state) =>
  state.finance.revenueAndExpanses;
export const getTransactions = (state) => state.finance.transactions;
export const getChart = (state) => state.finance.financeChart;

// ---------------revenue and expanses-----------------------
export const getTransactionSummary = createSelector(
  getRevenueAndExpanses,
  ({ data: { transactionsSummary } }) => transactionsSummary
);
export const getTeamTotalSummary = createSelector(
  getRevenueAndExpanses,
  ({ data: { teamTotalSummary } }) => teamTotalSummary || {}
);
export const getLoansAndTransfers = createSelector(
  getRevenueAndExpanses,
  ({ data: { loansAndTransfers } }) => loansAndTransfers
);
export const isRevenueAndExpansesDataLoaded = createSelector(
  getRevenueAndExpanses,
  ({ loading }) => loading
);

// --------------transactions--------------------------------
export const getTransactionsData = createSelector(
  getTransactions,
  ({ data }) => data
);
export const isTransactionDataLoaded = createSelector(
  getTransactions,
  ({ loading }) => loading
);
