import styled, { css } from 'styled-components';

export const StyledReadMoreLink = styled.span`
  font-family: 'Gilroy-SemiBold', sans-serif;
  color: #000;
  font-size: 12px;
  cursor: pointer;
  ${({ additionalLabelStyles }) => {
    if (additionalLabelStyles) {
      return css`
        ${additionalLabelStyles}
      `;
    }
  }}
`;
