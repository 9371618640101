import React from 'react';
import ContentLoader from 'react-content-loader';

const TransactionsPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={545}
    height={50}
    viewBox="0 0 545 50"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="66" y="21" rx="3" ry="3" width="77" height="13" />
    <rect x="428" y="18" rx="3" ry="3" width="96" height="16" />
    <rect x="31" y="18" rx="22" ry="22" width="20" height="20" />
  </ContentLoader>
);

export default TransactionsPreloader;
