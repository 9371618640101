import styled from 'styled-components';
import { StyledWrapper } from '../styled';

export const StyledNewWrapper = styled(StyledWrapper)`
  flex-direction: column;
`;

export const StyledTeamCard = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 24px;
  @media (min-width: 48em) {
    margin: 0 25px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 1199px) {
    margin: 0 0 24px 0;
  }
  @media screen and (max-width: 426px) {
    margin: 15px 0 24px 0;
  }
  @media screen and (min-width: 769px) {
    margin: 0 20px 25px 0px;
  }
  > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 15px;
  }
`;

export const StyledBlockContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;

export const StyledContainer = styled.div`
  height: 100%;
  margin: 0 0px;
  justify-content: space-between;

  &.first {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    @media screen and (max-width: 1199px) {
      width: 100%;
      flex: none;
      margin: 0;
    }
  }
  &.second {
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1199px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    margin: 0 0px;
  }
`;

export const StyledLobbySection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  @media only screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;
export const StyledTeamName = styled.div`
  display: flex;
  flex-direction: column;
  height: 205px;
  width: 100%;
  max-width: 520px;
  background-color: #fff;
  padding: 30px 26px;
  border-radius: 4px;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    padding: 10px 15px;
  }
`;
export const StyledTeamStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32%;
  max-width: 220px;
  background-color: #fff;
  border-radius: 4px;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    flex-direction: row;
    max-width: 100%;
    margin-top: 20px;
  }
`;

export const StyledInfoLabel = styled.span`
  cursor: pointer;
  padding: 7px 15px;
  border-radius: 4px;
  background-color: #fdca40;
  text-transform: uppercase;
  letter-spacing: 0.5;
  font-family: Gilroy;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  margin-right: 12px;
`;
export const StyledRankingContainer = styled.div`
  display: flex;
  width: auto;
  margin: 7px 0;
`;

export const StyledBestBadge = styled.div`
  background-color: #3b3b3b;
  border-radius: 4px;
  color: #fff;
  font-size: 9px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  @media only screen and (max-width: 1199px) {
    margin-right: 7px;
  }
`;
export const StyledBestTitle = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  padding: 7px 10px;
  @media only screen and (max-width: 1199px) {
    font-size: 8px;
    padding: 7px;
  }
`;
export const StyledBestLabel = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  border-left: 1px solid #fff;
  padding: 7px 10px;
`;

export const StyledSmallStats = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    //margin-top: 20px;
  }
`;

export const StyledLeagueLabel = styled.span`
  color: rgba(25, 25, 25, 0.5);
  font-family: Gilroy;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 1.28px;
  text-transform: uppercase;
`;

export const StyledTeamTextFields = styled.div`
  display: flex;
  flex-direction: column;
  > h1 {
    margin: 0;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.91px;
    width: 100%;
    text-transform: none;
    margin-bottom: 10px;

    width: auto;
    @media screen and (max-width: 426px) {
      margin: 0;
    }
    @media screen and (max-width: 1199px) {
      margin: 0;
    }
    @media screen and (min-width: 769px) {
    }
  }
`;

export const SyledTopLeftSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 205px;
  width: 49%;
  justify-content: space-between;

  @media only screen and (max-width: 1199px) {
    width: 100%;
    flex-direction: column;
    height: auto;
  }
`;

export const StyledHalfWidthSection = styled.div`
  width: 49%;
  background-color: #fff;
  border-radius: 4px;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    margin-bottom: 23px;
  }
`;

export const StyledTeamInfo = styled.div`
  background-color: #fff;
  border-radius: 4px;
  width: 66%;
  max-width: 520px;
  @media only screen and (max-width: 1199px) {
    width: 100%;
    max-width: 100%;
  }
`;
