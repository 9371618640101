import React from 'react';
import ContentLoader from 'react-content-loader';

const MobileCupGroupPreloader = (props) => (
  <ContentLoader
    speed={2}
    width={325}
    height={115}
    viewBox="0 0 325 115"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="213" y="38" rx="3" ry="3" width="88" height="6" />
    <rect x="23" y="38" rx="3" ry="3" width="88" height="6" />
    <circle cx="126" cy="41" r="8" />
    <circle cx="200" cy="41" r="8" />
    <rect x="143" y="31" rx="0" ry="0" width="40" height="21" />
    <rect x="213" y="73" rx="3" ry="3" width="88" height="6" />
    <rect x="23" y="73" rx="3" ry="3" width="88" height="6" />
    <circle cx="126" cy="76" r="8" />
    <circle cx="200" cy="76" r="8" />
    <rect x="143" y="66" rx="0" ry="0" width="40" height="21" />
  </ContentLoader>
);

export default MobileCupGroupPreloader;
