import { headers } from './requestConfig';
import { tasks } from '../service/links';

export const getTasks = async () => {
  const response = await fetch(tasks, {
    method: 'GET',
    headers,
    credentials: 'include',
  });
  return response;
};
