import React from 'react';
import PropTypes from 'prop-types';
import { StyledServerAvailability } from './styled';

const ServerAvailability = ({ isFull }) => (
  <StyledServerAvailability isFull={isFull}>
    {isFull ? 'Full' : 'Teams available'}
  </StyledServerAvailability>
);

ServerAvailability.propTypes = {
  isFull: PropTypes.bool.isRequired,
};

export default ServerAvailability;
