import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import {
  StyledPopUpBlock,
  StyledCross,
  StyledPopUpContainer,
  StyledButtonContainer,
} from '../styled';
import { StyledTableLoader } from '../../../globalStyled';

import {
  StyledTermsContainer,
  StyledTermsButton,
  StyledShadow,
  StyledPopupWrapper,
  StyledIframe,
} from './styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { sendSelectTeamRequest } from '../../../redux/actions/SignUp';

import 'react-calendar/dist/Calendar.css';

const TermsPopup = ({ id, universeName }) => {
  const termsPopupName = 'termsPopup';

  const [isSpinnerVisible, showSpinner] = React.useState(true);

  const dispatch = useDispatch();

  const hidePopup = () => {
    dispatch(hidePopupWindow(termsPopupName));
  };

  const approveSelection = () => {
    mixpanel.track('User accepts terms');
    mixpanel.people.set({ 'Player universe name': universeName });
    mixpanel.register({ 'Player universe name': universeName });
    dispatch(sendSelectTeamRequest(id));
    hidePopup();
  };

  const cancelSelection = () => {
    hidePopup();
    mixpanel.track('User rejects terms');
  };

  return (
    <StyledPopupWrapper>
      <StyledPopUpBlock maxWidth={680} maxHeight={575} paddingAround="20px">
        <StyledCross onClick={() => hidePopup()} />
        <StyledPopUpContainer noOverflow>
          <StyledTermsContainer>
            {isSpinnerVisible && (
              <StyledTableLoader
                type="Bars"
                color="#2AFC98"
                height={100}
                width={100}
              />
            )}
            <StyledIframe
              onLoad={() => showSpinner(false)}
              loading="eager"
              title="Terms"
              src="https://app.termly.io/policy-viewer/policy.html?policyUUID=ab61bce6-ca20-4739-8dd8-8b3c7cdd7934"
            />
          </StyledTermsContainer>
          <StyledButtonContainer>
            <StyledShadow>
              <StyledTermsButton basic onClick={cancelSelection}>
                Cancel
              </StyledTermsButton>
              <StyledTermsButton primary onClick={() => approveSelection(id)}>
                Confirm
              </StyledTermsButton>
            </StyledShadow>
          </StyledButtonContainer>
        </StyledPopUpContainer>
      </StyledPopUpBlock>
    </StyledPopupWrapper>
  );
};
TermsPopup.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(TermsPopup);
