import React from 'react';

import usePortal from 'react-useportal';

import { useDispatch, useSelector } from 'react-redux';
import { sidebarOpenRightPanel } from '../../../../redux/actions/Sidebar';
import { transferShowUnshowFilter } from '../../../../redux/actions/Transfers';
import {
  isFilterShown,
  getSelectedPlayerByIndexInSearchable,
} from '../../../../redux/selector/Transfers';
import { isPremiumUser } from '../../../../redux/selector/User';

import { ReactComponent as Icon } from '../../../../assets/img/down-icon.svg';
import {
  StyledManipulatorContainer,
  StyledFilterContainer,
  StyledOpenFilterContainer,
  StyledOpenFilterButton,
} from './styled';
import CSVReport from '../CSVReport';

export default React.memo(function SearchTableFilter({ headerGroups }) {
  const dispatch = useDispatch();
  const isFilterVisible = useSelector(isFilterShown);
  const isPremium = useSelector(isPremiumUser);
  const playerThatOfferedToBuy = useSelector(
    getSelectedPlayerByIndexInSearchable
  );
  const { Portal } = usePortal({
    bindTo:
      document && document.querySelector('.right-sidebar .filter-container'),
  });

  return (
    <StyledFilterContainer>
      <CSVReport isPremium={isPremium} />
      <StyledOpenFilterContainer
        className="filter-section"
        onClick={() => {
          dispatch(sidebarOpenRightPanel(true));
          // if we didn't get info about player and if after next click we didn't see in right sidebar ANYTHING - we exit from function
          if (!playerThatOfferedToBuy && !isFilterVisible === false) return;
          dispatch(transferShowUnshowFilter(!isFilterVisible));
        }}
      >
        <span>Filter</span>
        <StyledOpenFilterButton>
          <Icon
            style={{
              transform: isFilterVisible ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </StyledOpenFilterButton>
      </StyledOpenFilterContainer>
      <Portal>
        <StyledManipulatorContainer openFilter={isFilterVisible.toString()}>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column, index) =>
              column.canFilter
                ? column.render('Filter', { key: index, data: column })
                : null
            )
          )}
        </StyledManipulatorContainer>
      </Portal>
    </StyledFilterContainer>
  );
});
