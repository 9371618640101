import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import usePortal from 'react-useportal';

import { StyledWrapper, StyledLoader, StyledFiltersContainer } from './styled';

import YouthCampDetails from '../../components/YouthCampDetails';
import YouthCampFilter from '../../components/YouthCampFilter';
import YouthCampHeader from '../../components/YouthCampHeader';
import YouthCampPlayers from '../../components/YouthCampPlayers';

import { ReadMoreFullPopup, BidPopup } from '../../components/Popup';

import {
  getYouthCampPlayersRequest,
  getYouthCampStatusRequest,
} from '../../redux/actions/YouthCamp';
import { sidebarGetDataForSideBar } from '../../redux/actions/Sidebar';

import {
  getYouthCampDataLoading,
  isYouthCampAvailable,
  getYouthCampFilteredPlayers,
} from '../../redux/selector/YouthCamp';
import { getPopupByName } from '../../redux/selector/Popups';
import { POPUPS } from '../../service/constants';
// TODO
// Add error statuses
const YouthCamp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const readMoreFullPopupData = useSelector(
    getPopupByName(POPUPS.READ_MORE_FULL_POPUP)
  );
  const bidPopupData = useSelector(getPopupByName(POPUPS.BID_POPUP));

  const youthCampPlayers = useSelector(getYouthCampFilteredPlayers);
  const isLoading = useSelector(getYouthCampDataLoading);
  const isYouthCampAuth = useSelector(isYouthCampAvailable);
  const { Portal } = usePortal({
    bindTo: document && document.querySelector('#modal'),
  });

  useEffect(() => {
    dispatch(sidebarGetDataForSideBar());
    if (isYouthCampAuth === null) {
      dispatch(getYouthCampStatusRequest());
    } else if (!isYouthCampAuth) {
      history.push('/home');
    } else {
      dispatch(getYouthCampPlayersRequest());
    }
  }, [dispatch, isYouthCampAuth, history]);

  return (
    <>
      {!isEmpty(readMoreFullPopupData) && (
        <Portal>
          <ReadMoreFullPopup
            popupName={POPUPS.READ_MORE_FULL_POPUP}
            id={readMoreFullPopupData.id}
          />
        </Portal>
      )}
      {!isEmpty(bidPopupData) && (
        <Portal>
          <BidPopup popupName={POPUPS.BID_POPUP} id={bidPopupData.id} />
        </Portal>
      )}
      <StyledWrapper>
        {isLoading || !isYouthCampAuth ? (
          <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
        ) : (
          <>
            <YouthCampHeader />
            <StyledFiltersContainer>
              <YouthCampDetails />
              <YouthCampFilter />
            </StyledFiltersContainer>
            <YouthCampPlayers players={youthCampPlayers} />
          </>
        )}
      </StyledWrapper>
    </>
  );
};

export default React.memo(YouthCamp);
