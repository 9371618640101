import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Stuff';

const initialValue = {
  stuff: [],
  selectedKit: {},

  loading: true,
};

export const stuff = handleActions(
  {
    [action.getStuffLoading]: (draft, { payload }) => {
      draft.loading = payload;
    },
    [action.getStuff]: (draft, { payload }) => {
      draft.stuff = payload.items;
      draft.selectedKit = payload.teamKit;
      draft.loading = false;
    },
  },
  initialValue
);
