import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Tasks';

const initialValue = {
  loading: true,
  data: {},
};
export const tasks = handleActions(
  {
    [action.getTasksData]: (draft, { payload }) => {
      draft.data = payload;
    },
    [action.getTasksListLoading]: (draft, { payload }) => {
      draft.loading = payload;
    },
  },
  initialValue
);
