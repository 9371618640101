import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as HackIcon } from '../../assets/img/hack-icon.svg';

export const StyledContainer = styled.section`
  height: 230px;
  width: 49%;
  > h4 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #949494;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1199px) {
    width: 100%;
    min-height: 200px;
    height: 320px;
    margin-top: 25px;
    margin-bottom: 25px;
    > h4 {
      font-size: 22px;
    }
  }
`;

export const StyledNextMatch = styled.div`
  width: 100%;
  height: 100%;
  /* max-width: 712px; */
  /* max-height: 574px; */
  position: relative;
  border-radius: 5px;
  /*  overflow: hidden; */
  padding: 16px 30px 0;
  background: black;
  margin-bottom: 31px;
  /* margin-bottom: 23px; */
  /* box-shadow: inset 81px 0px 5px 0px rgba(0, 0, 0, 1); */

  filter: drop-shadow(0px 7px 14px rgba(0, 0, 0, 0.08));

  @media only screen and (max-width: 1199px) {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
`;

export const StyledTextedDiv = styled.div`
  width: 100%;
  @media screen and (max-width: 425px) {
    width: auto;
  }
  > p {
    position: relative;
    z-index: 1;
    font-family: 'Gilroy', sans-serif;

    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    opacity: 0.5;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.4px;
    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }
  > h1 {
    margin: 0;
    position: relative;
    z-index: 2;
    color: #ffffff;
    font-size: 29px;

    span {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 900;
      line-height: 34px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      -webkit-text-stroke-color: white;
      -webkit-text-stroke-width: 1px;
      font-size: 32px;
      color: transparent;
      @media screen and (max-width: 991px) {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 22px;
        line-height: 24px;
        letter-spacing: 0.015em;
        text-transform: uppercase;
      }
    }
    @media screen and (max-width: 1199px) {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      text-align: center;
    }
  }
`;

export const StyledLink = styled(Link)`
  margin: 30px 0;
  position: relative;
  max-width: fit-content;
  z-index: 1;
  display: flex;
  padding: 12px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.01em;
  height: 38px;
  color: #000000;
  text-decoration: none;
  transition: 0.3s;
  width: 120px;
  &:focus {
    background: #0e4afb;
    color: #ffffff;
  }
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        width: 100%;
        justify-content: center;
        min-width: 100%;
      `;
    }
  }}
`;

export const StyledInGame = styled(Link)`
  margin: 5px 0;
  position: relative;
  justify-content: center;
  z-index: 1;
  display: flex;
  padding: 12px 25px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  margin-right: 15px;
  letter-spacing: 0.01em;
  height: 38px;
  color: #000;
  text-decoration: none;
  transition: 0.3s;
  width: 200px;
  padding-left: 25px;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const StyledNewLabel = styled.span`
  position: absolute;
  right: 0px;
  top: 0;
  height: 20px;
  width: 42px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 19px;
  font-size: 11px;
`;
export const StyledLeagueLabel = styled.span`
  position: absolute;
  right: 0px;
  top: 12px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  font-size: 10px;
  text-transform: uppercase;
  z-index: 1;
  padding: 7px 15px;
  min-height: 25px;
  min-width: 100px;
`;

export const StyledHackIcon = styled(HackIcon)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

export const StyledButton = styled.button`
  height: 38px;
  margin: 30px 5px;
  position: relative;
  max-width: fit-content;
  z-index: 1;
  display: flex;
  padding: 9px 20px;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.01em;
  background-color: #e94235;
  border: 1px solid #e94235;
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:disabled {
    background-color: #ebebeb;
    color: #989898;
    border: 1px solid #ebebeb;
  }
`;
export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 769px) and (max-width: 1199px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledFallbackContainer = styled.div`
  position: absolute;
  @media only screen and (max-width: 1199px) {
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 5px;
    background: black;
    margin-bottom: 31px;
    -webkit-filter: drop-shadow(0px 7px 14px rgba(0, 0, 0, 0.08));
    filter: drop-shadow(0px 7px 14px rgba(0, 0, 0, 0.08));
  }

  svg {
    z-index: 1;
    position: relative;
  }
`;

export const StyledLockers = styled.img`
  position: absolute;
  height: 100%;
  right: 0;
  width: 100%;
  width: 300px;
  top: 0;
  border-radius: 4px;
`;

export const StyledNextMatchBg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
`;

export const StyledTeamShirtContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
