import React from 'react';
import { StyledYouthCampWrapper } from './styled';
import PlayerCard from '../PlayerCard';

const YouthCampPlayers = ({ players }) => (
  <StyledYouthCampWrapper>
    {players &&
      players.map(
        ({
          _id,
          first_name,
          last_name,
          age,
          fav_position,
          scout_results,
          acquired,
          selectedToBeScouted,
          activeBid,
        }) => (
          <PlayerCard
            key={_id}
            id={_id}
            firstName={first_name}
            lastName={last_name}
            age={age}
            favPosition={fav_position}
            scoutResults={scout_results}
            acquired={acquired}
            selectedToBeScouted={selectedToBeScouted}
            activeBid={activeBid}
          />
        )
      )}
  </StyledYouthCampWrapper>
);

export default React.memo(YouthCampPlayers);
