import styled from 'styled-components';

export const StyledLeagueContainer = styled.div`
  height: 470px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  min-width: 250px;
  margin: 0 10px;
  width: 100%;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  @media only screen and (max-width: 991px) {
    margin: 10px 0;
  }
`;

export const StyledTeamsContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const StyledTeam = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-top: 1px solid #eee;
  &:last-child {
    border-bottom: 1px solid #eee;
  }
  svg {
    margin-left: 5px;
  }
`;

export const StyledLeagueHeader = styled.h3`
  font-size: 13px;
  font-weight: 500;
  padding-left: 20px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin: 0 15px;
    height: 100%;
    overflow-y: scroll;
    padding-top: 15px;
  }
`;

export const StyledHeader = styled.h2`
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: relative;
`;
