import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StyledPopUp, StyledCross, StyledPopUpContainer } from '../styled';
import { StyledButton } from '../../../globalStyled';
import {
  StyledPopupHeader,
  StyledPopUpBlockNoPadding,
  StyledParagraph,
} from './styled';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import { getPopupsList } from '../../../redux/selector/Popups';

const NotificationPopup = ({ popupName, closeCallback }) => {
  const dispatch = useDispatch();
  const popupsList = useSelector(getPopupsList);
  const notificationPopupContent = popupsList[popupName];
  const hidePopup = () => {
    closeCallback();
    dispatch(hidePopupWindow(popupName));
  };

  return (
    <StyledPopUp>
      <StyledPopUpBlockNoPadding>
        <StyledPopUpContainer>
          <StyledPopupHeader>
            <StyledCross onClick={() => hidePopup()} />
          </StyledPopupHeader>
          <StyledParagraph>{notificationPopupContent.content}</StyledParagraph>
          <StyledButton primary onClick={() => hidePopup()}>
            OK
          </StyledButton>
        </StyledPopUpContainer>
      </StyledPopUpBlockNoPadding>
    </StyledPopUp>
  );
};

export default React.memo(NotificationPopup);
