import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cashFormat } from '../../service/functions';
import { StyledTooltip, StyledDate, StyledVal } from './styled';

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <StyledTooltip>
        <StyledDate>
          {currData && currData.tick === 'Today'
            ? 'Now'
            : moment(currData.date).format('DD MMM')}
        </StyledDate>

        <StyledVal>
          {currData
            ? `${cashFormat(currData.amount || currData.prediction, '$')}`
            : ' -- '}
        </StyledVal>
      </StyledTooltip>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};
CustomTooltip.defaultProps = {
  active: false,
  payload: [],
};

export default CustomTooltip;
