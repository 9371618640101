import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Crown } from '../../../assets/img/crown.svg';
import { sendTrainingRequest } from '../../../redux/actions/Training';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import {
  getTrainingLoading,
  trainingTeams,
  getTrainingOfferLoading,
  getTrainingOfferResponse,
} from '../../../redux/selector/Training';

import {
  StyledPopUp,
  StyledMultisectionPopup,
  StyledLoader,
  StyledCross,
  StyledSubmitButton,
} from '../styled';
import {
  StyledLeagueContainer,
  StyledTeam,
  StyledLeagueHeader,
  StyledContainer,
  StyledHeader,
  StyledTeamsContainer,
} from './styled';
import { isAuth } from '../../../redux/selector/User';

import { goToDiscordLogin } from '../../../service/functions';

const TrainingPopup = ({ popupName, leaguesList }) => {
  const [activeTeamSelectId, setActiveTeamId] = React.useState(null);
  const dispatch = useDispatch();
  const trainingData = useSelector(trainingTeams);
  const isTrainingLoading = useSelector(getTrainingLoading);
  const isTrainingOfferLoading = useSelector(getTrainingOfferLoading);
  const trainingOfferResponse = useSelector(getTrainingOfferResponse);
  const isAuthenticated = useSelector(isAuth);

  const sendTraining = (teamId) => {
    setActiveTeamId(teamId);
    dispatch(sendTrainingRequest(teamId));
  };

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };

  const buttonText = (teamData, offerResponse) => {
    if (teamData.bot) {
      return 'Select';
    }
    if (offerResponse === teamData._id) {
      return 'Sent';
    }
    return 'Select';
  };

  return (
    <StyledPopUp>
      <StyledMultisectionPopup>
        {isTrainingLoading ? (
          <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
        ) : (
          <StyledContainer>
            <StyledHeader>
              Practice - Select a partner
              <StyledCross onClick={() => hidePopup()} />
            </StyledHeader>
            <StyledTeamsContainer>
              {leaguesList.map((league) => {
                const teamsForLeague = trainingData.filter(
                  (team) => team.league === league.name
                );

                return (
                  <>
                    {!isEmpty(teamsForLeague) ? (
                      <StyledLeagueContainer>
                        <StyledLeagueHeader>{league.name}</StyledLeagueHeader>
                        <div>
                          {teamsForLeague.map((team) => (
                            <StyledTeam key={team._id}>
                              <span>
                                {team.name}
                                {team.premium && <Crown />}
                              </span>
                              <StyledSubmitButton
                                primary
                                isGrey={team.training}
                                disabled={
                                  isTrainingOfferLoading &&
                                  activeTeamSelectId === team._id
                                }
                                isCompleted={trainingOfferResponse === team._id}
                                onClick={() => {
                                  if (!isAuthenticated) {
                                    goToDiscordLogin();
                                  } else {
                                    sendTraining(team._id);
                                  }
                                }}
                              >{`${buttonText(
                                team,
                                trainingOfferResponse
                              )}`}</StyledSubmitButton>
                            </StyledTeam>
                          ))}
                        </div>
                      </StyledLeagueContainer>
                    ) : null}
                  </>
                );
              })}
            </StyledTeamsContainer>
          </StyledContainer>
        )}
      </StyledMultisectionPopup>
    </StyledPopUp>
  );
};

export default TrainingPopup;
