import React from 'react';
import { StyledIframe, StyledWrapper } from './styled';

const TermsPage = () => (
  <StyledWrapper>
    <StyledIframe
      title="Terms"
      src="https://app.termly.io/policy-viewer/policy.html?policyUUID=ab61bce6-ca20-4739-8dd8-8b3c7cdd7934"
    />
  </StyledWrapper>
);

export default TermsPage;
