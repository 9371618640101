import styled, { css } from 'styled-components';
import { ReactComponent as Arrow } from '../../../../assets/img/SimpleArrow.svg';

export const StyledArrow = styled(Arrow)`
  position: absolute;
  right: 0px;
  top: 5px;
  cursor: pointer;
`;

export const StyledRelativeContainer = styled.div`
  position: relative;
  transition: all 0.2s;
  ${({ isRightOpen }) => {
    if (isRightOpen) {
      return css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-right: 300px;
        transition: all 0.2s;
      `;
    }
  }}
`;
export const StyledHeaderBlock = styled.aside`
  overflow: auto;
  position: fixed;
  overflow: auto;
  width: calc(100% - 100px);
  left: 60px;
  margin-top: 20px;
  top: 26px;
  height: 35px;
  transition: all 0.2s, opacity 0.3s;
  display: none;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
  background: #fff;
  @media screen and (max-width: 1199px) {
    z-index: 2;
  }
  @media screen and (min-width: 991px) and (max-width: 1199px) {
    left: 0;
  }
  h4 {
    margin-right: 20px;
  }
  ${({ showHeader }) => {
    if (showHeader) {
      return css`
        display: flex;
         {
          /* Navigation and additional pop up opened */
          width: 100%;
          /* width: 377px; */
          @media screen and (max-width: 1100px) {
            left: 0;
            width: 100%;
          }
        }
      `;
    }
  }}
  &[data-left='true'] {
    /* Navigation and additional pop up opened */
    width: 100%;
    /* width: 377px; */
    @media screen and (max-width: 1100px) {
      left: 0;
      width: 100%;
    }
  }
  &[data-left='false'] {
    /* Navigation closed - additional pop up closed */
    left: 60px;
    width: 270px;
    transform: translate3d(-100%, 0, 0);
    /* width: 377px; */
    @media screen and (max-width: 1100px) {
      left: 0;
      transform: translate3d(100%, 0, 0);

      /* left: 200%; */
      width: 100%;
    }
  }
`;
