import React from 'react';
import { StyledInfoIcon } from '../../globalStyled';
import {
  StyledDetailsContainer,
  StyledDetailsDescription,
  StyledDetailsDescriptionContainer,
  StyledDetailsNumber,
  StyledFirstNumber,
  StyledLastNumber,
  StyledReactTooltip,
} from './styled';

const YouthCampDetailsItem = ({
  firstNumber,
  lastNumber = null,
  descriptionLabel,
  description,
  id,
}) => (
  <StyledDetailsContainer>
    <StyledDetailsNumber>
      <StyledFirstNumber>{firstNumber}</StyledFirstNumber>
      <StyledLastNumber>
        {lastNumber ? `of ${lastNumber}` : ''}
      </StyledLastNumber>
    </StyledDetailsNumber>
    <StyledDetailsDescriptionContainer>
      <StyledDetailsDescription>{descriptionLabel}</StyledDetailsDescription>

      <StyledInfoIcon data-tip clickable data-for={id} />

      <StyledReactTooltip id={id} type="light" effect="solid" place="right">
        {description}
      </StyledReactTooltip>
    </StyledDetailsDescriptionContainer>
  </StyledDetailsContainer>
);

export default React.memo(YouthCampDetailsItem);
