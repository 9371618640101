import styled, { css } from 'styled-components';
import { StyledButtonContainer } from '../../../../../../globalStyled';

export const StyledPlayerData = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledControlContainer = styled(StyledButtonContainer)`
  justify-content: space-between;
  @media only screen and (max-width: 426px) {
    bottom: 0;
  }
`;

export const StyledChartContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

export const StyledCardDetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
  width: auto;
  flex-grow: 1;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ half }) => {
    if (half)
      return css`
        width: 50%;
        border-top: 1px solid #e5e5e5;
      `;
  }}
  ${({ topBorder }) => {
    if (topBorder)
      return css`
        border-top: 1px solid #e5e5e5;
      `;
  }}
  &:not(:last-child) {
    border-right: 1px solid #e5e5e5;
  }
`;
export const StyledValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ color }) => color};
`;
export const StyledLabel = styled.span`
  font-family: Gilroy;
  font-size: 11px;
  font-weight: 500;
  color: #868686;
  text-align: center;
  letter-spacing: 0.77px;
  padding-top: 5px;
`;

export const StyledTeamSquadContainer = styled.section``;
export const StyledTransfersSearch = styled.div`
  padding-top: ${({ isShownFilter }) => (isShownFilter ? '50px' : '0px')};
  h3 {
    text-align: center;
    margin-bottom: 22px;
  }
`;
export const StyledCard = styled.div`
  padding: 0px;
  background: #ffffff;
  > p {
    margin: 14px 0;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.222px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #000000;
  }
`;

export const StyledInjuredLabel = styled.div`
  height: 35px;
  padding: 11px 14px 10px 15px;
  background-color: #de3e32;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const StyledLeftLabel = styled.span`
  color: #fff;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
`;
export const StyldRightLabel = styled.span`
  font-family: Gilroy;
  font-size: 14px;
  color: #fff;
`;

export const StyledPlus = styled.span`
  font-family: 'Gilroy-SemiBold', sans-serif;
  font-weight: bold;
  font-size: 26px;
  position: absolute;
  left: 5px;
  top: 2px;
`;
export const StyledTeamName = styled.span`
  font-size: 14px;
  color: #828282;
  font-weight: 500;
  font-family: 'Gilroy';
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;
`;

export const StyledContainerForFilter = styled.div``;
