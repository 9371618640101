import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { sendFriendlyRequest } from '../../../redux/actions/Friendly';
import { hidePopupWindow } from '../../../redux/actions/Popups';
import {
  getFriendlyLoading,
  friendlyTeams,
  getFriendlyOfferLoading,
  getFriendlyOfferResponse,
} from '../../../redux/selector/Friendly';

import {
  StyledPopUp,
  StyledMultisectionPopup,
  StyledLoader,
  StyledCross,
  StyledSubmitButton,
} from '../styled';
import {
  StyledLeagueContainer,
  StyledTeam,
  StyledLeagueHeader,
  StyledContainer,
  StyledHeader,
  StyledTeamsContainer,
} from './styled';

const FriendlyPopup = ({ popupName, leaguesList }) => {
  const [activeTeamSelectId, setActiveTeamId] = React.useState(null);
  const dispatch = useDispatch();
  const friendlyData = useSelector(friendlyTeams);
  const isFriendlyLoading = useSelector(getFriendlyLoading);
  const isFriendlyOfferLoading = useSelector(getFriendlyOfferLoading);
  const friendlyOfferResponse = useSelector(getFriendlyOfferResponse);
  const sendFriendly = (teamId) => {
    setActiveTeamId(teamId);
    dispatch(sendFriendlyRequest(teamId));
  };

  const hidePopup = () => {
    dispatch(hidePopupWindow(popupName));
  };

  const buttonText = (teamData, offerResponse) => {
    if (teamData.bot) {
      return 'Bot';
    }
    if (offerResponse === teamData._id) {
      return 'Sent';
    }
    return 'Select';
  };

  return (
    <StyledPopUp>
      <StyledMultisectionPopup>
        {isFriendlyLoading ? (
          <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
        ) : (
          <StyledContainer>
            <StyledHeader>
              Friendly match - Select an opponent
              <StyledCross onClick={() => hidePopup()} />
            </StyledHeader>
            <StyledTeamsContainer>
              {leaguesList.map((league) => {
                const teamsForLeague = friendlyData.filter(
                  (team) => team.league === league.name
                );
                return (
                  <StyledLeagueContainer>
                    <StyledLeagueHeader>{league.name}</StyledLeagueHeader>
                    <div>
                      {teamsForLeague.map((team) => (
                        <StyledTeam key={team._id}>
                          {team.name}
                          <StyledSubmitButton
                            isGrey={team.bot}
                            primary
                            disabled={
                              isFriendlyOfferLoading &&
                              activeTeamSelectId === team._id
                            }
                            isCompleted={friendlyOfferResponse === team._id}
                            onClick={() => {
                              sendFriendly(team._id);
                            }}
                          >{`${buttonText(
                            team,
                            friendlyOfferResponse
                          )}`}</StyledSubmitButton>
                        </StyledTeam>
                      ))}
                    </div>
                  </StyledLeagueContainer>
                );
              })}
            </StyledTeamsContainer>
          </StyledContainer>
        )}
      </StyledMultisectionPopup>
    </StyledPopUp>
  );
};

export default FriendlyPopup;
