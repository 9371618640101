import React from 'react';
import { isMobile } from 'react-device-detect';
import { Switch, Route } from 'react-router-dom';
import { StyledHeaderBlock, StyledRelativeContainer } from './styled';

import TeamSide from './pages/Team';
import TransfersSide from './pages/Transfers';
import UserGuideSide from './pages/UserGuide';
import StuffSide from './pages/Stuff';

export default React.memo(function HeaderBlock({ showHeader, isRightOpen }) {
  return (
    <StyledHeaderBlock showHeader={showHeader}>
      <StyledRelativeContainer isRightOpen={isRightOpen}>
        <Switch>
          <Route path="/team" component={TeamSide} />
          <Route path="/transfers" component={TransfersSide} />
          {!isMobile && <Route path="/user-guide" component={UserGuideSide} />}
          <Route path="/stuff" component={StuffSide} />
        </Switch>
      </StyledRelativeContainer>
    </StyledHeaderBlock>
  );
});
