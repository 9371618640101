import unset from 'lodash/unset';
import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Popups';

const initialValue = {};

export const popups = handleActions(
  {
    [action.showPopup]: (draft, { payload }) => {
      const { id, popupName, isLoading = false } = payload;
      const newData = draft;
      newData[popupName] = {
        id,
        isOpen: true,
        isLoading,
      };
    },
    [action.hidePopup]: (draft, { payload }) => {
      unset(draft, payload);
    },
    [action.setPopupLoading]: (draft, { payload }) => {
      const newData = draft;
      newData[payload.popupName].isLoading = payload.isLoading;
    },
    [action.addPopupError]: (draft, { payload }) => {
      const { popupName, error } = payload;
      const newData = draft[popupName];
      newData.error = error;
    },
    [action.addNotificationPopup]: (draft, { payload }) => {
      const notificationPopupName = payload.name;
      const newData = draft;
      newData[notificationPopupName] = {
        content: payload.message,
      };
    },
  },
  initialValue
);
