import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { StyledButton } from '../../globalStyled';

export const StyledCross = styled.span`
  position: absolute;
  right: 32px;
  top: 32px;
  background-color: #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  z-index: 1;
  top: -6px;
  right: -8px;

  &:hover {
    cursor: pointer;
  }
  &:before,
  &:after {
    position: absolute;
    left: 9px;
    top: 5px;
    content: ' ';
    height: 11px;
    width: 2px;
    background-color: #fff;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const StyledWrapper = styled.div`
  width: 230px;
`;
export const StyledCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 230px;
  width: 100%;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 10%);
  border-radius: 6px;

  ${({ isAcquired }) => {
    if (isAcquired) {
      return css`
        opacity: 0.55;
        &:hover {
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
        }
      `;
    }
  }}
`;

export const StyledCardButton = styled(StyledButton)`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
  min-width: 110px;
  font-size: 13px;
  padding: 12px 10px;
  ${({ isActive }) => {
    if (isActive) {
      return css`
        background: #ffc80f;
        color: #000;
        border: 1px solid #ffc80f;
      `;
    }
  }}
`;

export const StyledPlayerHeader = styled.div`
  position: relative;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 0 6px 7px rgba(0, 0, 0, 0.02);
  width: 230px;
`;

export const StyledCardDetails = styled.div`
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 0 6px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
`;

export const StyledCardDetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 16px 10px;
  align-items: center;
  justify-content: center;
  img {
    width: 100px;
    height: auto;
  }
  &:first-child {
    border-right: 1px solid #ddd;
  }
`;

export const StyledHeaderContent = styled.h1`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 70px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const StyledPlayerName = styled.p`
  color: #000;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 600;
  text-transform: none;
  line-height: 1.24;
`;

export const StyledLabel = styled.span`
  font-family: Gilroy;
  font-size: 11px;
  font-weight: 500;
  color: #7f7f7f;
  text-align: center;
  letter-spacing: 0.77px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  ${({ extendedPadding }) => {
    if (extendedPadding) {
      return css`
        padding-bottom: 5px;
      `;
    }
  }}
`;

export const StyledValue = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-align: center;
`;

export const StyledMore = styled.span`
  font-family: 'Gilroy-SemiBold', sans-serif;
  color: #000;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledReportLabel = styled.span`
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: #333333;
  line-height: 6px;
  color: #fff;
  font-family: Gilroy;
  font-size: 10px;
  font-weight: 500;
  width: 135px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const StyledScoutContainer = styled.div`
  position: relative;
  background-color: #fafafa;
`;

export const StyledNotAvailable = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
`;
export const StyledReactTooltip = styled(ReactTooltip)`
  box-shadow: 0 0 15px 5px rgba(17, 17, 17, 0.08);
  font-family: Gilroy;
  cursor: pointer;
  border: solid 0.5px #e1e1e1;
  width: 190px;
  &.__react_component_tooltip.show {
    color: #4e4e4e;
    font-size: 13px;
    line-height: 18px;
    border-radius: 6px;
    opacity: 1;
  }
`;
