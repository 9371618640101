import styled, { css } from 'styled-components';
import { ReactComponent as Info } from './assets/info.svg';
import { StyledButton } from '../../../../globalStyled';
import { ReactComponent as StyledExpand } from '../../../../assets/img/info-card-icon-white.svg';

export const StyledInfo = styled(Info)`
  position: absolute;
  left: 4px;
  top: 4px;
`;
export const StyledInfoIcon = styled(StyledExpand)`
  margin-bottom: 3px;
`;

export const StyledPlayerCard = styled.div`
  /*   @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 200, 15, 0.7);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(255, 200, 15, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 200, 15, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 200, 15, 0.7);
      box-shadow: 0 0 0 0 rgba(255, 200, 15, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 200, 15, 0);
      box-shadow: 0 0 0 10px rgba(255, 200, 15, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 200, 15, 0);
      box-shadow: 0 0 0 0 rgba(255, 200, 15, 0);
    }
  } */
  cursor: pointer;
  width: 230px;
  position: relative;
  height: 265px;
  padding: 0px;
  background-color: #ffffff;
  border: 0;
  box-shadow: 0 2px 6px 7px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  margin: 20px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 200px;
    height: auto;
    padding: 0;
    margin: 20px 0;
  }
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.3;
        pointer-events: none;
      `;
    }
  }}
  /*   ${({ cardType }) => {
    if (cardType === 'buy') {
      return css`
        animation: pulse 1.5s infinite;
      `;
    }
  }} */


  > h5 {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
  }
  ${({ selectPlayer }) => {
    if (selectPlayer) {
      return css`
        &.data-${selectPlayer} {
          background: #0e4afb;
          > h5 {
            color: white;
          }
          p {
            /* border: none; */
          }
          svg {
            circle {
              fill: white;
            }
          }
        }
      `;
    }
  }}
`;

export const StyledInfoText = styled.p`
  user-select: none;
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14.22px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.015em;
  color: #000000;
  justify-content: center;
  padding: 13px 10px;
`;

export const StyledHeader = styled.div`
  height: 90px;
  position: relative;
  background-color: #000;
  font-family: 'Gilroy', sans-serif;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 0.6;
    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const StyledLabel = styled.span`
  position: absolute;
  left: -8px;
  top: 5px;
  background-color: #ffc80f;
  color: #000;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  font-family: 'Gilroy', sans-serif;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 0.45px;
  text-transform: uppercase;
  z-index: 1;
  padding: 7px 15px;
  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-top: 7px solid #d6a605;
    border-left: 7px solid transparent;
    left: 0px;
    top: 24px;
  }
`;

export const StyledInlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  padding: 0 5px 10px 5px;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledName = styled.h5`
  color: #fff;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  font-weight: 800;
  font-size: 18px;
`;

export const StyledDate = styled.span`
  position: relative;
  z-index: 1;
  color: #ccc;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  font-family: 'Gilroy', sans-serif;
  align-items: flex-end;
  justify-content: center;

  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.45px;
  padding-top: 7px;
  padding-right: 10px;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;
export const StyledInfoItem = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ small }) => {
    if (small) {
      return css`
        height: 60px;
        width: 50%;
        &:first-of-type {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
      `;
    }
  }}
`;
export const StyledAmount = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  text-align: center;
`;
export const StyledInfoLabel = styled.div`
  color: #b2b2b2;
  font-family: 'Gilroy', sans-serif;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
`;

export const StyledCardButton = styled(StyledButton)`
  min-width: 138px;
  padding: 7px 10px;
  font-size: 10px;
  margin: 0;
  width: 100%;
  @media only screen and (max-width: 768px) {
    padding: 7px 10px;
  }
  ${({ small }) => {
    if (small) {
      return css`
        min-width: 88px;
        width: 49%;
        max-width: 100px;
        margin: 0 5px;
        flex-direction: row;
      `;
    }
  }}
`;
