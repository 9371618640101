import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/DomesticCup';

const initialValue = {
  domesticCupData: {
    phase: null,
    first_round_first_set: [],
    first_round_second_set: [],
    second_round: [],
    final_round: [],
    qual_round: [],
  },
  cupCalendar: [],
  isLoading: true,
  isTableLoading: false,
};
export const domesticCup = handleActions(
  {
    [action.setDomesticCupData]: (draft, { payload }) => {
      draft.domesticCupData = payload;
    },
    [action.domesticCupDataLoading]: (draft, { payload }) => {
      draft.isLoading = payload;
    },
    [action.setCalendarDates]: (draft, { payload }) => {
      draft.cupCalendar = payload;
    },
    [action.domesticCupTableLoading]: (draft, { payload }) => {
      draft.isTableLoading = payload;
    },
  },
  initialValue
);
