import styled from 'styled-components';
import { ReactComponent as StyledRightArrowWhiteIcon } from '../../assets/img/go-arrow-white.svg';
import { StyledButton } from '../../globalStyled';

export const StyledInput = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  text-align: left;

  width: calc(100% - 50px);
  height: 40px;
  position: relative;
  background: #ffffff;
  border: 0.1em solid rgba(0, 0, 0, 0.26);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.015em;
  color: #666666;

  padding: 13px 8px;
  margin: 25px 0 15px 0;
  outline: none;
  &:focus {
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);
  }
`;

export const StyledSubmitArrow = styled(StyledRightArrowWhiteIcon)`
  width: 20px;
  height: auto;
`;

export const StyledFormButton = styled(StyledButton)`
  position: absolute;
  width: 100%;
  max-width: 210px;
  height: 40px;
  font-size: 16px;
  width: 50px;
  padding: 0;
  min-width: 50px;
  right: 1px;
  top: 25px;
  margin: 0;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 0.1em solid rgba(0, 0, 0, 0.26);
  border-left: 0px;
  background-color: #000000;
  &:disabled {
    background-color: #0000008b;
    border: 0.1em solid rgba(0, 0, 0, 0.26);
    border-left: 0;
    box-shadow: 0px;
  }
`;
