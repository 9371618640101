import { handleActions } from '../../helpers/immer';
import * as action from '../../actions/Home';

const initialValue = {
  tipsShow: true,
  data: {
    rankingTable: null,
    last_match: null,
    next_match: null,
    cash_summary: null,
    learning: null,
    symbol: null,
    teamName: null,
    teamzone: null,
    inServer: true,
    discordChannel: null,
    matchRunning: null,
    opponentTeamPremium: null,
  },
  loading: null,
};
export const home = handleActions(
  {
    [action.showTips]: (draft, { payload }) => {
      draft.tipsShow = payload.state;
    },
    [action.getHomeInitialData]: (draft, { payload }) => {
      draft.data.rankingTable = payload.rankingTable;
      draft.data.last_match = payload.last_match;
      draft.data.next_match = payload.next_match;
      draft.data.cash_summary = payload.current_cash;
      draft.data.current_cup_ranking = payload.current_cup_ranking;
      draft.data.current_season_ranking = payload.current_season_ranking;
      draft.data.best_cup_ranking = payload.best_cup_ranking;
      draft.data.best_season_ranking = payload.best_season_ranking;
      draft.data.players_avg_rating = payload.players_avg_rating;
      draft.data.learning = payload.learning;
      draft.data.symbol = payload.symbol;
      draft.data.teamId = payload.teamId;
      draft.data.teamName = payload.teamName;
      draft.data.leagueName = payload.leagueName;
      draft.data.timezone = payload.timezone;
      draft.data.inServer = payload.inServer;
      draft.data.discordChannel = payload.discordChannel;
      draft.data.matchRunning = payload.matchRunning;
      draft.data.opponentTeamPremium = payload.opponentTeamPremium;
      draft.data.teamKit = payload.teamKit;
      draft.data.opponentKit = payload.opponentKit;
      draft.loading = false;
    },
    [action.homeInitialDataLoading]: (draft, { payload }) => {
      draft.loading = true;
    },
  },
  initialValue
);
