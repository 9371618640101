import mixpanel from 'mixpanel-browser';

export const getQueryParam = (url, params) => {
  // Expects a raw URL
  // eslint-disable-next-line
  const param = params.replace(/[[]/, '[').replace(/[]]/, ']');
  const regexS = `[?&]${param}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (
    results === null ||
    (results && typeof results[1] !== 'string' && results[1].length)
  ) {
    return '';
  }
  return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
};
export const campaignParams = () => {
  const campaignKeywords =
    'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let kw = '';
  const params = {};
  const firstParams = {};
  let index;
  for (index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index]);
    if (kw.length) {
      params[`${campaignKeywords[index]} [last touch]`] = kw;
    }
  }
  for (index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index]);
    if (kw.length) {
      firstParams[`${campaignKeywords[index]} [first touch]`] = kw;
    }
  }

  mixpanel.people.set(params);
  mixpanel.people.set_once(firstParams);
  mixpanel.register(params);
  mixpanel.identify();
};
